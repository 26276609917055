import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select
} from '@material-ui/core';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import {
  addMonths,
  addWeeks,
  endOfWeek,
  format,
  getISOWeek,
  isBefore,
  parse,
  startOfMonth,
  startOfWeek
} from 'date-fns';
import MonthCalendarSettings from '../Components/Utils/MonthCalendarSettings';
import { MonthCalendarStyle, MonthCalendarStyles } from '../../../../../../store/MyOrganization/types';
import moment from 'moment';

type MonthCalendarProps = {
  open: boolean;
  onCloseClick: () => void;
  onFormSubmit: (values: {
    selectedMonth: number;
    selectedYear: number;
    selectedWeek: number;
    selectedStyle: MonthCalendarStyles;
    styles: MonthCalendarStyle;
    viewType: 'month' | 'week';
    labelPosition: 'left' | 'center' | 'right';
  }) => void;
  month: number;
  year: number;
  week: number;
  selectedStyle: MonthCalendarStyles;
  styles: MonthCalendarStyle;
  viewType: 'month' | 'week';
  labelPosition: 'left' | 'center' | 'right';
};

const MonthCalendarWindow: FunctionComponent<MonthCalendarProps> = ({
  open,
  onCloseClick,
  onFormSubmit,
  month,
  year,
  week,
  selectedStyle,
  styles,
  viewType,
  labelPosition
}) => {
  const { t } = useTranslation();

  const getMonthWeeks = (year: number, month: number) => {
    const startOfCurrent = startOfMonth(parse(`${year}-${month}-1`, 'yyyy-M-d', new Date()));
    const startOfNext = addMonths(startOfCurrent, 1);

    const weeks = [];

    for (let i = startOfCurrent; isBefore(i, startOfNext); i = addWeeks(i, 1)) {
      weeks.push([getISOWeek(i), format(startOfWeek(i), 'MM/dd/yyyy'), format(endOfWeek(i), 'MM/dd/yyyy')]);
    }

    return weeks;
  };

  const [calendarViewType, setCalendarViewType] = useState<'month' | 'week'>(viewType);

  const [weeks, setWeeks] = useState<(string | number)[][]>(getMonthWeeks(year, month + 1));
  const [selectedWeek, setSelectedWeek] = useState<number>(week);
  const [selectedMonth, setSelectedMonth] = useState<number>(month);
  const [selectedYear, setSelectedYear] = useState<number>(year);

  const [calendarStyle, setCalendarStyle] = useState<MonthCalendarStyles>(selectedStyle);
  const [calendarStyles, setCalendarStyles] = useState<MonthCalendarStyle>(styles);

  const [calendarLabelPosition, setCalendarLabelPosition] = useState<'left' | 'center' | 'right'>(labelPosition);

  const currentYear = new Date().getFullYear();

  const handleMonthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedMonth(event.target.value as number);
    setWeeks(getMonthWeeks(selectedYear, (event.target.value as number) + 1));
    setSelectedWeek(getMonthWeeks(selectedYear, (event.target.value as number) + 1)[0][0] as number);
  };

  const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedYear(event.target.value as number);
    setWeeks(getMonthWeeks(event.target.value as number, selectedMonth + 1));
    setSelectedWeek(getMonthWeeks(event.target.value as number, selectedMonth + 1)[0][0] as number);
  };

  const handleWeekChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedWeek(event.target.value as number);
  };

  const handleLabelPositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCalendarLabelPosition((event.target as HTMLInputElement).value as 'left' | 'center' | 'right');
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.emailEditor.monthCalendar.title')}
      onCloseClick={onCloseClick}
      maxWidth="md"
      onSubmit={() =>
        onFormSubmit({
          selectedMonth,
          selectedYear,
          selectedWeek,
          selectedStyle: calendarStyle,
          styles: calendarStyles,
          viewType: calendarViewType,
          labelPosition: calendarLabelPosition
        })
      }
    >
      <FormControl variant="outlined" size="small" style={{ marginRight: 10 }}>
        <InputLabel id="style-select-label">{t('pages.emailEditor.monthCalendar.type')}</InputLabel>
        <Select
          labelId="style-select-label"
          id="style-select"
          value={calendarViewType}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setCalendarViewType(event.target.value as 'month' | 'week');
          }}
          label={t('pages.emailEditor.monthCalendar.type')}
        >
          <MenuItem value="week">{t(`pages.emailEditor.monthCalendar.types.week`)}</MenuItem>
          <MenuItem value="month">{t(`pages.emailEditor.monthCalendar.types.month`)}</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" size="small" style={{ marginRight: 10 }}>
        <InputLabel id="year-select-label">{t('pages.emailEditor.monthCalendar.year')}</InputLabel>
        <Select
          labelId="year-select-label"
          id="year-select"
          value={selectedYear}
          onChange={handleYearChange}
          variant="outlined"
          label={t('pages.emailEditor.monthCalendar.year')}
        >
          {[currentYear - 1, currentYear, currentYear + 1].map((year) => (
            <MenuItem key={`${year}-year`} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" size="small" style={{ marginRight: 10 }}>
        <InputLabel id="month-select-label">{t('pages.emailEditor.monthCalendar.month')}</InputLabel>
        <Select
          labelId="month-select-label"
          id="month-select"
          value={selectedMonth}
          onChange={handleMonthChange}
          label={t('pages.emailEditor.monthCalendar.month')}
        >
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => (
            <MenuItem key={`${month}-month`} value={month}>
              {moment.utc({ month, day: 1 }).format('MMMM')}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {calendarViewType === 'week' && (
        <FormControl variant="outlined" size="small" style={{ marginRight: 10 }}>
          <InputLabel id="month-select-label">{t('pages.emailEditor.monthCalendar.week')}</InputLabel>
          <Select
            labelId="month-select-label"
            id="month-select"
            value={selectedWeek}
            onChange={handleWeekChange}
            label={t('pages.emailEditor.monthCalendar.month')}
          >
            {weeks.map((week) => (
              <MenuItem key={`${week[0]}-week`} value={week[0]}>
                {week[0]}: {week[1]} - {week[2]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Divider style={{ margin: '15px 0' }} />
      <MonthCalendarSettings
        selectedStyle={selectedStyle}
        setSelectedStyle={setCalendarStyle}
        styles={calendarStyles}
        setStyles={setCalendarStyles}
      />
      <FormControl
        fullWidth
        component="fieldset"
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px' }}
      >
        <FormLabel component="legend" style={{ fontSize: '11px', color: 'rgba(0, 0, 0, 0.54)' }}>
          {t('pages.emailEditor.monthCalendar.labelPosition')}
        </FormLabel>
        <RadioGroup name="label-position" value={calendarLabelPosition} onChange={handleLabelPositionChange} row>
          <FormControlLabel
            value="left"
            control={<Radio size="small" />}
            label={t('pages.emailEditor.monthCalendar.left')}
          />
          <FormControlLabel
            value="center"
            control={<Radio size="small" />}
            label={t('pages.emailEditor.monthCalendar.center')}
          />
          <FormControlLabel
            value="right"
            control={<Radio size="small" />}
            label={t('pages.emailEditor.monthCalendar.right')}
          />
        </RadioGroup>
      </FormControl>
    </ModalConfirmationWindow>
  );
};

export default MonthCalendarWindow;
