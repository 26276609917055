import { createOrderArray, httpRequest } from '../../utils';
import {
  AdminCreatePasswordPolicyValues,
  AdminPasswordPoliciesIndexResponse,
  AdminPasswordPolicy,
  AdminUpdatePasswordPolicyValues
} from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { adminPasswordPoliciesActions, adminPasswordPoliciesRequests } from './index';

export const index = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { adminPasswordPolicies } = getState();

  try {
    dispatch(adminPasswordPoliciesActions.passwordPoliciesIndexBegin());

    const response = await httpRequest.runRequest<AdminPasswordPoliciesIndexResponse>(
      adminPasswordPoliciesRequests.index({
        requestParams: {
          page: adminPasswordPolicies.pagination.page,
          per: adminPasswordPolicies.pagination.per,
          q: adminPasswordPolicies.pagination.q,
          order: createOrderArray(adminPasswordPolicies.tableSearch.order)
        }
      })
    );

    dispatch(adminPasswordPoliciesActions.passwordPoliciesIndexSuccess(response));
  } catch (e) {
    dispatch(adminPasswordPoliciesActions.passwordPoliciesIndexFailed(e));
  }
};

export const create = (values: AdminCreatePasswordPolicyValues) => async () => {
  return await httpRequest.runRequest<void>(
    adminPasswordPoliciesRequests.create({
      requestPayload: values
    })
  );
};

export const update = (id: string, values: AdminUpdatePasswordPolicyValues) => async () => {
  return await httpRequest.runRequest<void>(
    adminPasswordPoliciesRequests.update({
      requestPayload: values,
      requestParams: { id }
    })
  );
};

export const deletePasswordPolicy = (passwordPolicyId: string) => async () => {
  return await httpRequest.runRequest<void>(
    adminPasswordPoliciesRequests.deletePasswordPolicy({
      requestParams: { id: passwordPolicyId }
    })
  );
};

export const getAll = async () => {
  return httpRequest.runRequest<{ data: AdminPasswordPolicy[] }>(adminPasswordPoliciesRequests.getAll());
};
