import React, { FunctionComponent } from 'react';
import { AdSpace } from '../../types';

type AdSpaceProps = {
  data: AdSpace;
};

const AdSpaceComponent: FunctionComponent<AdSpaceProps> = ({ data }) => {
  return (
    <div
      style={{
        width: data.params.width,
        height: data.params.height,
        background: '#eeeeee',
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '1.6em',
        lineHeight: '1.6em',
        margin: '0 auto'
      }}
    >
      YOUR AD HERE <br />
      {data.params.width}x{data.params.height}
    </div>
  );
};

export default AdSpaceComponent;
