import React, { FunctionComponent } from 'react';
import { ArticleHorizontal, ArticleVertical, ImageData, ImageDropObjectData } from '../../types';
import { ArticleMainWrapper, LoaderWrapper } from './ArticleHorizontal';
import ReadMoreConfigPopover from './Utils/ReadMoreConfigPopover';
import { useArticle } from './Utils/useArticle';
import { getBodyElement, getHeadlineElement, getImageElement, getReadMoreLinkElement } from './Utils/articleElements';
import { ImageProcessingResponse } from '../../../../../../store/Images/types';
import Loader from '../../../../../Shared/Loading/Loader';
import { MyOrganizationSwatchColor } from '../../../../../../store/MyOrganization/types';
import { extractColorsFromSwatches, getArticleColorSwatchTypes } from '../../Utils';
import { AdminOrganization } from '../../../../../../store/AdminOrganizations/types';
import { ReactComponent as SettingsIcon } from '../../../../../../assets/icons/settings.svg';
import { ComponentActionIcons, StyledEditIcon } from '../../../../../Shared/StyledComponents';
import useOpenHandler from '../../../../../../hooks/useOpenHandler';
import ArticleSettingsWindow from '../Windows/ArticleSettingsWindow';
import { getDefaultImage } from './Factories/initialComponentFactory';

type ArticleVerticalProps = {
  data: ArticleVertical;
  sourceProjectId: string | null;
  organization: AdminOrganization | null;
  onChange: (data: ArticleHorizontal | ArticleVertical) => void;
  onImageDrop: (data: ImageDropObjectData, imageData: ImageData) => Promise<ImageProcessingResponse | null>;
  selectedColorSwatches: { [key: string]: MyOrganizationSwatchColor };
  onInnerAdjustmentEnabledChange: (innerAdjustmentEnabled: boolean) => void;
};

const ArticleVerticalComponent: FunctionComponent<ArticleVerticalProps> = ({
  data,
  sourceProjectId,
  organization,
  onChange,
  onImageDrop,
  selectedColorSwatches,
  onInnerAdjustmentEnabledChange
}) => {
  const {
    mainElement,
    showImage,
    setShowImage,

    showHeadline,
    setShowHeadline,

    showReadMore,
    setShowReadMore,

    customReadMore,
    setCustomReadMore,

    customReadMoreText,
    setCustomReadMoreText,

    customReadMoreLink,
    setCustomReadMoreLink,

    drop,
    isDraggingOver,
    applyingStory,

    readMoreType,
    setReadMoreType,

    flipbookReadMoreLink
  } = useArticle(data, onChange, onImageDrop, sourceProjectId, organization);
  const [
    articleVerticalSettingsWindowOpen,
    onArticleVerticalSettingsWindowOpen,
    onArticleVerticalSettingsWindowClose
  ] = useOpenHandler();

  return (
    <ArticleMainWrapper
      ref={(node) => {
        mainElement.current = node;
        drop(node);
      }}
      style={{
        ...data.wrapperStyle,
        ...extractColorsFromSwatches(selectedColorSwatches, getArticleColorSwatchTypes(data))
      }}
      className={isDraggingOver ? 'article-drag-over' : ''}
    >
      {data.params.imagePosition !== 'top' && data.params.imagePosition && (
        <div style={data.articleWrapperStyle}>
          {showHeadline &&
            getHeadlineElement(data, onChange, selectedColorSwatches, onImageDrop, onInnerAdjustmentEnabledChange)}
        </div>
      )}
      {(!data.params.imagePosition || data.params.imagePosition !== 'bottom') && (
        <div
          style={{
            ...(data.imageWrapperStyle ? data.imageWrapperStyle : {}),
            flexShrink: 0
          }}
        >
          {getImageElement(data, onChange, onImageDrop, applyingStory, onInnerAdjustmentEnabledChange)}
        </div>
      )}
      <div style={data.articleWrapperStyle}>
        {showHeadline &&
          (data.params.imagePosition === 'top' || !data.params.imagePosition) &&
          getHeadlineElement(data, onChange, selectedColorSwatches, onImageDrop, onInnerAdjustmentEnabledChange)}
        {getBodyElement(data, onChange, selectedColorSwatches)}
        {getReadMoreLinkElement(data, selectedColorSwatches)}
      </div>
      {data.params.imagePosition === 'bottom' && (
        <div
          style={{
            ...(data.imageWrapperStyle ? data.imageWrapperStyle : {}),
            flexShrink: 0
          }}
        >
          {getImageElement(data, onChange, onImageDrop, applyingStory, onInnerAdjustmentEnabledChange)}
        </div>
      )}
      <ReadMoreConfigPopover
        storyId={data.storyId}
        sourceProjectId={sourceProjectId}
        flipbookReadMoreLink={flipbookReadMoreLink}
        showImage={showImage}
        setShowImage={setShowImage}
        showReadMore={showReadMore}
        setShowReadMore={setShowReadMore}
        customReadMoreText={customReadMoreText}
        setCustomReadMoreText={setCustomReadMoreText}
        customReadMoreLink={customReadMoreLink}
        setCustomReadMoreLink={setCustomReadMoreLink}
        readMoreType={readMoreType}
        setReadMoreType={setReadMoreType}
        showHeadline={showHeadline}
        setShowHeadline={setShowHeadline}
      />
      {applyingStory && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      <ComponentActionIcons style={{ left: 'calc(100% - 45px)' }}>
        <StyledEditIcon onClick={() => onArticleVerticalSettingsWindowOpen()}>
          <SettingsIcon style={{ width: 22, fill: '#fff' }} />
        </StyledEditIcon>
      </ComponentActionIcons>

      {articleVerticalSettingsWindowOpen && (
        <ArticleSettingsWindow
          open={articleVerticalSettingsWindowOpen}
          onCloseClick={onArticleVerticalSettingsWindowClose}
          onFormSubmit={(values) => {
            onChange({
              ...data,
              articleHeadlineStyle: {
                ...data.articleHeadlineStyle,
                backgroundColor: values.headlineBackgroundColor
              },
              articleBodyStyle: {
                ...data.articleBodyStyle,
                backgroundColor: values.bodyBackgroundColor
              },
              wrapperStyle: {
                ...data.wrapperStyle,
                backgroundColor: values.backgroundColor
              },
              params: {
                ...data.params,
                imagePosition: values.imagePosition,
                showHeadlineImage: values.showHeadlineImage,
                headlineImage: data.params.headlineImage || getDefaultImage(50, 50)
              }
            });
            onArticleVerticalSettingsWindowClose();
          }}
          imagePosition={data.params.imagePosition || 'top'}
          backgroundColor={
            data.wrapperStyle && data.wrapperStyle.backgroundColor ? data.wrapperStyle.backgroundColor : '#ffffff'
          }
          headlineBackgroundColor={
            data.articleHeadlineStyle && data.articleHeadlineStyle.backgroundColor
              ? data.articleHeadlineStyle.backgroundColor
              : '#ffffff'
          }
          bodyBackgroundColor={
            data.articleBodyStyle && data.articleBodyStyle.backgroundColor
              ? data.articleBodyStyle.backgroundColor
              : '#ffffff'
          }
          showHeadlineImage={data.params.showHeadlineImage ? true : false}
        />
      )}
    </ArticleMainWrapper>
  );
};

export default ArticleVerticalComponent;
