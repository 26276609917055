import styled from 'styled-components';
import { Table, TableBody, TableCell, TableHead, TableRow, withTheme } from '@material-ui/core';

export const TableWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow-x: auto;
`;
export const TableContainer = styled(Table)`
  min-width: 650px;
`;
export const TableHeadStyled = styled(TableHead)`
  background-color: #fff;
`;
export const FullWidthTableCell = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const EmptyCell = styled(TableCell)`
  font-style: italic;
  color: rgba(0, 0, 0, 0.54);
`;
export const DataTableHeaderCell = withTheme(styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};

  > div:first-child {
    display: flex;

    button {
      margin-right: 8px;
    }
    align-items: center;
  }
`);
