import React from 'react';
import { ReactComponent as ContentImage } from '../../../../../assets/dnd/content-image.svg';
import { ReactComponent as ContentText } from '../../../../../assets/dnd/content-text.svg';
import { ReactComponent as ContentArticleHorizontal } from '../../../../../assets/dnd/content-article-horizontal.svg';
import { ReactComponent as ContentArticleVertical } from '../../../../../assets/dnd/content-article-vertical.svg';
import { ReactComponent as ContentAdSpace } from '../../../../../assets/dnd/content-ad-space.svg';
import { ReactComponent as ContentCtaButton } from '../../../../../assets/dnd/content-cta-button.svg';
import { ReactComponent as ContentDownloadAttachment } from '../../../../../assets/dnd/content-download-attachment.svg';
import { ReactComponent as ContentSeparator } from '../../../../../assets/dnd/content-separator.svg';
import { ReactComponent as ContentSocialLinks } from '../../../../../assets/dnd/content-social-links.svg';
import { ReactComponent as ContentLogoHorizontal } from '../../../../../assets/dnd/content-logo-horizontal.svg';
import { ReactComponent as ContentLogoVertical } from '../../../../../assets/dnd/content-logo-vertical.svg';
import { ReactComponent as ContentPrintProjectCover } from '../../../../../assets/dnd/content-print-project-cover.svg';
import { ReactComponent as ContentUpcomingEvents } from '../../../../../assets/dnd/content-upcoming-events.svg';
import { ReactComponent as ContentMonthCalendar } from '../../../../../assets/dnd/content-month-calendar.svg';
import { ReactComponent as ContentPromotions } from '../../../../../assets/dnd/content-promotions.svg';

export type ContentIconProps = {
  type: string;
};

export const ContentIcon: React.FC<ContentIconProps> = ({ type }) => {
  switch (type) {
    case 'image':
      return <ContentImage />;
    case 'text':
      return <ContentText />;
    case 'article-horizontal':
      return <ContentArticleHorizontal />;
    case 'article-vertical':
      return <ContentArticleVertical />;
    case 'ad-space':
      return <ContentAdSpace />;
    case 'cta-button':
      return <ContentCtaButton />;
    case 'download-attachment':
      return <ContentDownloadAttachment />;
    case 'separator':
      return <ContentSeparator />;
    case 'social-links':
      return <ContentSocialLinks />;
    case 'logo-horizontal':
      return <ContentLogoHorizontal />;
    case 'logo-vertical':
      return <ContentLogoVertical />;
    case 'print-project-cover':
      return <ContentPrintProjectCover />;
    case 'upcoming-events':
      return <ContentUpcomingEvents />;
    case 'month-calendar':
      return <ContentMonthCalendar />;
    case 'promotions':
      return <ContentPromotions />;
    default:
      return <></>;
  }
};
