import update from 'immutability-helper';
import { AdminUser, AdminUsersActionsConstants, AdminUsersActionType, AdminUsersState } from './types';
import { normalizeAdminUsers } from '../helpers';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: AdminUsersState = {
  users: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  },
  details: {
    activeUser: null,
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const adminUsersPaginationReducer = createPaginationReducer<AdminUsersState, AdminUser>(
  PaginationStoreModule.ADMIN_USERS
);

const adminUsersTableSearchReducer = createTableSearchReducer<AdminUser, AdminUsersState>(
  TableSearchStoreModule.ADMIN_USERS
);

export default function adminUsersReducer(state = initialState, action: WithLogoutAction<AdminUsersActionType>) {
  switch (action.type) {
    case AdminUsersActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case AdminUsersActionsConstants.INDEX_SUCCESS: {
      const data = normalizeAdminUsers(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        users: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case AdminUsersActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        users: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AdminUsersActionsConstants.SHOW_BEGIN: {
      return update(state, {
        details: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null },
          activeUser: { $set: null }
        }
      });
    }

    case AdminUsersActionsConstants.SHOW_SUCCESS: {
      return update(state, {
        details: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null },
          activeUser: { $set: action.payload }
        }
      });
    }

    case AdminUsersActionsConstants.SHOW_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        details: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError },
          activeUser: { $set: null }
        }
      });
    }

    case AdminUsersActionsConstants.KEYWORD_GROUP_ADDED:
    case AdminUsersActionsConstants.KEYWORD_GROUP_REMOVED:
    case AdminUsersActionsConstants.KEYWORD_REMOVED:
    case AdminUsersActionsConstants.KEYWORD_ADDED: {
      return update(state, {
        users: {
          entities: {
            [action.payload.id]: {
              keywords: { $set: action.payload.keywords },
              keywordGroups: { $set: action.payload.keywordGroups }
            }
          }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return adminUsersTableSearchReducer(adminUsersPaginationReducer(state, action), action) as AdminUsersState;
    }
  }
}
