import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import EmailStatisticsPage from './EmailStatisticsPage';

const EmailStatisticsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.PROJECTS.EMAIL.STATISTICS(':projectId'),
    exact: false,
    name: 'emailStatisticsPage',
    public: false,
    show: true,
    component: EmailStatisticsPage,
    scope: []
  }
];

export default EmailStatisticsRoutes;
