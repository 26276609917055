import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageContainer, PageContent } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import TemplateTypePaper from '../../Shared/Layout/TemplateTypePaper';
import { templateTypeName, TemplateTypeName } from '../../../store/Templates/types';
import BulletinIcon from '../../../assets/icons/Bulletin_Icon.png';
import EmailIcon from '../../../assets/icons/Email_Icon.png';
import FlyerIcon from '../../../assets/icons/Flyer_Icon.png';
import NewsletterIcon from '../../../assets/icons/Newsletter_Icon.png';
import DirectoryIcon from '../../../assets/icons/Directory_Icon.png';
import Toast from '../../Shared/Toast/Toast';
import Loader from '../../Shared/Loading/Loader';
import { OrganizationData } from '../../../store/Auth/types';
import { authOperations } from '../../../store/Auth';
import { useTheme } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';

type CreateProjectPageProps = RouteComponentProps & {
  location: { state: { campaignId: string } };
};

const CreateProjectPage: FunctionComponent<CreateProjectPageProps> = ({ location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const fromCampaignId = location.state && location.state.campaignId;

  if (fromCampaignId) {
    localStorage.setItem('sc-campaign-id', fromCampaignId);
  } else {
    localStorage.removeItem('sc-campaign-id');
  }

  const [organizationData, setOrganizationData] = useState<OrganizationData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await authOperations.getOrganizationData();

        setOrganizationData(data);
      } catch (e) {
        Toast.error(t('notifications.getBusinessUserData.error'));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const backButton = {
    onClick: () => dispatch(push(linksConstants.DASHBOARD.INDEX)),
    label: t('navigation.dashboard'),
    icon: <KeyboardArrowLeftIcon />
  };

  const getTemplateTypeIcon = (type: TemplateTypeName) => {
    switch (type) {
      case TemplateTypeName.BULLETIN:
        return <img src={BulletinIcon} alt="BulletinIcon" />;

      case TemplateTypeName.EMAIL:
        return <img src={EmailIcon} alt="EmailIcon" />;

      case TemplateTypeName.NEWSLETTER:
        return <img src={NewsletterIcon} alt="NewsletterIcon" />;

      case TemplateTypeName.FLYER:
        return <img src={FlyerIcon} alt="FlyerIcon" />;

      case TemplateTypeName.DIRECTORY:
        return <img src={DirectoryIcon} alt="DirectoryIcon" />;
    }
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.dashboard.createProjectTitle')} leftActionButtons={[backButton]} />
      {loading && <Loader />}
      {!loading && (
        <PageContent>
          <ProjectsRow>
            {organizationData &&
              templateTypeName.map(
                (name) =>
                  organizationData[name] && (
                    <TemplateTypePaper
                      key={name}
                      link={linksConstants.DASHBOARD.PROJECT_CREATOR[name]}
                      title={t(`pages.dashboard.projectType.${name}`)}
                      color={theme.palette.custom.templateTypes[name]}
                      icon={getTemplateTypeIcon(name)}
                      name={name}
                    />
                  )
              )}
          </ProjectsRow>
        </PageContent>
      )}
    </PageContainer>
  );
};

const ProjectsRow = styled.div`
  display: flex;
`;

export default CreateProjectPage;
