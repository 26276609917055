import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import {
  AdminCreateKeywordGroupValues,
  AdminCreateKeywordValues,
  AdminUpdateKeywordGroupValues,
  AdminUpdateKeywordValues,
  IndexAdminKeywordsRequestParams
} from './types';

export const index = (settings: RequestSettings<IndexAdminKeywordsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/keywords/individual?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{}, AdminCreateKeywordValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/keywords/individual'
  },
  payload: settings.requestPayload
});

export const update = (settings: RequestSettings<{}, AdminUpdateKeywordValues>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/keywords/individual/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteKeyword = (settings: RequestSettings<{ id: string }>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/keywords/individual/:id'
  },
  params: settings.requestParams
});

export const getAllKeywords = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/keywords/all'
  }
});

export const indexGroups = (settings: RequestSettings<IndexAdminKeywordsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/keywords/groups?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const createGroup = (settings: RequestSettings<{}, AdminCreateKeywordGroupValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/keywords/groups'
  },
  payload: settings.requestPayload
});

export const updateGroup = (settings: RequestSettings<{}, AdminUpdateKeywordGroupValues>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/keywords/groups/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteKeywordGroup = (settings: RequestSettings<{ id: string }>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/keywords/groups/:id'
  },
  params: settings.requestParams
});

export const addKeywordToGroup = (settings: RequestSettings<{ id: string }, { keywordId: string }>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/keywords/groups/:id/keyword'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeKeywordFromGroup = (
  settings: RequestSettings<{ id: string }, { keywordId: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/keywords/groups/:id/keyword'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});
