import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import Loader from '../../../../Shared/Loading/Loader';
import { DoAppPromotionClaim } from '../../../../../store/DoApp/PromotionClaims/types';
import { doAppPromotionClaimsOperations } from '../../../../../store/DoApp/PromotionClaims';
import { WidgetTitle } from '../../../../Shared/ListingWidget/WidgetTitle';
import { WidgetSearchInput } from '../../../../Shared/ListingWidget/WidgetSearchInput';
import { WidgetPagination } from '../../../../Shared/ListingWidget/WidgetPagination';
import { WidgetFetchError } from '../../../../Shared/ListingWidget/WidgetFetchError';
import { formatPublishDate } from '../../../../../utils';
import DoAppCreatePromotionClaimForm from './DoAppPromotionClaimForm';

type DoAppPromotionClaimsListProps = {};

const DoAppPromotionClaimsList: FunctionComponent<DoAppPromotionClaimsListProps> = () => {
  const { t } = useTranslation();
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [lastSearchValue, setLastSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [promotionClaims, setPromotionClaims] = useState<DoAppPromotionClaim[]>([]);
  const [activePromotion, setActivePromotion] = useState<DoAppPromotionClaim | null>(null);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 10, q: '', total: 0, totalPages: 0 });

  const fetchData = async (page: number, search: string) => {
    setFetchError(false);
    try {
      const promotionClaims = await doAppPromotionClaimsOperations.indexRaw(page, 10, search, {});
      setPromotionClaims(promotionClaims.data);
      setPagination(promotionClaims.pagination);
      setLastSearchValue(search);
    } catch (e) {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1, '');
  }, []);

  useEffect(() => {
    const onEnter = (e: any) => {
      if (e.key === 'Enter') {
        fetchData(1, searchValue);
      }
    };
    document.addEventListener('keydown', onEnter);
    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [searchValue]);

  const renderList = () => {
    return (
      <>
        <WidgetTitle
          title={t('pages.doApp.promotionClaims.title')}
          subtitle={t('pages.doApp.promotionClaims.subtitle')}
        />
        <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
        {loading && <Loader />}
        <WidgetSearchInput
          placeholder={`${t('common.search')}...`}
          value={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={fetchData}
        />

        {fetchError && <WidgetFetchError title={t('pages.doApp.promotionClaims.fetchError')} />}
        <List>
          {promotionClaims.map((claim) => (
            <React.Fragment key={claim.id}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  style={{
                    color: claim.acceptedAt ? 'green' : claim.rejection ? 'red' : 'black'
                  }}
                  primary={claim.promotion.name}
                  secondary={formatPublishDate(claim.createdAt)}
                />
                <ListItemText primary={claim.merchant.name} />
                <ListItemText primary={claim.subscriber.email} />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {pagination.total > 0 && (
            <WidgetPagination
              pagination={{ page: pagination.page, totalPages: pagination.totalPages }}
              handlePaginationChange={(val) => fetchData(val, searchValue)}
            />
          )}
        </div>
        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
        <DoAppCreatePromotionClaimForm onClaimCreated={() => fetchData(pagination.page, '')} />
      </>
    );
  };

  return renderList();
};

export default DoAppPromotionClaimsList;
