import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminKeywordGroup } from '../../../../../store/AdminKeywords/types';
import { adminKeywordsOperations } from '../../../../../store/AdminKeywords';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';
import Toast from '../../../../Shared/Toast/Toast';

type AdminDeleteKeywordGroupWindowProps = {
  open: boolean;
  group: AdminKeywordGroup | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminDeleteKeywordGroupWindow: FunctionComponent<AdminDeleteKeywordGroupWindowProps> = ({
  group,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!group) return null;

  const onSubmit = async () => {
    try {
      await dispatch(adminKeywordsOperations.deleteKeywordGroup(group.id));
      await dispatch(adminKeywordsOperations.indexGroups());
      onCloseClick();
      Toast.success(t('notifications.adminKeywords.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminKeywords.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminKeywords.windows.deleteKeywordGroup')}
      okButtonText={t('pages.adminKeywords.windows.completelyDelete')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.adminKeywords.windows.deleteKeywordGroupContent')}</Row>
      <Row>
        <Strong>{group.name}</Strong>
      </Row>
      <Row>
        <InfoBox type="warning">{t('pages.adminKeywords.windows.cannotBeUndone')}</InfoBox>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default AdminDeleteKeywordGroupWindow;
