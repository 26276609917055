import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Switch } from '@material-ui/core';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import { ReactComponent as ImageTopIcon } from '../../../../../../assets/icons/image_top.svg';
import { ReactComponent as ImageMiddleIcon } from '../../../../../../assets/icons/image_middle.svg';
import { ReactComponent as ImageBottomIcon } from '../../../../../../assets/icons/image_bottom.svg';
import styled from 'styled-components';
import ColorPicker from '../../../../../Shared/ColorPicker/ColorPicker';

type ArticleSettingsProps = {
  open: boolean;
  onCloseClick: () => void;
  onFormSubmit: (values: {
    imagePosition?: 'top' | 'middle' | 'bottom';
    headlineBackgroundColor: string;
    bodyBackgroundColor: string;
    backgroundColor: string;
    showHeadlineImage: boolean;
  }) => void;
  imagePosition?: 'top' | 'middle' | 'bottom';
  headlineBackgroundColor: string;
  bodyBackgroundColor: string;
  backgroundColor: string;
  showHeadlineImage: boolean;
};

const ArticleSettingsWindow: FunctionComponent<ArticleSettingsProps> = ({
  open,
  onCloseClick,
  onFormSubmit,
  imagePosition,
  headlineBackgroundColor,
  bodyBackgroundColor,
  backgroundColor,
  showHeadlineImage
}) => {
  const { t } = useTranslation();

  const [imagePositionValue, setImagePositionValue] = useState<'top' | 'middle' | 'bottom'>(imagePosition || 'top');
  const handleImagePositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImagePositionValue((event.target as HTMLInputElement).value as 'top' | 'middle' | 'bottom');
  };

  const [bg, setBg] = useState<string>(backgroundColor);
  const [headlineBg, setHeadlineBg] = useState<string>(headlineBackgroundColor);
  const [bodyBg, setBodyBg] = useState<string>(bodyBackgroundColor);
  const [headlineImage, setHeadlineImage] = useState<boolean>(showHeadlineImage);

  const handleHeadlineImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeadlineImage(event.target.checked);
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.emailEditor.articleSettings.header')}
      onCloseClick={onCloseClick}
      onSubmit={() =>
        onFormSubmit({
          imagePosition: imagePositionValue,
          headlineBackgroundColor: headlineBg,
          bodyBackgroundColor: bodyBg,
          backgroundColor: bg,
          showHeadlineImage: headlineImage
        })
      }
    >
      {imagePosition && (
        <fieldset
          style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
        >
          <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
            {t('pages.emailEditor.articleSettings.structure')}
          </legend>
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="headline-position"
                name="headline-position"
                value={imagePositionValue}
                onChange={handleImagePositionChange}
                row
              >
                <RadioContainer>
                  <Radio value="top" />
                  <ImageMiddleIcon onClick={() => setImagePositionValue('top')} />
                </RadioContainer>
                <RadioContainer>
                  <Radio value="middle" />
                  <ImageTopIcon onClick={() => setImagePositionValue('middle')} />
                </RadioContainer>
                <RadioContainer>
                  <Radio value="bottom" />
                  <ImageBottomIcon onClick={() => setImagePositionValue('bottom')} />
                </RadioContainer>
              </RadioGroup>
            </FormControl>
          </Box>
        </fieldset>
      )}
      <FormControlLabel
        control={<Switch checked={headlineImage} onChange={handleHeadlineImageChange} name="headlineImage" />}
        label={t('pages.emailEditor.articleSettings.headlineImage')}
      />
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.articleSettings.backgroundColors')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.articleSettings.general')}:</div>
            <ColorPicker color={bg} onChange={setBg} />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.articleSettings.headline')}:</div>
            <ColorPicker color={headlineBg} onChange={setHeadlineBg} />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.articleSettings.body')}:</div>
            <ColorPicker color={bodyBg} onChange={setBodyBg} />
          </Grid>
        </Grid>
      </fieldset>
    </ModalConfirmationWindow>
  );
};

const RadioContainer = styled.div`
  width: 100px;
  text-align: center;
  margin: 0 10px;
  cursor: pointer;
`;

export default ArticleSettingsWindow;
