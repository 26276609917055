import { createOrderArray, httpRequest } from '../../utils';
import { saveAs } from 'file-saver';
import {
  AdminCreatePrintTemplateValues,
  AdminPrintTemplate,
  AdminPrintTemplatesIndexResponse,
  AdminUpdatePrintTemplateValues
} from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { adminPrintTemplatesActions, adminPrintTemplatesRequests } from './index';
import { createUrl } from '../../utils/httpRequest';
import { AdminKeyword, AdminKeywordGroup } from '../AdminKeywords/types';

export const index = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { adminPrintTemplates } = getState();

  try {
    dispatch(adminPrintTemplatesActions.templatesIndexBegin());

    const response = await httpRequest.runRequest<AdminPrintTemplatesIndexResponse>(
      adminPrintTemplatesRequests.index({
        requestParams: {
          page: adminPrintTemplates.pagination.page,
          per: adminPrintTemplates.pagination.per,
          q: adminPrintTemplates.pagination.q,
          order: createOrderArray(adminPrintTemplates.tableSearch.order)
        }
      })
    );

    dispatch(adminPrintTemplatesActions.templatesIndexSuccess(response));
  } catch (e) {
    dispatch(adminPrintTemplatesActions.templatesIndexFailed(e));
  }
};

export const create = (values: AdminCreatePrintTemplateValues) => async () => {
  return await httpRequest.runRequest<void>(
    adminPrintTemplatesRequests.create({
      requestPayload: values
    })
  );
};

export const update = (id: string, values: AdminUpdatePrintTemplateValues) => async () => {
  return await httpRequest.runRequest<void>(
    adminPrintTemplatesRequests.update({
      requestPayload: values,
      requestParams: { id }
    })
  );
};

export const deletePrintTemplate = (templateId: string) => async () => {
  return await httpRequest.runRequest<void>(
    adminPrintTemplatesRequests.deletePrintTemplate({
      requestParams: { id: templateId }
    })
  );
};

export const downloadPrintTemplateZip = async (templateId: string) => {
  const url = createUrl(
    adminPrintTemplatesRequests.downloadPrintTemplate({
      requestParams: { id: templateId }
    }).query,
    { id: templateId }
  );
  await saveAs(url);
};

export const uploadPrintTemplateZip = async (
  zipFile: File,
  template: AdminPrintTemplate,
  onProgress: (progress: number) => void
) => {
  const formData = new FormData();

  formData.append('file', zipFile);
  formData.append('filename', zipFile.name);

  return await httpRequest.runRequest<void>({
    ...adminPrintTemplatesRequests.uploadPrintTemplateZip({
      requestParams: { id: template.id },
      requestPayload: formData
    }),
    onUploadProgress: (progressEvent) => {
      const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
      onProgress(progress);
    }
  });
};

export const addKeywordToPrintTemplate = (template: AdminPrintTemplate, keyword: AdminKeyword) => async (
  dispatch: Dispatch
) => {
  const updatedPrintTemplate = await httpRequest.runRequest<AdminPrintTemplate>(
    adminPrintTemplatesRequests.addKeywordToPrintTemplate({
      requestParams: { templateId: template.id },
      requestPayload: { keywordId: keyword.id }
    })
  );
  dispatch(adminPrintTemplatesActions.keywordAdded(updatedPrintTemplate));
};

export const addKeywordGroupToPrintTemplate = (template: AdminPrintTemplate, group: AdminKeywordGroup) => async (
  dispatch: Dispatch
) => {
  const updatedPrintTemplate = await httpRequest.runRequest<AdminPrintTemplate>(
    adminPrintTemplatesRequests.addKeywordGroupToPrintTemplate({
      requestParams: { templateId: template.id },
      requestPayload: { keywordGroupId: group.id }
    })
  );
  dispatch(adminPrintTemplatesActions.keywordGroupAdded(updatedPrintTemplate));
};

export const removeKeywordFromPrintTemplate = (template: AdminPrintTemplate, keyword: AdminKeyword) => async (
  dispatch: Dispatch
) => {
  const updatedPrintTemplate = await httpRequest.runRequest<AdminPrintTemplate>(
    adminPrintTemplatesRequests.removeKeywordFromPrintTemplate({
      requestParams: { templateId: template.id },
      requestPayload: { keywordId: keyword.id }
    })
  );
  dispatch(adminPrintTemplatesActions.keywordRemoved(updatedPrintTemplate));
};

export const removeKeywordGroupFromPrintTemplate = (template: AdminPrintTemplate, group: AdminKeywordGroup) => async (
  dispatch: Dispatch
) => {
  const updatedPrintTemplate = await httpRequest.runRequest<AdminPrintTemplate>(
    adminPrintTemplatesRequests.removeKeywordGroupFromPrintTemplate({
      requestParams: { templateId: template.id },
      requestPayload: { keywordGroupId: group.id }
    })
  );
  dispatch(adminPrintTemplatesActions.keywordGroupRemoved(updatedPrintTemplate));
};

export const getPrintTemplateThumbnail = async (templateId: string) => {
  return await httpRequest.runRequest<{ url: string }>(
    adminPrintTemplatesRequests.getPrintTemplateThumbnail({
      requestParams: { id: templateId }
    })
  );
};

export const uploadPrintTemplateThumbnail = async (
  imageFile: File,
  template: AdminPrintTemplate,
  onProgress: (progress: number) => void
) => {
  const formData = new FormData();

  formData.append('file', imageFile);

  return await httpRequest.runRequest<void>({
    ...adminPrintTemplatesRequests.uploadPrintTemplateThumbnail({
      requestParams: { id: template.id },
      requestPayload: formData
    }),
    onUploadProgress: (progressEvent) => {
      const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
      onProgress(progress);
    }
  });
};
