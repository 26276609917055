import DoAppDashboardPage from './DoAppDashboardPage';
import { ApplicationRoute } from '../../../../../typings';
import linksConstants from '../../../../../config/app/linksConstants';

const DoAppDashboardRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.DO_APP.DASHBOARD,
    exact: true,
    name: 'doAppDashboard',
    public: false,
    show: true,
    component: DoAppDashboardPage,
    scope: []
  }
];

export default DoAppDashboardRoutes;
