import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import FlipBookPage from './FlipBookPage';
import FlipBookPublicPage from './FlipBookPublicPage';

export const FlipBookRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.PLAYGROUND.FLIP_BOOK(':projectId'),
    exact: false,
    name: 'flipBookPreview',
    public: false,
    show: true,
    component: FlipBookPage,
    scope: []
  }
];

export const FlipBookPublicRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.FLIPBOOK_PUBLIC,
    exact: true,
    name: 'flipBookPublic',
    public: true,
    show: true,
    component: FlipBookPublicPage,
    scope: []
  }
];
