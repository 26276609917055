import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createNetworkErrorObject, useTypedSelector } from '../../../utils';
import { usePaginationWatch } from '../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../Shared/DataTable/useTableSearchWatch';
import { PageContainer, PageContent } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import DataTable from '../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import { TableSearchStoreModule } from '../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../store/Pagination/types';
import linksConstants from '../../../config/app/linksConstants';
import { Campaign } from '../../../store/Campaigns/types';
import { TableLink } from '../Admin/Organizations/AdminOrganizationsPage';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RouteComponentProps } from 'react-router';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { designProjectsOperations } from '../../../store/DesignProjects';
import { DesignProject } from '../../../store/DesignProjects/types';
import Toast from '../../Shared/Toast/Toast';
import { storiesOperations } from '../../../store/Stories';
import { StoryChannel } from '../../../store/Stories/types';
import styled from 'styled-components';
import { Alert } from '@material-ui/lab';

type StoryDesignsPageProps = RouteComponentProps<{ channelId: string; storyId: string }>;

const StoryDesignsPage: FunctionComponent<StoryDesignsPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const designProjects = useTypedSelector((state) => state.designProjects.list);
  const isFailed = useTypedSelector((state) => state.designProjects.index.isFailed);
  const isLoading = useTypedSelector((state) => state.designProjects.index.isLoading);
  const pagination = useTypedSelector((state) => state.designProjects.pagination);
  const tableSearch = useTypedSelector((state) => state.designProjects.tableSearch);
  const [loading, setLoading] = useState(true);
  const [storyChannel, setStoryChannel] = useState<StoryChannel | null>(null);

  usePaginationWatch(pagination, [designProjectsOperations.index]);
  useTableSearchWatch<Campaign>(tableSearch, [designProjectsOperations.index]);

  useEffect(() => {
    if (match.params.storyId) {
      fetchStoryData();
    } else {
      setLoading(false);
    }
  }, []);

  const fetchStoryData = async () => {
    try {
      const storyData = await storiesOperations.show(match.params.storyId);
      setStoryChannel(storyData.channels.find((channel) => channel.id === match.params.channelId) || null);
    } catch (e) {
      const error = createNetworkErrorObject(e);

      switch (error.statusCode) {
        case 401:
          return Toast.info(t('notifications.common.loginFirst'));
        default:
          return Toast.error(t('notifications.designProjects.cannotFetchStory'));
      }
    } finally {
      setLoading(false);
    }
  };

  const backButton = {
    onClick: () => dispatch(push(linksConstants.STORY.EDIT(match.params.storyId))),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const associateToChannel = async (designProjectId: string) => {
    try {
      await designProjectsOperations.associateToChannel(designProjectId, match.params.channelId);
      dispatch(push(linksConstants.STORY.EDIT(match.params.storyId)));
      Toast.success(t('notifications.designProjects.projectAssociated'));
    } catch (e) {
      Toast.error(t('notifications.designProjects.errorOperation'));
    }
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.designProjects.heading')} leftActionButtons={[backButton]} />
      <PageContent>
        <DataTable<DesignProject, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'name',
              sortable: true,
              style: { width: '100%' },
              render: (designProject) => (
                <>
                  <TableLink onClick={() => dispatch(push(linksConstants.PROJECTS.DESIGN.EDIT(designProject.id)))}>
                    {designProject.name}
                  </TableLink>
                </>
              )
            },
            {
              name: 'actions',
              style: { whiteSpace: 'nowrap' },
              render: (designProject) => (
                <TableActionButtonsContainer>
                  {!loading && (
                    <>
                      {storyChannel?.attachments.find((a) => a.printDesignId === designProject.id) ? (
                        <StyledAlert severity="success" variant="outlined">
                          {t('pages.designProjects.alreadyAdded')}
                        </StyledAlert>
                      ) : (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => associateToChannel(designProject.id)}
                          size="small"
                          // disabled={!hasPermission(role, ['designProjectsEdit'])}
                        >
                          {t('pages.designProjects.selectThisDesign')}
                        </Button>
                      )}
                    </>
                  )}
                </TableActionButtonsContainer>
              )
            }
          ]}
          tPath={'pages.designProjects.table'}
          data={designProjects}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.DESIGN_PROJECTS
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.DESIGN_PROJECTS
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>
    </PageContainer>
  );
};

export const StyledAlert = styled(Alert)`
  .MuiAlert-icon {
    font-size: 16px;
  }

  .MuiAlert-message {
    padding: 5px 0;
  }

  padding: 0 6px;
`;

export default StoryDesignsPage;
