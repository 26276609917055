export const createOrderArray = (
  orderData: { [x: string]: { order: 'desc' | 'asc'; name: string } | undefined },
  defaultSorting?: string[]
) => {
  const defaults = defaultSorting || ['order=createdAt,desc'];

  if (orderData) {
    const entries = Object.entries(orderData);

    const orderStrings = entries
      /** Filter out falsy orders */
      .filter(([, order]) => Boolean(order))
      /** Transform order data into string values */
      .map(([column, order]) => `order=${column},${order && order.order}`);

    if (orderStrings.length) {
      return orderStrings;
    }
  }

  return defaults;
};
