import { TemplateTypeName } from '../store/Templates/types';
import { AnyProject } from '../store/Dashboard/types';
import { EmailProject } from '../store/EmailProjects/types';
import { PrintProject } from '../store/PrintProjects/types';
import { SharedProject } from '../store/SharingCenter/types';
import { Story } from '../store/Stories/types';

export const printTemplateTypes = [
  TemplateTypeName.BULLETIN,
  TemplateTypeName.DIRECTORY,
  TemplateTypeName.FLYER,
  TemplateTypeName.NEWSLETTER
];

export const emailTemplateTypes = [TemplateTypeName.EMAIL];

export const isEmailProject = (project: AnyProject): project is EmailProject => {
  return project.kind === 'email';
};
export const isPrintProject = (project: AnyProject): project is PrintProject => {
  return project.kind === 'print';
};

export const isSharedProject = (project: SharedProject | AnyProject): project is SharedProject => {
  return project.shareContext === true;
};

export const isStory = (project: AnyProject | Story): project is Story => {
  return Array.isArray((project as Story).channels) || project.kind === 'story';
};
