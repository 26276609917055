import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageData } from '../../types';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../../Shared/Window/ModalFormWindow';
import { AdminCheckboxInput, AdminTextInput } from '../../../../Admin/Shared/AdminFormInputs';
import { Grid } from '@material-ui/core';

type EditImageLinkWindowProps = {
  open: boolean;
  data: ImageData;
  onCloseClick: () => void;
  onFormSubmit: (
    link: string,
    hasCaption: boolean,
    borderTopLeftRadius: string,
    borderTopRightRadius: string,
    borderBottomLeftRadius: string,
    borderBottomRightRadius: string
  ) => void;
  fullScreenOnMobile: boolean;
};

const EditImageLinkWindow: FunctionComponent<EditImageLinkWindowProps> = ({
  onCloseClick,
  onFormSubmit,
  open,
  data
}) => {
  const { t } = useTranslation();

  if (!data) return null;

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.submit')}
      header={t('pages.emailEditor.editImageLinkWindow.imageOptions')}
      onCloseClick={onCloseClick}
      onSubmit={(values) => {
        onFormSubmit(
          values.link,
          values.hasCaption,
          values.borderTopLeftRadius,
          values.borderTopRightRadius,
          values.borderBottomLeftRadius,
          values.borderBottomRightRadius
        );
      }}
      formInputs={() => {
        return (
          <>
            <AdminTextInput t={t} name="link" section="emailEditor.editImageLinkWindow" />
            <AdminCheckboxInput t={t} name="hasCaption" section="emailEditor.editImageLinkWindow" />
            <fieldset
              style={{
                border: '1px solid #ccc',
                padding: '10px',
                margin: '10px 0',
                borderRadius: '4px',
                width: '100%'
              }}
            >
              <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
                {t('pages.emailEditor.editImageLinkWindow.borderRadius')}
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <AdminTextInput t={t} name="borderTopLeftRadius" section="emailEditor.editImageLinkWindow" />
                </Grid>
                <Grid item xs={3}>
                  <AdminTextInput t={t} name="borderTopRightRadius" section="emailEditor.editImageLinkWindow" />
                </Grid>
                <Grid item xs={3}>
                  <AdminTextInput t={t} name="borderBottomLeftRadius" section="emailEditor.editImageLinkWindow" />
                </Grid>
                <Grid item xs={3}>
                  <AdminTextInput t={t} name="borderBottomRightRadius" section="emailEditor.editImageLinkWindow" />
                </Grid>
              </Grid>
            </fieldset>
          </>
        );
      }}
      initialValues={{
        link: data.linkUrl,
        hasCaption: data.hasCaption,
        borderTopLeftRadius: data.borderTopLeftRadius,
        borderTopRightRadius: data.borderTopRightRadius,
        borderBottomLeftRadius: data.borderBottomLeftRadius,
        borderBottomRightRadius: data.borderBottomRightRadius
      }}
      validationSchema={Yup.object().shape({
        link: Yup.lazy((value: any) => {
          if (!value) {
            return Yup.string().url();
          }
          if (value.startsWith('<')) {
            return Yup.string();
          }
          return Yup.string().url();
        }),
        hasCaption: Yup.boolean(),
        borderTopLeftRadius: Yup.string().matches(/^[0-9]+$/, t('pages.emailEditor.editImageLinkWindow.mustBeANumber')),
        borderTopRightRadius: Yup.string().matches(
          /^[0-9]+$/,
          t('pages.emailEditor.editImageLinkWindow.mustBeANumber')
        ),
        borderBottomLeftRadius: Yup.string().matches(
          /^[0-9]+$/,
          t('pages.emailEditor.editImageLinkWindow.mustBeANumber')
        ),
        borderBottomRightRadius: Yup.string().matches(
          /^[0-9]+$/,
          t('pages.emailEditor.editImageLinkWindow.mustBeANumber')
        )
      })}
    />
  );
};

export default EditImageLinkWindow;
