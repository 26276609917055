import React, { FunctionComponent } from 'react';
import { Checkbox, TextField } from '@material-ui/core';
import { FilterHeader } from '../../AdminStyledComponents';
import { FilterCheckboxWithStringValue } from '../../../../../store/AdminProjects/types';

type FilterCheckboxWithInputProps = {
  header: string;
  filter: FilterCheckboxWithStringValue;
  onChange: (data: FilterCheckboxWithStringValue) => void;
};

const FilterCheckboxWithInput: FunctionComponent<FilterCheckboxWithInputProps> = ({ filter, header, onChange }) => {
  return (
    <>
      <FilterHeader>{header}</FilterHeader>
      <div>
        <Checkbox
          checked={filter.enabled}
          onChange={() =>
            onChange({
              enabled: !filter.enabled,
              value: filter.value
            })
          }
        />
        <TextField
          label={header}
          variant="outlined"
          size="small"
          disabled={!filter.enabled}
          value={filter.value || ''}
          onChange={(e) =>
            onChange({
              enabled: filter.enabled,
              value: e.target.value || ''
            })
          }
        />
      </div>
    </>
  );
};

export default FilterCheckboxWithInput;
