import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { DoAppCreditTransaction } from '../../../../store/DoApp/CreditTransactions/types';
import { doAppCreditTransactionsOperations } from '../../../../store/DoApp/CreditTransactions';

type CancelDisputeCreditTransactionWindowProps = {
  open: boolean;
  creditTransaction: DoAppCreditTransaction;
  onCloseClick: () => void;
  onSubmit: () => void;
  fullScreenOnMobile: boolean;
};

const CancelDisputeCreditTransactionWindow: FunctionComponent<CancelDisputeCreditTransactionWindowProps> = ({
  onCloseClick,
  onSubmit,
  creditTransaction,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onFormSubmit = async () => {
    try {
      await dispatch(doAppCreditTransactionsOperations.unReject(creditTransaction.id));
      onCloseClick();
      onSubmit();
      Toast.success(t('notifications.cancelDisputeCreditTransaction.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.cancelDisputeCreditTransaction.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.myOrganization.windows.cancelDisputeCreditTransaction.title')}
      okButtonText={t('pages.myOrganization.windows.cancelDisputeCreditTransaction.submit')}
      onCloseClick={onCloseClick}
      onSubmit={onFormSubmit}
    >
      <Row>
        {t('pages.myOrganization.windows.cancelDisputeCreditTransaction.subscriber')}:{' '}
        <Strong>{creditTransaction.subscriber.email}</Strong>
        <br />
        {t('pages.myOrganization.windows.cancelDisputeCreditTransaction.merchant')}:{' '}
        <Strong>{creditTransaction.merchant.name}</Strong>
        <br />
        {t('pages.myOrganization.windows.cancelDisputeCreditTransaction.amount')}:{' '}
        <Strong>{`$${(creditTransaction.amount / 100).toFixed(2)}`}</Strong>
      </Row>
      <Row>
        {t('pages.myOrganization.windows.cancelDisputeCreditTransaction.reason')}:{' '}
        <Strong>{creditTransaction.rejection?.comment}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default CancelDisputeCreditTransactionWindow;
