import React, { FunctionComponent, useEffect, useState } from 'react';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Toast from '../../../Shared/Toast/Toast';
import { TextField } from '@material-ui/core';
import { createNetworkErrorObject, useTypedSelector } from '../../../../utils';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import styled from 'styled-components';
import { hexToRgb } from '../../../../utils/colors';
import Loader from '../../../Shared/Loading/Loader';
import { MyOrganizationSwatchColor } from '../../../../store/MyOrganization/types';
import ColorPickerSlider from '../Partials/ColorPickerSlider';

type CreateOrUpdateSwatchColorWindowProps = {
  type: 'create' | 'update';
  open: boolean;
  onCloseClick: () => void;
  onSubmit: () => void;
  fullScreenOnMobile: boolean;
  swatchColor?: MyOrganizationSwatchColor;
};

let debounceTimeout: number | null = null;

const CreateOrUpdateSwatchColorWindow: FunctionComponent<CreateOrUpdateSwatchColorWindowProps> = ({
  onCloseClick,
  onSubmit,
  open,
  swatchColor,
  type
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const colorGuidePdfUrl = useTypedSelector((state) => state.auth.colorGuidePdfUrl);
  const currentOrganizationId = useTypedSelector((state) => state.auth.organizationId);

  const [loading, setLoading] = useState<boolean>(false);
  const [hex, setHex] = useState<string>('#000000');
  const [red, setRed] = useState<number>(0);
  const [green, setGreen] = useState<number>(0);
  const [blue, setBlue] = useState<number>(0);
  const [cyan, setCyan] = useState<number>(0);
  const [magenta, setMagenta] = useState<number>(0);
  const [yellow, setYellow] = useState<number>(0);
  const [black, setBlack] = useState<number>(100);
  const [opacity, setOpacity] = useState<number>(100);
  const [colorName, setColorName] = useState<string>('');

  useEffect(() => {
    if (open) {
      resetAllValues();
    }
  }, [open]);

  useEffect(() => {
    if (swatchColor) {
      setColorName(swatchColor.name);
      setHex(swatchColor.hex);
      setRed(swatchColor.red);
      setGreen(swatchColor.green);
      setBlue(swatchColor.blue);
      setCyan(swatchColor.cyan);
      setMagenta(swatchColor.magenta);
      setYellow(swatchColor.yellow);
      setBlack(swatchColor.black);
      setOpacity(swatchColor.opacity);
    }
  }, [swatchColor]);

  const onFormSubmit = async () => {
    try {
      if (currentOrganizationId) {
        if (type === 'create') {
          await dispatch(
            myOrganizationOperations.createOrganizationSwatchColor({
              name: colorName.trim(),
              hex,
              red,
              green,
              blue,
              cyan,
              magenta,
              yellow,
              black,
              opacity
            })
          );
        }

        if (type === 'update' && swatchColor) {
          await dispatch(
            myOrganizationOperations.updateOrganizationSwatchColor(swatchColor.id, {
              name: colorName.trim(),
              hex,
              red,
              green,
              blue,
              cyan,
              magenta,
              yellow,
              black,
              opacity
            })
          );
        }

        resetAllValues();
        onCloseClick();
        onSubmit();
        Toast.success(t('notifications.myOrganization.successOperation'));
      }
    } catch (e) {
      const errorOb = createNetworkErrorObject(e);
      if (errorOb.message === 'colors_limit_reached') {
        Toast.info(t('notifications.myOrganization.colorLimitReached'));
      } else {
        Toast.error(t('notifications.myOrganization.errorOperation'));
      }
    }
  };

  const resetAllValues = () => {
    setHex('#000000');
    setRed(0);
    setGreen(0);
    setBlue(0);
    setCyan(0);
    setMagenta(0);
    setYellow(0);
    setBlack(100);
    setOpacity(100);
    setColorName('');
  };

  const convertCMYKtoRGB = (c: number, m: number, y: number, k: number) => {
    setLoading(true);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(async () => {
      const response = await myOrganizationOperations.convertCmykToHex([c, m, y, k].join(','));

      if (response.hex) {
        const rgb = hexToRgb(response.hex);
        if (rgb) {
          setRed(rgb.r);
          setGreen(rgb.g);
          setBlue(rgb.b);
        }

        setHex(response.hex);
        setOpacity(100);
        setCyan(c);
        setMagenta(m);
        setYellow(y);
        setBlack(k);
      }
      setLoading(false);
    }, 2000);
  };

  const getPickerContent = () => (
    <div style={{ display: 'flex' }}>
      <div style={{ margin: '1em', width: '100%' }}>
        <TextField
          placeholder={t('pages.myOrganization.inputs.colorPlaceholder')}
          label={t('pages.myOrganization.inputs.color')}
          type="text"
          variant="outlined"
          size="small"
          value={colorName}
          fullWidth
          inputProps={{
            maxLength: 255
          }}
          onChange={(e) => setColorName(e.target.value)}
        />
        <div style={{ display: 'flex', marginTop: '1em' }}>
          <ColorPreviewWrapper>
            {loading && (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            )}
            <legend>{t('pages.myOrganization.rgbPreview')}</legend>
            <ColorPreview style={{ backgroundColor: hex }} />
          </ColorPreviewWrapper>
        </div>
        <div style={{ marginTop: '1em' }}>
          <ColorPickerSlider
            value={cyan}
            label="C"
            color="#55FFFE"
            onValueChange={(val) => {
              setCyan(val);
              convertCMYKtoRGB(val, magenta, yellow, black);
            }}
          />
          <ColorPickerSlider
            value={magenta}
            label="M"
            color="#F627FF"
            onValueChange={(val) => {
              setMagenta(val);
              convertCMYKtoRGB(cyan, val, yellow, black);
            }}
          />
          <ColorPickerSlider
            value={yellow}
            label="Y"
            color="#FDFD02"
            onValueChange={(val) => {
              setYellow(val);
              convertCMYKtoRGB(cyan, magenta, val, black);
            }}
          />
          <ColorPickerSlider
            value={black}
            label="K"
            color="#000000"
            onValueChange={(val) => {
              setBlack(val);
              convertCMYKtoRGB(cyan, magenta, yellow, val);
            }}
          />
        </div>
        <div style={{ marginTop: '1em' }}>
          Need help matching your organization&apos;s brand colors? Click{' '}
          <PseudoLink onClick={() => window.open(colorGuidePdfUrl)}>here</PseudoLink> to view a pdf of our standard
          swatch recommendations, or call us for support at (800) 950-9952 ext. 2222.
        </div>
      </div>
    </div>
  );

  return (
    <ModalConfirmationWindow
      onCloseClick={onCloseClick}
      open={open}
      header={
        type === 'create'
          ? t('pages.myOrganization.windows.createOrganizationSwatchColor')
          : t('pages.myOrganization.windows.updateOrganizationSwatchColor')
      }
      onSubmit={onFormSubmit}
      okButtonText={type === 'create' ? t('common.create') : t('common.update')}
      disableSubmitButton={
        Math.max(cyan, magenta, yellow, black) > 100 || Math.min(cyan, magenta, yellow, black) < 0 || loading
      }
    >
      {getPickerContent()}
    </ModalConfirmationWindow>
  );
};

const LoaderWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ColorPreviewWrapper = styled.fieldset`
  position: relative;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 0 8px;

  legend {
    background: #fff;
    padding: 0 5px;
  }
`;

const ColorPreview = styled.div`
  width: 100%;
  height: 100px;
  margin: 5px 0 8px;
  border-radius: 4px;
`;

const PseudoLink = styled.span`
  color: #2aa5aa;
  text-decoration: underline;
  cursor: pointer;
`;

export default CreateOrUpdateSwatchColorWindow;
