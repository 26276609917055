import React from 'react';
import { FullWidthTableCell } from '../DataTableStyled';
import { TableCell, TableRow } from '@material-ui/core';
import { DataTableInfoBodyProps } from '../types';
import { useTranslation } from 'react-i18next';

export const FailedData: React.FC<DataTableInfoBodyProps> = ({ columnAmount }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell colSpan={columnAmount}>
        <FullWidthTableCell>{t('table.failedData')}</FullWidthTableCell>
      </TableCell>
    </TableRow>
  );
};
