import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import { formatTableTimestamp, useTypedSelector } from '../../../../utils';
import { usePaginationWatch } from '../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../Shared/DataTable/useTableSearchWatch';
import { PaginationStoreModule } from '../../../../store/Pagination/types';
import DataTable from '../../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../../Shared/DataTable/TableActionButton/TableActionButton';
import { TableSearchStoreModule } from '../../../../store/TableSearch/types';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import AdminCreateKeywordGroupWindow from './Windows/AdminCreateKeywordGroupWindow';
import AdminUpdateKeywordGroupWindow from './Windows/AdminUpdateKeywordGroupWindow';
import AdminDeleteKeywordGroupWindow from './Windows/AdminDeleteKeywordGroupWindow';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { adminKeywordsOperations } from '../../../../store/AdminKeywords';
import { AdminKeyword, AdminKeywordGroup } from '../../../../store/AdminKeywords/types';
import { hasPermission } from '../../../../utils/permissions';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useDispatch } from 'react-redux';
import AdminKeywordsList from '../../../Shared/Tags/AdminKeywordsList';

type AdminKeywordGroupsPageProps = {};

const AdminKeywordGroupsPage: FunctionComponent<AdminKeywordGroupsPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);
  const groups = useTypedSelector((state) => state.adminKeywordGroups.groups);
  const isFailed = useTypedSelector((state) => state.adminKeywordGroups.index.isFailed);
  const isLoading = useTypedSelector((state) => state.adminKeywordGroups.index.isLoading);
  const pagination = useTypedSelector((state) => state.adminKeywordGroups.pagination);
  const tableSearch = useTypedSelector((state) => state.adminKeywordGroups.tableSearch);
  const allKeywords = useTypedSelector((state) => state.adminKeywords.all);

  usePaginationWatch(pagination, [adminKeywordsOperations.indexGroups]);
  useTableSearchWatch<AdminKeywordGroup>(tableSearch, [adminKeywordsOperations.indexGroups]);

  const [activeGroup, setActiveGroup] = useState<AdminKeywordGroup | null>(null);
  const [createGroupWindowOpen, onCreateGroupWindowOpen, onCreateGroupWindowClose] = useOpenHandler();
  const [updateGroupWindowOpen, onUpdateGroupWindowOpen, onUpdateGroupWindowClose] = useOpenHandler();
  const [deleteGroupWindowOpen, onDeleteGroupWindowOpen, onDeleteGroupWindowClose] = useOpenHandler();

  useEffect(() => {
    const fetchKeywords = async () => {
      await dispatch(adminKeywordsOperations.getAllKeywords());
    };
    fetchKeywords();
  }, []);

  const getActionButtons = () => {
    if (hasPermission(role, ['keywordsAdd'])) {
      return [
        {
          label: t('pages.adminKeywords.createKeywordGroup'),
          icon: <AddIcon />,
          onClick: onCreateGroupWindowOpen
        }
      ];
    }

    return [];
  };

  const backButton = {
    onClick: () => dispatch(push(linksConstants.ADMINISTRATION.KEYWORDS.INDEX)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const onAddKeywordToGroup = useCallback((group: AdminKeywordGroup, keyword: AdminKeyword) => {
    dispatch(adminKeywordsOperations.addKeywordToGroup(group, keyword));
  }, []);

  const onDeleteKeywordFromGroup = useCallback((group: AdminKeywordGroup, keyword: AdminKeyword) => {
    dispatch(adminKeywordsOperations.removeKeywordFromGroup(group, keyword));
  }, []);

  return (
    <PageContainer>
      <PageHeader
        title={t('pages.adminKeywords.titleGroups')}
        rightActionButtons={getActionButtons()}
        leftActionButtons={[backButton]}
      />
      <PageContent>
        <DataTable<AdminKeywordGroup, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'name',
              sortable: true,
              render: (group) => <div>{group.name}</div>
            },
            {
              name: 'actions',
              render: (group) => (
                <TableActionButtonsContainer>
                  <TableActionButton
                    icon={<EditIcon />}
                    tooltip={t('pages.adminKeywords.table.buttons.edit')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['keywordsAdd']),
                      onClick: () => {
                        setActiveGroup(group);
                        onUpdateGroupWindowOpen();
                      }
                    }}
                  />
                  <TableActionButton
                    icon={<DeleteIcon />}
                    tooltip={t('pages.adminKeywords.table.buttons.delete')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['keywordsRemove']),
                      onClick: () => {
                        setActiveGroup(group);
                        onDeleteGroupWindowOpen();
                      }
                    }}
                  />
                </TableActionButtonsContainer>
              )
            },
            {
              name: 'keywords',
              render: (group) => (
                <AdminKeywordsList
                  readOnly={!hasPermission(role, ['keywordsAssociate'])}
                  allKeywords={{
                    keywords: allKeywords.keywords,
                    groups: []
                  }}
                  keywords={group.keywords}
                  groups={[]}
                  onDelete={
                    hasPermission(role, ['keywordsAssociate'])
                      ? (keyword: AdminKeyword) => onDeleteKeywordFromGroup(group, keyword)
                      : null
                  }
                  onDeleteGroup={(g) => {
                    /** noop */
                  }}
                  onAdd={(keyword: AdminKeyword) => onAddKeywordToGroup(group, keyword)}
                  onAddGroup={(g) => {
                    /** noop */
                  }}
                />
              )
            },
            {
              name: 'createdAt',
              render: (group) => <>{formatTableTimestamp(group.createdAt)}</>,
              sortable: true
            }
          ]}
          tPath={'pages.adminKeywords.tableGroups'}
          data={groups}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.ADMIN_KEYWORD_GROUPS
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.ADMIN_KEYWORD_GROUPS
            // onPageChange: () => {},
            // onPerChange: () => {}
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>

      <AdminCreateKeywordGroupWindow
        open={createGroupWindowOpen}
        onCloseClick={onCreateGroupWindowClose}
        fullScreenOnMobile
      />
      <AdminUpdateKeywordGroupWindow
        group={activeGroup}
        open={updateGroupWindowOpen}
        onCloseClick={onUpdateGroupWindowClose}
        fullScreenOnMobile
      />
      <AdminDeleteKeywordGroupWindow
        group={activeGroup}
        open={deleteGroupWindowOpen}
        onCloseClick={onDeleteGroupWindowClose}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

export default AdminKeywordGroupsPage;
