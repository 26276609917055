import React, { FunctionComponent, useEffect, useState } from 'react';
import { DesignerStory, PrintProject } from '../../../../../store/PrintProjects/types';
import { Row } from '../../../../Shared/StyledComponents';
import { useTranslation } from 'react-i18next';
import DesignerArticle from './Items/DesignerArticle';
import Loader from '../../../../Shared/Loading/Loader';
import Toast from '../../../../Shared/Toast/Toast';
import { printProjectsOperations } from '../../../../../store/PrintProjects';
import { Button, Divider, List, ListItemText, Paper, Typography } from '@material-ui/core';
import { Template } from '../../../../../store/Templates/types';
import styled from 'styled-components';
import TemplateThumbnail from '../../../../Shared/Thumbnail/TemplateThumbnail';
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';
import SelectArticleImageWindow from './Items/SelectArticleImageWindow';
import useOpenHandler from '../../../../../hooks/useOpenHandler';

type PrintPublishArticlesSelectionStepProps = {
  project: PrintProject;
  onArticlesChoose: (articles: { name: string; selectedImageIndex: number }[]) => void;
  chosenArticles: { name: string; selectedImageIndex: number }[];
  onTemplateChangeClick: () => void;
  template: Template | null;
};

const PrintPublishArticlesSelectionStep: FunctionComponent<PrintPublishArticlesSelectionStepProps> = ({
  project,
  chosenArticles,
  onArticlesChoose,
  template,
  onTemplateChangeClick
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [articles, setArticles] = useState<DesignerStory[]>([]);
  const [activeArticle, setActiveArticle] = useState<DesignerStory | null>(null);
  const [selectImageWindowOpen, onSelectImageWindowOpen, onSelectImageWindowClose] = useOpenHandler();

  useEffect(() => {
    setError(false);
    const fetchData = async () => {
      setLoading(true);
      try {
        const articlesList = await printProjectsOperations.getArticles(project.id);
        const allArticles = articlesList.data.map((article, i) => ({
          ...article,
          id: i,
          images: article.images.map((image, i) => ({ ...image, selected: i === 0 }))
        }));
        setArticles(allArticles);
        onArticlesChoose(
          allArticles.map((art) => ({
            name: art.name,
            selectedImageIndex: 0
          }))
        );
      } catch (e) {
        Toast.error(t('windows.publishPrintProject.errorGettingArticles'));
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const moveArticle = (id: number, atIndex: number) => {
    const { article, index } = findArticle(id);
    const reorderedArticles = update(articles, {
      $splice: [
        [index, 1],
        [atIndex, 0, article]
      ]
    });
    setArticles(reorderedArticles);
    onArticlesChoose(
      reorderedArticles
        .filter((art) => chosenArticles.find((a) => a.name === art.name))
        .map((a) => ({
          name: a.name,
          selectedImageIndex: a.images.findIndex((img) => img.selected)
        }))
    );
  };

  const findArticle = (id: number) => {
    const article = articles.filter((article) => article.id === id)[0];
    return {
      article,
      index: articles.indexOf(article)
    };
  };

  const [, drop] = useDrop({ accept: 'article' });

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <Wrapper>
          {template && (
            <TemplateWrapper>
              <Paper style={{ width: 180, height: 240 }}>
                <TemplateThumbnail thumbnailUrl={template.thumbnailUrl} width="180px" height="240px" />
              </Paper>
              <ListItemText
                style={{ textAlign: 'center', flex: 0, marginBottom: '1rem' }}
                primary={
                  <Typography variant="subtitle1" color="secondary" gutterBottom style={{ fontWeight: 'bold' }}>
                    {template.name}
                  </Typography>
                }
                secondary={template.description}
              />
              <Button color="secondary" variant="contained" size="small" onClick={onTemplateChangeClick}>
                Change email template...
              </Button>
            </TemplateWrapper>
          )}
          {error && <Row>{t('windows.publishPrintProject.errorGettingArticles')}</Row>}
          {articles.length === 0 && <Row>{t('windows.publishPrintProject.thereAreNoArticles')}</Row>}
          {articles.length > 0 && (
            <ArticlesList style={{ border: '1px solid #6d6d6d', overflowX: 'auto' }}>
              <List ref={drop}>
                {articles.map((article, i) => (
                  <div key={`article-${i}`}>
                    <DesignerArticle
                      id={article.id}
                      article={article}
                      onSelectArticleImages={(art) => {
                        setActiveArticle(art);
                        onSelectImageWindowOpen();
                      }}
                      selected={chosenArticles.some((art) => art.name === article.name)}
                      onSelect={(selected) => {
                        if (selected) {
                          const articleObject = articles.find((art) => art.name === article.name);
                          if (articleObject) {
                            onArticlesChoose(
                              articles
                                .filter((art) =>
                                  [
                                    {
                                      name: article.name,
                                      selectedImageIndex: articleObject.images.findIndex((img) => img.selected)
                                    },
                                    ...chosenArticles
                                  ].find((a) => a.name === art.name)
                                )
                                .map((a) => ({
                                  name: a.name,
                                  selectedImageIndex: a.images.findIndex((img) => img.selected)
                                }))
                            );
                          }
                        } else {
                          onArticlesChoose(chosenArticles.filter((chosenArt) => chosenArt.name !== article.name));
                        }
                      }}
                      moveArticle={moveArticle}
                      findArticle={findArticle}
                    />
                    {i < articles.length - 1 && <Divider key={`article-divider-${i}`} />}
                  </div>
                ))}
              </List>
            </ArticlesList>
          )}
        </Wrapper>
      )}

      {activeArticle && activeArticle.images.length && (
        <SelectArticleImageWindow
          article={activeArticle}
          onSubmit={(selectedImageIndex) => {
            if (activeArticle) {
              setArticles(
                articles.map((article) => {
                  if (article.name === activeArticle.name) {
                    return {
                      ...article,
                      images: article.images.map((image, i) => {
                        return {
                          ...image,
                          selected: selectedImageIndex === i
                        };
                      })
                    };
                  }

                  return article;
                })
              );

              onArticlesChoose(
                chosenArticles.map((chosenArticle) => {
                  if (chosenArticle.name === activeArticle.name) {
                    return {
                      name: chosenArticle.name,
                      selectedImageIndex: selectedImageIndex
                    };
                  }
                  return chosenArticle;
                })
              );
            }

            onSelectImageWindowClose();
          }}
          onCloseClick={onSelectImageWindowClose}
          open={selectImageWindowOpen}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  max-height: 100%;
`;

const TemplateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 3rem;
  justify-content: flex-start;
  align-items: center;
`;
const ArticlesList = styled.div`
  flex-grow: 1;
  margin: 1rem;
  border: 1px solid #6d6d6d;
  overflow-x: auto;
  max-height: 100%;
`;

export default PrintPublishArticlesSelectionStep;
