import update from 'immutability-helper';
import { MailingList, MailingListsActionsConstants, MailingListsActionType, MailingListsState } from './types';
import { normalizeMailingLists } from '../helpers';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: MailingListsState = {
  data: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const mailingListsPaginationReducer = createPaginationReducer<MailingListsState, MailingList>(
  PaginationStoreModule.MAILING_LISTS
);

const mailingListsTableSearchReducer = createTableSearchReducer<MailingList, MailingListsState>(
  TableSearchStoreModule.MAILING_LISTS
);

export default function mailingListsReducer(state = initialState, action: WithLogoutAction<MailingListsActionType>) {
  switch (action.type) {
    case MailingListsActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case MailingListsActionsConstants.INDEX_SUCCESS: {
      const data = normalizeMailingLists(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        data: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case MailingListsActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        data: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case MailingListsActionsConstants.UPDATE_MAILING_LIST: {
      const mailingList = action.payload.mailingList;

      if (mailingList && state.data.entities[mailingList.id]) {
        return update(state, {
          data: {
            entities: {
              [mailingList.id]: { $set: mailingList }
            }
          }
        });
      }

      return state;
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return mailingListsTableSearchReducer(mailingListsPaginationReducer(state, action), action) as MailingListsState;
    }
  }
}
