import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageContentPaper } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import { Grid } from '@material-ui/core';
import { useTypedSelector } from '../../../utils';
import GeneralSystemSettings from './Partials/GeneralSystemSettings';
import PasswordPolicyEditForm from './Partials/PasswordPolicyEditForm';
import DamSystems from './Partials/DamSystems';
import PrintCenters from './Partials/PrintCenters';

type SystemSettingsPageProps = {};

const SystemSettingsPage: FunctionComponent<SystemSettingsPageProps> = () => {
  const { t } = useTranslation();
  const authState = useTypedSelector((state) => state.auth);

  return (
    <PageContainer>
      <PageHeader title={t('pages.systemSettings.title')} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <PageContentPaper>
              <GeneralSystemSettings role={authState.role} />
            </PageContentPaper>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <PageContentPaper>
              <PasswordPolicyEditForm isGlobal={true} authState={authState} />
            </PageContentPaper>
          </Grid>
          <Grid item xs={12}>
            <PageContentPaper>
              <DamSystems role={authState.role} />
            </PageContentPaper>
          </Grid>
          <Grid item xs={12}>
            <PageContentPaper>
              <PrintCenters role={authState.role} />
            </PageContentPaper>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default SystemSettingsPage;
