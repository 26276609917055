import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import {
  CreateMyOrganizationSwatchColorValues,
  CreateMyOrganizationUserValues,
  IndexMyOrganizationUsersRequestParams,
  MonthCalendarStyle,
  UpdateMyOrganizationUserValues,
  WordpressIntegrationForm
} from './types';
import { PasswordPolicySettings } from '../SystemSettings/types';

export const index = (settings: RequestSettings<IndexMyOrganizationUsersRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/organization/:organizationId/users?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const create = (
  settings: RequestSettings<{ organizationId: string }, CreateMyOrganizationUserValues>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/organization/:organizationId/users'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const update = (
  settings: RequestSettings<{ organizationId: string; userId: string }, UpdateMyOrganizationUserValues>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/organization/:organizationId/users/:userId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteOrganizationUser = (
  settings: RequestSettings<{ organizationId: string; userId: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/organization/:organizationId/users/:userId'
  },
  params: settings.requestParams
});

export const getPasswordPolicy = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization/password_policy'
  }
});

export const getOrganizationPasswordPolicy = (settings: RequestSettings<{ organizationId: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization/:organizationId/password_policy'
  },
  params: settings.requestParams
});

export const removePasswordPolicy = (): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/organization/password_policy'
  }
});

export const setPasswordPolicy = (settings: RequestSettings<{}, PasswordPolicySettings>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/organization/password_policy'
  },
  payload: settings.requestPayload
});

export const getOrganizationSwatches = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization/swatches'
  }
});

export const getOrganizationColors = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization/colors'
  }
});

export const createOrganizationSwatchColor = (
  settings: RequestSettings<{}, CreateMyOrganizationSwatchColorValues>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/organization/swatches/color'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const updateOrganizationSwatchColor = (
  settings: RequestSettings<{ swatchColorId: string }, CreateMyOrganizationSwatchColorValues>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/organization/swatches/color/:swatchColorId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteOrganizationSwatchColor = (settings: RequestSettings<{ swatchColorId: string }>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/organization/swatches/color/:swatchColorId'
  },
  params: settings.requestParams
});

export const convertCmykToHex = (settings: RequestSettings<{ cmyk: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization/swatches/color/convert_cmyk_to_hex?cmyk=:cmyk'
  },
  params: settings.requestParams
});

export const indexOrganizationContributors = (
  settings: RequestSettings<{ organizationId: string; page: number; per: number; q: string }>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/organizations/:organizationId/contributors?per=:per&page=:page'
  },
  params: settings.requestParams
});

export const indexOrganizationContributorsForStory = (
  settings: RequestSettings<{ organizationId: string; storyId: string; page: number; per: number; q: string }>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/organizations/:organizationId/contributors/:storyId?per=:per&page=:page'
  },
  params: settings.requestParams
});

export const getSocialAccountsConnectorPage = (
  settings: RequestSettings<{ organizationId: string }>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization/social/connect'
  },
  params: settings.requestParams
});

export const deleteOrganizationContributor = (
  settings: RequestSettings<{ organizationId: string; userId: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/organizations/:organizationId/contributors/:userId'
  },
  params: settings.requestParams
});

export const getCalendarEvents = (settings: RequestSettings<{ startDate: string; endDate: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization/calendar_events?start=:startDate&end=:endDate'
  },
  params: settings.requestParams
});

export const getOrganizationMonthCalendar = (
  settings: RequestSettings<{ organizationId: string; date: string; type: 'month' | 'week' }>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization/month_calendar?oid=:organizationId&date=:date&type=:type'
  },
  params: settings.requestParams
});

export const getPromotions = (settings: RequestSettings<{ startDate: string; endDate: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization/promotions?start=:startDate&end=:endDate'
  },
  params: settings.requestParams
});

export const setOrganizationCalendarStyle = (settings: RequestSettings<{}, MonthCalendarStyle>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/organization/calendar_style'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const getOrganizationTree = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization/tree'
  }
});

export const getWordpressIntegrations = (settings: RequestSettings<{ withCategories: boolean }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization/wordpress_integrations?categories=:withCategories'
  },
  params: settings.requestParams
});

export const createWordpressIntegration = (settings: RequestSettings<{}, WordpressIntegrationForm>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/organization/wordpress_integrations'
  },
  payload: settings.requestPayload
});

export const deleteWordpressIntegration = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/organization/wordpress_integrations/:id'
  },
  params: settings.requestParams
});

export const updateWordpressIntegration = (
  settings: RequestSettings<{ id: string }, WordpressIntegrationForm>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/organization/wordpress_integrations/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const getDamStatus = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/integration/dam_status'
  }
});

export const getPrintCenters = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/organization/print_centers'
  }
});
