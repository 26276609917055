import React, { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../../../Shared/Loading/Loader';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NoResultsFound } from '../../PageStyledComponents';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import { EmailProject } from '../../../../store/EmailProjects/types';
import Toast from '../../../Shared/Toast/Toast';
import linksConstants from '../../../../config/app/linksConstants';
import { Button } from '@material-ui/core';
import { Row } from '../../../Shared/StyledComponents';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

type PreviewEmailWindowProps = {
  open: boolean;
  project: EmailProject;
  onCloseClick: () => void;
};

const PreviewEmailWindow: FunctionComponent<PreviewEmailWindowProps> = ({ open, project, onCloseClick }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [contentWidth, setContentWidth] = useState<string>('');

  const onInit = async () => {
    try {
      const data = await emailProjectsOperations.getProjectPreviewUrl(project);
      setPreviewUrl(data.url);
      setContentWidth(data.contentWidth.toString());
      setLoading(false);
    } catch (e) {
      Toast.error(t('windows.previewEmailProject.generatingPreviewError'));
    }
  };

  useEffect(() => {
    onInit();
  }, []);

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('windows.previewEmailProject.emailProjectPreviewHeader')}
      disableBackdropClick
      hideActionButtons
      onCloseClick={onCloseClick}
    >
      {loading && (
        <LoaderWrapper>
          <Loader />
          <NoResultsFound>{t('windows.previewEmailProject.loadingPreview')}</NoResultsFound>
        </LoaderWrapper>
      )}
      {!loading && (
        <Row style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (contentWidth) {
                const encodedUrl = encodeURIComponent(previewUrl);
                const previewRouteUrl = linksConstants.PROJECTS.EMAIL.PREVIEW(encodedUrl, contentWidth);
                window.open(previewRouteUrl, '_blank');
                onCloseClick();
              }
            }}
          >
            <OpenInNewIcon style={{ marginRight: 5 }} /> {t('windows.previewEmailProject.open')}
          </Button>
        </Row>
      )}
    </ModalConfirmationWindow>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default PreviewEmailWindow;
