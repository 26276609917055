import React, { FunctionComponent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { StoryChannel, StoryChannelType } from '../../../../store/Stories/types';
import { storiesOperations } from '../../../../store/Stories';
import Toast from '../../../Shared/Toast/Toast';
import { AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import ModalFormWindow from '../../../Shared/Window/ModalFormWindow';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Button, Grid, Typography } from '@material-ui/core';
import { Row, Strong } from '../../../Shared/StyledComponents';
import { createNetworkErrorObject } from '../../../../utils';
import moment from 'moment';
import { FormikProps } from 'formik';
import {
  WordpressCategory,
  WordpressIntegration,
  WordpressIntegrationForm
} from '../../../../store/MyOrganization/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import Loader from '../../../Shared/Loading/Loader';
import { NoResultsFound } from '../../PageStyledComponents';
import WordPressIntegrationForm from '../Partials/WordPressIntegrationForm';
import { handleWordpressIntegrationError } from '../../../../utils/errorCodeToToast';
import WordpressIntegrationsList from '../../MyOrganization/Partials/Integrations/WordpressIntegrationsList';

type PublishBlogChannelWindowProps = {
  type: StoryChannelType;
  open: boolean;
  channel: StoryChannel;
  onCloseClick: () => void;
  onSubmit: () => void;
  fullScreenOnMobile: boolean;
};

const PublishBlogChannelWindow: FunctionComponent<PublishBlogChannelWindowProps> = ({
  onCloseClick,
  type,
  onSubmit,
  open,
  channel
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [publishing, setPublishing] = useState(false);
  const [wordpressIntegrations, setWordpressIntegrations] = useState<WordpressIntegration[]>([]);
  const [selectedWordpressIntegrations, setSelectedWordpressIntegrations] = useState<string[]>([]);

  const [selectedCategories, setSelectedCategories] = useState<{ [integrationId: string]: WordpressCategory[] }>({});

  const handleCategorySelect = (integrationId: string, categories: WordpressCategory[]) => {
    setSelectedCategories((prevSelectedCategories) => ({
      ...prevSelectedCategories,
      [integrationId]: categories
    }));
  };

  const fetchIntegrations = async () => {
    setLoading(true);
    setError(false);
    try {
      const wordpressIntegrations = await myOrganizationOperations.getWordpressIntegrations({ withCategories: true });
      setWordpressIntegrations(wordpressIntegrations.data);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const createIntegration = async (formData: WordpressIntegrationForm) => {
    try {
      await myOrganizationOperations.createWordpressIntegration(formData);
      await fetchIntegrations();
      Toast.success(t('notifications.story.wordpressIntegrationCreated'));
    } catch (e) {
      handleWordpressIntegrationError(e, t);
    }
  };

  useEffect(() => {
    if (open) {
      setPublishing(false);
      fetchIntegrations();
    }
  }, [open]);

  const onPublish = async (values: {
    publishDate: string;
    publishTime: string;
    selectedWordpressIntegrations: string[];
  }) => {
    setPublishing(true);
    try {
      const publishDateAndTime = new Date(`${values.publishDate}T${values.publishTime}`);
      await storiesOperations.publishChannel(channel.storyId, channel.id, {
        publishDate: values.publishDate,
        publishTime: publishDateAndTime,
        wordpressIntegrations: values.selectedWordpressIntegrations.reduce((all, current) => {
          all.push({
            id: current,
            categories: selectedCategories[current] ? selectedCategories[current].map((cat) => cat.id) : []
          });
          return all;
        }, [] as { id: string; categories: number[] }[])
      });
      onCloseClick();
      onSubmit();
    } catch (e) {
      const networkError = createNetworkErrorObject(e);
      switch (networkError.message) {
        case 'post_body_empty':
          return Toast.error(t('notifications.story.channelPublishErrorPostBodyEmpty'));
        case 'already_published':
          return Toast.error(t('notifications.story.channelPublishErrorAlreadyPublished'));
        case 'bad_configuration':
          return Toast.error(t('notifications.story.channelWordpressBadConfiguration'));
        default:
          return Toast.error(t('notifications.story.channelPublishError'));
      }
    } finally {
      setPublishing(false);
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      {loading && <Loader />}
      {error && <NoResultsFound>{t('pages.story.windows.wordpressIntegration.error')}</NoResultsFound>}
      {!loading && !error && (
        <Grid container>
          {wordpressIntegrations.length === 0 && <WordPressIntegrationForm onSubmit={createIntegration} />}
          {wordpressIntegrations.length > 0 && (
            <Grid item xs={12}>
              <div style={{ textAlign: 'center' }}>
                <WordpressIntegrationsList
                  wordpressIntegrations={wordpressIntegrations}
                  checkboxes
                  categories
                  setSelectedWordpressIntegrations={setSelectedWordpressIntegrations}
                  selectedCategories={selectedCategories}
                  handleCategorySelect={handleCategorySelect}
                />
                <Row>{t('pages.story.windows.publish.youAreAboutToPublish')}</Row>
                <Button
                  style={{ margin: '1rem 0 0.5rem 0' }}
                  color="secondary"
                  variant="contained"
                  onClick={async () => {
                    const now = moment();
                    await onPublish({
                      publishDate: now.format('YYYY-MM-DD'),
                      publishTime: now.format('HH:mm'),
                      selectedWordpressIntegrations
                    });
                  }}
                  size="large"
                  disabled={publishing || selectedWordpressIntegrations.length === 0}
                >
                  {t(`pages.story.windows.publish.${publishing ? 'publishing' : 'publishNow'}`)}
                </Button>
                <Row>
                  <Typography variant="h6">{t('common.or')}</Typography>
                  <Strong>{t('pages.story.windows.publish.selectSpecificDateTime')}</Strong>
                </Row>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <AdminTextInput t={t} section="story" name="publishDate" type="date" />
                </Grid>
                <Grid item xs={6}>
                  <AdminTextInput
                    t={t}
                    section="story"
                    name="publishTime"
                    type="time"
                    inputProps={{
                      step: 300
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={publishing ? t('pages.story.windows.publish.publishing') : t('common.publish')}
      forceDisableSubmit={publishing}
      header={t(`pages.story.windows.publish.header.${type}`)}
      onCloseClick={onCloseClick}
      onSubmit={onPublish}
      formInputs={formInputs}
      initialValues={{
        publishDate: '',
        publishTime: '',
        wordpressIntegrations: []
      }}
      validationSchema={Yup.object().shape({
        publishDate: Yup.string().required(),
        publishTime: Yup.string().required(),
        wordpressIntegrations: Yup.array()
      })}
      maxWidth="lg"
    />
  );
};

export default PublishBlogChannelWindow;
