import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import SystemSettingsPage from './SystemSettingsPage';

const SystemSettingsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMINISTRATION.SYSTEM_SETTINGS.INDEX,
    exact: true,
    name: 'systemSettings',
    public: false,
    show: true,
    component: SystemSettingsPage,
    scope: []
  }
];

export default SystemSettingsRoutes;
