import React, { FunctionComponent } from 'react';
import { Redirect, Switch } from 'react-router';
import RoutesConfig from '../../../routes/RoutesConfig';
import linksConstants from '../../../config/app/linksConstants';
import AuthLayoutRoute from '../../../routes/AuthLayoutRoute';

type AuthRoutesContainerProps = {};

const AuthRoutesContainer: FunctionComponent<AuthRoutesContainerProps> = () => {
  const authRoutes = RoutesConfig.createAuthRoutes();

  return (
    <Switch key={location.pathname}>
      {authRoutes.map((route) => (
        <AuthLayoutRoute
          key={route.name}
          name={route.name}
          exact={route.exact}
          path={route.path}
          component={route.component}
        />
      ))}
      <Redirect to={linksConstants.AUTH.LOGIN} />
    </Switch>
  );
};

export default AuthRoutesContainer;
