import React from 'react';
import { EmailProjectAttachment } from '../../../../../../../store/EmailProjects/types';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { formatNoteDate } from '../../../../../../../utils';

interface EmailAttachmentProps {
  attachment: EmailProjectAttachment;
  removeAttachment: (attachment: EmailProjectAttachment) => void;
}

export const EmailAttachment: React.FC<EmailAttachmentProps> = ({ attachment, removeAttachment }) => {
  const { t } = useTranslation();

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <Avatar>{attachment.extension}</Avatar>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        style={{ overflowWrap: 'break-word' }}
        primary={attachment.filename}
        secondary={formatNoteDate(attachment.createdAt)}
      />
      <ListItemSecondaryAction>
        <Tooltip title={t('emailAttachments.removeAttachment') as string}>
          <IconButton onClick={() => removeAttachment(attachment)}>
            <DeleteIcon style={{ color: '#ef6666' }} />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
