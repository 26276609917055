import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { campaignsOperations } from '../../../../store/Campaigns';
import { useDispatch } from 'react-redux';
import Toast from '../../../Shared/Toast/Toast';
import { CreateCampaignValues } from '../../../../store/Campaigns/types';
import { AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import app from '../../../../config/app/app';
import { FormikProps } from 'formik';
import linksConstants from '../../../../config/app/linksConstants';
import { push } from 'connected-react-router';

type CreateCampaignWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const CreateCampaignWindow: FunctionComponent<CreateCampaignWindowProps> = ({ onCloseClick, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = async (values: CreateCampaignValues) => {
    try {
      const campaign = await campaignsOperations.create(values);
      dispatch(push(linksConstants.CAMPAIGNS.EDIT(campaign.id)));
      Toast.success(t('notifications.campaigns.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.campaigns.errorOperation'));
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="name" section="campaigns" />
      <AdminTextInput t={t} name="description" section="campaigns" />
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.create')}
      header={t('pages.campaigns.windows.createCampaign')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{ name: '', description: '' }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(app.maxInputLength).required(),
        description: Yup.string().max(app.maxInputLength)
      })}
    />
  );
};

export default CreateCampaignWindow;
