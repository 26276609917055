import React, { FunctionComponent, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  StoryChannel,
  StoryChannelType,
  StoryFieldName,
  StoryPrintChannelValues
} from '../../../../store/Stories/types';
import { Form, Formik, FormikProps } from 'formik';
import { AdminTextInput, WysiwygInput } from '../../Admin/Shared/AdminFormInputs';
import * as Yup from 'yup';
import ChannelFormButtons from './ChannelFormButtons';
import { getChannelFileMimeTypes, getFieldValue, isChannelDisabled } from '../Utils/storyUtils';
import ChannelAttachments from './Attachments/ChannelAttachments';
import { appConfig } from '../../../../config';
import { useTypedSelector } from '../../../../utils';
import { hasPermission } from '../../../../utils/permissions';

type PrintChannelFormProps = {
  storyId: string;
  channelId: string;
  expanded: StoryChannelType | false;
  channelData: StoryChannel;
  submitForm: (data: StoryPrintChannelValues, showToast?: boolean) => void;
  // deleteChannel: () => void;
  setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>;
  isOwnerOfStory: boolean;
};

const PrintChannelForm: FunctionComponent<PrintChannelFormProps> = ({
  expanded,
  channelData,
  submitForm,
  // deleteChannel,
  channelId,
  storyId,
  setIsFormValid,
  isOwnerOfStory
}) => {
  const { t } = useTranslation();
  const role = useTypedSelector((state) => state.auth.role);
  const isFormDisabled =
    isChannelDisabled(channelData, isOwnerOfStory) || !hasPermission(role, ['storiesEditChannels']);
  const [showToast, setShowToast] = useState<boolean>(false);

  const renderBasicDataForm = (props: FormikProps<StoryPrintChannelValues>) => {
    setIsFormValid(props.isValid);

    return (
      <Form style={{ width: '100%' }}>
        <Grid container>
          <Grid item xs={12}>
            <AdminTextInput t={t} name={`${[StoryFieldName.HEADLINE]}`} section="story" disabled={isFormDisabled} />
          </Grid>
          <Grid item xs={12}>
            <AdminTextInput t={t} name={`${[StoryFieldName.LEAD]}`} section="story" disabled={isFormDisabled} />
          </Grid>
          <Grid item xs={12}>
            <WysiwygInput
              t={t}
              name={`${[StoryFieldName.BODY]}`}
              section="story"
              disabled={isFormDisabled}
              toolbar={appConfig.storyFieldToolbar}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <ChannelAttachments
              mimeTypes={getChannelFileMimeTypes(StoryChannelType.PRINT)}
              initialAttachments={channelData.attachments}
              channelId={channelId}
              storyId={storyId}
              disabled={isFormDisabled}
              channelType={channelData.type}
            />
          </Grid> */}
        </Grid>
        <ChannelFormButtons
          props={props}
          // deleteChannel={deleteChannel}
          saveChannel={async () => {
            setShowToast(true);
            await props.submitForm();
            setShowToast(false);
          }}
          disabled={isFormDisabled}
        />
      </Form>
    );
  };

  if (expanded === false) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        [StoryFieldName.HEADLINE]: getFieldValue(channelData, StoryFieldName.HEADLINE).toString(),
        [StoryFieldName.LEAD]: getFieldValue(channelData, StoryFieldName.LEAD).toString(),
        [StoryFieldName.BODY]: getFieldValue(channelData, StoryFieldName.BODY).toString()
      }}
      validationSchema={Yup.object().shape({
        [StoryFieldName.HEADLINE]: Yup.string().required(),
        [StoryFieldName.LEAD]: Yup.string().required(),
        [StoryFieldName.BODY]: Yup.string().required()
      })}
      onSubmit={(data) => submitForm(data, showToast)}
      validateOnMount
    >
      {renderBasicDataForm}
    </Formik>
  );
};

export default PrintChannelForm;
