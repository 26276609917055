import React from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';
import styled from 'styled-components';
import { StructureIcon } from '../../Styling/StructureIcon';
import { ItemTypes, ProjectModelColumn } from '../../../types';

const style: React.CSSProperties = {
  border: '1px solid #D4D4D4',
  backgroundColor: 'white',
  padding: '0.7rem 1rem 0.4rem',
  cursor: 'move',
  borderRadius: '10px',
  color: '#717175',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '12px',
  alignItems: 'center',
  width: '100%'
};

interface StructureElementProps {
  name: string;
  cols: ProjectModelColumn[];
  sizes: number[];
}

export const StructureElement: React.FC<StructureElementProps> = ({ name, cols, sizes }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { name, type: ItemTypes.STRUCTURE, cols },
    end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        // alert(`You dropped ${item.name} into ${dropResult.name}!`);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0.4 : 1;

  return (
    <div ref={drag} style={{ ...style, opacity }}>
      <StructureIcon sizes={sizes} />
      <Name>{name}</Name>
    </div>
  );
};

const Name = styled.div`
  margin-top: 5px;
`;
