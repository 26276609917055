import React, { FunctionComponent, useEffect, useState } from 'react';
import { AdBanner, AdCategory, Advertiser, AdZone } from '../../../../../../store/AdminAds/types';
import { useTranslation } from 'react-i18next';
import { adminEmailTemplatesOperations } from '../../../../../../store/AdminEmailTemplates';
import Loader from '../../../../../Shared/Loading/Loader';
import styled from 'styled-components';
import { NoResultsFound } from '../../../../PageStyledComponents';
import { AdminEmailTemplate } from '../../../../../../store/AdminEmailTemplates/types';
import { adminAdsOperations } from '../../../../../../store/AdminAds';
import HouseAdsTable from './HouseAdsTable';
import { useDispatch } from 'react-redux';
import AdsPreviewInTemplate from '../AdsPreviewInTemplate';
import { ProjectModelV2 } from '../../../../Editors/EmailDndEditor/types';

type HouseAdToEmailTemplateAssignmentProps = {
  allBanners: AdBanner[];
  advertiser: Advertiser;
  categories: AdCategory[];
  emailTemplate: AdminEmailTemplate;
  emailTemplateSelector: JSX.Element;
};

const HouseAdToEmailTemplateAssignment: FunctionComponent<HouseAdToEmailTemplateAssignmentProps> = ({
  emailTemplate,
  categories,
  advertiser,
  emailTemplateSelector
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [model, setProjectModel] = useState<ProjectModelV2 | null>(null);
  const [adSpaces, setAdSpaces] = useState<{ [key: string]: number }>({});
  const [bannerZones, setBannerZones] = useState<{ zone: AdZone; banner: AdBanner }[]>([]);
  const [selectedZones, setSelectedZones] = useState<{ size: string; zones: string[] }[]>([]);

  useEffect(() => {
    const getModel = async () => {
      setLoading(true);
      try {
        const response = await adminEmailTemplatesOperations.getTemplateModel(emailTemplate.id);
        setProjectModel(response.model);

        const adSpacesFromModel = response.model.sizes.large.reduce((allAdSpaces, row) => {
          row.cols.forEach((column) => {
            column.components.forEach((comp) => {
              if (comp.type === 'ad-space') {
                const adSize = `${comp.params.width}x${comp.params.height}`;

                if (!allAdSpaces[adSize]) {
                  allAdSpaces[adSize] = 1;
                } else {
                  allAdSpaces[adSize] = allAdSpaces[adSize] + 1;
                }
              }
            });
          });
          return allAdSpaces;
        }, {} as { [key: string]: number });
        setAdSpaces(adSpacesFromModel);

        const zones = await adminAdsOperations.getZones(advertiser.advertiserId, Object.keys(adSpacesFromModel));
        setBannerZones(zones.data);

        const selectedZones = (emailTemplate.overrideAdZones || '')
          .split(';')
          .filter((val) => val)
          .map((sizeOverride) => {
            const [size, zonesIds] = sizeOverride.split('=');
            return {
              size,
              zones: (zonesIds || '')
                .split(',')
                .filter((savedZoneId) => zones.data.find((z) => z.zone.zoneId.toString() === savedZoneId.toString()))
            };
          });
        setSelectedZones(selectedZones);
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoading(false);
      }
    };
    getModel();
  }, [emailTemplate]);

  const updateTemplateWithNewAdsSelection = (selectedZones: { size: string; zones: string[] }[]) => {
    const overrideAdZones = selectedZones.reduce((stringValue, oneOverride, index) => {
      if (oneOverride.zones.length) {
        stringValue += `${oneOverride.size}=${oneOverride.zones.join(',')}`;

        if (index < selectedZones.length) {
          stringValue += ';';
        }
      }

      return stringValue;
    }, '');

    dispatch(
      adminEmailTemplatesOperations.update(emailTemplate.id, {
        name: emailTemplate.name,
        description: emailTemplate.description,
        type: emailTemplate.type,
        overrideAdZones: overrideAdZones || null
      })
    );
  };

  const removeAssignment = (size: string, banner: AdBanner, zone: AdZone) => {
    try {
      const newSelectedZones = selectedZones.map((selectedZone) => {
        if (selectedZone.size === size) {
          selectedZone.zones = selectedZone.zones.filter((id) => id !== zone.zoneId);
        }
        return selectedZone;
      });
      setSelectedZones(newSelectedZones);

      updateTemplateWithNewAdsSelection(newSelectedZones);
    } catch (e) {
      console.log('e', e);
    }
  };

  const onSizeSelectionSaved = (size: string, selectedZonesIds: string[]) => {
    try {
      const newSelectedZones = selectedZones.map((selectedZone) => {
        if (selectedZone.size === size) {
          selectedZone.zones = selectedZonesIds;
        }
        return selectedZone;
      });
      if (!newSelectedZones.find((z) => z.size === size)) {
        newSelectedZones.push({
          size,
          zones: selectedZonesIds
        });
      }
      setSelectedZones(newSelectedZones);

      updateTemplateWithNewAdsSelection(newSelectedZones);
    } catch (e) {
      console.log('e', e);
    }
  };
  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && !model && <NoResultsFound>Template cannot be processed...</NoResultsFound>}
      {!loading && !!model && (
        <>
          <AdsPreviewInTemplate model={model} />
          <BannerContentWrapper>
            {emailTemplateSelector}
            {Object.keys(adSpaces).map((size) => (
              <HouseAdsTable
                key={`${emailTemplate.id}-${size}`}
                size={size}
                adSpaces={adSpaces}
                removeAssignment={removeAssignment}
                bannerZones={bannerZones}
                selectedZones={selectedZones}
                categories={categories}
                onSave={onSizeSelectionSaved}
              />
            ))}
          </BannerContentWrapper>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 2rem;
  border-bottom: 1px solid #0000001f;
  display: flex;
  width: 100%;

  &:not(:first-child) {
    padding-top: 2rem;
  }
`;
const BannerContentWrapper = styled.div`
  width: calc(100% - 300px);
  margin-left: 1rem;
`;

export default HouseAdToEmailTemplateAssignment;
