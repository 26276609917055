import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../../../Shared/Window/ModalConfirmationWindow';
import { Pin } from '../../../../../../../store/EmailProjects/types';
import { Button, Grid } from '@material-ui/core';
import { ReactComponent as PinIcon } from '../../../../../../../assets/icons/pin.svg';

type PinsWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  pins: Pin[] | null;
  onPinClick: (searchQuery: string) => void;
};

const PinsWindow: FunctionComponent<PinsWindowProps> = ({ onCloseClick, open, pins, onPinClick }) => {
  const { t } = useTranslation();
  const [pinsLength, setPinsLength] = useState<number>(0);

  useEffect(() => {
    if (open) {
      if (pins) {
        setPinsLength(pins.length);
      }
    }
  }, [open]);

  return (
    <ModalConfirmationWindow
      open={open}
      header={`${pinsLength} Pinned Searches`}
      onCloseClick={onCloseClick}
      hideActionButtons
      maxWidth="md"
    >
      {pins && (
        <Grid container spacing={2}>
          {pins.map((pin) => (
            <Grid item xs={4} key={pin.id}>
              <Button
                size="small"
                color="secondary"
                startIcon={<PinIcon />}
                onClick={() => onPinClick(pin.attributes.query)}
              >
                {pin.attributes.label}
              </Button>
            </Grid>
          ))}
        </Grid>
      )}
    </ModalConfirmationWindow>
  );
};

export default PinsWindow;
