import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import ProjectDetailsPage from './ProjectDetailsPage';

const ProjectDetailsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.PROJECT_DETAILS(':projectId'),
    exact: true,
    name: 'projectDetails',
    public: false,
    show: true,
    component: ProjectDetailsPage,
    scope: []
  }
];

export default ProjectDetailsRoutes;
