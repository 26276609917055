import { Dictionary } from 'lodash';
import {
  CancelEmailProjectPublicationAction,
  CheckInEmailProjectAction,
  EmailProject,
  EmailProjectStatus,
  PublishEmailProjectAction,
  RemoveEmailProjectAction,
  SaveEmailProjectSubjectAction
} from '../EmailProjects/types';
import {
  CheckInPrintProjectAction,
  PrintProject,
  PrintProjectStatus,
  PublishPrintProjectAction,
  RemovePrintProjectAction
} from '../PrintProjects/types';
import { ShortUser } from '../Auth/types';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';

export type AnyProject = EmailProject | PrintProject;

export type AnyProjectStatus = EmailProjectStatus | PrintProjectStatus;

export type DashboardState = StateWithPagination &
  StateWithTableSearch<AnyProject> & {
    projects: NormalizedDashboardProjects;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
    details: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
      project: AnyProject | null;
    };
    notificationToCheckOutWindows: NotificationToCheckOutWindowItem[];
  };

export type IndexDashboardProjectsRequestParams = {
  per: number;
  page: number;
  order?: string[];
  q: string | null;
};

export type NotificationToCheckOutWindowItem = {
  open: boolean;
  notifier: ShortUser | null;
  project: AnyProject | null;
};

export type DashboardProjectsIndexResponse = {
  data: AnyProject[];
  pagination: Pagination;
};

export type NormalizedDashboardProjects = {
  entities: Dictionary<AnyProject>;
  result: string[];
};

export type ProjectsIndexActionBEGIN = {
  type: DashboardActionsConstants.INDEX_BEGIN;
};

export type ProjectsIndexActionSUCCESS = {
  type: DashboardActionsConstants.INDEX_SUCCESS;
  payload: DashboardProjectsIndexResponse;
};

export type ProjectsIndexActionFAILED = {
  type: DashboardActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type GetProjectByIdActionBEGIN = {
  type: DashboardActionsConstants.GET_PROJECT_BY_ID_BEGIN;
};

export type GetProjectByIdActionSUCCESS = {
  type: DashboardActionsConstants.GET_PROJECT_BY_ID_SUCCESS;
  payload: AnyProject;
};

export type GetProjectByIdActionFAILED = {
  type: DashboardActionsConstants.GET_PROJECT_BY_ID_FAILED;
  payload: {
    error: any;
  };
};

export type ProjectCheckedOutAction = {
  type: DashboardActionsConstants.PROJECT_CHECKED_OUT;
  payload: {
    projectId: string;
    user: ShortUser;
  };
};

export type NotifyToCheckOutAction = {
  type: DashboardActionsConstants.NOTIFY_TO_CHECK_OUT;
  payload: {
    project: AnyProject;
    notifier: ShortUser;
  };
};

export type NotifyToCheckOutCloseAction = {
  type: DashboardActionsConstants.NOTIFY_TO_CHECK_OUT_CLOSE;
  payload: {
    project: AnyProject;
  };
};

export type KickedOutFromProjectAction = {
  type: DashboardActionsConstants.KICKED_OUT_FROM_PROJECT;
  payload: {
    project: AnyProject;
  };
};

export type TestFlagToggledAction = {
  type: DashboardActionsConstants.TEST_FLAG_TOGGLED;
  payload: AnyProject;
};

export enum DashboardActionsConstants {
  TEST_FLAG_TOGGLED = 'DASHBOARD/TEST_FLAG_TOGGLED',
  KICKED_OUT_FROM_PROJECT = 'DASHBOARD/KICKED_OUT_FROM_PROJECT',
  NOTIFY_TO_CHECK_OUT_CLOSE = 'DASHBOARD/NOTIFY_TO_CHECK_OUT_CLOSE',
  NOTIFY_TO_CHECK_OUT = 'DASHBOARD/NOTIFY_TO_CHECK_OUT',
  PROJECT_CHECKED_OUT = 'DASHBOARD/PROJECT_CHECKED_OUT',
  GET_PROJECT_BY_ID_BEGIN = 'DASHBOARD/GET_PROJECT_BY_ID/BEGIN',
  GET_PROJECT_BY_ID_SUCCESS = 'DASHBOARD/GET_PROJECT_BY_ID/SUCCESS',
  GET_PROJECT_BY_ID_FAILED = 'DASHBOARD/GET_PROJECT_BY_ID/FAILED',

  INDEX_BEGIN = 'DASHBOARD/INDEX/BEGIN',
  INDEX_SUCCESS = 'DASHBOARD/INDEX/SUCCESS',
  INDEX_FAILED = 'DASHBOARD/INDEX/FAILED'
}

export type DashboardActionType =
  | GetProjectByIdActionBEGIN
  | GetProjectByIdActionSUCCESS
  | GetProjectByIdActionFAILED
  | ProjectsIndexActionBEGIN
  | ProjectsIndexActionSUCCESS
  | ProjectsIndexActionFAILED
  | PublishEmailProjectAction
  | SaveEmailProjectSubjectAction
  | CancelEmailProjectPublicationAction
  | RemoveEmailProjectAction
  | PublishPrintProjectAction
  | RemovePrintProjectAction
  | CheckInEmailProjectAction
  | CheckInPrintProjectAction
  | ProjectCheckedOutAction
  | NotifyToCheckOutAction
  | KickedOutFromProjectAction
  | TestFlagToggledAction
  | NotifyToCheckOutCloseAction;
