import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatTableTimestamp, formatTableTimestampWithHours, useTypedSelector } from '../../../../../utils';
import DataTable from '../../../../Shared/DataTable/DataTable';
import { DoAppPromotion, DoAppPromotionStatus } from '../../../../../store/DoApp/Promotions/types';
import { TableSearchStoreModule } from '../../../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../../../store/Pagination/types';
import { usePaginationWatch } from '../../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../../Shared/DataTable/useTableSearchWatch';
import { DoAppPromotionClaim, DoAppPromotionClaimStatus } from '../../../../../store/DoApp/PromotionClaims/types';
import { doAppPromotionClaimsOperations } from '../../../../../store/DoApp/PromotionClaims';
import {
  TextField,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Popover,
  IconButton,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { CheckCircle, Schedule, Cancel } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { doAppPromotionsOperations } from '../../../../../store/DoApp/Promotions';
import BlockIcon from '@material-ui/icons/Block';
import ReplayIcon from '@material-ui/icons/Replay';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import DisputePromotionClaimWindow from '../../Windows/DisputePromotionClaimWindow';
import CancelDisputePromotionClaimWindow from '../../Windows/CancelDisputePromotionClaimWindow';
import { ColumnDefinition } from '../../../../Shared/DataTable/types';
import moment from 'moment';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

type PromotionClaimsTabProps = {
  promotionClaimStatus: DoAppPromotionClaimStatus;
  promotionId?: string;
  onClaimDispute: () => void;
};

const PromotionClaimsTab: React.FC<PromotionClaimsTabProps> = ({
  promotionClaimStatus,
  promotionId,
  onClaimDispute
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [promotionClaimStatusFilter, setPromotionClaimStatusFilter] = useState(promotionClaimStatus);
  const [promotionIdFilter, setPromotionIdFilter] = useState(promotionId || '');
  const [promotionsFilter, setPromotionsFilter] = useState<DoAppPromotion[]>([]);
  const [allPromotions, setAllPromotions] = useState<DoAppPromotion[]>([]);
  const [selectedPromotionClaim, setSelectedPromotionClaim] = useState<DoAppPromotionClaim | null>(null);

  const doAppPromotionClaims = useTypedSelector((state) => state.doAppMerchantPromotionClaims.data);
  const isFailed = useTypedSelector((state) => state.doAppMerchantPromotionClaims.index.isFailed);
  const isLoading = useTypedSelector((state) => state.doAppMerchantPromotionClaims.index.isLoading);
  const pagination = useTypedSelector((state) => state.doAppMerchantPromotionClaims.pagination);
  const tableSearch = useTypedSelector((state) => state.doAppMerchantPromotionClaims.tableSearch);

  const [
    disputePromotionClaimWindowOpen,
    onDisputePromotionClaimWindowOpen,
    onDisputePromotionClaimWindowClose
  ] = useOpenHandler();
  const [
    cancelDisputePromotionClaimWindowOpen,
    onCancelDisputePromotionClaimWindowOpen,
    onCancelDisputePromotionClaimWindowClose
  ] = useOpenHandler();

  usePaginationWatch(pagination, [
    doAppPromotionClaimsOperations.indexMerchantPromotionClaims.bind(
      null,
      promotionClaimStatusFilter,
      promotionIdFilter
    )
  ]);
  useTableSearchWatch<DoAppPromotion>(tableSearch, [
    doAppPromotionClaimsOperations.indexMerchantPromotionClaims.bind(
      null,
      promotionClaimStatusFilter,
      promotionIdFilter
    )
  ]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getPromotionStatusIcon = (status: DoAppPromotionStatus) => {
    switch (status) {
      case DoAppPromotionStatus.ACTIVE:
        return <CheckCircle style={{ color: 'green', width: 14 }} />;
      case DoAppPromotionStatus.EXPIRED:
        return <Cancel style={{ color: 'red', width: 14 }} />;
      case DoAppPromotionStatus.UPCOMING:
        return <Schedule style={{ color: 'orange', width: 14 }} />;
      default:
        return null;
    }
  };

  const handlePromotionClaimStatusFilterChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFilter = (event.target as HTMLInputElement).value as DoAppPromotionClaimStatus;
    setPromotionClaimStatusFilter(selectedFilter);
    await dispatch(doAppPromotionClaimsOperations.indexMerchantPromotionClaims(selectedFilter, promotionIdFilter));
  };

  const fetchFilterData = async () => {
    const promotionsResponse = await doAppPromotionsOperations.getAllMinePromotions();
    setAllPromotions(promotionsResponse.data);
    setPromotionsFilter(promotionsResponse.data.filter((promo) => promo.status === DoAppPromotionStatus.ACTIVE));
  };

  useEffect(() => {
    fetchFilterData();
  }, []);

  const handlePromotionChange = async (event: React.ChangeEvent<{}>, selectedPromotion: DoAppPromotion | null) => {
    const selectedPromotionId = selectedPromotion ? selectedPromotion.id : '';
    setPromotionIdFilter(selectedPromotionId);
    await dispatch(
      doAppPromotionClaimsOperations.indexMerchantPromotionClaims(promotionClaimStatusFilter, selectedPromotionId)
    );
  };

  const getColumnDefinitions = (): ColumnDefinition<
    DoAppPromotionClaim,
    'actions' | 'reason' | 'rejectedAt' | 'acceptedAt' | 'autoAccept'
  >[] => {
    const columns: ColumnDefinition<
      DoAppPromotionClaim,
      'actions' | 'reason' | 'rejectedAt' | 'acceptedAt' | 'autoAccept'
    >[] = [
      {
        name: 'promotion',
        sortable: false,
        render: (row) => <>{row.promotion.name}</>
      },
      {
        name: 'subscriber',
        sortable: false,
        render: (row) => <>{row.subscriber.email}</>
      },
      {
        name: 'createdAt',
        sortable: false,
        render: (row) => <>{formatTableTimestampWithHours(row.createdAt)}</>
      }
    ];

    if (promotionClaimStatusFilter === DoAppPromotionClaimStatus.PENDING) {
      columns.push(
        {
          name: 'autoAccept',
          sortable: false,
          render: (row) => {
            const hoursUntilAutoAccept = 48 - moment().diff(moment(row.createdAt), 'hours');
            return (
              <>
                {hoursUntilAutoAccept} {t('pages.doApp.merchants.table.hoursRemaining')}
              </>
            );
          }
        },
        {
          name: 'actions',
          sortable: false,
          render: (row) => (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<BlockIcon />}
              style={{ color: '#de3e31', borderColor: '#de3e31' }}
              onClick={() => {
                setSelectedPromotionClaim(row);
                onDisputePromotionClaimWindowOpen();
              }}
            >
              {t('pages.myOrganization.merchant.dispute')}
            </Button>
          )
        }
      );
    } else if (promotionClaimStatusFilter === DoAppPromotionClaimStatus.REJECTED) {
      columns.push(
        {
          name: 'reason',
          sortable: false,
          render: (row) => <>{row.rejection?.comment}</>
        },
        {
          name: 'rejectedAt',
          sortable: false,
          render: (row) => <>{row.rejection ? formatTableTimestampWithHours(row.rejection.createdAt) : ''}</>
        },
        {
          name: 'actions',
          sortable: false,
          render: (row) => (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<ReplayIcon />}
              onClick={() => {
                setSelectedPromotionClaim(row);
                onCancelDisputePromotionClaimWindowOpen();
              }}
            >
              {t('pages.myOrganization.merchant.unDispute')}
            </Button>
          )
        }
      );
    } else if (promotionClaimStatusFilter === DoAppPromotionClaimStatus.ACCEPTED) {
      columns.push({
        name: 'acceptedAt',
        sortable: false,
        render: (row) => <>{row.acceptedAt ? formatTableTimestampWithHours(row.acceptedAt) : ''}</>
      });
    }

    return columns;
  };

  const getShowingPromotionValue = () => {
    let promotion;
    if (promotionId) {
      promotion = allPromotions.find((p) => p.id === promotionId);
    } else {
      promotion = promotionsFilter.find((p) => p.id === promotionIdFilter);
    }

    return (
      <Box display="flex" alignItems="center">
        <strong>{t('pages.myOrganization.merchant.promotionClaims.showing')}:</strong>
        <div style={{ marginLeft: 7, marginRight: 7 }}>
          {promotion ? promotion.name + ' ' : t('pages.myOrganization.merchant.promotionClaims.allActive')}
        </div>
        {promotion && getPromotionStatusIcon(promotion.status)}
      </Box>
    );
  };

  const renderInfoText = () => {
    return (
      <>
        {t('pages.myOrganization.merchant.promotionClaims.typeToSearch')}
        <Box display="flex" alignItems="center">
          <div style={{ marginRight: 7, fontSize: '12px', fontWeight: 'bold' }}>
            {t('pages.myOrganization.merchant.promotionClaims.legend')}:
          </div>
          {getPromotionStatusIcon(DoAppPromotionStatus.ACTIVE)}{' '}
          <div style={{ marginLeft: 4, marginRight: 7, fontSize: '12px' }}>
            {t('pages.myOrganization.merchant.promotionClaims.status.active')}
          </div>
          {getPromotionStatusIcon(DoAppPromotionStatus.UPCOMING)}{' '}
          <div style={{ marginLeft: 4, marginRight: 7, fontSize: '12px' }}>
            {t('pages.myOrganization.merchant.promotionClaims.status.upcoming')}
          </div>
          {getPromotionStatusIcon(DoAppPromotionStatus.EXPIRED)}{' '}
          <div style={{ marginLeft: 4, marginRight: 7, fontSize: '12px' }}>
            {t('pages.myOrganization.merchant.promotionClaims.status.expired')}
          </div>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="left">
        <Box display="flex" alignItems="flex-end">
          <div>
            {getShowingPromotionValue()}
            <Autocomplete
              id="promotions-autocomplete"
              options={promotionsFilter}
              getOptionLabel={(option) => option.name}
              value={promotionsFilter.find((p) => p.id === promotionIdFilter)}
              onChange={handlePromotionChange}
              onInputChange={(event, value) => {
                if (
                  value &&
                  !promotionsFilter
                    .filter((promo) => promo.status === DoAppPromotionStatus.ACTIVE)
                    .find((p) => p.name === value)
                ) {
                  setPromotionsFilter(allPromotions);
                } else {
                  // Reset to show only active promotions when the search input is cleared
                  setPromotionsFilter(allPromotions.filter((promo) => promo.status === DoAppPromotionStatus.ACTIVE));
                  setPromotionIdFilter('');
                }
              }}
              noOptionsText={renderInfoText()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('pages.myOrganization.merchant.promotionClaims.searchPromotions')}
                  variant="outlined"
                />
              )}
              renderOption={(option) => (
                <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
                  <Box ml={1}>
                    {option.name}
                    <Typography variant="caption" display="block">
                      {formatTableTimestamp(option.startDate)} - {formatTableTimestamp(option.endDate)}
                    </Typography>
                  </Box>
                  {getPromotionStatusIcon(option.status)}
                </Box>
              )}
              style={{ width: 300, marginTop: 8 }}
              size="small"
            />
          </div>
          <IconButton aria-label="info" onClick={handleClick} size="small" style={{ marginLeft: 4, marginBottom: 8 }}>
            <InfoOutlinedIcon fontSize="inherit" />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <div style={{ padding: 10 }}>{renderInfoText()}</div>
          </Popover>
        </Box>
        <FormControl component="fieldset" style={{ padding: '24px 0 0 40px' }}>
          <RadioGroup
            name="promotion-claims-status-radio-group"
            value={promotionClaimStatusFilter}
            onChange={handlePromotionClaimStatusFilterChange}
            row
          >
            <FormControlLabel
              value={DoAppPromotionClaimStatus.ACCEPTED}
              control={<Radio />}
              label={t('pages.myOrganization.merchant.promotionClaims.filter.accepted')}
            />
            <FormControlLabel
              value={DoAppPromotionClaimStatus.PENDING}
              control={<Radio />}
              label={t('pages.myOrganization.merchant.promotionClaims.filter.pending')}
            />
            <FormControlLabel
              value={DoAppPromotionClaimStatus.REJECTED}
              control={<Radio />}
              label={t('pages.myOrganization.merchant.promotionClaims.filter.rejected')}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <DataTable<DoAppPromotionClaim, 'actions' | 'reason' | 'rejectedAt' | 'acceptedAt' | 'autoAccept'>
        enableSearch={false}
        columnDefinitions={getColumnDefinitions()}
        tPath={'pages.doApp.merchants.table'}
        data={doAppPromotionClaims}
        tableSearchProps={{
          tableSearch,
          module: TableSearchStoreModule.DO_APP_MERCHANT_PROMOTION_CLAIMS
        }}
        paginationProps={{
          pagination,
          module: PaginationStoreModule.DO_APP_MERCHANT_PROMOTION_CLAIMS,
          noUrlUpdate: true
        }}
        isFailed={isFailed}
        isLoading={isLoading}
        disablePaper
      />

      {disputePromotionClaimWindowOpen && selectedPromotionClaim && (
        <DisputePromotionClaimWindow
          open={disputePromotionClaimWindowOpen}
          promotionClaim={selectedPromotionClaim}
          onCloseClick={onDisputePromotionClaimWindowClose}
          onSubmit={async () => {
            await dispatch(
              doAppPromotionClaimsOperations.indexMerchantPromotionClaims(promotionClaimStatusFilter, promotionIdFilter)
            );
            onClaimDispute();
          }}
          fullScreenOnMobile
        />
      )}
      {cancelDisputePromotionClaimWindowOpen && selectedPromotionClaim && (
        <CancelDisputePromotionClaimWindow
          open={cancelDisputePromotionClaimWindowOpen}
          promotionClaim={selectedPromotionClaim}
          onCloseClick={onCancelDisputePromotionClaimWindowClose}
          onSubmit={async () => {
            await dispatch(
              doAppPromotionClaimsOperations.indexMerchantPromotionClaims(promotionClaimStatusFilter, promotionIdFilter)
            );
            onClaimDispute();
          }}
          fullScreenOnMobile
        />
      )}
    </>
  );
};

export default PromotionClaimsTab;
