import React from 'react';
import { Field, FieldProps } from 'formik';
import { FormSelectTextInputProps } from './types';
import { InputAdornment, TextField } from '@material-ui/core';
import * as _ from 'lodash';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import getFormInputError from './getFormInputError';
import { useTranslation } from 'react-i18next';

const FormSelectTextInput: React.FC<FormSelectTextInputProps> = (props) => {
  const { t } = useTranslation();
  const { formikFieldProps, textFieldProps, customErrorMessagePath, className, label } = props;

  const getPlaceholder = () => {
    if (!textFieldProps) return;

    return textFieldProps.required && textFieldProps.value
      ? `${textFieldProps.placeholder} *`
      : textFieldProps.placeholder;
  };

  return (
    <>
      {label}
      <Field name={formikFieldProps.name}>
        {(fieldProps: FieldProps) => {
          const { field } = fieldProps;
          const errorMessage = getFormInputError(fieldProps, t, customErrorMessagePath);
          return (
            <TextField
              {...field}
              select
              {...textFieldProps}
              onChange={(...props) => {
                field.onChange(...props);
                textFieldProps && textFieldProps.onChange && textFieldProps.onChange(...props);
              }}
              classes={{
                root: className
              }}
              placeholder={getPlaceholder()}
              label={_.get(textFieldProps, 'label')}
              error={errorMessage[0]}
              helperText={errorMessage[1] || ''}
              SelectProps={{
                startAdornment: errorMessage[0] ? (
                  <InputAdornment position={'start'}>
                    <ErrorOutline style={{ color: '#FF3300' }} />
                  </InputAdornment>
                ) : null,
                ...(textFieldProps && textFieldProps.SelectProps ? textFieldProps.SelectProps : {})
              }}
            >
              {props.children}
            </TextField>
          );
        }}
      </Field>
    </>
  );
};

export default FormSelectTextInput;
