import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import { formatTableTimestamp, useTypedSelector } from '../../../../utils';
import { usePaginationWatch } from '../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../Shared/DataTable/useTableSearchWatch';
import { PaginationStoreModule } from '../../../../store/Pagination/types';
import DataTable from '../../../Shared/DataTable/DataTable';
import { TableSearchStoreModule } from '../../../../store/TableSearch/types';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { adminRolesOperations } from '../../../../store/AdminRoles';
import { AdminRole, DefaultRoleName, defaultRoleNames } from '../../../../store/AdminRoles/types';
import { useDispatch } from 'react-redux';
import AdminKeywordsList from '../../../Shared/Tags/AdminKeywordsList';
import { AdminKeyword } from '../../../../store/AdminKeywords/types';
import { adminKeywordsOperations } from '../../../../store/AdminKeywords';
import AddIcon from '@material-ui/icons/Add';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import TableActionButtonsContainer from '../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../../Shared/DataTable/TableActionButton/TableActionButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import DeleteRoleWindow from './Windows/DeleteRoleWindow';
import { canEditUserType, hasPermission, isSystemOwner } from '../../../../utils/permissions';

type AdminRolesPageProps = {};

const AdminRolesPage: FunctionComponent<AdminRolesPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authState = useTypedSelector((state) => state.auth);
  const userRole = useTypedSelector((state) => state.auth.role);
  const roles = useTypedSelector((state) => state.adminRoles.roles);
  const isFailed = useTypedSelector((state) => state.adminRoles.index.isFailed);
  const isLoading = useTypedSelector((state) => state.adminRoles.index.isLoading);
  const pagination = useTypedSelector((state) => state.adminRoles.pagination);
  const tableSearch = useTypedSelector((state) => state.adminRoles.tableSearch);

  const [activeRole, setActiveRole] = useState<AdminRole | null>(null);
  const [deleteRoleWindowOpen, onDeleteRoleWindowOpen, onDeleteRoleWindowClose] = useOpenHandler();

  usePaginationWatch(pagination, [adminRolesOperations.index]);
  useTableSearchWatch<AdminRole>(tableSearch, [adminRolesOperations.index]);

  const onAddKeywordToRole = useCallback((template: AdminRole, keyword: AdminKeyword) => {
    dispatch(adminRolesOperations.addKeywordToRole(template, keyword));
  }, []);

  const onDeleteKeywordFromRole = useCallback((template: AdminRole, keyword: AdminKeyword) => {
    dispatch(adminRolesOperations.removeKeywordFromRole(template, keyword));
  }, []);

  const getActionButtons = () => {
    if (hasPermission(userRole, ['rolesAdd'])) {
      return [
        {
          label: t('pages.adminRoles.create.buttons.addNewRole'),
          icon: <AddIcon />,
          onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ROLES.CREATE))
        }
      ];
    }

    return [];
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.adminRoles.title')} rightActionButtons={getActionButtons()} />
      <PageContent>
        <DataTable<AdminRole, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'name',
              sortable: true,
              render: (role) => (
                <div>
                  {defaultRoleNames.includes(role.name as DefaultRoleName) && (
                    <div>
                      {t('common.default')} {t(`common.userTypes.${role.userType.name}`)}
                    </div>
                  )}
                  {!defaultRoleNames.includes(role.name as DefaultRoleName) && <div>{role.name}</div>}
                </div>
              )
            },
            {
              name: 'actions',
              render: (role) => (
                <TableActionButtonsContainer>
                  <TableActionButton
                    icon={<EditIcon />}
                    tooltip={t('pages.adminRoles.table.buttons.edit')}
                    ButtonProps={{
                      disabled:
                        !hasPermission(userRole, ['rolesAdd']) ||
                        !canEditUserType(authState, role.userType.name as DefaultRoleName) ||
                        role.name === DefaultRoleName.SYSTEM_OWNER ||
                        (defaultRoleNames.includes(role.name as DefaultRoleName) && !isSystemOwner(authState)),
                      onClick: () => {
                        dispatch(push(linksConstants.ADMINISTRATION.ROLES.EDIT(role.id)));
                      }
                    }}
                  />
                  {!defaultRoleNames.includes(role.name as DefaultRoleName) && (
                    <TableActionButton
                      icon={<DeleteIcon />}
                      tooltip={t('pages.adminRoles.table.buttons.delete')}
                      ButtonProps={{
                        disabled:
                          !hasPermission(userRole, ['rolesRemove']) ||
                          !canEditUserType(authState, role.userType.name as DefaultRoleName),
                        onClick: () => {
                          setActiveRole(role);
                          onDeleteRoleWindowOpen();
                        }
                      }}
                    />
                  )}
                </TableActionButtonsContainer>
              )
            },
            {
              name: 'userType',
              sortable: true,
              render: (role) => <div>{t(`common.userTypes.${role.userType.name}`)}</div>
            },
            {
              name: 'createdAt',
              render: (role) => <>{formatTableTimestamp(role.createdAt)}</>,
              sortable: true
            }
          ]}
          tPath={'pages.adminRoles.table'}
          data={roles}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.ADMIN_ROLES
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.ADMIN_ROLES
            // onPageChange: () => {},
            // onPerChange: () => {}
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>

      <DeleteRoleWindow
        role={activeRole}
        open={deleteRoleWindowOpen}
        onCloseClick={onDeleteRoleWindowClose}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

export default AdminRolesPage;
