import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { AdminKeyword, AdminKeywordGroup } from '../AdminKeywords/types';
import { AdminRole } from '../AdminRoles/types';
import { AdminOrganization } from '../AdminOrganizations/types';

export type AdminUsersState = StateWithPagination &
  StateWithTableSearch<AdminUser> & {
    users: NormalizedAdminUser;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
    details: {
      activeUser: AdminUser | null;
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedAdminUser = {
  entities: Dictionary<AdminUser>;
  result: string[];
};

export type AdminUser = {
  id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  role: AdminRole;
  roleId: string;
  verified: boolean;
  blocked: boolean;
  lastName: string;
  firstName: string;
  systemMaintenanceAllowed: boolean | null;
  keywords: AdminKeyword[];
  keywordGroups: AdminKeywordGroup[];
  type: UserType;
  typeId: string;
  organizations?: AdminOrganization[];
  brandfolderCollection: string | null;
};

export type UserType = {
  id: string;
  name: string;
  landingPage: string;
  createdAt: string;
  updatedAt: string;
  defaultRole?: AdminRole;
};

export type AdminCreateUserValues = Pick<AdminUser, 'email' | 'firstName' | 'lastName' | 'typeId' | 'roleId'> & {
  organization: {
    id: string;
    label: string;
  };
  isOwner: boolean;
};
export type AdminUpdateUserValues = Pick<
  AdminUser,
  'firstName' | 'lastName' | 'typeId' | 'roleId' | 'brandfolderCollection' | 'systemMaintenanceAllowed'
>;

export type AdminUsersIndexResponse = {
  data: AdminUser[];
  pagination: Pagination;
};

export type IndexAdminUsersRequestParams = {
  per: number;
  page: number;
  q: string | null;
  order?: string[];
};

export type AdminUsersIndexActionBEGIN = {
  type: AdminUsersActionsConstants.INDEX_BEGIN;
};

export type AdminUsersIndexActionSUCCESS = {
  type: AdminUsersActionsConstants.INDEX_SUCCESS;
  payload: AdminUsersIndexResponse;
};

export type AdminUsersIndexActionFAILED = {
  type: AdminUsersActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type AdminUsersShowActionBEGIN = {
  type: AdminUsersActionsConstants.SHOW_BEGIN;
};

export type AdminUsersShowActionSUCCESS = {
  type: AdminUsersActionsConstants.SHOW_SUCCESS;
  payload: AdminUsersShowResponse;
};

export type AdminUsersShowActionFAILED = {
  type: AdminUsersActionsConstants.SHOW_FAILED;
  payload: {
    error: any;
  };
};

export type AdminUsersShowResponse = AdminUser;

export type AdminUserIdOnlyParam = {
  id: string;
};

export type AdminVerifyUserPayload = {
  password: string;
  confirmPassword: string;
};

export type AdminUsersKeywordAddedAction = {
  type: AdminUsersActionsConstants.KEYWORD_ADDED;
  payload: AdminUser;
};

export type AdminUsersKeywordGroupAddedAction = {
  type: AdminUsersActionsConstants.KEYWORD_GROUP_ADDED;
  payload: AdminUser;
};

export type AdminUsersKeywordRemovedAction = {
  type: AdminUsersActionsConstants.KEYWORD_REMOVED;
  payload: AdminUser;
};

export type AdminUsersKeywordGroupRemovedAction = {
  type: AdminUsersActionsConstants.KEYWORD_GROUP_REMOVED;
  payload: AdminUser;
};

export enum AdminUsersActionsConstants {
  KEYWORD_ADDED = 'ADMIN/USERS/KEYWORD_ADDED',
  KEYWORD_REMOVED = 'ADMIN/USERS/KEYWORD_REMOVED',
  KEYWORD_GROUP_ADDED = 'ADMIN/USERS/KEYWORD_GROUP_ADDED',
  KEYWORD_GROUP_REMOVED = 'ADMIN/USERS/KEYWORD_GROUP_REMOVED',
  INDEX_BEGIN = 'ADMIN/USERS/INDEX/BEGIN',
  INDEX_SUCCESS = 'ADMIN/USERS/INDEX/SUCCESS',
  INDEX_FAILED = 'ADMIN/USERS/INDEX/FAILED',
  SHOW_BEGIN = 'ADMIN/USERS/SHOW/BEGIN',
  SHOW_SUCCESS = 'ADMIN/USERS/SHOW/SUCCESS',
  SHOW_FAILED = 'ADMIN/USERS/SHOW/FAILED'
}

export type AdminUsersActionType =
  | AdminUsersKeywordGroupAddedAction
  | AdminUsersKeywordGroupRemovedAction
  | AdminUsersKeywordAddedAction
  | AdminUsersKeywordRemovedAction
  | AdminUsersIndexActionBEGIN
  | AdminUsersIndexActionSUCCESS
  | AdminUsersIndexActionFAILED
  | AdminUsersShowActionBEGIN
  | AdminUsersShowActionSUCCESS
  | AdminUsersShowActionFAILED;
