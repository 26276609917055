import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import ColorPicker from '../../../../../Shared/ColorPicker/ColorPicker';
import { FormControl, Grid, MenuItem, Select, TextField } from '@material-ui/core';

type PromotionsSettingsProps = {
  open: boolean;
  onCloseClick: () => void;
  onFormSubmit: (values: {
    backgroundColor: string;
    color: string;
    borderRadius: string;
    borderStyle: string;
    borderWidth: string;
    borderColor: string;
    headerPosition: 'left' | 'center' | 'right';
    headerVariable: 'region' | 'district' | 'custom';
    customHeaderText: string;
    height: string;
  }) => void;
  backgroundColor: string;
  color: string;
  borderRadius: string;
  borderStyle: string;
  borderWidth: string;
  borderColor: string;
  headerPosition: 'left' | 'center' | 'right';
  headerVariable: 'region' | 'district' | 'custom';
  customHeaderText: string;
  height: string;
};

const PromotionsSettingsWindow: FunctionComponent<PromotionsSettingsProps> = ({
  open,
  onCloseClick,
  onFormSubmit,
  backgroundColor,
  color,
  borderRadius,
  borderStyle,
  borderWidth,
  borderColor,
  headerPosition,
  headerVariable,
  customHeaderText,
  height
}) => {
  const { t } = useTranslation();
  const [promotionsColor, setPromotionsColor] = useState<string>(color);
  const [promotionsBackgroundColor, setPromotionsBackgroundColor] = useState<string>(backgroundColor);
  const [promotionsBorderRadius, setPromotionsBorderRadius] = useState<string>(
    borderRadius.includes('px') ? borderRadius.slice(0, -2).trim() : '0'
  );
  const [promotionsBorderColor, setPromotionsBorderColor] = useState<string>(borderColor);
  const [promotionsBorderWidth, setPromotionsBorderWidth] = useState<string>(
    borderWidth.includes('px') ? borderWidth.slice(0, -2).trim() : '0'
  );
  const [promotionsSpacing, setPromotionsSpacing] = useState<string>(
    height.includes('px') ? height.slice(0, -2).trim() : '0'
  );
  const [promotionsHeaderPosition, setPromotionsHeaderPosition] = React.useState(headerPosition);

  const handlePromotionsHeaderPositionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPromotionsHeaderPosition(event.target.value as 'left' | 'center' | 'right');
  };

  const [promotionsHeaderVariable, setPromotionsHeaderVariable] = React.useState(headerVariable);
  const [customText, setCustomText] = useState<string>(customHeaderText);

  const handlePromotionsHeaderVariableChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPromotionsHeaderVariable(event.target.value as 'region' | 'district' | 'custom');
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.emailEditor.promotionsSettings.header')}
      onCloseClick={onCloseClick}
      maxWidth="md"
      onSubmit={() =>
        onFormSubmit({
          color: promotionsColor,
          backgroundColor: promotionsBackgroundColor,
          borderRadius: promotionsBorderRadius + 'px',
          borderStyle: 'solid',
          borderWidth: promotionsBorderWidth + 'px',
          borderColor: promotionsBorderColor,
          headerPosition: promotionsHeaderPosition,
          headerVariable: promotionsHeaderVariable,
          customHeaderText: customText,
          height: promotionsSpacing + 'px'
        })
      }
    >
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.promotionsSettings.general')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.promotionsSettings.backgroundColor')}:</div>
            <ColorPicker color={promotionsBackgroundColor} onChange={setPromotionsBackgroundColor} />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.promotionsSettings.color')}:</div>
            <ColorPicker color={promotionsColor} onChange={setPromotionsColor} />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.promotionsSettings.spacing')}:</div>
            <TextField
              id="promotions-spacing"
              type="number"
              value={promotionsSpacing}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const spacing = Math.abs(Number(event.target.value)) || '';
                setPromotionsSpacing((spacing as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setPromotionsSpacing('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </fieldset>
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.promotionsSettings.headerSettings')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div>{t('pages.emailEditor.promotionsSettings.headerPosition')}:</div>
            <FormControl size="small" variant="outlined">
              <Select
                id="promotions-header-position-select"
                value={promotionsHeaderPosition}
                onChange={handlePromotionsHeaderPositionChange}
              >
                <MenuItem value={'left'}>{t('pages.emailEditor.promotionsSettings.left')}</MenuItem>
                <MenuItem value={'center'}>{t('pages.emailEditor.promotionsSettings.center')}</MenuItem>
                <MenuItem value={'right'}>{t('pages.emailEditor.promotionsSettings.right')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <div>{t('pages.emailEditor.promotionsSettings.headerVariable')}:</div>
            <FormControl size="small" variant="outlined">
              <Select
                id="promotions-header-variable-select"
                value={promotionsHeaderVariable}
                onChange={handlePromotionsHeaderVariableChange}
              >
                <MenuItem value="region">{t('pages.emailEditor.promotionsSettings.region')}</MenuItem>
                <MenuItem value="district">{t('pages.emailEditor.promotionsSettings.district')}</MenuItem>
                <MenuItem value="custom">{t('pages.emailEditor.promotionsSettings.customText')}</MenuItem>
              </Select>
            </FormControl>
            {promotionsHeaderVariable === 'custom' && (
              <Grid item xs={9}>
                <TextField
                  label={t('pages.emailEditor.promotionsSettings.enterCustomText')}
                  value={customText}
                  onChange={(e) => setCustomText(e.target.value)}
                  fullWidth
                  variant="outlined"
                  size="small"
                  style={{ marginTop: 16 }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </fieldset>
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.promotionsSettings.border')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.promotionsSettings.borderRadius')}:</div>
            <TextField
              id="promotions-border-radius"
              type="number"
              value={promotionsBorderRadius}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const radius = Math.abs(Number(event.target.value)) || '';
                setPromotionsBorderRadius((radius as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setPromotionsBorderRadius('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.promotionsSettings.borderWidth')}:</div>
            <TextField
              id="promotions-border-width"
              type="number"
              value={promotionsBorderWidth}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const width = Math.abs(Number(event.target.value)) || '';
                setPromotionsBorderWidth((width as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setPromotionsBorderWidth('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.promotionsSettings.borderColor')}:</div>
            <ColorPicker color={promotionsBorderColor} onChange={setPromotionsBorderColor} />
          </Grid>
        </Grid>
      </fieldset>
    </ModalConfirmationWindow>
  );
};

export default PromotionsSettingsWindow;
