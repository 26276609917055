import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import { designProjectsOperations } from '../../../../store/DesignProjects';
import { DesignProject } from '../../../../store/DesignProjects/types';

type DeleteDesignWindowProps = {
  open: boolean;
  design: DesignProject | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const DeleteDesignWindow: FunctionComponent<DeleteDesignWindowProps> = ({ design, onCloseClick, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!design) return null;

  const onSubmit = async () => {
    try {
      await dispatch(designProjectsOperations.deleteDesignProject(design.id));
      await dispatch(designProjectsOperations.index());
      onCloseClick();
      Toast.success(t('notifications.designProjects.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.designProjects.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.designProjects.windows.deleteDesign')}
      okButtonText={t('pages.designProjects.windows.delete')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.designProjects.windows.deleteDesignContent')}</Row>
      <Row>
        <Strong>{design.name}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteDesignWindow;
