import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import Story from '../Helpers/DesignerStory';
import { DesignerStory, PrintProject } from '../../../../../../../store/PrintProjects/types';
import { EmailProject } from '../../../../../../../store/EmailProjects/types';
import { printProjectsOperations } from '../../../../../../../store/PrintProjects';
import moment from 'moment';
import app from '../../../../../../../config/app/app';
import linksConstants from '../../../../../../../config/app/linksConstants';

type DesignerStoriesProps = {
  stories: DesignerStory[];
  project: EmailProject;
};

const DesignerStories: FunctionComponent<DesignerStoriesProps> = ({ stories, project }) => {
  const { t } = useTranslation();
  const [printProject, setPrintProject] = useState<PrintProject | null>(null);

  const fetchPrintProject = async (id: string) => {
    setPrintProject(await printProjectsOperations.show(id));
  };

  useEffect(() => {
    if (project.source) {
      fetchPrintProject(project.source);
    }
  }, []);

  return (
    <Stories>
      <SectionLabel variant="h6">{t('common.articles')}</SectionLabel>
      {printProject && (
        <SourceProjectInfo>
          <Link to={linksConstants.PROJECT_DETAILS(printProject.id)} target="_blank">
            <ProjectName title={printProject.name}>{printProject.name}</ProjectName>
          </Link>{' '}
          <span>- {moment(printProject.publishDate).format(app.dateFormats.projectPublishDate)}</span>
        </SourceProjectInfo>
      )}
      <StoriesList>
        {stories.map((story, i) => (
          <Story key={`${story.name}_${i}`} story={story} />
        ))}
      </StoriesList>
    </Stories>
  );
};

const SectionLabel = styled(Typography)`
  padding: 0.5rem 0 0 1rem;
`;

const SourceProjectInfo = styled.div`
  padding: 0 1rem;
  display: flex;
  font-size: 0.8em;
  font-style: italic;

  > span {
    white-space: nowrap;
  }
`;

const ProjectName = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Stories = styled.div`
  min-height: 300px;
`;

const StoriesList = styled.div``;

export default DesignerStories;
