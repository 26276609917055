import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';
import { myOrganizationOperations } from '../../../../../store/MyOrganization';
import { WordpressIntegration, WordpressIntegrationForm } from '../../../../../store/MyOrganization/types';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import DeleteWordpressIntegrationWindow from '../../Windows/DeleteWordpressIntegrationWindow';
import { Alert } from '@material-ui/lab';
import CreateOrUpdateWordpressIntegration from '../../Windows/CreateOrUpdateWordpressIntegration';
import { handleWordpressIntegrationError } from '../../../../../utils/errorCodeToToast';
import Toast from '../../../../Shared/Toast/Toast';
import WordpressIntegrationsList from './WordpressIntegrationsList';

type WordpressIntegrationsProps = {};

const WordpressIntegrations: FunctionComponent<WordpressIntegrationsProps> = () => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [wordpressIntegrations, setWordpressIntegrations] = useState<WordpressIntegration[]>([]);
  const [activeIntegration, setActiveIntegration] = useState<WordpressIntegration | null>(null);
  const [
    deleteWordpressIntegrationWindowOpen,
    onDeleteWordpressIntegrationWindowOpen,
    onDeleteWordpressIntegrationWindowClose
  ] = useOpenHandler();
  const [
    updateWordpressIntegrationWindowOpen,
    onUpdateWordpressIntegrationWindowOpen,
    onUpdateWordpressIntegrationWindowClose
  ] = useOpenHandler();
  const [
    createWordpressIntegrationWindowOpen,
    onCreateWordpressIntegrationWindowOpen,
    onCreateWordpressIntegrationWindowClose
  ] = useOpenHandler();

  const fetchIntegrations = async () => {
    setLoading(true);
    setError(false);
    try {
      const wordpressIntegrations = await myOrganizationOperations.getWordpressIntegrations({ withCategories: false });
      setWordpressIntegrations(wordpressIntegrations.data);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIntegrations();
  }, []);

  return (
    <>
      <Box marginTop={5} marginBottom={1}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          {t('pages.myOrganization.integrations.wordpress.title')}
          <Button
            color="secondary"
            size="small"
            variant="contained"
            style={{
              marginLeft: 12
            }}
            onClick={() => {
              setActiveIntegration(null);
              onCreateWordpressIntegrationWindowOpen();
            }}
          >
            + {t('pages.myOrganization.integrations.wordpress.addSite')}
          </Button>
        </Typography>
      </Box>
      {wordpressIntegrations.length === 0 && (
        <Alert variant="outlined" severity="info">
          {t('pages.myOrganization.integrations.wordpress.noSites')}
        </Alert>
      )}
      {wordpressIntegrations.length > 0 && (
        <WordpressIntegrationsList
          wordpressIntegrations={wordpressIntegrations}
          onUpdateIconClick={(integration) => {
            setActiveIntegration(integration);
            onUpdateWordpressIntegrationWindowOpen();
          }}
          onDeleteIconClick={(integration) => {
            setActiveIntegration(integration);
            onDeleteWordpressIntegrationWindowOpen();
          }}
        />
      )}
      <DeleteWordpressIntegrationWindow
        open={deleteWordpressIntegrationWindowOpen}
        integration={activeIntegration}
        onCloseClick={onDeleteWordpressIntegrationWindowClose}
        onSuccessfulSubmit={fetchIntegrations}
        fullScreenOnMobile
      />
      <CreateOrUpdateWordpressIntegration
        type="create"
        open={createWordpressIntegrationWindowOpen}
        onCloseClick={onCreateWordpressIntegrationWindowClose}
        onSubmit={async (formData: WordpressIntegrationForm) => {
          try {
            await myOrganizationOperations.createWordpressIntegration(formData);
            onCreateWordpressIntegrationWindowClose();
            Toast.success(t('notifications.story.wordpressIntegrationCreated'));
            await fetchIntegrations();
          } catch (e) {
            handleWordpressIntegrationError(e, t);
          }
        }}
        fullScreenOnMobile
      />
      <CreateOrUpdateWordpressIntegration
        type="update"
        open={updateWordpressIntegrationWindowOpen}
        onCloseClick={onUpdateWordpressIntegrationWindowClose}
        onSubmit={async (formData: WordpressIntegrationForm) => {
          if (activeIntegration) {
            try {
              await myOrganizationOperations.updateWordpressIntegration(activeIntegration.id, formData);
              onUpdateWordpressIntegrationWindowClose();
              Toast.success(t('notifications.story.wordpressIntegrationUpdated'));
              await fetchIntegrations();
            } catch (e) {
              handleWordpressIntegrationError(e, t);
            }
          }
        }}
        integration={activeIntegration}
        fullScreenOnMobile
      />
    </>
  );
};

export default WordpressIntegrations;
