import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@material-ui/core';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import ColorPicker from '../../../../../Shared/ColorPicker/ColorPicker';

type CtaButtonProps = {
  open: boolean;
  onCloseClick: () => void;
  onFormSubmit: (values: {
    backgroundColor: string;
    borderRadius: string;
    borderStyle: string;
    borderWidth: string;
    borderColor: string;
  }) => void;
  backgroundColor: string;
  borderRadius: string;
  borderStyle: string;
  borderWidth: string;
  borderColor: string;
};

const CtaButtonWindow: FunctionComponent<CtaButtonProps> = ({
  open,
  onCloseClick,
  onFormSubmit,
  backgroundColor,
  borderRadius,
  borderStyle,
  borderWidth,
  borderColor
}) => {
  const { t } = useTranslation();

  const [buttonBackgroundColor, setButtonBackgroundColor] = useState<string>(backgroundColor);
  const [buttonBorderColor, setButtonBorderColor] = useState<string>(borderColor);
  const [buttonBorderRadius, setButtonBorderRadius] = useState<string>(
    borderRadius.includes('px') ? borderRadius.slice(0, -2).trim() : '0'
  );
  const [buttonBorderWidth, setButtonBorderWidth] = useState<string>(
    borderWidth.includes('px') ? borderWidth.slice(0, -2).trim() : '0'
  );

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.emailEditor.ctaButton.header')}
      onCloseClick={onCloseClick}
      onSubmit={() =>
        onFormSubmit({
          backgroundColor: buttonBackgroundColor,
          borderRadius: buttonBorderRadius + 'px',
          borderStyle: 'solid',
          borderWidth: buttonBorderWidth + 'px',
          borderColor: buttonBorderColor
        })
      }
    >
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.ctaButton.general')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.ctaButton.backgroundColor')}:</div>
            <ColorPicker color={buttonBackgroundColor} onChange={setButtonBackgroundColor} />
          </Grid>
        </Grid>
      </fieldset>
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.ctaButton.border')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.ctaButton.borderRadius')}:</div>
            <TextField
              id="button-border-radius"
              type="number"
              value={buttonBorderRadius}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const radius = Math.abs(Number(event.target.value)) || '';
                setButtonBorderRadius((radius as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setButtonBorderRadius('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.ctaButton.borderWidth')}:</div>
            <TextField
              id="button-border-width"
              type="number"
              value={buttonBorderWidth}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const width = Math.abs(Number(event.target.value)) || '';
                setButtonBorderWidth((width as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setButtonBorderWidth('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.ctaButton.borderColor')}:</div>
            <ColorPicker color={buttonBorderColor} onChange={setButtonBorderColor} />
          </Grid>
        </Grid>
      </fieldset>
    </ModalConfirmationWindow>
  );
};

export default CtaButtonWindow;
