import { imagesOperations } from '../../../../../../store/Images';
import { ImageData } from '../../types';

export const designImage = async (projectId: string, imageData: ImageData, printDesignId: string) => {
  // upload image using design projects palette
  return await imagesOperations.uploadDesignImage(
    projectId,
    printDesignId,
    imageData.containerWidth || 80,
    imageData.containerHeight || 80
  );
};
