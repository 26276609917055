import update from 'immutability-helper';
import { createPaginationReducer } from '../../Pagination/reducers';
import { PaginationStoreModule } from '../../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../../utils';
import { AuthActionsConstants } from '../../Auth/types';
import { createTableSearchReducer } from '../../TableSearch/reducers';
import { TableSearchStoreModule } from '../../TableSearch/types';
import {
  DoAppPromotionClaim,
  DoAppMerchantPromotionClaimsActionsConstants,
  DoAppMerchantPromotionClaimsActionType,
  DoAppMerchantPromotionClaimsState
} from './types';
import { normalizeDoAppMerchantPromotionClaims } from '../../helpers';

const initialState: DoAppMerchantPromotionClaimsState = {
  data: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: {
    page: 1,
    per: 10,
    q: null,
    total: 0,
    totalPages: 0
  },
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const doAppMerchantPromotionClaimsPaginationReducer = createPaginationReducer<
  DoAppMerchantPromotionClaimsState,
  DoAppPromotionClaim
>(PaginationStoreModule.DO_APP_MERCHANT_PROMOTION_CLAIMS);

const doAppMerchantPromotionClaimsTableSearchReducer = createTableSearchReducer<
  DoAppPromotionClaim,
  DoAppMerchantPromotionClaimsState
>(TableSearchStoreModule.DO_APP_MERCHANT_PROMOTION_CLAIMS);

export default function doAppMerchantPromotionClaimsReducer(
  state = initialState,
  action: WithLogoutAction<DoAppMerchantPromotionClaimsActionType>
) {
  switch (action.type) {
    case DoAppMerchantPromotionClaimsActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case DoAppMerchantPromotionClaimsActionsConstants.INDEX_SUCCESS: {
      const data = normalizeDoAppMerchantPromotionClaims(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        data: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case DoAppMerchantPromotionClaimsActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        data: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return doAppMerchantPromotionClaimsTableSearchReducer(
        doAppMerchantPromotionClaimsPaginationReducer(state, action),
        action
      ) as DoAppMerchantPromotionClaimsState;
    }
  }
}
