import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { changePage, changePagination, changeSearchQuery } from '../../../../store/Pagination/actions';
import { replace } from 'connected-react-router';
import { getLocationPaginationState, useTypedSelector } from '../../../../utils';
import { DataTablePaginationProps } from '../types';

export const usePagination = ({
  pagination,
  onPageChange,
  onPerChange,
  module,
  noUrlUpdate = false
}: Pick<DataTablePaginationProps<any>, 'pagination' | 'onPageChange' | 'onPerChange' | 'module'> & {
  noUrlUpdate?: boolean;
}) => {
  const dispatch = useDispatch();

  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(changePage(module, { page }));

      if (!noUrlUpdate) {
        dispatch(
          replace({
            search: `?page=${page}&per=${pagination.per}${pagination.q ? `&q=${encodeURIComponent(pagination.q)}` : ''}`
          })
        );
      }

      if (onPageChange) {
        onPageChange(page);
      }
    },
    [pagination]
  );

  const handleSearchQueryChange = useCallback(
    (q: string) => {
      dispatch(changeSearchQuery(module, { q }));

      if (!noUrlUpdate) {
        dispatch(
          replace({
            search: `?page=1&per=${pagination.per}${q ? `&q=${encodeURIComponent(q)}` : ''}`
          })
        );
      }
    },
    [pagination]
  );

  const handlePerChange = useCallback(
    ({ target: { value: per } }: any) => {
      dispatch(changePagination(module, { per, page: 1, q: pagination.q }));

      if (!noUrlUpdate) {
        dispatch(
          replace({
            search: `?page=${1}&per=${per}${pagination.q ? `&q=${encodeURIComponent(pagination.q)}` : ''}`
          })
        );
      }

      if (onPerChange) {
        onPerChange(per);
      }
    },
    [pagination]
  );

  const router = useTypedSelector((state) => state.router);

  useEffect(() => {
    if (!noUrlUpdate && router.location.pathname.includes(module)) {
      const { page, per, q } = getLocationPaginationState();
      dispatch(
        replace({
          search: `?page=${page}&per=${per}${q ? `&q=${encodeURIComponent(q)}` : ''}`
        })
      );
    }
  }, [router.location.search]);

  const onPage =
    pagination.per * pagination.page > pagination.total ? pagination.total : pagination.per * pagination.page;

  const hasMore = pagination.total > 0 && pagination.page < pagination.totalPages;

  return {
    handlePerChange,
    handlePageChange,
    handleSearchQueryChange,
    onPage,
    hasMore
  };
};
