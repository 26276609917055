import React, { FunctionComponent } from 'react';
import Loader from './Loader';

type SplashscreenProps = {};

const Splashscreen: FunctionComponent<SplashscreenProps> = () => {
  return <Loader />;
};

export default Splashscreen;
