import {
  MailingList,
  MailingListsActionsConstants,
  MailingListsIndexActionBEGIN,
  MailingListsIndexActionFAILED,
  MailingListsIndexActionSUCCESS,
  MailingListsIndexResponse,
  MailingListsUpdateAction
} from './types';

export const mailingListsIndexBegin = (): MailingListsIndexActionBEGIN => ({
  type: MailingListsActionsConstants.INDEX_BEGIN
});

export const mailingListsIndexSuccess = (payload: MailingListsIndexResponse): MailingListsIndexActionSUCCESS => ({
  type: MailingListsActionsConstants.INDEX_SUCCESS,
  payload
});

export const mailingListsIndexFailed = (error: any): MailingListsIndexActionFAILED => ({
  type: MailingListsActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const updateMailingList = (mailingList: MailingList): MailingListsUpdateAction => ({
  type: MailingListsActionsConstants.UPDATE_MAILING_LIST,
  payload: {
    mailingList
  }
});
