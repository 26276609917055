import React, { CSSProperties } from 'react';
import app from '../../../../config/app/app';
import { StyledTableCell } from '../../StyledComponents';

export type CustomRenderCellProps<E = never> = {
  value: E;
  render: (entity: E) => JSX.Element;
  style?: CSSProperties;
};

export function CustomRenderColumnCell<E = never>({ value, render, style }: CustomRenderCellProps<E>) {
  return <StyledTableCell style={{ maxWidth: app.maxTableColumnWidth, ...style }}>{render(value)}</StyledTableCell>;
}
