import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TableRow, TableCell, withTheme } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AdsStatisticsBannersSection from './AdsStatisticsBannersSection';
import { AdvertiserBannerStatisticsData, AdvertiserStatisticsData } from '../../../../store/AdminAds/types';
import Loader from '../../../Shared/Loading/Loader';
import { adminAdsOperations } from '../../../../store/AdminAds';

type AdsStatisticsAdvertiserSectionProps = {
  advertiser: AdvertiserStatisticsData;
  startDate: string;
  endDate: string;
};

const AdsStatisticsAdvertiserSection: FunctionComponent<AdsStatisticsAdvertiserSectionProps> = ({
  advertiser,
  startDate,
  endDate
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [advertiserBannerStats, setAdvertiserBannerStats] = useState<AdvertiserBannerStatisticsData[]>([]);

  const ctr =
    Math.round(((parseInt(advertiser.clicks) / parseInt(advertiser.impressions)) * 100 + Number.EPSILON) * 100) / 100;

  const noBorderStyle = {
    border: 0
  };

  const fetchData = async () => {
    const response = await adminAdsOperations.getAdvertiserBannerStatistics(
      parseInt(advertiser.advertiserid),
      startDate,
      endDate
    );

    setAdvertiserBannerStats(response.data.banners.data);
    setLoading(false);
  };

  useEffect(() => {
    if (expanded) {
      fetchData();
    }
  }, [expanded]);

  return (
    <>
      <TableRow>
        <TableCell onClick={() => setExpanded(!expanded)} style={expanded ? noBorderStyle : {}}>
          {expanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        </TableCell>
        <TableCell onClick={() => setExpanded(!expanded)} style={expanded ? noBorderStyle : {}}>
          <TitleRow>
            <AccountBoxIcon />
            <Title>{advertiser.advertisername}</Title>
          </TitleRow>
        </TableCell>
        <TableCell style={expanded ? noBorderStyle : {}}>{advertiser.impressions}</TableCell>
        <TableCell style={expanded ? noBorderStyle : {}}>{advertiser.clicks}</TableCell>
        <TableCell style={expanded ? noBorderStyle : {}}>{isNaN(ctr) ? 0 : ctr}%</TableCell>
      </TableRow>
      {loading && expanded && <Loader />}
      {!loading &&
        expanded &&
        advertiserBannerStats.map((banner, index) => (
          <AdsStatisticsBannersSection
            banner={banner}
            key={banner.bannerid}
            isLast={advertiserBannerStats.length - 1 === index}
          />
        ))}
    </>
  );
};

const TitleRow = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: center;
  cursor: pointer;
  div {
    margin-left: 0.5rem;
  }
`);
const TextHolder = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-width: 12rem;
`;
const Title = withTheme(styled(TextHolder)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
`);

export default AdsStatisticsAdvertiserSection;
