import {
  AdminEmailTemplate,
  AdminEmailTemplatesActionsConstants,
  AdminEmailTemplatesIndexActionBEGIN,
  AdminEmailTemplatesIndexActionFAILED,
  AdminEmailTemplatesIndexActionSUCCESS,
  AdminEmailTemplatesIndexResponse,
  AdminEmailTemplatesKeywordAddedAction,
  AdminEmailTemplatesKeywordGroupAddedAction,
  AdminEmailTemplatesKeywordGroupRemovedAction,
  AdminEmailTemplatesKeywordRemovedAction
} from './types';

export const templatesIndexBegin = (): AdminEmailTemplatesIndexActionBEGIN => ({
  type: AdminEmailTemplatesActionsConstants.INDEX_BEGIN
});

export const templatesIndexSuccess = (
  payload: AdminEmailTemplatesIndexResponse
): AdminEmailTemplatesIndexActionSUCCESS => ({
  type: AdminEmailTemplatesActionsConstants.INDEX_SUCCESS,
  payload
});

export const templatesIndexFailed = (error: any): AdminEmailTemplatesIndexActionFAILED => ({
  type: AdminEmailTemplatesActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const keywordAdded = (template: AdminEmailTemplate): AdminEmailTemplatesKeywordAddedAction => ({
  type: AdminEmailTemplatesActionsConstants.KEYWORD_ADDED,
  payload: template
});

export const keywordGroupAdded = (template: AdminEmailTemplate): AdminEmailTemplatesKeywordGroupAddedAction => ({
  type: AdminEmailTemplatesActionsConstants.KEYWORD_GROUP_ADDED,
  payload: template
});

export const keywordRemoved = (template: AdminEmailTemplate): AdminEmailTemplatesKeywordRemovedAction => ({
  type: AdminEmailTemplatesActionsConstants.KEYWORD_REMOVED,
  payload: template
});

export const keywordGroupRemoved = (template: AdminEmailTemplate): AdminEmailTemplatesKeywordGroupRemovedAction => ({
  type: AdminEmailTemplatesActionsConstants.KEYWORD_GROUP_REMOVED,
  payload: template
});
