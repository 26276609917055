import AuthRoutes from '../components/Pages/Auth/AuthRoutes';
import DashboardRoutes from '../components/Pages/Dashboard/DashboardRoutes';
import AdminUsersRoutes from '../components/Pages/Admin/Users/AdminUsersRoutes';
import linksConstants from '../config/app/linksConstants';
import SignOutPage from '../components/Pages/Auth/SignOut/SignOutPage';
import AdminPrintTemplatesRoutes from '../components/Pages/Admin/PrintTemplates/AdminPrintTemplatesRoutes';
import MyAccountRoutes from '../components/Pages/MyAccount/MyAccountRoutes';
import ProjectDetailsRoutes from '../components/Pages/ProjectDetails/ProjectDetailsRoutes';
import SystemSettingsRoutes from '../components/Pages/SystemSettings/SystemSettingsRoutes';
import SupportRoutes from '../components/Pages/Support/SupportRoutes';
import AdminKeywordsRoutes from '../components/Pages/Admin/Keywords/AdminKeywordsRoutes';
import AdminKeywordGroupsRoutes from '../components/Pages/Admin/KeywordGroups/AdminKeywordGroupsRoutes';
import AdminPasswordPoliciesRoutes from '../components/Pages/Admin/PasswordPolicies/AdminPasswordPoliciesRoutes';
import AdminRolesRoutes from '../components/Pages/Admin/Roles/AdminRolesRoutes';
import AdminEmailTemplatesRoutes from '../components/Pages/Admin/EmailTemplates/AdminEmailTemplatesRoutes';
import MailingListsRoutes from '../components/Pages/MailingLists/MailingListsRoutes';
import AdminOrganizationsRoutes from '../components/Pages/Admin/Organizations/AdminOrganizationsRoutes';
import { AdminRole } from '../store/AdminRoles/types';
import MyOrganizationRoutes from '../components/Pages/MyOrganization/MyOrganizationRoutes';
import CommonRoutes from '../components/Pages/Common/CommonRoutes';
import AdminAdsRoutes from '../components/Pages/Admin/Ads/AdminAdsRoutes';
import EmailStatisticsRoutes from '../components/Pages/EmailStatistics/EmailStatisticsRoutes';
import AdsStatisticsRoutes from '../components/Pages/AdsStatistics/AdsStatisticsRoutes';
import { FlipBookPublicRoutes, FlipBookRoutes } from '../components/Pages/FlipBook/FlipBookRoutes';
import AdminProjectsRoutes from '../components/Pages/Admin/Projects/AdminProjectsRoutes';
import ReportsRoutes from '../components/Pages/Admin/Reports/ReportsRoutes';
import AdminOnlineUsersRoutes from '../components/Pages/Admin/OnlineUsers/AdminOnlineUsersRoutes';
import AdminMailingListsRoutes from '../components/Pages/Admin/MailingLists/AdminMailingListsRoutes';
import AdminEmailTemplatesEditorRoutes from '../components/Pages/Admin/EmailTemplates/AdminEmailTemplatesEditorRoutes';
import { StoriesPublicRoutes, StoriesRoutes } from '../components/Pages/Stories/StoriesRoutes';
import { CalendarPublicRoutes, CalendarRoutes } from '../components/Pages/Calendar/CalendarRoutes';
import SharingCenterRoutes from '../components/Pages/SharingCenter/SharingCenterRoutes';
import EditorRoutes from '../components/Pages/Editors/EditorRoutes';
import CampaignsRoutes from '../components/Pages/Campaigns/CampaignsRoutes';
import DoAppMerchantsRoutes from '../components/Pages/Admin/DoApp/Merchants/DoAppMerchantsRoutes';
import DoAppDashboardRoutes from '../components/Pages/Admin/DoApp/Dashboard/DoAppDashboardRoutes';
import DoAppMerchantDashboardPublicRoutes from '../components/Pages/DoAppMerchantDashboard/DoAppMerchantDashboardRoutes';

/**
 * Creates object with routes that will be connected with admin parts of the application:
 * managing users, API tokens etc.
 * In future additional function parameters may be added in order to parametrize routes rendering (eg. by permissions)
 */
function createSignedInRoutes(accountRole: AdminRole) {
  const rootRoutes: any[] = [
    ...DashboardRoutes,
    ...MailingListsRoutes,
    ...AdminUsersRoutes,
    ...AdminPrintTemplatesRoutes,
    ...AdminEmailTemplatesRoutes,
    ...MyAccountRoutes,
    ...ProjectDetailsRoutes,
    ...SystemSettingsRoutes,
    ...SupportRoutes,
    ...AdminKeywordsRoutes,
    ...AdminKeywordGroupsRoutes,
    ...AdminRolesRoutes,
    ...AdminOrganizationsRoutes,
    ...MyOrganizationRoutes,
    ...AdminPasswordPoliciesRoutes,
    ...AdminAdsRoutes,
    ...EmailStatisticsRoutes,
    ...AdsStatisticsRoutes,
    ...FlipBookRoutes,
    ...AdminProjectsRoutes,
    ...ReportsRoutes,
    ...AdminOnlineUsersRoutes,
    ...AdminMailingListsRoutes,
    ...StoriesRoutes,
    ...CalendarRoutes,
    ...SharingCenterRoutes,
    ...CampaignsRoutes,
    ...DoAppMerchantsRoutes,
    ...DoAppDashboardRoutes,
    {
      path: linksConstants.AUTH.LOGOUT,
      exact: true,
      name: 'logout',
      public: false,
      show: true,
      component: SignOutPage,
      scope: []
    }
  ];
  return rootRoutes.filter((route) => route.show); // && (route.scope.includes(accountRole) || route.scope.length === 0));
}

function createFullPageRoutes() {
  return [...EditorRoutes, ...AdminEmailTemplatesEditorRoutes];
}

function createPublicRoutes() {
  return [
    ...CalendarPublicRoutes,
    ...StoriesPublicRoutes,
    ...FlipBookPublicRoutes,
    ...DoAppMerchantDashboardPublicRoutes
  ];
}

/**
 * Creates object with routes connected with authorization - login, resetting password, verifying password etc.
 * In future additional function parameters may be added in order to parametrize routes rendering (eg. by permissions)
 */
function createAuthRoutes() {
  return [...AuthRoutes, ...CommonRoutes];
}

export default { createSignedInRoutes, createAuthRoutes, createFullPageRoutes, createPublicRoutes };
