export type EmailProjectCreatorState = 'publishDate' | 'printProduct' | 'template' | 'finish';
export type BulletinProjectCreatorState = 'publishDate' | 'template' | 'finish';
export type FlyerProjectCreatorState = 'publishDate' | 'template' | 'finish';
export type DirectoryProjectCreatorState = 'publishDate' | 'template' | 'finish';
export type NewsletterProjectCreatorState = 'publishDate' | 'template' | 'finish';
export type CloneProjectCreatorState = 'publishDate' | 'confirm' | 'finish';
export type DesignProjectCreatorState = 'template' | 'finish';

export enum PrintPublishStep {
  REVIEW = 'review',
  LPI_EXPRESS = 'lpi-express',
  CHOOSE_EMAIL_OPTION = 'choose-email-option',
  SCHEDULE_FORM = 'schedule-form',
  CANCEL = 'cancel',
  EMAIL_PREVIEW = 'email-preview',
  SELECT_EMAIL_TEMPLATE = 'select-email-template',
  ARTICLE_SELECTION = 'article-selection'
}
