import React, { FunctionComponent, useEffect, useState } from 'react';
import { ArticleHorizontal, ArticleVertical, ImageData, ImageDropObjectData } from '../../types';
import styled from 'styled-components';
import { useArticle } from './Utils/useArticle';
import ReadMoreConfigPopover from './Utils/ReadMoreConfigPopover';
import { getBodyElement, getHeadlineElement, getImageElement, getReadMoreLinkElement } from './Utils/articleElements';
import { ImageProcessingResponse } from '../../../../../../store/Images/types';
import Loader from '../../../../../Shared/Loading/Loader';
import { MyOrganizationSwatchColor } from '../../../../../../store/MyOrganization/types';
import { extractColorsFromSwatches, getArticleColorSwatchTypes } from '../../Utils';
import { AdminOrganization } from '../../../../../../store/AdminOrganizations/types';
import useOpenHandler from '../../../../../../hooks/useOpenHandler';
import { ReactComponent as SettingsIcon } from '../../../../../../assets/icons/settings.svg';
import { ComponentActionIcons, StyledEditIcon } from '../../../../../Shared/StyledComponents';
import ArticleSettingsWindow from '../Windows/ArticleSettingsWindow';
import { getDefaultImage } from './Factories/initialComponentFactory';

type ArticleHorizontalProps = {
  data: ArticleHorizontal;
  sourceProjectId: string | null;
  organization: AdminOrganization | null;
  onChange: (data: ArticleHorizontal | ArticleVertical) => void;
  onImageDrop: (data: ImageDropObjectData, imageData: ImageData) => Promise<ImageProcessingResponse | null>;
  selectedColorSwatches: { [key: string]: MyOrganizationSwatchColor };
  onInnerAdjustmentEnabledChange: (innerAdjustmentEnabled: boolean) => void;
};

const ArticleHorizontalComponent: FunctionComponent<ArticleHorizontalProps> = ({
  data,
  onChange,
  onImageDrop,
  selectedColorSwatches,
  sourceProjectId,
  organization,
  onInnerAdjustmentEnabledChange
}) => {
  const {
    mainElement,
    showImage,
    setShowImage,
    showHeadline,
    setShowHeadline,

    showReadMore,
    setShowReadMore,

    customReadMore,
    setCustomReadMore,

    customReadMoreText,
    setCustomReadMoreText,

    customReadMoreLink,
    setCustomReadMoreLink,

    drop,
    isDraggingOver,
    applyingStory,

    readMoreType,
    setReadMoreType,

    flipbookReadMoreLink
  } = useArticle(data, onChange, onImageDrop, sourceProjectId, organization);

  const [imagePosition, setImagePosition] = useState<'left' | 'right'>(
    data.imageWrapperStyle && data.imageWrapperStyle.order === 1 ? 'right' : 'left'
  );

  const [
    articleHorizontalSettingsWindowOpen,
    onArticleHorizontalSettingsWindowOpen,
    onArticleHorizontalSettingsWindowClose
  ] = useOpenHandler();

  useEffect(() => {
    onChange({
      ...data,
      imageWrapperStyle: {
        ...data.imageWrapperStyle,
        order: imagePosition === 'left' ? 0 : 1
      }
    });
  }, [imagePosition]);

  return (
    <ArticleMainWrapper
      ref={(node) => {
        mainElement.current = node;
        drop(node);
      }}
      style={{
        display: 'flex',
        ...data.wrapperStyle,
        ...extractColorsFromSwatches(selectedColorSwatches, getArticleColorSwatchTypes(data))
      }}
      className={isDraggingOver ? 'article-drag-over' : ''}
    >
      <div
        style={{
          ...(data.imageWrapperStyle ? data.imageWrapperStyle : {}),
          flexShrink: 0
        }}
      >
        {getImageElement(data, onChange, onImageDrop, applyingStory, onInnerAdjustmentEnabledChange)}
      </div>

      <div style={data.articleWrapperStyle}>
        <div>
          {showHeadline &&
            getHeadlineElement(data, onChange, selectedColorSwatches, onImageDrop, onInnerAdjustmentEnabledChange)}
          {getBodyElement(data, onChange, selectedColorSwatches)}
          {getReadMoreLinkElement(data, selectedColorSwatches)}
        </div>
      </div>
      <ReadMoreConfigPopover
        storyId={data.storyId}
        sourceProjectId={sourceProjectId}
        flipbookReadMoreLink={flipbookReadMoreLink}
        showImage={showImage}
        setShowImage={setShowImage}
        showReadMore={showReadMore}
        setShowReadMore={setShowReadMore}
        customReadMoreText={customReadMoreText}
        setCustomReadMoreText={setCustomReadMoreText}
        customReadMoreLink={customReadMoreLink}
        setCustomReadMoreLink={setCustomReadMoreLink}
        imagePosition={imagePosition}
        setImagePosition={setImagePosition}
        readMoreType={readMoreType}
        setReadMoreType={setReadMoreType}
        showHeadline={showHeadline}
        setShowHeadline={setShowHeadline}
      />
      {applyingStory && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      <ComponentActionIcons style={{ left: 'calc(100% - 45px)' }}>
        <StyledEditIcon onClick={() => onArticleHorizontalSettingsWindowOpen()}>
          <SettingsIcon style={{ width: 22, fill: '#fff' }} />
        </StyledEditIcon>
      </ComponentActionIcons>

      {articleHorizontalSettingsWindowOpen && (
        <ArticleSettingsWindow
          open={articleHorizontalSettingsWindowOpen}
          onCloseClick={onArticleHorizontalSettingsWindowClose}
          onFormSubmit={(values) => {
            onChange({
              ...data,
              articleHeadlineStyle: {
                ...data.articleHeadlineStyle,
                backgroundColor: values.headlineBackgroundColor
              },
              articleBodyStyle: {
                ...data.articleBodyStyle,
                backgroundColor: values.bodyBackgroundColor
              },
              wrapperStyle: {
                ...data.wrapperStyle,
                backgroundColor: values.backgroundColor
              },
              params: {
                ...data.params,
                showHeadlineImage: values.showHeadlineImage,
                headlineImage: data.params.headlineImage || getDefaultImage(50, 50)
              }
            });
            onArticleHorizontalSettingsWindowClose();
          }}
          backgroundColor={
            data.wrapperStyle && data.wrapperStyle.backgroundColor ? data.wrapperStyle.backgroundColor : '#ffffff'
          }
          headlineBackgroundColor={
            data.articleHeadlineStyle && data.articleHeadlineStyle.backgroundColor
              ? data.articleHeadlineStyle.backgroundColor
              : '#ffffff'
          }
          bodyBackgroundColor={
            data.articleBodyStyle && data.articleBodyStyle.backgroundColor
              ? data.articleBodyStyle.backgroundColor
              : '#ffffff'
          }
          showHeadlineImage={data.params.showHeadlineImage ? true : false}
        />
      )}
    </ArticleMainWrapper>
  );
};

export const ArticleMainWrapper = styled.div`
  position: relative;

  &[data-show-read-more='false'] a[data-placeholder-name='read-more'] {
    display: none;
  }

  .toggle-show-read-more {
    display: none;
    position: absolute;
    top: 98%;
    left: 50%;
    background: #fff;
    border: 1px solid #b7b7b7;
    padding: 0.5rem 2rem;
    transform: translateX(-50%);
    border-radius: 4px;
    box-shadow: #ccc 3px 3px 3px 1px;
    z-index: 10;
    cursor: pointer;
    white-space: nowrap;
  }

  &:hover .toggle-show-read-more {
    display: block;
  }

  &.article-drag-over:after {
    content: 'Drop Article Here...';
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    outline: 4px solid #f39c12;
    font-size: 1rem;
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
`;

export default ArticleHorizontalComponent;
