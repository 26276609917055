import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../utils';
import moment from 'moment';
import { PrintProject } from '../../../../store/PrintProjects/types';
import { printProjectsActions, printProjectsOperations } from '../../../../store/PrintProjects';
import { PrintPublishStep } from '../types';
import PrintPublishReviewStep from './PrintPublishPartials/PrintPublishReviewStep';
import {
  getCancelButtonTextBasedOnStep,
  getConfirmButtonTextBasedOnStep,
  getHeaderBasedOnStep,
  getNextStep
} from './PrintPublishPartials';
import PrintPublishNotSendingAnEmail from './PrintPublishPartials/PrintPublishNotSendingAnEmail';
import PrintPublishLpiExpressStep from './PrintPublishPartials/PrintPublishLpiExpressStep';
import PrintPublishAutomatedEmailStep from './PrintPublishPartials/PrintPublishAutomatedEmailStep';
import PrintPublishWindowLpiExpressDataCenters from './PrintPublishPartials/PrintPublishWindowLpiExpressDataCenters';
import { Button } from '@material-ui/core';
import PrintPublishArticlesSelectionStep from './PrintPublishPartials/PrintPublishArticlesSelectionStep';
import PrintPublishTemplateChooser from './PrintPublishPartials/PrintPublishTemplateChooser';
import PrintPublishFinalizeStep from './PrintPublishPartials/PrintPublishFinalizeStep';
import { EmailProject } from '../../../../store/EmailProjects/types';
import { useEmailPublish } from './EmailPublishPartials/useEmailPublish';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import { Template, TemplateTypeName } from '../../../../store/Templates/types';
import Toast from '../../../Shared/Toast/Toast';
import PrintPublishEmailPreviewStep from './PrintPublishPartials/PrintPublishEmailPreviewStep';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import { PrintCenter } from '../../../../store/SystemSettings/types';
import { isTheme } from '../../../../utils/environment';
import { APP_THEME } from '../../../../typings/environment';
import PrintPublishFormStep from './PrintPublishPartials/PrintPublishFormStep';

type StepActionButtons = {
  confirm: JSX.Element | false;
  cancel: JSX.Element | false;
};

type ProjectPublishPrintWindowProps = {
  open: boolean;
  project: PrintProject | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  onSuccessfulSubmit?: () => void;
  onlyPreview?: boolean;
  reScheduling?: boolean;
};

const ProjectPublishPrintWindow: FunctionComponent<ProjectPublishPrintWindowProps> = ({
  project,
  onCloseClick,
  reScheduling,
  open,
  onSuccessfulSubmit,
  onlyPreview
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true);
  const [customTemplate, setCustomTemplate] = useState<Template | null>(null);
  const [chosenArticles, setChosenArticles] = useState<{ name: string; selectedImageIndex: number }[]>([]);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [previousStep, setPreviousStep] = useState<PrintPublishStep>(PrintPublishStep.REVIEW);
  const [emailOption, setEmailOption] = useState<'express' | 'custom'>('express');
  const [step, setStep] = useState<PrintPublishStep>(PrintPublishStep.REVIEW);
  const [emailProject, setEmailProject] = useState<EmailProject | null>(null);
  const authState = useTypedSelector((state) => state.auth);
  const [printCenters, setPrintCenters] = useState<PrintCenter[]>([]);
  const [activePrintCenter, setActivePrintCenter] = useState<PrintCenter | null>(null);
  const [emailProjectState, setEmailProjectState] = useState<{
    creating: boolean;
    previewGenerating: boolean;
    previewUrl: string;
  }>({
    creating: false,
    previewGenerating: false,
    previewUrl: ''
  });
  const automatedEmailFlowEnabled = reScheduling ? false : authState.organization?.emailWizardEnabled || false;

  const resetToDefaultState = () => {
    setStep(PrintPublishStep.REVIEW);
    setEmailProjectState({
      creating: false,
      previewGenerating: false,
      previewUrl: ''
    });
    setEmailProject(null);
    setPdfUrl('');
    setNumberOfPages(0);
    setEmailOption('express');
    setNextButtonDisabled(false);
  };

  const { formik, formFields, getDefaultEmailSubject } = useEmailPublish(emailProject, {
    displayPublicationDate: true
  });

  const fetchPrintCenters = async () => {
    const res = await myOrganizationOperations.getPrintCenters();
    setPrintCenters(res.data);
    if (res.data.length) {
      setActivePrintCenter(res.data[0]);
    }
  };

  useEffect(() => {
    if (!project) return;

    if (open) {
      dispatch(printProjectsActions.openPublishWindow(project, reScheduling));
      resetToDefaultState();
      fetchPrintCenters();
    }
  }, [open]);

  useEffect(() => {
    if (step === PrintPublishStep.SCHEDULE_FORM) {
      setNextButtonDisabled(!formik.isValid);
    }
  }, [formik.isValid, step]);

  if (!project) return null;

  const closeWindowHandler = async (optedOut: boolean) => {
    if (optedOut && project) {
      try {
        setNextButtonDisabled(true);
        await dispatch(emailProjectsOperations.automatedEmailOptOut(project.id));
      } catch (e) {
        // project has not yet been created...
      } finally {
        setNextButtonDisabled(false);
      }
    }
    dispatch(printProjectsActions.closePublishWindow());
    onCloseClick();
  };

  const getStepContent = (step: PrintPublishStep) => {
    switch (step) {
      case PrintPublishStep.REVIEW:
        return (
          <PrintPublishReviewStep
            project={project}
            reScheduling={reScheduling}
            onlyPreview={!!onlyPreview}
            setNextButtonDisabled={setNextButtonDisabled}
            onPreviewLoaded={(data) => {
              setNumberOfPages(data.pages);
              setPdfUrl(data.url);
            }}
          />
        );

      case PrintPublishStep.LPI_EXPRESS: {
        if (isTheme(APP_THEME.LPI)) {
          return (
            <PrintPublishLpiExpressStep
              setNextButtonDisabled={setNextButtonDisabled}
              project={project}
              numberOfPages={numberOfPages}
              pdfUrl={pdfUrl}
              printCenter={activePrintCenter}
              reScheduling={reScheduling}
            />
          );
        }

        return (
          <PrintPublishFormStep
            project={project}
            printCenters={printCenters}
            activePrintCenter={activePrintCenter}
            numberOfPages={numberOfPages}
            setActivePrintCenter={setActivePrintCenter}
          />
        );
      }

      case PrintPublishStep.CHOOSE_EMAIL_OPTION:
        return (
          <PrintPublishAutomatedEmailStep
            buttonsDisabled={nextButtonDisabled}
            onCancel={() => {
              setPreviousStep(step);
              setStep(PrintPublishStep.CANCEL);
            }}
            onExpressSelected={() => {
              setEmailProjectState({
                creating: false,
                previewGenerating: false,
                previewUrl: ''
              });
              setEmailOption('express');
              setStep(PrintPublishStep.EMAIL_PREVIEW);
            }}
            onCustomSelected={async () => {
              setEmailProjectState({
                creating: false,
                previewGenerating: false,
                previewUrl: ''
              });
              if (!customTemplate) {
                setCustomTemplate(await emailProjectsOperations.getAutomatedEmailTemplate());
              }
              setEmailOption('custom');
              setStep(PrintPublishStep.ARTICLE_SELECTION);
            }}
          />
        );

      case PrintPublishStep.EMAIL_PREVIEW:
        return (
          <PrintPublishEmailPreviewStep
            onInit={async () => {
              setNextButtonDisabled(true);
              try {
                const generatedProject = await reCreateEmailProject({
                  stripUnusedArticleSpots: true
                });
                await new Promise<void>((resolve) => resolve());
                await generatePreviewUrl(generatedProject);
                setNextButtonDisabled(false);
              } catch (e) {
                Toast.error(t('windows.publishPrintProject.createEmailResourcesError'));
              }
            }}
            previewUrl={emailProjectState.previewUrl}
            loading={emailProjectState.previewGenerating || emailProjectState.creating}
          />
        );

      case PrintPublishStep.ARTICLE_SELECTION:
        return (
          <DndProvider backend={HTML5Backend}>
            <PrintPublishArticlesSelectionStep
              project={project}
              chosenArticles={chosenArticles}
              onArticlesChoose={(articles) => setChosenArticles(articles)}
              onTemplateChangeClick={() => {
                setPreviousStep(PrintPublishStep.ARTICLE_SELECTION);
                setStep(PrintPublishStep.SELECT_EMAIL_TEMPLATE);
              }}
              template={customTemplate}
            />
          </DndProvider>
        );

      case PrintPublishStep.SELECT_EMAIL_TEMPLATE:
        return (
          <PrintPublishTemplateChooser
            onCancel={() => {
              setStep(PrintPublishStep.ARTICLE_SELECTION);
            }}
            publishDate={new Date(project.publishDate)}
            onSubmit={async (template) => {
              setCustomTemplate(template);
              setPreviousStep(PrintPublishStep.CHOOSE_EMAIL_OPTION);
              setStep(PrintPublishStep.ARTICLE_SELECTION);
            }}
          />
        );

      case PrintPublishStep.SCHEDULE_FORM:
        return <PrintPublishFinalizeStep form={formFields} />;

      case PrintPublishStep.CANCEL:
        return <PrintPublishNotSendingAnEmail setNextButtonDisabled={setNextButtonDisabled} />;

      default:
        return 'Default content';
    }
  };

  const reCreateEmailProject = async (payload?: {
    template?: Template | null;
    articles?: { name: string; selectedImageIndex: number }[];
    stripUnusedArticleSpots?: boolean;
  }) => {
    setEmailProjectState({
      ...emailProjectState,
      creating: true
    });
    const emailProject = await emailProjectsOperations.createAutomatedEmailContent(
      TemplateTypeName.EMAIL,
      project,
      payload
    );
    setEmailProject(emailProject);
    formik.setFieldValue('publishDate', moment(emailProject.publishDate).format('YYYY-MM-DD'));
    formik.setFieldValue('subject', getDefaultEmailSubject(emailProject));
    setEmailProjectState({
      ...emailProjectState,
      creating: false
    });

    return emailProject;
  };

  const generatePreviewUrl = async (project?: EmailProject) => {
    const projectToUse = project || emailProject;
    if (projectToUse) {
      setEmailProjectState({
        ...emailProjectState,
        previewGenerating: true
      });
      const previewResponse = await emailProjectsOperations.getProjectPreviewUrl(projectToUse);
      setEmailProjectState({
        previewUrl: previewResponse.url,
        creating: false,
        previewGenerating: false
      });
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      disableOnlyXCloseButton={step === PrintPublishStep.CANCEL}
      iframeContent={
        (step === PrintPublishStep.LPI_EXPRESS && isTheme(APP_THEME.LPI)) ||
        step === PrintPublishStep.SELECT_EMAIL_TEMPLATE ||
        step === PrintPublishStep.ARTICLE_SELECTION ||
        step === PrintPublishStep.EMAIL_PREVIEW
      }
      showScrollBar={
        step === PrintPublishStep.SELECT_EMAIL_TEMPLATE ||
        step === PrintPublishStep.EMAIL_PREVIEW ||
        step === PrintPublishStep.ARTICLE_SELECTION
      }
      disableSubmitButton={nextButtonDisabled}
      header={
        <>
          {getHeaderBasedOnStep(step, !!onlyPreview) || <span>&nbsp;</span>}
          {step === PrintPublishStep.LPI_EXPRESS && (
            <PrintPublishWindowLpiExpressDataCenters
              printCenters={printCenters}
              activePrintCenter={activePrintCenter}
              setActivePrintCenter={setActivePrintCenter}
            />
          )}
        </>
      }
      extraButtons={
        step === PrintPublishStep.ARTICLE_SELECTION
          ? [
              <Button
                key="express-articles-selection-preview-btn"
                color="secondary"
                variant={emailProjectState.previewUrl ? 'contained' : 'outlined'}
                size="medium"
                disabled={emailProjectState.creating || emailProjectState.previewGenerating}
                onClick={async () => {
                  if (emailProjectState.previewUrl) {
                    window.open(emailProjectState.previewUrl);
                    setEmailProjectState({
                      ...emailProjectState,
                      previewUrl: ''
                    });
                  } else {
                    try {
                      setNextButtonDisabled(true);
                      const generatedProject = await reCreateEmailProject({
                        articles: chosenArticles,
                        stripUnusedArticleSpots: true,
                        template: customTemplate
                      });
                      await new Promise<void>((resolve) => resolve());
                      await generatePreviewUrl(generatedProject);
                    } catch (e) {
                      Toast.error(t('windows.publishPrintProject.emailPreviewError'));
                      setEmailProjectState({
                        creating: false,
                        previewGenerating: false,
                        previewUrl: ''
                      });
                    } finally {
                      setNextButtonDisabled(false);
                    }
                  }
                }}
              >
                {!emailProjectState.previewUrl &&
                  !emailProjectState.creating &&
                  !emailProjectState.previewGenerating &&
                  t('common.previewEmail')}
                {emailProjectState.previewUrl && t('common.previewReady')}
                {emailProjectState.creating && `${t('common.loading')}...`}
                {emailProjectState.previewGenerating && t('common.generatingPreview')}
              </Button>
            ]
          : []
      }
      disableBackdropClick={true}
      okButtonText={getConfirmButtonTextBasedOnStep(step, emailOption, automatedEmailFlowEnabled)}
      cancelButtonText={getCancelButtonTextBasedOnStep(step)}
      onCloseModalButtonClick={() => {
        if (step === PrintPublishStep.REVIEW || step === PrintPublishStep.LPI_EXPRESS) {
          return closeWindowHandler(false);
        }
        if (step !== PrintPublishStep.CANCEL) {
          setPreviousStep(step);
        }
        setStep(PrintPublishStep.CANCEL);
      }}
      onCloseClick={() => {
        if (step === PrintPublishStep.REVIEW || step === PrintPublishStep.LPI_EXPRESS) {
          return closeWindowHandler(false);
        }
        if (step === PrintPublishStep.EMAIL_PREVIEW) {
          setNextButtonDisabled(false);
          return setStep(PrintPublishStep.CHOOSE_EMAIL_OPTION);
        }
        if (step === PrintPublishStep.CANCEL) {
          return setStep(previousStep);
        }
        if (step === PrintPublishStep.ARTICLE_SELECTION) {
          setNextButtonDisabled(false);
          return setStep(PrintPublishStep.CHOOSE_EMAIL_OPTION);
        }
        if (step === PrintPublishStep.SCHEDULE_FORM && emailOption === 'express') {
          setNextButtonDisabled(false);
          return setStep(PrintPublishStep.EMAIL_PREVIEW);
        }
        if (step === PrintPublishStep.SCHEDULE_FORM && emailOption === 'custom') {
          setNextButtonDisabled(false);
          return setStep(PrintPublishStep.ARTICLE_SELECTION);
        }

        setPreviousStep(step);
        setStep(PrintPublishStep.CANCEL);
      }}
      onSubmit={async () => {
        if (step === PrintPublishStep.LPI_EXPRESS && !isTheme(APP_THEME.LPI)) {
          if (!activePrintCenter) return;

          setNextButtonDisabled(true);

          try {
            await dispatch(
              printProjectsOperations.publishPrintProject(project.id, activePrintCenter.id, numberOfPages)
            );
            Toast.success(t('notifications.projectPublished.success'));
          } catch (e) {
            Toast.error(t('notifications.projectPublished.error'));
            return;
          } finally {
            setNextButtonDisabled(false);
          }
        }
        if (step === PrintPublishStep.LPI_EXPRESS && !automatedEmailFlowEnabled) {
          if (onSuccessfulSubmit) {
            onSuccessfulSubmit();
          }
          return closeWindowHandler(false);
        }
        if (step === PrintPublishStep.CANCEL) {
          return closeWindowHandler(true);
        }
        if (step === PrintPublishStep.SCHEDULE_FORM) {
          try {
            await formik.submitForm();
            if (onSuccessfulSubmit) {
              onSuccessfulSubmit();
            }
            return closeWindowHandler(false);
          } catch (e) {
            // already handled in onSubmit method
            return;
          }
        }

        if (step === PrintPublishStep.ARTICLE_SELECTION) {
          try {
            await reCreateEmailProject({
              articles: chosenArticles,
              stripUnusedArticleSpots: true,
              template: customTemplate
            });
          } catch (e) {
            Toast.error(t('windows.publishPrintProject.createEmailResourcesError'));
            return setNextButtonDisabled(false);
          }
        }

        const nextStep = getNextStep(step);
        if (nextStep === PrintPublishStep.CHOOSE_EMAIL_OPTION) {
          setNextButtonDisabled(false);
        }
        setPreviousStep(step);
        setStep(nextStep);
      }}
      hideActionButtons={
        onlyPreview || step === PrintPublishStep.CHOOSE_EMAIL_OPTION || step === PrintPublishStep.SELECT_EMAIL_TEMPLATE
      }
    >
      {getStepContent(step)}
    </ModalConfirmationWindow>
  );
};

export default ProjectPublishPrintWindow;
