import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { AdminKeywordGroup, AdminUpdateKeywordGroupValues } from '../../../../../store/AdminKeywords/types';
import { adminKeywordsOperations } from '../../../../../store/AdminKeywords';
import { useDispatch } from 'react-redux';
import { AdminTextInput } from '../../Shared/AdminFormInputs';
import Toast from '../../../../Shared/Toast/Toast';
import app from '../../../../../config/app/app';
import { FormikProps } from 'formik';

type AdminUpdateKeywordGroupWindowProps = {
  open: boolean;
  group: AdminKeywordGroup | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminUpdateKeywordGroupWindow: FunctionComponent<AdminUpdateKeywordGroupWindowProps> = ({
  group,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!group) return null;

  const onSubmit = async (values: AdminUpdateKeywordGroupValues) => {
    try {
      await dispatch(
        adminKeywordsOperations.updateGroup(group.id, {
          name: values.name
        })
      );
      Toast.success(t('notifications.adminKeywords.successOperation'));

      await dispatch(adminKeywordsOperations.indexGroups());

      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.adminKeywords.errorOperation'));
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="name" section="adminKeywords" />
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      header={t('pages.adminKeywords.windows.updateKeywordGroup')}
      okButtonText={t('common.update')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{ name: group.name }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(app.maxInputLength).required()
      })}
    />
  );
};

export default AdminUpdateKeywordGroupWindow;
