import React, { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../../../Shared/Loading/Loader';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NoResultsFound } from '../../PageStyledComponents';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import { EmailProject } from '../../../../store/EmailProjects/types';
import Toast from '../../../Shared/Toast/Toast';
import { Button, TextField } from '@material-ui/core';
import { Row } from '../../../Shared/StyledComponents';
import LinkIcon from '@material-ui/icons/Link';

type GenerateLinkWindowProps = {
  open: boolean;
  project: EmailProject;
  onCloseClick: () => void;
};

const GenerateLinkWindow: FunctionComponent<GenerateLinkWindowProps> = ({ open, project, onCloseClick }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [previewUrl, setPreviewUrl] = useState<string>('');

  const onInit = async () => {
    try {
      const data = await emailProjectsOperations.getProjectPreviewUrl(project);
      setPreviewUrl(data.url);
      setLoading(false);
    } catch (e) {
      Toast.error(t('windows.previewEmailProject.generatingLinkError'));
    }
  };

  useEffect(() => {
    onInit();
  }, []);

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('windows.previewEmailProject.emailProjectLinkHeader')}
      disableBackdropClick
      hideActionButtons
      onCloseClick={onCloseClick}
    >
      {loading && (
        <LoaderWrapper>
          <Loader />
          <NoResultsFound>{t('windows.previewEmailProject.loadingLink')}</NoResultsFound>
        </LoaderWrapper>
      )}
      {!loading && (
        <Row style={{ textAlign: 'center' }}>
          <TextField
            id="url-read-only-input"
            defaultValue={previewUrl}
            InputProps={{
              readOnly: true
            }}
            size="small"
            variant="outlined"
            fullWidth
            onFocus={(e) => e.target.select()}
            style={{ marginTop: 20, marginBottom: 5 }}
          />
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              try {
                navigator.clipboard.writeText(previewUrl);
                Toast.success(t('windows.previewEmailProject.linkCopied'));
              } catch (e) {
                Toast.success(t('windows.previewEmailProject.linkCopiedError'));
              }
            }}
          >
            <LinkIcon style={{ marginRight: 5 }} />
            {t('windows.previewEmailProject.copyLink')}
          </Button>
        </Row>
      )}
    </ModalConfirmationWindow>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default GenerateLinkWindow;
