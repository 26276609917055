import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from './Window/ModalConfirmationWindow';
import { Row, Strong } from './StyledComponents';
import { useTypedSelector } from '../../utils';
import { appOperations } from '../../store/App';
import * as pkg from '../../../package.json';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, withTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import WarningIcon from '@material-ui/icons/Warning';

type NewVersionNotificationProps = {};

const NewVersionNotification: FunctionComponent<NewVersionNotificationProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [whatChanged, setWhatChanged] = useState<null | 'web' | 'designer'>(null);
  const authenticated = useTypedSelector((state) => state.auth.authenticated);
  const appVersions = useTypedSelector((state) => state.app.versions.versions);
  const designerVersionFromIframe = useTypedSelector((state) => state.app.versions.designerVersionFromIframe);

  let checkVersionInterval: null | number = null;

  const checkVersion = async () => {
    try {
      await dispatch(appOperations.getVersions());
    } catch (e) {
      console.warn('cannot fetch app version...', e);
    }
  };

  const openWindowAndClearInterval = () => {
    setOpen(true);
    if (checkVersionInterval) {
      clearInterval(checkVersionInterval);
    }
  };

  useEffect(() => {
    if (
      !!window.location.pathname.match(/\/bulletin\/\w+-\w+-\w+-\w+-\w+\/editor/) &&
      appVersions.designer &&
      designerVersionFromIframe &&
      appVersions.designer !== designerVersionFromIframe
    ) {
      setWhatChanged('designer');
      openWindowAndClearInterval();
    }
    if (
      !window.location.pathname.match(/\/bulletin\/\w+-\w+-\w+-\w+-\w+\/editor/) &&
      appVersions.web &&
      appVersions.web !== pkg.version
    ) {
      setWhatChanged('web');
      openWindowAndClearInterval();
    }
  }, [appVersions.web, appVersions.designer, designerVersionFromIframe]);

  useEffect(() => {
    if (authenticated) {
      checkVersion();
      checkVersionInterval = setInterval(async () => {
        await checkVersion();
      }, 60000 * 3);
    } else {
      if (checkVersionInterval) {
        clearInterval(checkVersionInterval);
      }
    }

    return () => {
      if (checkVersionInterval) {
        clearInterval(checkVersionInterval);
      }
    };
  }, [authenticated]);

  return (
    <Wrapper open={open}>
      <TextContainer>
        <div>
          <Strong>{t('windows.newVersionWindow.title')}</Strong>
        </div>
        <div>
          {t('windows.newVersionWindow.infoText')}{' '}
          <Strong style={{ color: '#f5852b' }}>{t('windows.newVersionWindow.warningInfo')}</Strong>{' '}
          <WarningIconStyled />
        </div>
      </TextContainer>
      <ButtonsContainer>
        <Button
          color={'secondary'}
          variant={'contained'}
          size="small"
          onClick={() => {
            if (whatChanged === 'web') {
              window.location.reload();
            }
            if (whatChanged === 'designer') {
              const iframe = document.getElementById('designer-iframe') as HTMLIFrameElement;
              if (iframe) {
                // eslint-disable-next-line no-self-assign
                iframe.src = `${iframe.src}&tt=${new Date().getTime()}`;
              }
              setOpen(false);
            }
          }}
        >
          {t('windows.newVersionWindow.submitButton')}
        </Button>
        <Button color={'secondary'} variant={'text'} size="medium" onClick={() => setOpen(false)}>
          X
        </Button>
      </ButtonsContainer>
    </Wrapper>
  );
};

const Wrapper = withTheme(styled.div<{ open: boolean }>`
  grid-area: system-update;
  height: ${(props) => `${props.open ? 60 : 0}px`};
  background: ${(props) => props.theme.palette.primary.main};
  display: flex;
  color: #fff;
  border-bottom: ${(props) => `${props.open ? 2 : 0}px`} solid ${(props) => props.theme.palette.secondary.main};
  box-sizing: border-box;
  transition: height 1s ease-in-out;
  overflow: hidden;
`);

const TextContainer = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;
const WarningIconStyled = styled(WarningIcon)`
  color: #f5852b;
  vertical-align: bottom;
`;

export default NewVersionNotification;
