import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { TextField } from '@material-ui/core';
import LoadingModal from '../../../Shared/Loading/LoadingModal';
import { DesignProject } from '../../../../store/DesignProjects/types';
import { designProjectsOperations } from '../../../../store/DesignProjects';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import { useDispatch } from 'react-redux';

type CloneDesignProjectWindowProps = {
  open: boolean;
  project: DesignProject | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const CloneDesignProjectWindow: FunctionComponent<CloneDesignProjectWindowProps> = ({
  project,
  onCloseClick,
  open
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [projectName, setProjectName] = useState<string>(project ? project.name : '');
  const [copyingProject, setCopyingProject] = useState<boolean>(false);

  const onSubmit = async () => {
    if (!project) return;
    try {
      setCopyingProject(true);
      const newProject = await designProjectsOperations.cloneProject(project.id, projectName);
      onCloseClick();
      Toast.success(t('notifications.sharingCenter.cloneProjectSuccess'));
      setCopyingProject(false);
      dispatch(push(linksConstants.PROJECTS.DESIGN.EDIT(newProject.id)));
    } catch (e) {
      Toast.error(t('notifications.sharingCenter.cloneProjectError'));
      setCopyingProject(false);
    }
  };

  useEffect(() => {
    if (open && project) {
      setProjectName(project.name);
    }
  }, [open, project]);

  return (
    <>
      {copyingProject && <LoadingModal message={t('pages.sharingCenter.copyingProject')} />}
      <ModalConfirmationWindow
        open={open}
        header={t('pages.dashboard.cloneDesignProject.title')}
        okButtonText={t('pages.sharingCenter.windows.clone')}
        onCloseClick={onCloseClick}
        onSubmit={onSubmit}
        disableSubmitButton={projectName === ''}
      >
        <Row>{t('pages.dashboard.cloneDesignProject.content')}</Row>
        <Row>
          <TextField
            id="project-name"
            value={projectName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProjectName(event.target.value);
            }}
            size="small"
            fullWidth
            label={t('pages.sharingCenter.windows.name')}
            variant="outlined"
          />
        </Row>
      </ModalConfirmationWindow>
    </>
  );
};

export default CloneDesignProjectWindow;
