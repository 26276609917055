import React from 'react';
import { CheckboxState } from './OrganizationTree';
import { Checkbox, makeStyles, useTheme } from '@material-ui/core';
import { AdminOrganization } from '../../../../../store/AdminOrganizations/types';
import styled from 'styled-components';
import { useTypedSelector } from '../../../../../utils';
import OrganizationTile from './OrganizationTile';

export type Item = AdminOrganization;

type CheckboxListProps = {
  items: Item[];
  idsToRender?: string[];
  indentLevel?: number;
  onClick: (id: string) => void;
  getStateForId: (id: string) => CheckboxState;
  showCheckboxes: boolean;
};

const useStyles = makeStyles({
  root: {
    marginLeft: '-38px',
    zIndex: 1,
    '& .MuiIconButton-label': {
      background: '#fff'
    }
  }
});

const CheckboxList: React.FC<CheckboxListProps> = ({
  items,
  getStateForId,
  idsToRender = [],
  indentLevel = 0,
  onClick,
  showCheckboxes
}) => {
  const classes = useStyles();
  const authState = useTypedSelector((state) => state.auth);
  const theme = useTheme();

  if (!idsToRender.length) {
    idsToRender = items.filter((i) => !i.parentId).map((i) => i.id);
  }

  const getChildNodes = (parentId: string) => {
    const nodeItems = items.filter((i) => i.parentId === parentId);
    if (!nodeItems.length) return null;
    return (
      <TreeLi>
        <CheckboxList
          items={items}
          idsToRender={nodeItems.map((i) => i.id)}
          indentLevel={indentLevel + 1}
          onClick={onClick}
          getStateForId={getStateForId}
          showCheckboxes={showCheckboxes}
        />
      </TreeLi>
    );
  };

  const hasChildren = (id: string) => {
    return items.find((item) => item.parentId === id) !== undefined;
  };

  return (
    <TreeUl>
      {idsToRender.map((id) => {
        const item = items.find((i) => i.id === id);
        const checkboxState = getStateForId(id);
        if (!item) {
          return null;
        }

        const rootOrganization = !item.parentId ? { background: '#394764', color: '#fff' } : {};
        const loggedOrganization =
          authState.organizationId === item.id ? { border: `2px solid ${theme.palette.secondary.main}` } : {};

        return (
          <React.Fragment key={item.id}>
            <TreeLi className={`${hasChildren(item.id) && 'hasChildren'} ${!item.parentId && 'root'}`}>
              <TreeTileContainer>
                {item.parentId && showCheckboxes && (
                  <Checkbox
                    size="small"
                    color="default"
                    checked={checkboxState === CheckboxState.CHECKED}
                    // indeterminate={checkboxState === CheckboxState.INDETERMINATE}
                    onClick={() => onClick(item.id)}
                    disabled={authState.organizationId === item.id}
                    className={classes.root}
                  />
                )}
                <OrganizationTile
                  organization={item}
                  style={{
                    ...rootOrganization,
                    ...loggedOrganization
                  }}
                />
              </TreeTileContainer>
            </TreeLi>
            {getChildNodes(item.id)}
          </React.Fragment>
        );
      })}
    </TreeUl>
  );
};

const TreeUl = styled.ul`
  margin-left: 20px;
  & .hasChildren:nth-last-child(2):after {
    border-left: 0;
  }
  & li:has(> ul):nth-child(2):before {
    border: 0;
  }
  & li:has(> ul):last-child:before {
    border: 0;
  }
  &:has(> .root) {
    margin-left: 0;
  }
`;

const TreeLi = styled.li`
  list-style-type: none;
  margin: 10px 0 10px 10px;
  position: relative;
  &.root {
    &:before,
    &:after {
      display: none;
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -20px;
    border-left: 1px dashed #ddd;
    border-bottom: 1px dashed #ddd;
    width: 20px;
    height: 38px;
  }
  &:after {
    position: absolute;
    content: '';
    top: -10px;
    left: -20px;
    border-left: 1px dashed #ddd;
    border-top: 0;
    width: 20px;
    height: calc(100% + 10px);
  }
  &:last-child:after {
    display: none;
  }
  &:hover + li ul li div div,
  &:focus + li ul li div div {
    background: #e1e9f2;
  }
`;

const TreeTileContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default CheckboxList;
