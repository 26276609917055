import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Link, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import Loader from '../../../../Shared/Loading/Loader';
import { DoAppRunningPromotionReport } from '../../../../../store/DoApp/Reports/types';
import { doAppReportsOperations } from '../../../../../store/DoApp/Reports';
import { WidgetTitle } from '../../../../Shared/ListingWidget/WidgetTitle';
import { WidgetSearchInput } from '../../../../Shared/ListingWidget/WidgetSearchInput';
import { WidgetPagination } from '../../../../Shared/ListingWidget/WidgetPagination';
import { WidgetFetchError } from '../../../../Shared/ListingWidget/WidgetFetchError';
import { formatPublishDate } from '../../../../../utils';
import DoAppRunningPromotionReportContent from '../windows/DoAppRunningPromotionReportContent';
import theme from '../../../../../config/themes/btl';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import DoAppDeleteReportWindow from '../windows/DoAppDeleteReportWindow';

type DoAppRunningPromotionReportsListProps = {};

const DoAppRunningPromotionReportsList: FunctionComponent<DoAppRunningPromotionReportsListProps> = () => {
  const { t } = useTranslation();
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [lastSearchValue, setLastSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [runningPromotionReports, setRunningPromotionReports] = useState<DoAppRunningPromotionReport[]>([]);
  const [activeReport, setActiveReport] = useState<DoAppRunningPromotionReport | null>(null);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 10, q: '', total: 0, totalPages: 0 });
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [deleteReportWindowOpen, onDeleteReportWindowOpen, onDeleteReportWindowClose] = useOpenHandler();

  const fetchData = async (page: number, search: string) => {
    setFetchError(false);
    try {
      const runningPromotionReports = await doAppReportsOperations.indexRunningPromotionReportsRaw(
        page,
        10,
        search,
        {}
      );
      setRunningPromotionReports(runningPromotionReports.data);
      setPagination(runningPromotionReports.pagination);
      setLastSearchValue(search);
    } catch (e) {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1, '');
  }, []);

  const handleOpenDialog = (report: DoAppRunningPromotionReport) => {
    setActiveReport(report);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setActiveReport(null);
  };

  useEffect(() => {
    const onEnter = (e: any) => {
      if (e.key === 'Enter') {
        fetchData(1, searchValue);
      }
    };
    document.addEventListener('keydown', onEnter);
    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [searchValue]);

  const renderList = () => {
    return (
      <>
        <WidgetTitle title={t('pages.doApp.runningPromotionReports.title')} />
        <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
        {loading && <Loader />}
        <WidgetSearchInput
          placeholder={`${t('common.search')}...`}
          value={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={fetchData}
        />
        {fetchError && <WidgetFetchError title={t('pages.doApp.runningPromotionReports.fetchError')} />}
        <List>
          {runningPromotionReports.map((r) => (
            <React.Fragment key={r.id}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={r.id}
                  secondary={
                    <>
                      {t('pages.doApp.runningPromotionReports.content')}:{' '}
                      <Link
                        component="button"
                        color="secondary"
                        variant="body2"
                        onClick={() => handleOpenDialog(r)}
                        style={{ fontWeight: 'bold' }}
                      >
                        {t('pages.doApp.runningPromotionReports.show')}
                      </Link>
                    </>
                  }
                />
                <ListItemText
                  primary={
                    <>
                      {t('pages.doApp.runningPromotionReports.valid')}:{' '}
                      {r.valid ? (
                        <span style={{ color: theme.palette.success.main }}>{t('common.yes')}</span>
                      ) : (
                        <span style={{ color: theme.palette.error.main }}>{t('common.no')}</span>
                      )}
                    </>
                  }
                  secondary={
                    <>
                      <div>
                        {t('pages.doApp.runningPromotionReports.createdAt')}:{' '}
                        {r.createdAt ? formatPublishDate(r.createdAt) : '---'}
                      </div>
                      <div>
                        {t('pages.doApp.runningPromotionReports.appliedAt')}:{' '}
                        {r.appliedAt ? formatPublishDate(r.appliedAt) : '---'}
                      </div>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="outlined"
                    onClick={async () => {
                      setActiveReport(r);
                      onDeleteReportWindowOpen();
                    }}
                  >
                    {t('common.remove')}
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {pagination.total > 0 && (
            <WidgetPagination
              pagination={{ page: pagination.page, totalPages: pagination.totalPages }}
              handlePaginationChange={(val) => fetchData(val, searchValue)}
            />
          )}
        </div>

        {activeReport && (
          <DoAppRunningPromotionReportContent
            open={openDialog}
            content={activeReport.content}
            onCloseClick={handleCloseDialog}
          />
        )}
        {activeReport && (
          <DoAppDeleteReportWindow
            open={deleteReportWindowOpen}
            report={activeReport}
            onCloseClick={onDeleteReportWindowClose}
            onSubmit={() => fetchData(1, lastSearchValue)}
          />
        )}
      </>
    );
  };

  return renderList();
};

export default DoAppRunningPromotionReportsList;
