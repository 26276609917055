import React, { FunctionComponent } from 'react';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PrintPublishStepper, { PrintPublishStep } from './Items/PrintPublishStepper';
import { ReactComponent as ManageIcon } from '../../../../../assets/icons/manage.svg';
import { ReactComponent as EmailDigestIcon } from '../../../../../assets/icons/email_digest.svg';

type PrintPublishAutomatedEmailStepProps = {
  buttonsDisabled: boolean;
  onCancel: () => void;
  onExpressSelected: () => void;
  onCustomSelected: () => void;
};

const PrintPublishAutomatedEmailStep: FunctionComponent<PrintPublishAutomatedEmailStepProps> = ({
  buttonsDisabled,
  onCancel,
  onExpressSelected,
  onCustomSelected
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: 'center' }}>
      <PrintPublishStepper activeStep={PrintPublishStep.Email} />
      <Grid container spacing={3}>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <StyledButton disabled={buttonsDisabled} onClick={onExpressSelected}>
            <EmailDigestIcon />
            {t('pages.projectPublish.emailDigestExpress')}
          </StyledButton>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'left' }}>
          <StyledButton disabled={buttonsDisabled} onClick={onCustomSelected}>
            {buttonsDisabled ? <CircularProgress size={70} color="secondary" /> : <ManageIcon />}
            {t('pages.projectPublish.customizeTemplate')}
          </StyledButton>
        </Grid>
      </Grid>
      <StyledOptOutButton variant="text" disabled={buttonsDisabled} onClick={onCancel}>
        {t('pages.projectPublish.optOutButton')}
      </StyledOptOutButton>
    </div>
  );
};

export default PrintPublishAutomatedEmailStep;

const StyledButton = styled(Button)`
  border: 2px solid #009a9f;
  background: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  color: #009a9f;
  text-transform: initial;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 140px;
  line-height: 18px;

  .MuiButton-label {
    display: flex;
    flex-direction: column;
    svg {
      width: 70px;
      height: 70px;
      fill: #009a9f;
    }
  }
  .MuiCircularProgress-root {
    svg {
      padding: 10px;
    }
  }
`;

const StyledOptOutButton = styled(Button)`
  font-style: italic;
  text-transform: initial;
  font-size: 90%;
  margin: 10px auto;
`;
