import { Dictionary } from 'lodash';
import { ProjectNote } from '../Notes/types';
import { TemplateTypeName } from '../Templates/types';
import { ShortUser } from '../Auth/types';
import { ProjectCheckedOutAction, TestFlagToggledAction } from '../Dashboard/types';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { ProjectShare } from '../SharingCenter/types';
import { AdminOrganization } from '../AdminOrganizations/types';

export type PrintProject = {
  id: string;
  name: string;
  test: boolean;
  kind: 'print';
  subject: string | null;
  flipBookState: FlipBookState | null;
  templateId: string;
  templateDescription: string;
  type: TemplateTypeName;
  updatedAt: Date;
  createdAt: Date;
  status: PrintProjectStatus;
  publishDate: string;
  updatedByAt: Date;
  updatedBy?: ShortUser;
  checkedInBy?: ShortUser;
  notes: ProjectNote[];
  externalId: number;
  hash: string;
  printProjectUnique: 'printProjectUnique';
  mailingListId: string | null;
  automated: null;
  thumbnailUrl: string | null;
  shares: ProjectShare[];
  isShared: boolean;
  organization?: AdminOrganization;
  organizationId: string;
  shareContext?: boolean;
  vertexErrors: VertexError[];
};

export enum FlipBookState {
  WAITING_FOR_PROCESSED_PDF = 'WAITING_FOR_PROCESSED_PDF',
  DOWNLOADING_PROCESSED_PDF = 'DOWNLOADING_PROCESSED_PDF',
  DOWNLOAD_PROCESSED_PDF_ERROR = 'DOWNLOAD_PROCESSED_PDF_ERROR',
  UPLOADING = 'UPLOADING',
  UPLOADING_ERROR = 'UPLOADING_ERROR',
  GENERATING = 'GENERATING',
  GENERATION_ERROR = 'GENERATION_ERROR',
  DONE = 'DONE'
}

export enum PrintProjectStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SCHEDULED = 'SCHEDULED',
  DELIVERED = 'DELIVERED'
}

export const printProjectStatus = [
  PrintProjectStatus.IN_PROGRESS,
  PrintProjectStatus.SCHEDULED,
  PrintProjectStatus.DELIVERED
];

export type VertexError = {
  id: string;
  updatedAt: Date;
  createdAt: Date;
  message: string;
  printCenterId: string | null;
};

export type DesignerStory = {
  name: string;
  title: string;
  images: DesignerStoryImage[];
  body: string;
  id?: number;
};

export type DesignerStoryImage = { url: string; link?: string; selected?: boolean };

export type PrintProjectsState = StateWithPagination &
  StateWithTableSearch<PrintProject> & {
    projects: NormalizedPrintProjects;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };

    publishWindow: {
      opened: boolean;
      renderJobStatus: 'inProgress' | 'done' | 'error';
      project: PrintProject | null;
    };
    flipBookPreview: {
      project: PrintProject | null;
    };
  };

export type NormalizedPrintProjects = {
  entities: Dictionary<PrintProject>;
  result: string[];
};

export type IndexPrintUserProjectsRequestParams = {
  per: number;
  page: number;
  order?: string[];
  q: string | null;
  status?: string;
};

export type GetUserPrintProjectsResponse = {
  data: PrintProject[];
  pagination: Pagination;
};

export type PrintProjectClone = {
  id: string;
  createdAt: string;
  sourceId: string;
  target: PrintProject;
  createdBy: ShortUser;
};

export type PrintProjectClonesResponse = {
  data: PrintProjectClone[];
};

export type PrintProjectsIndexActionBEGIN = {
  type: PrintProjectsActionsConstants.INDEX_BEGIN;
};

export type PrintProjectsIndexActionSUCCESS = {
  type: PrintProjectsActionsConstants.INDEX_SUCCESS;
  payload: GetUserPrintProjectsResponse;
};

export type PrintProjectsIndexActionFAILED = {
  type: PrintProjectsActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type SharePrintProjectAction = {
  type: PrintProjectsActionsConstants.SHARE_PROJECT;
  payload: string;
};

export type RemovePrintProjectAction = {
  type: PrintProjectsActionsConstants.REMOVE_PROJECT;
  payload: string;
};

export type PublishPrintProjectAction = {
  type: PrintProjectsActionsConstants.PUBLISH_PROJECT;
  payload: PrintProject;
};

export type PublishPrintProjectWindowRenderingDoneAction = {
  type: PrintProjectsActionsConstants.PUBLISH_PROJECT_WINDOW_JOB_RENDERING_DONE;
  payload: { projectId: string };
};

export type PublishPrintProjectWindowRenderingErrorAction = {
  type: PrintProjectsActionsConstants.PUBLISH_PROJECT_WINDOW_JOB_RENDERING_ERROR;
  payload: { projectId: string };
};

export type PublishPrintProjectWindowOpenAction = {
  type: PrintProjectsActionsConstants.PUBLISH_PROJECT_WINDOW_OPEN;
  payload: {
    project: PrintProject;
    reScheduling?: boolean;
  };
};

export type PublishPrintProjectWindowCloseAction = {
  type: PrintProjectsActionsConstants.PUBLISH_PROJECT_WINDOW_CLOSE;
};

export type CheckInPrintProjectAction = {
  type: PrintProjectsActionsConstants.CHECK_IN_PROJECT;
  payload: {
    projectId: string;
    user: ShortUser;
  };
};

export type SetFlipBookProjectAction = {
  type: PrintProjectsActionsConstants.SET_FLIP_BOOK_PROJECT;
  payload: {
    project: PrintProject | null;
  };
};

export type SetFlipBookStateAction = {
  type: PrintProjectsActionsConstants.SET_FLIP_BOOK_STATE;
  payload: {
    projectId: string;
    state: FlipBookState | null;
  };
};

export type GetDesignerArticlesResponse = {
  data: DesignerStory[];
};

export enum PrintProjectsActionsConstants {
  PUBLISH_PROJECT_WINDOW_JOB_RENDERING_ERROR = 'PROJECTS/PRINT/PUBLISH_PROJECT_WINDOW_JOB_RENDERING_ERROR',
  PUBLISH_PROJECT_WINDOW_JOB_RENDERING_DONE = 'PROJECTS/PRINT/PUBLISH_PROJECT_WINDOW_JOB_RENDERING_DONE',
  PUBLISH_PROJECT_WINDOW_CLOSE = 'PROJECTS/PRINT/PUBLISH_PROJECT_WINDOW_CLOSE',
  PUBLISH_PROJECT_WINDOW_OPEN = 'PROJECTS/PRINT/PUBLISH_PROJECT_WINDOW_OPEN',
  CHECK_IN_PROJECT = 'PROJECTS/PRINT/CHECK_IN_PROJECT',
  SET_FLIP_BOOK_PROJECT = 'PROJECTS/PRINT/SET_FLIP_BOOK_PROJECT',
  SET_FLIP_BOOK_STATE = 'PROJECTS/PRINT/SET_FLIP_BOOK_STATE',
  PUBLISH_PROJECT = 'PROJECTS/PRINT/PUBLISH_PROJECT',
  REMOVE_PROJECT = 'PROJECTS/PRINT/REMOVE',
  SHARE_PROJECT = 'PROJECTS/PRINT/SHARE',
  INDEX_BEGIN = 'PROJECTS/PRINT/INDEX/BEGIN',
  INDEX_SUCCESS = 'PROJECTS/PRINT/INDEX/SUCCESS',
  INDEX_FAILED = 'PROJECTS/PRINT/INDEX/FAILED'
}

export type PrintProjectsActionType =
  | PrintProjectsIndexActionBEGIN
  | PrintProjectsIndexActionSUCCESS
  | PrintProjectsIndexActionFAILED
  | PublishPrintProjectAction
  | RemovePrintProjectAction
  | CheckInPrintProjectAction
  | ProjectCheckedOutAction
  | PublishPrintProjectWindowRenderingDoneAction
  | PublishPrintProjectWindowRenderingErrorAction
  | PublishPrintProjectWindowOpenAction
  | PublishPrintProjectWindowCloseAction
  | SetFlipBookProjectAction
  | SetFlipBookStateAction
  | TestFlagToggledAction;

export type FlipBookMeta = {
  id: string;
  pages: {
    low: FlipBookPage[];
    high: FlipBookPage[];
  };
};

export type FlipBookPage = {
  path: string;
};
