import React, { useState, useEffect } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Tooltip, useTheme } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getOrganizationChannelTypes, getStoryChannelStatus } from '../Utils/storyUtils';
import { hasPermission } from '../../../../utils/permissions';
import {
  StoryChannel,
  StoryChannelStatus,
  StoryChannelType,
  StoryEmailChannelPriority
} from '../../../../store/Stories/types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PrintIcon } from '../../../../assets/icons/channels/print.svg';
import { ReactComponent as EmailIcon } from '../../../../assets/icons/channels/email.svg';
import { ReactComponent as FacebookIcon } from '../../../../assets/icons/channels/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../../../assets/icons/channels/x.svg';
import { ReactComponent as InstagramIcon } from '../../../../assets/icons/channels/instagram.svg';
import { ReactComponent as LinkedinIcon } from '../../../../assets/icons/channels/linkedin.svg';
import { ReactComponent as BlogIcon } from '../../../../assets/icons/channels/wordpress.svg';
import { AdminRole } from '../../../../store/AdminRoles/types';
import ChannelForm from './ChannelForm';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { useTypedSelector } from '../../../../utils';
import { storiesOperations } from '../../../../store/Stories';
import DeleteStoryChannelWindow from '../Windows/DeleteStoryChannelWindow';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CreateWithAiWindow from '../Windows/CreateWithAiWindow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as CreateWithAiIcon } from '../../../../assets/icons/create_with_ai.svg';
import { Alert } from '@material-ui/lab';
import { ReactComponent as TipsAndUpdatesIcon } from '../../../../assets/icons/tips_and_updates.svg';
import { ReactComponent as MouseIcon } from '../../../../assets/icons/mouse.svg';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import { AdminOrganization } from '../../../../store/AdminOrganizations/types';
import PublishSelectedChannelsWindow from '../Windows/PublishSelectedChannelsWindow';
import { useDispatch } from 'react-redux';
import { authOperations } from '../../../../store/Auth';
import { WordpressIntegration } from '../../../../store/MyOrganization/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import Loader from '../../../Shared/Loading/Loader';
import Toast from '../../../Shared/Toast/Toast';

interface ChannelsProps {
  role: AdminRole;
  organization: AdminOrganization | null;
  channelsOrder: StoryChannelType[];
  channelsData: StoryChannel[];
  storyId: string;
  fetchStoryData: () => Promise<React.ReactText | undefined>;
  handleChannelChange: (channel: StoryChannelType) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  setChannelStatus: (
    channel: StoryChannel,
    status: StoryChannelStatus,
    muteToast: boolean,
    prevStatus?: StoryChannelStatus,
    priority?: StoryEmailChannelPriority
  ) => void;
  isOwnerOfStory: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  channelContainer: {
    '&:hover': {
      backgroundColor: '#F5F5F5 !important'
    }
  },
  alertMessage: {
    '& .MuiAlert-message': {
      width: '100%',
      whiteSpace: 'pre-wrap'
    }
  }
}));

const Channels: React.FC<ChannelsProps> = ({
  role,
  organization,
  channelsOrder,
  channelsData,
  storyId,
  fetchStoryData,
  handleChannelChange,
  setChannelStatus,
  isOwnerOfStory
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const user = useTypedSelector((state) => state.auth.user);
  const aiGeneration = useTypedSelector((state) => state.stories.aiGeneration);
  const authState = useTypedSelector((state) => state.auth);
  const [selectedChannels, setSelectedChannels] = useState<StoryChannelType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [channelsToPublish, setChannelsToPublish] = useState<StoryChannel[]>([]);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [activeChannel, setActiveChannel] = useState<StoryChannel | undefined>(undefined);
  const [channelToDelete, setChannelToDelete] = useState<StoryChannel | null>(null);
  const [channelAddClicked, setChannelAddClicked] = useState(false);
  const [wordpressIntegrations, setWordpressIntegrations] = useState<WordpressIntegration[]>([]);
  const [
    deleteStoryChannelWindowOpen,
    onDeleteStoryChannelWindowOpen,
    onDeleteStoryChannelWindowClose
  ] = useOpenHandler();
  const [createWithAiWindowOpen, onCreateWithAiWindowOpen, onCreateWithAiWindowClose] = useOpenHandler();
  const [
    publishSelectedChannelsWindowOpen,
    onPublishSelectedChannelsWindowOpen,
    onPublishSelectedChannelsWindowClose
  ] = useOpenHandler();

  const socialAccount = authState.socialAccounts[0];

  const fetchIntegrations = async () => {
    setLoading(true);
    try {
      const wordpressIntegrations = await myOrganizationOperations.getWordpressIntegrations({ withCategories: true });
      setWordpressIntegrations(wordpressIntegrations.data);
    } catch (e) {
      Toast.error(t('notifications.story.cannotFetchWordpressIntegrations'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(authOperations.getOrganizationSocialAccountsData());
    fetchIntegrations();
  }, []);

  useEffect(() => {
    if (aiGeneration.channelsDone.length > 0 && aiGeneration.storyId === storyId) {
      fetchStoryData();
    }
  }, [aiGeneration]);

  useEffect(() => {
    if (selectedChannels.length > 0) {
      setChannelsToPublish(
        channelsData.filter(
          (chD) =>
            selectedChannels.includes(chD.type) &&
            chD.status === StoryChannelStatus.APPROVED &&
            ![StoryChannelType.PRINT, StoryChannelType.EMAIL].includes(chD.type)
        )
      );
    }
  }, [setChannelStatus]);

  const shouldRender = (storyChannelType: StoryChannelType) => {
    if (
      organization &&
      [organization.bulletin, organization.flyer, organization.newsletter, organization.directory].includes(true) &&
      storyChannelType === StoryChannelType.PRINT
    ) {
      return true;
    } else if (organization && organization.email && storyChannelType === StoryChannelType.EMAIL) {
      return true;
    } else if (
      organization?.socialPublishing &&
      socialAccount &&
      socialAccount.facebookPagesConnected &&
      storyChannelType === StoryChannelType.FACEBOOK
    ) {
      return true;
    } else if (
      organization?.socialPublishing &&
      socialAccount &&
      socialAccount.instagramConnected &&
      storyChannelType === StoryChannelType.INSTAGRAM
    ) {
      return true;
    } else if (
      organization?.socialPublishing &&
      socialAccount &&
      socialAccount.linkedinConnected &&
      storyChannelType === StoryChannelType.LINKEDIN
    ) {
      return true;
    } else if (
      organization?.socialPublishing &&
      socialAccount &&
      socialAccount.twitterConnected &&
      storyChannelType === StoryChannelType.TWITTER
    ) {
      return true;
    } else if (wordpressIntegrations.length > 0 && storyChannelType === StoryChannelType.BLOG) {
      return true;
    }
    return false;
  };

  const storyChannelTypes = getOrganizationChannelTypes(organization)
    .sort((a, b) => (channelsOrder.indexOf(a) < channelsOrder.indexOf(b) ? -1 : 1))
    .filter(shouldRender);

  useEffect(() => {
    const totalChannels = storyChannelTypes.length;
    const selectedCount = selectedChannels.length;

    setSelectAllChecked(selectedCount === totalChannels);
    setIndeterminate(selectedCount > 0 && selectedCount < totalChannels);
  }, [selectedChannels, storyChannelTypes]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedChannels(storyChannelTypes);
      setChannelsToPublish(
        channelsData.filter(
          (chD) =>
            storyChannelTypes.includes(chD.type) &&
            chD.status === StoryChannelStatus.APPROVED &&
            ![StoryChannelType.PRINT, StoryChannelType.EMAIL].includes(chD.type)
        )
      );
    } else {
      setSelectedChannels([]);
      setChannelsToPublish([]);
    }
  };

  const handleCheckboxChange = (channelType: StoryChannelType) => {
    setSelectedChannels((prevSelectedChannels) => {
      const newSelectedChannels = prevSelectedChannels.includes(channelType)
        ? prevSelectedChannels.filter((ch) => ch !== channelType)
        : [...prevSelectedChannels, channelType];

      setChannelsToPublish(
        channelsData.filter(
          (chD) =>
            newSelectedChannels.includes(chD.type) &&
            chD.status === StoryChannelStatus.APPROVED &&
            ![StoryChannelType.PRINT, StoryChannelType.EMAIL].includes(chD.type)
        )
      );

      return newSelectedChannels;
    });
  };

  const handleChannelClick = async (storyChannelType: StoryChannelType) => {
    if (aiGeneration.storyId !== storyId && !channelAddClicked) {
      const clickedChannel = channelsData.find((chD) => chD.type === storyChannelType);
      if (clickedChannel) {
        await fetchStoryData();
        setActiveChannel(clickedChannel);
      } else {
        setChannelAddClicked(true);
        const createdChannel = await storiesOperations.createChannel(storyId, storyChannelType, []);
        await fetchStoryData();
        setActiveChannel(createdChannel);
        setChannelAddClicked(false);
      }
    }
  };

  const getStoryChannelStatusByType = (type: StoryChannelType) => {
    const channelData = channelsData.find((chD) => chD.type === type);
    if (channelData) {
      return (
        <div style={{ marginTop: 2 }}>
          {getStoryChannelStatus(channelData.status, t, theme, channelData.type, channelData.priority)}
        </div>
      );
    }
    return null;
  };

  const getStoryChannelAuthorByType = (type: StoryChannelType) => {
    const channelData = channelsData.find((chD) => chD.type === type);

    if (channelData && channelData.createdBy) {
      return (
        <Box display="flex" alignItems="center" fontSize="12px" marginLeft={1}>
          <Tooltip
            title={
              (
                <>
                  {channelData.createdBy.fullName}
                  <br />
                  {channelData.createdBy.email}
                </>
              ) || ''
            }
            placement="bottom"
            arrow
          >
            <AssignmentIndIcon titleAccess={t('pages.story.author')} style={{ width: '20px', height: '20px' }} />
          </Tooltip>
        </Box>
      );
    }
    return null;
  };

  const isChannelPublishedOrScheduled = (type: StoryChannelType) => {
    const channelData = channelsData.find((chD) => chD.type === type);
    if (channelData) {
      return [StoryChannelStatus.PUBLISHED, StoryChannelStatus.SCHEDULED].includes(channelData.status);
    }
    return false;
  };

  const isDeleteChannelDisabled = (type: StoryChannelType) => {
    return (
      !hasPermission(role, ['storiesEditChannels']) ||
      aiGeneration.storyId === storyId ||
      isChannelPublishedOrScheduled(type)
    );
  };

  const activeChannelTypes = storyChannelTypes.filter((storyChannelType) =>
    channelsData.find((channel) => channel.type === storyChannelType)
  );

  return (
    <div style={{ marginTop: '15px' }}>
      {aiGeneration.storyId === storyId && (
        <Alert variant="filled" severity="warning" className={classes.alertMessage} style={{ marginBottom: 10 }}>
          {t('pages.story.contentCreationInProgress').toString()}
        </Alert>
      )}
      {storyChannelTypes.length > 0 && (
        <Box display="flex" alignItems="center" mb={2}>
          <FormControlLabel
            style={{
              border: '1px solid #E4E4E4',
              borderRadius: '6px',
              marginLeft: '0px',
              paddingRight: '10px'
            }}
            control={
              <Checkbox
                indeterminate={indeterminate}
                checked={selectAllChecked}
                onChange={handleSelectAll}
                color="secondary"
                inputProps={{ 'aria-label': 'select all channels' }}
                disabled={!hasPermission(role, ['storiesEditChannels']) || aiGeneration.storyId === storyId}
              />
            }
            label={`${selectedChannels.length} selected`}
          />
          {hasPermission(role, ['storiesEditChannels']) && hasPermission(role, ['storiesAiContent']) && (
            <Button
              size="large"
              color="secondary"
              variant="outlined"
              disabled={aiGeneration.storyId.length > 0 || selectedChannels.length === 0}
              onClick={async () => {
                await fetchStoryData();
                onCreateWithAiWindowOpen();
              }}
            >
              {aiGeneration.storyId.length > 0 ? (
                <CircularProgress size={11} color="secondary" style={{ marginRight: 6 }} />
              ) : (
                <CreateWithAiIcon
                  fill={selectedChannels.length === 0 ? theme.palette.text.disabled : theme.palette.secondary.main}
                  width={16}
                  height={16}
                  style={{ marginRight: 6 }}
                />
              )}
              {t('pages.story.createWithAi')}
            </Button>
          )}
          {hasPermission(role, ['storiesEditChannels']) && (
            <Button
              size="large"
              color="secondary"
              variant="contained"
              disabled={channelsToPublish.length === 0}
              onClick={onPublishSelectedChannelsWindowOpen}
              style={{ marginLeft: '10px' }}
              startIcon={<PublishOutlinedIcon />}
            >
              {t('pages.story.publishSelected')} ({channelsToPublish.length})
            </Button>
          )}
        </Box>
      )}
      {loading && <Loader />}
      {!loading && (
        <Grid container>
          <Grid item sm={4} md={3}>
            <div
              style={{
                height: '41px',
                borderBottom: '1px solid #e8e8e8',
                marginBottom: '10px',
                fontWeight: 'bold',
                paddingLeft: '42px',
                paddingTop: '7px'
              }}
            >
              {t('pages.story.channel')}
            </div>
            {storyChannelTypes.map((storyChannelType) => (
              <Box
                key={`channel-tile-${storyChannelType}`}
                className={classes.channelContainer}
                display="flex"
                alignItems="center"
                mr={5}
                style={{
                  backgroundColor: activeChannel && activeChannel.type === storyChannelType ? '#F5F5F5' : 'transparent'
                }}
              >
                <Checkbox
                  color="secondary"
                  checked={selectedChannels.includes(storyChannelType)}
                  onChange={() => handleCheckboxChange(storyChannelType)}
                  inputProps={{ 'aria-label': `checkbox for ${storyChannelType}` }}
                  disabled={aiGeneration.storyId === storyId}
                />
                <Box
                  onClick={() => handleChannelClick(storyChannelType)}
                  style={{ flex: 1, cursor: 'pointer' }}
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{
                      fontWeight: activeChannel && activeChannel.type === storyChannelType ? 'bold' : 'normal'
                    }}
                  >
                    {getChannelIcon(storyChannelType, theme)}&nbsp;{t(`pages.story.channels.${storyChannelType}`)}
                  </Box>
                  {getStoryChannelStatusByType(storyChannelType)}
                </Box>
                <div>{getStoryChannelAuthorByType(storyChannelType)}</div>
                {channelsData.find((channel) => channel.type === storyChannelType) ? (
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      const clickedChannel = channelsData.find((channel) => channel.type === storyChannelType);
                      if (clickedChannel) {
                        setChannelToDelete(clickedChannel);
                        onDeleteStoryChannelWindowOpen();
                      }
                    }}
                    disabled={isDeleteChannelDisabled(storyChannelType)}
                  >
                    <ClearIcon color={isDeleteChannelDisabled(storyChannelType) ? 'disabled' : 'error'} />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="add"
                    disabled={!hasPermission(role, ['storiesEditChannels']) || aiGeneration.storyId === storyId}
                    onClick={async () => {
                      if (aiGeneration.storyId !== storyId && !channelAddClicked) {
                        setChannelAddClicked(true);
                        const createdChannel = await storiesOperations.createChannel(storyId, storyChannelType, []);
                        await fetchStoryData();
                        setActiveChannel(createdChannel);
                        setChannelAddClicked(false);
                      }
                    }}
                  >
                    <AddIcon color="secondary" />
                  </IconButton>
                )}
              </Box>
            ))}
          </Grid>
          <Grid item sm={8} md={9}>
            {activeChannel ? (
              <ChannelForm
                key={activeChannel.id}
                storyId={storyId}
                channelId={activeChannel.id}
                channel={activeChannel}
                channelsData={channelsData}
                channelType={activeChannel.type}
                expanded={activeChannel.type}
                handleChannelChange={handleChannelChange}
                setChannelStatus={setChannelStatus}
                isOwnerOfStory={isOwnerOfStory}
                disabled={aiGeneration.storyId === storyId}
                fetchStoryData={fetchStoryData}
              />
            ) : (
              <>
                <div
                  style={{
                    height: '41px',
                    borderBottom: '1px solid #e8e8e8',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                    paddingLeft: '42px',
                    paddingTop: '7px'
                  }}
                ></div>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="80%"
                  textAlign="center"
                  flexDirection="column"
                >
                  {storyChannelTypes.length > 0 && activeChannelTypes.length === 0 && aiGeneration.storyId !== storyId && (
                    <>
                      <TipsAndUpdatesIcon fill={theme.palette.secondary.main} width={48} height={48} />
                      <div style={{ marginTop: '20px', marginBottom: '10px', width: '70%' }}>
                        {t('pages.story.startMessage')}
                      </div>
                    </>
                  )}
                  {storyChannelTypes.length > 0 && activeChannelTypes.length > 0 && aiGeneration.storyId !== storyId && (
                    <>
                      <MouseIcon fill={theme.palette.secondary.main} width={48} height={48} />
                      <div style={{ marginTop: '20px', marginBottom: '10px', width: '70%' }}>
                        {t('pages.story.infoMessage')}
                      </div>
                    </>
                  )}
                  {aiGeneration.storyId === storyId && <CircularProgress size={38} color="secondary" />}
                </Box>
              </>
            )}
          </Grid>
          {storyChannelTypes.length === 0 && (
            <Grid item xs={12}>
              <Alert variant="outlined" severity="info">
                {t('pages.story.noChannelsToDisplay')}
              </Alert>
            </Grid>
          )}
        </Grid>
      )}
      {createWithAiWindowOpen && (
        <CreateWithAiWindow
          open={createWithAiWindowOpen}
          onCloseClick={onCreateWithAiWindowClose}
          onHandleCreated={() => {
            setSelectedChannels([]);
            setChannelsToPublish([]);
            setActiveChannel(undefined);
          }}
          fullScreenOnMobile
          activeChannelTypes={activeChannelTypes}
          selectedChannels={selectedChannels}
          channelsData={channelsData}
          storyId={storyId}
          userId={user.id}
        />
      )}
      {deleteStoryChannelWindowOpen && channelToDelete && (
        <DeleteStoryChannelWindow
          channelType={channelToDelete.type}
          storyId={storyId}
          channelId={channelToDelete.id}
          open={deleteStoryChannelWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onDeleteStoryChannelWindowClose}
          onSuccess={() => {
            onDeleteStoryChannelWindowClose();
            fetchStoryData();
            setActiveChannel(undefined);
          }}
        />
      )}
      {publishSelectedChannelsWindowOpen && (
        <PublishSelectedChannelsWindow
          open={publishSelectedChannelsWindowOpen}
          channels={channelsToPublish}
          onCloseClick={onPublishSelectedChannelsWindowClose}
          onSubmit={(publishedChannels) => {
            setSelectedChannels([]);
            publishedChannels.map((pCh) => {
              if (pCh.type === StoryChannelType.BLOG) {
                setChannelStatus(pCh, StoryChannelStatus.PUBLISHED, true);
              } else {
                setChannelStatus(pCh, StoryChannelStatus.SCHEDULED, true);
              }
            });
          }}
          fullScreenOnMobile={true}
        />
      )}
    </div>
  );
};

export const getChannelIcon = (type: StoryChannelType, theme: Theme) => {
  switch (type) {
    case StoryChannelType.PRINT:
      return <PrintIcon width={20} height={20} fill={theme.palette.primary.main} />;
    case StoryChannelType.EMAIL:
      return <EmailIcon width={20} height={20} fill={theme.palette.primary.main} />;
    case StoryChannelType.FACEBOOK:
      return <FacebookIcon width={20} height={20} fill={theme.palette.primary.main} />;
    case StoryChannelType.TWITTER:
      return <TwitterIcon width={20} height={20} fill={theme.palette.primary.main} />;
    case StoryChannelType.INSTAGRAM:
      return <InstagramIcon width={20} height={20} fill={theme.palette.primary.main} />;
    case StoryChannelType.LINKEDIN:
      return <LinkedinIcon width={20} height={20} fill={theme.palette.primary.main} />;
    case StoryChannelType.BLOG:
      return <BlogIcon width={20} height={20} fill={theme.palette.primary.main} />;

    default:
      return null;
  }
};

export default Channels;
