import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { ShortUser } from '../Auth/types';

export type CampaignsState = StateWithPagination &
  StateWithTableSearch<Campaign> & {
    data: NormalizedCampaigns;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export enum CampaignProjectType {
  PRINT = 'print',
  EMAIL = 'email',
  STORY = 'story'
}

export type CampaignProject = {
  id: string;
  associationId: string;
  name: string;
  type: CampaignProjectType;
  createdBy: ShortUser;
  createdAt: string;
  publicationDateFrom: string;
  publicationDateTo: string;
};

export type Campaign = {
  id: string;
  name: string;
  description: string;
  createdBy: ShortUser;
  updatedAt: string;
  createdAt: string;
  projects: CampaignProject[];
};

export type NormalizedCampaigns = {
  entities: Dictionary<Campaign>;
  result: string[];
};

export type CampaignsIndexResponse = {
  data: Campaign[];
  pagination: Pagination;
};

export type CampaignsIndexActionBEGIN = {
  type: CampaignsActionsConstants.INDEX_BEGIN;
};

export type CampaignsIndexActionSUCCESS = {
  type: CampaignsActionsConstants.INDEX_SUCCESS;
  payload: CampaignsIndexResponse;
};

export type CampaignsIndexActionFAILED = {
  type: CampaignsActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type CampaignsUpdateAction = {
  type: CampaignsActionsConstants.UPDATE_CAMPAIGN;
  payload: {
    campaign: Campaign;
  };
};

export type IndexCampaignsRequestParams = {
  per: number;
  page: number;
  q: string | null;
  order?: string[];
};

export type CreateCampaignValues = Pick<Campaign, 'name' | 'description'>;
export type UpdateCampaignValues = Pick<Campaign, 'name' | 'description'>;

export type CampaignsResponse = {
  data: Campaign[];
};

export enum CampaignsActionsConstants {
  UPDATE_CAMPAIGN = 'CAMPAIGNS/UPDATE',
  INDEX_BEGIN = 'CAMPAIGNS/INDEX/BEGIN',
  INDEX_SUCCESS = 'CAMPAIGNS/INDEX/SUCCESS',
  INDEX_FAILED = 'CAMPAIGNS/INDEX/FAILED'
}

export type CampaignsActionType =
  | CampaignsUpdateAction
  | CampaignsIndexActionBEGIN
  | CampaignsIndexActionSUCCESS
  | CampaignsIndexActionFAILED;
