import {
  AppActionsConstants,
  CloseVideoPreviewModalAction,
  GetVersionsActionBEGIN,
  GetVersionsActionFAILED,
  GetVersionsActionSUCCESS,
  GetVersionsResponse,
  OpenVideoPreviewModalAction,
  SetDesignerVersionFromIframeAction
} from './types';

export const getVersionsBegin = (): GetVersionsActionBEGIN => ({
  type: AppActionsConstants.GET_VERSIONS_BEGIN
});

export const getVersionsSuccess = (response: GetVersionsResponse): GetVersionsActionSUCCESS => ({
  type: AppActionsConstants.GET_VERSIONS_SUCCESS,
  payload: response
});

export const getVersionsFailed = (error: any): GetVersionsActionFAILED => ({
  type: AppActionsConstants.GET_VERSIONS_FAILED,
  payload: {
    error
  }
});

export const setDesignerVersionFromIframe = (payload: string): SetDesignerVersionFromIframeAction => ({
  type: AppActionsConstants.SET_DESIGNER_VERSION_FROM_IFRAME,
  payload
});

export const openVideoPreviewModal = (src: string): OpenVideoPreviewModalAction => ({
  type: AppActionsConstants.OPEN_VIDEO_MODAL,
  payload: {
    src
  }
});

export const closeVideoPreviewModal = (): CloseVideoPreviewModalAction => ({
  type: AppActionsConstants.CLOSE_VIDEO_MODAL
});
