import { EmailSocialLinks } from '../../../types';
import { TFunction } from 'i18next';
import { AdminTextInput } from '../../../../../Admin/Shared/AdminFormInputs';
import * as Yup from 'yup';
import app from '../../../../../../../config/app/app';
import update from 'immutability-helper';
import React from 'react';
import { ComponentEditFormReturnType } from '../index';
import { Grid } from '@material-ui/core';
import { AdminInputLabel } from '../../../../../Admin/AdminStyledComponents';
import { FormikProps } from 'formik';
import ColorPicker from '../../../../../../Shared/ColorPicker/ColorPicker';
import { useTypedSelector } from '../../../../../../../utils';

export const socialLinks = (
  component: EmailSocialLinks,
  t: TFunction
): ComponentEditFormReturnType<EmailSocialLinks> => {
  const organization = useTypedSelector((state) => state.auth.organization);

  type Values = {
    socialLinks: {
      color: string;
      facebook: string;
      instagram: string;
      linkedIn: string;
      twitter: string;
      youtube: string;
    };
  };

  const form = (props: FormikProps<Values>) => (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <AdminTextInput disabled t={t} name="socialLinks.facebook" section="emailEditor" />
        <AdminTextInput disabled t={t} name="socialLinks.instagram" section="emailEditor" />
        <AdminTextInput disabled t={t} name="socialLinks.linkedIn" section="emailEditor" />
        <AdminTextInput disabled t={t} name="socialLinks.twitter" section="emailEditor" />
        <AdminTextInput disabled t={t} name="socialLinks.youtube" section="emailEditor" />
      </Grid>
      <Grid item xs={6}>
        <AdminInputLabel>{t('pages.emailEditor.inputs.color')}</AdminInputLabel>
        <ColorPicker
          color={props.values.socialLinks.color}
          onChange={(color) => props.setFieldValue('socialLinks.color', color)}
        />
      </Grid>
    </Grid>
  );

  const facebookLink = organization?.facebookLink || '';
  const instagramLink = organization?.instagramLink || '';
  const linkedinLink = organization?.linkedinLink || '';
  const twitterLink = organization?.twitterLink || '';
  const youtubeLink = organization?.youtubeLink || '';

  const initialFields: Values = {
    socialLinks: {
      color: component.params.color || '#000000',
      facebook: facebookLink,
      instagram: instagramLink,
      linkedIn: linkedinLink,
      twitter: twitterLink,
      youtube: youtubeLink
    }
  };

  const validationSchema = {
    socialLinks: Yup.object().shape({
      color: Yup.string().max(app.maxInputLength),
      facebook: Yup.string().max(app.maxInputLength),
      instagram: Yup.string().max(app.maxInputLength),
      linkedIn: Yup.string().max(app.maxInputLength),
      twitter: Yup.string().max(app.maxInputLength),
      youtube: Yup.string().max(app.maxInputLength)
    })
  };

  return {
    type: 'socialLinks',
    form,
    initialFields,
    validationSchema,
    onSubmit: (params: Values, component: EmailSocialLinks) =>
      update(component, {
        params: {
          urls: {
            facebook: { $set: params.socialLinks.facebook },
            instagram: { $set: params.socialLinks.instagram },
            linkedIn: { $set: params.socialLinks.linkedIn },
            twitter: { $set: params.socialLinks.twitter },
            youtube: { $set: params.socialLinks.youtube }
          },
          color: { $set: params.socialLinks.color }
        }
      })
  };
};
