import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { AdminCreateAdBannerValues, AdminCreateAdCategoryValues, AdminUpdateAdBannerValues } from './types';

export const updateAdBanner = (
  settings: RequestSettings<{ bannerId: string }, AdminUpdateAdBannerValues>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/revenue/designs/:bannerId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteAdBanner = (settings: RequestSettings<{ bannerId: string }>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/revenue/designs/:bannerId'
  },
  params: settings.requestParams
});

export const getZones = (settings: RequestSettings<{ sizes: string; advertiserId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/zones/:advertiserId?sizes=:sizes'
  },
  params: settings.requestParams
});

export const createAdBanner = (settings: RequestSettings<{}, AdminCreateAdBannerValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/revenue/designs'
  },
  payload: settings.requestPayload
});

export const indexOrganizationBanners = (settings: RequestSettings<{ organizationId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/designs/:organizationId'
  },
  params: settings.requestParams
});

export const indexHouseAds = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/house_ads'
  }
});

export const getChartData = (settings: RequestSettings<{ startDate: string; endDate: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/designs/chart/:startDate/:endDate'
  },
  params: settings.requestParams
});

export const getAdvertiserStatistics = (
  settings: RequestSettings<{ startDate: string; endDate: string }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/designs/statistics/advertiser/:startDate/:endDate'
  },
  params: settings.requestParams
});

export const getAdvertiserBannerStatistics = (
  settings: RequestSettings<{ advertiserId: number; startDate: string; endDate: string }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/designs/statistics/advertiser/:advertiserId/banner/:startDate/:endDate'
  },
  params: settings.requestParams
});

export const createAdCategory = (settings: RequestSettings<{}, AdminCreateAdCategoryValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/revenue/designs/design_category'
  },
  payload: settings.requestPayload
});

export const updateAdCategory = (
  settings: RequestSettings<{ id: string }, AdminCreateAdCategoryValues>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/revenue/designs/design_category/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeAdCategory = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/revenue/designs/design_category/:id'
  },
  params: settings.requestParams
});

export const indexHouseAdsCategories = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/designs/design_category'
  }
});
