import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { AdminOrganization } from '../AdminOrganizations/types';
import { AdminUser } from '../AdminUsers/types';

export type AdminOnlineUsersState = StateWithPagination &
  StateWithTableSearch<AdminOnlineUser> & {
    users: NormalizedAdminOnlineUsers;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedAdminOnlineUsers = {
  entities: Dictionary<AdminOnlineUser>;
  result: string[];
};

export type AdminOnlineUsersIndexResponse = {
  data: AdminOnlineUser[];
  pagination: Pagination;
};

export type AdminOnlineUsersIndexActionBEGIN = {
  type: AdminOnlineUsersActionsConstants.INDEX_BEGIN;
};

export type AdminOnlineUsersIndexActionSUCCESS = {
  type: AdminOnlineUsersActionsConstants.INDEX_SUCCESS;
  payload: AdminOnlineUsersIndexResponse;
};

export type IndexAdminOnlineUsersRequestParams = {
  order?: string[];
  q: string | null;
};

export type AdminOnlineUsersIndexActionFAILED = {
  type: AdminOnlineUsersActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type AdminOnlineUser = {
  id: string;
  createdAt: string;
  email: string;
  user: {
    id: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
  };
  organizations: AdminOrganization[];
  uniqAdminOnlineUser: 'uniqAdminOnlineUser';
};

export enum AdminOnlineUsersActionsConstants {
  INDEX_BEGIN = 'ADMIN_KEYWORDS/INDEX/BEGIN',
  INDEX_SUCCESS = 'ADMIN_KEYWORDS/INDEX/SUCCESS',
  INDEX_FAILED = 'ADMIN_KEYWORDS/INDEX/FAILED'
}

export type AdminOnlineUsersActionType =
  | AdminOnlineUsersIndexActionBEGIN
  | AdminOnlineUsersIndexActionSUCCESS
  | AdminOnlineUsersIndexActionFAILED;
