import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import {
  AdminCreateOrganizationUserValues,
  AdminUpdateOrganizationUserValues,
  IndexAdminOrganizationUsersRequestParams
} from './types';

export const index = (settings: RequestSettings<IndexAdminOrganizationUsersRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/organizations/:organizationId/users?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const create = (
  settings: RequestSettings<{ organizationId: string }, AdminCreateOrganizationUserValues>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/organizations/:organizationId/users'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const update = (
  settings: RequestSettings<{ organizationId: string; userId: string }, AdminUpdateOrganizationUserValues>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/organizations/:organizationId/users/:userId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteOrganizationUser = (
  settings: RequestSettings<{ organizationId: string; userId: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/organizations/:organizationId/users/:userId'
  },
  params: settings.requestParams
});
