import React from 'react';
import styled from 'styled-components';
import { AdminOrganization } from '../../../../../store/AdminOrganizations/types';
import { Box } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import MailIcon from '@material-ui/icons/Mail';

type OrganizationTileProps = {
  organization: AdminOrganization;
  style?: React.CSSProperties;
};

const OrganizationTile: React.FC<OrganizationTileProps> = ({ organization, style }) => {
  return (
    <TreeTile style={style}>
      <strong>{organization.name}</strong>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <PersonIcon style={{ width: '16px', height: '16px', marginRight: 5 }} />
          {organization.owners.length > 0 ? organization.owners[0].fullName : 'N/A'}
        </Box>
        <Box display="flex" alignItems="center">
          <MailIcon style={{ width: '16px', height: '16px', marginRight: 5 }} />
          {organization.owners.length > 0 ? organization.owners[0].email : 'N/A'}
        </Box>
      </Box>
    </TreeTile>
  );
};

const TreeTile = styled.div`
  background: #f3f8fd;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 5px 10px;
  width: 100%;
  &:hover,
  &:focus {
    background: #e1e9f2;
  }
`;

export default OrganizationTile;
