import update from 'immutability-helper';
import { normalizeSharedProjects } from '../helpers';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import {
  SharedProject,
  SharedProjectsListingType,
  SharingCenterActionsConstants,
  SharingCenterActionType,
  SharingCenterState
} from './types';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';
import { EmailProject } from '../EmailProjects/types';

const initialState: SharingCenterState = {
  type: SharedProjectsListingType.SHARED_WITH_ME,
  projects: {
    entities: {},
    result: []
  },
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  sharingCenterUnique: 'sharingCenterUnique'
};

const sharingCenterPaginationReducer = createPaginationReducer<SharingCenterState, SharedProject>(
  // @ts-ignore
  PaginationStoreModule.SHARING_CENTER
);

const sharingCenterTableSearchReducer = createTableSearchReducer<EmailProject, SharingCenterState>(
  // @ts-ignore
  TableSearchStoreModule.SHARING_CENTER
);

export default function sharingCenter(state = initialState, action: WithLogoutAction<SharingCenterActionType>) {
  switch (action.type) {
    case SharingCenterActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case SharingCenterActionsConstants.INDEX_SUCCESS: {
      const data = normalizeSharedProjects(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        projects: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case SharingCenterActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        projects: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case SharingCenterActionsConstants.SET_LISTING_TYPE: {
      return update(state, {
        type: { $set: action.payload }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return sharingCenterTableSearchReducer(
        sharingCenterPaginationReducer(state, action),
        action
      ) as SharingCenterState;
    }
  }
}
