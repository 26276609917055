import React, { FunctionComponent } from 'react';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { useTranslation } from 'react-i18next';
import { WordpressIntegration, WordpressIntegrationForm } from '../../../../store/MyOrganization/types';
import WordPressIntegrationForm from '../../Stories/Partials/WordPressIntegrationForm';

type CreateOrUpdateWordpressIntegrationProps = {
  type: 'create' | 'update';
  open: boolean;
  onCloseClick: () => void;
  onSubmit: (form: WordpressIntegrationForm) => Promise<void>;
  fullScreenOnMobile: boolean;
  integration?: WordpressIntegration | null;
};

const CreateOrUpdateWordpressIntegration: FunctionComponent<CreateOrUpdateWordpressIntegrationProps> = ({
  onCloseClick,
  onSubmit,
  type,
  open,
  integration
}) => {
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      onCloseClick={onCloseClick}
      open={open}
      header={
        type === 'create'
          ? t('pages.myOrganization.integrations.wordpress.createTitle')
          : t('pages.myOrganization.integrations.wordpress.updateTitle')
      }
      okButtonText={type === 'create' ? t('common.create') : t('common.update')}
      hideActionButtons
    >
      <WordPressIntegrationForm onSubmit={onSubmit} integration={integration} />
    </ModalConfirmationWindow>
  );
};

export default CreateOrUpdateWordpressIntegration;
