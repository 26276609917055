import React, { FunctionComponent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import {
  AdminOrganizationUser,
  AdminUpdateOrganizationUserValues
} from '../../../../../../store/AdminOrganizationUsers/types';
import { adminOrganizationUsersOperations } from '../../../../../../store/AdminOrganizationUsers';
import { useDispatch } from 'react-redux';
import { AdminCheckboxInput, AdminSelectInput, AdminTextInput } from '../../../Shared/AdminFormInputs';
import Toast from '../../../../../Shared/Toast/Toast';
import app from '../../../../../../config/app/app';
import { AdminRole, DefaultRoleName } from '../../../../../../store/AdminRoles/types';
import { adminUsersOperations } from '../../../../../../store/AdminUsers';
import { adminRolesOperations } from '../../../../../../store/AdminRoles';
import { MenuItem } from '@material-ui/core';
import { FormikProps } from 'formik';
import { getRoleNameElement } from '../../../../../../utils';

type AdminUpdateOrganizationUserWindowProps = {
  open: boolean;
  organizationId: string;
  disableSetAsOwnerCheckbox: boolean;
  organizationUser: AdminOrganizationUser | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminUpdateOrganizationUserUserWindow: FunctionComponent<AdminUpdateOrganizationUserWindowProps> = ({
  organizationUser,
  organizationId,
  onCloseClick,
  open,
  disableSetAsOwnerCheckbox
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [roles, setRoles] = useState<AdminRole[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      if (organizationUser) {
        await fetchRolesData(organizationUser.isOwner ? 'owner' : 'contact');
      }
    };

    if (open) {
      fetchRoles();
    }
  }, [open]);

  if (!organizationUser) return null;

  const fetchRolesData = async (type: 'owner' | 'contact') => {
    const typeToSearch = type === 'contact' ? DefaultRoleName.ORGANIZATION_CONTACT : DefaultRoleName.ORGANIZATION_OWNER;
    const userTypes = await adminUsersOperations.getUserTypes();
    const contactUserType = userTypes.data.find((type) => type.name === typeToSearch);
    if (contactUserType) {
      const rolesData = await adminRolesOperations.getRolesOfUserType(contactUserType.id);

      setRoles(rolesData.data);
    }
  };

  const onSubmit = async (values: AdminUpdateOrganizationUserValues) => {
    try {
      await dispatch(
        adminOrganizationUsersOperations.update(organizationId, organizationUser.id, {
          roleId: values.roleId,
          isOwner: values.isOwner
        })
      );
      Toast.success(t('notifications.adminOrganizationUsers.successOperation'));

      await dispatch(adminOrganizationUsersOperations.index(organizationId));

      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.adminOrganizationUsers.errorOperation'));
    }
  };

  const formInputs = (props: FormikProps<AdminUpdateOrganizationUserValues>) => (
    <>
      <AdminTextInput t={t} name="email" section="adminOrganizationUsers" disabled />
      <AdminCheckboxInput
        t={t}
        name="isOwner"
        disabled={disableSetAsOwnerCheckbox}
        onChange={(e) => {
          props.setFieldValue('roleId', '');
          e.target.checked ? fetchRolesData('owner') : fetchRolesData('contact');
          return true;
        }}
        section="adminOrganizationUsers"
      />
      <AdminSelectInput t={t} name="roleId" section="adminOrganizationUsers">
        {roles.map((role) => (
          <MenuItem key={role.id} value={role.id}>
            {getRoleNameElement(t, role)}
          </MenuItem>
        ))}
      </AdminSelectInput>
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      header={t('pages.adminOrganizationUsers.windows.updateOrganizationUser')}
      okButtonText={t('common.update')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        email: organizationUser.email,
        roleId: organizationUser.roleId,
        isOwner: organizationUser.isOwner
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().max(app.maxInputLength).email().required(),
        roleId: Yup.string().required(),
        isOwner: Yup.boolean().required()
      })}
    />
  );
};

export default AdminUpdateOrganizationUserUserWindow;
