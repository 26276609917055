import React, { FunctionComponent } from 'react';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import styled from 'styled-components';
import { ProjectNote } from '../../../../store/Notes/types';
import { AdminRole, DefaultRoleName } from '../../../../store/AdminRoles/types';
import { hasPermission } from '../../../../utils/permissions';
import { User } from '../../../../store/Auth/types';
import { formatNoteDate } from '../../../../utils';

type ProjectNoteProps = {
  note: ProjectNote;
  user: User;
  role: AdminRole;
  onDeleteNote: (note: ProjectNote) => void;
};

const ProjectNoteItem: FunctionComponent<ProjectNoteProps> = ({ note, onDeleteNote, role, user }) => {
  const hasPermissionsToRemove =
    user.type.name === DefaultRoleName.SYSTEM_OWNER ||
    (note.createdBy.id === user.id && hasPermission(role, ['projectNotesEdit']));

  return (
    <ProjectNoteWrapper>
      <UserDataWrapper>
        <Email>{note.createdBy.email}</Email>{' '}
        <FullName>
          ({note.createdBy.firstName} {note.createdBy.lastName} {formatNoteDate(note.createdAt)})
        </FullName>
        {hasPermissionsToRemove && (
          <NoteActionsWrapper>
            <DeleteOutlineOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => onDeleteNote(note)} />
          </NoteActionsWrapper>
        )}
      </UserDataWrapper>
      <NoteContent>
        {note.content.split('\n').map(function (item, idx) {
          return (
            <span key={idx}>
              {item}
              <br />
            </span>
          );
        })}
      </NoteContent>
    </ProjectNoteWrapper>
  );
};
const ProjectNoteWrapper = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid #e3e2e2;
`;
const UserDataWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-bottom: 0.6rem;
`;
const Email = styled.div`
  margin-right: 0.2rem;
  font-weight: bold;
`;
const FullName = styled.div`
  font-style: italic;
  font-size: 0.7rem;
`;
const NoteContent = styled.div`
  word-break: break-word;
`;
const NoteActionsWrapper = styled.div`
  text-align: right;
  flex-grow: 1;
  margin-right: 0.5rem;
`;

export default ProjectNoteItem;
