import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';

type FlipBookReGenerateResourcesWindowProps = {
  open: boolean;
  onSubmit: () => void;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const FlipBookReGenerateResourcesWindow: FunctionComponent<FlipBookReGenerateResourcesWindowProps> = ({
  onSubmit,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.flipBookPreview.windows.reGenerateResources.title')}
      okButtonText={t('common.ok')}
      onCloseClick={onCloseClick}
      onSubmit={async () => {
        await onCloseClick();
        await onSubmit();
      }}
    >
      <Row>{t('pages.flipBookPreview.windows.reGenerateResources.content')}</Row>
    </ModalConfirmationWindow>
  );
};

export default FlipBookReGenerateResourcesWindow;
