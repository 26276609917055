import React from 'react';
import { ButtonBase, Tooltip, withTheme } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import styled from 'styled-components';

type TableActionButtonProps = {
  icon: JSX.Element;
  ButtonProps?: ButtonProps;
  tooltip?: string;
};

const TableActionButton: React.FC<TableActionButtonProps> = ({ icon, ButtonProps, tooltip }) => {
  const baseComponent = (
    <div>
      <StyledButtonBase {...ButtonProps}>{icon}</StyledButtonBase>
    </div>
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="top">
        {baseComponent}
      </Tooltip>
    );
  }

  return baseComponent;
};

const StyledButtonBase = withTheme(styled(ButtonBase)`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 0 6px;

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    color: white;
  }

  &.Mui-disabled {
    opacity: 0.5;
  }
`);

export default TableActionButton;
