import React, { FunctionComponent } from 'react';
import { Separator } from '../../types';
import { ColorSwatchType, MyOrganizationSwatchColor } from '../../../../../../store/MyOrganization/types';
import { extractColorsFromSwatches } from '../../Utils';

type SeparatorProps = {
  data: Separator;
  selectedColorSwatches: { [key: string]: MyOrganizationSwatchColor };
};

const SeparatorComponent: FunctionComponent<SeparatorProps> = ({ data, selectedColorSwatches }) => {
  if (data.style?.border && typeof data.style.border === 'string') {
    const [borderWidth, borderStyle, ...borderColor] = data.style.border.split(' ');
    delete data.style.border;
    data.style.borderWidth = borderWidth;
    data.style.borderStyle = borderStyle;
    data.style.borderColor = borderColor.join(' ');
  }

  const defaultColor = data.style?.borderColor || '#FFFFFF';
  const dataDefault = { [`data-${ColorSwatchType.divider1}-default`]: defaultColor };

  return (
    <hr
      {...dataDefault}
      className={ColorSwatchType.divider1}
      style={{
        ...data.style,
        ...extractColorsFromSwatches(
          selectedColorSwatches,
          [ColorSwatchType.divider1],
          data.colorSwatchIgnore,
          defaultColor
        )
      }}
    />
  );
};

export default SeparatorComponent;
