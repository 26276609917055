import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { mailingListsOperations } from '../../../store/MailingLists';
import ListIcon from '@material-ui/icons/List';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { useTypedSelector } from '../../../utils';
import { usePaginationWatch } from '../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../Shared/DataTable/useTableSearchWatch';
import { MailingList } from '../../../store/MailingLists/types';
import useOpenHandler from '../../../hooks/useOpenHandler';
import { PageContainer, PageContent } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import DataTable from '../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../Shared/DataTable/TableActionButton/TableActionButton';
import { TableSearchStoreModule } from '../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../store/Pagination/types';
import CreateMailingListWindow from './Windows/CreateMailingListWindow';
import UpdateMailingListWindow from './Windows/UpdateMailingListWindow';
import DeleteMailingListWindow from './Windows/DeleteMailingListWindow';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../config/app/linksConstants';
import { hasPermission } from '../../../utils/permissions';
import { withTheme } from '@material-ui/core';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import NotVerifiedIcon from '@material-ui/icons/HighlightOff';

type MailingListsPageProps = {};

const MailingListsPage: FunctionComponent<MailingListsPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);
  const mailingLists = useTypedSelector((state) => state.mailingLists.data);
  const isFailed = useTypedSelector((state) => state.mailingLists.index.isFailed);
  const isLoading = useTypedSelector((state) => state.mailingLists.index.isLoading);
  const pagination = useTypedSelector((state) => state.mailingLists.pagination);
  const tableSearch = useTypedSelector((state) => state.mailingLists.tableSearch);

  usePaginationWatch(pagination, [mailingListsOperations.index]);
  useTableSearchWatch<MailingList>(tableSearch, [mailingListsOperations.index]);

  const [activeMailingList, setActiveMailingList] = useState<MailingList | null>(null);
  const [createMailingListWindowOpen, onCreateMailingListWindowOpen, onCreateMailingListWindowClose] = useOpenHandler();
  const [updateMailingListWindowOpen, onUpdateMailingListWindowOpen, onUpdateMailingListWindowClose] = useOpenHandler();
  const [deleteMailingListWindowOpen, onDeleteMailingListWindowOpen, onDeleteMailingListWindowClose] = useOpenHandler();

  const openTestMailingList = async (mailingList: MailingList) => {
    const testMailingList = await mailingListsOperations.getTestMailingList(mailingList.id);
    dispatch(push(linksConstants.MAILING_LISTS.EDIT(testMailingList.id, 'test')));
  };

  const getActionButtons = () => {
    if (hasPermission(role, ['mailingListsAdd'])) {
      return [
        {
          label: t('pages.mailingLists.create'),
          icon: <AddIcon />,
          onClick: onCreateMailingListWindowOpen
        }
      ];
    }

    return [];
  };

  return (
    <PageContainer>
      <PageHeader title={t(`pages.mailingLists.title`)} rightActionButtons={getActionButtons()} />
      <PageContent>
        <DataTable<MailingList, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              isUrl: true,
              url: (mailingList) => linksConstants.MAILING_LISTS.EDIT(mailingList.id, 'main'),
              name: 'name',
              sortable: true,
              render: (mailingList) => <>{mailingList.name}</>
            },
            {
              name: 'actions',
              render: (mailingList) => (
                <TableActionButtonsContainer>
                  <TableActionButton
                    icon={<ListIcon />}
                    tooltip={t('pages.mailingLists.table.buttons.open')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['mailingListsView']),
                      onClick: () => dispatch(push(linksConstants.MAILING_LISTS.EDIT(mailingList.id, 'main')))
                    }}
                  />
                  <TableActionButton
                    icon={<ListAltIcon />}
                    tooltip={t('pages.mailingLists.table.buttons.openTestList')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['mailingListsView']),
                      onClick: () => openTestMailingList(mailingList)
                    }}
                  />
                  <TableActionButton
                    icon={<PlaylistAddCheckIcon />}
                    tooltip={t('pages.mailingLists.table.buttons.setWebsiteFormDefault')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['mailingListsEdit']) || mailingList.websiteFormDefault,
                      onClick: async () => {
                        await mailingListsOperations.setWebsiteFormDefault(mailingList.id);
                        await dispatch(mailingListsOperations.index());
                      }
                    }}
                  />
                  <TableActionButton
                    icon={<EditIcon />}
                    tooltip={t('pages.mailingLists.table.buttons.edit')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['mailingListsEdit']),
                      onClick: () => {
                        setActiveMailingList(mailingList);
                        onUpdateMailingListWindowOpen();
                      }
                    }}
                  />
                  <TableActionButton
                    icon={<DeleteIcon />}
                    tooltip={t('pages.mailingLists.table.buttons.delete')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['mailingListsRemove']),
                      onClick: () => {
                        setActiveMailingList(mailingList);
                        onDeleteMailingListWindowOpen();
                      }
                    }}
                  />
                </TableActionButtonsContainer>
              )
            },
            {
              name: 'activeMemberCount',
              sortable: true,
              render: (mailingList) => <>{mailingList.activeMemberCount}</>
            },
            {
              name: 'bouncedMemberCount',
              sortable: true,
              render: (mailingList) => <>{mailingList.bouncedMemberCount}</>
            },
            {
              name: 'unsubscribedMemberCount',
              sortable: true,
              render: (mailingList) => <>{mailingList.unsubscribedMemberCount}</>
            },
            {
              name: 'removedMemberCount',
              sortable: true,
              render: (mailingList) => <>{mailingList.removedMemberCount}</>
            },
            {
              name: 'websiteFormDefault',
              sortable: true,
              render: (mailingList) =>
                mailingList.websiteFormDefault ? <CheckIconStyled /> : <NotVerifiedIconStyled />
            }
          ]}
          tPath={'pages.mailingLists.table'}
          data={mailingLists}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.MAILING_LISTS
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.MAILING_LISTS
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>

      <CreateMailingListWindow
        open={createMailingListWindowOpen}
        onCloseClick={onCreateMailingListWindowClose}
        fullScreenOnMobile
      />
      <UpdateMailingListWindow
        mailingList={activeMailingList}
        open={updateMailingListWindowOpen}
        onCloseClick={onUpdateMailingListWindowClose}
        fullScreenOnMobile
      />
      <DeleteMailingListWindow
        mailingList={activeMailingList}
        open={deleteMailingListWindowOpen}
        onCloseClick={onDeleteMailingListWindowClose}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

const CheckIconStyled = withTheme(styled(CheckIcon)`
  color: ${({ theme }) => theme.palette.success.main};
`);

const NotVerifiedIconStyled = withTheme(styled(NotVerifiedIcon)`
  color: ${({ theme }) => theme.palette.warning.main};
`);

export default MailingListsPage;
