import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from '../../../Shared/StyledComponents';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import MonthCalendarSettings from '../../Editors/EmailDndEditor/Partials/Components/Utils/MonthCalendarSettings';
import { MonthCalendarStyle, MonthCalendarStyles } from '../../../../store/MyOrganization/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import Toast from '../../../Shared/Toast/Toast';

type CalendarSettingsWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  styles: MonthCalendarStyle;
  setStyles: React.Dispatch<React.SetStateAction<MonthCalendarStyle>>;
  selectedStyle: MonthCalendarStyles;
  setSelectedStyle: React.Dispatch<React.SetStateAction<MonthCalendarStyles>>;
};

const CalendarSettingsWindow: FunctionComponent<CalendarSettingsWindowProps> = ({
  onCloseClick,
  open,
  styles,
  setStyles,
  selectedStyle,
  setSelectedStyle
}) => {
  const { t } = useTranslation();
  const [calendarStyles, setCalendarStyles] = useState<MonthCalendarStyle>(styles);

  const onSubmit = async () => {
    try {
      await myOrganizationOperations.setOrganizationCalendarStyle({ ...calendarStyles, style: selectedStyle });
      Toast.success(t('notifications.calendarStyle.success'));
    } catch (error) {
      Toast.error(t('notifications.calendarStyle.error'));
    } finally {
      setStyles(calendarStyles);
      onCloseClick();
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.calendar.settingsHeader')}
      okButtonText={t('common.save')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>
        <MonthCalendarSettings
          selectedStyle={selectedStyle}
          styles={styles}
          setStyles={setCalendarStyles}
          setSelectedStyle={setSelectedStyle}
        />
      </Row>
    </ModalConfirmationWindow>
  );
};

export default CalendarSettingsWindow;
