import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Paper, Tooltip, withTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  MyOrganizationSwatchColor,
  MyOrganizationSwatchGroup,
  MyOrganizationSwatchGroupWithValue
} from '../../../../../../../store/MyOrganization/types';
import Toast from '../../../../../../Shared/Toast/Toast';
import Loader from '../../../../../../Shared/Loading/Loader';
import RestoreIcon from '@material-ui/icons/Restore';

export type AllowedColorSwatchStyleDirectives = 'background-color' | 'color';

type ColorSwatchesProps = {
  colors: MyOrganizationSwatchColor[];
  swatches: MyOrganizationSwatchGroup[];
  applyColor: (swatchGroup: MyOrganizationSwatchGroup, color: MyOrganizationSwatchColor | null) => void;
  currentColors?: { [key: string]: MyOrganizationSwatchColor };
};

const ColorSwatches: FunctionComponent<ColorSwatchesProps> = ({ applyColor, currentColors, swatches, colors }) => {
  const { t } = useTranslation();

  const getCurrentElementColor = (group: MyOrganizationSwatchGroup) => {
    if (currentColors && currentColors[group.name]) {
      return currentColors[group.name].hex;
    }

    return 'transparent';
  };

  const [swatchesWeb, setSwatchesWeb] = useState<MyOrganizationSwatchGroupWithValue[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      setSwatchesWeb(
        swatches.map((el) => ({
          ...el,
          value: getCurrentElementColor(el)
        }))
      );
    } catch (e) {
      Toast.error(t('notifications.myOrganization.error'));
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (element: MyOrganizationSwatchGroup, color: MyOrganizationSwatchColor | null) => {
    if (element && swatchesWeb) {
      const updatedSwatches = swatchesWeb.map((swatch) => {
        if (swatch.name === element.name) {
          swatch.value = color ? color.hex : '';
        }
        return swatch;
      });

      setSwatchesWeb(updatedSwatches);
      applyColor(element, color);
    }
  };

  const renderTooltipText = (color: MyOrganizationSwatchColor) => {
    const colorName = color.name ? `${color.name} (${color.hex})` : color.hex.toUpperCase();
    return (
      <>
        {colorName}
        <br />
        R: {color.red} G: {color.green} B: {color.blue} A: {color.opacity}
        <br />
        C: {color.cyan} M: {color.magenta} Y: {color.yellow} K: {color.black}
      </>
    );
  };

  return (
    <Wrapper>
      <Title>{t('pages.dashboard.colorSwatchesTitle')}</Title>
      {loading && <Loader />}
      {!loading && swatchesWeb && colors && (
        <>
          <ColorSwatchesContainer>
            {swatchesWeb.map((element) => (
              <React.Fragment key={element.id}>
                <GroupContainer>
                  <GroupTitle>{t(`pages.dashboard.colorSwatches.${element.name}`)}</GroupTitle>
                  {element.value !== '' && element.value !== 'transparent' && (
                    <Button
                      size="small"
                      onClick={() => handleClick(element, null)}
                      color="secondary"
                      startIcon={<RestoreIcon />}
                    >
                      {t('common.reset')}
                    </Button>
                  )}
                </GroupContainer>
                {colors.length > 0 && (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {colors.map((color) => (
                      <ColorContainer
                        key={color.id}
                        onClick={() => handleClick(element, color)}
                        className={element.value === color.hex ? 'active' : ''}
                      >
                        <Tooltip title={renderTooltipText(color)} placement="left" arrow>
                          <Color color={color.hex} />
                        </Tooltip>
                      </ColorContainer>
                    ))}
                  </div>
                )}
                {colors.length === 0 && <div>{t('pages.dashboard.noColorSwatches')}</div>}
              </React.Fragment>
            ))}
          </ColorSwatchesContainer>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Paper)`
  padding: 1rem;
`;

const Title = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const ColorSwatchesContainer = styled.div`
  min-height: 400px;
`;

const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: 0.5rem;
`;

const GroupTitle = styled.div`
  font-style: italic;
  margin-right: 0.5rem;
`;

const ColorContainer = withTheme(styled.div`
  border: 3px solid transparent;
  border-radius: 8px;
  padding: 1px;
  &.active {
    border-color: ${({ theme }) => theme.palette.secondary.main};
  }
`);

const Color = styled.div<{ color: string }>`
  width: 35px;
  height: 25px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: ${({ color }) => color};
  cursor: pointer;
`;

export default ColorSwatches;
