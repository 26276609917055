import { createOrderArray, httpRequest } from '../../utils';
import { sharingCenterActions, sharingCenterRequests } from './index';
import { SharedProjectsIndexResponse, SharedProjectsListingType } from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';

export const getProjectsSharedWithMe = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { sharingCenter } = getState();

  try {
    dispatch(sharingCenterActions.projectsIndexBegin());

    const request =
      sharingCenter.type === SharedProjectsListingType.SHARED_WITH_ME
        ? sharingCenterRequests.getProjectsSharedWithMe
        : sharingCenterRequests.getProjectsIShared;

    const response = await httpRequest.runRequest<SharedProjectsIndexResponse>(
      request({
        requestParams: {
          page: sharingCenter.pagination.page,
          per: sharingCenter.pagination.per,
          q: sharingCenter.pagination.q,
          order: createOrderArray(
            sharingCenter.tableSearch.order,
            sharingCenter.type === SharedProjectsListingType.SHARED_WITH_ME
              ? ['order=sharedAt,desc']
              : ['order=createdAt,desc']
          )
        }
      })
    );

    dispatch(sharingCenterActions.projectsIndexSuccess(response));
  } catch (e) {
    dispatch(sharingCenterActions.projectsIndexFailed(e));
  }
};
