import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoResultsFound, PageContainer } from '../PageStyledComponents';
import PageHeader, { HeaderActionButton } from '../../Shared/Layout/PageHeader';
import { Grid } from '@material-ui/core';
import Toast from '../../Shared/Toast/Toast';
import Loader from '../../Shared/Loading/Loader';
import styled from 'styled-components';
import { printProjectsActions, printProjectsOperations } from '../../../store/PrintProjects';
import { useTypedSelector } from '../../../utils';
import CachedIcon from '@material-ui/icons/Cached';
import { RouteComponentProps } from 'react-router';
import { hasPermission } from '../../../utils/permissions';
import { FlipBookState } from '../../../store/PrintProjects/types';
import useOpenHandler from '../../../hooks/useOpenHandler';
import FlipBookReGenerateResourcesWindow from './Windows/FlipBookReGenerateResourcesWindow';
import { useDispatch } from 'react-redux';

type FlipBookPageProps = RouteComponentProps<{ projectId?: string }> & {};

const FlipBookPage: FunctionComponent<FlipBookPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const role = useTypedSelector((state) => state.auth.role);
  const mainStorageUrl = useTypedSelector((state) => state.auth.mainStorageUrl);
  const flipBookPreviewProject = useTypedSelector((state) => state.printProjects.flipBookPreview.project);
  const [pageFlip, setPageFlip] = useState<any | null>(null);
  const [initError, setInitError] = useState<boolean>(false);
  const [
    reGenerateResourcesWindowOpen,
    onReGenerateResourcesWindowOpen,
    onReGenerateResourcesWindowClose
  ] = useOpenHandler();

  const initFlipBook = () => {
    setLoading(true);
    try {
      const el = document.getElementById('flip-book');

      if (el) {
        // @ts-ignore
        const newPageFlip = new window.ePub.FlipBook(
          el,
          {
            baseMetaUrl: mainStorageUrl,
            /** Optional. Default: true */
            insertNavigationButtons: true,
            /** Optional. Default: '1px solid #dcdcdc' */
            mainBorder: '1px solid #dcdcdc',
            width: 614,
            height: 792,
            size: 'stretch',
            minWidth: 307,
            maxWidth: 1228,
            minHeight: 396,
            maxHeight: 1584,
            autoSize: true,
            usePortrait: true,
            backgroundColor: '#F6F7FF'
          },
          () => setInitError(true)
        );

        // @ts-ignore
        window.newPageFlip = newPageFlip;

        setPageFlip(newPageFlip);
      }
    } catch (e) {
      Toast.error(t('notifications.generateFlipBook.error'));
    } finally {
      setLoading(false);
    }
  };

  const reGenerateFlipBook = async () => {
    setLoading(true);
    try {
      // @ts-ignore
      window.newPageFlip.destroy();
    } catch (e) {
      // try to destroy the instance, does not matter if it fails...
    }
    try {
      if (match.params.projectId) {
        await printProjectsOperations.generateFlipBookResources(match.params.projectId);
        setInitError(false);
      }
    } catch (e) {
      setInitError(true);
      setLoading(false);
      Toast.error(t('notifications.generateFlipBook.error'));
    }
  };

  useEffect(() => {
    const fetchProjectData = async () => {
      if (match.params.projectId) {
        try {
          const projectData = await printProjectsOperations.show(match.params.projectId);
          dispatch(printProjectsActions.setFlipBookProject(projectData));
        } catch (e) {
          Toast.error('Project ID is invalid.');
        }
      }
    };

    fetchProjectData();

    return () => {
      dispatch(printProjectsActions.setFlipBookProject(null));
    };
  }, []);

  useEffect(() => {
    if (flipBookPreviewProject && flipBookPreviewProject.flipBookState === FlipBookState.DONE) {
      initFlipBook();
    } else {
      setInitError(true);
    }
  }, [flipBookPreviewProject]);

  const getActionButtons = () => {
    const buttons: HeaderActionButton[] = [];

    if (hasPermission(role, ['debugFlipBookRegeneration'])) {
      buttons.push({
        label: t('pages.flipBookPreview.reGenerateFlipBookResources'),
        variant: 'contained' as const,
        icon: <CachedIcon />,
        onClick: () => onReGenerateResourcesWindowOpen()
      });
    }

    return buttons;
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.flipBookPreview.title')} rightActionButtons={getActionButtons()} />

      <Grid container spacing={3}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <strong>{t('pages.flipBookPreview.currentState')}:</strong>{' '}
          {t(`common.flipBookState.${flipBookPreviewProject && flipBookPreviewProject.flipBookState}`)}
        </Grid>

        {loading && (
          <>
            <NoResultsFound>{t('pages.flipBookPreview.generatingFlipBookMessage')}</NoResultsFound>
            <Loader />
          </>
        )}
        <FlipBookContainer item xs={12} style={{ width: '100%', height: '100%' }}>
          <FlipBook id="flip-book" />
        </FlipBookContainer>
      </Grid>
      <FlipBookReGenerateResourcesWindow
        open={reGenerateResourcesWindowOpen}
        fullScreenOnMobile={true}
        onCloseClick={() => onReGenerateResourcesWindowClose()}
        onSubmit={() => reGenerateFlipBook()}
      />
    </PageContainer>
  );
};

const ErrorWrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FlipBook = styled.div`
  margin: 1rem auto;
`;

const FlipBookContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FlipBookPage;
