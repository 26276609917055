import React, { useState } from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import { Chip, ClickAwayListener, Divider, Tooltip, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { StoryChannel } from '../../../../store/Stories/types';
import { generateChannelChipContent, getChannelFields } from '../Utils/storyUtils';

type ChannelChipProps = {
  channel: StoryChannel;
  tooltip?: boolean;
};

const ChannelChip: React.FC<ChannelChipProps> = ({ channel, tooltip = true }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const fieldsOrder = getChannelFields(channel.type);

  const channelContent = channel.fields
    .sort((a, b) => fieldsOrder.indexOf(a.name) - fieldsOrder.indexOf(b.name))
    .map((field, i) => (
      <>
        <strong>{t(`pages.story.inputs.${field.name}`)}</strong>:{' '}
        <span dangerouslySetInnerHTML={{ __html: field.value.toString() }} />
        {i !== channel.fields.length - 1 && <Divider style={{ margin: '0.4rem 0' }} />}
      </>
    ));
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    if (tooltip) {
      setTooltipOpen(true);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <HtmlTooltip
        PopperProps={{
          disablePortal: true
        }}
        onClose={handleTooltipClose}
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        interactive
        title={channelContent.length ? channelContent : t('pages.story.noContent').toString()}
      >
        <Chip
          variant="outlined"
          label={generateChannelChipContent(channel, t, theme)}
          style={{
            margin: '0 2px 2px 0',
            height: 'auto',
            paddingTop: '5px',
            paddingBottom: '5px'
          }}
          onClick={handleTooltipOpen}
        />
      </HtmlTooltip>
    </ClickAwayListener>
  );
};

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    maxWidth: 380,
    fontSize: theme.typography.pxToRem(13),
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: '0.7rem',
    maxHeight: '50vh',
    overflow: 'auto'
  },
  arrow: {
    color: theme.palette.secondary.main
  }
}))(Tooltip);

export default ChannelChip;
