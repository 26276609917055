import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import { PageContentPaper, PageContentPaperHeading } from '../../../PageStyledComponents';
import SaveIcon from '@material-ui/icons/Save';
import { SubmittedPrintProjectsReportResponse } from '../../../../../store/AdminReports/types';
import { adminReportsOperations } from '../../../../../store/AdminReports';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import AdminReportsGenerateCsvWindow from '../Windows/AdminReportsGenerateCsvWindow';
import { Link } from 'react-router-dom';
import linksConstants from '../../../../../config/app/linksConstants';

type SubmittedPrintProjectsReportProps = {};

const SubmittedPrintProjectsReport: FunctionComponent<SubmittedPrintProjectsReportProps> = () => {
  const { t } = useTranslation();
  const [reportData, setReportData] = useState<SubmittedPrintProjectsReportResponse['data']>([]);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [csvDownloadWindowOpen, onCsvDownloadWindowOpen, onCsvDownloadWindowClose] = useOpenHandler();

  useEffect(() => {
    const fetchData = async () => {
      setError(false);
      try {
        const response = await adminReportsOperations.submittedPrintProjectsReport('json');

        if (response) {
          setReportData(response.data);
        }
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    };

    fetchData();
  }, []);

  if (loading && !reportData) {
    return <>Loading...</>;
  }

  return (
    <PageContentPaper>
      <PageContentPaperHeading>
        <Typography variant="h6" gutterBottom>
          {t('pages.adminReports.submittedPrintProjects.title')}
        </Typography>
        <Button color={'secondary'} variant={'contained'} size="medium" onClick={onCsvDownloadWindowOpen}>
          <SaveIcon /> {t('pages.adminReports.generateCsvReport')}
        </Button>
      </PageContentPaperHeading>
      <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
      {error && <div>Something went wrong...</div>}
      {!error && (
        <>
          {reportData.map((project, index) => (
            <Row key={index}>
              <div>
                <Strong>
                  <Link to={linksConstants.ADMINISTRATION.ORGANIZATIONS.USERS(project.organizationId)}>
                    {project.organizationName}
                  </Link>
                </Strong>{' '}
                <em>({project.organizationNumber})</em>
              </div>
              <Link to={linksConstants.PROJECT_DETAILS(project.id)}>{project.name}</Link>{' '}
              {!!project.submittedAt && (
                <>
                  submitted by <Strong>{project.submittedBy || 'unknown'}</Strong> on {project.submittedAt}{' '}
                  <em>(pub date: {project.publishDate})</em>
                </>
              )}
            </Row>
          ))}
        </>
      )}
      <AdminReportsGenerateCsvWindow
        open={csvDownloadWindowOpen}
        onCloseClick={onCsvDownloadWindowClose}
        onSubmit={async (range) => await adminReportsOperations.submittedPrintProjectsReport('csv', range)}
      />
    </PageContentPaper>
  );
};

export default SubmittedPrintProjectsReport;
