import { createOrderArray, httpRequest } from '../../utils';
import { dashboardActions, dashboardRequests } from './index';
import { AnyProject, DashboardProjectsIndexResponse } from './types';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import linksConstants from '../../config/app/linksConstants';
import { ApplicationState } from '../rootReducer';

export const getUserProjectsInProgress = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { dashboard } = getState();

  try {
    dispatch(dashboardActions.projectsIndexBegin());

    const response = await httpRequest.runRequest<DashboardProjectsIndexResponse>(
      dashboardRequests.getUserProjectsInProgress({
        requestParams: {
          page: dashboard.pagination.page,
          per: dashboard.pagination.per,
          q: dashboard.pagination.q,
          order: createOrderArray(dashboard.tableSearch.order)
        }
      })
    );

    dispatch(dashboardActions.projectsIndexSuccess(response));
  } catch (e) {
    dispatch(dashboardActions.projectsIndexFailed(e));
  }
};

export const getProjectByIdRaw = async (projectId: string) => {
  return await httpRequest.runRequest<AnyProject>(
    dashboardRequests.getProjectById({
      requestParams: { projectId }
    })
  );
};

export const getProjectById = (projectId: string) => async (dispatch: Dispatch): Promise<AnyProject | undefined> => {
  try {
    dispatch(dashboardActions.getProjectByIdBegin());

    const response = await httpRequest.runRequest<AnyProject>(
      dashboardRequests.getProjectById({
        requestParams: { projectId }
      })
    );

    dispatch(dashboardActions.getProjectByIdSuccess(response));

    return response;
  } catch (e) {
    dispatch(dashboardActions.getProjectByIdFailed(e));
  }
};

export const notifyUserToCheckOut = async (projectId: string) => {
  return await httpRequest.runRequest<AnyProject>(
    dashboardRequests.notifyUserToCheckOut({
      requestParams: { projectId }
    })
  );
};

export const forceCheckOut = async (projectId: string) => {
  return await httpRequest.runRequest<AnyProject>(
    dashboardRequests.forceCheckOut({
      requestParams: { projectId }
    })
  );
};

export const kickOutFromProject = (project: AnyProject) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  const { notificationToCheckOutWindows } = getState().dashboard;

  const notificationWindow = notificationToCheckOutWindows.find(
    (notification) => notification.project && notification.project.id === project.id
  );

  if (notificationWindow && notificationWindow.project && notificationWindow.project.id === project.id) {
    dispatch(dashboardActions.kickOutFromProject(project));
    if (window.location.pathname.includes(project.id)) {
      dispatch(push(linksConstants.DASHBOARD.INDEX));
    }
  }
};

export const toggleTestFlag = (project: AnyProject) => async (dispatch: Dispatch) => {
  const updatedProject = await httpRequest.runRequest<AnyProject>(
    dashboardRequests.toggleTestFlag({
      requestParams: { projectId: project.id },
      requestPayload: { test: !project.test }
    })
  );

  dispatch(dashboardActions.testFlagToggled(updatedProject));
};
