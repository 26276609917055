import React from 'react';
import { useLocation } from 'react-router';

let debounce: number | null = null;

export function usePageViews() {
  const location = useLocation();

  React.useEffect(() => {
    if (debounce) {
      clearTimeout(debounce);
    }

    debounce = setTimeout(() => {
      if (window.Beacon) {
        window.Beacon('event', {
          type: 'page-viewed',
          url: document.location.href,
          title: document.title
        });
        window.Beacon('suggest');
      }
    }, 1000);
  }, [location, debounce]);
}
