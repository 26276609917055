import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { mailingListsOperations } from '../../../../store/MailingLists';
import { MailingList, UpdateMailingListValues } from '../../../../store/MailingLists/types';
import Toast from '../../../Shared/Toast/Toast';
import { AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import ModalFormWindow from '../../../Shared/Window/ModalFormWindow';
import { FormikProps } from 'formik';
import app from '../../../../config/app/app';

type UpdateMailingListWindowProps = {
  open: boolean;
  mailingList: MailingList | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const UpdateMailingListWindow: FunctionComponent<UpdateMailingListWindowProps> = ({
  mailingList,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!mailingList) return null;

  const onSubmit = async (values: UpdateMailingListValues) => {
    try {
      await dispatch(
        mailingListsOperations.update(mailingList.id, {
          name: values.name
        })
      );
      Toast.success(t('notifications.mailingLists.successOperation'));

      await dispatch(mailingListsOperations.index());

      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.mailingLists.errorOperation'));
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="name" section="mailingLists" />
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      header={t('pages.mailingLists.windows.updateMailingList')}
      okButtonText={t('common.update')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{ name: mailingList.name }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(app.maxInputLength)
          .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric characters are allowed for this field')
          .required()
      })}
    />
  );
};

export default UpdateMailingListWindow;
