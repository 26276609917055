import React, { ChangeEvent, FormEvent, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { WordpressIntegration, WordpressIntegrationForm } from '../../../../store/MyOrganization/types';
import { useTranslation } from 'react-i18next';

type FormField = keyof WordpressIntegrationForm;

type WordPressIntegrationFormProps = {
  onSubmit: (formData: WordpressIntegrationForm) => Promise<void>;
  integration?: WordpressIntegration | null;
};

const WordPressIntegrationForm: React.FC<WordPressIntegrationFormProps> = ({ onSubmit, integration }) => {
  const { t } = useTranslation();
  const getInitialFormData = (): WordpressIntegrationForm => {
    if (integration) {
      return {
        name: integration.name,
        url: integration.url,
        client: integration.client || '',
        secret: ''
      };
    }

    return {
      name: '',
      url: '',
      client: '',
      secret: ''
    };
  };

  const [formData, setFormData] = useState<WordpressIntegrationForm>(getInitialFormData());
  const [formErrors, setFormErrors] = useState<Record<FormField, boolean>>({
    name: false,
    url: false,
    client: false,
    secret: false
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    const errors: Record<FormField, boolean> = {
      name: false,
      url: false,
      client: false,
      secret: false
    };

    let isValid = true;

    for (const key in formData) {
      if (formData[key as FormField] === '') {
        if (!integration || key !== 'secret') {
          errors[key as FormField] = true;
          isValid = false;
        }
      } else {
        errors[key as FormField] = false;
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const integrate = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateForm()) {
      await onSubmit(formData);
    }
  };

  return (
    <div>
      <div>
        <Typography variant="subtitle1">
          {t('pages.story.windows.wordpressIntegration.downloadInfo')}{' '}
          <a href="/downloads/nhm-story-creator.zip" target="_blank" rel="noopener noreferrer">
            {t('pages.story.windows.wordpressIntegration.here')}
          </a>
        </Typography>
      </div>
      <div>
        <form onSubmit={integrate}>
          <TextField
            name="name"
            label={t('pages.story.windows.wordpressIntegration.form.name')}
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            error={formErrors.name}
            helperText={formErrors.name && t('pages.story.windows.wordpressIntegration.form.nameError')}
            onChange={handleInputChange}
            value={formData.name}
          />
          <TextField
            name="url"
            label={t('pages.story.windows.wordpressIntegration.form.url')}
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            error={formErrors.url}
            helperText={formErrors.url && t('pages.story.windows.wordpressIntegration.form.urlError')}
            onChange={handleInputChange}
            value={formData.url}
          />
          <TextField
            name="client"
            label={t('pages.story.windows.wordpressIntegration.form.client')}
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            error={formErrors.client}
            helperText={formErrors.client && t('pages.story.windows.wordpressIntegration.form.clientError')}
            onChange={handleInputChange}
            value={formData.client}
          />
          <TextField
            name="secret"
            label={t('pages.story.windows.wordpressIntegration.form.secret')}
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            error={formErrors.secret}
            helperText={
              (formErrors.secret && t('pages.story.windows.wordpressIntegration.form.secretError')) ||
              (integration && t('pages.story.windows.wordpressIntegration.form.leaveBlankIfNoChange'))
            }
            onChange={handleInputChange}
            value={formData.secret}
          />
          <div style={{ textAlign: 'right', marginBottom: 16 }}>
            <Button type="submit" variant="contained" color="primary" style={{ marginTop: 10 }}>
              {t('pages.story.windows.wordpressIntegration.testAndConnect')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WordPressIntegrationForm;
