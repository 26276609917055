import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { IndexDesignProjectsRequestParams } from './types';

export const index = (settings: RequestSettings<IndexDesignProjectsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/design_project?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{}, { templateId: string; channelId?: string }>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/design_project'
  },
  payload: settings.requestPayload
});

export const show = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/design_project/:projectId'
  },
  params: settings.requestParams
});

export const deleteDesignProject = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/design_project/:id'
  },
  params: settings.requestParams
});

export const saveContent = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/design_project/:id/save'
  },
  params: settings.requestParams
});

export const associateToChannel = (
  settings: RequestSettings<{ projectId: string; channelId: string }, {}>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/design_project/:projectId/associate_to_channel/:channelId'
  },
  params: settings.requestParams
});

export const saveName = (settings: RequestSettings<{ projectId: string }, { name: string }>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/design_project/:projectId'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const cloneProject = (settings: RequestSettings<{ projectId: string }, { name: string }>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/design_project/:projectId/clone'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});
