import React, { useEffect, useState } from 'react';
import { StoryChannelAttachment, StoryChannelType } from '../../../../../store/Stories/types';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isVideo } from '../../Utils/storyUtils';
import { appActions } from '../../../../../store/App';
import { useDispatch } from 'react-redux';
import Loader from '../../../../Shared/Loading/Loader';
import { useTypedSelector } from '../../../../../utils';

export type AttachmentThumbnailProps = {
  attachment: StoryChannelAttachment;
  channelType?: StoryChannelType;
};

export const AttachmentThumbnail: React.FC<AttachmentThumbnailProps> = ({ attachment, channelType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [hasError, setHasError] = useState<boolean>(false);
  const printDesignThumbnailGenerated = useTypedSelector((state) => state.designProjects.printDesignThumbnailGenerated);

  useEffect(() => {
    if (printDesignThumbnailGenerated && attachment.printDesignId === printDesignThumbnailGenerated.id) {
      setHasError(false);
    }
  }, [printDesignThumbnailGenerated]);

  const wrapperStyle = {
    maxWidth: '50px',
    maxHeight: '50px',
    width: '50px',
    height: '50px',
    border: '1px solid #ccc',
    marginRight: '15px',
    cursor: 'pointer',
    display: 'inline-block'
  };

  return (
    <Tooltip
      title={channelType !== undefined ? t(`pages.story.channels.${channelType}`).toString() : ''}
      placement="top"
      arrow
    >
      <>
        {hasError && (
          <div style={{ ...wrapperStyle }}>
            <Loader style={{ scale: '0.5' }} />
          </div>
        )}
        {!hasError && (
          <img
            onError={() => setHasError(true)}
            onClick={() => {
              if (isVideo(attachment.filename)) {
                dispatch(appActions.openVideoPreviewModal(attachment.url));
              } else {
                window.open(attachment.url);
              }
            }}
            style={{ ...wrapperStyle, objectFit: 'contain' }}
            alt={attachment.filename}
            src={attachment.thumbnailUrl}
          />
        )}
      </>
    </Tooltip>
  );
};
