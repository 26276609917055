import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { getCampaignDateRange, useTypedSelector } from '../../../utils';
import { usePaginationWatch } from '../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../Shared/DataTable/useTableSearchWatch';
import useOpenHandler from '../../../hooks/useOpenHandler';
import { PageContainer, PageContent } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import DataTable from '../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../Shared/DataTable/TableActionButton/TableActionButton';
import { TableSearchStoreModule } from '../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../store/Pagination/types';
import linksConstants from '../../../config/app/linksConstants';
import { hasPermission } from '../../../utils/permissions';
import { Campaign } from '../../../store/Campaigns/types';
import { campaignsOperations } from '../../../store/Campaigns';
import DeleteCampaignWindow from './Windows/DeleteCampaignWindow';
import CreateCampaignWindow from './Windows/CreateCampaignWindow';
import { TableLink, TableLinkDisabled } from '../Admin/Organizations/AdminOrganizationsPage';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import CalendarIcon from '@material-ui/icons/CalendarToday';

type CampaignsPageProps = {};

const CampaignsPage: FunctionComponent<CampaignsPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);
  const campaigns = useTypedSelector((state) => state.campaigns.data);
  const isFailed = useTypedSelector((state) => state.campaigns.index.isFailed);
  const isLoading = useTypedSelector((state) => state.campaigns.index.isLoading);
  const pagination = useTypedSelector((state) => state.campaigns.pagination);
  const tableSearch = useTypedSelector((state) => state.campaigns.tableSearch);

  usePaginationWatch(pagination, [campaignsOperations.index]);
  useTableSearchWatch<Campaign>(tableSearch, [campaignsOperations.index]);

  const [activeCampaign, setActiveCampaign] = useState<Campaign | null>(null);
  const [createCampaignWindowOpen, onCreateCampaignWindowOpen, onCreateCampaignWindowClose] = useOpenHandler();
  const [deleteCampaignWindowOpen, onDeleteCampaignWindowOpen, onDeleteCampaignWindowClose] = useOpenHandler();

  const getActionButtons = () => {
    if (hasPermission(role, ['campaignsAdd'])) {
      return [
        {
          label: t('pages.campaigns.create'),
          icon: <AddIcon />,
          onClick: onCreateCampaignWindowOpen
        }
      ];
    }

    return [];
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.campaigns.title')} rightActionButtons={getActionButtons()} />
      <PageContent>
        <DataTable<Campaign, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'name',
              sortable: true,
              render: (campaign) => (
                <>
                  {hasPermission(role, ['campaignsView']) ? (
                    <TableLink onClick={() => dispatch(push(linksConstants.CAMPAIGNS.EDIT(campaign.id)))}>
                      {campaign.name}
                    </TableLink>
                  ) : (
                    <TableLinkDisabled>{campaign.name}</TableLinkDisabled>
                  )}
                  <Typography variant="caption" display="block">
                    {campaign.description}
                  </Typography>
                  {campaign.projects.length > 0 && (
                    <Typography variant="caption" display="block">
                      <CalendarIcon style={{ fontSize: '12px', marginRight: 5 }} />
                      {getCampaignDateRange(campaign)}
                    </Typography>
                  )}
                </>
              )
            },
            {
              name: 'projects',
              sortable: false,
              render: (campaign) => <>{campaign.projects.length}</>
            },
            {
              name: 'createdBy',
              sortable: false,
              render: (campaign) => <>{campaign.createdBy.fullName}</>
            },
            {
              name: 'actions',
              render: (campaign) => (
                <TableActionButtonsContainer>
                  <TableActionButton
                    icon={<EditIcon />}
                    tooltip={t('pages.campaigns.table.buttons.edit')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['campaignsEdit']),
                      onClick: () => dispatch(push(linksConstants.CAMPAIGNS.EDIT(campaign.id)))
                    }}
                  />
                  <TableActionButton
                    icon={<DeleteIcon />}
                    tooltip={t('pages.campaigns.table.buttons.delete')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['campaignsRemove']),
                      onClick: () => {
                        setActiveCampaign(campaign);
                        onDeleteCampaignWindowOpen();
                      }
                    }}
                  />
                </TableActionButtonsContainer>
              )
            }
          ]}
          tPath={'pages.campaigns.table'}
          data={campaigns}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.CAMPAIGNS
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.CAMPAIGNS
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>

      <CreateCampaignWindow
        open={createCampaignWindowOpen}
        onCloseClick={onCreateCampaignWindowClose}
        fullScreenOnMobile
      />
      <DeleteCampaignWindow
        campaign={activeCampaign}
        open={deleteCampaignWindowOpen}
        onCloseClick={onDeleteCampaignWindowClose}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

export default CampaignsPage;
