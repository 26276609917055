import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../../Shared/StyledComponents';

type DoAppRunningPromotionReportContentProps = {
  open: boolean;
  content: string;
  onCloseClick: () => void;
};

const DoAppRunningPromotionReportContent: FunctionComponent<DoAppRunningPromotionReportContentProps> = ({
  open,
  content,
  onCloseClick
}) => {
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.doApp.runningPromotionReports.content')}
      hideActionButtons
      onCloseClick={onCloseClick}
    >
      <Row>{content}</Row>
    </ModalConfirmationWindow>
  );
};

export default DoAppRunningPromotionReportContent;
