import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Box, Button } from '@material-ui/core';
import linksConstants from '../../../../config/app/linksConstants';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ReactComponent as DesignProjectsIcon } from '../../../../assets/icons/navigation/design-projects.svg';

type AddDesignProjectWindowProps = {
  storyId: string;
  channelId: string;
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AddDesignProjectWindow: FunctionComponent<AddDesignProjectWindowProps> = ({
  channelId,
  storyId,
  onCloseClick,
  open
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.designProjects.addDesignProjectTitle')}
      onCloseClick={onCloseClick}
      hideActionButtons
      maxWidth="sm"
    >
      <Box display="flex" alignItems="center" justifyContent="center" my={1}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => dispatch(push(linksConstants.STORY.DESIGNS(storyId, channelId)))}
          style={{ marginRight: 15 }}
        >
          {t('pages.designProjects.selectExistingProject')}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => dispatch(push(linksConstants.DASHBOARD.PROJECT_CREATOR.design, { channelId, storyId }))}
        >
          <DesignProjectsIcon
            style={{
              width: '18px',
              height: '18px',
              fill: '#fff',
              verticalAlign: 'middle',
              marginRight: '5px'
            }}
          />
          {t('pages.designProjects.createNewDesign')}
        </Button>
      </Box>
    </ModalConfirmationWindow>
  );
};

export default AddDesignProjectWindow;
