import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { DataTableHeaderCell } from '../../../Shared/DataTable/DataTableStyled';
import AdsStatisticsAdvertiserSection from './AdsStatisticsAdvertiserSection';
import { AdvertiserStatisticsData } from '../../../../store/AdminAds/types';

type AdsStatisticsTableProps = {
  advertiserStats: AdvertiserStatisticsData[];
  startDate: string;
  endDate: string;
};

const AdsStatisticsTable: FunctionComponent<AdsStatisticsTableProps> = ({ advertiserStats, startDate, endDate }) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '24px' }} />
            <TableCell>
              <DataTableHeaderCell>{t('pages.adsStatistics.table.name')}</DataTableHeaderCell>
            </TableCell>
            <TableCell>
              <DataTableHeaderCell>{t('pages.adsStatistics.table.impressions')}</DataTableHeaderCell>
            </TableCell>
            <TableCell>
              <DataTableHeaderCell>{t('pages.adsStatistics.table.clicks')}</DataTableHeaderCell>
            </TableCell>
            <TableCell>
              <DataTableHeaderCell>{t('pages.adsStatistics.table.ctr')}</DataTableHeaderCell>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {advertiserStats.map((advertiser) => (
            <AdsStatisticsAdvertiserSection
              advertiser={advertiser}
              key={advertiser.advertiserid}
              startDate={startDate}
              endDate={endDate}
            />
          ))}
          {advertiserStats.length === 0 && (
            <TableRow>
              <TableCell colSpan={5}>{t('pages.adsStatistics.table.noStatistics')}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdsStatisticsTable;
