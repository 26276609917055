import { httpRequest } from '../../utils';
import {
  AdBanner,
  AdCategory,
  AdminCreateAdBannerValues,
  AdminCreateAdCategoryValues,
  AdminUpdateAdBannerValues,
  AdminUpdateAdCategoryValues,
  Advertiser,
  AdvertiserBannerStatistics,
  AdvertiserStatistics,
  AdZone,
  ChartDataByDays
} from './types';
import { adminAdsRequests } from './index';
import moment from 'moment';
import { AdminOrganization } from '../AdminOrganizations/types';
import { AdminEmailTemplate } from '../AdminEmailTemplates/types';

const prepareBannerFormData = (formData: FormData, values: AdminCreateAdBannerValues | AdminUpdateAdBannerValues) => {
  if (values.bannerFile) {
    formData.append('bannerFile', values.bannerFile);
  }

  formData.append('height', values.height);
  formData.append('width', values.width);
  formData.append('weight', values.weight);
  formData.append('alt', values.alt);
  formData.append('comments', values.comments);
  formData.append('storageType', values.storageType);
  formData.append('url', values.url);
  formData.append('bannerName', values.bannerName);
  formData.append('target', values.target);
  formData.append('advertiserId', values.advertiserId);
  formData.append('startDate', moment(values.startDate).set({ hours: 0, minutes: 0, seconds: 0 }).format());
  if (values.endDate) {
    formData.append('endDate', moment(values.endDate).set({ hours: 23, minutes: 59, seconds: 59 }).format());
  }
  if (values.category && values.category.id) {
    formData.append('categoryId', values.category.id);
  }

  return formData;
};

export const createAdBanner = (values: AdminCreateAdBannerValues) => async () => {
  const formData = prepareBannerFormData(new FormData(), values);

  return await httpRequest.runRequest<AdBanner>({
    ...adminAdsRequests.createAdBanner({
      // @ts-ignore
      requestPayload: formData
    })
  });
};

export const updateAdBanner = (bannerId: string, values: AdminUpdateAdBannerValues) => async () => {
  const formData = prepareBannerFormData(new FormData(), values);

  return await httpRequest.runRequest<AdBanner>({
    ...adminAdsRequests.updateAdBanner({
      requestParams: { bannerId },
      // @ts-ignore
      requestPayload: formData
    })
  });
};

export const deleteAdBanner = (bannerId: string) => async () => {
  return await httpRequest.runRequest<void>(
    adminAdsRequests.deleteAdBanner({
      requestParams: { bannerId }
    })
  );
};

export const getZones = async (advertiserId: string, sizes: string[]) => {
  return httpRequest.runRequest<{ data: { zone: AdZone; banner: AdBanner }[] }>(
    adminAdsRequests.getZones({
      requestParams: { sizes: sizes.join('|'), advertiserId }
    })
  );
};

export const indexOrganizationBanners = async (organizationId: string) => {
  return httpRequest.runRequest<{
    data: AdBanner[];
    organization: AdminOrganization;
    emailTemplates: AdminEmailTemplate[];
  }>(
    adminAdsRequests.indexOrganizationBanners({
      requestParams: { organizationId }
    })
  );
};

export const indexHouseAds = async () => {
  return httpRequest.runRequest<{
    data: AdBanner[];
    advertiser: Advertiser;
    emailTemplates: AdminEmailTemplate[];
    categories: AdCategory[];
  }>(adminAdsRequests.indexHouseAds());
};

export const getChartData = async (startDate: string, endDate: string) => {
  return httpRequest.runRequest<{
    data: ChartDataByDays;
  }>(
    adminAdsRequests.getChartData({
      requestParams: { startDate, endDate }
    })
  );
};

export const getAdvertiserStatistics = async (startDate: string, endDate: string) => {
  return httpRequest.runRequest<{
    data: AdvertiserStatistics;
  }>(
    adminAdsRequests.getAdvertiserStatistics({
      requestParams: { startDate, endDate }
    })
  );
};

export const getAdvertiserBannerStatistics = async (advertiserId: number, startDate: string, endDate: string) => {
  return httpRequest.runRequest<{
    data: AdvertiserBannerStatistics;
  }>(
    adminAdsRequests.getAdvertiserBannerStatistics({
      requestParams: { advertiserId, startDate, endDate }
    })
  );
};

export const indexHouseAdsCategories = async () => {
  return httpRequest.runRequest<{ data: AdCategory[] }>(adminAdsRequests.indexHouseAdsCategories());
};

export const createAdCategory = async (payload: AdminCreateAdCategoryValues) => {
  return httpRequest.runRequest<AdCategory>(
    adminAdsRequests.createAdCategory({
      requestPayload: payload
    })
  );
};

export const updateAdCategory = async (id: string, payload: AdminUpdateAdCategoryValues) => {
  return httpRequest.runRequest<AdCategory>(
    adminAdsRequests.updateAdCategory({
      requestPayload: payload,
      requestParams: { id }
    })
  );
};

export const removeAdCategory = async (category: AdCategory) => {
  return httpRequest.runRequest<AdCategory>(
    adminAdsRequests.removeAdCategory({
      requestParams: { id: category.id }
    })
  );
};
