import React, { FunctionComponent } from 'react';
import { Redirect, Switch } from 'react-router';
import RoutesConfig from '../../../routes/RoutesConfig';
import linksConstants from '../../../config/app/linksConstants';
import PublicLayoutRoute from '../../../routes/PublicLayoutRoute';

type SignedInRoutesContainerProps = {};

const PublicLayoutRoutesContainer: FunctionComponent<SignedInRoutesContainerProps> = () => {
  const publicRoutes = RoutesConfig.createPublicRoutes();

  return (
    <Switch key={location.pathname}>
      {publicRoutes.map((route) => (
        <PublicLayoutRoute
          key={route.name}
          name={route.name}
          exact={route.exact}
          path={route.path}
          component={route.component}
        />
      ))}
      <Redirect to={linksConstants.AUTH.LOGIN} />
    </Switch>
  );
};

export default PublicLayoutRoutesContainer;
