import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../utils';
import { usePaginationWatch } from '../../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../../Shared/DataTable/useTableSearchWatch';
import { PageContainer, PageContent } from '../../../PageStyledComponents';
import PageHeader from '../../../../Shared/Layout/PageHeader';
import DataTable from '../../../../Shared/DataTable/DataTable';
import { useDispatch } from 'react-redux';
import { doAppMerchantsOperations } from '../../../../../store/DoApp/Merchants';
import { DoAppMerchant } from '../../../../../store/DoApp/Merchants/types';
import TableActionButtonsContainer from '../../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import { TableSearchStoreModule } from '../../../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../../../store/Pagination/types';

type DoAppMerchantsPageProps = {};

const DoAppMerchantsPage: FunctionComponent<DoAppMerchantsPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const doAppMerchants = useTypedSelector((state) => state.doAppMerchants.data);
  const isFailed = useTypedSelector((state) => state.doAppMerchants.index.isFailed);
  const isLoading = useTypedSelector((state) => state.doAppMerchants.index.isLoading);
  const pagination = useTypedSelector((state) => state.doAppMerchants.pagination);
  const tableSearch = useTypedSelector((state) => state.doAppMerchants.tableSearch);

  usePaginationWatch(pagination, [doAppMerchantsOperations.index]);
  useTableSearchWatch<DoAppMerchant>(tableSearch, [doAppMerchantsOperations.index]);

  const getActionButtons = () => {
    return [];
  };

  return (
    <PageContainer>
      <PageHeader title={t(`pages.doApp.merchants.title`)} rightActionButtons={getActionButtons()} />
      <PageContent>
        <DataTable<DoAppMerchant, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'name',
              sortable: true,
              render: (row) => <>{row.name}</>
            },
            {
              name: 'actions',
              render: () => <TableActionButtonsContainer>Actions....</TableActionButtonsContainer>
            },
            {
              name: 'email',
              sortable: true,
              render: (row) => <>{row.email}</>
            },
            {
              name: 'billingEmail',
              sortable: true,
              render: (row) => <>{row.billingEmail}</>
            },
            {
              name: 'merchantPortalId',
              sortable: true,
              render: (row) => <>{row.merchantPortalId}</>
            },
            {
              name: 'region',
              sortable: true,
              render: (row) => <>{row.region}</>
            },
            {
              name: 'district',
              sortable: true,
              render: (row) => <>{row.district}</>
            }
          ]}
          tPath={'pages.doApp.merchants.table'}
          data={doAppMerchants}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.DO_APP_MERCHANTS
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.DO_APP_MERCHANTS
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>
    </PageContainer>
  );
};

export default DoAppMerchantsPage;
