import React, { FunctionComponent } from 'react';
import { EmailText } from '../../types';
import WysiwygEditor from './Utils/WysiwygEditor';
import { ComponentActionIcons, StyledEditIcon } from '../../../../../Shared/StyledComponents';
import { ReactComponent as SettingsIcon } from '../../../../../../assets/icons/settings.svg';
import useOpenHandler from '../../../../../../hooks/useOpenHandler';
import TextSettingsWindow from '../Windows/TextSettingsWindow';
import { ExtendedCSSProperties, translatePaddingProperty } from './Utils/translatePaddingProperty';

type TextProps = {
  data: EmailText;
  onChange: (data: EmailText) => void;
};

const Text: FunctionComponent<TextProps> = ({ data, onChange }) => {
  const [textSettingsWindowOpen, onTextSettingsWindowOpen, onTextSettingsWindowClose] = useOpenHandler();

  return (
    <div style={data.wrapperStyle}>
      <WysiwygEditor
        inline
        content={data.params.content}
        onBlur={(newContent) =>
          onChange({
            ...data,
            params: {
              ...data.params,
              content: newContent
            }
          })
        }
      />
      <ComponentActionIcons style={{ left: 'calc(100% - 45px)' }}>
        <StyledEditIcon onClick={() => onTextSettingsWindowOpen()}>
          <SettingsIcon style={{ width: 22, fill: '#fff' }} />
        </StyledEditIcon>
      </ComponentActionIcons>

      {textSettingsWindowOpen && (
        <TextSettingsWindow
          open={textSettingsWindowOpen}
          onCloseClick={onTextSettingsWindowClose}
          onFormSubmit={(values) => {
            onChange({
              ...data,
              wrapperStyle: {
                ...data.wrapperStyle,
                backgroundColor: values.backgroundColor,
                marginTop: values.marginTop + 'px',
                marginRight: values.marginRight + 'px',
                marginBottom: values.marginBottom + 'px',
                marginLeft: values.marginLeft + 'px',
                paddingTop: values.paddingTop + 'px',
                paddingRight: values.paddingRight + 'px',
                paddingBottom: values.paddingBottom + 'px',
                paddingLeft: values.paddingLeft + 'px',
                borderTopLeftRadius: values.borderTopLeftRadius + 'px',
                borderTopRightRadius: values.borderTopRightRadius + 'px',
                borderBottomLeftRadius: values.borderBottomLeftRadius + 'px',
                borderBottomRightRadius: values.borderBottomRightRadius + 'px'
              }
            });
            onTextSettingsWindowClose();
          }}
          backgroundColor={
            data.wrapperStyle && data.wrapperStyle.backgroundColor ? data.wrapperStyle.backgroundColor : '#fff'
          }
          marginTop={data.wrapperStyle && data.wrapperStyle.marginTop ? data.wrapperStyle.marginTop.toString() : '0'}
          marginRight={
            data.wrapperStyle && data.wrapperStyle.marginRight ? data.wrapperStyle.marginRight.toString() : '0'
          }
          marginBottom={
            data.wrapperStyle && data.wrapperStyle.marginBottom ? data.wrapperStyle.marginBottom.toString() : '0'
          }
          marginLeft={data.wrapperStyle && data.wrapperStyle.marginLeft ? data.wrapperStyle.marginLeft.toString() : '0'}
          paddingTop={translatePaddingProperty('paddingTop', data.wrapperStyle as ExtendedCSSProperties)}
          paddingRight={translatePaddingProperty('paddingRight', data.wrapperStyle as ExtendedCSSProperties)}
          paddingBottom={translatePaddingProperty('paddingBottom', data.wrapperStyle as ExtendedCSSProperties)}
          paddingLeft={translatePaddingProperty('paddingLeft', data.wrapperStyle as ExtendedCSSProperties)}
          borderTopLeftRadius={
            data.wrapperStyle && data.wrapperStyle.borderTopLeftRadius
              ? data.wrapperStyle.borderTopLeftRadius.toString()
              : '0'
          }
          borderTopRightRadius={
            data.wrapperStyle && data.wrapperStyle.borderTopRightRadius
              ? data.wrapperStyle.borderTopRightRadius.toString()
              : '0'
          }
          borderBottomLeftRadius={
            data.wrapperStyle && data.wrapperStyle.borderBottomLeftRadius
              ? data.wrapperStyle.borderBottomLeftRadius.toString()
              : '0'
          }
          borderBottomRightRadius={
            data.wrapperStyle && data.wrapperStyle.borderBottomRightRadius
              ? data.wrapperStyle.borderBottomRightRadius.toString()
              : '0'
          }
        />
      )}
    </div>
  );
};

export default Text;
