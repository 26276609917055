import { ListItemText, MenuItem, Paper, TextField, Typography, withTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PrintProject } from '../../../../../store/PrintProjects/types';
import { useTranslation } from 'react-i18next';
import ProjectAvatar from '../../../../Shared/Avatar/ProjectAvatar';
import PublicationDateCell from '../../WorkInProgressTable/PublicationDateCell';
import ProjectStatusCell from '../../WorkInProgressTable/ProjectStatusCell';
import { templatesOperations } from '../../../../../store/Templates';
import { Template } from '../../../../../store/Templates/types';
import Toast from '../../../../Shared/Toast/Toast';
import Loader from '../../../../Shared/Loading/Loader';
import TemplateThumbnail from '../../../../Shared/Thumbnail/TemplateThumbnail';
import { PrintCenter } from '../../../../../store/SystemSettings/types';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import EventIcon from '@material-ui/icons/Event';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';

type PrintPublishFormProps = {
  project: PrintProject;
  printCenters: PrintCenter[];
  activePrintCenter: PrintCenter | null;
  numberOfPages: number;
  setActivePrintCenter: (center: PrintCenter) => void;
};

const PrintPublishForm: React.FC<PrintPublishFormProps> = ({
  project,
  printCenters,
  activePrintCenter,
  numberOfPages,
  setActivePrintCenter
}) => {
  const { t } = useTranslation();
  const [templateDataLoading, setTemplateDataLoading] = useState<boolean>(true);
  const [templateData, setTemplateData] = useState<Template | null>(null);

  useEffect(() => {
    const fetchTemplateData = async (templateId: string) => {
      try {
        const templateResponse = await templatesOperations.getTemplate(templateId);
        setTemplateData(templateResponse);
      } catch (error) {
        Toast.error(t('notifications.common.errorOperation'));
      } finally {
        setTemplateDataLoading(false);
      }
    };

    fetchTemplateData(project.templateId);
  }, []);

  return (
    <FormContent>
      {templateDataLoading && <Loader />}
      {!templateDataLoading && templateData && (
        <TemplateWrapper>
          <Paper style={{ width: 180, height: 240 }}>
            <TemplateThumbnail thumbnailUrl={templateData.thumbnailUrl} width="180px" height="240px" />
          </Paper>
          <ListItemText
            style={{ textAlign: 'center', flex: 0, marginBottom: '1rem' }}
            primary={
              <Typography variant="subtitle1" color="secondary" gutterBottom style={{ fontWeight: 'bold' }}>
                {templateData.name}
              </Typography>
            }
            secondary={templateData.description}
          />
        </TemplateWrapper>
      )}
      <ProjectContainer>
        <FormTitle>
          <strong>{t('windows.printPublishFormStep.project')}:</strong>
        </FormTitle>
        <ContentArea style={{ marginBottom: 20 }}>
          <ProjectAvatar type={project.type} />
          <div>
            <ProjectTitle>
              {project.name}
              <br />
              <ProjectStatusCell project={project} />
            </ProjectTitle>
          </div>
        </ContentArea>
        <ContentArea>
          <FormTitle>
            <EventIcon fontSize="small" style={{ marginRight: 5 }} />
            {t('windows.printPublishFormStep.publicationDate')}:&nbsp;
            <strong>
              <PublicationDateCell project={project} />
            </strong>
          </FormTitle>
        </ContentArea>
        <ContentArea>
          <FormTitle>
            <MenuBookOutlinedIcon fontSize="small" style={{ marginRight: 5 }} />
            {t('windows.printPublishFormStep.numberOfPages')}:&nbsp;<strong>{numberOfPages}</strong>
          </FormTitle>
        </ContentArea>
        <ContentArea>
          <FormTitle noMargin>
            <BusinessOutlinedIcon fontSize="small" style={{ marginRight: 5 }} />
            {t('windows.printPublishFormStep.printCenter')}:
          </FormTitle>
          {activePrintCenter && (
            <TextField
              id="select-print-center"
              select
              variant="outlined"
              size="small"
              value={activePrintCenter.id}
              style={{ marginLeft: '10px' }}
              onChange={(e) => {
                const printCenter = printCenters.find((center) => center.id === e.target.value);
                if (printCenter) {
                  setActivePrintCenter(printCenter);
                }
              }}
            >
              {printCenters.map((printCenter) => (
                <MenuItem key={printCenter.id} value={printCenter.id}>
                  {printCenter.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </ContentArea>
        {!activePrintCenter && <InfoBox type="warning">{t('windows.printPublishFormStep.noPrintCenters')}</InfoBox>}
      </ProjectContainer>
    </FormContent>
  );
};

const FormContent = styled.div`
  display: flex;
`;

const FormTitle = styled('h2')<{ noMargin?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '10px;')};
`;

const ContentArea = styled.div`
  display: flex;
  align-items: center;
`;

const ProjectTitle = withTheme(styled.div`
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  word-break: normal;
`);

const TemplateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem 1rem 0;
  justify-content: flex-start;
  align-items: center;
`;
const ProjectContainer = styled.div`
  flex-grow: 1;
  margin: 1rem;
  max-height: 100%;
`;

export default PrintPublishForm;
