import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { EditRowFormWindowData, EmailProjectRow, ProjectModelColumn, ProjectModelComponent } from '../../types';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../../Shared/Window/ModalFormWindow';
import { ComponentEditFormReturnType, componentHasConfig, getComponentEditForm, getRowEditForm } from '../EditForms';
import { FormikProps } from 'formik';
import { AdminInputLabel } from '../../../../Admin/AdminStyledComponents';
import { Strong } from '../../../../../Shared/StyledComponents';
import { ComponentToUpdate, RowToUpdateAlongWithComponents } from '../../Utils/modelTransformator';
import { Divider } from '@material-ui/core';

type EditRowWindowProps = {
  open: boolean;
  row: EmailProjectRow | null;
  rowIndex: number;
  onCloseClick: () => void;
  onFormSubmit: () => void;
  updateComponentsAndRow: (
    components: ComponentToUpdate[],
    rowData?: RowToUpdateAlongWithComponents,
    cols?: ProjectModelColumn[]
  ) => void;
  fullScreenOnMobile: boolean;
  inProjectEditor: boolean;
};

const getComponentType = (type: ProjectModelComponent['type']): string => {
  if (['image', 'logo-horizontal', 'logo-vertical'].includes(type)) {
    return 'image';
  }

  if (['article-horizontal', 'article-vertical'].includes(type)) {
    return 'article';
  }

  if (type === 'social-links') {
    return 'socialLinks';
  }

  if (type === 'ad-space') {
    return 'adSpace';
  }

  return type;
};

const EditRowWindow: FunctionComponent<EditRowWindowProps> = ({
  row,
  rowIndex,
  onCloseClick,
  onFormSubmit,
  open,
  inProjectEditor,
  updateComponentsAndRow
}) => {
  const { t } = useTranslation();

  if (!row) return null;

  const rowFormData = getRowEditForm(row, t, inProjectEditor);

  if (!rowFormData) return null;

  const components = row.cols.reduce((comps, row) => {
    row.components.forEach((c) => comps.push(c));
    return comps;
  }, [] as ProjectModelComponent[]);

  const componentWithForms = components.filter((component) => componentHasConfig(component, inProjectEditor));
  const componentForms: ComponentEditFormReturnType<ProjectModelComponent>[] = [];
  const uniqueComponents: string[] = [];

  for (const component of componentWithForms) {
    const type = getComponentType(component.type);

    if (!uniqueComponents.includes(type)) {
      uniqueComponents.push(type);
      const form = getComponentEditForm(component, t, inProjectEditor);
      if (form) {
        componentForms.push(form);
      }
    }
  }

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.submit')}
      header={'Edit This Row'}
      onCloseClick={onCloseClick}
      onSubmit={(params: EditRowFormWindowData) => {
        const componentsToUpdate: ComponentToUpdate[] = [];

        row.cols.forEach((column, columnIndex) => {
          column.components.forEach((component, componentIterator) => {
            const type = getComponentType(component.type);

            if (params[type]) {
              const componentForm = componentForms.find((form) => form.type === type);
              if (componentForm) {
                const updatedComponentData = componentForm.onSubmit(params, component);

                componentsToUpdate.push({
                  component: updatedComponentData,
                  componentIterator,
                  rowIndex,
                  columnIndex
                });
              }
            }
          });
        });

        updateComponentsAndRow(componentsToUpdate, { row: rowFormData.onSubmit(params), index: rowIndex }, params.cols);

        onFormSubmit();
      }}
      formInputs={(props: FormikProps<any>) => (
        <>
          {componentForms.length > 0 && (
            <AdminInputLabel>
              <Strong>{t('pages.emailEditor.component')}</Strong>
            </AdminInputLabel>
          )}
          {componentForms.map((componentForm) => componentForm.form(props))}
          {componentForms.length > 0 && <Divider style={{ margin: '15px 0' }} />}
          {rowFormData.form(props)}
        </>
      )}
      initialValues={{
        ...rowFormData.initialFields,
        ...componentForms.reduce((all, formData) => {
          all = {
            ...all,
            ...formData.initialFields
          };
          return all;
        }, {})
      }}
      validationSchema={Yup.object().shape({
        ...rowFormData.validationSchema,
        ...componentForms.reduce((all, formData) => {
          all = {
            ...all,
            ...formData.validationSchema
          };
          return all;
        }, {})
      })}
    />
  );
};

export default EditRowWindow;
