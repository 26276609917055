import {
  SharedProjectsIndexActionBEGIN,
  SharedProjectsIndexActionFAILED,
  SharedProjectsIndexActionSUCCESS,
  SharedProjectsIndexResponse,
  SharedProjectsListingType,
  SharedProjectsSetTypeAction,
  SharingCenterActionsConstants
} from './types';

export const projectsIndexBegin = (): SharedProjectsIndexActionBEGIN => ({
  type: SharingCenterActionsConstants.INDEX_BEGIN
});

export const projectsIndexSuccess = (payload: SharedProjectsIndexResponse): SharedProjectsIndexActionSUCCESS => ({
  type: SharingCenterActionsConstants.INDEX_SUCCESS,
  payload
});

export const projectsIndexFailed = (error: any): SharedProjectsIndexActionFAILED => ({
  type: SharingCenterActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const setListingType = (type: SharedProjectsListingType): SharedProjectsSetTypeAction => ({
  type: SharingCenterActionsConstants.SET_LISTING_TYPE,
  payload: type
});
