import React, { FunctionComponent } from 'react';
import { AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AdminRole } from '../../../../store/AdminRoles/types';
import { User } from '../../../../store/Auth/types';
import { CampaignProjectType } from '../../../../store/Campaigns/types';
import { StyledAccordion } from '../../PageStyledComponents';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ProjectCampaigns from './ProjectCampaigns';

type ProjectCampaignsAccordionProps = {
  projectId: string;
  role: AdminRole;
  user: User;
  type: CampaignProjectType;
};

const ProjectCampaignsAccordion: FunctionComponent<ProjectCampaignsAccordionProps> = ({
  projectId,
  type,
  role,
  user
}) => {
  const { t } = useTranslation();

  return (
    <StyledAccordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={'campaigns-content'}>
        <Typography variant="h6">{t('common.campaigns')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ProjectCampaigns projectId={projectId} role={role} user={user} type={type} />
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default ProjectCampaignsAccordion;
