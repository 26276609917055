import React from 'react';
import Loader from '../../Loading/Loader';
import { DataTableInfoBodyProps } from '../types';
import styled from 'styled-components';

export const LoadingData: React.FC<DataTableInfoBodyProps> = ({ columnAmount }) => (
  <LoaderWrapper>
    <Loader />
  </LoaderWrapper>
);

const LoaderWrapper = styled.div`
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;
