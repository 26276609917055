import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { WordpressIntegration } from '../../../../store/MyOrganization/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import { DamSystem } from '../../../../store/SystemSettings/types';
import { systemSettingsOperations } from '../../../../store/SystemSettings';

type DeleteDamWindowProps = {
  open: boolean;
  dam: DamSystem | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  onSuccessfulSubmit?: () => void;
};

const DeleteDamWindow: FunctionComponent<DeleteDamWindowProps> = ({ onCloseClick, dam, open, onSuccessfulSubmit }) => {
  const { t } = useTranslation();

  if (!dam) return null;

  const onSubmit = async () => {
    try {
      await systemSettingsOperations.deleteDamSystem(dam.id);

      if (onSuccessfulSubmit) {
        onSuccessfulSubmit();
      }
      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.common.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.systemSettings.dam.deleteDamWindowTitle')}
      okButtonText={t('common.remove')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.systemSettings.dam.deleteDamWindowContent')}</Row>
      <Row>
        <Strong>{t(`common.damSystem.${dam.name}`)}</Strong>
      </Row>
      <Row>
        <Strong>{dam.url}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteDamWindow;
