import { format } from 'date-fns';
import { StoryChannel, StoryFieldName } from '../../../../../../../store/Stories/types';
import { addNegativeTimezoneOffset, trimHtml } from '../../../../../../../utils';
import { getFieldValue } from '../../../../../Stories/Utils/storyUtils';

export const getCalendarEventData = (event: StoryChannel) => {
  const eventName = getFieldValue(event, StoryFieldName.NAME).toString();
  const eventStartDate = addNegativeTimezoneOffset(getFieldValue(event, StoryFieldName.START_DATE));
  const eventEndDate = addNegativeTimezoneOffset(getFieldValue(event, StoryFieldName.END_DATE));
  const eventDescription = trimHtml(getFieldValue(event, StoryFieldName.DESCRIPTION).toString(), {
    limit: 160,
    preserveTags: false
  }).html;
  const eventUrl = getFieldValue(event, StoryFieldName.URL).toString();

  const eventStartDay = eventStartDate.getDate();
  const eventEndDay = eventEndDate.getDate();
  const eventStartMonth = format(eventStartDate, 'LLL');
  const eventEndMonth = format(eventEndDate, 'LLL');
  const eventStartYear = eventStartDate.getFullYear();
  const eventEndYear = eventEndDate.getFullYear();

  const isSingleDayEvent =
    eventStartDay === eventEndDay && eventStartMonth === eventEndMonth && eventStartYear === eventEndYear;
  const thumbnailUrl = event.attachments.length > 0 ? event.attachments[0].thumbnailUrl : null;

  return {
    name: eventName,
    description: eventDescription,
    url: eventUrl,
    startDay: eventStartDay,
    endDay: eventEndDay,
    startMonth: eventStartMonth,
    endMonth: eventEndMonth,
    thumbnailUrl,
    isSingleDayEvent
  };
};
