import update from 'immutability-helper';
import {
  EmailProject,
  EmailProjectsActionsConstants,
  EmailProjectsActionType,
  EmailProjectsState,
  EmailProjectStatus
} from './types';
import { normalizeProjects } from '../helpers';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { DashboardActionsConstants } from '../Dashboard/types';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: EmailProjectsState = {
  projects: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: true
  }
};

const emailProjectsPaginationReducer = createPaginationReducer<EmailProjectsState, EmailProject>(
  PaginationStoreModule.EMAIL_PROJECTS
);

const emailProjectsTableSearchReducer = createTableSearchReducer<EmailProject, EmailProjectsState>(
  TableSearchStoreModule.EMAIL_PROJECTS
);

export default function emailProjectsReducer(state = initialState, action: WithLogoutAction<EmailProjectsActionType>) {
  switch (action.type) {
    case EmailProjectsActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case EmailProjectsActionsConstants.INDEX_SUCCESS: {
      const data = normalizeProjects(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        projects: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case EmailProjectsActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        projects: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case EmailProjectsActionsConstants.REMOVE_PROJECT:
      return update(state, {
        projects: {
          result: { $set: state.projects.result.filter((projectId) => projectId !== action.payload) }
        }
      });

    case EmailProjectsActionsConstants.PUBLISH_PROJECT: {
      const project = state.projects.entities[action.payload.id];
      if (!project) {
        return state;
      }

      return update(state, {
        projects: {
          entities: {
            [action.payload.id]: {
              status: { $set: EmailProjectStatus.SCHEDULED },
              subject: { $set: action.payload.subject },
              exactTime: { $set: action.payload.exactTime },
              publishDate: { $set: action.payload.publishDate }
            }
          }
        }
      });
    }

    case EmailProjectsActionsConstants.SAVE_PROJECT_SUBJECT: {
      const project = state.projects.entities[action.payload.id];
      if (!project) {
        return state;
      }

      return update(state, {
        projects: {
          entities: {
            [action.payload.id]: {
              subject: { $set: action.payload.subject }
            }
          }
        }
      });
    }

    case EmailProjectsActionsConstants.CANCEL_PUBLISH_PROJECT: {
      const project = state.projects.entities[action.payload.id];
      if (!project) {
        return state;
      }

      return update(state, {
        projects: {
          entities: {
            [action.payload.id]: {
              status: { $set: EmailProjectStatus.IN_PROGRESS },
              exactTime: { $set: null }
            }
          }
        }
      });
    }

    case EmailProjectsActionsConstants.CHECK_IN_PROJECT: {
      const project = state.projects.entities[action.payload.projectId];
      if (project) {
        return update(state, {
          projects: {
            entities: {
              [action.payload.projectId]: {
                checkedInBy: { $set: action.payload.user }
              }
            }
          }
        });
      }
      return state;
    }

    case DashboardActionsConstants.TEST_FLAG_TOGGLED: {
      const project = state.projects.entities[action.payload.id];
      if (!project) {
        return state;
      }

      return update(state, {
        projects: {
          entities: {
            [action.payload.id]: {
              test: { $set: action.payload.test }
            }
          }
        }
      });
    }

    case DashboardActionsConstants.PROJECT_CHECKED_OUT: {
      const project = state.projects.entities[action.payload.projectId];

      if (project) {
        return update(state, {
          projects: {
            entities: {
              [action.payload.projectId]: {
                checkedInBy: { $set: undefined }
              }
            }
          }
        });
      }
      return state;
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return emailProjectsTableSearchReducer(
        emailProjectsPaginationReducer(state, action),
        action
      ) as EmailProjectsState;
    }
  }
}
