import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { adminMailingListsOperations } from '../../../../../store/AdminMailingLists';
import { AdminMailingList, UpdateAdminMailingListValues } from '../../../../../store/AdminMailingLists/types';
import Toast from '../../../../Shared/Toast/Toast';
import { AdminTextInput } from '../../Shared/AdminFormInputs';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { FormikProps } from 'formik';
import app from '../../../../../config/app/app';

type UpdateAdminMailingListWindowProps = {
  open: boolean;
  adminMailingList: AdminMailingList | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const UpdateAdminMailingListWindow: FunctionComponent<UpdateAdminMailingListWindowProps> = ({
  adminMailingList,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!adminMailingList) return null;

  const onSubmit = async (values: UpdateAdminMailingListValues) => {
    try {
      await dispatch(
        adminMailingListsOperations.update(adminMailingList.id, {
          name: values.name
        })
      );
      Toast.success(t('notifications.adminMailingLists.successOperation'));

      await dispatch(adminMailingListsOperations.index());

      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.adminMailingLists.errorOperation'));
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="name" section="adminMailingLists" />
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      header={t('pages.adminMailingLists.windows.updateAdminMailingList')}
      okButtonText={t('common.update')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{ name: adminMailingList.name }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(app.maxInputLength)
          .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric characters are allowed for this field')
          .required()
      })}
    />
  );
};

export default UpdateAdminMailingListWindow;
