import AdminHouseAdsPage from './AdminHouseAdsPage';
import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';
import AdminHouseAdsToTemplateAssignmentPage from './AdminHouseAdsToTemplateAssignmentPage';

const AdminAdsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMINISTRATION.ADS.INDEX,
    exact: true,
    name: 'house_ads',
    public: false,
    show: true,
    component: AdminHouseAdsPage,
    scope: []
  },
  {
    path: linksConstants.ADMINISTRATION.ADS.ASSIGN_ADS(':emailTemplateId'),
    exact: false,
    name: 'emailTemplatesAssignAds',
    public: false,
    show: true,
    component: AdminHouseAdsToTemplateAssignmentPage,
    scope: []
  }
];

export default AdminAdsRoutes;
