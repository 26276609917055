import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Strong } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { useDispatch } from 'react-redux';
import { EmailProject } from '../../../../store/EmailProjects/types';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import { formatPublishDate } from '../../../../utils';

type ProjectCancelPublishWindowProps = {
  open: boolean;
  project: EmailProject | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  onSuccessfulSubmit?: () => void;
};

const ProjectCancelPublishWindow: FunctionComponent<ProjectCancelPublishWindowProps> = ({
  project,
  onCloseClick,
  open,
  onSuccessfulSubmit
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!project) return null;

  const onSubmit = async () => {
    try {
      await dispatch(emailProjectsOperations.cancelProjectPublication(project.id));

      Toast.success(t('notifications.projectPublishCancelled.success'));

      if (onSuccessfulSubmit) {
        onSuccessfulSubmit();
      }

      onCloseClick();
    } catch (e) {
      console.log('e', e);
      Toast.error(t('notifications.projectPublishCancelled.error'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('windows.projectPublishCancel.header')}
      okButtonText={t('common.cancelPublish')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>
        {t('windows.projectPublishCancel.areYouSure')} <Strong>{formatPublishDate(project.publishDate)}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default ProjectCancelPublishWindow;
