import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { AdminOrganization } from '../AdminOrganizations/types';
import { ShortUser } from '../Auth/types';
import { Color } from '../../typings';

export type MyOrganizationState = StateWithPagination &
  StateWithTableSearch<MyOrganizationUser> & {
    users: NormalizedMyOrganizationUsers;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedMyOrganizationUsers = {
  entities: Dictionary<MyOrganizationUser>;
  result: string[];
};

export type MyOrganizationUsersIndexResponse = {
  data: MyOrganizationUser[];
  pagination: Pagination;
};

export type MyOrganizationUsersIndexActionBEGIN = {
  type: MyOrganizationActionsConstants.INDEX_BEGIN;
};

export type MyOrganizationUsersIndexActionSUCCESS = {
  type: MyOrganizationActionsConstants.INDEX_SUCCESS;
  payload: MyOrganizationUsersIndexResponse;
};

export type IndexMyOrganizationUsersRequestParams = {
  per: number;
  page: number;
  organizationId: string;
  order?: string[];
  q: string | null;
};
export type CreateMyOrganizationUserValues = Pick<MyOrganizationUser, 'email' | 'firstName' | 'lastName' | 'isOwner'>;
export type UpdateMyOrganizationUserValues = Pick<MyOrganizationUser, 'roleId' | 'isOwner'>;

export type MyOrganizationUsersIndexActionFAILED = {
  type: MyOrganizationActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type MyOrganizationUser = {
  id: string;
  isOwner: boolean;
  email: string;
  firstName: string;
  lastName: string;
  uniqMyOrganizationUser: 'uniqMyOrganizationUser';
  createdAt: string;
  updatedAt: string;
  roleId: string;
  typeId: string;
};

export type MyOrganizationUsersResponse = {
  data: MyOrganizationUser[];
};

export enum MyOrganizationActionsConstants {
  INDEX_BEGIN = 'MY_ORGANIZATION/USERS/INDEX/BEGIN',
  INDEX_SUCCESS = 'MY_ORGANIZATION/USERS/INDEX/SUCCESS',
  INDEX_FAILED = 'MY_ORGANIZATION/USERS/INDEX/FAILED'
}

export type MyOrganizationActionType =
  | MyOrganizationUsersIndexActionBEGIN
  | MyOrganizationUsersIndexActionSUCCESS
  | MyOrganizationUsersIndexActionFAILED;

export type MyOrganizationSwatchGroup = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  selector: string;
  cssParam: string;
};

export type MyOrganizationSwatchGroupWithValue = MyOrganizationSwatchGroup & { value: string };

export type MyOrganizationSwatchGroupResponse = {
  data: MyOrganizationSwatchGroup[];
};

export type MyOrganizationSwatchColorResponse = {
  data: MyOrganizationSwatchColor[];
  colorSwatchLimit: number;
};

export type MyOrganizationSwatchColor = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  hex: string;
  red: number;
  green: number;
  blue: number;
  cyan: number;
  magenta: number;
  yellow: number;
  black: number;
  opacity: number;
  organizationId: string;
};

export type MyOrganizationSwatchColorSmall = Color;

export enum ColorSwatchType {
  paragraph = 'paragraph',
  divider2 = 'divider2',
  divider1 = 'divider1',
  accent2 = 'accent2',
  accent1 = 'accent1',
  button = 'button',
  link = 'link',
  headerFooterBackground = 'headerFooterBackground',
  headerFooterText = 'headerFooterText',
  heading = 'heading'
}

export type CreateMyOrganizationSwatchColorValues = Pick<
  MyOrganizationSwatchColor,
  'name' | 'hex' | 'red' | 'green' | 'blue' | 'cyan' | 'magenta' | 'yellow' | 'black' | 'opacity'
>;

export type WordpressCategory = {
  id: number;
  count: number;
  description: string;
  link: string;
  name: string;
  slug: string;
  taxonomy: string;
  parent: number;
};

export type WordpressIntegration = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  url: string;
  client?: string;
  secret?: string;
  organizationId: string;
  categories: WordpressCategory[];
};

export type WordpressPost = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  postId: string;
  channelId: string;
  createdBy: ShortUser | null;
  integration: WordpressIntegration;
};

export type WordpressIntegrationForm = {
  name: string;
  client: string;
  secret: string;
  url: string;
};

export type AyrshareSocialAccount = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  refId: string;
  facebookPagesConnected: boolean;
  instagramConnected: boolean;
  twitterConnected: boolean;
  linkedinConnected: boolean;
  organizationId: string;
};

export type OrganizationMonthCalendar = {
  monthName: string;
  date: number;
  year: number;
  days: OrganizationMonthCalendarDay[];
  events: { [key: string]: MonthCalendarEvent };
  style?: MonthCalendarStyle & { id: string; createdAt: string; updatedAt: string; style: MonthCalendarStyles };
};

export type MonthCalendarEvent = {
  id: string;
  name: string;
  description: string;
  url: string | null;
  thumbnailUrl: string | null;
  startDay: number;
  endDay: number;
  startMonth: string;
  endMonth: string;
} | null;

export type MonthCalendarEventPointer = {
  id: number;
  s: boolean;
  e: boolean;
  row: number;
};

export type OrganizationMonthCalendarDay = {
  dayNumber: string;
  otherMonth: boolean;
  events?: MonthCalendarEventPointer[];
};

export enum MonthCalendarStyles {
  MAIN = 'main',
  LIGHT = 'light',
  DARK = 'dark'
}

export const monthCalendarStyles = [MonthCalendarStyles.MAIN, MonthCalendarStyles.LIGHT, MonthCalendarStyles.DARK];

export type MonthCalendarStyle = {
  eventBackgroundColor: string;
  eventTextColor: string;
  eventBorderRadius: number;
  headerBackgroundColor: string;
  headerTextColor: string;
  borderColor: string;
  backgroundColor: string;
  backgroundPrevNextColor: string;
  dayBackgroundColor: string;
  dayTextColor: string;
  dayBorderRadius: number;
  dayPosition: 'l' | 'r';
};

export type OrganizationTreeStructure = {
  org: AdminOrganization;
  children: OrganizationTreeStructure[];
};

export type OrganizationCalendarStyleValues = MonthCalendarStyle & { style: MonthCalendarStyles };
