import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { AdminUpdateRoleValues, IndexAdminRolesRequestParams, RolePermissions } from './types';

export const index = (settings: RequestSettings<IndexAdminRolesRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/roles?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const addKeywordToRole = (
  settings: RequestSettings<{ roleId: string }, { keywordId: string }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/roles/:roleId/keywords'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeKeywordFromRole = (
  settings: RequestSettings<{ roleId: string }, { keywordId: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/roles/:roleId/keywords'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const create = (
  settings: RequestSettings<{}, AdminUpdateRoleValues & { permissions: RolePermissions }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/roles'
  },
  payload: settings.requestPayload
});

export const update = (
  settings: RequestSettings<{ id: string }, AdminUpdateRoleValues & { permissions: RolePermissions }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/roles/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteRole = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/roles/:id'
  },
  params: settings.requestParams
});

export const show = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/roles/:id'
  },
  params: settings.requestParams
});

export const getRolesOfUserType = (settings: RequestSettings<{ userTypeId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/roles/all_of_type/:userTypeId'
  },
  params: settings.requestParams
});
