import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Strong } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../utils';
import { dashboardActions } from '../../../../store/Dashboard';
import { AnyProject } from '../../../../store/Dashboard/types';

type ForcingCheckOutNotificationWindowProps = {
  activeProject: AnyProject | null;
};

const ForcingCheckOutNotificationWindow: FunctionComponent<ForcingCheckOutNotificationWindowProps> = ({
  activeProject
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const notificationToCheckOutWindows = useTypedSelector((state) => state.dashboard.notificationToCheckOutWindows);

  if (!activeProject) return null;

  const notificationToCheckOutWindow = notificationToCheckOutWindows.find(
    (notification) => notification.project && notification.project.id === activeProject.id
  );

  if (!notificationToCheckOutWindow || !notificationToCheckOutWindow.project) {
    return null;
  }

  const onClose = async () => {
    try {
      if (activeProject) {
        dispatch(dashboardActions.notifyToCheckOutClose(activeProject));
      }
    } catch (e) {
      Toast.error(t('notifications.resetCheckIn.error'));
    }
  };

  const { project, notifier, open } = notificationToCheckOutWindow;

  const opened = open && project.id === activeProject.id;

  return (
    <ModalConfirmationWindow
      open={opened}
      header={t('windows.forceCheckOut.header')}
      okButtonText={t('common.ok')}
      disableCloseButton={true}
      disableBackdropClick={true}
      onCloseClick={onClose}
      onSubmit={onClose}
    >
      {<Row>{t('windows.forceCheckOut.forcingCheckOut')}</Row>}
      <Row>
        <div>
          {t('windows.forceCheckOut.projectName')}: <Strong>{project.name}</Strong>
        </div>
        <div>
          {t('windows.forceCheckOut.notifier')}:{' '}
          <Strong>{notifier ? `${notifier.fullName} (${notifier.email})` : '---'}</Strong>
        </div>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default ForcingCheckOutNotificationWindow;
