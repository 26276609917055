import update from 'immutability-helper';
import {
  AdminKeyword,
  AdminKeywordGroup,
  AdminKeywordGroupsActionType,
  AdminKeywordGroupsState,
  AdminKeywordsActionsConstants,
  AdminKeywordsActionType,
  AdminKeywordsState
} from './types';
import { normalizeKeywordGroups, normalizeKeywords } from '../helpers';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: AdminKeywordsState = {
  keywords: {
    entities: {},
    result: []
  },
  all: {
    keywords: [],
    groups: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const groupsInitialState: AdminKeywordGroupsState = {
  groups: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const adminKeywordsPaginationReducer = createPaginationReducer<AdminKeywordsState, AdminKeyword>(
  PaginationStoreModule.ADMIN_KEYWORDS
);

const adminKeywordsTableSearchReducer = createTableSearchReducer<AdminKeyword, AdminKeywordsState>(
  TableSearchStoreModule.ADMIN_KEYWORDS
);

const adminKeywordGroupsPaginationReducer = createPaginationReducer<AdminKeywordGroupsState, AdminKeywordGroup>(
  // @ts-ignore
  PaginationStoreModule.ADMIN_KEYWORD_GROUPS
);

const adminKeywordGroupsTableSearchReducer = createTableSearchReducer<AdminKeywordGroup, AdminKeywordGroupsState>(
  // @ts-ignore
  TableSearchStoreModule.ADMIN_KEYWORD_GROUPS
);

export function adminKeywordsReducer(
  state = initialState,
  action: WithLogoutAction<AdminKeywordsActionType>
): AdminKeywordsState {
  switch (action.type) {
    case AdminKeywordsActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case AdminKeywordsActionsConstants.INDEX_SUCCESS: {
      const data = normalizeKeywords(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        keywords: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case AdminKeywordsActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        keywords: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AdminKeywordsActionsConstants.GET_ALL_KEYWORDS: {
      return update(state, {
        all: {
          keywords: { $set: action.payload.keywords },
          groups: { $set: action.payload.groups }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return adminKeywordsTableSearchReducer(
        adminKeywordsPaginationReducer(state, action),
        action
      ) as AdminKeywordsState;
    }
  }
}

export function adminKeywordGroupsReducer(
  state = groupsInitialState,
  action: WithLogoutAction<AdminKeywordGroupsActionType>
): AdminKeywordGroupsState {
  switch (action.type) {
    case AdminKeywordsActionsConstants.INDEX_GROUPS_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case AdminKeywordsActionsConstants.INDEX_GROUPS_SUCCESS: {
      const data = normalizeKeywordGroups(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        groups: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case AdminKeywordsActionsConstants.INDEX_GROUPS_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        groups: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AdminKeywordsActionsConstants.KEYWORD_REMOVED:
    case AdminKeywordsActionsConstants.KEYWORD_ADDED: {
      return update(state, {
        groups: {
          entities: {
            [action.payload.id]: {
              keywords: { $set: action.payload.keywords }
            }
          }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: groupsInitialState
      });

    default: {
      return adminKeywordGroupsTableSearchReducer(
        adminKeywordGroupsPaginationReducer(state, action),
        action
      ) as AdminKeywordGroupsState;
    }
  }
}
