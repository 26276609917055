export const set = (): void => {
  sessionStorage.setItem('sc_la', new Date().getTime().toString());
};

export const get = (): number => {
  return parseInt(sessionStorage.getItem('sc_la') || '') || new Date().getTime();
};

export const getSecondsThatLeft = (limitInMinutes: number) => {
  return Math.floor((get() + limitInMinutes * 1000 * 60 - new Date().getTime()) / 1000);
};

export const getTimeToShowModal = (limitInMinutes: number) => {
  const oneMinute = 1;
  const oneMinuteInSeconds = 60;

  return limitInMinutes > oneMinute ? oneMinuteInSeconds : Math.floor(limitInMinutes * 60 * 0.2);
};
