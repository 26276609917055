import { imagesOperations } from '../../../../../../store/Images';
import { ImageData } from '../../types';
import { ImageProcessingResponse } from '../../../../../../store/Images/types';
import { StoryChannel } from '../../../../../../store/Stories/types';

export const storyArticle = async (
  projectId: string,
  imageData: ImageData,
  story: StoryChannel
): Promise<ImageProcessingResponse | null> => {
  if (story.attachments.length) {
    // upload image using story URL
    return await imagesOperations.uploadStoryImage(
      projectId,
      story.attachments[0].url,
      imageData.containerWidth,
      imageData.containerHeight
    );
  }

  return null;
};
