import { BaseSettings } from '../../utils/httpRequest';

export const getVersions = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/utilities/versions'
  }
});

export const getMainStorageUrl = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/utilities/mainStorageUrl'
  }
});
