import { BaseSettings, RequestSettings } from '../../../utils/httpRequest';
import {
  CreatePromotionClaimRequestBodyDto,
  IndexDoAppPromotionClaimsMineRequestParams,
  IndexDoAppPromotionClaimsRequestParams
} from './types';

export const index = (settings: RequestSettings<IndexDoAppPromotionClaimsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/promotions/claims?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const indexMine = (settings: RequestSettings<IndexDoAppPromotionClaimsMineRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/promotions/claims/mine/?per=:per&page=:page&status=:status${
      settings.requestParams?.promotionId ? '&promotionId=:promotionId' : ''
    }`
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{}, CreatePromotionClaimRequestBodyDto>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/do-app/v1/promotions/claims'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const reject = (settings: RequestSettings<{ id: string }, { comment: string }>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/do-app/v1/promotions/claims/:id/reject'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const unReject = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/do-app/v1/promotions/claims/:id/un-reject'
  },
  params: settings.requestParams
});
