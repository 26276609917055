import React, { FunctionComponent, useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import Loader from '../../../../../Shared/Loading/Loader';
import { EmailProjectAttachment } from '../../../../../../store/EmailProjects/types';
import { emailProjectsOperations } from '../../../../../../store/EmailProjects';
import Toast from '../../../../../Shared/Toast/Toast';
import { Alert } from '@material-ui/lab';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import ColorPicker from '../../../../../Shared/ColorPicker/ColorPicker';

type DownloadAttachmentProps = {
  open: boolean;
  onCloseClick: () => void;
  onFormSubmit: (values: {
    downloadAll: boolean;
    attachmentId: string | null;
    backgroundColor: string;
    borderRadius: string;
    borderStyle: string;
    borderWidth: string;
    borderColor: string;
  }) => void;
  projectId: string;
  downloadAllSwitch: boolean;
  selectedAttachmentId: string | null;
  backgroundColor: string;
  borderRadius: string;
  borderStyle: string;
  borderWidth: string;
  borderColor: string;
};

const DownloadAttachmentWindow: FunctionComponent<DownloadAttachmentProps> = ({
  open,
  onCloseClick,
  onFormSubmit,
  projectId,
  downloadAllSwitch,
  selectedAttachmentId,
  backgroundColor,
  borderRadius,
  borderStyle,
  borderWidth,
  borderColor
}) => {
  const { t } = useTranslation();
  const [downloadAll, setDownloadAll] = useState<boolean>(downloadAllSwitch);
  const [attachmentId, setAttachmentId] = useState<string | null>(selectedAttachmentId);
  const [attachments, setAttachments] = useState<EmailProjectAttachment[] | null>(null);
  const [fetchingAttachments, setFetchingAttachments] = useState<boolean>(false);

  const [buttonBackgroundColor, setButtonBackgroundColor] = useState<string>(backgroundColor);
  const [buttonBorderColor, setButtonBorderColor] = useState<string>(borderColor);
  const [buttonBorderRadius, setButtonBorderRadius] = useState<string>(
    borderRadius.includes('px') ? borderRadius.slice(0, -2).trim() : '0'
  );
  const [buttonBorderWidth, setButtonBorderWidth] = useState<string>(
    borderWidth.includes('px') ? borderWidth.slice(0, -2).trim() : '0'
  );

  const handleAttachmentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAttachmentId(event.target.value as string);
  };

  const fetchAttachments = async () => {
    setFetchingAttachments(true);
    try {
      const attachmentsResponse = await emailProjectsOperations.getAttachments(projectId);
      setAttachments(attachmentsResponse.data);
      if (!attachmentId && attachmentsResponse.data.length > 0) {
        setAttachmentId(attachmentsResponse.data[0].id);
      }
    } catch (error) {
      Toast.error(t('notifications.getEmailProjectAttachments.error'));
    } finally {
      setFetchingAttachments(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchAttachments();
    }
  }, [open]);

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.emailEditor.downloadAttachment.header')}
      onCloseClick={onCloseClick}
      onSubmit={() =>
        onFormSubmit({
          downloadAll,
          attachmentId,
          backgroundColor: buttonBackgroundColor,
          borderRadius: buttonBorderRadius + 'px',
          borderStyle: 'solid',
          borderWidth: buttonBorderWidth + 'px',
          borderColor: buttonBorderColor
        })
      }
    >
      {attachments && attachments.length === 0 && (
        <Alert severity="warning">{t('pages.emailEditor.downloadAttachment.noAttachments')}</Alert>
      )}
      {fetchingAttachments && (
        <div>
          <Divider />
          <Loader />
        </div>
      )}
      {!fetchingAttachments && attachments && attachments.length > 0 && (
        <div onClick={() => setDownloadAll(!downloadAll)}>
          {t('pages.emailEditor.downloadAttachment.downloadAllSwitch')}:{' '}
          <Switch checked={downloadAll} onChange={(e) => setDownloadAll(e.target.checked)} />
        </div>
      )}
      {!fetchingAttachments && !downloadAll && (
        <div>
          {attachments && attachments.length > 0 && (
            <>
              <Divider />
              <FormControl component="fieldset" style={{ marginTop: 10 }}>
                <FormLabel
                  component="legend"
                  style={{
                    margin: '10px 0 5px',
                    fontWeight: 'bold'
                  }}
                >
                  {t('pages.emailEditor.downloadAttachment.selectAttachment')}
                </FormLabel>
                <RadioGroup value={attachmentId} onChange={handleAttachmentChange}>
                  {attachments.map((attachment) => (
                    <FormControlLabel
                      key={attachment.id}
                      value={attachment.id}
                      control={<Radio size="small" style={{ padding: '2px 9px' }} />}
                      label={attachment.filename}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </>
          )}
        </div>
      )}
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.ctaButton.general')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.ctaButton.backgroundColor')}:</div>
            <ColorPicker color={buttonBackgroundColor} onChange={setButtonBackgroundColor} />
          </Grid>
        </Grid>
      </fieldset>
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.ctaButton.border')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.ctaButton.borderRadius')}:</div>
            <TextField
              id="button-border-radius"
              type="number"
              value={buttonBorderRadius}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const radius = Math.abs(Number(event.target.value)) || '';
                setButtonBorderRadius((radius as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setButtonBorderRadius('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.ctaButton.borderWidth')}:</div>
            <TextField
              id="button-border-width"
              type="number"
              value={buttonBorderWidth}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const width = Math.abs(Number(event.target.value)) || '';
                setButtonBorderWidth((width as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setButtonBorderWidth('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.ctaButton.borderColor')}:</div>
            <ColorPicker color={buttonBorderColor} onChange={setButtonBorderColor} />
          </Grid>
        </Grid>
      </fieldset>
    </ModalConfirmationWindow>
  );
};

export default DownloadAttachmentWindow;
