import React, { FunctionComponent } from 'react';
import {
  EmailProjectRow,
  ProjectModalColumnSize,
  ProjectModelColumn,
  ProjectModelComponent,
  ProjectModelV2
} from '../../../Editors/EmailDndEditor/types';
import { Grid, withTheme } from '@material-ui/core';
import styled from 'styled-components';
import textPattern from '../../../../../assets/text-pattern.png';

type AdsPreviewInTemplateProps = {
  model: ProjectModelV2;
};

const AdsPreviewInTemplate: FunctionComponent<AdsPreviewInTemplateProps> = ({ model }) => {
  const renderComponent = (
    component: ProjectModelComponent,
    role: 'header' | 'footer' | undefined,
    size: ProjectModalColumnSize,
    key: string
  ) => {
    if (role && component.type !== 'separator') {
      return <TemplateHeaderAndFooter key={key} />;
    }
    switch (component.type) {
      case 'article-horizontal':
      case 'article-vertical':
        return (
          <TemplateArticle key={key}>
            <TemplateArticleSpacer />
          </TemplateArticle>
        );
      case 'ad-space':
        return (
          <TemplateAdSpace
            style={{ height: `${(component.params.height * (270 / (12 / size))) / component.params.width}px` }}
          >
            {component.params.width}x{component.params.height}
          </TemplateAdSpace>
        );
      case 'cta-button':
        return <TemplateCtaButton key={key} />;
      default:
        return null;
    }
  };

  const renderRows = (row: EmailProjectRow, rowIterator: number) => {
    return row.cols.map((col, colIterator) => renderColumns(col, rowIterator, colIterator));
  };

  const renderColumns = (column: ProjectModelColumn, rowIterator: number, colIterator: number) => {
    return (
      <Grid key={`col-${rowIterator}-${colIterator}`} item xs={column.size} style={{ display: 'flex' }}>
        {column.components.map((component) =>
          renderComponent(component, column.role, column.size, `comp-${rowIterator}-${colIterator}`)
        )}
      </Grid>
    );
  };

  return <TemplateContainer container>{model.sizes.large.map(renderRows)}</TemplateContainer>;
};

const TemplateContainer = withTheme(styled(Grid)`
  background-color: #fff;
  padding: 10px 10px 0 10px;
  width: 300px;
`);
const TemplateHeaderAndFooter = styled.div`
  background-color: #f9f9f9;
  height: 50px;
  width: 100%;
  margin-bottom: 10px;
`;
const TemplateArticle = styled.div`
  width: 100%;
  min-height: 40px;
  height: 100%;
  margin: 5px;
  overflow: hidden;
  background: url(${textPattern});
  position: relative;
`;
const TemplateArticleSpacer = styled.div`
  height: 5px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
`;
const TemplateCtaButton = styled.div`
  background-color: #f9f9f9;
  height: 30px;
  width: 100%;
  margin: 5px 5px 10px 5px;
`;
const TemplateAdSpace = styled.div`
  background-color: #00a5aa;
  width: 100%;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;
`;

export default AdsPreviewInTemplate;
