import React, { FunctionComponent } from 'react';
import { useTypedSelector } from '../../../../../utils';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { isSystemOwner } from '../../../../../utils/permissions';
import { PrintCenter } from '../../../../../store/SystemSettings/types';
import { isTheme } from '../../../../../utils/environment';
import { APP_THEME } from '../../../../../typings/environment';

type PrintPublishWindowLpiExpressDataCentersProps = {
  printCenters: PrintCenter[];
  activePrintCenter: PrintCenter | null;
  setActivePrintCenter: (center: PrintCenter) => void;
};

const PrintPublishWindowLpiExpressDataCenters: FunctionComponent<PrintPublishWindowLpiExpressDataCentersProps> = ({
  activePrintCenter,
  printCenters,
  setActivePrintCenter
}) => {
  const authState = useTypedSelector((state) => state.auth);

  if (!activePrintCenter) return null;

  return (
    <>
      {isSystemOwner(authState) && isTheme(APP_THEME.LPI) && (
        <RadioGroup
          row
          value={activePrintCenter.id}
          onChange={(e) => {
            const printCenter = printCenters.find((center) => center.id === e.target.value);
            if (printCenter) {
              setActivePrintCenter(printCenter);
            }
          }}
          style={{
            display: 'inline-block',
            marginLeft: 20
          }}
        >
          {printCenters.map((center) => (
            <FormControlLabel
              key={center.id}
              value={center.id}
              control={<Radio />}
              label={
                <div style={{ lineHeight: 1 }}>
                  <div>{center.name}</div>
                  <em style={{ fontSize: 12 }}>{center.url}</em>
                </div>
              }
            />
          ))}
        </RadioGroup>
      )}
    </>
  );
};

export default PrintPublishWindowLpiExpressDataCenters;
