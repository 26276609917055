import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { IndexDashboardProjectsRequestParams } from './types';

export const getUserProjectsInProgress = (
  settings: RequestSettings<IndexDashboardProjectsRequestParams, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/dashboard/projects_in_progress?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const getProjectById = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/dashboard/project/:projectId`
  },
  params: settings.requestParams
});

export const notifyUserToCheckOut = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/dashboard/project/:projectId/notify_to_check_out`
  },
  params: settings.requestParams
});

export const forceCheckOut = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/dashboard/project/:projectId/force_check_out`
  },
  params: settings.requestParams
});

export const toggleTestFlag = (settings: RequestSettings<{ projectId: string }, { test: boolean }>): BaseSettings => ({
  query: {
    method: 'put',
    url: `/api/v1/dashboard/project/:projectId/toggle_test_flag`
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});
