import React, { FunctionComponent } from 'react';
import { PageContentPaper } from '../../PageStyledComponents';
import { formatTableTimestamp } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import linksConstants from '../../../../config/app/linksConstants';
import { Story, StoryChannelType } from '../../../../store/Stories/types';
import { Strong } from '../../../Shared/StyledComponents';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TableLink } from '../../../Shared/DataTable/ColumnCell/UrlColumnCell';
import { getStoryAttachments } from '../Utils/storyUtils';
import { AttachmentThumbnail } from '../Partials/Attachments/AttachmentThumbnail';
import ChannelChip from '../Partials/ChannelChip';

type StoryPreviewProps = {
  story: Story;
};

const StoryPreview: FunctionComponent<StoryPreviewProps> = ({ story }) => {
  const { t } = useTranslation();
  const channels = story.channels.filter((cd) => cd.type !== StoryChannelType.CALENDAR_EVENT);
  const attachments = getStoryAttachments(channels);
  const first3 = attachments.slice(0, 3);

  return (
    <PageContentPaper>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={4}>
          <StoryLink
            to={{
              pathname: linksConstants.STORY.EDIT(story.id),
              state: { from: linksConstants.STORY.CONTENT_CONTRIBUTORS(story.id) }
            }}
          >
            <TableLink>{story.name}</TableLink>
          </StoryLink>
        </Grid>
        <Grid item xs={3}>
          <Strong>{t('pages.story.table.attachments')}</Strong>
          <br />
          {!attachments.length ? (
            <div>{t('pages.story.table.noAttachments')}</div>
          ) : (
            <>
              {first3.map((att) => {
                const storyChannel = channels.find((channel) => channel.id === att.channelId);
                return (
                  <AttachmentThumbnail
                    key={`att-list-${att.id}`}
                    attachment={att}
                    channelType={storyChannel && storyChannel.type}
                  />
                );
              })}
              {attachments.length > 3 && (
                <>
                  <br />
                  {t('pages.story.table.andMoreAttachments', { more: attachments.length - 3 })}
                </>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={3}>
          <Strong>{t('pages.story.table.channels')}</Strong>
          <br />
          {channels.length > 0 ? (
            <div>
              {channels.map((channel) => (
                <ChannelChip key={channel.id} channel={channel} />
              ))}
            </div>
          ) : (
            <div>{t('pages.story.table.noChannels')}</div>
          )}
        </Grid>
        <Grid item xs={2}>
          <Strong>{t('pages.story.table.publicationWeek')}</Strong>
          <br />
          <div>
            {formatTableTimestamp(story.publicationDateFrom)} - {formatTableTimestamp(story.publicationDateTo)}
          </div>
        </Grid>
      </Grid>
    </PageContentPaper>
  );
};

export const StoryLink = styled(Link)`
  text-decoration: none !important;
  cursor: pointer;
`;

export default StoryPreview;
