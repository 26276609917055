import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { IndexSharedProjectsRequestParams } from './types';

export const getProjectsSharedWithMe = (
  settings: RequestSettings<IndexSharedProjectsRequestParams, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/dashboard/projects_shared_with_me?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const getProjectsIShared = (settings: RequestSettings<IndexSharedProjectsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/dashboard/projects_i_shared?per=:per&page=:page`
  },
  params: settings.requestParams
});
