import React, { FunctionComponent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Toast from '../../../Shared/Toast/Toast';
import app from '../../../../config/app/app';
import { MenuItem } from '@material-ui/core';
import { AdminRole, DefaultRoleName } from '../../../../store/AdminRoles/types';
import { adminRolesOperations } from '../../../../store/AdminRoles';
import { adminUsersOperations } from '../../../../store/AdminUsers';
import { AdminCheckboxInput, AdminSelectInput, AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import { CreateMyOrganizationUserValues } from '../../../../store/MyOrganization/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import { FormikProps } from 'formik';
import { AdminCreateOrganizationUserValues } from '../../../../store/AdminOrganizationUsers/types';
import { getRoleNameElement } from '../../../../utils';

type InviteOrganizationUserWindowProps = {
  open: boolean;
  organizationId: string;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const InviteOrganizationUserWindow: FunctionComponent<InviteOrganizationUserWindowProps> = ({
  onCloseClick,
  organizationId,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [roles, setRoles] = useState<AdminRole[]>([]);

  const onSubmit = async (values: CreateMyOrganizationUserValues) => {
    try {
      await dispatch(myOrganizationOperations.create(organizationId, values));
      await dispatch(myOrganizationOperations.index(organizationId));
      onCloseClick();
      Toast.success(t('notifications.myOrganization.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.myOrganization.errorOperation'));
    }
  };

  useEffect(() => {
    if (open) {
      fetchRolesData('contact');
    }
  }, [open]);

  const fetchRolesData = async (type: 'owner' | 'contact') => {
    const typeToSearch = type === 'contact' ? DefaultRoleName.ORGANIZATION_CONTACT : DefaultRoleName.ORGANIZATION_OWNER;
    const userTypes = await adminUsersOperations.getUserTypes();
    const contactUserType = userTypes.data.find((type) => type.name === typeToSearch);
    if (contactUserType) {
      const rolesData = await adminRolesOperations.getRolesOfUserType(contactUserType.id);

      setRoles(rolesData.data);
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="email" section="myOrganization" />
      <AdminCheckboxInput
        t={t}
        name="isOwner"
        onChange={(e) => {
          props.setFieldValue('roleId', '');
          e.target.checked ? fetchRolesData('owner') : fetchRolesData('contact');
          return true;
        }}
        section="myOrganization"
      />
      <AdminSelectInput t={t} name="roleId" section="myOrganization">
        {roles.map((role) => (
          <MenuItem key={role.id} value={role.id}>
            {getRoleNameElement(t, role)}
          </MenuItem>
        ))}
      </AdminSelectInput>
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.create')}
      header={t('pages.myOrganization.windows.createOrganizationUser')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{ email: '', roleId: '', isOwner: false }}
      validationSchema={Yup.object().shape({
        email: Yup.string().max(app.maxInputLength).email().required(),
        isOwner: Yup.boolean().required(),
        roleId: Yup.string().required()
      })}
    />
  );
};

export default InviteOrganizationUserWindow;
