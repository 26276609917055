import React from 'react';
import { Route } from 'react-router';

interface PublicLayoutRouteProps {
  exact: boolean;
  path: string;
  component: any;
  name: string;
}

const PublicLayoutRoute = ({ component: Component, ...props }: PublicLayoutRouteProps) => {
  return (
    <Route {...props}>
      <Component />
    </Route>
  );
};

export default PublicLayoutRoute;
