import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Box, Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { createNetworkErrorObject, useTypedSelector } from '../../../../../utils';
import { isOrganizationOwner } from '../../../../../utils/permissions';
import { myOrganizationOperations } from '../../../../../store/MyOrganization';
import { useDispatch } from 'react-redux';
import { authOperations } from '../../../../../store/Auth';
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';
import { ReactComponent as FacebookIcon } from '../../../../../assets/icons/channels/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../../../../assets/icons/channels/x.svg';
import { ReactComponent as InstagramIcon } from '../../../../../assets/icons/channels/instagram-circle.svg';
import { ReactComponent as LinkedinIcon } from '../../../../../assets/icons/channels/linkedin-circle.svg';
import { createStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import Toast from '../../../../Shared/Toast/Toast';
import { StoryChannelType } from '../../../../../store/Stories/types';

type SocialAccountsProps = {};

const SocialAccounts: FunctionComponent<SocialAccountsProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authState = useTypedSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [ayrshareUrl, setAyrshareUrl] = useState<string | null>(null);

  const socialAccount = authState.socialAccounts[0];

  useEffect(() => {
    dispatch(authOperations.getOrganizationSocialAccountsData());
  }, []);

  const facebookPagesConnected = socialAccount && socialAccount.facebookPagesConnected;
  const twitterConnected = socialAccount && socialAccount.twitterConnected;
  const instagramConnected = socialAccount && socialAccount.instagramConnected;
  const linkedinConnected = socialAccount && socialAccount.linkedinConnected;

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginY={2}>
        <Box>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            {t('pages.myOrganization.integrations.social.title')}
          </Typography>
        </Box>
        <Box>
          <Button
            disabled={loading || !isOrganizationOwner(authState)}
            color={ayrshareUrl ? 'primary' : 'secondary'}
            variant="contained"
            onClick={async () => {
              if (ayrshareUrl) {
                window.open(ayrshareUrl);
                setAyrshareUrl(null);
              } else {
                if (authState.organizationId) {
                  setLoading(true);
                  try {
                    const connectorUrl = await myOrganizationOperations.getSocialAccountsConnectorPage(
                      authState.organizationId
                    );
                    setAyrshareUrl(connectorUrl.url);
                  } catch (e) {
                    const error = createNetworkErrorObject(e);
                    switch (error.message) {
                      case 'max_number_of_profiles_reached':
                        Toast.error(t('notifications.story.maxNumberOfProfiles'));
                        break;
                      case 'profile_not_found':
                        Toast.error(t('notifications.story.socialProfileNotFound'));
                        break;
                      default:
                        Toast.error(t('notifications.story.connectorPageError'));
                        break;
                    }
                  } finally {
                    setLoading(false);
                  }
                }
              }
            }}
            size="small"
            startIcon={<SyncAltOutlinedIcon />}
          >
            {ayrshareUrl
              ? t('pages.myOrganization.integrations.social.connectAccountsOpen')
              : t('pages.myOrganization.integrations.social.connectAccounts')}
          </Button>
        </Box>
      </Box>
      <Box>
        <SocialBadge connected={facebookPagesConnected} type={StoryChannelType.FACEBOOK} Icon={FacebookIcon} />
        <SocialBadge connected={twitterConnected} type={StoryChannelType.TWITTER} Icon={TwitterIcon} />
        <SocialBadge connected={instagramConnected} type={StoryChannelType.INSTAGRAM} Icon={InstagramIcon} />
        <SocialBadge connected={linkedinConnected} type={StoryChannelType.LINKEDIN} Icon={LinkedinIcon} />
      </Box>
    </>
  );
};

const SocialBadge: FunctionComponent<{
  connected: boolean;
  type: StoryChannelType;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}> = ({ connected, type, Icon }) => {
  const { t } = useTranslation();
  const iconStyles: React.CSSProperties = { marginRight: 8 };

  const getSocialColor = (type: StoryChannelType) => {
    const theme = useTheme();

    switch (type) {
      case StoryChannelType.FACEBOOK:
        return '#1877F2';
      case StoryChannelType.TWITTER:
        return '#14171a';
      case StoryChannelType.INSTAGRAM:
        return '#E1306C';
      case StoryChannelType.LINKEDIN:
        return '#0A66C2';

      default:
        return theme.palette.primary.main;
    }
  };

  return (
    <StyledBadge
      color="primary"
      badgeContent={t('pages.myOrganization.integrations.social.linked')}
      invisible={!connected}
    >
      <Tooltip title={`${t(`pages.story.channels.${type}`)}`} placement="top">
        <Icon
          width={50}
          height={50}
          style={iconStyles}
          opacity={connected ? 1 : 0.1}
          fill={connected ? getSocialColor(type) : '#000000'}
        />
      </Tooltip>
    </StyledBadge>
  );
};

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: 32,
      top: 57,
      backgroundColor: theme.palette.success.main,
      border: '2px solid #fff',
      height: 22,
      padding: '0px 6px'
    }
  })
)(Badge);

export default SocialAccounts;
