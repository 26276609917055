import { createOrderArray, httpRequest } from '../../utils';
import { AdminMailingListsIndexResponse, CreateAdminMailingListValues, UpdateAdminMailingListValues } from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { adminMailingListsActions, adminMailingListsRequests } from './index';
import { MailingList } from '../MailingLists/types';

export const index = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { adminMailingLists } = getState();

  try {
    dispatch(adminMailingListsActions.adminMailingListsIndexBegin());

    const response = await httpRequest.runRequest<AdminMailingListsIndexResponse>(
      adminMailingListsRequests.index({
        requestParams: {
          page: adminMailingLists.pagination.page,
          per: adminMailingLists.pagination.per,
          q: adminMailingLists.pagination.q,
          order: createOrderArray(adminMailingLists.tableSearch.order, ['order=name,asc'])
        }
      })
    );

    dispatch(adminMailingListsActions.adminMailingListsIndexSuccess(response));
  } catch (e) {
    dispatch(adminMailingListsActions.adminMailingListsIndexFailed(e));
  }
};

export const create = (values: CreateAdminMailingListValues) => async () => {
  await httpRequest.runRequest<void>(
    adminMailingListsRequests.create({
      requestPayload: {
        name: values.name,
        organizationId: values.organization.id
      }
    })
  );
};

export const update = (id: string, values: UpdateAdminMailingListValues) => async () => {
  await httpRequest.runRequest<void>(
    adminMailingListsRequests.update({
      requestPayload: values,
      requestParams: { id }
    })
  );
};

export const deleteAdminMailingList = (id: string) => async () => {
  await httpRequest.runRequest<void>(
    adminMailingListsRequests.deleteAdminMailingList({
      requestParams: { id }
    })
  );
};

export const getOrganizationMailingLists = async (organizationId: string) => {
  return httpRequest.runRequest<{ data: MailingList[] }>(
    adminMailingListsRequests.getOrganizationMailingLists({
      requestParams: {
        organizationId
      }
    })
  );
};
