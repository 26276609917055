import React, { useState } from 'react';
import { TextField, Button, Collapse, IconButton, InputAdornment } from '@material-ui/core';
import { doAppCreditTransactionsOperations } from '../../../../../store/DoApp/CreditTransactions';
import { WidgetTitle } from '../../../../Shared/ListingWidget/WidgetTitle';
import { Alert, Color } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import Toast from '../../../../Shared/Toast/Toast';

type DoAppCreateCreditTransactionFormProps = {
  onTransactionCreated: () => void;
};

const DoAppCreateCreditTransactionForm: React.FC<DoAppCreateCreditTransactionFormProps> = ({
  onTransactionCreated
}) => {
  const { t } = useTranslation();
  const [merchantAccountNumber, setMerchantAccountNumber] = useState<string>('');
  const [subscriberEmail, setSubscriberEmail] = useState<string>('');
  const [deviceId, setDeviceId] = useState<string>('');
  const [amount, setAmount] = useState<string>('0');
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<Color>('success');
  const [open, setOpen] = useState(false);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Allow only numbers and one dot
    const validValue = inputValue.match(/^\d*\.?\d*$/); // Regex allows numbers and a single dot
    if (validValue) {
      setAmount(inputValue);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setOpen(false);
    setMessage(null);
    const parsedAmount = parseFloat(amount);

    if (isNaN(parsedAmount) || parsedAmount < 0) {
      Toast.error(t('pages.doApp.subscribers.invalidCredits'));
      return;
    }
    try {
      await doAppCreditTransactionsOperations.createTransaction({
        merchantAccountNumber,
        subscriberEmail,
        amount: Math.round(parsedAmount * 100),
        deviceId
      });
      setMessage(t('pages.doApp.creditTransactions.creationSuccess'));
      setSeverity('success');
      setMerchantAccountNumber('');
      setSubscriberEmail('');
      setDeviceId('');
      setAmount('0');
      setOpen(true);
      onTransactionCreated();
    } catch (err) {
      setMessage(t('pages.doApp.creditTransactions.creationError'));
      setSeverity('error');
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <WidgetTitle title={t('pages.doApp.creditTransactions.createNewTransaction')} />
      <Collapse in={open}>
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Collapse>
      <TextField
        label={t('pages.doApp.creditTransactions.merchantAccountNumber')}
        value={merchantAccountNumber}
        onChange={(e) => setMerchantAccountNumber(e.target.value)}
        fullWidth
        variant="outlined"
        size="small"
        style={{ marginBottom: 16, marginTop: 16 }}
      />
      <TextField
        label={t('pages.doApp.creditTransactions.subscriberEmail')}
        value={subscriberEmail}
        onChange={(e) => setSubscriberEmail(e.target.value)}
        fullWidth
        variant="outlined"
        size="small"
        style={{ marginBottom: 16 }}
      />
      <TextField
        label={t('pages.doApp.deviceId')}
        value={deviceId}
        onChange={(e) => setDeviceId(e.target.value)}
        fullWidth
        variant="outlined"
        size="small"
        style={{ marginBottom: 16 }}
      />
      <TextField
        label={t('pages.doApp.creditTransactions.amount')}
        type="text"
        value={amount}
        onChange={handleAmountChange}
        fullWidth
        variant="outlined"
        size="small"
        style={{ marginBottom: 16 }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        }}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
        {loading
          ? t('pages.doApp.creditTransactions.creating')
          : t('pages.doApp.creditTransactions.createTransactionSubmit')}
      </Button>
    </div>
  );
};

export default DoAppCreateCreditTransactionForm;
