import React, { ChangeEvent, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, TextField, withTheme } from '@material-ui/core';
import { AdminAutocompleteInput, AdminTextInput } from '../../Shared/AdminFormInputs';
import styled from 'styled-components';
import app from '../../../../../config/app/app';
import moment from 'moment';
import { AdCategory, HouseAdsBannersCategory } from '../../../../../store/AdminAds/types';

type AdBannerFormProps = {
  width: number;
  height: number;
  startDate: string;
  endDate: string;
  categories: HouseAdsBannersCategory[];
  onBannerFileChoose: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  bannerFilePreviewUrl: string | null;
  bannerFileRef: React.MutableRefObject<HTMLInputElement | null>;
  bannerFilePreviewRef: React.MutableRefObject<HTMLImageElement | null>;
  showDates?: boolean;
  showCategories?: boolean;
};

const AdBannerForm: FunctionComponent<AdBannerFormProps> = ({
  width,
  height,
  startDate,
  categories,
  endDate,
  onBannerFileChoose,
  bannerFilePreviewUrl,
  bannerFileRef,
  bannerFilePreviewRef,
  showDates = true,
  showCategories = true
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <AdminTextInput t={t} name="organization" section="adminAds" disabled />
        {showCategories && (
          <AdminAutocompleteInput
            t={t}
            name="category"
            section="adminAds"
            options={categories.map((data) => ({ label: data.category.name, id: data.category.id }))}
          />
        )}
        <AdminTextInput t={t} name="bannerName" section="adminAds" />
        {showDates && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AdminTextInput
                t={t}
                section="adminAds"
                name="startDate"
                type="date"
                inputProps={{ min: moment().format(app.dateFormats.inputValueDate) }}
              />
            </Grid>
            <Grid item xs={6}>
              <AdminTextInput
                t={t}
                section="adminAds"
                name="endDate"
                type="date"
                disabled={!startDate}
                inputProps={{ min: moment(startDate).format(app.dateFormats.inputValueDate) }}
              />
            </Grid>
          </Grid>
        )}

        <AdminTextInput t={t} name="alt" section="adminAds" />
        <AdminTextInput t={t} name="url" section="adminAds" />
        <UrlHint>{t('pages.adminAds.inputs.urlHint')}</UrlHint>
        <AdminTextInput multiline t={t} name="comments" section="adminAds" />
        {width > 0 && height > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StyledLabel>{t('pages.adminAds.inputs.width')}</StyledLabel>
              <TextField
                id="width-input"
                value={width}
                InputProps={{
                  readOnly: true
                }}
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <StyledLabel>{t('pages.adminAds.inputs.height')}</StyledLabel>
              <TextField
                id="height-input"
                value={height}
                InputProps={{
                  readOnly: true
                }}
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={6}>
        <ImagePickerContainer>
          {t('pages.adminAds.windows.chooseFileInfo')}
          <Button
            color="secondary"
            variant="contained"
            size="medium"
            onClick={() => bannerFileRef.current && bannerFileRef.current.click()}
            style={{ margin: '10px' }}
          >
            {t('common.chooseFile')}
          </Button>
          <input
            type="file"
            id="bannerUploader"
            accept="image/jpeg,image/jpg,image/png"
            ref={bannerFileRef}
            style={{ display: 'none' }}
            onChange={onBannerFileChoose}
          />
          {bannerFilePreviewUrl && (
            <>
              <img ref={bannerFilePreviewRef} src={bannerFilePreviewUrl} style={{ display: 'none' }} alt="" />
              <img
                src={bannerFilePreviewUrl}
                style={{ width: '100%', height: '350px', objectFit: 'contain', objectPosition: 'center' }}
                alt=""
              />
            </>
          )}
        </ImagePickerContainer>
      </Grid>
    </Grid>
  );
};

const StyledLabel = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.primary.dark};
  margin: 1rem 0 0.3rem;
  font-size: 1rem;
  display: block;
`);
const ImagePickerContainer = styled.div`
  width: 100%;
  background: #f1f1f1;
  padding: 20px 10px 10px 10px;
  text-align: center;
  color: #545871;
`;
const UrlHint = withTheme(styled.div`
  font-style: italic;
  font-size: 100%;
  color: ${({ theme }) => theme.palette.primary.dark};
`);

export default AdBannerForm;
