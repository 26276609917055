import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import { PageContentPaper, PageContentPaperHeading } from '../../../PageStyledComponents';
import SaveIcon from '@material-ui/icons/Save';
import {
  DetailedEmailCampaignsReportResponse,
  SubmittedPrintProjectsReportResponse
} from '../../../../../store/AdminReports/types';
import { adminReportsOperations } from '../../../../../store/AdminReports';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import AdminReportsGenerateCsvWindow from '../Windows/AdminReportsGenerateCsvWindow';
import linksConstants from '../../../../../config/app/linksConstants';
import { formatPublishDate } from '../../../../../utils';
import styled from 'styled-components';
import moment from 'moment';

type DetailedEmailCampaignsReportProps = {};

const DetailedEmailCampaignsReport: FunctionComponent<DetailedEmailCampaignsReportProps> = () => {
  const { t } = useTranslation();
  const [reportData, setReportData] = useState<DetailedEmailCampaignsReportResponse['data']>([]);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [csvDownloadWindowOpen, onCsvDownloadWindowOpen, onCsvDownloadWindowClose] = useOpenHandler();

  useEffect(() => {
    const fetchData = async () => {
      setError(false);
      try {
        const response = await adminReportsOperations.detailedEmailCampaignsReport('json');

        if (response) {
          setReportData(response.data);
        }
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    };

    fetchData();
  }, []);

  if (loading && !reportData) {
    return <>Loading...</>;
  }

  return (
    <PageContentPaper>
      <PageContentPaperHeading>
        <Typography variant="h6" gutterBottom>
          {t('pages.adminReports.detailedEmailCampaignsReport.title')}
        </Typography>
        <Button color={'secondary'} variant={'contained'} size="medium" onClick={onCsvDownloadWindowOpen}>
          <SaveIcon /> {t('pages.adminReports.generateCsvReport')}
        </Button>
      </PageContentPaperHeading>
      <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
      {error && <div>Something went wrong...</div>}
      {!error && (
        <>
          {reportData.map((project, index) => (
            <Row key={index}>
              <div>
                <Strong>
                  <Link to={linksConstants.ADMINISTRATION.ORGANIZATIONS.USERS(project.organizationId)}>
                    {project.organizationName}
                  </Link>
                </Strong>{' '}
                <em>({project.organizationNumber})</em>
              </div>
              <Link to={linksConstants.PROJECT_DETAILS(project.id)}>{project.name}</Link>{' '}
              {!!project.deliveredAt && (
                <>
                  published by <Strong>{project.scheduledBy || 'unknown'}</Strong> on{' '}
                  {moment(project.deliveredAt).format('MM-DD-YYYY h:mm a')} to {project.recipients} recipients (unique
                  opens: {project.uniqueOpens})
                </>
              )}
              {!project.deliveredAt && (
                <>
                  (campaign data not yet synced, go to <Link to={linksConstants.ADMIN_PROJECTS}>Admin Dashboard</Link>{' '}
                  to trigger it manually)
                </>
              )}
            </Row>
          ))}
        </>
      )}
      <AdminReportsGenerateCsvWindow
        open={csvDownloadWindowOpen}
        onCloseClick={onCsvDownloadWindowClose}
        onSubmit={async (range) => await adminReportsOperations.detailedEmailCampaignsReport('csv', range)}
      />
    </PageContentPaper>
  );
};
const PublicationTime = styled.span`
  font-size: 0.7rem;
  font-style: italic;
`;

export default DetailedEmailCampaignsReport;
