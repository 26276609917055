import React from 'react';
import { TableCell } from '@material-ui/core';
import app from '../../../../config/app/app';
import { StyledTableCell } from '../../StyledComponents';

export type BasicColumnCellProps<E = never> = {
  value: ValueOf<E>;
};

export function BasicColumnCell<E = never>({ value }: BasicColumnCellProps<E>) {
  return <StyledTableCell style={{ maxWidth: app.maxTableColumnWidth }}>{value}</StyledTableCell>;
}
