import React from 'react';
import { Story, StoryChannelType } from '../../../../store/Stories/types';
import CalendarEventChip from './CalendarEventChip';
import { useDispatch } from 'react-redux';
import linksConstants from '../../../../config/app/linksConstants';
import { push } from 'connected-react-router';
import { Box, Tooltip, withTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TableLink } from '../../../Shared/DataTable/ColumnCell/UrlColumnCell';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import styled from 'styled-components';

type StoryNameCellProps = {
  story: Story;
};

const StoryNameCell: React.FC<StoryNameCellProps> = ({ story }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div onClick={() => dispatch(push(linksConstants.STORY.EDIT(story.id)))} style={{ cursor: 'pointer' }}>
      {story.sharedByReference && story.sourceOrganization && (
        <SharedByInfo>Shared by: {story.sourceOrganization.name}</SharedByInfo>
      )}
      <TableLink>{story.name}</TableLink>
      <Box display="flex" alignItems="center">
        <AssignmentIndIcon titleAccess={t('pages.story.author')} fontSize="small" />
        <div style={{ fontSize: '13px', marginLeft: '3px' }}>
          {story.createdBy ? (
            <Tooltip title={story.createdBy.email || ''} placement="bottom" arrow>
              <div>{story.createdBy.fullName}</div>
            </Tooltip>
          ) : (
            'N/A'
          )}
        </div>
      </Box>
      <div onClick={(e) => e.stopPropagation()}>
        {story.channels
          .filter((cd) => cd.type === StoryChannelType.CALENDAR_EVENT)
          .map((channel) => (
            <CalendarEventChip key={channel.id} channel={channel} />
          ))}
      </div>
    </div>
  );
};

export default StoryNameCell;

const SharedByInfo = withTheme(styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #9e9e9e;
  ${({ theme }) =>
    `
    color: ${theme.palette.warning.main};
  `};
`);
