import React from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { AuthTicketValidationData, SetPasswordFormValues } from '../../../../store/Auth/types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@material-ui/core';
import InfoBox from '../../../Shared/InfoBox/InfoBox';
import AuthTicketValidationError from './AuthTicketValidationError';
import PasswordPolicyInfo from '../Shared/PasswordPolicyInfo';
import { AuthActionButtons, AuthInput, AuthMainButton, InputRow } from '../AuthStyledComponents';
import { PasswordPolicy } from '../../../../store/SystemSettings/types';

type SetPasswordFormProps = {
  handleSubmit: (values: any, actions: FormikHelpers<any>) => void;
  authTicketValidationData?: AuthTicketValidationData;
  formSubmitError: NetworkError | null;
  withPreviousPassword?: boolean;
  consentRequired?: boolean;
  passwordPolicy: PasswordPolicy;
};

/* OWASP Special Characters: https://www.owasp.org/index.php/Password_special_characters */
const specialCharacters = [
  ' ',
  '!',
  '"',
  '#',
  '\\$',
  '%',
  '&',
  "'",
  '\\(',
  '\\)',
  '\\*',
  '\\+',
  ',',
  '-',
  '\\.',
  '/',
  ':',
  ';',
  '<',
  '=',
  '>',
  '\\?',
  '@',
  '\\[',
  '\\\\',
  '\\]',
  '\\^',
  '_',
  '`',
  '{',
  '\\|',
  '}',
  '~'
].join('|');

const specialCharactersRegexp = new RegExp(specialCharacters);

// const passwordPolicy = {
//   length: 8,
//   specialCharactersRegexp
// };

const SetPasswordForm: React.FC<SetPasswordFormProps> = ({
  handleSubmit,
  authTicketValidationData,
  formSubmitError,
  withPreviousPassword,
  consentRequired,
  passwordPolicy
}) => {
  const { t, i18n } = useTranslation();
  // Root password validation schema for PasswordPolicy component
  let passwordValidationSchema = Yup.string().min(passwordPolicy.passwordLength, 'length').required('required');

  if (passwordPolicy.upper) {
    passwordValidationSchema = passwordValidationSchema.matches(/[A-Z]/, {
      message: 'upper'
    });
  }

  if (passwordPolicy.lower) {
    passwordValidationSchema = passwordValidationSchema.matches(/[a-z]/, {
      message: 'lower'
    });
  }

  if (passwordPolicy.numbers) {
    passwordValidationSchema = passwordValidationSchema.matches(/[\d]/, { message: 'numbers' });
  }

  if (passwordPolicy.specialCharacters) {
    passwordValidationSchema = passwordValidationSchema.matches(specialCharactersRegexp, {
      message: 'specialCharacters'
    });
  }

  /**
   * Function used used to create a recipe for validation schema for Formik. It has to be called with Yup.lazy()
   * in order to create an actual validation schema.
   * The main difference between this function and @see {@link passwordValidationSchema}
   * is the fact that this function checks if at least 3 out of 4 optional rules were passed by input values
   * when @see {@link passwordValidationSchema} validates all rules.
   * @param values
   */
  const passwordRulesValidation = (values: SetPasswordFormValues) => ({
    password: Yup.string()
      .min(passwordPolicy.passwordLength, 'length')
      .test('rulesNotMatched', 'rulesNotMatched', (value) => {
        if (!value) {
          return false;
        }

        const tests = [];
        if (passwordPolicy.upper) {
          tests.push(/[A-Z]/.test(value));
        }

        if (passwordPolicy.lower) {
          tests.push(/[a-z]/.test(value));
        }

        if (passwordPolicy.numbers) {
          tests.push(/[\d]/.test(value));
        }

        if (passwordPolicy.specialCharacters) {
          tests.push(specialCharactersRegexp.test(value));
        }

        return tests.filter(Boolean).length === tests.length;
      })
      .required('required'),
    confirmPassword: Yup.string().required().oneOf([values.password], t('passwordPolicy.validations.match'))
  });

  // Create actual validation schema with Yup.lazy();
  const setPasswordFormValidation = Yup.lazy((values: any) =>
    Yup.object().shape({
      ...passwordRulesValidation(values),
      ...(consentRequired
        ? {
            consent: Yup.boolean().oneOf([true]).required()
          }
        : {}),
      ...(withPreviousPassword
        ? {
            previousPassword: Yup.string().required(
              t('formValidationErrors.required', { fieldName: 'Previous password' })
            )
          }
        : {})
    })
  );

  const renderSetPasswordForm = (formikProps: FormikProps<SetPasswordFormValues>) => {
    const { isValid } = formikProps;
    return (
      <Form autoComplete={'off'}>
        {withPreviousPassword && (
          <InputRow>
            <AuthInput
              formikFieldProps={{
                name: 'previousPassword'
              }}
              textFieldProps={{
                label: t('pages.auth.validateAndSetPassword.form.previousPassword'),
                type: 'password',
                variant: 'outlined',
                fullWidth: true,
                required: true
              }}
            />
          </InputRow>
        )}
        <InputRow>
          <AuthInput
            formikFieldProps={{
              name: 'password'
            }}
            customErrorMessagePath={'passwordPolicy.validations'}
            textFieldProps={{
              label: t('pages.auth.validateAndSetPassword.form.password'),
              type: 'password',
              variant: 'outlined',
              fullWidth: true,
              required: true
            }}
          />
        </InputRow>
        <InputRow style={{ margin: '24px 0 18px 0' }}>
          <PasswordPolicyInfo
            formValues={formikProps.values}
            passwordValidationSchema={passwordValidationSchema}
            passwordPolicy={passwordPolicy}
          />
        </InputRow>
        <InputRow>
          <AuthInput
            formikFieldProps={{
              name: 'confirmPassword'
            }}
            textFieldProps={{
              label: t('pages.auth.validateAndSetPassword.form.confirmPassword'),
              variant: 'outlined',
              fullWidth: true,
              type: 'password',
              required: true
            }}
          />
        </InputRow>
        {withPreviousPassword && (
          <Collapse in={!!formSubmitError}>
            {formSubmitError && (
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <InfoBox fullWidth>{t(`networkErrors.${formSubmitError.message}`)}</InfoBox>
              </div>
            )}
          </Collapse>
        )}
        {authTicketValidationData && !withPreviousPassword && (
          <div style={{ marginTop: 24 }}>
            <AuthTicketValidationError authTicketValidationData={authTicketValidationData} error={formSubmitError} />
          </div>
        )}
        <AuthActionButtons>
          <AuthMainButton
            color={'secondary'}
            variant={'contained'}
            size={'large'}
            type={'submit'}
            disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
          >
            {t('pages.auth.validateAndSetPassword.form.setPassword')}
          </AuthMainButton>
        </AuthActionButtons>
      </Form>
    );
  };
  return (
    <Formik
      initialValues={{
        ...(withPreviousPassword ? { previousPassword: '' } : {}),
        password: '',
        confirmPassword: '',
        ...(consentRequired ? { consent: false } : {})
      }}
      onSubmit={handleSubmit}
      validationSchema={setPasswordFormValidation}
    >
      {(formikProps) => renderSetPasswordForm(formikProps)}
    </Formik>
  );
};

export default SetPasswordForm;
