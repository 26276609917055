import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { AdminPasswordPolicy, AdminUpdatePasswordPolicyValues } from '../../../../../store/AdminPasswordPolicies/types';
import { adminPasswordPoliciesOperations } from '../../../../../store/AdminPasswordPolicies';
import { useDispatch } from 'react-redux';
import { AdminCheckboxInput, AdminTextInput } from '../../Shared/AdminFormInputs';
import Toast from '../../../../Shared/Toast/Toast';
import app from '../../../../../config/app/app';
import { FormikProps } from 'formik';
import { Row } from '../../../../Shared/StyledComponents';

type AdminUpdatePasswordPolicyWindowProps = {
  open: boolean;
  passwordPolicy: AdminPasswordPolicy | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminUpdatePasswordPolicyWindow: FunctionComponent<AdminUpdatePasswordPolicyWindowProps> = ({
  passwordPolicy,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!passwordPolicy) return null;

  const onSubmit = async (values: AdminUpdatePasswordPolicyValues) => {
    try {
      await dispatch(
        adminPasswordPoliciesOperations.update(passwordPolicy.id, {
          name: values.name,
          description: values.description,
          passwordLength: values.passwordLength,
          upper: values.upper,
          lower: values.lower,
          numbers: values.numbers,
          specialCharacters: values.specialCharacters,
          forceRotation: values.forceRotation,
          rotationDays: values.rotationDays,
          forceUnique: values.forceUnique,
          historyLength: values.historyLength
        })
      );
      Toast.success(t('notifications.adminPasswordPolicies.successOperation'));

      await dispatch(adminPasswordPoliciesOperations.index());

      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.adminPasswordPolicies.errorOperation'));
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="name" section="adminPasswordPolicies" />
      <AdminTextInput t={t} name="description" section="adminPasswordPolicies" />
      <Row>
        <AdminTextInput t={t} name="passwordLength" section="passwordPolicy" type="number" />
      </Row>
      <Row>
        <AdminCheckboxInput t={t} name="upper" section="adminPasswordPolicies" />
      </Row>
      <Row>
        <AdminCheckboxInput t={t} name="lower" section="adminPasswordPolicies" />
      </Row>
      <Row>
        <AdminCheckboxInput t={t} name="numbers" section="adminPasswordPolicies" />
      </Row>
      <Row>
        <AdminCheckboxInput t={t} name="specialCharacters" section="adminPasswordPolicies" />
      </Row>
      <Row>
        <AdminCheckboxInput t={t} name="forceRotation" section="adminPasswordPolicies" />
      </Row>
      <Row>
        <AdminTextInput
          t={t}
          name="rotationDays"
          section="adminPasswordPolicies"
          type="number"
          disabled={!props.values.forceRotation}
        />
      </Row>
      <Row>
        <AdminCheckboxInput t={t} name="forceUnique" section="adminPasswordPolicies" />
      </Row>
      <Row>
        <AdminTextInput
          t={t}
          name="historyLength"
          section="adminPasswordPolicies"
          type="number"
          disabled={!props.values.forceUnique}
        />
      </Row>
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      header={t('pages.adminPasswordPolicies.windows.updatePasswordPolicy')}
      okButtonText={t('common.update')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        name: passwordPolicy.name || '',
        description: passwordPolicy.description || '',
        passwordLength: passwordPolicy.passwordLength,
        upper: passwordPolicy.upper,
        lower: passwordPolicy.lower,
        numbers: passwordPolicy.numbers,
        specialCharacters: passwordPolicy.specialCharacters,
        forceRotation: passwordPolicy.forceRotation,
        rotationDays: passwordPolicy.rotationDays || 60,
        forceUnique: passwordPolicy.forceUnique,
        historyLength: passwordPolicy.historyLength || 10
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(app.maxInputLength).required(),
        description: Yup.string().max(app.maxInputLength).required(),
        passwordLength: Yup.number().integer().min(6).max(50).required(),
        upper: Yup.boolean().required(),
        lower: Yup.boolean().required(),
        numbers: Yup.boolean().required(),
        specialCharacters: Yup.boolean().required(),
        forceRotation: Yup.boolean().required(),
        rotationDays: Yup.number().integer().min(10).max(365).required(),
        forceUnique: Yup.boolean().required(),
        historyLength: Yup.number().integer().min(1).max(20).required()
      })}
    />
  );
};

export default AdminUpdatePasswordPolicyWindow;
