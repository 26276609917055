import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Button, Paper, withTheme } from '@material-ui/core';
import ProjectAvatar from '../Avatar/ProjectAvatar';
import { useTranslation } from 'react-i18next';
import { AnyProject } from '../../../store/Dashboard/types';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import TemplateThumbnail from '../Thumbnail/TemplateThumbnail';
import { TemplateTypeName } from '../../../store/Templates/types';
import { formatPublishDate } from '../../../utils';

type ProjectPaperProps = {
  onSelect: (project: AnyProject) => void;
  project: AnyProject;
};

const ProjectPaper: FunctionComponent<ProjectPaperProps> = ({ project, onSelect }) => {
  const { t } = useTranslation();

  return (
    <LinkNoUnderline>
      <Paper elevation={3} data-item-id={project.id} style={{ maxWidth: 240 }}>
        <TemplateThumbnail thumbnailUrl={project.thumbnailUrl} width="240px" height="320px" />
        <Texts>
          <ProjectAvatar type={project.type} />
          <div>
            <Title>{project.name}</Title>
            <PublishDate>
              <CalendarIcon style={{ fontSize: 12 }} /> {formatPublishDate(project.publishDate)}
            </PublishDate>
            <Description>{project.templateDescription}</Description>
          </div>
        </Texts>
        <ActionButtons>
          <Button color={'secondary'} variant="text" size="medium">
            {/*{t('common.preview')}*/}
          </Button>
          <Button color={'secondary'} variant="contained" size="medium" onClick={() => onSelect(project)}>
            {t('common.select')}
          </Button>
        </ActionButtons>
      </Paper>
    </LinkNoUnderline>
  );
};

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem;
`;

const LinkNoUnderline = styled.div`
  margin: 0 2rem 2rem 0;
  text-align: left;
  display: inline-block;
`;
const Texts = withTheme(styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  letter-spacing: 0.08rem;
`);
const Image = withTheme(styled.div<{ src: string }>`
  margin-bottom: 0.5rem;
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  min-width: 20rem;
  min-height: 15rem;
`);

const TextHolder = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-width: 12rem;
  word-break: break-all;
  letter-spacing: normal;
  line-height: normal;
`;
const Title = withTheme(styled(TextHolder)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  margin-bottom: 0.1rem;
`);
const Description = withTheme(styled(TextHolder)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 0.8rem;
`);

const PublishDate = withTheme(styled(TextHolder)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 0.8rem;
  letter-spacing: normal;
  margin-bottom: 0.4rem;
`);

export default ProjectPaper;
