import AdminPrintTemplatesPage from './AdminPrintTemplatesPage';
import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';

const AdminPrintTemplatesRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMINISTRATION.PRINT_TEMPLATES.INDEX,
    exact: true,
    name: 'printTemplates',
    public: false,
    show: true,
    component: AdminPrintTemplatesPage,
    scope: []
  }
];

export default AdminPrintTemplatesRoutes;
