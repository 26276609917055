import update from 'immutability-helper';
import { Subscriber, SubscribersActionsConstants, SubscribersActionType, SubscribersState } from './types';
import { normalizeSubscribers } from '../helpers';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';
import { MailingListsActionsConstants } from '../MailingLists/types';

const initialState: SubscribersState = {
  data: {
    entities: {},
    result: []
  },
  mailingList: null,
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const subscribersPaginationReducer = createPaginationReducer<SubscribersState, Subscriber>(
  PaginationStoreModule.SUBSCRIBERS
);

const subscribersTableSearchReducer = createTableSearchReducer<Subscriber, SubscribersState>(
  TableSearchStoreModule.SUBSCRIBERS
);

export default function subscribersReducer(state = initialState, action: WithLogoutAction<SubscribersActionType>) {
  switch (action.type) {
    case SubscribersActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case SubscribersActionsConstants.INDEX_SUCCESS: {
      const data = normalizeSubscribers(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        mailingList: { $set: action.payload.mailingList },
        data: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case SubscribersActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        mailingList: { $set: null },
        data: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case MailingListsActionsConstants.UPDATE_MAILING_LIST: {
      const mailingList = action.payload.mailingList;

      if (mailingList && state.mailingList && state.mailingList.id === mailingList.id) {
        return update(state, {
          mailingList: { $set: mailingList }
        });
      }

      return state;
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return subscribersTableSearchReducer(subscribersPaginationReducer(state, action), action) as SubscribersState;
    }
  }
}
