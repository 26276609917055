import React, { FunctionComponent } from 'react';
import { AnyProject } from '../../../../store/Dashboard/types';
import { Tooltip, useTheme } from '@material-ui/core';
import { EmailProjectStatus } from '../../../../store/EmailProjects/types';
import { useTranslation } from 'react-i18next';
import { getStatusIcon } from '../../../Shared/ProjectStatusIcon';
import { ProjectStatusCellWrapper } from '../../../Shared/StyledComponents';
import { AnyProjectAdmin } from '../../../../store/AdminProjects/types';

type ProjectStatusCellProps = {
  project: AnyProject | AnyProjectAdmin;
};

const ProjectStatusCell: FunctionComponent<ProjectStatusCellProps> = ({ project }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const hasProcessingErrors = Boolean(project.vertexErrors && project.vertexErrors.length);

  const wrapper = (
    <ProjectStatusCellWrapper status={project.status} hasErrors={hasProcessingErrors}>
      {getStatusIcon(project.status, hasProcessingErrors, theme.palette.success.main)}{' '}
      {hasProcessingErrors
        ? t(`common.projectStatus.vertexError`)
        : t(`common.projectStatus.${project.kind}.${project.status}`)}
    </ProjectStatusCellWrapper>
  );

  if (project.vertexErrors && project.vertexErrors.length) {
    return (
      <Tooltip
        placement="top"
        title={`${t(`common.projectStatus.vertexErrorTooltip`, { message: project.vertexErrors[0].message })}`}
      >
        {wrapper}
      </Tooltip>
    );
  }

  if (project.status === EmailProjectStatus.ERROR) {
    return (
      <Tooltip placement="top" title={`${t(`common.projectStatus.emailDeliveryError`)}`}>
        {wrapper}
      </Tooltip>
    );
  }

  return wrapper;
};

export default ProjectStatusCell;
