import React, { useState } from 'react';
import { TextField, Button, Collapse, IconButton } from '@material-ui/core';
import { doAppPromotionClaimsOperations } from '../../../../../store/DoApp/PromotionClaims';
import { WidgetTitle } from '../../../../Shared/ListingWidget/WidgetTitle';
import { Alert, Color } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

type DoAppCreatePromotionClaimFormProps = {
  onClaimCreated: () => void;
};

const DoAppCreatePromotionClaimForm: React.FC<DoAppCreatePromotionClaimFormProps> = ({ onClaimCreated }) => {
  const { t } = useTranslation();
  const [promotionId, setPromotionId] = useState<string>('');
  const [deviceId, setDeviceId] = useState<string>('');
  const [subscriberEmail, setSubscriberEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<Color>('success');
  const [open, setOpen] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setOpen(false);
    setMessage(null);
    try {
      const data = {
        promotionId,
        email: subscriberEmail,
        deviceId
      };
      await doAppPromotionClaimsOperations.createPromotionClaim(data);
      setMessage(t('pages.doApp.promotionClaims.creationSuccess'));
      setSeverity('success');
      setPromotionId('');
      setSubscriberEmail('');
      setDeviceId('');
      setOpen(true);
      onClaimCreated();
    } catch (err) {
      setMessage(t('pages.doApp.promotionClaims.creationError'));
      setSeverity('error');
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <WidgetTitle title={t('pages.doApp.promotionClaims.createNewClaim')} />
      <Collapse in={open}>
        <Alert
          severity={severity}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={() => setOpen(false)}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Collapse>
      <TextField
        label={t('pages.doApp.promotionClaims.promotionId')}
        value={promotionId}
        onChange={(e) => setPromotionId(e.target.value)}
        fullWidth
        variant="outlined"
        size="small"
        style={{ marginBottom: 16, marginTop: 16 }}
      />
      <TextField
        label={t('pages.doApp.promotionClaims.subscriberEmail')}
        value={subscriberEmail}
        onChange={(e) => setSubscriberEmail(e.target.value)}
        fullWidth
        variant="outlined"
        size="small"
        style={{ marginBottom: 16 }}
      />
      <TextField
        label={t('pages.doApp.deviceId')}
        value={deviceId}
        onChange={(e) => setDeviceId(e.target.value)}
        fullWidth
        variant="outlined"
        size="small"
        style={{ marginBottom: 16 }}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
        {loading ? t('pages.doApp.promotionClaims.creating') : t('pages.doApp.promotionClaims.createClaimSubmit')}
      </Button>
    </div>
  );
};

export default DoAppCreatePromotionClaimForm;
