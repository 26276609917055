import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminEmailTemplate } from '../../../../../store/AdminEmailTemplates/types';
import { adminEmailTemplatesOperations } from '../../../../../store/AdminEmailTemplates';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';
import Toast from '../../../../Shared/Toast/Toast';

type AdminDeleteEmailTemplateWindowProps = {
  open: boolean;
  template: AdminEmailTemplate | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminDeleteEmailTemplateWindow: FunctionComponent<AdminDeleteEmailTemplateWindowProps> = ({
  template,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!template) return null;

  const onSubmit = async () => {
    try {
      await dispatch(adminEmailTemplatesOperations.deleteEmailTemplate(template.id));
      await dispatch(adminEmailTemplatesOperations.index());
      onCloseClick();
      Toast.success(t('notifications.adminEmailTemplates.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminEmailTemplates.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminEmailTemplates.windows.deleteEmailTemplate')}
      okButtonText={t('pages.adminEmailTemplates.windows.completelyDelete')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.adminEmailTemplates.windows.deleteEmailTemplateContent')}</Row>
      <Row>
        <Strong>{template.name}</Strong>
      </Row>
      <Row>
        <InfoBox type="warning">{t('pages.adminEmailTemplates.windows.cannotBeUndone')}</InfoBox>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default AdminDeleteEmailTemplateWindow;
