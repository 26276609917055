import { createOrderArray, httpRequest } from '../../utils';
import { CreateCampaignValues, CampaignsIndexResponse, UpdateCampaignValues, Campaign } from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { campaignsActions, campaignsRequests } from './index';

export const index = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { campaigns } = getState();

  try {
    dispatch(campaignsActions.campaignsIndexBegin());

    const response = await httpRequest.runRequest<CampaignsIndexResponse>(
      campaignsRequests.index({
        requestParams: {
          page: campaigns.pagination.page,
          per: campaigns.pagination.per,
          q: campaigns.pagination.q,
          order: createOrderArray(campaigns.tableSearch.order)
        }
      })
    );

    dispatch(campaignsActions.campaignsIndexSuccess(response));
  } catch (e) {
    dispatch(campaignsActions.campaignsIndexFailed(e));
  }
};

export const create = (values: CreateCampaignValues) => {
  return httpRequest.runRequest<Campaign>(
    campaignsRequests.create({
      requestPayload: values
    })
  );
};

export const show = async (campaignId: string) => {
  return httpRequest.runRequest<Campaign>(
    campaignsRequests.show({
      requestParams: {
        campaignId
      }
    })
  );
};

export const update = (id: string, values: UpdateCampaignValues) => {
  return httpRequest.runRequest<Campaign>(
    campaignsRequests.update({
      requestPayload: values,
      requestParams: { id }
    })
  );
};

export const deleteCampaign = (id: string) => async () => {
  await httpRequest.runRequest<void>(
    campaignsRequests.deleteCampaign({
      requestParams: { id }
    })
  );
};

export const assign = (id: string, itemId: string, type: string) => {
  return httpRequest.runRequest<void>(
    campaignsRequests.assign({
      requestPayload: { itemId, type },
      requestParams: { id }
    })
  );
};

export const unassign = (id: string, itemId: string, type: string) => async () => {
  await httpRequest.runRequest<void>(
    campaignsRequests.unassign({
      requestPayload: { itemId, type },
      requestParams: { id }
    })
  );
};
