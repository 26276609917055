import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { subscribersOperations } from '../../../../../store/Subscribers';
import { Subscriber } from '../../../../../store/Subscribers/types';
import Toast from '../../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';

type DeleteSubscriberWindowProps = {
  open: boolean;
  mailingListId: string;
  subscriber: Subscriber | null;
  onCloseClick: (isFormSubmitted: boolean) => void;
  fullScreenOnMobile: boolean;
};

const DeleteSubscriberWindow: FunctionComponent<DeleteSubscriberWindowProps> = ({
  mailingListId,
  subscriber,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!subscriber) return null;

  const onSubmit = async () => {
    try {
      await dispatch(subscribersOperations.deleteSubscriber(mailingListId, subscriber.id));
      onCloseClick(true);
      Toast.success(t('notifications.subscribers.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.subscribers.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.subscribers.windows.deleteSubscriber')}
      okButtonText={t('pages.subscribers.windows.delete')}
      onCloseClick={() => onCloseClick(false)}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.subscribers.windows.deleteSubscriberContent')}</Row>
      <Row>
        <Strong>{subscriber.email}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteSubscriberWindow;
