import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import CalendarPage from './CalendarPage';
import PublicOrganizationCalendarPage from './PublicOrganizationCalendarPage';

export const CalendarRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.CALENDAR,
    exact: true,
    name: 'calendar',
    public: false,
    show: true,
    component: CalendarPage,
    scope: []
  }
];

export const CalendarPublicRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.CALENDAR_PUBLIC,
    exact: true,
    name: 'calendar_public',
    public: true,
    show: true,
    component: PublicOrganizationCalendarPage,
    scope: []
  }
];
