import React, { useEffect, useRef, useState } from 'react';
import { MonthCalendarEvent, MonthCalendarEventPointer } from '../../../../../../../store/MyOrganization/types';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import LinkIcon from '@material-ui/icons/Link';

type CalendarEventProps = {
  eventRef: MonthCalendarEventPointer;
  event: MonthCalendarEvent;
  eventName: string | null;
  isWeekView: boolean;
  detailedView: JSX.Element;
  setRowHeight: (row: number, height: number, eventId: string) => void;
  rowHeights: { eventId: string; row: number; height: number }[];
  eventStyles: React.CSSProperties;
  staticEventHeight?: number;
};

const CalendarEvent = React.forwardRef<HTMLDivElement, CalendarEventProps>(
  (
    {
      eventRef,
      event,
      eventName,
      isWeekView,
      detailedView,
      setRowHeight,
      rowHeights,
      eventStyles,
      staticEventHeight,
      ...rest
    },
    tooltipRef
  ) => {
    const ref = useRef<HTMLDivElement>(null);
    const height = () => {
      if (isWeekView && staticEventHeight) {
        return staticEventHeight + 'px';
      }
      const foundRowHeight = event && rowHeights.find((rh) => rh.row === eventRef.row && rh.eventId === event.id);
      return isWeekView && foundRowHeight ? foundRowHeight.height : isWeekView ? 'auto' : 22;
    };

    const marginBottom = () => {
      if (isWeekView && rowHeights.length > 0) {
        const highestEventInRow = Math.max(
          ...rowHeights.filter((rh) => rh.row === eventRef.row).map((rh) => rh.height)
        );
        const foundRowHeight = event && rowHeights.find((rh) => rh.row === eventRef.row && rh.eventId === event.id);
        return highestEventInRow - (foundRowHeight ? foundRowHeight.height : 0) + 3;
      }
      return 3;
    };

    useEffect(() => {
      if (ref.current && isWeekView && event) {
        const margin = 10;
        setRowHeight(eventRef.row, ref.current.clientHeight + margin, event.id);
      }
    });

    // useEffect(() => {
    //   window.addEventListener('resize', () => {
    //     if (ref.current) {
    //       setRowHeight(eventRef.row, ref.current.clientHeight);
    //     }
    //   });
    // }, []);

    if (!event) {
      return null;
    }

    return (
      <div ref={tooltipRef} {...rest}>
        <div
          ref={ref}
          className={`event ${eventRef.s && 'start'} ${eventRef.e && 'end'}`}
          style={{
            ...eventStyles,
            height: height(),
            marginBottom: marginBottom()
          }}
          onClick={() => {
            if (event && event.url) {
              window.open(event.url, '_blank');
            }
          }}
        >
          {eventRef.e && (event.thumbnailUrl || event.url) && (
            <div className="icons">
              {eventRef.e && event.thumbnailUrl && (
                <PhotoLibraryOutlinedIcon style={{ width: 15, height: 15, marginLeft: 'auto' }} />
              )}
              {eventRef.e && event.url && <LinkIcon style={{ width: 15, height: 15, marginLeft: 'auto' }} />}
            </div>
          )}
          {isWeekView ? eventName && detailedView : eventName}
        </div>
      </div>
    );
  }
);

export default CalendarEvent;
