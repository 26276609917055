import moment from 'moment';
import { ReportDateRange } from '../store/AdminReports/types';

function toZeroHours(date: Date | moment.Moment | number) {
  return moment(date).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
}

function toMaxHours(date: Date | moment.Moment | number) {
  return moment(date).set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 });
}

export function parseDateRangeStringToObject(
  rangeName: ReportDateRange
): { from: moment.Moment; to: moment.Moment } | null {
  if (rangeName === ReportDateRange.CUSTOM) {
    return null;
  }

  switch (rangeName) {
    default:
    case 'today':
      return {
        from: toZeroHours(moment()),
        to: toMaxHours(moment())
      };

    case 'lastDay':
      return {
        from: moment().subtract(1, 'day').startOf('day'),
        to: moment().subtract(1, 'day').endOf('day')
      };

    case 'thisWeek':
      return {
        from: toZeroHours(moment().startOf('week')),
        to: toMaxHours(moment().endOf('week'))
      };

    case 'lastWeek':
      return {
        from: toZeroHours(moment().subtract(1, 'week').startOf('week')),
        to: toMaxHours(moment().subtract(1, 'week').endOf('week'))
      };

    case 'thisMonth':
      return {
        from: toZeroHours(moment().startOf('month')),
        to: toMaxHours(moment().endOf('month'))
      };

    case 'lastMonth':
      return {
        from: toZeroHours(moment().subtract(1, 'month').startOf('month')),
        to: toMaxHours(moment().subtract(1, 'month').endOf('month'))
      };

    case 'thisQuarter':
      return {
        from: toZeroHours(moment().startOf('quarter')),
        to: toMaxHours(moment().endOf('quarter'))
      };

    case 'lastQuarter':
      return {
        from: toZeroHours(moment().subtract(1, 'quarter').startOf('quarter')),
        to: toMaxHours(moment().subtract(1, 'quarter').endOf('quarter'))
      };

    case 'thisYear':
      return {
        from: toZeroHours(moment().startOf('year')),
        to: toMaxHours(moment().endOf('year'))
      };

    case 'lastYear':
      return {
        from: toZeroHours(moment().subtract(1, 'year').startOf('year')),
        to: toMaxHours(moment().subtract(1, 'year').endOf('year'))
      };
  }
}
