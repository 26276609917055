import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export function usePaginationWatch(pagination: Pagination, cbs: Function[], deps?: any[]) {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      for (const cb of cbs) {
        await dispatch(cb());
      }
    })();
  }, [pagination.per, pagination.page, pagination.q, ...(deps || [])]);
}
