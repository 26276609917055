import AdminUsersPage from './AdminUsersPage';
import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';

const AdminUsersRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMINISTRATION.USERS.INDEX,
    exact: true,
    name: 'users',
    public: false,
    show: true,
    component: AdminUsersPage,
    scope: []
  }
];

export default AdminUsersRoutes;
