import React, { FunctionComponent } from 'react';

import { AuthTicketValidationData } from '../../../../store/Auth/types';
import InfoBox from '../../../Shared/InfoBox/InfoBox';
import linksConstants from '../../../../config/app/linksConstants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type AuthTicketValidationErrorProps = {
  authTicketValidationData: AuthTicketValidationData;
  error: NetworkError | null;
};

const AuthTicketValidationError: FunctionComponent<AuthTicketValidationErrorProps> = ({
  authTicketValidationData,
  error
}) => {
  const { t, i18n } = useTranslation();

  if (!error) return null;
  return (
    <InfoBox fullWidth>
      <div>
        <span>{t(`networkErrors.${error.message}`)}</span>
        <br />
        <Link
          to={
            authTicketValidationData.ticketType === 'AV'
              ? linksConstants.AUTH.RESEND_VERIFICATION
              : linksConstants.AUTH.FORGOT_PASSWORD
          }
        >
          {t(
            `pages.auth.${
              authTicketValidationData.ticketType === 'AV'
                ? 'doYouNeedVerificationEmailAgain'
                : 'doYouNeedPasswordResetEmailAgain'
            }`
          )}
        </Link>
      </div>
    </InfoBox>
  );
};

export default AuthTicketValidationError;
