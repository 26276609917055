import styled from 'styled-components';
import { withTheme, Button } from '@material-ui/core';
import authLayoutImage from '../../../assets/splash-image.jpg';
import { InputLabel } from '../../Shared/StyledComponents';
import FormTextInput from '../../Shared/Forms/FormTextInput';
import { isTheme } from '../../../utils/environment';
import { APP_THEME } from '../../../typings/environment';

export const AuthContainer = withTheme(styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'splash content content';

  ${(props) => props.theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
    grid-template-areas: 'content';
  }
`);

export const SplashAreaContainer = withTheme(styled.div`
  position: relative;
  grid-area: splash;
  background: ${isTheme(APP_THEME.NHM) || isTheme(APP_THEME.BTL)
    ? '#fff'
    : `#535970 url(${authLayoutImage}) 50% 50% no-repeat`};
  background-size: cover;
  text-align: center;
  border-left: ${isTheme(APP_THEME.NHM)
    ? '60px solid #364766'
    : isTheme(APP_THEME.BTL)
    ? '60px solid #000000'
    : 'none'};

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`);

export const SplashImageContainer = styled.div`
  position: fixed;
  top: 460px;
  left: 10%;
`;

export const AuthContentContainer = withTheme(styled.div`
  grid-area: content;
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 3rem 8rem;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 1rem;
  }
`);

export const AuthPageTitle = styled.div`
  width: 100%;
  font-size: 3rem;
  color: #545871;
  ${isTheme(APP_THEME.LPI) && 'flex-grow: 1; max-height: 10rem;'}
  ${isTheme(APP_THEME.NHM) && 'padding-left: 1rem;'}
`;

export const AuthPageForm = withTheme(styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 3rem;
  form {
    width: 100%;
  }
  ${({ theme }) =>
    isTheme(APP_THEME.NHM) && `padding-left: 1rem; z-index: 1; background: ${theme.palette.background.default}de;`}
`);

export const InputRow = styled.div`
  margin: 1.6rem 0;
  max-width: 30rem;
`;

export const AuthInputLabel = withTheme(styled(InputLabel)`
  color: ${(props) => props.theme.palette.secondary.main};
`);

export const AdditionalLinkContainer = withTheme(styled.div`
  margin: ${isTheme(APP_THEME.NHM) ? '1rem 0' : '2rem 0'};
  font-size: 1rem;
  font-weight: bold;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`);

export const AuthActionButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const AuthMainButton = styled(Button)`
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

export const AuthInput = styled(FormTextInput)`
  .MuiInput-root {
    border-radius: 6px;
    border: 1px solid #e6dbda;
    background-color: #fff;
    padding: 0.7rem;
  }

  input {
    font-size: 1.2rem;
  }
`;
