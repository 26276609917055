import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminUser } from '../../../../../store/AdminUsers/types';
import { adminUsersOperations } from '../../../../../store/AdminUsers';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import { createNetworkErrorObject } from '../../../../../utils';
import Toast from '../../../../Shared/Toast/Toast';

type AdminLoginAsUserWindowProps = {
  open: boolean;
  user: AdminUser | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminLoginAsUserWindow: FunctionComponent<AdminLoginAsUserWindowProps> = ({ user, onCloseClick, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!user) return null;

  const onSubmit = async () => {
    try {
      await dispatch(adminUsersOperations.loginAsUser(user.id));
      window.location.assign('/');
    } catch (e) {
      const networkError = createNetworkErrorObject(e);
      if (networkError.statusCode === 404) {
        return Toast.error('User does not belong to any organization...');
      }
      window.location.assign('/');
    }

    onCloseClick();
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminUsers.windows.loginAsUser')}
      okButtonText={t('pages.adminUsers.windows.switchUser')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>
        {t('pages.adminUsers.windows.loginAsUserContent')} <Strong>{user.email}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default AdminLoginAsUserWindow;
