import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type ProjectDetailsErrorInfoProps = {
  error: NetworkError | null;
};

const ProjectDetailsErrorInfo: FunctionComponent<ProjectDetailsErrorInfoProps> = ({ error }) => {
  const { t } = useTranslation();
  if (!error) {
    return null;
  }

  const getErrorMessage = () => {
    switch (error.statusCode) {
      case 403:
        return t('pages.projectDetails.errors.forbidden');

      case 404:
        return t('pages.projectDetails.errors.notFound');

      default:
        return t('pages.projectDetails.errors.default');
    }
  };

  return <ProjectDetailsErrorText>{getErrorMessage()}</ProjectDetailsErrorText>;
};

const ProjectDetailsErrorText = styled.div`
  font-style: italic;
  text-align: center;
  margin: 4rem 2rem;
  font-size: 1.3rem;
  opacity: 0.8;
`;

export default ProjectDetailsErrorInfo;
