import { nativeImage } from './nativeImage';
import { brandfolderImage } from './brandfolderImage';
import { designerArticle } from './designerArticle';
import { userImage } from './userImage';
import { TFunction } from 'i18next';
import { ImageData, ImageDropObjectData, ItemTypes } from '../../types';
import { ImageProcessingResponse } from '../../../../../../store/Images/types';
import { EmailProject } from '../../../../../../store/EmailProjects/types';
import { AdminEmailTemplate } from '../../../../../../store/AdminEmailTemplates/types';
import { isEmailProject } from '../../../../../../utils';
import { storyArticle } from './storyArticle';
import { designImage } from './designImage';

export const preventDragEvent = (e: any) => e.preventDefault();

export const getDroppedNativeFile = (event: ImageDropObjectData) => {
  if (event && event.files && event.files[0]) {
    return event.files[0];
  }
  return null;
};

export const dragAndDropEventHandler = async (
  project: EmailProject | AdminEmailTemplate,
  imageData: ImageData,
  event: ImageDropObjectData,
  t: TFunction
): Promise<ImageProcessingResponse | null> => {
  /** Native image drop */
  const droppedNativeFile = getDroppedNativeFile(event);

  const type: 'project' | 'template' = isEmailProject(project as EmailProject) ? 'project' : 'template';

  if (droppedNativeFile) {
    return nativeImage(project.id, imageData, droppedNativeFile, t, type);
  }

  if (event.type) {
    try {
      if (event.type === ItemTypes.BRANDFOLDER && event.id) {
        return brandfolderImage(project.id, imageData, event.id, type);
      }

      if (event.type === ItemTypes.ORGANIZATION_UPLOADED_ASSET && event.id) {
        return userImage(project.id, imageData, event.id);
      }

      if (event.type === ItemTypes.DESIGN_ASSET && event.id) {
        return designImage(project.id, imageData, event.id);
      }

      if (event.type === ItemTypes.DESIGNER_STORY && event.designerStory) {
        return designerArticle(project.id, imageData, event.designerStory);
      }

      if (event.type === ItemTypes.STORY && event.story) {
        return storyArticle(project.id, imageData, event.story);
      }
    } catch (e) {
      console.log('Not supported drop action:', e);
    }
  }

  return null;
};
