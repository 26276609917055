import AdminKeywordsPage from './AdminKeywordsPage';
import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';

const AdminKeywordsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMINISTRATION.KEYWORDS.INDEX,
    exact: true,
    name: 'keywords',
    public: false,
    show: true,
    component: AdminKeywordsPage,
    scope: []
  }
];

export default AdminKeywordsRoutes;
