import update from 'immutability-helper';
import { createPaginationReducer } from '../../Pagination/reducers';
import { PaginationStoreModule } from '../../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../../utils';
import { AuthActionsConstants } from '../../Auth/types';
import { createTableSearchReducer } from '../../TableSearch/reducers';
import { TableSearchStoreModule } from '../../TableSearch/types';
import {
  DoAppPromotion,
  DoAppMerchantPromotionsActionsConstants,
  DoAppMerchantPromotionsActionType,
  DoAppMerchantPromotionsState
} from './types';
import { normalizeDoAppMerchantPromotions } from '../../helpers';

const initialState: DoAppMerchantPromotionsState = {
  data: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: {
    page: 1,
    per: 10,
    q: null,
    total: 0,
    totalPages: 0
  },
  meta: {
    claims: {}
  },
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const doAppMerchantPromotionsPaginationReducer = createPaginationReducer<DoAppMerchantPromotionsState, DoAppPromotion>(
  PaginationStoreModule.DO_APP_MERCHANT_PROMOTIONS
);

const doAppMerchantPromotionsTableSearchReducer = createTableSearchReducer<
  DoAppPromotion,
  DoAppMerchantPromotionsState
>(TableSearchStoreModule.DO_APP_MERCHANT_PROMOTIONS);

export default function doAppMerchantPromotionsReducer(
  state = initialState,
  action: WithLogoutAction<DoAppMerchantPromotionsActionType>
) {
  switch (action.type) {
    case DoAppMerchantPromotionsActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case DoAppMerchantPromotionsActionsConstants.INDEX_SUCCESS: {
      const data = normalizeDoAppMerchantPromotions(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        data: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        },
        meta: {
          $set: action.payload.meta
        }
      });
    }

    case DoAppMerchantPromotionsActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        data: {
          entities: { $set: {} },
          result: { $set: [] }
        },
        meta: {
          claims: { $set: {} }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return doAppMerchantPromotionsTableSearchReducer(
        doAppMerchantPromotionsPaginationReducer(state, action),
        action
      ) as DoAppMerchantPromotionsState;
    }
  }
}
