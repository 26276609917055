import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Input, InputAdornment, InputLabel, TextField } from '@material-ui/core';
import Toast from '../../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../../Shared/StyledComponents';

type DoAppUpdateSubscriberCreditsProps = {
  open: boolean;
  credits: number;
  onCloseClick: () => void;
  onSubmit: (newCredits: number) => void;
};

const DoAppUpdateSubscriberCredits: FunctionComponent<DoAppUpdateSubscriberCreditsProps> = ({
  open,
  credits: initialCredits,
  onCloseClick,
  onSubmit
}) => {
  const { t } = useTranslation();
  const [credits, setCredits] = useState<string>((initialCredits / 100).toString());

  useEffect(() => {
    if (open) {
      setCredits((initialCredits / 100).toString());
    }
  }, [open, initialCredits]);

  const handleSubmit = () => {
    const parsedCredits = parseFloat(credits);

    if (isNaN(parsedCredits) || parsedCredits < 0) {
      Toast.error(t('pages.doApp.subscribers.invalidCredits'));
      return;
    }
    onSubmit(Math.round(parsedCredits * 100));
  };

  const handleCreditsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Allow only numbers and one dot
    const validValue = inputValue.match(/^\d*\.?\d*$/); // Regex allows numbers and a single dot
    if (validValue) {
      setCredits(inputValue);
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.doApp.subscribers.updateCredits')}
      okButtonText={t('common.update')}
      disableSubmitButton={credits === (initialCredits / 100).toString()}
      onCloseClick={onCloseClick}
      onSubmit={handleSubmit}
    >
      <Row>
        <TextField
          label={t('pages.doApp.subscribers.credits')}
          type="text"
          value={credits}
          onChange={handleCreditsChange}
          fullWidth
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
        />
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DoAppUpdateSubscriberCredits;
