import React, { FunctionComponent, useEffect, useState } from 'react';
import PageHeader from '../../Shared/Layout/PageHeader';
import { PageContainer, PageContentPaper } from '../PageStyledComponents';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Toast from '../../Shared/Toast/Toast';
import { storiesOperations } from '../../../store/Stories';
import { push } from 'connected-react-router';
import linksConstants from '../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Form, Formik, FormikProps } from 'formik';
import { AdminTextInput } from '../Admin/Shared/AdminFormInputs';
import * as Yup from 'yup';
import {
  CreateStoryValues,
  Story,
  StoryChannel,
  StoryChannelStatus,
  StoryChannelType,
  storyChannelTypes,
  StoryContentContributor,
  StoryEmailChannelPriority,
  StoryFieldName
} from '../../../store/Stories/types';
import { RouteComponentProps } from 'react-router';
import Loader from '../../Shared/Loading/Loader';
import { Range } from 'react-date-range';
import DateRangePickerComponent from './Partials/DateRangePickerComponent';
import { getFieldValue, storyIsOwnedOrSharedByReference } from './Utils/storyUtils';
import {
  addNegativeTimezoneOffset,
  createNetworkErrorObject,
  formatPublishDate,
  useTypedSelector
} from '../../../utils';
import SaveFormButton from './Partials/SaveFormButton';
import { hasPermission } from '../../../utils/permissions';
import { Alert } from '@material-ui/lab';
import { DefaultRoleName } from '../../../store/AdminRoles/types';
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { setAiGeneration } from '../../../store/Stories/actions';
import { CampaignProjectType } from '../../../store/Campaigns/types';
import ContentContributorTab from './ManageStoryPageTabs/ContentContributorTab';
import CampaignsTab from './ManageStoryPageTabs/CampaignsTab';
import CalendarEventTab from './ManageStoryPageTabs/CalendarEventTab';
import Channels from './Partials/Channels';
import ShareTab from './ManageStoryPageTabs/ShareTab';

type ManageStoryPageProps = RouteComponentProps<{ id: string }> & { location: { state: { from: string } } };

export const AntTabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
      '& .MuiTabs-flexContainer': {
        marginTop: '2px',
        '& button:first-child': {
          borderTopLeftRadius: '4px',
          boxShadow: '-2px -2px 4px -4px rgba(189, 189, 189, 1)'
        },
        '& button:last-child': {
          borderTopRightRadius: '4px',
          boxShadow: '2px -2px 4px -4px rgba(189, 189, 189, 1)'
        },
        '& button': {
          boxShadow: '0px -2px 4px -4px rgba(189, 189, 189, 1)'
        }
      }
    },
    indicator: {
      backgroundColor: theme.palette.secondary.main,
      height: '4px'
    }
  })
)(Tabs);

export const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      opacity: 1,
      padding: '6px 20px',
      '&:hover': {
        color: theme.palette.secondary.main
      },
      '&$selected': {
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium
      },
      '&:focus': {
        color: theme.palette.secondary.main
      }
    },
    selected: {}
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabProps {
  label: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%'
  },
  padding: {
    padding: theme.spacing(3)
  },
  tabs: {},
  alertMessage: {
    '& .MuiAlert-message': {
      width: '100%',
      whiteSpace: 'pre-wrap'
    }
  }
}));

const channelsOrder: StoryChannelType[] = [
  StoryChannelType.PRINT,
  StoryChannelType.EMAIL,
  StoryChannelType.FACEBOOK,
  StoryChannelType.INSTAGRAM,
  StoryChannelType.LINKEDIN,
  StoryChannelType.TWITTER,
  StoryChannelType.BLOG
];

const ManageStoryPage: FunctionComponent<ManageStoryPageProps> = ({ match, location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const storyId = match.params.id;
  const fromPath = location.state && location.state.from;
  const organization = useTypedSelector((state) => state.auth.organization);
  const role = useTypedSelector((state) => state.auth.role);
  const user = useTypedSelector((state) => state.auth.user);
  const aiGeneration = useTypedSelector((state) => state.stories.aiGeneration);
  const isContentContributor = role.name === DefaultRoleName.CONTENT_CONTRIBUTOR;

  const [expanded, setExpanded] = useState<StoryChannelType | false>(false);
  const [channelsData, setChannelsData] = useState<StoryChannel[]>([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [story, setStory] = useState<Story | null>(null);
  const [publicationPeriod, setPublicationPeriod] = useState<Range[]>([]);
  const [storyContributor, setStoryContributor] = useState<StoryContentContributor | null>(null);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [openInfobox, setOpenInfobox] = useState<boolean>(
    localStorage.getItem(`sc-infobox-hidden-${storyId}`) !== 'true'
  );
  const [calendarEvent, setCalendarEvent] = useState<boolean>(false);
  const [creatingCalendarEvent, setCreatingCalendarEvent] = useState<boolean>(false);
  const [calendarEventData, setCalendarEventData] = useState<StoryChannel | null>(null);
  const [calendarEventPeriod, setCalendarEventPeriod] = useState<Range[]>([]);

  const classes = useStyles();

  const [hideInfoboxForever, setHideInfoboxForever] = useState(false);

  const [tabValue, setTabValue] = React.useState(0);

  const handleStoryTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleInfoboxCb = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHideInfoboxForever(event.target.checked);
  };

  const handleDissmissInfobox = () => {
    if (hideInfoboxForever) {
      localStorage.setItem(`sc-infobox-hidden-${storyId}`, 'true');
    }
    setOpenInfobox(false);
  };

  useEffect(() => {
    if (match.params.id) {
      fetchStoryData();
      if (openInfobox && isContentContributor) {
        fetchStoryContributorData();
      }
    } else {
      setInitialLoading(false);
    }
  }, []);

  useEffect(() => {
    if (expanded !== false) {
      fetchStoryData();
    }
  }, [expanded]);

  const fetchStoryContributorData = async () => {
    try {
      const contributor = await storiesOperations.showStoryContributor(match.params.id, user.id);
      setStoryContributor(contributor);
    } catch (e) {
      Toast.error(t('notifications.story.cannotFetchStoryContributor'));
    }
  };

  const fetchStoryData = async () => {
    try {
      const story = await storiesOperations.show(match.params.id);
      setStory(story);

      if (story.aiGenerationInProgress) {
        dispatch(setAiGeneration(story.id));
      } else {
        dispatch(setAiGeneration(''));
      }

      setChannelsData(story.channels.filter((cd) => cd.type !== StoryChannelType.CALENDAR_EVENT));

      const calendarEvent = story.channels.find((channel) => channel.type === StoryChannelType.CALENDAR_EVENT);
      if (calendarEvent) {
        setCalendarEventData(calendarEvent);
        setCalendarEvent(true);

        const eventStartDate = getFieldValue(calendarEvent, StoryFieldName.START_DATE);
        const eventEndDate = getFieldValue(calendarEvent, StoryFieldName.END_DATE);
        setCalendarEventPeriod([
          {
            startDate: eventStartDate
              ? addNegativeTimezoneOffset(eventStartDate)
              : addNegativeTimezoneOffset(story.publicationDateFrom),
            endDate: eventEndDate
              ? addNegativeTimezoneOffset(eventEndDate)
              : addNegativeTimezoneOffset(story.publicationDateTo),
            key: 'selection'
          }
        ]);
      }
      setPublicationPeriod([
        {
          startDate: addNegativeTimezoneOffset(story.publicationDateFrom),
          endDate: addNegativeTimezoneOffset(story.publicationDateTo),
          key: 'selection'
        }
      ]);
    } catch (e) {
      const error = createNetworkErrorObject(e);

      switch (error.statusCode) {
        case 401:
          return Toast.info(t('notifications.common.loginFirst'));
        default:
          return Toast.error(t('notifications.story.cannotFetchStory'));
      }
    } finally {
      setInitialLoading(false);
    }
  };

  if (!story) {
    return null;
  }

  const handleChannelChange = (channel: StoryChannelType) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? channel : false);
  };

  const updateStory = async (values: CreateStoryValues, showToast: boolean) => {
    try {
      if (storyId && publicationPeriod && publicationPeriod[0].startDate && publicationPeriod[0].endDate) {
        await storiesOperations.update(
          storyId,
          values.name,
          moment(publicationPeriod[0].startDate).format('YYYY-MM-DD'),
          moment(publicationPeriod[0].endDate).format('YYYY-MM-DD')
        );
        if (showToast) {
          Toast.success(t('notifications.story.storySaved'));
        }
      }
    } catch (e) {
      Toast.error(t('notifications.story.errorSave'));
    }
  };

  const backButton = {
    onClick: () => {
      if (fromPath === linksConstants.STORY.CONTENT_CONTRIBUTORS(story.id)) {
        dispatch(push(linksConstants.STORY.CONTENT_CONTRIBUTORS(storyId)));
      } else if (fromPath === linksConstants.DASHBOARD.SHARING_CENTER) {
        dispatch(push(linksConstants.DASHBOARD.SHARING_CENTER));
      } else if (fromPath === linksConstants.DASHBOARD.DESIGN_PROJECTS) {
        dispatch(push(linksConstants.DASHBOARD.DESIGN_PROJECTS));
      } else {
        dispatch(push(linksConstants.STORY.INDEX));
      }
    },
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const handleCalendarEventChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCalendarEvent(event.target.checked);
    if (event.target.checked) {
      setCreatingCalendarEvent(true);
      await storiesOperations.createChannel(storyId, StoryChannelType.CALENDAR_EVENT, []);
      await fetchStoryData();
      setCreatingCalendarEvent(false);
    } else if (calendarEventData) {
      await storiesOperations.removeChannel(storyId, calendarEventData.id);
    }
  };

  const isOwnerOfStory = storyIsOwnedOrSharedByReference(story, organization, aiGeneration);

  const renderBasicDataForm = (props: FormikProps<CreateStoryValues>) => (
    <Form>
      <PageContentPaper style={{ margin: 0, padding: '1rem 2rem 2rem 2rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <AdminTextInput
              t={t}
              name="name"
              section="story"
              autoFocus
              disabled={!isOwnerOfStory || !hasPermission(role, ['storiesEdit'])}
            />
          </Grid>
          <Grid item xs={3}>
            <DateRangePickerComponent
              range={publicationPeriod}
              setRange={(period) => {
                setPublicationPeriod(period);
                props.submitForm();
              }}
              disabled={!isOwnerOfStory || !hasPermission(role, ['storiesEdit'])}
              label={t('pages.story.inputs.publicationWeek')}
            />
          </Grid>
          <Grid item xs={5} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <SaveFormButton
              props={props}
              saveForm={async () => {
                setShowToast(true);
                await props.submitForm();
                setShowToast(false);
              }}
              disabled={!isOwnerOfStory || !hasPermission(role, ['storiesEdit'])}
            />
          </Grid>
        </Grid>
      </PageContentPaper>
    </Form>
  );

  const setChannelStatus = async (
    channel: StoryChannel,
    status: StoryChannelStatus,
    muteToast = false,
    prevStatus?: StoryChannelStatus,
    priority?: StoryEmailChannelPriority
  ) => {
    try {
      await storiesOperations.updateChannelStatus(storyId, channel.id, status, priority);

      if (!muteToast) {
        switch (status) {
          case StoryChannelStatus.APPROVED:
            if (prevStatus === StoryChannelStatus.SCHEDULED) {
              Toast.success(t('notifications.story.storyScheduleCancelled'));
            } else if (channel.type === StoryChannelType.CALENDAR_EVENT) {
              Toast.success(t('notifications.story.calendarEventPublished'));
            } else {
              Toast.success(t('notifications.story.storyApproved'));
            }
            break;
          case StoryChannelStatus.REJECTED:
            Toast.success(t('notifications.story.storyRejected'));
            break;
          case StoryChannelStatus.SCHEDULED:
            Toast.success(t('notifications.story.storyScheduled'));
            break;
          case StoryChannelStatus.READY_TO_REVIEW:
            Toast.success(t('notifications.story.sentForApproval'));
            break;
          case StoryChannelStatus.IDLE:
            if (channel.type === StoryChannelType.CALENDAR_EVENT) {
              Toast.success(t('notifications.story.calendarEventUnpublished'));
            } else {
              Toast.success(t('notifications.story.storyIdled'));
            }
            break;
          default:
            Toast.success(t('notifications.story.storySaved'));
            break;
        }
      }

      fetchStoryData();
    } catch (e) {
      Toast.error(t('notifications.story.errorSave'));
    }
  };

  return (
    <PageContainer style={{ padding: '2rem 3rem' }}>
      <PageHeader title={t('pages.story.manageStoryTitle')} leftActionButtons={[backButton]} />
      <Box marginTop={4}>
        {initialLoading && <Loader />}
        {!initialLoading && (
          <>
            <Box display="flex" alignItems="center" justifyContent="flex-end" marginBottom="-30px">
              {t('pages.story.author')}:&nbsp;
              <AssignmentIndIcon titleAccess={t('pages.story.author')} fontSize="small" />
              <div style={{ fontSize: '13px', marginLeft: '3px' }}>
                {story.createdBy ? (
                  <Tooltip title={story.createdBy.email || ''} placement="bottom" arrow>
                    <div>{story.createdBy.fullName}</div>
                  </Tooltip>
                ) : (
                  'N/A'
                )}
              </div>
              &nbsp;@&nbsp;{formatPublishDate(story.createdAt)}
            </Box>

            <div className={classes.root}>
              <div className={classes.tabs}>
                <AntTabs value={tabValue} onChange={handleStoryTabChange}>
                  <AntTab label={t('pages.story.tabs.story')} />
                  <AntTab label={t('pages.story.tabs.calendarEvent')} />
                  {organization && organization.enableCampaigns && <AntTab label={t('pages.story.tabs.campaigns')} />}
                  <AntTab label={t('pages.story.tabs.contentContributors')} />
                  <AntTab label={t('pages.story.tabs.share')} />
                </AntTabs>
              </div>
            </div>
            {tabValue === 0 && (
              <Formik
                initialValues={{
                  name: story?.name || ''
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required()
                })}
                onSubmit={(data) => updateStory(data, showToast)}
              >
                {renderBasicDataForm}
              </Formik>
            )}
            {tabValue === 1 && (
              <CalendarEventTab
                story={story}
                calendarEvent={calendarEvent}
                creatingCalendarEvent={creatingCalendarEvent}
                calendarEventData={calendarEventData}
                handleCalendarEventChange={handleCalendarEventChange}
                role={role}
                isOwnerOfStory={isOwnerOfStory}
                calendarEventPeriod={calendarEventPeriod}
                setCalendarEventPeriod={setCalendarEventPeriod}
                setChannelStatus={setChannelStatus}
              />
            )}
            {tabValue === 2 && organization && organization.enableCampaigns && (
              <CampaignsTab storyId={story.id} role={role} user={user} type={CampaignProjectType.STORY} />
            )}
            {tabValue === 3 && <ContentContributorTab storyId={story.id} />}
            {tabValue === 4 && <ShareTab story={story} />}
          </>
        )}
        {isContentContributor && storyContributor && (
          <Collapse in={openInfobox}>
            <Alert variant="outlined" severity="info" className={classes.alertMessage}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('pages.story.infobox.defaultMessage')
                }}
              />
              {storyContributor.infobox && (
                <div style={{ marginTop: 15 }}>
                  <strong>{t('pages.story.infobox.additionalInstructions')}:</strong>
                  <div>{storyContributor.infobox}</div>
                </div>
              )}
              <Divider style={{ margin: '0.4rem 0' }} />
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <FormControlLabel
                  control={<Checkbox checked={hideInfoboxForever} onChange={handleInfoboxCb} size="small" />}
                  label={<Typography variant="body2">{t('pages.story.infobox.dontShowAgain')}</Typography>}
                />
                <Button size="small" color="primary" variant="contained" onClick={handleDissmissInfobox}>
                  {t('pages.story.infobox.close')}
                </Button>
              </Box>
            </Alert>
          </Collapse>
        )}
        <PageContentPaper style={{ padding: '1rem 2rem' }}>
          <Typography variant="h6" gutterBottom>
            {t('pages.story.channelsTitle')}
          </Typography>
          <Divider />
          <Channels
            role={role}
            organization={organization}
            channelsOrder={channelsOrder}
            channelsData={channelsData}
            storyId={storyId}
            fetchStoryData={fetchStoryData}
            handleChannelChange={handleChannelChange}
            setChannelStatus={setChannelStatus}
            isOwnerOfStory={isOwnerOfStory}
          />
        </PageContentPaper>
      </Box>
    </PageContainer>
  );
};

export default ManageStoryPage;
