import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';
import { StoryChannel, StoryChannelType } from '../../../../store/Stories/types';
import { Box, Button, TextField } from '@material-ui/core';
import Toast from '../../../Shared/Toast/Toast';
import { storiesOperations } from '../../../../store/Stories';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createNetworkErrorObject } from '../../../../utils';
import { Autocomplete } from '@material-ui/lab';
import { getSavedValuesFromLocalStorage, saveValueToLocalStorage } from '../Utils/storyUtils';

type GenerateAiImageWindowProps = {
  channelType: StoryChannelType;
  channelData: StoryChannel;
  open: boolean;
  onCloseClick: () => void;
  onGenerationDone: () => void;
  fullScreenOnMobile: boolean;
  userId: string;
};

const GenerateAiImageWindow: FunctionComponent<GenerateAiImageWindowProps> = ({
  channelType,
  channelData,
  onCloseClick,
  onGenerationDone,
  open,
  userId
}) => {
  const { t } = useTranslation();
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>('');

  const LOCAL_STORAGE_KEY = `image-ai-prompt-${userId}`;
  const MAX_VALUES_TO_SAVE = 10;

  const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPrompt(e.target.value);
  };

  // Create an array of suggestions from the localStorage values
  const suggestions = getSavedValuesFromLocalStorage(LOCAL_STORAGE_KEY);

  const generateAiImage = async () => {
    setIsGenerating(true);
    try {
      await storiesOperations.generateAiImage(channelData.storyId, channelData.id, prompt);
      saveValueToLocalStorage(prompt, LOCAL_STORAGE_KEY, MAX_VALUES_TO_SAVE);
      onGenerationDone();
    } catch (e) {
      const error = createNetworkErrorObject(e);

      switch (error.message) {
        case 'content_policy_violation':
          Toast.error(
            <>
              {t('pages.story.errorGeneratingAiImagePolicy')}{' '}
              <a href="https://openai.com/policies/usage-policies" target="_blank" rel="noopener noreferrer">
                {t('pages.story.policyPageName')}
              </a>
              .
            </>
          );
          break;
        default:
          Toast.error(t('pages.story.errorGeneratingAiImage'));
          break;
      }
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={`${t('pages.story.generateAiImage')} for ${t(`pages.story.channels.${channelType}`)}`}
      onCloseClick={onCloseClick}
      hideActionButtons
      maxWidth="md"
      disableBackdropClick={isGenerating}
      disableOnlyXCloseButton={isGenerating}
    >
      <Row>
        <Autocomplete
          id="ai-image-prompt-value"
          freeSolo
          disableClearable
          options={suggestions}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              variant="outlined"
              label={t('pages.story.windows.generateAiImage.enterPrompt')}
              onChange={handlePromptChange}
              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
        <Box display="flex" alignItems="center" justifyContent="space-between" marginTop={1}>
          <Button color={'secondary'} variant={'outlined'} size="medium" onClick={onCloseClick} disabled={isGenerating}>
            {t('common.close')}
          </Button>
          <Button
            color={'secondary'}
            variant={'contained'}
            size="medium"
            onClick={generateAiImage}
            disabled={isGenerating || prompt.trim().length === 0}
          >
            {isGenerating && <CircularProgress size={11} color="secondary" style={{ marginRight: 6 }} />}
            {isGenerating
              ? t('pages.story.windows.generateAiImage.generating')
              : t('pages.story.windows.generateAiImage.generate')}
          </Button>
        </Box>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default GenerateAiImageWindow;
