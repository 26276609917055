import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createNetworkErrorObject, isStory, useTypedSelector } from '../../../utils';
import { usePaginationWatch } from '../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../Shared/DataTable/useTableSearchWatch';
import { PageContainer, PageContent } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import DataTable from '../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import { TableSearchStoreModule } from '../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../store/Pagination/types';
import linksConstants from '../../../config/app/linksConstants';
import { hasPermission } from '../../../utils/permissions';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { emailProjectsOperations } from '../../../store/EmailProjects';
import { EmailProject } from '../../../store/EmailProjects/types';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { RouteComponentProps } from 'react-router';
import { Box, Button, FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { PrintProject } from '../../../store/PrintProjects/types';
import { Story } from '../../../store/Stories/types';
import { printProjectsOperations } from '../../../store/PrintProjects';
import { storiesOperations } from '../../../store/Stories';
import { campaignsOperations } from '../../../store/Campaigns';
import Toast from '../../Shared/Toast/Toast';
import { Campaign, CampaignProjectType } from '../../../store/Campaigns/types';
import ProjectNameCell from '../Dashboard/WorkInProgressTable/ProjectNameCell';
import { AnyProject } from '../../../store/Dashboard/types';
import StoryNameCell from '../Stories/Partials/StoryNameCell';
import { Alert } from '@material-ui/lab';
import styled from 'styled-components';

type CampaignProjectsPageProps = RouteComponentProps<{ id: string }>;

const CampaignProjectsPage: FunctionComponent<CampaignProjectsPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const campaignId = match.params.id;
  const [projectType, setProjectType] = useState<CampaignProjectType>(CampaignProjectType.EMAIL);
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const role = useTypedSelector((state) => state.auth.role);
  const user = useTypedSelector((state) => state.auth.user);

  useEffect(() => {
    if (match.params.id) {
      fetchCampaignData();
    } else {
      setLoading(false);
    }
  }, []);

  const fetchCampaignData = async () => {
    try {
      const campaign = await campaignsOperations.show(match.params.id);
      setCampaign(campaign);
    } catch (e) {
      const error = createNetworkErrorObject(e);

      switch (error.statusCode) {
        case 401:
          return Toast.info(t('notifications.common.loginFirst'));
        default:
          return Toast.error(t('notifications.campaigns.cannotFetchCampaign'));
      }
    } finally {
      setLoading(false);
    }
  };

  const projects = useTypedSelector((state) =>
    projectType === CampaignProjectType.EMAIL
      ? state.emailProjects.projects
      : projectType === CampaignProjectType.PRINT
      ? state.printProjects.projects
      : state.stories.list
  );
  const isFailed = useTypedSelector((state) =>
    projectType === CampaignProjectType.EMAIL
      ? state.emailProjects.index.isFailed
      : projectType === CampaignProjectType.PRINT
      ? state.printProjects.index.isFailed
      : state.stories.index.isFailed
  );
  const isLoading = useTypedSelector((state) =>
    projectType === CampaignProjectType.EMAIL
      ? state.emailProjects.index.isLoading
      : projectType === CampaignProjectType.PRINT
      ? state.printProjects.index.isLoading
      : state.stories.index.isLoading
  );
  const pagination = useTypedSelector((state) =>
    projectType === CampaignProjectType.EMAIL
      ? state.emailProjects.pagination
      : projectType === CampaignProjectType.PRINT
      ? state.printProjects.pagination
      : state.stories.pagination
  );
  const tableSearch = useTypedSelector((state) =>
    projectType === CampaignProjectType.EMAIL
      ? state.emailProjects.tableSearch
      : projectType === CampaignProjectType.PRINT
      ? state.printProjects.tableSearch
      : state.stories.tableSearch
  );

  usePaginationWatch(pagination, [
    projectType === CampaignProjectType.EMAIL
      ? emailProjectsOperations.getUserEmailProjects
      : projectType === CampaignProjectType.PRINT
      ? printProjectsOperations.getUserPrintProjects
      : storiesOperations.index
  ]);
  useTableSearchWatch<EmailProject | PrintProject | Story>(tableSearch, [
    projectType === CampaignProjectType.EMAIL
      ? emailProjectsOperations.getUserEmailProjects
      : projectType === CampaignProjectType.PRINT
      ? printProjectsOperations.getUserPrintProjects
      : storiesOperations.index
  ]);

  const handleProjectTypeChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    setProjectType(event.target.value as CampaignProjectType);
    switch (event.target.value) {
      case CampaignProjectType.EMAIL:
        await dispatch(emailProjectsOperations.getUserEmailProjects());
        break;
      case CampaignProjectType.PRINT:
        await dispatch(printProjectsOperations.getUserPrintProjects());
        break;
      default:
        await dispatch(storiesOperations.index());
        break;
    }
  };

  const backButton = {
    onClick: () => dispatch(push(linksConstants.CAMPAIGNS.EDIT(campaignId))),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const assignToCampaign = async (itemId: string) => {
    try {
      if (campaignId) {
        await campaignsOperations.assign(campaignId, itemId, projectType);
        dispatch(push(linksConstants.CAMPAIGNS.EDIT(campaignId)));
        Toast.success(t('notifications.campaigns.projectAssigned'));
      }
    } catch (e) {
      Toast.error(t('notifications.campaigns.errorOperation'));
    }
  };

  const projectTypeSelect = (
    <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop={1}>
      <Typography variant="subtitle1" style={{ marginRight: 10 }}>
        {t('pages.campaigns.projectList.type')}:
      </Typography>
      <FormControl size="small">
        <Select
          labelId="project-type-select-label"
          id="project-type-select"
          value={projectType}
          onChange={handleProjectTypeChange}
          variant="outlined"
          style={{ background: '#fff' }}
        >
          <MenuItem value={CampaignProjectType.EMAIL}>{t('pages.campaigns.projectList.email')}</MenuItem>
          <MenuItem value={CampaignProjectType.PRINT}>{t('pages.campaigns.projectList.print')}</MenuItem>
          <MenuItem value={CampaignProjectType.STORY}>{t('pages.campaigns.projectList.story')}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );

  return (
    <PageContainer>
      <PageHeader
        title={t('pages.campaigns.projectList.title')}
        leftActionButtons={[backButton]}
        rightContent={projectTypeSelect}
      />
      <PageContent style={{ marginTop: 20 }}>
        <DataTable<EmailProject | PrintProject | Story, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'name',
              sortable: true,
              style: { width: '100%' },
              render: (project) =>
                isStory(project) ? (
                  <StoryNameCell story={project as Story} />
                ) : (
                  <ProjectNameCell project={project as AnyProject} user={user} role={role} />
                )
            },
            {
              name: 'actions',
              style: { whiteSpace: 'nowrap' },
              render: (project) => (
                <TableActionButtonsContainer>
                  {!loading && (
                    <>
                      {campaign?.projects.find((p) => p.id === project.id) ? (
                        <StyledAlert severity="success" variant="outlined">
                          {t('pages.campaigns.projectList.alreadyAdded')}
                        </StyledAlert>
                      ) : (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => assignToCampaign(project.id)}
                          size="small"
                          disabled={!hasPermission(role, ['campaignsEdit'])}
                        >
                          {t('pages.campaigns.projectList.addToCampaign')}
                        </Button>
                      )}
                    </>
                  )}
                </TableActionButtonsContainer>
              )
            }
          ]}
          tPath={'pages.campaigns.projectList.table'}
          data={projects}
          tableSearchProps={{
            tableSearch,
            module:
              projectType === CampaignProjectType.EMAIL
                ? TableSearchStoreModule.EMAIL_PROJECTS
                : projectType === CampaignProjectType.PRINT
                ? TableSearchStoreModule.PRINT_PROJECTS
                : TableSearchStoreModule.STORY
          }}
          paginationProps={{
            pagination,
            module:
              projectType === CampaignProjectType.EMAIL
                ? PaginationStoreModule.EMAIL_PROJECTS
                : projectType === CampaignProjectType.PRINT
                ? PaginationStoreModule.PRINT_PROJECTS
                : PaginationStoreModule.STORY
          }}
          isFailed={isFailed}
          isLoading={loading && isLoading}
        />
      </PageContent>
    </PageContainer>
  );
};

export const StyledAlert = styled(Alert)`
  .MuiAlert-icon {
    font-size: 16px;
  }

  .MuiAlert-message {
    padding: 5px 0;
  }

  padding: 0 6px;
`;

export default CampaignProjectsPage;
