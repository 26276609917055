export interface ExtendedCSSProperties extends React.CSSProperties {
  [key: string]: string | number | undefined;
}

function convertEmRemToPx(emRemValue: string): string {
  // Assuming the base font size is 16 pixels
  const baseFontSize = 16;

  // Convert em/rem to pixels
  return (parseFloat(emRemValue) * baseFontSize).toString() + 'px';
}

function translatePaddingValue(paddingValue: string | undefined): string {
  // Check if the padding value contains 'em' or 'rem
  if (paddingValue && ['em', 'rem'].some((v) => paddingValue.includes(v))) {
    // Extract the numeric value from the padding property
    const emNumericValue: number = parseFloat(paddingValue);

    // Calculate the equivalent pixel value
    const pxValue: string = convertEmRemToPx(emNumericValue.toString());

    // Return the pixel value
    return pxValue;
  }

  // If the padding value doesn't contain 'em' or 'rem', return it as is
  return paddingValue || '';
}

function translateShorthandPadding(shorthandValue: string): string {
  // Split the shorthand value into individual values
  const values: string[] = shorthandValue.split(/\s+/);

  // Translate each value
  const translatedValues: string[] = values.map(translatePaddingValue);

  // Return the translated shorthand value
  return translatedValues.join(' ');
}

export function translatePaddingProperty(propertyName: string, style?: ExtendedCSSProperties): string {
  // Check if the specific padding property is available, if not, check shorthand padding
  if (style && style[propertyName] && typeof style[propertyName] === 'string') {
    return translatePaddingValue(style[propertyName] as string);
  } else if (style && style.padding && typeof style.padding === 'string') {
    // If the specific property is not available, check shorthand padding
    const shorthandPaddingValue: string = translateShorthandPadding(style.padding as string);
    // Extract the specific property value from the shorthand
    const shorthandValues: string[] = shorthandPaddingValue.split(/\s+/);

    switch (shorthandValues.length) {
      case 2:
        if (propertyName === 'paddingTop' || propertyName === 'paddingBottom') {
          return shorthandValues[0] || '0';
        }
        return shorthandValues[1] || '0';
      case 3:
        if (propertyName === 'paddingTop') {
          return shorthandValues[0] || '0';
        } else if (propertyName === 'paddingLeft' || propertyName === 'paddingRight') {
          return shorthandValues[1] || '0';
        }
        return shorthandValues[2] || '0';
      case 4:
        if (propertyName === 'paddingTop') {
          return shorthandValues[0] || '0';
        } else if (propertyName === 'paddingRight') {
          return shorthandValues[1] || '0';
        } else if (propertyName === 'paddingBottom') {
          return shorthandValues[2] || '0';
        }
        return shorthandValues[3] || '0';
      default:
        return shorthandValues[0] || '0';
    }
  }

  // If neither the specific property nor shorthand padding is available, return 0
  return '0';
}
