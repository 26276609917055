import { createOrderArray, httpRequest } from '../../../utils';
import { DoAppRunningPromotionReportsIndexResponse, DoAppRunningPromotionsIndexResponse } from './types';
import { doAppReportsRequests } from './index';

export const indexRunningPromotionsRaw = (page: number, per: number, q: string | null, order: any) => {
  return httpRequest.runRequest<DoAppRunningPromotionsIndexResponse>(
    doAppReportsRequests.indexRunningPromotions({
      requestParams: {
        page: page,
        per: per,
        q: q,
        order: createOrderArray(order)
      }
    })
  );
};

export const indexRunningPromotionReportsRaw = (page: number, per: number, q: string | null, order: any) => {
  return httpRequest.runRequest<DoAppRunningPromotionReportsIndexResponse>(
    doAppReportsRequests.indexRunningPromotionReports({
      requestParams: {
        page: page,
        per: per,
        q: q,
        order: createOrderArray(order)
      }
    })
  );
};

export const removeReportWithEntries = (id: string) => {
  return httpRequest.runRequest<void>(
    doAppReportsRequests.removeReportWithEntries({
      requestParams: {
        id: id
      }
    })
  );
};
