import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../../Shared/StyledComponents';
import { ProjectModelComponent } from '../../types';

type DeleteComponentWindowProps = {
  open: boolean;
  component: ProjectModelComponent | null;
  onCloseClick: () => void;
  onFormSubmit: () => void;
  fullScreenOnMobile: boolean;
};

const DeleteComponentWindow: FunctionComponent<DeleteComponentWindowProps> = ({
  component,
  onCloseClick,
  onFormSubmit,
  open
}) => {
  const { t } = useTranslation();

  if (!component) return null;

  return (
    <ModalConfirmationWindow
      open={open}
      header={'Delete Component'}
      okButtonText={"I'm sure, remove this component"}
      onCloseClick={onCloseClick}
      onSubmit={onFormSubmit}
    >
      <Row>Are you sure you want to remove that component?</Row>
      <Row>
        <Strong>{component.type}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteComponentWindow;
