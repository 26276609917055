import React, { FunctionComponent } from 'react';
import { SplashAreaContainer } from '../AuthStyledComponents';
import DefaultLogoImage from '../../../../assets/epub-logo-white.png';
import NhmLogoImage from '../../../../assets/storycreator_logo.png';
import BtLLogoImage from '../../../../assets/btl-story-creator-logo.png';
import NhmLogo from '../../../../assets/nhm_logo.png';
import styled from 'styled-components';
import { ApplicationInfoSplash, PoweredBy, Version } from '../../../Shared/StyledComponents';
import * as pkg from '../../../../../package.json';
import { useTypedSelector } from '../../../../utils';
import { isTheme } from '../../../../utils/environment';
import { APP_THEME } from '../../../../typings/environment';

type SplashAreaProps = {};

const SplashArea: FunctionComponent<SplashAreaProps> = () => {
  const version = useTypedSelector((state) => state.auth.version);

  const getThemeLogo = () => {
    if (isTheme(APP_THEME.NHM)) {
      return <NhmLogoImg src={NhmLogoImage} />;
    } else if (isTheme(APP_THEME.BTL)) {
      return <BtLLogoImg src={BtLLogoImage} />;
    } else {
      return <DefaultLogoImg src={DefaultLogoImage} />;
    }
  };

  return (
    <SplashAreaContainer>
      {getThemeLogo()}
      <ApplicationInfoSplash>
        {!isTheme(APP_THEME.BTL) ? (
          <PoweredBy>Powered by {isTheme(APP_THEME.NHM) ? <NhmLogoImg src={NhmLogo} /> : 'StoryCreator'}</PoweredBy>
        ) : (
          ''
        )}
        <Version>
          {pkg.version} | {version}
        </Version>
      </ApplicationInfoSplash>
    </SplashAreaContainer>
  );
};

const BtLLogoImg = styled.img`
  padding: 3em 2em 0 2em;
  box-sizing: content-box;
  max-width: 80%;
  max-height: 200px;
`;

const NhmLogoImg = styled.img`
  padding: 3em 2em 0 2em;
  box-sizing: content-box;
  max-width: 80%;
  max-height: 200px;
`;

const DefaultLogoImg = styled.img`
  padding: 3em;
  box-sizing: content-box;
  max-width: 70%;
  max-height: 200px;
`;

export default SplashArea;
