import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import {
  AdminCreateOrganizationValues,
  AdminUpdateOrganizationValues,
  IndexAdminOrganizationsRequestParams
} from './types';

export const index = (settings: RequestSettings<IndexAdminOrganizationsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/organizations?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const getAll = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/organizations/all'
  }
});

export const show = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/organizations/:id'
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{}, AdminCreateOrganizationValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/organizations'
  },
  payload: settings.requestPayload
});

export const update = (settings: RequestSettings<{}, AdminUpdateOrganizationValues>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/organizations/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteOrganization = (settings: RequestSettings<{ id: string }>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/organizations/:id'
  },
  params: settings.requestParams
});

export const addKeywordToOrganization = (
  settings: RequestSettings<{ organizationId: string }, { keywordId: string }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/organizations/:organizationId/keywords'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const addKeywordGroupToOrganization = (
  settings: RequestSettings<{ organizationId: string }, { keywordGroupId: string }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/organizations/:organizationId/keyword_groups'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeKeywordFromOrganization = (
  settings: RequestSettings<{ organizationId: string }, { keywordId: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/organizations/:organizationId/keywords'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeKeywordGroupFromOrganization = (
  settings: RequestSettings<{ organizationId: string }, { keywordGroupId: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/organizations/:organizationId/keyword_groups'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});
