import React, { FunctionComponent } from 'react';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { DesignerStory as DesignerStoryType } from '../../../../../../../store/PrintProjects/types';
import { ItemTypes } from '../../../types';
import { useDrag } from 'react-dnd';
import { stripHtml } from '../../../Utils';

type DesignerStoryProps = {
  story: DesignerStoryType;
};

const DesignerStory: FunctionComponent<DesignerStoryProps> = ({ story }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.DESIGNER_STORY, designerStory: story },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0.4 : 1;

  return (
    <Wrapper ref={drag} style={{ opacity }}>
      <Actions>
        <IconButton style={{ cursor: 'move' }}>
          <OpenWithIcon />
        </IconButton>
      </Actions>
      <Content>
        {story.images.length > 0 && (
          <StoryImageWrapper>
            <StoryImage src={story.images[0].url} />
          </StoryImageWrapper>
        )}
        <StoryTextWrapper>
          {story.title && (
            <StoryHeadline title={stripHtml(story.title)} dangerouslySetInnerHTML={{ __html: story.title }} />
          )}
          {story.body && <StoryBody title={stripHtml(story.body)} dangerouslySetInnerHTML={{ __html: story.body }} />}
        </StoryTextWrapper>
      </Content>
    </Wrapper>
  );
};

const StoryImageWrapper = styled.div``;
const StoryTextWrapper = styled.div`
  padding: 0 10px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
`;

const StoryImage = styled.div<{ src: string }>`
  width: 50px;
  height: 50px;
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
`;
const StoryHeadline = styled.div`
  font-weight: bold;
  font-size: 0.9em;
  -webkit-line-clamp: 3;

  a {
    pointer-events: none;
  }
`;
const StoryBody = styled.div`
  padding-top: 6px;
  font-size: 0.8em;
  -webkit-line-clamp: 2;

  a {
    pointer-events: none;
  }
`;

const Wrapper = styled.div`
  cursor: move;
  padding: 1em 0.5em 0 0.5em;
  display: flex;
  align-items: center;
  width: 100%;
  word-break: break-word;
`;

const Actions = styled.div``;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

export default DesignerStory;
