import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { EmailProjectAttachment } from '../../../../store/EmailProjects/types';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import Loader from '../../../Shared/Loading/Loader';
import { Alert } from '@material-ui/lab';
import GetAppIcon from '@material-ui/icons/GetApp';

type EmailAttachmentListProps = {
  projectId: string;
};

const EmailAttachmentList: FunctionComponent<EmailAttachmentListProps> = ({ projectId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<EmailProjectAttachment[]>([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await emailProjectsOperations.getAttachments(projectId);
      setAttachments(response.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: 0 }}>
        <Typography>{t('emailAttachments.projectDetails.attachments')}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        {loading && <Loader />}
        {!loading && (
          <>
            {attachments.length === 0 && (
              <Alert severity="info" style={{ width: '100%' }}>
                {t('emailAttachments.projectDetails.noAttachments')}
              </Alert>
            )}
            {attachments.length > 0 && (
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('emailAttachments.projectDetails.filename')}</TableCell>
                      <TableCell align="right">{t('emailAttachments.projectDetails.downloads')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attachments.map((attachment) => (
                      <TableRow key={attachment.id}>
                        <TableCell component="th" scope="row">
                          <Link
                            href={`${process.env.REACT_APP_API_HOST}/api/v1/channel/email/downloads/attachment/${attachment.id}`}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <GetAppIcon style={{ width: '16px', height: '16px' }} />
                            {attachment.filename}
                          </Link>
                        </TableCell>
                        <TableCell align="right">{attachment.downloads}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default EmailAttachmentList;
