import styled from 'styled-components';
import FormTextInput from '../../Shared/Forms/FormTextInput';
import { withTheme } from '@material-ui/core';
import { InputLabel } from '../../Shared/StyledComponents';
import FormSelectTextInput from '../../Shared/Forms/FormSelectTextInput';
import FormAutocompleteInput from '../../Shared/Forms/FormAutocompleteInput';
import FormCheckboxInput from '../../Shared/Forms/FormCheckboxInput';

export const FilterHeader = styled.div`
  color: #014b72;
  font-size: 1rem;
`;

export const AdminInput = styled(FormTextInput)`
  .MuiInput-root {
    border-radius: 6px;
    border: 1px solid #e6dbda;
    background-color: #fff;
    padding: 0.2rem 0.5rem;
  }

  .MuiInput-underline:before {
    border-bottom: 0;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }

  input {
    font-size: 1rem;
  }
`;

export const AdminSelect = styled(FormSelectTextInput)`
  .MuiInput-root {
    border-radius: 6px;
    border: 1px solid #e6dbda;
    background-color: #fff;
    padding: 0.2rem 0.5rem;
  }

  .MuiInput-underline:before {
    border-bottom: 0;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }

  input {
    font-size: 1rem;
  }
`;

export const AdminAutocomplete = styled(FormAutocompleteInput)`
  .MuiInput-root {
    border-radius: 6px;
    border: 1px solid #e6dbda;
    background-color: #fff;
    padding: 0.2rem 0.5rem;
  }

  .MuiInput-underline:before {
    border-bottom: 0;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }

  input {
    font-size: 1rem;
  }
`;

export const AdminCheckbox = styled(FormCheckboxInput)`
  .MuiInput-root {
    padding: 0.2rem 0.5rem;
  }

  input {
    font-size: 1rem;
  }
`;

export const AdminInputLabel = withTheme(styled(InputLabel)`
  margin: 1rem 0 0.3rem;
  font-size: 1rem;
`);
