import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import SharingCenterPage from './SharingCenterPage';

const SharingCenterRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.DASHBOARD.SHARING_CENTER,
    exact: true,
    name: 'sharing_center',
    public: false,
    show: true,
    component: SharingCenterPage,
    scope: []
  }
];

export default SharingCenterRoutes;
