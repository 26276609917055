import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Strong } from '../../../Shared/StyledComponents';
import { AnyProject } from '../../../../store/Dashboard/types';
import { limitString } from '../../../../utils';
import moment from 'moment';
import { SubTitle } from './StyledProjectRowComponents';

type LatestNoteCellProps = {
  project: AnyProject;
};

const LatestNoteCell: FunctionComponent<LatestNoteCellProps> = ({ project }) => {
  return (
    <>
      <ProjectNoteWrapper>
        {!project.notes.length && <>N/A</>}
        {project.notes.length > 0 && (
          <>
            <div>
              <Strong>
                {project.notes[0].createdBy.firstName} {project.notes[0].createdBy.lastName}{' '}
              </Strong>
            </div>
            <ProjectNoteContent title={project.notes[0].content}>
              {limitString(project.notes[0].content, 100)}
            </ProjectNoteContent>
          </>
        )}
      </ProjectNoteWrapper>
      {project.notes.length > 0 && <SubTitle>{moment(project.notes[0].updatedAt).fromNow()}</SubTitle>}
    </>
  );
};

const ProjectNoteWrapper = styled.div`
  font-size: 0.8rem;
`;

const ProjectNoteContent = styled.div`
  padding-top: 0.2rem;
  word-break: break-word;
`;

export default LatestNoteCell;
