import update, { Spec } from 'immutability-helper';
import { EmailProjectRow, ProjectModelColumn, ProjectModelComponent, ProjectModelV2 } from '../types';
import { cloneDeep, merge } from 'lodash';

export const onMoveRow = (model: ProjectModelV2, dragIndex: number, hoverIndex: number) => {
  const draggedRow = model.sizes.large[dragIndex];
  if (draggedRow) {
    return update(model, {
      sizes: {
        large: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, draggedRow]
          ]
        }
      }
    });
  }

  return model;
};

export const onAddRow = (model: ProjectModelV2, cols: ProjectModelColumn[]) => {
  const index = model.sizes.large.length;
  return update(model, {
    sizes: {
      large: {
        $push: [
          {
            id: index,
            cols,
            style: {
              backgroundColor: '#ffffff'
            }
          }
        ]
      }
    }
  });
};

export const onDuplicateRow = (model: ProjectModelV2, row: EmailProjectRow) => {
  const index = model.sizes.large.length;

  // Create a deep copy of the row object
  const rowCopy = cloneDeep(row);

  const updatedCols = rowCopy.cols.map((col) => {
    if (col.components && Array.isArray(col.components) && col.components.length > 0) {
      const updatedComponents = col.components.map((component) => {
        if (
          (component.type === 'image' ||
            component.type === 'logo-horizontal' ||
            component.type === 'logo-vertical' ||
            component.type === 'print-project-cover' ||
            component.type === 'article-horizontal' ||
            component.type === 'article-vertical') &&
          'params' in component
        ) {
          if ('image' in component.params && component.params.image !== null && 'url' in component.params.image) {
            // Update the URLs for images within params.image to an empty string
            component.params.image.url = '';
            component.params.image.linkUrl = '';
          } else if ('url' in component.params) {
            // Update the URLs for images directly within params to an empty string
            component.params.url = '';
            component.params.linkUrl = '';
          }
        }
        return component;
      });

      return {
        ...col,
        components: updatedComponents
      };
    }
    return col;
  });

  const updatedRow: EmailProjectRow = {
    ...rowCopy,
    cols: updatedCols
  };

  return update(model, {
    sizes: {
      large: {
        $push: [
          {
            id: index,
            cols: updatedRow.cols,
            style: updatedRow.style,
            border: updatedRow.border,
            role: updatedRow.role
          }
        ]
      }
    }
  });
};

export const onUpdateRow = (model: ProjectModelV2, row: EmailProjectRow, rowIndex: number) => {
  return update(model, {
    sizes: {
      large: {
        [rowIndex]: {
          style: {
            $set: row.style
          },
          role: {
            $set: row.role
          },
          border: {
            $set: row.border
          }
        }
      }
    }
  });
};

export const onDeleteRow = (model: ProjectModelV2, rowIndex: number) => {
  return update(model, {
    sizes: {
      large: {
        $splice: [[rowIndex, 1]]
      }
    }
  });
};

export const onAddComponent = (
  model: ProjectModelV2,
  component: ProjectModelComponent,
  rowIndex: number,
  colIndex: number
) => {
  return update(model, {
    sizes: {
      large: {
        [rowIndex]: {
          cols: {
            [colIndex]: {
              components: {
                $push: [component]
              }
            }
          }
        }
      }
    }
  });
};

export const onDeleteComponent = (
  model: ProjectModelV2,
  componentIterator: number,
  rowIndex: number,
  colIndex: number
) => {
  return update(model, {
    sizes: {
      large: {
        [rowIndex]: {
          cols: {
            [colIndex]: {
              components: {
                $splice: [[componentIterator, 1]]
              }
            }
          }
        }
      }
    }
  });
};

export const onAddColumnsToRow = (model: ProjectModelV2, rowIterator: number, cols: ProjectModelColumn[]) => {
  return update(model, {
    sizes: {
      large: {
        [rowIterator]: {
          cols: {
            $set: cols
          }
        }
      }
    }
  });
};

export type ComponentToUpdate = {
  component: ProjectModelComponent;
  componentIterator: number;
  rowIndex: number;
  columnIndex: number;
};

export type RowToUpdateAlongWithComponents = {
  row: EmailProjectRow;
  index: number;
};

export const onComponentsUpdate = (
  model: ProjectModelV2,
  components: ComponentToUpdate[],
  rowData?: RowToUpdateAlongWithComponents
) => {
  let updateSpec: Spec<ProjectModelV2, never> = {
    sizes: {
      large: components.reduce((wholeSpec, data) => {
        return merge(wholeSpec, {
          [data.rowIndex]: {
            cols: {
              [data.columnIndex]: {
                components: {
                  [data.componentIterator]: { $set: data.component }
                }
              }
            }
          }
        });
      }, {})
    }
  };

  if (rowData) {
    updateSpec = merge(updateSpec, {
      sizes: {
        large: {
          [rowData.index]: {
            style: {
              $set: rowData.row.style
            },
            role: {
              $set: rowData.row.role
            },
            border: {
              $set: rowData.row.border
            }
          }
        }
      }
    });
  }

  return update(model, updateSpec);
};
