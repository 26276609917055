import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import { formatTableTimestamp } from '../../../../utils';
import { AdminInputLabel } from '../../Admin/AdminStyledComponents';
import DateRangePickerWindow from '../Windows/DateRangePickerWindow';
import { Range } from 'react-date-range';

type DateRangePickerComponentProps = {
  range: Range[] | undefined;
  setRange: React.Dispatch<React.SetStateAction<Range[]>>;
  disabled?: boolean;
  label?: string;
};

const DateRangePickerComponent: React.FC<DateRangePickerComponentProps> = ({
  range,
  setRange,
  disabled = false,
  label
}) => {
  const { t } = useTranslation();
  const [dateRangePickerWindowOpen, onDateRangePickerWindowOpen, onDateRangePickerWindowClose] = useOpenHandler();

  return (
    <>
      {label && <AdminInputLabel>{label}</AdminInputLabel>}
      <Button
        color="secondary"
        variant="outlined"
        onClick={onDateRangePickerWindowOpen}
        size="medium"
        fullWidth
        disabled={disabled}
      >
        {range && range[0].startDate && formatTableTimestamp(range[0].startDate)} -{' '}
        {range && range[0].endDate && formatTableTimestamp(range[0].endDate)}
      </Button>
      {dateRangePickerWindowOpen && (
        <DateRangePickerWindow
          range={range}
          open={dateRangePickerWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onDateRangePickerWindowClose}
          onSaveClick={setRange}
          label={label}
        />
      )}
    </>
  );
};

export default DateRangePickerComponent;
