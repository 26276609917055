import { FieldProps, FormikErrors } from 'formik';
import { TFunction } from 'i18next';

const getFormInputError = <T>(
  fieldProps: FieldProps<T>,
  t: TFunction,
  customErrorMessagePath?: string
): [boolean, FormikErrors<T>[keyof T]] => {
  const {
    field: { name },
    form: { errors, touched }
  } = fieldProps;

  if (touched?.[name]) {
    if (errors?.[name] === undefined) {
      return [false, undefined];
    }
    return customErrorMessagePath
      ? [true, t(`${customErrorMessagePath}.${errors?.[name]}`) as any]
      : [true, errors?.[name]];
  }

  return [false, undefined];
};

export default getFormInputError;
