import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageContent, PageContentPaper } from '../PageStyledComponents';
import PageHeader, { HeaderActionButton } from '../../Shared/Layout/PageHeader';
import { useTypedSelector } from '../../../utils';
import useOpenHandler from '../../../hooks/useOpenHandler';
import BusinessIcon from '@material-ui/icons/Business';
import AddIcon from '@material-ui/icons/Add';
import { hasPermission, isOrganizationOwner } from '../../../utils/permissions';
import InviteOrganizationUserWindow from './Windows/InviteOrganizationUserWindow';
import { Grid, Typography } from '@material-ui/core';
import OrganizationInformationForm from './Partials/OrganizationInformationForm';
import OrganizationSwatches from './Partials/OrganizationSwatches';
import OrganizationUsersTable from './Partials/OrganizationUsersTable';
import OrganizationContributorsList from './Partials/OrganizationContributorsList';
import OrganizationIntegrations from './Partials/OrganizationIntegrations';
import OrganizationStructure from './Partials/OrganizationStructure';
import OrganizationMerchant from './Partials/OrganizationMerchant';
import SwitchOrganizationWindow from './Windows/SwitchOrganizationWindow';

type MyAccountPageProps = {};

const MyOrganizationPage: FunctionComponent<MyAccountPageProps> = () => {
  const { t } = useTranslation();
  const [displayMerchantWidget, setDisplayMerchantWidget] = useState(true);
  const authState = useTypedSelector((state) => state.auth);
  const { organizationId } = authState;
  const organization = authState.organizations.find((organization) => organization.id === organizationId);

  const [
    createOrganizationUserWindowOpen,
    onCreateOrganizationUserWindowOpen,
    onCreateOrganizationUserWindowClose
  ] = useOpenHandler();
  const [
    switchOrganizationWindowOpen,
    onSwitchOrganizationWindowOpen,
    onSwitchOrganizationWindowClose
  ] = useOpenHandler();

  const getActionButtons = () => {
    const buttons: HeaderActionButton[] = [];

    if (authState.organizations.length > 1) {
      buttons.push({
        label: t('pages.myOrganization.switchOrganization'),
        icon: <BusinessIcon />,
        onClick: onSwitchOrganizationWindowOpen,
        variant: 'outlined'
      });
    }

    if (isOrganizationOwner(authState)) {
      buttons.push({
        label: t('pages.myOrganization.inviteOrganizationUser'),
        icon: <AddIcon />,
        onClick: onCreateOrganizationUserWindowOpen
      });
    }

    return buttons;
  };

  if (!organizationId || !organization) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeader title={organization.name} rightActionButtons={getActionButtons()} />
      <PageContent>
        <Grid container spacing={3}>
          {displayMerchantWidget && (
            <Grid item xs={12}>
              <PageContentPaper>
                <Grid container>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <Typography variant="h6" gutterBottom>
                      {t('pages.myOrganization.merchant.title')}
                    </Typography>
                    <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
                  </Grid>
                </Grid>
                <OrganizationMerchant onError={() => setDisplayMerchantWidget(false)} />
              </PageContentPaper>
            </Grid>
          )}

          <Grid item xs={12}>
            <OrganizationUsersTable />
          </Grid>

          <Grid item xs={12} md={8}>
            <PageContentPaper>
              <OrganizationInformationForm />
            </PageContentPaper>
            <PageContentPaper>
              <OrganizationIntegrations
                hasSocialPublishing={(authState.organization && authState.organization.socialPublishing) || false}
              />
            </PageContentPaper>
            {hasPermission(authState.role, ['swatchesView']) && (
              <PageContentPaper>
                <OrganizationSwatches />
              </PageContentPaper>
            )}
            {authState.organization && authState.organization.masterOrgId && <OrganizationStructure />}
          </Grid>

          <Grid item xs={12} md={4}>
            <PageContentPaper>
              <OrganizationContributorsList display="list" title={t('pages.myOrganization.contributors.title')} />
            </PageContentPaper>
          </Grid>
        </Grid>
      </PageContent>

      <SwitchOrganizationWindow
        open={switchOrganizationWindowOpen}
        onCloseClick={onSwitchOrganizationWindowClose}
        fullScreenOnMobile
      />

      <InviteOrganizationUserWindow
        organizationId={organizationId}
        open={createOrganizationUserWindowOpen}
        onCloseClick={onCreateOrganizationUserWindowClose}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

export default MyOrganizationPage;
