import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { InfoText } from '../../PageStyledComponents';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Box, Button, MenuItem, Typography } from '@material-ui/core';
import Toast from '../../../Shared/Toast/Toast';
import {
  automatedEmailDateSchedule,
  SystemSettingsNormalized,
  SystemSettingType
} from '../../../../store/SystemSettings/types';
import { systemSettingsOperations } from '../../../../store/SystemSettings';
import { AdminAutocompleteInput, AdminSelectInput, AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import Loader from '../../../Shared/Loading/Loader';
import { Row } from '../../../Shared/StyledComponents';
import { hasPermission } from '../../../../utils/permissions';
import { AdminRole } from '../../../../store/AdminRoles/types';
import { insertTrailingZero } from '../../../../utils';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import { SenderData } from '../../../../store/EmailProjects/types';
import { AdminUser } from '../../../../store/AdminUsers/types';
import { adminUsersOperations } from '../../../../store/AdminUsers';

type GeneralSystemSettingsProps = {
  role: AdminRole;
};

const GeneralSystemSettings: FunctionComponent<GeneralSystemSettingsProps> = ({ role }) => {
  const { t } = useTranslation();
  const [systemSettings, setSystemSettings] = useState<SystemSettingsNormalized | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [senders, setSenders] = useState<SenderData[]>([]);
  const [apiUsersDoAppOpen, setApiUsersDoAppOpen] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<AdminUser[]>([]);
  const [usersListSearchValue, setUsersListSearchValue] = useState<string>('');
  const userListLoading = apiUsersDoAppOpen && usersList.length === 0;

  const saveSystemSettings = async (values: SystemSettingsNormalized, props: FormikHelpers<any>) => {
    try {
      await systemSettingsOperations.setSystemSettings(values);
      Toast.success(t('notifications.setSystemSettings.success'));
      props.resetForm({ values });
    } catch (e) {
      Toast.error(t('notifications.setSystemSettings.error'));
    }
  };

  const fetchUsersList = async (searchValue: string) => {
    const response = await adminUsersOperations.indexRaw(searchValue);
    setUsersList(response.data);
  };

  useEffect(() => {
    if (!apiUsersDoAppOpen) {
      setUsersList([]);
    }
  }, [apiUsersDoAppOpen]);

  // get users list on initial autocomplete open
  useEffect(() => {
    if (!userListLoading) {
      return undefined;
    }

    fetchUsersList('');
  }, [userListLoading]);

  // run debounced useEffect after each userListSearchValue change
  useEffect(() => {
    const debouncedSearch = setTimeout(() => {
      fetchUsersList(usersListSearchValue);
    }, 1000);

    return () => {
      clearTimeout(debouncedSearch);
    };
  }, [usersListSearchValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sendersResponse = await emailProjectsOperations.getSenders();
        setSenders(sendersResponse.data);
      } catch (e) {
        Toast.error(t('notifications.getSenders.error'));
      }
      try {
        const settings = await systemSettingsOperations.getSystemSettings();
        const automatedEmailPublishTime = new Date(parseInt(settings.AUTOMATED_EMAIL_TIME));
        const hour = insertTrailingZero(automatedEmailPublishTime.getHours());
        const minute = insertTrailingZero(automatedEmailPublishTime.getMinutes());
        settings.AUTOMATED_EMAIL_TIME = `${hour}:${minute}`;

        setSystemSettings(settings);
      } catch (e) {
        Toast.error(t('notifications.getSystemSettings.error'));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (!systemSettings) {
    return null;
  }

  const hasPermissionsToEdit = hasPermission(role, ['systemSettingsManage']);

  const renderSystemSettingsForm = (props: FormikProps<SystemSettingsNormalized>) => (
    <Form>
      <Typography variant="h6" gutterBottom>
        {t('pages.systemSettings.title')}
      </Typography>
      <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
      <AdminTextInput
        t={t}
        name="PUBLISH_HOUR"
        section="systemSettings"
        type="number"
        disabled={!hasPermissionsToEdit}
      />
      <Row>
        <InfoText>{t('pages.systemSettings.publishDateInfo')}</InfoText>
      </Row>
      <Row>
        <AdminTextInput
          t={t}
          name="ARCHIVE_TIME_BULLETIN"
          section="systemSettings"
          type="number"
          disabled={!hasPermissionsToEdit}
        />
        <AdminTextInput
          t={t}
          name="ARCHIVE_TIME_EMAIL"
          section="systemSettings"
          type="number"
          disabled={!hasPermissionsToEdit}
        />
        <AdminTextInput
          t={t}
          name="ARCHIVE_TIME_FLYER"
          section="systemSettings"
          type="number"
          disabled={!hasPermissionsToEdit}
        />
        <AdminTextInput
          t={t}
          name="ARCHIVE_TIME_NEWSLETTER"
          section="systemSettings"
          type="number"
          disabled={!hasPermissionsToEdit}
        />
        <AdminTextInput
          t={t}
          name="ARCHIVE_TIME_DIRECTORY"
          section="systemSettings"
          type="number"
          disabled={!hasPermissionsToEdit}
        />
      </Row>
      <Row>
        <AdminTextInput
          t={t}
          name="COLOR_SWATCHES_LIMIT"
          section="systemSettings"
          type="number"
          disabled={!hasPermissionsToEdit}
        />
      </Row>
      <Row>
        <AdminTextInput
          t={t}
          name="PROJECT_LEAVE_INACTIVITY_TIME"
          section="systemSettings"
          type="number"
          disabled={!hasPermissionsToEdit}
        />
      </Row>
      <Row>
        <AdminTextInput
          t={t}
          name="LOGOUT_TIME_INACTIVITY"
          section="systemSettings"
          type="number"
          disabled={!hasPermissionsToEdit}
        />
      </Row>
      <Row>
        <AdminTextInput
          t={t}
          name="AUTOMATED_EMAIL_TIME"
          section="systemSettings"
          type="time"
          disabled={!hasPermissionsToEdit}
        />
        <InfoText>{t('pages.systemSettings.publishDateInfo')}</InfoText>
      </Row>
      <Row>
        <AdminSelectInput
          disabled={!hasPermissionsToEdit}
          t={t}
          name="AUTOMATED_EMAIL_DATE_SCHEDULE"
          section="systemSettings"
        >
          {automatedEmailDateSchedule.map((name) => (
            <MenuItem key={name} value={name}>
              {t(`common.automatedEmailDateSchedule.${name}`)}
            </MenuItem>
          ))}
        </AdminSelectInput>
      </Row>
      <Row>
        <AdminTextInput
          t={t}
          name="AUTOMATED_EMAIL_SUBJECT"
          section="systemSettings"
          disabled={!hasPermissionsToEdit}
        />
        <InfoText>{t('pages.systemSettings.automatedEmailSubjectInfo')}</InfoText>
      </Row>
      <Row>
        <AdminSelectInput disabled={!hasPermissionsToEdit} t={t} name="SENDER_EMAIL" section="systemSettings">
          {senders.map((sender) => (
            <MenuItem key={sender.ID} value={sender.Email}>
              {sender.Name} ({sender.Email})
            </MenuItem>
          ))}
        </AdminSelectInput>
      </Row>
      <Row>
        <AdminAutocompleteInput
          disabled={!hasPermissionsToEdit}
          t={t}
          options={usersList.map((user) => ({ label: user.email, id: user.id }))}
          section="systemSettings"
          name={SystemSettingType.API_USER_DO_LAKE_HURON_APP}
          loading={userListLoading}
          onOpen={() => setApiUsersDoAppOpen(true)}
          onClose={() => setApiUsersDoAppOpen(false)}
          onSearchTextChange={(event) => {
            setUsersListSearchValue(event.target.value);
          }}
        />
      </Row>
      <Box display="flex" justifyContent="flex-end">
        <Button
          style={{ marginTop: '1rem' }}
          color="secondary"
          variant="contained"
          onClick={props.submitForm}
          size="medium"
          type="submit"
          disabled={!hasPermissionsToEdit || props.isSubmitting || !props.isValid || !props.dirty}
        >
          {t('common.save')}
        </Button>
      </Box>
    </Form>
  );

  const getJsonValue = (value: string) => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return '';
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <Formik
          initialValues={{
            PUBLISH_HOUR: systemSettings.PUBLISH_HOUR,
            COLOR_SWATCHES_LIMIT: systemSettings.COLOR_SWATCHES_LIMIT,
            ARCHIVE_TIME_BULLETIN: systemSettings.ARCHIVE_TIME_BULLETIN,
            ARCHIVE_TIME_EMAIL: systemSettings.ARCHIVE_TIME_EMAIL,
            ARCHIVE_TIME_FLYER: systemSettings.ARCHIVE_TIME_FLYER,
            ARCHIVE_TIME_NEWSLETTER: systemSettings.ARCHIVE_TIME_NEWSLETTER,
            ARCHIVE_TIME_DIRECTORY: systemSettings.ARCHIVE_TIME_DIRECTORY,
            PROJECT_LEAVE_INACTIVITY_TIME: systemSettings.PROJECT_LEAVE_INACTIVITY_TIME,
            LOGOUT_TIME_INACTIVITY: systemSettings.LOGOUT_TIME_INACTIVITY,
            AUTOMATED_EMAIL_TIME: systemSettings.AUTOMATED_EMAIL_TIME,
            AUTOMATED_EMAIL_DATE_SCHEDULE: systemSettings.AUTOMATED_EMAIL_DATE_SCHEDULE,
            AUTOMATED_EMAIL_SUBJECT: systemSettings.AUTOMATED_EMAIL_SUBJECT,
            SENDER_EMAIL: systemSettings.SENDER_EMAIL,
            API_USER_DO_LAKE_HURON_APP: getJsonValue(systemSettings.API_USER_DO_LAKE_HURON_APP)
          }}
          validationSchema={Yup.object().shape({
            PUBLISH_HOUR: Yup.number().integer().min(0).max(23).required(),
            COLOR_SWATCHES_LIMIT: Yup.number().integer().min(0).max(200).required(),
            ARCHIVE_TIME_BULLETIN: Yup.number().integer().min(1).max(365).required(),
            ARCHIVE_TIME_EMAIL: Yup.number().integer().min(1).max(365).required(),
            ARCHIVE_TIME_FLYER: Yup.number().integer().min(1).max(365).required(),
            ARCHIVE_TIME_NEWSLETTER: Yup.number().integer().min(1).max(365).required(),
            ARCHIVE_TIME_DIRECTORY: Yup.number().integer().min(1).max(365).required(),
            PROJECT_LEAVE_INACTIVITY_TIME: Yup.number().integer().min(0).required(),
            LOGOUT_TIME_INACTIVITY: Yup.number().integer().min(0).required(),
            AUTOMATED_EMAIL_TIME: Yup.string().required(),
            AUTOMATED_EMAIL_DATE_SCHEDULE: Yup.string().required(),
            AUTOMATED_EMAIL_SUBJECT: Yup.string().required(),
            SENDER_EMAIL: Yup.string().required(),
            API_USER_DO_LAKE_HURON_APP: Yup.string().optional()
          })}
          onSubmit={saveSystemSettings}
        >
          {renderSystemSettingsForm}
        </Formik>
      )}
    </>
  );
};

export default GeneralSystemSettings;
