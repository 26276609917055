/**
 * Creates network error object based on the error passed. If the error isn't a network error (eg. was caused by an
 * error in JS runtime) it will still create a basic "network error". It's up for discussion what should happen in such
 * case.
 * @param e
 */
const createNetworkErrorObject = (e: any) => {
  const error: NetworkError = e?.response?.data || {
    error: 'Network error',
    statusCode: 500,
    message: 'network_error'
  };
  if (error.statusCode === 500) {
    return {
      error: 'Server error',
      statusCode: 500,
      message: 'server_error'
    };
  }
  return error;
};

export default createNetworkErrorObject;
