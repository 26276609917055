import React, { useEffect, useState } from 'react';
import { DesignProject } from '../../../../store/DesignProjects/types';
import { useTypedSelector } from '../../../../utils';
import Loader from '../../../Shared/Loading/Loader';

export type DesignProjectThumbnailProps = {
  project: DesignProject;
};

export const DesignProjectThumbnail: React.FC<DesignProjectThumbnailProps> = ({ project }) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const printDesignThumbnailGenerated = useTypedSelector((state) => state.designProjects.printDesignThumbnailGenerated);

  useEffect(() => {
    if (printDesignThumbnailGenerated && project.id === printDesignThumbnailGenerated.id) {
      setHasError(false);
    }
  }, [printDesignThumbnailGenerated]);

  const wrapperStyle = {
    maxWidth: '50px',
    maxHeight: '50px',
    width: '50px',
    height: '50px',
    border: '1px solid #ccc',
    marginRight: '15px',
    cursor: 'pointer',
    display: 'inline-block'
  };

  return (
    <>
      {hasError && (
        <div style={{ ...wrapperStyle }}>
          <Loader style={{ scale: '0.5' }} />
        </div>
      )}
      {!hasError && (
        <img
          onError={() => setHasError(true)}
          onClick={() => window.open(project.previewUrl)}
          style={{ ...wrapperStyle, objectFit: 'contain' }}
          alt={project.name}
          src={project.thumbnailUrl}
        />
      )}
    </>
  );
};
