import {
  AdminGetAllKeywordsDataResponse,
  AdminGetAllTagsAction,
  AdminKeywordGroup,
  AdminKeywordGroupKeywordAdded,
  AdminKeywordGroupKeywordRemoved,
  AdminKeywordGroupsIndexActionBEGIN,
  AdminKeywordGroupsIndexActionFAILED,
  AdminKeywordGroupsIndexActionSUCCESS,
  AdminKeywordGroupsIndexResponse,
  AdminKeywordsActionsConstants,
  AdminKeywordsIndexActionBEGIN,
  AdminKeywordsIndexActionFAILED,
  AdminKeywordsIndexActionSUCCESS,
  AdminKeywordsIndexResponse
} from './types';

export const keywordsIndexBegin = (): AdminKeywordsIndexActionBEGIN => ({
  type: AdminKeywordsActionsConstants.INDEX_BEGIN
});

export const keywordsIndexSuccess = (payload: AdminKeywordsIndexResponse): AdminKeywordsIndexActionSUCCESS => ({
  type: AdminKeywordsActionsConstants.INDEX_SUCCESS,
  payload
});

export const keywordsIndexFailed = (error: any): AdminKeywordsIndexActionFAILED => ({
  type: AdminKeywordsActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const getAllKeywords = (data: AdminGetAllKeywordsDataResponse): AdminGetAllTagsAction => ({
  type: AdminKeywordsActionsConstants.GET_ALL_KEYWORDS,
  payload: data
});

export const keywordGroupsIndexBegin = (): AdminKeywordGroupsIndexActionBEGIN => ({
  type: AdminKeywordsActionsConstants.INDEX_GROUPS_BEGIN
});

export const keywordGroupsIndexSuccess = (
  payload: AdminKeywordGroupsIndexResponse
): AdminKeywordGroupsIndexActionSUCCESS => ({
  type: AdminKeywordsActionsConstants.INDEX_GROUPS_SUCCESS,
  payload
});

export const keywordGroupsIndexFailed = (error: any): AdminKeywordGroupsIndexActionFAILED => ({
  type: AdminKeywordsActionsConstants.INDEX_GROUPS_FAILED,
  payload: {
    error
  }
});

export const keywordAdded = (payload: AdminKeywordGroup): AdminKeywordGroupKeywordAdded => ({
  type: AdminKeywordsActionsConstants.KEYWORD_ADDED,
  payload
});

export const keywordRemoved = (payload: AdminKeywordGroup): AdminKeywordGroupKeywordRemoved => ({
  type: AdminKeywordsActionsConstants.KEYWORD_REMOVED,
  payload
});
