import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { AdminEmailTemplate, AdminUpdateEmailTemplateValues } from '../../../../../store/AdminEmailTemplates/types';
import { adminEmailTemplatesOperations } from '../../../../../store/AdminEmailTemplates';
import { useDispatch } from 'react-redux';
import { AdminSelectInput, AdminTextInput } from '../../Shared/AdminFormInputs';
import { MenuItem } from '@material-ui/core';
import Toast from '../../../../Shared/Toast/Toast';
import app from '../../../../../config/app/app';
import { emailTemplateTypes, printTemplateTypes } from '../../../../../utils/projectTypeUtils';
import { FormikProps } from 'formik';
import { isSystemOwner } from '../../../../../utils/permissions';
import { useTypedSelector } from '../../../../../utils';

type AdminUpdateEmailTemplateWindowProps = {
  open: boolean;
  template: AdminEmailTemplate | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminUpdateEmailTemplateWindow: FunctionComponent<AdminUpdateEmailTemplateWindowProps> = ({
  template,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authState = useTypedSelector((state) => state.auth);

  if (!template) return null;

  const onSubmit = async (values: AdminUpdateEmailTemplateValues) => {
    try {
      await dispatch(
        adminEmailTemplatesOperations.update(template.id, {
          name: values.name,
          description: values.description,
          type: values.type,
          overrideAdZones: values.overrideAdZones || null
        })
      );
      Toast.success(t('notifications.adminEmailTemplates.successOperation'));

      await dispatch(adminEmailTemplatesOperations.index());

      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.adminEmailTemplates.errorOperation'));
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="name" section="adminEmailTemplates" />
      <AdminTextInput t={t} name="description" section="adminEmailTemplates" />
      {isSystemOwner(authState) && <AdminTextInput t={t} name="overrideAdZones" section="adminEmailTemplates" />}
      <AdminSelectInput t={t} name="type" section="adminEmailTemplates">
        {emailTemplateTypes.map((name) => (
          <MenuItem key={name} value={name}>
            {t(`common.projectType.${name}`)}
          </MenuItem>
        ))}
      </AdminSelectInput>
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      header={t('pages.adminEmailTemplates.windows.updateEmailTemplate')}
      okButtonText={t('common.update')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        name: template.name,
        description: template.description,
        type: template.type,
        overrideAdZones: template.overrideAdZones
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(app.maxInputLength).required(),
        description: Yup.string().max(app.maxInputLength).required(),
        overrideAdZones: Yup.string().max(app.maxInputLength).nullable(),
        type: Yup.string().required()
      })}
    />
  );
};

export default AdminUpdateEmailTemplateWindow;
