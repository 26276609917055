import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../../Shared/StyledComponents';
import Toast from '../../../../../Shared/Toast/Toast';
import { AdCategory } from '../../../../../../store/AdminAds/types';
import { adminAdsOperations } from '../../../../../../store/AdminAds';

type AdminDeleteAdCategoryWindowProps = {
  open: boolean;
  category: AdCategory | null;
  onCloseClick: () => void;
  onFormSuSubmit?: () => void;
  fullScreenOnMobile: boolean;
};

const AdminDeleteAdCategoryWindow: FunctionComponent<AdminDeleteAdCategoryWindowProps> = ({
  category,
  onCloseClick,
  onFormSuSubmit,
  open
}) => {
  const { t } = useTranslation();

  if (!category) return null;

  const onSubmit = async () => {
    try {
      await adminAdsOperations.removeAdCategory(category);
      onCloseClick();
      if (onFormSuSubmit) {
        onFormSuSubmit();
      }
      Toast.success(t('notifications.adminAds.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminAds.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminAds.windows.deleteAdCategory')}
      okButtonText={t('pages.adminAds.windows.completelyDeleteCategory')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.adminAds.windows.deleteAdCategoryContent')}</Row>
      <Row>
        <Strong>{category.name}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default AdminDeleteAdCategoryWindow;
