import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { SharedProject } from '../../../../store/SharingCenter/types';
import ProjectClones from '../../Dashboard/WorkInProgressTable/ProjectClones';
import { isStory } from '../../../../utils';

type ProjectDetailsWindowProps = {
  open: boolean;
  project: SharedProject;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const ProjectDetailsWindow: FunctionComponent<ProjectDetailsWindowProps> = ({ project, onCloseClick, open }) => {
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      open={open}
      header={
        isStory(project)
          ? t('pages.sharingCenter.windows.storyDetails')
          : t('pages.sharingCenter.windows.projectDetails')
      }
      onCloseClick={onCloseClick}
      hideActionButtons
    >
      <ProjectClones project={project} open={open} context="modal" />
    </ModalConfirmationWindow>
  );
};

export default ProjectDetailsWindow;
