import update from 'immutability-helper';
import {
  AdminOrganizationUser,
  AdminOrganizationUsersActionsConstants,
  AdminOrganizationUsersActionType,
  AdminOrganizationUsersState
} from './types';
import { normalizeOrganizationUsers } from '../helpers';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: AdminOrganizationUsersState = {
  users: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  organization: null,
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const adminOrganizationUsersPaginationReducer = createPaginationReducer<
  AdminOrganizationUsersState,
  AdminOrganizationUser
>(PaginationStoreModule.ADMIN_ORGANIZATION_USERS);

const adminOrganizationUsersTableSearchReducer = createTableSearchReducer<
  AdminOrganizationUser,
  AdminOrganizationUsersState
>(TableSearchStoreModule.ADMIN_ORGANIZATION_USERS);

export default function adminOrganizationUsersReducer(
  state = initialState,
  action: WithLogoutAction<AdminOrganizationUsersActionType>
) {
  switch (action.type) {
    case AdminOrganizationUsersActionsConstants.INDEX_BEGIN: {
      return update(state, {
        organization: { $set: null },
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case AdminOrganizationUsersActionsConstants.INDEX_SUCCESS: {
      const data = normalizeOrganizationUsers(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        organization: { $set: action.payload.organization },
        users: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case AdminOrganizationUsersActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        organization: { $set: null },
        users: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return adminOrganizationUsersTableSearchReducer(
        adminOrganizationUsersPaginationReducer(state, action),
        action
      ) as AdminOrganizationUsersState;
    }
  }
}
