import { AnyProjectStatus } from '../../store/Dashboard/types';
import { PrintProjectStatus } from '../../store/PrintProjects/types';
import { EmailProjectStatus } from '../../store/EmailProjects/types';
import WarningIcon from '@material-ui/icons/Warning';
import CachedIcon from '@material-ui/icons/Cached';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { ReactComponent as ProcessedIcon } from '../../assets/icons/processed.svg';
import TelegramIcon from '@material-ui/icons/Telegram';
import React from 'react';

export const getStatusIcon = (status: AnyProjectStatus, hasProcessingErrors = false, successMainColor?: string) => {
  switch (status) {
    case PrintProjectStatus.IN_PROGRESS:
    case EmailProjectStatus.IN_PROGRESS: {
      if (hasProcessingErrors) {
        return <WarningIcon />;
      }
      return <CachedIcon />;
    }

    case PrintProjectStatus.SCHEDULED:
    case EmailProjectStatus.SCHEDULED:
      return <ScheduleIcon />;

    case PrintProjectStatus.DELIVERED:
      return <ProcessedIcon width={24} fill={successMainColor} />;

    case EmailProjectStatus.SENDING:
    case EmailProjectStatus.PUBLISHED:
      return <TelegramIcon />;

    case EmailProjectStatus.ERROR:
      return <WarningIcon />;
  }
};
