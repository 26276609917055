import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { IndexAdminMailingListsRequestParams, UpdateAdminMailingListValues } from './types';

export const index = (settings: RequestSettings<IndexAdminMailingListsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/mailing_list?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{}, { name: string; organizationId: string }>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/mailing_list'
  },
  payload: settings.requestPayload
});

export const update = (settings: RequestSettings<{}, UpdateAdminMailingListValues>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/mailing_list/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteAdminMailingList = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/mailing_list/:id'
  },
  params: settings.requestParams
});

export const getOrganizationMailingLists = (
  settings: RequestSettings<{ organizationId: string }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/mailing_list/all/:organizationId'
  },
  params: settings.requestParams
});
