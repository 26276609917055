import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  MonthCalendarStyle,
  MonthCalendarStyles,
  OrganizationMonthCalendar,
  OrganizationMonthCalendarDay
} from '../../../../../../store/MyOrganization/types';
import { MonthCalendar } from '../../types';
import useOpenHandler from '../../../../../../hooks/useOpenHandler';
import { ComponentActionIcons, StyledEditIcon } from '../../../../../Shared/StyledComponents';
import { ReactComponent as SettingsIcon } from '../../../../../../assets/icons/settings.svg';
import { myOrganizationOperations } from '../../../../../../store/MyOrganization';
import Loader from '../../../../../Shared/Loading/Loader';
import moment from 'moment';
import MonthCalendarElement, { getCalendarStyle } from './Utils/MonthCalendarElement';
import MonthCalendarWindow from '../Windows/MonthCalendarWindow';
import { useTypedSelector } from '../../../../../../utils';
import { useTheme } from '@material-ui/core';
import _ from 'lodash';
import { getWeek, parse } from 'date-fns';
import Toast from '../../../../../Shared/Toast/Toast';
import { useTranslation } from 'react-i18next';

type MonthCalendarComponentProps = {
  data: MonthCalendar;
  onChange: (data: MonthCalendar) => void;
  inProjectEditor: boolean;
};

const MonthCalendarComponent: FunctionComponent<MonthCalendarComponentProps> = ({
  data,
  onChange,
  inProjectEditor
}) => {
  const { t } = useTranslation();
  const [monthCalendarWindowOpen, onMonthCalendarWindowOpen, onMonthCalendarWindowClose] = useOpenHandler();

  const [calendarRows, setCalendarRows] = useState<OrganizationMonthCalendarDay[][]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [organizationCalendar, setOrganizationCalendar] = useState<OrganizationMonthCalendar | null>(null);
  const currentOrganizationId = useTypedSelector((state) => state.auth.organizationId);
  const [selectedStyle, setSelectedStyle] = useState<MonthCalendarStyles>(MonthCalendarStyles.MAIN);
  const [calendarStyles, setCalendarStyles] = useState<MonthCalendarStyle>(
    getCalendarStyle(MonthCalendarStyles.MAIN, useTheme())
  );

  const now = moment();

  const fetchOrganizationCalendar = async (
    date: string,
    selectedStyle: MonthCalendarStyles | null,
    styles: MonthCalendarStyle,
    viewType: 'month' | 'week',
    year: number,
    month: number,
    week: number,
    labelPosition: 'left' | 'center' | 'right'
  ) => {
    setLoading(true);
    try {
      if (currentOrganizationId) {
        const response = await myOrganizationOperations.getOrganizationMonthCalendar(
          currentOrganizationId,
          date,
          viewType
        );
        setOrganizationCalendar(response);

        let orgSelectedStyle: MonthCalendarStyles | null = null;
        let orgStyles: MonthCalendarStyle | null = null;
        if (response.style) {
          orgStyles = _.omit(response.style, ['id', 'createdAt', 'updatedAt', 'style']);
          setCalendarStyles(orgStyles);
          orgSelectedStyle = response.style.style;
          setSelectedStyle(orgSelectedStyle);
        }

        const daysCount = response.days.length;
        const rows: OrganizationMonthCalendarDay[][] = [];
        for (let i = 0; i < daysCount / 7; i++) {
          rows.push(response.days.splice(0, 7));
        }
        setCalendarRows(rows);

        const incomingDataParams = {
          calendar: rows,
          date,
          selectedStyle: selectedStyle ? selectedStyle : orgSelectedStyle,
          styles: selectedStyle ? styles : orgStyles ? orgStyles : styles,
          viewType,
          year,
          month,
          week,
          labelPosition
        };

        if (!_.isEqual(data.params, incomingDataParams)) {
          onChange({
            ...data,
            params: incomingDataParams
          });
        }
      }
    } catch (error) {
      Toast.error(t('notifications.story.errorFetchingCalendarEvents'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganizationCalendar(
      data.params.date || now.format('YYYY-MM-DD'),
      data.params.selectedStyle,
      data.params.styles,
      data.params.viewType || 'month',
      data.params.year || new Date().getFullYear(),
      data.params.month || new Date().getMonth(),
      data.params.week || getWeek(new Date()),
      data.params.labelPosition
    );
  }, []);

  const selectedDate = moment.utc(data.params.date);

  return (
    <div style={data.wrapperStyle}>
      {loading && <Loader />}
      {!loading && organizationCalendar && (
        <>
          <div style={{ fontSize: '24px', padding: '15px', textAlign: data.params.labelPosition }}>
            {selectedDate.format('MMMM')} {selectedDate.format('YYYY')}
          </div>
          <MonthCalendarElement
            calendarRows={calendarRows}
            calendarEvents={organizationCalendar.events}
            disableHoverListener
            styles={data.params.selectedStyle ? data.params.styles : calendarStyles}
            staticEventHeight={190}
          />
        </>
      )}
      {inProjectEditor && (
        <ComponentActionIcons style={{ left: 'calc(100% - 45px)' }}>
          <StyledEditIcon onClick={() => onMonthCalendarWindowOpen()}>
            <SettingsIcon style={{ width: 22, fill: '#fff' }} />
          </StyledEditIcon>
        </ComponentActionIcons>
      )}

      {monthCalendarWindowOpen && (
        <MonthCalendarWindow
          open={monthCalendarWindowOpen}
          onCloseClick={onMonthCalendarWindowClose}
          onFormSubmit={(values) => {
            let selectedDate;
            if (values.viewType === 'week') {
              selectedDate = moment.utc(parse(values.selectedWeek.toString(), 'I', new Date(), { weekStartsOn: 0 }));
            } else {
              selectedDate = moment.utc({ month: values.selectedMonth, year: values.selectedYear });
            }

            const formattedDate = selectedDate.format('YYYY-MM-DD');

            onMonthCalendarWindowClose();
            fetchOrganizationCalendar(
              formattedDate,
              values.selectedStyle,
              values.styles,
              values.viewType,
              values.selectedYear,
              values.selectedMonth,
              values.selectedWeek,
              values.labelPosition
            );
          }}
          month={selectedDate.month()}
          year={selectedDate.year()}
          week={data.params.week || getWeek(new Date())}
          selectedStyle={data.params.selectedStyle || selectedStyle}
          styles={data.params.selectedStyle ? data.params.styles : calendarStyles}
          viewType={data.params.viewType || 'month'}
          labelPosition={data.params.labelPosition || 'left'}
        />
      )}
    </div>
  );
};

export default MonthCalendarComponent;
