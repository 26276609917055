import React, { FunctionComponent, useEffect, useState } from 'react';
import moment from 'moment';
import app from '../../../../../../../config/app/app';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  GridList,
  GridListTile,
  IconButton,
  InputAdornment,
  List,
  MenuItem,
  Paper,
  TextField,
  withStyles
} from '@material-ui/core';
import { Alert, Pagination } from '@material-ui/lab';
import Toast from '../../../../../../Shared/Toast/Toast';
import Loader from '../../../../../../Shared/Loading/Loader';
import { emailProjectsOperations } from '../../../../../../../store/EmailProjects';
import {
  Asset,
  Attachment,
  EmailProject,
  PaginationData,
  Pin,
  Section
} from '../../../../../../../store/EmailProjects/types';
import { ReactComponent as AttachmentIcon } from '../../../../../../../assets/icons/attachment.svg';
import { ReactComponent as TemplateIcon } from '../../../../../../../assets/icons/template.svg';
import { ReactComponent as PinIcon } from '../../../../../../../assets/icons/pin.svg';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import PinsWindow from '../Helpers/PinsWindow';
import useOpenHandler from '../../../../../../../hooks/useOpenHandler';
import ClearIcon from '@material-ui/icons/Clear';
import { createNetworkErrorObject, useTypedSelector } from '../../../../../../../utils';
import { DragDataTransfer } from '../../../types';
import { BrandfolderAsset } from '../Helpers/BrandfolderAsset';

type BrandfolderProps = {
  project: EmailProject | null;
};

const Brandfolder: FunctionComponent<BrandfolderProps> = ({ project }) => {
  const { t } = useTranslation();
  const user = useTypedSelector((state) => state.auth.user);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [sections, setSections] = useState<Section[]>([]);
  const [pins, setPins] = useState<Pin[]>([]);
  const [selectedSection, setSelectedSection] = useState<string>('all');
  const [isCurrentWeekChecked, setIsCurrentWeekChecked] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [hiddenSearchValue, setHiddenSearchValue] = useState<string>('');
  const [brandfolderPage, setBrandfolderPage] = useState<string>('index');
  const [pagination, setPagination] = useState<PaginationData | null>(null);

  const [pinsWindowOpen, onPinsWindowOpen, onPinsWindowClose] = useOpenHandler();

  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
    fetchData(page, selectedSection, searchValue, hiddenSearchValue);
  };

  const handleSectionChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const section = event.target.value as string;
    setSelectedSection(section);
    fetchData(1, section, searchValue, hiddenSearchValue);
  };

  const handleSearch = (event: React.SyntheticEvent) => {
    event.preventDefault();
    fetchData(1, selectedSection, searchValue, hiddenSearchValue);
  };

  const handleSearchClear = () => {
    setSearchValue('');
    fetchData(1, selectedSection, '', hiddenSearchValue);
  };

  const fetchData = async (page: number, section: string, searchValue: string, hiddenSearchValue: string) => {
    setLoading(true);
    setError('');
    try {
      const numberOfItemsPerPage = 12;
      const combinedSearchValues =
        hiddenSearchValue === ''
          ? searchValue
          : `${hiddenSearchValue} ${searchValue === '' ? '' : 'AND'} ${searchValue}`;
      const sections = await emailProjectsOperations.getBrandfolderSections();
      setSections(sections.data);
      const pins = await emailProjectsOperations.getBrandfolderPins();
      setPins(pins.data);
      if (section === 'all') {
        const assets = await emailProjectsOperations.getBrandfolderAssets(
          numberOfItemsPerPage,
          page,
          combinedSearchValues
        );
        setAssets(assets.data);
        setPagination(assets.pagination);
      } else {
        const assets = await emailProjectsOperations.getBrandfolderSectionAssets(
          section,
          numberOfItemsPerPage,
          page,
          combinedSearchValues
        );
        setAssets(assets.data);
        setPagination(assets.pagination);
      }
    } catch (e) {
      const error = createNetworkErrorObject(e);

      switch (error.statusCode) {
        case 500:
          setError(t('brandfolder.errors.500'));
          break;
        case 404:
          setError(t('brandfolder.errors.404'));
          break;
        default:
          Toast.error(t('notifications.newsletterBrandfolderFetch.error'));
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAssetClick = async (asset: Asset) => {
    if (asset.attributes.extension === 'bftemplate' && asset.attributes.printuiEditorLink) {
      window.open(`https://wecreate.4lpi.com${asset.attributes.printuiEditorLink}`);
    } else {
      setBrandfolderPage('attachments');

      setLoading(true);
      try {
        const attachments = await emailProjectsOperations.getBrandfolderAttachments(asset.id as string);
        setAttachments(attachments.data);
      } catch (e) {
        Toast.error(t('notifications.newsletterBrandfolderFetch.error'));
      } finally {
        setLoading(false);
      }
    }
  };

  const CenteredLoader = () => (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );

  const handlePinClick = (query: string) => {
    setSearchValue(query);
    fetchData(1, selectedSection, query, hiddenSearchValue);
    onPinsWindowClose();
  };

  const handleCurrentWeekCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCurrentWeekChecked(event.target.checked);
    if (event.target.checked) {
      if (project) {
        const parsedDate = moment.utc(project.publishDate);

        const hiddenQuery = `tags.strict:"${app.monthNames[parsedDate.month()]} ${parsedDate.date()}"`;
        setHiddenSearchValue(hiddenQuery);
        fetchData(1, selectedSection, searchValue, hiddenQuery);
      }
    } else {
      const hiddenQuery = '';
      setHiddenSearchValue(hiddenQuery);
      fetchData(1, selectedSection, searchValue, hiddenQuery);
    }
  };

  const getFileTypePillContent = (attachmentCount: number, extension: string) => {
    if (extension === 'bftemplate') {
      return <TemplateIcon />;
    }

    if (attachmentCount === 1) {
      return extension.toUpperCase();
    }

    return (
      <>
        <AttachmentIcon /> {attachmentCount}
      </>
    );
  };

  const handleAttachmentClick = (attachment: Attachment) => {
    switch (attachment.attributes.extension) {
      case 'doc':
        return window.open(`https://wecreate.4lpi.com/viewer/lpi/${attachment.id}`);

      case 'bftemplate':
        console.log('Template editing not yet implemented...');
        break;
    }
  };

  const AttachmentsList = () => {
    if (!attachments) {
      return <StyledPaper>{loading && <CenteredLoader />}</StyledPaper>;
    }

    return (
      <StyledPaper>
        {loading && <CenteredLoader />}
        {!loading && (
          <>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<KeyboardArrowLeftIcon />}
              onClick={() => setBrandfolderPage('index')}
              style={{ margin: '10px auto 5px 20px' }}
            >
              {t('brandfolder.backToSearch')}
            </Button>
            <List style={{ width: '380px', height: '500px', overflowY: 'auto' }}>
              {attachments.map((attachment) => (
                <BrandfolderAsset
                  key={attachment.id}
                  attachment={attachment}
                  handleAttachmentClick={handleAttachmentClick}
                />
              ))}
            </List>
          </>
        )}
      </StyledPaper>
    );
  };

  const AssetsContent = () => {
    if (!sections || !assets || !pagination) {
      return <>{loading && <CenteredLoader />}</>;
    }

    return (
      <>
        {loading && <CenteredLoader />}
        {!loading && (
          <>
            <GridList
              cellHeight={87}
              cols={3}
              spacing={10}
              style={{ padding: '10px', margin: '0 10px 10px 10px', width: '400px' }}
            >
              {assets.map((asset) => (
                <GridListTile key={asset.id}>
                  <ImageItem
                    src={asset.attributes.thumbnailUrl}
                    title={asset.attributes.name}
                    onClick={() => handleAssetClick(asset)}
                    onDragStart={(e) => {
                      const data: DragDataTransfer = { type: 'bandFolder', data: { id: asset.id || '' } };
                      e.dataTransfer.setData('email-editor-data', JSON.stringify(data));
                    }}
                  >
                    <FileTypePill>
                      {getFileTypePillContent(asset.attributes.attachmentCount, asset.attributes.extension)}
                    </FileTypePill>
                  </ImageItem>
                </GridListTile>
              ))}
            </GridList>
            {assets.length === 0 && (
              <Alert severity="info" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                {t('brandfolder.noAssetsFound')}
              </Alert>
            )}
          </>
        )}
        {pagination.total > 0 && (
          <Pagination
            count={pagination.totalPages}
            page={pagination.page}
            shape="rounded"
            onChange={handleChange}
            size="small"
            style={{ marginTop: 'auto' }}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    fetchData(1, selectedSection, searchValue, hiddenSearchValue);
  }, []);

  if (!sections || !assets || !pagination) {
    return (
      <StyledPaper>
        {error && (
          <Alert severity="info" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            {error}
          </Alert>
        )}
        {!error && <CenteredLoader />}
      </StyledPaper>
    );
  }

  return (
    <>
      {brandfolderPage === 'index' && (
        <StyledPaper>
          {sections && (
            <SectionSelectContainer>
              {t('brandfolder.section')}:
              <SectionsTextField select size="small" value={selectedSection} onChange={handleSectionChange}>
                <MenuItem value="all">All</MenuItem>
                {sections.map((section) => (
                  <MenuItem key={section.id} value={section.id}>
                    {section.attributes.name}
                  </MenuItem>
                ))}
              </SectionsTextField>
            </SectionSelectContainer>
          )}
          {assets && (
            <>
              <SearchBarRow>
                <form onSubmit={handleSearch} style={{ width: '100%', marginRight: '10px' }}>
                  <TextField
                    placeholder={t('brandfolder.search')}
                    type="text"
                    variant="outlined"
                    size="small"
                    value={searchValue}
                    fullWidth
                    onChange={(e) => setSearchValue(e.target.value)}
                    InputProps={{
                      endAdornment: searchValue !== '' && (
                        <InputAdornment position="end">
                          <IconButton size="small" onClick={handleSearchClear}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </form>
                {pins && pins.length > 0 && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={onPinsWindowOpen}
                    startIcon={<PinIcon />}
                  >
                    {pins.length}
                  </Button>
                )}
              </SearchBarRow>
              {pins && pins.length > 0 && (
                <Box display="flex" justifyContent="flex-start" style={{ width: '360px' }}>
                  <ArtworkFormControlLabel
                    control={
                      <Checkbox checked={isCurrentWeekChecked} onChange={handleCurrentWeekCheckbox} color="primary" />
                    }
                    label={t('brandfolder.artworkForWeekOfPublication')}
                  />
                </Box>
              )}
              <AssetsContent />
            </>
          )}
        </StyledPaper>
      )}
      {brandfolderPage === 'attachments' && <AttachmentsList />}
      <PinsWindow
        open={pinsWindowOpen}
        onCloseClick={onPinsWindowClose}
        fullScreenOnMobile
        pins={pins}
        onPinClick={(query) => handlePinClick(query)}
      />
    </>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  height: 20rem;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const SearchBarRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 370px;
`;

const ImageItem = styled.div<{ src: string }>`
  width: 100px;
  height: 80px;
  cursor: pointer;
  background-image: url('${({ src }) => src}');
  background-size: contain;
  border: 1px solid #f3f3f3;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 4px;
  position: relative;
`;

const FileTypePill = styled.div`
  background: #f8fafc;
  border-radius: 10px;
  position: absolute;
  width: 34px;
  bottom: -7px;
  left: calc(50% - 17px);
  text-align: center;
  font-size: 75%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 2px;
`;

const SectionSelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 5px 0px 5px 30px;
  font-size: 0.75rem;
`;

const StyledPaper = styled(Paper)`
  min-height: 567px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  align-items: center;
`;

const ArtworkFormControlLabel = withStyles({
  root: {
    '& .MuiButtonBase-root': {
      padding: '4px'
    },
    '& .MuiTypography-root': {
      fontSize: '90%'
    }
  }
})(FormControlLabel);

const SectionsTextField = withStyles({
  root: {
    '& .MuiInputBase-root': {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      marginLeft: '5px',
      color: '#00A5AA'
    },
    '& .MuiInput-underline:before': {
      border: 0
    },
    '& .MuiInputBase-input': {
      padding: '3px 24px 3px 0'
    }
  }
})(TextField);

export default Brandfolder;
