import React, { FunctionComponent } from 'react';
import { Redirect, Switch } from 'react-router';
import RoutesConfig from '../../../routes/RoutesConfig';
import SignedInRoute from '../../../routes/SignedInRoute';
import linksConstants from '../../../config/app/linksConstants';
import { useTypedSelector } from '../../../utils';
import { getUserMainPage } from '../../../utils/redirection';

type SignedInRoutesContainerProps = {};

const SignedInRoutesContainer: FunctionComponent<SignedInRoutesContainerProps> = () => {
  const role = useTypedSelector((state) => state.auth.role);
  const user = useTypedSelector((state) => state.auth.user);
  const signedInRoutes = RoutesConfig.createSignedInRoutes(role);

  return (
    <Switch>
      {signedInRoutes.map((route) => {
        return (
          <SignedInRoute
            name={route.name}
            key={route.name}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        );
      })}
      <Redirect to={getUserMainPage(user)} />
    </Switch>
  );
};

export default SignedInRoutesContainer;
