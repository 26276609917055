import { BaseSettings, RequestSettings } from '../../../utils/httpRequest';
import { DoAppInvoiceDateRange, IndexDoAppMerchantsRequestParams, UpdateMerchantRequestBody } from './types';

export const index = (settings: RequestSettings<IndexDoAppMerchantsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/merchants?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const getInvoiceResources = (
  settings: RequestSettings<{ id: string; range: DoAppInvoiceDateRange }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/invoices/:id/invoice-resources?range=:range`
  },
  params: settings.requestParams
});

export const createInvoice = (
  settings: RequestSettings<{ id: string; range: DoAppInvoiceDateRange }, {}>
): BaseSettings => ({
  query: {
    method: 'post',
    url: `/do-app/v1/invoices/:id/create-invoice?range=:range`
  },
  params: settings.requestParams
});

export const getOrganizationMerchant = (): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/merchants/mine`
  }
});

export const updateMerchant = (
  settings: RequestSettings<{ merchantId: string }, UpdateMerchantRequestBody>
): BaseSettings => ({
  query: {
    method: 'patch',
    url: `/do-app/v1/merchants/:merchantId`
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});
