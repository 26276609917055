import { Campaign } from '../store/Campaigns/types';
import { formatPublishDate } from './dates';

export const getCampaignDateRange = (campaign: Campaign): string => {
  if (campaign.projects.length === 0) {
    return '';
  }

  let earliestDate = campaign.projects[0].publicationDateFrom;
  let latestDate = campaign.projects[0].publicationDateTo;

  for (const project of campaign.projects) {
    if (project.publicationDateFrom < earliestDate) {
      earliestDate = project.publicationDateFrom;
    }
    if (project.publicationDateTo > latestDate) {
      latestDate = project.publicationDateTo;
    }
  }

  return `${formatPublishDate(earliestDate)} -  ${formatPublishDate(latestDate)}`;
};
