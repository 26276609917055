import React, { FunctionComponent, useEffect, useState } from 'react';
import { printProjectsOperations } from '../../../../../store/PrintProjects';
import { createNetworkErrorObject, useTypedSelector } from '../../../../../utils';
import Toast from '../../../../Shared/Toast/Toast';
import { PrintProject } from '../../../../../store/PrintProjects/types';
import { useTranslation } from 'react-i18next';
import { Row, RowCenter, Strong } from '../../../../Shared/StyledComponents';
import Loader from '../../../../Shared/Loading/Loader';
import { Button } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import PrintPublishStepper, { PrintPublishStep } from './Items/PrintPublishStepper';

type PrintPublishReviewStepProps = {
  project: PrintProject;
  onPreviewLoaded: (data: { url: string; pages: number }) => void;
  onlyPreview: boolean;
  reScheduling?: boolean;
  setNextButtonDisabled: (state: boolean) => void;
};

const PrintPublishReviewStep: FunctionComponent<PrintPublishReviewStepProps> = ({
  project,
  onPreviewLoaded,
  onlyPreview,
  reScheduling,
  setNextButtonDisabled
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const publishWindow = useTypedSelector((state) => state.printProjects.publishWindow);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setNextButtonDisabled(true);
      try {
        if (!reScheduling) {
          const projectData = await printProjectsOperations.generatePrintPdf(project.id);
          onPreviewLoaded({
            url: projectData.url,
            pages: projectData.pages
          });
          setPdfUrl(projectData.url);
        } else {
          const projectData = await printProjectsOperations.getProjectPreviewUrl(project);
          onPreviewLoaded({
            url: projectData.url,
            pages: projectData.pages
          });
          setPdfUrl(projectData.url);
        }
      } catch (e) {
        const errorOb = createNetworkErrorObject(e);
        if (errorOb.message) {
          setError(errorOb.message);
        }
        Toast.error(t('notifications.publishPrintProject.error', { rawError: `(${errorOb.message})` }));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (publishWindow.project && publishWindow.project.id === project.id && publishWindow.renderJobStatus === 'done') {
      setNextButtonDisabled(false);
    }
  }, [publishWindow]);

  return (
    <div>
      {(error || publishWindow.renderJobStatus === 'error') && (
        <>
          <Row>{t('windows.publishPrintProject.error', { rawError: `(${error})` })}</Row>
        </>
      )}
      {(loading || publishWindow.renderJobStatus === 'inProgress') && (
        <>
          <Row>{t('windows.publishPrintProject.loading')}</Row>
          <Row>
            <Loader />
          </Row>
        </>
      )}
      {!loading && !error && (reScheduling || publishWindow.renderJobStatus === 'done') && (
        <>
          {!onlyPreview && <PrintPublishStepper activeStep={PrintPublishStep.Review} />}
          <RowCenter>{t('windows.publishPrintProject.finalProjectPdfGenerated')}</RowCenter>
          <RowCenter>
            <Button
              color={'secondary'}
              startIcon={<LaunchIcon />}
              variant="contained"
              size="large"
              onClick={() => window.open(pdfUrl)}
            >
              {t('common.open')}
            </Button>
          </RowCenter>
          <RowCenter>
            {!onlyPreview && <Strong>{t('windows.publishPrintProject.clickPublishToProceed')}</Strong>}
          </RowCenter>
        </>
      )}
    </div>
  );
};

export default PrintPublishReviewStep;
