import update from 'immutability-helper';
import {
  PrintProject,
  PrintProjectsActionsConstants,
  PrintProjectsActionType,
  PrintProjectsState,
  PrintProjectStatus
} from './types';
import { normalizePrintProjects } from '../helpers';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { DashboardActionsConstants } from '../Dashboard/types';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: PrintProjectsState = {
  projects: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  },
  publishWindow: {
    opened: false,
    renderJobStatus: 'inProgress',
    project: null
  },
  flipBookPreview: {
    project: null
  }
};

const printProjectsPaginationReducer = createPaginationReducer<PrintProjectsState, PrintProject>(
  PaginationStoreModule.PRINT_PROJECTS
);

const printProjectsTableSearchReducer = createTableSearchReducer<PrintProject, PrintProjectsState>(
  TableSearchStoreModule.PRINT_PROJECTS
);

export default function printProjectsReducer(state = initialState, action: WithLogoutAction<PrintProjectsActionType>) {
  switch (action.type) {
    case PrintProjectsActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case PrintProjectsActionsConstants.INDEX_SUCCESS: {
      const data = normalizePrintProjects(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        projects: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case PrintProjectsActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        projects: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case PrintProjectsActionsConstants.REMOVE_PROJECT:
      return update(state, {
        projects: {
          result: { $set: state.projects.result.filter((projectId) => projectId !== action.payload) }
        }
      });

    case PrintProjectsActionsConstants.SET_FLIP_BOOK_PROJECT:
      return update(state, {
        flipBookPreview: {
          project: { $set: action.payload.project }
        }
      });

    case PrintProjectsActionsConstants.SET_FLIP_BOOK_STATE: {
      if (state.flipBookPreview.project && action.payload.projectId === state.flipBookPreview.project.id) {
        return update(state, {
          flipBookPreview: {
            project: {
              flipBookState: { $set: action.payload.state }
            }
          }
        });
      }

      return state;
    }

    case PrintProjectsActionsConstants.PUBLISH_PROJECT: {
      const project = state.projects.entities[action.payload.id];
      if (!project) {
        return state;
      }

      return update(state, {
        projects: {
          entities: {
            [action.payload.id]: {
              status: { $set: PrintProjectStatus.SCHEDULED },
              vertexErrors: { $set: [] }
            }
          }
        }
      });
    }

    case PrintProjectsActionsConstants.PUBLISH_PROJECT_WINDOW_OPEN:
      return update(state, {
        publishWindow: {
          renderJobStatus: { $set: action.payload.reScheduling ? 'done' : 'inProgress' },
          opened: { $set: true },
          project: { $set: action.payload.project }
        }
      });

    case PrintProjectsActionsConstants.PUBLISH_PROJECT_WINDOW_JOB_RENDERING_DONE: {
      const currentProject = state.publishWindow.project;
      if (currentProject && currentProject.id === action.payload.projectId) {
        return update(state, {
          publishWindow: {
            renderJobStatus: { $set: 'done' }
          }
        });
      }
      return state;
    }

    case PrintProjectsActionsConstants.PUBLISH_PROJECT_WINDOW_JOB_RENDERING_ERROR: {
      const currentProject = state.publishWindow.project;
      if (currentProject && currentProject.id === action.payload.projectId) {
        return update(state, {
          publishWindow: {
            renderJobStatus: { $set: 'error' }
          }
        });
      }
      return state;
    }

    case PrintProjectsActionsConstants.PUBLISH_PROJECT_WINDOW_CLOSE:
      return update(state, {
        publishWindow: {
          opened: { $set: false },
          project: { $set: null }
        }
      });

    case PrintProjectsActionsConstants.CHECK_IN_PROJECT: {
      const project = state.projects.entities[action.payload.projectId];
      if (project) {
        return update(state, {
          projects: {
            entities: {
              [action.payload.projectId]: {
                checkedInBy: { $set: action.payload.user }
              }
            }
          }
        });
      }
      return state;
    }

    case DashboardActionsConstants.TEST_FLAG_TOGGLED: {
      const project = state.projects.entities[action.payload.id];
      if (!project) {
        return state;
      }

      return update(state, {
        projects: {
          entities: {
            [action.payload.id]: {
              test: { $set: action.payload.test }
            }
          }
        }
      });
    }

    case DashboardActionsConstants.PROJECT_CHECKED_OUT: {
      const project = state.projects.entities[action.payload.projectId];

      if (project) {
        return update(state, {
          projects: {
            entities: {
              [action.payload.projectId]: {
                checkedInBy: { $set: undefined }
              }
            }
          }
        });
      }
      return state;
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return printProjectsTableSearchReducer(
        printProjectsPaginationReducer(state, action),
        action
      ) as PrintProjectsState;
    }
  }
}
