import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../../Shared/StyledComponents';
import Toast from '../../../../Shared/Toast/Toast';
import { TextField } from '@material-ui/core';
import LoadingModal from '../../../../Shared/Loading/LoadingModal';
import { push } from 'connected-react-router';
import linksConstants from '../../../../../config/app/linksConstants';
import { AdminEmailTemplate } from '../../../../../store/AdminEmailTemplates/types';
import { adminEmailTemplatesOperations } from '../../../../../store/AdminEmailTemplates';
import { createNetworkErrorObject } from '../../../../../utils';

type AdminCopyEmailTemplateWindowProps = {
  open: boolean;
  template: AdminEmailTemplate;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminCopyEmailTemplateWindow: FunctionComponent<AdminCopyEmailTemplateWindowProps> = ({
  template,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [templateName, setTemplateName] = useState<string>(template.name);
  const [copyingTemplate, setCopyingTemplate] = useState<boolean>(false);

  const onSubmit = async () => {
    try {
      setCopyingTemplate(true);
      const newTemplate = await adminEmailTemplatesOperations.copyTemplate(template.id, templateName);
      onCloseClick();
      Toast.success(t('notifications.adminEmailTemplates.copyTemplateSuccess'));

      dispatch(push(linksConstants.ADMINISTRATION.EMAIL_TEMPLATES.EDIT(newTemplate.id)));
    } catch (e) {
      const error = createNetworkErrorObject(e);

      if (error.message) {
        switch (error.message) {
          case 'CLONING_ERROR':
            Toast.error(t('notifications.adminEmailTemplates.templateCloningError'));
            break;
          case 'NO_FILES_FOUND_TO_CLONE':
            Toast.error(t('notifications.adminEmailTemplates.noFilesFoundToClone'));
            break;
          case 'NAME_ALREADY_TAKEN':
            Toast.error(t('notifications.adminEmailTemplates.nameAlreadyTaken'));
            break;
          case 'TEMPLATE_NOT_FOUND':
            Toast.error(t('notifications.adminEmailTemplates.sourceProjectNotFound'));
            break;
          case 'THUMBNAIL_CLONING_ERROR':
            Toast.error(t('notifications.adminEmailTemplates.thumbnailCloningError'));
            break;
          default:
            Toast.error(t('notifications.common.errorOperation'));
            break;
        }
      } else {
        Toast.error(t('notifications.common.errorOperation'));
      }
    } finally {
      setCopyingTemplate(false);
    }
  };

  useEffect(() => {
    if (open) {
      setTemplateName(`${template.name} ${t('pages.adminEmailTemplates.windows.copyEmailTemplate.copySuffix')}`);
    }
  }, [open]);

  return (
    <>
      {copyingTemplate && (
        <LoadingModal message={t('pages.adminEmailTemplates.windows.copyEmailTemplate.copyingTemplate')} />
      )}
      <ModalConfirmationWindow
        open={open}
        header={t('pages.adminEmailTemplates.windows.copyEmailTemplate.header')}
        okButtonText={t('pages.adminEmailTemplates.windows.copyEmailTemplate.copy')}
        onCloseClick={onCloseClick}
        onSubmit={onSubmit}
        disableSubmitButton={templateName === ''}
      >
        <Row>{t('pages.adminEmailTemplates.windows.copyEmailTemplate.content')}</Row>
        <Row>
          <TextField
            id="project-name"
            value={templateName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTemplateName(event.target.value);
            }}
            size="small"
            fullWidth
            label={t('pages.adminEmailTemplates.windows.copyEmailTemplate.name')}
            variant="outlined"
          />
        </Row>
      </ModalConfirmationWindow>
    </>
  );
};

export default AdminCopyEmailTemplateWindow;
