import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../../Shared/StyledComponents';
import Toast from '../../../../../Shared/Toast/Toast';
import { AdBanner } from '../../../../../../store/AdminAds/types';
import { adminAdsOperations } from '../../../../../../store/AdminAds';

type AdminDeleteAdBannerWindowProps = {
  open: boolean;
  banner: AdBanner | null;
  onCloseClick: () => void;
  onFormSuSubmit?: () => void;
  fullScreenOnMobile: boolean;
};

const AdminDeleteAdBannerWindow: FunctionComponent<AdminDeleteAdBannerWindowProps> = ({
  banner,
  onCloseClick,
  onFormSuSubmit,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!banner) return null;

  const onSubmit = async () => {
    try {
      await dispatch(adminAdsOperations.deleteAdBanner(banner.bannerId));
      onCloseClick();
      if (onFormSuSubmit) {
        onFormSuSubmit();
      }
      Toast.success(t('notifications.adminAds.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminAds.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminAds.windows.deleteAdBanner')}
      okButtonText={t('pages.adminAds.windows.completelyDeleteBanner')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.adminAds.windows.deleteAdBannerContent')}</Row>
      <Row>
        <Strong>{banner.bannerName}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default AdminDeleteAdBannerWindow;
