import createNetworkErrorObject from './createNetworkErrorObject';
import Toast from '../components/Shared/Toast/Toast';
import { TFunction } from 'i18next';

export const handleContributorInvitationErrorCode = (e: any, t: TFunction) => {
  const networkError = createNetworkErrorObject(e);
  switch (networkError.message) {
    case 'user_already_registered_as_contributor':
      return Toast.info(t('notifications.story.contributorAlreadyInvited'));
    case 'story_not_found':
      return Toast.error(t('notifications.story.storyNotFound'));
    case 'user_type_not_found':
    case 'contributor_default_role_not_found':
      return Toast.error(t('notifications.story.configurationIssue'));
    case 'user_already_member_of_organization':
      return Toast.info(t('notifications.story.userAlreadyMemberOfOrganization'));
    default:
      return Toast.error(t('notifications.common.errorOperation'));
  }
};

export const handleWordpressIntegrationError = (e: any, t: TFunction) => {
  const networkError = createNetworkErrorObject(e);
  switch (networkError.message) {
    case 'bad_data':
      return Toast.error(t('notifications.story.badDataProvidedForWordpressIntegration'));
    case 'cannot_connect':
      return Toast.error(t('notifications.story.cannotConnectWithWordpressIntegration'));
    case 'url_already_exists':
      return Toast.error(t('notifications.story.urlAlreadyExists'));
    default:
      return Toast.error(t('notifications.story.badDataProvidedForWordpressIntegration'));
  }
};

export const handleDamSystemError = (e: any, t: TFunction) => {
  const networkError = createNetworkErrorObject(e);
  switch (networkError.message) {
    default:
      return Toast.error(t('notifications.common.errorOperation'));
  }
};
