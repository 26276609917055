import React, { useEffect, useState } from 'react';
import { adminEmailTemplatesOperations } from '../../../store/AdminEmailTemplates';
import { adminPrintTemplatesOperations } from '../../../store/AdminPrintTemplates';
import { TemplateTypeName } from '../../../store/Templates/types';
import { withTheme } from '@material-ui/core';
import styled from 'styled-components';
import Loader from '../Loading/Loader';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import { emailTemplateTypes, printTemplateTypes } from '../../../utils/projectTypeUtils';

type TemplateThumbnailProps = {
  thumbnailUrl: string | null;
  width: string;
  height: string;
};

const TemplateThumbnail: React.FC<TemplateThumbnailProps> = ({ thumbnailUrl, width, height }) => {
  return (
    <ThumbnailContainer width={width} height={height}>
      {thumbnailUrl && (
        <img src={thumbnailUrl} alt="" style={{ width, height, objectFit: 'cover', objectPosition: 'top center' }} />
      )}
      {!thumbnailUrl && (
        <NoThumbnail width={width} height={height}>
          <WallpaperIcon color="secondary" />
        </NoThumbnail>
      )}
    </ThumbnailContainer>
  );
};

const ThumbnailContainer = withTheme(styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`);

const NoThumbnail = withTheme(styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border: 1px solid #eee;
`);

export default TemplateThumbnail;
