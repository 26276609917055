import React, { FunctionComponent, useEffect, useState } from 'react';
import { FullSizePageContainer } from '../../PageStyledComponents';
import { RouteComponentProps } from 'react-router';
import Toast from '../../../Shared/Toast/Toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Loader from '../../../Shared/Loading/Loader';
import { createNetworkErrorObject, useTypedSelector } from '../../../../utils';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import { printProjectsOperations } from '../../../../store/PrintProjects';
import { PrintProject } from '../../../../store/PrintProjects/types';
import { connectToProjectChannel } from '../../../../utils/webSocket';
import nes from '@hapi/nes/lib/client';
import ProjectAlreadyCheckedIn from '../Windows/ProjectAlreadyCheckedIn';
import ForcingCheckOutNotificationWindow from '../Windows/ForcingCheckOutNotificationWindow';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import { v4 } from 'uuid';
import DesignerEditor from '../Common/DesignerEditor';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import { DamSystemName } from '../../../../store/SystemSettings/types';

type BulletinEditorPageProps = RouteComponentProps<{ projectId: string }> & {};

const BulletinEditorPage: FunctionComponent<BulletinEditorPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [projectAlreadyCheckedInErrorMessage, setProjectAlreadyCheckedInErrorMessage] = useState('');
  const [projectData, setProjectData] = useState<PrintProject | null>(null);
  const [damStatus, setDamStatus] = useState<{ damActive: boolean; system: DamSystemName | null }>({
    damActive: false,
    system: null
  });
  const [
    projectAlreadyCheckedInWindowOpen,
    onProjectAlreadyCheckedInWindowOpen,
    onProjectAlreadyCheckedInWindowClose
  ] = useOpenHandler();
  const authData = useTypedSelector((state) => state.auth);

  const projectId = match.params.projectId;

  useEffect(() => {
    let webSocketClient: nes.Client | null = null;

    const fetchData = async () => {
      try {
        const res = await printProjectsOperations.show(projectId);
        setProjectData(res);
        try {
          setDamStatus(await myOrganizationOperations.getDamStatus());
        } catch (e) {
          console.warn('Error occurred when trying to fetch DAM status', e);
        }
        setLoading(false);
      } catch (e) {
        Toast.error(
          t('notifications.newsletterProjectFetch.error', { rawError: `(${createNetworkErrorObject(e).message})` })
        );
      }

      try {
        await printProjectsOperations.checkIn(projectId, v4());
        webSocketClient = await connectToProjectChannel(projectId, authData.token, dispatch);
      } catch (e) {
        const error = createNetworkErrorObject(e);
        setProjectAlreadyCheckedInErrorMessage(error.message || '');
        onProjectAlreadyCheckedInWindowOpen();
        return;
      }
    };
    fetchData();

    return () => {
      let unsubscribeTimeout: number | undefined;
      let runs = 0;
      const maxRuns = 10;

      const unsubscribeFromWebSocket = () => {
        clearTimeout(unsubscribeTimeout);

        unsubscribeTimeout = setTimeout(() => {
          if (webSocketClient) {
            webSocketClient.disconnect();
            return;
          }
          if (runs++ >= maxRuns) {
            return;
          }

          unsubscribeFromWebSocket();
        }, 2000);
      };

      if (webSocketClient) {
        webSocketClient.disconnect();
      } else {
        unsubscribeFromWebSocket();
      }
    };
  }, []);

  const saveProjectName = async (name: string) => {
    if (projectData) {
      try {
        await printProjectsOperations.savePrintProjectName(projectData, name);
        Toast.success(t('notifications.projectNameChange.success'));
      } catch (e) {
        Toast.error(t('notifications.projectNameChange.error'));
      }
    }
  };

  if (!projectData) return null;

  return (
    <FullSizePageContainer>
      {loading && <Loader />}
      {!loading && !projectAlreadyCheckedInWindowOpen && (
        <DesignerEditor
          damStatus={damStatus}
          publishDate={projectData.publishDate}
          enableNotes
          externalId={projectData.externalId}
          projectId={projectData.id}
          projectHash={projectData.hash}
          projectName={projectData.name}
          onProjectNameChange={saveProjectName}
          onSave={() => printProjectsOperations.saveContent(projectId)}
          onBackButtonClick={() => dispatch(push(linksConstants.DASHBOARD.INDEX))}
        />
      )}
      <ProjectAlreadyCheckedIn
        open={projectAlreadyCheckedInWindowOpen}
        onCloseClick={onProjectAlreadyCheckedInWindowClose}
        errorMessage={projectAlreadyCheckedInErrorMessage}
        fullScreenOnMobile
      />
      <ForcingCheckOutNotificationWindow activeProject={projectData} />
    </FullSizePageContainer>
  );
};

export default BulletinEditorPage;
