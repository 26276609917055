import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatTableTimestamp, formatTableTimestampWithHours, useTypedSelector } from '../../../../../utils';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import DataTable from '../../../../Shared/DataTable/DataTable';
import { DoAppPromotion, DoAppPromotionRangeQuery } from '../../../../../store/DoApp/Promotions/types';
import { TableSearchStoreModule } from '../../../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../../../store/Pagination/types';
import { doAppPromotionsOperations } from '../../../../../store/DoApp/Promotions';
import { usePaginationWatch } from '../../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../../Shared/DataTable/useTableSearchWatch';
import { useDispatch } from 'react-redux';
import { TableLink, TableLinkDisabled } from '../../../../Shared/DataTable/ColumnCell/UrlColumnCell';
import { DoAppPromotionClaimStatus } from '../../../../../store/DoApp/PromotionClaims/types';

type PromotionTabProps = {
  onClaimClick: (claimStatus: DoAppPromotionClaimStatus, promotionId: string) => void;
};

const PromotionsTab: React.FC<PromotionTabProps> = ({ onClaimClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [promotionFilter, setPromotionFilter] = React.useState(DoAppPromotionRangeQuery.ACTIVE);

  const doAppPromotions = useTypedSelector((state) => state.doAppMerchantPromotions.data);
  const isFailed = useTypedSelector((state) => state.doAppMerchantPromotions.index.isFailed);
  const isLoading = useTypedSelector((state) => state.doAppMerchantPromotions.index.isLoading);
  const pagination = useTypedSelector((state) => state.doAppMerchantPromotions.pagination);
  const meta = useTypedSelector((state) => state.doAppMerchantPromotions.meta);
  const tableSearch = useTypedSelector((state) => state.doAppMerchantPromotions.tableSearch);

  usePaginationWatch(pagination, [doAppPromotionsOperations.indexMerchantPromotions.bind(null, promotionFilter)]);
  useTableSearchWatch<DoAppPromotion>(tableSearch, [
    doAppPromotionsOperations.indexMerchantPromotions.bind(null, promotionFilter)
  ]);

  const handlePromotionFilterChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFilter = (event.target as HTMLInputElement).value as DoAppPromotionRangeQuery;
    setPromotionFilter(selectedFilter);
    await dispatch(doAppPromotionsOperations.indexMerchantPromotions(selectedFilter));
  };

  return (
    <>
      <FormControl component="fieldset">
        <RadioGroup name="promotions-radio-group" value={promotionFilter} onChange={handlePromotionFilterChange} row>
          <FormControlLabel
            value={DoAppPromotionRangeQuery.ALL}
            control={<Radio />}
            label={t('pages.myOrganization.merchant.promotions.filter.all')}
          />
          <FormControlLabel
            value={DoAppPromotionRangeQuery.ACTIVE}
            control={<Radio />}
            label={t('pages.myOrganization.merchant.promotions.filter.active')}
          />
          <FormControlLabel
            value={DoAppPromotionRangeQuery.UPCOMING}
            control={<Radio />}
            label={t('pages.myOrganization.merchant.promotions.filter.upcoming')}
          />
          <FormControlLabel
            value={DoAppPromotionRangeQuery.EXPIRED}
            control={<Radio />}
            label={t('pages.myOrganization.merchant.promotions.filter.expired')}
          />
        </RadioGroup>
      </FormControl>
      <DataTable<DoAppPromotion, 'acceptedClaims' | 'pendingClaims' | 'rejectedClaims'>
        enableSearch={true}
        columnDefinitions={[
          {
            name: 'name',
            sortable: true,
            render: (row) => <>{row.name}</>
          },
          {
            name: 'startDate',
            sortable: true,
            render: (row) => <>{formatTableTimestamp(row.startDate)}</>
          },
          {
            name: 'endDate',
            sortable: true,
            render: (row) => <>{formatTableTimestamp(row.endDate)}</>
          },
          {
            name: 'acceptedClaims',
            render: (row) => {
              return (
                <>
                  {meta.claims[row.id] ? (
                    meta.claims[row.id].accepted > 0 ? (
                      <TableLink onClick={() => onClaimClick(DoAppPromotionClaimStatus.ACCEPTED, row.id)}>
                        {meta.claims[row.id].accepted}
                      </TableLink>
                    ) : (
                      <TableLinkDisabled>0</TableLinkDisabled>
                    )
                  ) : (
                    <TableLinkDisabled>0</TableLinkDisabled>
                  )}
                </>
              );
            }
          },
          {
            name: 'pendingClaims',
            render: (row) => {
              return (
                <>
                  {meta.claims[row.id] ? (
                    meta.claims[row.id].pending > 0 ? (
                      <TableLink onClick={() => onClaimClick(DoAppPromotionClaimStatus.PENDING, row.id)}>
                        {meta.claims[row.id].pending}
                      </TableLink>
                    ) : (
                      <TableLinkDisabled>0</TableLinkDisabled>
                    )
                  ) : (
                    <TableLinkDisabled>0</TableLinkDisabled>
                  )}
                </>
              );
            }
          },
          {
            name: 'rejectedClaims',
            render: (row) => {
              return (
                <>
                  {meta.claims[row.id] ? (
                    meta.claims[row.id].rejected > 0 ? (
                      <TableLink onClick={() => onClaimClick(DoAppPromotionClaimStatus.REJECTED, row.id)}>
                        {meta.claims[row.id].rejected}
                      </TableLink>
                    ) : (
                      <TableLinkDisabled>0</TableLinkDisabled>
                    )
                  ) : (
                    <TableLinkDisabled>0</TableLinkDisabled>
                  )}
                </>
              );
            }
          },
          {
            name: 'createdAt',
            render: (row) => <>{formatTableTimestampWithHours(row.createdAt)}</>,
            sortable: true
          }
        ]}
        tPath={'pages.doApp.merchants.table'}
        data={doAppPromotions}
        tableSearchProps={{
          tableSearch,
          module: TableSearchStoreModule.DO_APP_MERCHANT_PROMOTIONS
        }}
        paginationProps={{
          pagination,
          module: PaginationStoreModule.DO_APP_MERCHANT_PROMOTIONS,
          noUrlUpdate: true
        }}
        isFailed={isFailed}
        isLoading={isLoading}
        disablePaper
      />
    </>
  );
};

export default PromotionsTab;
