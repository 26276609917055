export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

export function rgbToHex(orig: string) {
  const rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);

  return rgb
    ? (((rgb[1] as unknown) as number) | (1 << 8)).toString(16).slice(1) +
        (((rgb[2] as unknown) as number) | (1 << 8)).toString(16).slice(1) +
        (((rgb[3] as unknown) as number) | (1 << 8)).toString(16).slice(1)
    : orig;
}
