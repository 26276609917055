import React from 'react';
import { Box } from '@material-ui/core';
import ProjectClones from './ProjectClones';
import { AnyProject } from '../../../../store/Dashboard/types';
import { Story } from '../../../../store/Stories/types';

type ProjectExpandableContentProps = {
  entity: AnyProject | Story;
  open: boolean;
};

const ProjectExpandableContent: React.FC<ProjectExpandableContentProps> = ({ entity, open }) => {
  return (
    <Box margin={2}>
      <ProjectClones project={entity} open={open} />
    </Box>
  );
};

export default ProjectExpandableContent;
