import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { AdminCreatePrintTemplateValues } from '../../../../../store/AdminPrintTemplates/types';
import { adminPrintTemplatesOperations } from '../../../../../store/AdminPrintTemplates';
import { useDispatch } from 'react-redux';
import { AdminCheckboxInput, AdminSelectInput, AdminTextInput } from '../../Shared/AdminFormInputs';
import { MenuItem } from '@material-ui/core';
import Toast from '../../../../Shared/Toast/Toast';
import { PrintProjectTemplateTypeName } from '../../../../../store/Templates/types';
import app from '../../../../../config/app/app';
import { printTemplateTypes } from '../../../../../utils/projectTypeUtils';
import { FormikProps } from 'formik';

type AdminCreatePrintTemplateWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminCreatePrintTemplateWindow: FunctionComponent<AdminCreatePrintTemplateWindowProps> = ({
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = async (values: AdminCreatePrintTemplateValues) => {
    try {
      await dispatch(adminPrintTemplatesOperations.create(values));
      await dispatch(adminPrintTemplatesOperations.index());
      onCloseClick();
      Toast.success(t('notifications.adminPrintTemplates.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminPrintTemplates.errorOperation'));
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="name" section="adminPrintTemplates" />
      <AdminTextInput t={t} name="description" section="adminPrintTemplates" />
      <AdminCheckboxInput t={t} name="designProject" section="adminPrintTemplates" />
      {!props.values.designProject && (
        <AdminSelectInput t={t} name="type" section="adminPrintTemplates">
          {printTemplateTypes.map((name) => (
            <MenuItem key={name} value={name}>
              {t(`common.projectType.${name}`)}
            </MenuItem>
          ))}
        </AdminSelectInput>
      )}
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.create')}
      header={t('pages.adminPrintTemplates.windows.createPrintTemplate')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{ name: '', description: '', type: PrintProjectTemplateTypeName.BULLETIN, designProject: false }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(app.maxInputLength).required(),
        description: Yup.string().max(app.maxInputLength).required(),
        type: Yup.string().required(),
        designProject: Yup.boolean()
      })}
    />
  );
};

export default AdminCreatePrintTemplateWindow;
