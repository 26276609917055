import { mailingListsOperations } from '../../../../../store/MailingLists';
import Toast from '../../../../Shared/Toast/Toast';
import { emailProjectsOperations } from '../../../../../store/EmailProjects';
import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { EmailProject, EmailProjectTemplateTypeDisplayName } from '../../../../../store/EmailProjects/types';
import moment from 'moment';
import { SystemSettingType } from '../../../../../store/SystemSettings/types';
import { useDispatch } from 'react-redux';
import { MailingList } from '../../../../../store/MailingLists/types';
import { formatPublishDate, useTypedSelector } from '../../../../../utils';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import { AdminSelectInput, AdminTextInput } from '../../../Admin/Shared/AdminFormInputs';
import Loader from '../../../../Shared/Loading/Loader';
import { Button, MenuItem } from '@material-ui/core';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';
import TelegramIcon from '@material-ui/icons/Telegram';
import { useTranslation } from 'react-i18next';

export const useEmailPublish = (project: EmailProject | null, settings: { displayPublicationDate: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [sendingToTestingListInProgress, setSendingToTestingListInProgress] = useState<boolean>(false);
  const [mailingLists, setMailingLists] = useState<MailingList[]>([]);
  const systemSettings = useTypedSelector((state) => state.auth.systemSettings);
  const organization = useTypedSelector((state) => state.auth.organization);

  const onSubmit = async (values: {
    mailingListId: string;
    publishTime: string;
    subject: string;
    publishDate: string;
  }) => {
    if (values.mailingListId && project) {
      const mailingList = mailingLists.find((list) => list.id === values.mailingListId);
      if (!mailingList || mailingList.activeMemberCount <= 0) {
        Toast.error(t('notifications.projectPublished.noMembers'));
        return;
      }

      try {
        const publishDateAndTime = new Date(`${values.publishDate}T${values.publishTime}`);

        /** Only update publish date entity */
        await dispatch(
          emailProjectsOperations.publishProject(
            project.id,
            values.mailingListId,
            publishDateAndTime,
            values.subject,
            values.publishDate
          )
        );
        Toast.success(t('notifications.projectPublished.success'));

        localStorage.setItem('sc-email-publish-time', values.publishTime);
      } catch (e) {
        Toast.error(t('notifications.projectPublished.error'));
        throw e;
      }
    }
  };

  const getDefaultEmailSubject = (project: EmailProject | null) => {
    let publishDateString = '';
    if (project && project.publishDate) {
      publishDateString = moment(project.publishDate).format('MMMM DD, YYYY');
    }

    const kind = project ? EmailProjectTemplateTypeDisplayName[project.kind] : '';

    return `${organization ? organization.name : ''} ${kind} ${publishDateString}`.trim();
  };

  const getDefaultPublishTime = () => {
    const storedTime = localStorage.getItem('sc-email-publish-time');
    if (storedTime) {
      return storedTime;
    }

    const systemSettingsTimeData = systemSettings.find((setting) => setting.type === SystemSettingType.PUBLISH_HOUR);

    return systemSettingsTimeData
      ? `${
          systemSettingsTimeData.value.length === 1 ? `0${systemSettingsTimeData.value}` : systemSettingsTimeData.value
        }:00`
      : '14:00';
  };

  const formik = useFormik({
    initialValues: {
      mailingListId: project?.mailingListId || '',
      publishTime: getDefaultPublishTime(),
      publishDate: project?.publishDate || moment().format('YYYY-MM-DD'),
      subject: project?.subject || getDefaultEmailSubject(project)
    },
    validationSchema: Yup.object().shape({
      mailingListId: Yup.string().required(),
      publishTime: Yup.string().required(),
      publishDate: Yup.string().required(),
      subject: Yup.string().required()
    }),
    validateOnMount: true,
    onSubmit
  });

  useEffect(() => {
    const getMailingLists = async () => {
      setInitialLoading(true);
      try {
        const mailingLists = await mailingListsOperations.getAllUserMailingLists();
        setMailingLists(mailingLists.data);
        setInitialLoading(false);
      } catch (e) {
        Toast.error(t('notifications.mailingLists.fetchingListsError'));
      }
    };
    getMailingLists();
  }, []);

  const sendNewsletterToTestMailingList = async (mailingListId: string, subject: string) => {
    if (mailingListId && project) {
      try {
        setSendingToTestingListInProgress(true);
        const testMailingList = await mailingListsOperations.getTestMailingList(mailingListId);
        if (testMailingList.activeMemberCount <= 0) {
          Toast.error(t('notifications.sendTestNewsletter.noMembers'));
          setSendingToTestingListInProgress(false);
        } else {
          await dispatch(emailProjectsOperations.sendToTestMailingList(project.id, testMailingList.id, subject));
          Toast.success(t('notifications.sendTestNewsletter.success'));
          setSendingToTestingListInProgress(false);
        }
      } catch (e) {
        setSendingToTestingListInProgress(false);
        Toast.error(t('notifications.sendTestNewsletter.error'));
      }
    }
  };

  const formFields = project ? (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Row>{t('windows.projectPublish.infoText')}</Row>
        <Row>
          <AdminTextInput t={t} section="projectPublish" name="subject" />
        </Row>
        <Row>
          {initialLoading && <Loader />}
          {!initialLoading && mailingLists.length > 0 && (
            <AdminSelectInput t={t} name="mailingListId" section="projectPublish">
              {mailingLists.map((mailingList) => (
                <MenuItem key={mailingList.id} value={mailingList.id}>
                  {mailingList.name} ({mailingList.activeMemberCount} {t('common.activeMembers')})
                </MenuItem>
              ))}
            </AdminSelectInput>
          )}
          {!initialLoading && mailingLists.length <= 0 && (
            <InfoBox type="warning">{t('windows.projectPublish.noMailingList')}</InfoBox>
          )}
        </Row>
        {!settings.displayPublicationDate && (
          <Row>
            {t('windows.projectPublish.infoText2')}{' '}
            <Strong>
              {moment(formik.values.publishDate).isValid() ? formatPublishDate(formik.values.publishDate) : '...'}
            </Strong>
          </Row>
        )}
        {settings.displayPublicationDate && (
          <Row style={{ width: '40%', marginRight: '2%', display: 'inline-block', marginTop: 0 }}>
            <AdminTextInput t={t} section="projectPublish" name="publishDate" type="date" />
          </Row>
        )}
        <Row style={settings.displayPublicationDate ? { width: '40%', display: 'inline-block', marginTop: 0 } : {}}>
          <AdminTextInput
            t={t}
            section="projectPublish"
            name="publishTime"
            type="time"
            inputProps={{
              step: 300
            }}
          />
        </Row>
        <Row>{t('windows.projectPublish.sendToTest')}</Row>
        <Row>
          <Button
            startIcon={<TelegramIcon />}
            color={'secondary'}
            disabled={!formik.values.mailingListId || sendingToTestingListInProgress || !formik.values.subject}
            onClick={() => sendNewsletterToTestMailingList(formik.values.mailingListId, formik.values.subject)}
            variant={'outlined'}
          >
            {sendingToTestingListInProgress
              ? t('windows.projectPublish.sendToTestMailingListButtonInProgress')
              : t('windows.projectPublish.sendToTestMailingListButton')}
          </Button>
        </Row>
      </form>
    </FormikProvider>
  ) : null;

  return {
    formik,
    sendNewsletterToTestMailingList,
    mailingLists,
    sendingToTestingListInProgress,
    initialLoading,
    formFields,
    getDefaultEmailSubject
  };
};
