import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';
import AdminProjectsPage from './AdminProjectsPage';

const AdminProjectsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMIN_PROJECTS,
    exact: true,
    name: 'adminProjects',
    public: false,
    show: true,
    component: AdminProjectsPage,
    scope: []
  }
];

export default AdminProjectsRoutes;
