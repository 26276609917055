import React, { FunctionComponent } from 'react';
import { Form, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import linksConstants from '../../../../config/app/linksConstants';
import { Link } from 'react-router-dom';
import { Collapse } from '@material-ui/core';
import { ResetPasswordInstructionsFormValues } from '../../../../store/Auth/types';
import InfoBox from '../../../Shared/InfoBox/InfoBox';
import translateNetworkError from '../../../../utils/translateNetworkError';
import { AuthActionButtons, AuthInput, AuthInputLabel, AuthMainButton, InputRow } from '../AuthStyledComponents';

type ForgotPasswordFormProps = {
  formikProps: FormikProps<ResetPasswordInstructionsFormValues>;
  error: NetworkError | null;
};

const ForgotPasswordForm: FunctionComponent<ForgotPasswordFormProps> = ({ formikProps, error }) => {
  const { t } = useTranslation();

  return (
    <Form>
      <InputRow>
        <AuthInput
          label={<AuthInputLabel>{t('pages.auth.forgotPassword.form.email')}</AuthInputLabel>}
          formikFieldProps={{ name: 'email' }}
          textFieldProps={{
            color: 'secondary',
            type: 'email',
            placeholder: 'your@email.com',
            fullWidth: true
          }}
        />
      </InputRow>
      <Collapse in={!!error}>
        <div style={{ maxWidth: '30rem', marginBottom: 24 }}>
          <InfoBox type={'error'} fullWidth>
            <div>
              {translateNetworkError(error, t)}
              {error?.message === 'unverified_account' && (
                <>
                  <br />
                  <Link to={linksConstants.AUTH.RESEND_VERIFICATION}>
                    {t('pages.auth.doYouNeedVerificationEmailAgain')}
                  </Link>
                </>
              )}
            </div>
          </InfoBox>
        </div>
      </Collapse>
      <AuthActionButtons>
        <AuthMainButton
          color={'secondary'}
          variant={'contained'}
          type={'submit'}
          disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
        >
          {t('pages.auth.forgotPassword.sendEmail')}
        </AuthMainButton>
      </AuthActionButtons>
    </Form>
  );
};

export default ForgotPasswordForm;
