import React from 'react';
import BorderLeftIcon from '@material-ui/icons/BorderLeft';
import BorderTopIcon from '@material-ui/icons/BorderTop';
import BorderRightIcon from '@material-ui/icons/BorderRight';
import BorderBottomIcon from '@material-ui/icons/BorderBottom';
import { Button } from '@material-ui/core';

type BorderProps = {
  selectedButtons: Record<string, boolean>;
  onClick: (button: string) => void;
};

const Border: React.FC<BorderProps> = ({ selectedButtons, onClick }) => {
  return (
    <div>
      <Button
        aria-label="left"
        color={selectedButtons['left'] ? 'primary' : 'default'}
        variant={selectedButtons['left'] ? 'contained' : 'text'}
        onClick={() => onClick('left')}
        size="small"
        style={{ minWidth: 'auto', marginRight: 5, padding: 4 }}
      >
        <BorderLeftIcon />
      </Button>
      <Button
        aria-label="top"
        color={selectedButtons['top'] ? 'primary' : 'default'}
        variant={selectedButtons['top'] ? 'contained' : 'text'}
        onClick={() => onClick('top')}
        size="small"
        style={{ minWidth: 'auto', marginRight: 5, padding: 4 }}
      >
        <BorderTopIcon />
      </Button>
      <Button
        aria-label="right"
        color={selectedButtons['right'] ? 'primary' : 'default'}
        variant={selectedButtons['right'] ? 'contained' : 'text'}
        onClick={() => onClick('right')}
        size="small"
        style={{ minWidth: 'auto', marginRight: 5, padding: 4 }}
      >
        <BorderRightIcon />
      </Button>
      <Button
        aria-label="bottom"
        color={selectedButtons['bottom'] ? 'primary' : 'default'}
        variant={selectedButtons['bottom'] ? 'contained' : 'text'}
        onClick={() => onClick('bottom')}
        size="small"
        style={{ minWidth: 'auto', marginRight: 5, padding: 4 }}
      >
        <BorderBottomIcon />
      </Button>
    </div>
  );
};

export default Border;
