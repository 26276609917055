import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatTableTimestampWithHours, useTypedSelector } from '../../../../../utils';
import DataTable from '../../../../Shared/DataTable/DataTable';
import { DoAppPromotion } from '../../../../../store/DoApp/Promotions/types';
import { TableSearchStoreModule } from '../../../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../../../store/Pagination/types';
import { usePaginationWatch } from '../../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../../Shared/DataTable/useTableSearchWatch';
import { doAppCreditTransactionsOperations } from '../../../../../store/DoApp/CreditTransactions';
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import BlockIcon from '@material-ui/icons/Block';
import ReplayIcon from '@material-ui/icons/Replay';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import { ColumnDefinition } from '../../../../Shared/DataTable/types';
import moment from 'moment';
import {
  DoAppCreditTransaction,
  DoAppCreditTransactionStatus
} from '../../../../../store/DoApp/CreditTransactions/types';
import DisputeCreditTransactionWindow from '../../Windows/DisputeCreditTransactionWindow';
import CancelDisputeCreditTransactionWindow from '../../Windows/CancelDisputeCreditTransactionWindow';

type CreditTransactionsTabProps = {
  onTransactionRejectionOrWithdrawal: () => void;
  initialStatusFilter?: DoAppCreditTransactionStatus;
};

const CreditTransactionsTab: React.FC<CreditTransactionsTabProps> = ({
  onTransactionRejectionOrWithdrawal,
  initialStatusFilter
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [creditTransactionStatusFilter, setCreditTransactionStatusFilter] = useState(
    initialStatusFilter || DoAppCreditTransactionStatus.PENDING
  );
  const [selectedCreditTransaction, setSelectedCreditTransaction] = useState<DoAppCreditTransaction | null>(null);

  const doAppCreditTransactions = useTypedSelector((state) => state.doAppMerchantCreditTransactions.data);
  const isFailed = useTypedSelector((state) => state.doAppMerchantCreditTransactions.index.isFailed);
  const isLoading = useTypedSelector((state) => state.doAppMerchantCreditTransactions.index.isLoading);
  const pagination = useTypedSelector((state) => state.doAppMerchantCreditTransactions.pagination);
  const tableSearch = useTypedSelector((state) => state.doAppMerchantCreditTransactions.tableSearch);

  const [
    disputeCreditTransactionWindowOpen,
    onDisputeCreditTransactionWindowOpen,
    onDisputeCreditTransactionWindowClose
  ] = useOpenHandler();
  const [
    cancelDisputeCreditTransactionWindowOpen,
    onCancelDisputeCreditTransactionWindowOpen,
    onCancelDisputeCreditTransactionWindowClose
  ] = useOpenHandler();

  usePaginationWatch(pagination, [
    doAppCreditTransactionsOperations.indexMerchantCreditTransactions.bind(null, creditTransactionStatusFilter)
  ]);
  useTableSearchWatch<DoAppPromotion>(tableSearch, [
    doAppCreditTransactionsOperations.indexMerchantCreditTransactions.bind(null, creditTransactionStatusFilter)
  ]);

  const handleCreditTransactionStatusFilterChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFilter = (event.target as HTMLInputElement).value as DoAppCreditTransactionStatus;
    setCreditTransactionStatusFilter(selectedFilter);
    await dispatch(doAppCreditTransactionsOperations.indexMerchantCreditTransactions(selectedFilter));
  };

  const getColumnDefinitions = (): ColumnDefinition<
    DoAppCreditTransaction,
    'actions' | 'reason' | 'rejectedAt' | 'acceptedAt' | 'autoAccept'
  >[] => {
    const columns: ColumnDefinition<
      DoAppCreditTransaction,
      'actions' | 'reason' | 'rejectedAt' | 'acceptedAt' | 'autoAccept'
    >[] = [
      {
        name: 'merchant',
        sortable: false,
        render: (row) => <>{row.merchant.name}</>
      },
      {
        name: 'subscriber',
        sortable: false,
        render: (row) => <>{row.subscriber.email}</>
      },
      {
        name: 'amount',
        sortable: false,
        render: (row) => <>{`$${(row.amount / 100).toFixed(2)}`}</>
      },
      {
        name: 'createdAt',
        sortable: false,
        render: (row) => <>{formatTableTimestampWithHours(row.createdAt)}</>
      }
    ];

    if (creditTransactionStatusFilter === DoAppCreditTransactionStatus.PENDING) {
      columns.push(
        {
          name: 'autoAccept',
          sortable: false,
          render: (row) => {
            const hoursUntilAutoAccept = 48 - moment().diff(moment(row.createdAt), 'hours');
            return (
              <>
                {hoursUntilAutoAccept} {t('pages.doApp.merchants.table.hoursRemaining')}
              </>
            );
          }
        },
        {
          name: 'actions',
          sortable: false,
          render: (row) => (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<BlockIcon />}
              style={{ color: '#de3e31', borderColor: '#de3e31' }}
              onClick={() => {
                setSelectedCreditTransaction(row);
                onDisputeCreditTransactionWindowOpen();
              }}
            >
              {t('pages.myOrganization.merchant.dispute')}
            </Button>
          )
        }
      );
    } else if (creditTransactionStatusFilter === DoAppCreditTransactionStatus.REJECTED) {
      columns.push(
        {
          name: 'reason',
          sortable: false,
          render: (row) => <>{row.rejection?.comment}</>
        },
        {
          name: 'rejectedAt',
          sortable: false,
          render: (row) => <>{row.rejection ? formatTableTimestampWithHours(row.rejection.createdAt) : ''}</>
        },
        {
          name: 'actions',
          sortable: false,
          render: (row) => (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<ReplayIcon />}
              onClick={() => {
                setSelectedCreditTransaction(row);
                onCancelDisputeCreditTransactionWindowOpen();
              }}
            >
              {t('pages.myOrganization.merchant.unDispute')}
            </Button>
          )
        }
      );
    } else if (creditTransactionStatusFilter === DoAppCreditTransactionStatus.ACCEPTED) {
      columns.push({
        name: 'acceptedAt',
        sortable: false,
        render: (row) => <>{row.acceptedAt ? formatTableTimestampWithHours(row.acceptedAt) : ''}</>
      });
    }

    return columns;
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <FormControl component="fieldset">
          <RadioGroup
            name="promotion-claims-status-radio-group"
            value={creditTransactionStatusFilter}
            onChange={handleCreditTransactionStatusFilterChange}
            row
          >
            <FormControlLabel
              value={DoAppCreditTransactionStatus.ACCEPTED}
              control={<Radio />}
              label={t('pages.myOrganization.merchant.creditTransactions.filter.accepted')}
            />
            <FormControlLabel
              value={DoAppCreditTransactionStatus.PENDING}
              control={<Radio />}
              label={t('pages.myOrganization.merchant.creditTransactions.filter.pending')}
            />
            <FormControlLabel
              value={DoAppCreditTransactionStatus.REJECTED}
              control={<Radio />}
              label={t('pages.myOrganization.merchant.creditTransactions.filter.rejected')}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <DataTable<DoAppCreditTransaction, 'actions' | 'reason' | 'rejectedAt' | 'acceptedAt' | 'autoAccept'>
        enableSearch={false}
        columnDefinitions={getColumnDefinitions()}
        tPath={'pages.doApp.merchants.table'}
        data={doAppCreditTransactions}
        tableSearchProps={{
          tableSearch,
          module: TableSearchStoreModule.DO_APP_MERCHANT_CREDIT_TRANSACTIONS
        }}
        paginationProps={{
          pagination,
          module: PaginationStoreModule.DO_APP_MERCHANT_CREDIT_TRANSACTIONS,
          noUrlUpdate: true
        }}
        isFailed={isFailed}
        isLoading={isLoading}
        disablePaper
      />

      {disputeCreditTransactionWindowOpen && selectedCreditTransaction && (
        <DisputeCreditTransactionWindow
          open={disputeCreditTransactionWindowOpen}
          creditTransaction={selectedCreditTransaction}
          onCloseClick={onDisputeCreditTransactionWindowClose}
          onSubmit={async () => {
            await dispatch(
              doAppCreditTransactionsOperations.indexMerchantCreditTransactions(creditTransactionStatusFilter)
            );
            onTransactionRejectionOrWithdrawal();
          }}
          fullScreenOnMobile
        />
      )}
      {cancelDisputeCreditTransactionWindowOpen && selectedCreditTransaction && (
        <CancelDisputeCreditTransactionWindow
          open={cancelDisputeCreditTransactionWindowOpen}
          creditTransaction={selectedCreditTransaction}
          onCloseClick={onCancelDisputeCreditTransactionWindowClose}
          onSubmit={async () => {
            await dispatch(
              doAppCreditTransactionsOperations.indexMerchantCreditTransactions(creditTransactionStatusFilter)
            );
            onTransactionRejectionOrWithdrawal();
          }}
          fullScreenOnMobile
        />
      )}
    </>
  );
};

export default CreditTransactionsTab;
