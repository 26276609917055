import React from 'react';
import { Chip } from '@material-ui/core';
import { AdminKeyword } from '../../../store/AdminKeywords/types';

type AdminKeywordChipProps = {
  keyword: AdminKeyword;
  onDelete: null | ((tag: AdminKeyword) => void);
};

const AdminKeywordChip: React.FC<AdminKeywordChipProps> = ({ keyword, onDelete }) => {
  return (
    <div>
      <Chip
        size="small"
        onDelete={onDelete ? () => onDelete(keyword) : undefined}
        label={keyword.name}
        style={{ margin: '0 2px 2px 0' }}
      />
    </div>
  );
};

export default AdminKeywordChip;
