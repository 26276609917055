import React, { FunctionComponent } from 'react';
import { ColorSwatchType, MyOrganizationSwatchColor } from '../../../../../../store/MyOrganization/types';
import { CtaButton } from '../../types';
import {
  extractColorsFromSwatches,
  getDefaultButtonBackgroundColor,
  getDefaultButtonBorderColor,
  getDefaultButtonBorderRadius,
  getDefaultButtonBorderWidth,
  isHTML
} from '../../Utils';
import WysiwygEditor from './Utils/WysiwygEditor';
import CtaButtonWindow from '../Windows/CtaButtonWindow';
import useOpenHandler from '../../../../../../hooks/useOpenHandler';
import { ComponentActionIcons, StyledEditIcon } from '../../../../../Shared/StyledComponents';
import { ReactComponent as SettingsIcon } from '../../../../../../assets/icons/settings.svg';

type CtaButtonProps = {
  data: CtaButton;
  onChange: (data: CtaButton) => void;
  selectedColorSwatches: { [key: string]: MyOrganizationSwatchColor };
  inProjectEditor: boolean;
};

const CtaButtonComponent: FunctionComponent<CtaButtonProps> = ({
  data,
  onChange,
  selectedColorSwatches,
  inProjectEditor
}) => {
  const [ctaButtonWindowOpen, onCtaButtonWindowOpen, onCtaButtonWindowClose] = useOpenHandler();

  return (
    <div>
      <p
        style={{
          ...data.wrapperStyle,
          ...data.style,
          ...extractColorsFromSwatches(
            selectedColorSwatches,
            [ColorSwatchType.button, ColorSwatchType.link],
            data.colorSwatchIgnore,
            '',
            data.params.style
          )
        }}
        className="cta-button"
      >
        <WysiwygEditor
          inline
          content={
            isHTML(data.params.text)
              ? data.params.text
              : `<p><a href="${data.params.link}" target="_blank" rel="noopener noreferrer">${data.params.text}</a></p>`
          }
          onBlur={(newContent) =>
            onChange({
              ...data,
              params: {
                ...data.params,
                text: newContent
              }
            })
          }
        />
      </p>
      <ComponentActionIcons style={{ left: 'calc(100% - 45px)' }}>
        <StyledEditIcon onClick={() => onCtaButtonWindowOpen()}>
          <SettingsIcon style={{ width: 22, fill: '#fff' }} />
        </StyledEditIcon>
      </ComponentActionIcons>

      {ctaButtonWindowOpen && (
        <CtaButtonWindow
          open={ctaButtonWindowOpen}
          onCloseClick={onCtaButtonWindowClose}
          onFormSubmit={(values) => {
            onChange({
              ...data,
              params: {
                ...data.params,
                style: {
                  backgroundColor: values.backgroundColor,
                  borderRadius: values.borderRadius,
                  borderStyle: values.borderStyle,
                  borderWidth: values.borderWidth,
                  borderColor: values.borderColor
                }
              }
            });
            onCtaButtonWindowClose();
          }}
          backgroundColor={getDefaultButtonBackgroundColor(data)}
          borderRadius={getDefaultButtonBorderRadius(data)}
          borderStyle={(data.params.style && data.params.style.borderStyle) || 'inherit'}
          borderWidth={getDefaultButtonBorderWidth(data)}
          borderColor={getDefaultButtonBorderColor(data)}
        />
      )}
    </div>
  );
};

export default CtaButtonComponent;
