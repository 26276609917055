import { StateWithTableSearch } from './types';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export function useTableSearchWatch<E>(
  tableSearch: StateWithTableSearch<E>['tableSearch'],
  cbs: Function[],
  deps?: any[]
) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (tableSearch.order && Object.keys(tableSearch.order).length) {
      (async () => {
        for (const cb of cbs) {
          await dispatch(cb());
        }
      })();
    }
  }, [tableSearch.order, ...(deps || [])]);
}
