import { Grid, Typography, MenuItem, FormLabel, TextField, Box, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import {
  DamSystemName,
  automatedEmailDateSchedule,
  AUTOMATED_EMAIL_DATE_SCHEDULE,
  DamSystem,
  PrintCenter
} from '../../../../../store/SystemSettings/types';
import { isSystemOwner } from '../../../../../utils/permissions';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';
import Loader from '../../../../Shared/Loading/Loader';
import { Row } from '../../../../Shared/StyledComponents';
import { PageContentPaper } from '../../../PageStyledComponents';
import { AdminTextInput, AdminCheckboxInput, AdminSelectInput } from '../../Shared/AdminFormInputs';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import Toast from '../../../../Shared/Toast/Toast';
import { adminEmailTemplatesOperations } from '../../../../../store/AdminEmailTemplates';
import { MailingList } from '../../../../../store/MailingLists/types';
import { AdminOrganization } from '../../../../../store/AdminOrganizations/types';
import { AdminEmailTemplate } from '../../../../../store/AdminEmailTemplates/types';
import { AdminPasswordPolicy } from '../../../../../store/AdminPasswordPolicies/types';
import { SenderData } from '../../../../../store/EmailProjects/types';
import { adminOrganizationsOperations } from '../../../../../store/AdminOrganizations';
import { emailProjectsOperations } from '../../../../../store/EmailProjects';
import { systemSettingsOperations } from '../../../../../store/SystemSettings';
import { useTypedSelector } from '../../../../../utils';

type AdminOrganizationProps = {
  props: FormikProps<any>;
  passwordPolicies: AdminPasswordPolicy[];
  mailingLists?: MailingList[];
};

const AdminOrganizationForm: React.FC<AdminOrganizationProps> = ({ props, passwordPolicies, mailingLists }) => {
  const { t } = useTranslation();
  const authState = useTypedSelector((state) => state.auth);
  const [damSystemsLoading, setDamSystemsLoading] = useState<boolean>(true);
  const [damSystems, setDamSystems] = useState<DamSystem[]>([]);
  const [printCentersLoading, setPrintCentersLoading] = useState<boolean>(true);
  const [printCenters, setPrintCenters] = useState<PrintCenter[]>([]);
  const [sendersLoading, setSendersLoading] = useState<boolean>(true);
  const [senders, setSenders] = useState<SenderData[]>([]);
  const [organizationsLoading, setOrganizationsLoading] = useState<boolean>(true);
  const [organizations, setOrganizations] = useState<AdminOrganization[]>([]);
  const [emailTemplates, setEmailTemplates] = useState<AdminEmailTemplate[]>([]);

  useEffect(() => {
    const getSenders = async () => {
      setSendersLoading(true);
      try {
        const sendersResponse = await emailProjectsOperations.getSenders();
        setSenders(sendersResponse.data);
      } catch (e) {
        Toast.error(t('notifications.getSenders.error'));
      } finally {
        setSendersLoading(false);
      }
    };
    const getDamSystems = async () => {
      setDamSystemsLoading(true);
      try {
        const dams = await systemSettingsOperations.getDamSystems();
        setDamSystems(dams.data);
      } catch (e) {
        Toast.error(t('notifications.dams.getDamsError'));
      } finally {
        setDamSystemsLoading(false);
      }
    };
    const getPrintCenters = async () => {
      setPrintCentersLoading(true);
      try {
        const response = await systemSettingsOperations.getPrintCenters();
        setPrintCenters(response.data);
      } catch (e) {
        Toast.error(t('notifications.printCenters.getPrintCentersError'));
      } finally {
        setPrintCentersLoading(false);
      }
    };
    const getOrganizations = async () => {
      setOrganizationsLoading(true);
      try {
        const organizationsResponse = await adminOrganizationsOperations.getAll();
        setOrganizations(organizationsResponse.data);
      } catch (e) {
        Toast.error(t('notifications.getBusinessUserData.error'));
      } finally {
        setOrganizationsLoading(false);
      }
    };
    const fetchEmailTemplates = async () => {
      const emailTemplates = await adminEmailTemplatesOperations.getAll();
      setEmailTemplates(emailTemplates);
    };

    fetchEmailTemplates();

    if (isSystemOwner(authState)) {
      getSenders();
      getDamSystems();
      getOrganizations();
      getPrintCenters();
    }
  }, []);

  const getChosenDamSystem = (props: FormikProps<any>): DamSystemName | null => {
    if (props.values.dam && props.values.dam.id) {
      const dam = damSystems.find((d) => d.id === props.values.dam.id);
      if (dam) {
        return dam.name;
      }
    }

    return null;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <PageContentPaper>
          <Typography variant="h6" gutterBottom>
            {t('pages.adminOrganizations.organizationInformation')}
          </Typography>
          <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
          <AdminTextInput t={t} name="name" section="adminOrganizations" />
          <AdminTextInput t={t} name="accountNumber" section="adminOrganizations" />
          {isSystemOwner(authState) && (
            <>
              <AdminCheckboxInput t={t} name="injectLogoToPrintProjects" section="adminOrganizations" />
              <br />
              <AdminCheckboxInput t={t} name="stripProtocolFromPrintInjectedUrls" section="adminOrganizations" />
              <br />
              <AdminCheckboxInput t={t} name="enableCampaigns" section="adminOrganizations" />
              <br />
              <AdminCheckboxInput t={t} name="socialPublishing" section="adminOrganizations" />
              <AdminTextInput t={t} type="number" name="colorSwatchesLimit" section="adminOrganizations" />
              <AdminTextInput t={t} name="readMoreLink" section="adminOrganizations" />
              {damSystemsLoading && <Loader />}
              {!damSystemsLoading && damSystems.length > 0 && (
                <AdminSelectInput t={t} name="dam.id" section="adminOrganizations">
                  {[null, ...damSystems].map((dam) => {
                    if (dam === null) {
                      return (
                        <MenuItem key="dam-not-set" value="-1">
                          {t(`common.notSet`)}
                        </MenuItem>
                      );
                    }
                    return (
                      <MenuItem key={dam.id} value={dam.id}>
                        <div>
                          <p>{t(`common.damSystem.${dam.name}`)}</p>
                          <p style={{ fontSize: '0.8rem', fontStyle: 'italic' }}>{dam.url}</p>
                        </div>
                      </MenuItem>
                    );
                  })}
                </AdminSelectInput>
              )}
              {!damSystemsLoading && getChosenDamSystem(props) === DamSystemName.ARK && (
                <AdminTextInput t={t} name="dam.apiKey" section="adminOrganizations" />
              )}
            </>
          )}
        </PageContentPaper>
        {isSystemOwner(authState) && (
          <PageContentPaper>
            <Typography variant="h6" gutterBottom>
              {t('pages.adminOrganizations.organizationStructure')}
            </Typography>
            <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
            <AdminTextInput t={t} name="region" section="adminOrganizations" />
            <AdminTextInput t={t} name="district" section="adminOrganizations" />
            <FormLabel style={{ marginTop: '1.5rem' }} component="legend">
              {t('pages.adminOrganizations.parentOrg')}
            </FormLabel>
            {organizationsLoading && <Loader />}
            {!organizationsLoading && (
              <Autocomplete
                onChange={(event, value) => {
                  if (value?.id) {
                    props.setFieldValue('parentId', value?.id);
                  }
                }}
                value={{
                  id: props.values.parentId,
                  label: organizations.find((org) => org.id === props.values.parentId)?.name || ''
                }}
                size="small"
                getOptionSelected={(option, value) => {
                  return option.id === value.id;
                }}
                renderInput={(params) => <TextField {...params} autoComplete="off" variant="outlined" />}
                options={[
                  { id: '-1', label: 'Unset' },
                  ...organizations.map((org) => ({ label: org.name, id: org.id }))
                ]}
                getOptionLabel={(option) => option.label}
              />
            )}
          </PageContentPaper>
        )}
        <PageContentPaper>
          <Typography variant="h6" gutterBottom>
            {t('pages.adminOrganizations.securitySettings')}
          </Typography>
          <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
          <AdminSelectInput t={t} name="passwordPolicyId" section="adminOrganizations">
            {passwordPolicies.map((passwordPolicy) => (
              <MenuItem key={passwordPolicy.id} value={passwordPolicy.id}>
                {passwordPolicy.isGlobal ? t('pages.systemSettings.passwordPolicyTitle') : passwordPolicy.name}
              </MenuItem>
            ))}
          </AdminSelectInput>
        </PageContentPaper>
      </Grid>
      <Grid item xs={12} md={6}>
        {isSystemOwner(authState) && (
          <PageContentPaper>
            <Typography variant="h6" gutterBottom>
              {t('pages.adminOrganizations.availableProducts')}
            </Typography>
            <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <AdminCheckboxInput t={t} name="bulletin" section="adminOrganizations" />
              <AdminCheckboxInput t={t} name="email" section="adminOrganizations" />
              <AdminCheckboxInput t={t} name="flyer" section="adminOrganizations" />
              <AdminCheckboxInput t={t} name="newsletter" section="adminOrganizations" />
              <AdminCheckboxInput t={t} name="directory" section="adminOrganizations" />
            </div>
          </PageContentPaper>
        )}
        {isSystemOwner(authState) && (
          <PageContentPaper>
            <Typography variant="h6" gutterBottom>
              {t('pages.adminOrganizations.emailSettings')}
            </Typography>
            <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
            <AdminCheckboxInput t={t} name="emailWizardEnabled" section="adminOrganizations" />
            <AdminSelectInput t={t} name="defaultAutomatedEmailTemplateId" section="adminOrganizations">
              {[null, ...emailTemplates].map((template) => {
                if (template === null) {
                  return (
                    <MenuItem key="template-not-set" value={undefined}>
                      {t(`common.notSet`)}
                    </MenuItem>
                  );
                }
                return (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                );
              })}
            </AdminSelectInput>
            <AdminCheckboxInput t={t} name="automatedEmailEnabled" section="adminOrganizations" />
            {props.values.automatedEmailEnabled && (
              <>
                <Row>
                  {mailingLists && mailingLists.length > 0 && (
                    <AdminSelectInput t={t} name="defaultAutomatedEmailMailingListId" section="adminOrganizations">
                      {mailingLists.map((mailingList) => (
                        <MenuItem key={mailingList.id} value={mailingList.id}>
                          {mailingList.name} ({mailingList.activeMemberCount} {t('common.activeMembers')})
                        </MenuItem>
                      ))}
                    </AdminSelectInput>
                  )}
                  {mailingLists && mailingLists.length <= 0 && (
                    <InfoBox type="warning">{t('pages.adminOrganizations.noMailingList')}</InfoBox>
                  )}
                </Row>

                <Grid container spacing={2}>
                  <Grid xs={6} item>
                    <AdminSelectInput t={t} name="automatedEmailDateSchedule" section="adminOrganizations">
                      {[null, ...automatedEmailDateSchedule].map((name) => {
                        if (name === null) {
                          return (
                            <MenuItem key="schedule-not-set" value={undefined}>
                              {t(`common.notSet`)}
                            </MenuItem>
                          );
                        }
                        return (
                          <MenuItem key={name} value={name}>
                            {t(`common.automatedEmailDateSchedule.${name}`)}
                          </MenuItem>
                        );
                      })}
                    </AdminSelectInput>
                  </Grid>
                  <Grid xs={6} item>
                    {props.values.automatedEmailDateSchedule !== AUTOMATED_EMAIL_DATE_SCHEDULE.IMMEDIATELY && (
                      <AdminTextInput t={t} name="automatedEmailTime" section="adminOrganizations" type="time" />
                    )}
                  </Grid>
                  <em style={{ textAlign: 'center', fontStyle: 'italic', width: '100%' }}>
                    {t('pages.adminOrganizations.automatedEmailSettingsHint')}
                  </em>
                </Grid>

                <Row>
                  <AdminTextInput t={t} name="automatedEmailSubject" section="adminOrganizations" />
                  <em style={{ textAlign: 'center', fontStyle: 'italic', width: '100%' }}>
                    {t('pages.adminOrganizations.automatedEmailSubjectHint')}
                  </em>
                </Row>
              </>
            )}
            {sendersLoading && <Loader />}
            {!sendersLoading && senders.length > 0 && (
              <AdminSelectInput t={t} name="senderEmail" section="adminOrganizations">
                {[null, ...senders].map((sender) => {
                  if (sender === null) {
                    return (
                      <MenuItem key="sender-not-set" value="-1">
                        {t(`pages.adminOrganizations.senderEmailDefault`)}
                      </MenuItem>
                    );
                  }
                  return (
                    <MenuItem key={sender.ID} value={sender.Email}>
                      {sender.Name} ({sender.Email})
                    </MenuItem>
                  );
                })}
              </AdminSelectInput>
            )}
          </PageContentPaper>
        )}
        {isSystemOwner(authState) && (
          <PageContentPaper>
            <Typography variant="h6" gutterBottom>
              {t('pages.adminOrganizations.printCenters')}
            </Typography>
            <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
            {printCentersLoading && <Loader />}
            {!printCentersLoading && printCenters.length && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {printCenters.map((printCenter) => (
                  <AdminCheckboxInput
                    key={printCenter.id}
                    t={t}
                    name={`printCenters.${printCenter.id}`}
                    label={printCenter.name}
                    section="adminOrganizations"
                  />
                ))}
              </div>
            )}
          </PageContentPaper>
        )}
        <Box display="flex" justifyContent="flex-end">
          <Button
            style={{ marginTop: '1rem' }}
            color="secondary"
            variant="contained"
            onClick={props.submitForm}
            size="medium"
            type="submit"
            disabled={props.isSubmitting || !props.isValid || !props.dirty}
          >
            {t('common.save')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AdminOrganizationForm;
