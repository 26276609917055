import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailProject } from '../../../../store/EmailProjects/types';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { useEmailPublish } from './EmailPublishPartials/useEmailPublish';

type ProjectPublishNewsletterWindowProps = {
  open: boolean;
  project: EmailProject;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  onSuccessfulSubmit?: () => void;
};

const ProjectPublishNewsletterWindow: FunctionComponent<ProjectPublishNewsletterWindowProps> = ({
  project,
  onCloseClick,
  open,
  onSuccessfulSubmit
}) => {
  const { t } = useTranslation();

  const { formik, formFields, mailingLists } = useEmailPublish(project, {
    displayPublicationDate: false
  });

  const hasMailingRecipients = (mailingListId: string) => {
    const foundMailingList = mailingLists.find((mailingList) => mailingList.id === mailingListId);
    return foundMailingList && foundMailingList.activeMemberCount > 0;
  };

  if (!project || !open) return null;

  return (
    <ModalConfirmationWindow
      open={open}
      okButtonText={t('common.publish')}
      header={t('windows.projectPublish.header')}
      onCloseClick={onCloseClick}
      disableSubmitButton={
        !formik.isValid ||
        !formik.values.mailingListId ||
        formik.isSubmitting ||
        !hasMailingRecipients(formik.values.mailingListId)
      }
      onSubmit={async () => {
        try {
          await formik.submitForm();
          onCloseClick();
          if (onSuccessfulSubmit) {
            onSuccessfulSubmit();
          }
        } catch (e) {
          // already handled in onSubmit method
        }
      }}
    >
      {formFields}
    </ModalConfirmationWindow>
  );
};

export default ProjectPublishNewsletterWindow;
