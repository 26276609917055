import {
  AdminPasswordPoliciesActionsConstants,
  AdminPasswordPoliciesIndexActionBEGIN,
  AdminPasswordPoliciesIndexActionFAILED,
  AdminPasswordPoliciesIndexActionSUCCESS,
  AdminPasswordPoliciesIndexResponse
} from './types';

export const passwordPoliciesIndexBegin = (): AdminPasswordPoliciesIndexActionBEGIN => ({
  type: AdminPasswordPoliciesActionsConstants.INDEX_BEGIN
});

export const passwordPoliciesIndexSuccess = (
  payload: AdminPasswordPoliciesIndexResponse
): AdminPasswordPoliciesIndexActionSUCCESS => ({
  type: AdminPasswordPoliciesActionsConstants.INDEX_SUCCESS,
  payload
});

export const passwordPoliciesIndexFailed = (error: any): AdminPasswordPoliciesIndexActionFAILED => ({
  type: AdminPasswordPoliciesActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});
