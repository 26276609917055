import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminOrganization } from '../../../../../store/AdminOrganizations/types';
import { adminOrganizationsOperations } from '../../../../../store/AdminOrganizations';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';
import Toast from '../../../../Shared/Toast/Toast';

type AdminDeleteOrganizationWindowProps = {
  open: boolean;
  organization: AdminOrganization | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminDeleteOrganizationWindow: FunctionComponent<AdminDeleteOrganizationWindowProps> = ({
  organization,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!organization) return null;

  const onSubmit = async () => {
    try {
      await dispatch(adminOrganizationsOperations.deleteOrganization(organization.id));
      await dispatch(adminOrganizationsOperations.index());
      onCloseClick();
      Toast.success(t('notifications.adminOrganizations.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminOrganizations.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminOrganizations.windows.deleteOrganization')}
      okButtonText={t('pages.adminOrganizations.windows.completelyDelete')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.adminOrganizations.windows.deleteOrganizationContent')}</Row>
      <Row>
        <Strong>
          {organization.name} {organization.accountNumber ? `(${organization.accountNumber})` : ''}
        </Strong>
      </Row>
      <Row>
        <InfoBox type="warning">{t('pages.adminOrganizations.windows.cannotBeUndone')}</InfoBox>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default AdminDeleteOrganizationWindow;
