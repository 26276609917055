import { combineReducers } from 'redux';
import browserHistory from './browserHistory';
import { connectRouter } from 'connected-react-router';
import appReducer from './App';
import authReducer from './Auth';
import adminUsersReducer from './AdminUsers';
import dashboardReducer from './Dashboard/reducers';
import adminPrintTemplatesReducer from './AdminPrintTemplates/reducers';
import adminEmailTemplatesReducer from './AdminEmailTemplates/reducers';
import subscribersReducer from './Subscribers/reducers';
import { adminKeywordGroupsReducer, adminKeywordsReducer } from './AdminKeywords/reducers';
import adminPasswordPoliciesReducer from './AdminPasswordPolicies/reducers';
import adminRolesReducer from './AdminRoles/reducers';
import mailingListsReducer from './MailingLists/reducers';
import adminMailingListsReducer from './AdminMailingLists/reducers';
import adminOrganizationsReducer from './AdminOrganizations/reducers';
import adminOrganizationUsersReducer from './AdminOrganizationUsers/reducers';
import myOrganizationReducer from './MyOrganization/reducers';
import emailProjectsReducer from './EmailProjects/reducers';
import printProjectsReducer from './PrintProjects/reducers';
import adminAdsReducer from './AdminAds/reducers';
import adminProjectsReducer from './AdminProjects/reducers';
import adminOnlineUsersReducer from './AdminOnlineUsers/reducers';
import { storiesReducer, storyContentContributorsReducer } from './Stories/reducers';
import sharingCenterReducer from './SharingCenter/reducers';
import designProjectsReducer from './DesignProjects/reducers';
import campaignsReducer from './Campaigns/reducers';
import doAppMerchantsReducer from './DoApp/Merchants/reducers';
import doAppMerchantPromotionsReducer from './DoApp/Promotions/reducers';
import doAppMerchantPromotionClaimsReducer from './DoApp/PromotionClaims/reducers';
import doAppMerchantCreditTransactionsReducer from './DoApp/CreditTransactions/reducers';
import doAppMerchantSubscribersReducer from './DoApp/Subscribers/reducers';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  router: connectRouter(browserHistory),
  adminUsers: adminUsersReducer,
  adminAds: adminAdsReducer,
  adminPrintTemplates: adminPrintTemplatesReducer,
  adminEmailTemplates: adminEmailTemplatesReducer,
  subscribers: subscribersReducer,
  dashboard: dashboardReducer,
  adminKeywords: adminKeywordsReducer,
  adminKeywordGroups: adminKeywordGroupsReducer,
  adminPasswordPolicies: adminPasswordPoliciesReducer,
  adminRoles: adminRolesReducer,
  mailingLists: mailingListsReducer,
  adminMailingLists: adminMailingListsReducer,
  adminOrganizations: adminOrganizationsReducer,
  adminOrganizationUsers: adminOrganizationUsersReducer,
  myOrganization: myOrganizationReducer,
  adminProjects: adminProjectsReducer,
  adminOnlineUsers: adminOnlineUsersReducer,

  emailProjects: emailProjectsReducer,
  printProjects: printProjectsReducer,
  stories: storiesReducer,
  storyContributors: storyContentContributorsReducer,

  designProjects: designProjectsReducer,

  sharingCenter: sharingCenterReducer,
  campaigns: campaignsReducer,

  doAppMerchants: doAppMerchantsReducer,
  doAppMerchantPromotions: doAppMerchantPromotionsReducer,
  doAppMerchantPromotionClaims: doAppMerchantPromotionClaimsReducer,
  doAppMerchantCreditTransactions: doAppMerchantCreditTransactionsReducer,
  doAppMerchantSubscribers: doAppMerchantSubscribersReducer
});

export type ApplicationState = ReturnType<typeof rootReducer>;
export default rootReducer;
