import { BaseSettings, RequestSettings } from '../../../utils/httpRequest';
import { IndexDoAppPromotionsMineRequestParams, IndexDoAppPromotionsRequestParams } from './types';

export const index = (settings: RequestSettings<IndexDoAppPromotionsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/promotions?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const indexMine = (settings: RequestSettings<IndexDoAppPromotionsMineRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/promotions/mine?per=:per&page=:page&range=:range`
  },
  params: settings.requestParams
});

export const getAllMinePromotions = (): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/promotions/mine?per=1000`
  }
});
