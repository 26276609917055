import React, { FunctionComponent } from 'react';
import { AnyProject } from '../../../../store/Dashboard/types';
import { formatPublishDate, isEmailProject } from '../../../../utils';
import moment, { Moment } from 'moment';
import styled from 'styled-components';

type PublicationDateCellProps = {
  project: AnyProject;
};

const PublicationDateCell: FunctionComponent<PublicationDateCellProps> = ({ project }) => {
  let exactPublishTimeElement: JSX.Element | null = null;
  let subjectString: JSX.Element | null = null;

  if (isEmailProject(project) && project.exactTime) {
    const time = moment(project.exactTime).format('h:mm a');

    exactPublishTimeElement = <PublicationTime>{time}</PublicationTime>;
  }
  if (isEmailProject(project) && project.subject) {
    subjectString = <SubjectString title={project.subject}>{project.subject}</SubjectString>;
  }

  return (
    <>
      {formatPublishDate(project.publishDate)} {exactPublishTimeElement}
      {subjectString}
    </>
  );
};

const PublicationTime = styled.span`
  font-size: 0.7rem;
  font-style: italic;
`;

const SubjectString = styled.div`
  font-size: 0.7rem;
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default PublicationDateCell;
