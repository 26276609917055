import React, { FunctionComponent } from 'react';
import { Strong } from '../../../Shared/StyledComponents';
import { AnyProject } from '../../../../store/Dashboard/types';
import moment from 'moment';
import { SubTitle } from './StyledProjectRowComponents';
import { User } from '../../../../store/Auth/types';
import { useTranslation } from 'react-i18next';

type LatestActivityCellProps = {
  project: AnyProject;
  user: User;
};

const LatestActivityCell: FunctionComponent<LatestActivityCellProps> = ({ project, user }) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        {!project.updatedBy && <>N/A</>}
        {project.updatedBy && (
          <>
            <Strong>{project.updatedBy.id === user.id ? t('common.you') : project.updatedBy.fullName}</Strong>{' '}
            {t('pages.dashboard.editedTheProject')}
          </>
        )}
      </div>
      {project.updatedBy && <SubTitle>{moment(project.updatedByAt).fromNow()}</SubTitle>}
    </>
  );
};

export default LatestActivityCell;
