import React from 'react';
import { Field, FieldProps } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormAutocompleteInputProps } from './types';
import getFormInputError from './getFormInputError';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

const FormAutocompleteInput: React.FC<FormAutocompleteInputProps> = (props) => {
  const { t } = useTranslation();
  const {
    formikFieldProps,
    autocompleteProps,
    customErrorMessagePath,
    className,
    label,
    required,
    onSearchTextChange
  } = props;

  const getPlaceholder = () => {
    return required && autocompleteProps.value ? `${autocompleteProps.placeholder} *` : autocompleteProps.placeholder;
  };

  return (
    <>
      {label}
      <Field name={formikFieldProps.name}>
        {(fieldProps: FieldProps) => {
          const { field } = fieldProps;
          const errorMessage = getFormInputError(fieldProps, t, customErrorMessagePath);
          return (
            <Autocomplete
              {...field}
              {...autocompleteProps}
              onChange={(event, value, reason, details) => {
                fieldProps.form.setFieldValue(field.name, value);
                autocompleteProps.onChange && autocompleteProps.onChange(event, value, reason, details);
              }}
              classes={{
                root: className
              }}
              placeholder={getPlaceholder()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoComplete="off"
                  variant="outlined"
                  error={errorMessage[0]}
                  helperText={errorMessage[1] || ''}
                  onChange={(event) => {
                    onSearchTextChange && onSearchTextChange(event);
                  }}
                />
              )}
              options={autocompleteProps.options}
              getOptionLabel={autocompleteProps.getOptionLabel}
            />
          );
        }}
      </Field>
    </>
  );
};

export default FormAutocompleteInput;
