import React, { FunctionComponent, useEffect, useState } from 'react';
import { FullSizePageContainer } from '../../PageStyledComponents';
import { RouteComponentProps } from 'react-router';
import Toast from '../../../Shared/Toast/Toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Loader from '../../../Shared/Loading/Loader';
import { createNetworkErrorObject } from '../../../../utils';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import DesignerEditor from '../Common/DesignerEditor';
import { DesignProject } from '../../../../store/DesignProjects/types';
import { designProjectsOperations } from '../../../../store/DesignProjects';
import { DamSystemName } from '../../../../store/SystemSettings/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import DesignEditWarningWindow from '../Windows/DesignEditWarningWindow';
import { Story } from '../../../../store/Stories/types';

type DesignProjectEditorPageProps = RouteComponentProps<{ projectId: string }> & {
  location: { state: { storyId?: string } };
};

const DesignProjectEditorPage: FunctionComponent<DesignProjectEditorPageProps> = ({ match, location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [projectData, setProjectData] = useState<DesignProject | null>(null);
  const [damStatus, setDamStatus] = useState<{ damActive: boolean; system: DamSystemName | null }>({
    damActive: false,
    system: null
  });
  const [designEditWarningWindowOpen, onDesignEditWarningWindowOpen, onDesignEditWarningWindowClose] = useOpenHandler();
  const [stories, setStories] = useState<Story[]>([]);

  const projectId = match.params.projectId;
  const storyId = location.state && location.state.storyId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await designProjectsOperations.show(projectId);
        setProjectData(res);

        if (res.stories.length > 1 || (res.stories.length === 1 && res.stories[0].channels.length > 1)) {
          setStories(res.stories);
          onDesignEditWarningWindowOpen();
        }

        try {
          setDamStatus(await myOrganizationOperations.getDamStatus());
        } catch (e) {
          console.warn('Error occurred when trying to fetch DAM status', e);
        }

        setLoading(false);
      } catch (e) {
        Toast.error(
          t('notifications.newsletterProjectFetch.error', { rawError: `(${createNetworkErrorObject(e).message})` })
        );
      }
    };
    fetchData();
  }, []);

  const saveProjectName = async (name: string) => {
    if (projectData) {
      try {
        await designProjectsOperations.saveName(projectData, name);
        Toast.success(t('notifications.projectNameChange.success'));
      } catch (e) {
        Toast.error(t('notifications.projectNameChange.error'));
      }
    }
  };

  if (!projectData) return null;

  return (
    <FullSizePageContainer>
      {loading && <Loader />}
      {!loading && projectData && (
        <DesignerEditor
          damStatus={damStatus}
          publishDate={null}
          enableNotes={false}
          externalId={projectData.externalId}
          projectId={projectData.id}
          projectHash={projectData.hash}
          projectName={projectData.name}
          onProjectNameChange={(name) => saveProjectName(name)}
          onSave={async () => {
            await designProjectsOperations.saveContent(projectData.id);
          }}
          onBackButtonClick={() => {
            if (storyId) {
              dispatch(push(linksConstants.STORY.EDIT(storyId)));
            } else {
              dispatch(push(linksConstants.DASHBOARD.DESIGN_PROJECTS));
            }
          }}
        />
      )}
      <DesignEditWarningWindow
        open={designEditWarningWindowOpen}
        onCloseClick={onDesignEditWarningWindowClose}
        fullScreenOnMobile
        stories={stories}
      />
    </FullSizePageContainer>
  );
};

export default DesignProjectEditorPage;
