import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Step, StepLabel, Stepper, withTheme } from '@material-ui/core';
import styled from 'styled-components';

type StepperProps = {
  steps: string[];
  currentState: string;
};

const CreateProjectStepper: FunctionComponent<StepperProps> = ({ steps, currentState }) => {
  const { t } = useTranslation();

  return (
    <Container activeStep={steps.indexOf(currentState)}>
      {steps.map((label, i) => {
        const isOnLastStep = i === steps.length - 1 && currentState === 'finish';
        const isCompleted = i < steps.indexOf(currentState) || isOnLastStep;
        return (
          <Step key={label} completed={isCompleted}>
            <StepLabel>{t(`pages.dashboard.createWizard.steps.${label}`)}</StepLabel>
          </Step>
        );
      })}
    </Container>
  );
};

const Container = withTheme(styled(Stepper)`
  &.MuiStepper-root {
    padding: 1rem 0;
    background: transparent;
  }

  .MuiStepConnector-root {
    max-width: 1rem;
  }

  .MuiStepIcon-completed {
    color: ${({ theme }) => theme.palette.success.main};
  }
`);

export default CreateProjectStepper;
