import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AdsStatisticsTitle } from '../AdsStatisticsPage';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import MouseIcon from '@material-ui/icons/Mouse';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Paper } from '@material-ui/core';

type AdsStatisticsSummaryProps = {
  impressions: number;
  clicks: number;
  ctr: number;
};

const AdsStatisticsSummary: FunctionComponent<AdsStatisticsSummaryProps> = ({ impressions, clicks, ctr }) => {
  const { t } = useTranslation();

  return (
    <>
      <Paper style={{ padding: '15px', marginBottom: '16px' }}>
        <AdsStatisticsItemContainer>
          <AdsStatisticsIconContainer style={{ backgroundColor: '#FF7F0E' }}>
            <VisibilityIcon style={{ color: '#FFFFFF' }} />
          </AdsStatisticsIconContainer>
          <div>
            <AdsStatisticsTitle>{t('pages.adsStatistics.impressions')}</AdsStatisticsTitle>
            <AdsStatisticsTextBig>{impressions}</AdsStatisticsTextBig>
          </div>
        </AdsStatisticsItemContainer>
      </Paper>
      <Paper style={{ padding: '15px', marginBottom: '16px' }}>
        <AdsStatisticsItemContainer>
          <AdsStatisticsIconContainer style={{ backgroundColor: '#1F77B4' }}>
            <MouseIcon style={{ color: '#FFFFFF' }} />
          </AdsStatisticsIconContainer>
          <div>
            <AdsStatisticsTitle>{t('pages.adsStatistics.clicks')}</AdsStatisticsTitle>
            <AdsStatisticsTextBig>{clicks}</AdsStatisticsTextBig>
          </div>
        </AdsStatisticsItemContainer>
      </Paper>
      <Paper style={{ padding: '15px' }}>
        <AdsStatisticsItemContainer>
          <AdsStatisticsIconContainer style={{ backgroundColor: '#9C1FB4' }}>
            <InsertChartIcon style={{ color: '#FFFFFF' }} />
          </AdsStatisticsIconContainer>
          <div>
            <AdsStatisticsTitle>{t('pages.adsStatistics.ctr')}</AdsStatisticsTitle>
            <AdsStatisticsTextBig>{ctr}%</AdsStatisticsTextBig>
          </div>
        </AdsStatisticsItemContainer>
      </Paper>
    </>
  );
};

const AdsStatisticsItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AdsStatisticsIconContainer = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

const AdsStatisticsTextBig = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #545871;
`;

export default AdsStatisticsSummary;
