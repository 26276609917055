import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { adminMailingListsOperations } from '../../../../store/AdminMailingLists';
import { useTypedSelector } from '../../../../utils';
import { usePaginationWatch } from '../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../Shared/DataTable/useTableSearchWatch';
import { AdminMailingList } from '../../../../store/AdminMailingLists/types';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import PageHeader from '../../../Shared/Layout/PageHeader';
import DataTable from '../../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../../Shared/DataTable/TableActionButton/TableActionButton';
import { TableSearchStoreModule } from '../../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../../store/Pagination/types';
import CreateAdminMailingListWindow from './Windows/CreateAdminMailingListWindow';
import UpdateAdminMailingListWindow from './Windows/UpdateAdminMailingListWindow';
import DeleteAdminMailingListWindow from './Windows/DeleteAdminMailingListWindow';
import { useDispatch } from 'react-redux';
import linksConstants from '../../../../config/app/linksConstants';
import { hasPermission } from '../../../../utils/permissions';
import { MailingListType } from '../../../../store/MailingLists/types';
import styled from 'styled-components';

type AdminMailingListsPageProps = {};

const AdminMailingListsPage: FunctionComponent<AdminMailingListsPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);
  const adminMailingLists = useTypedSelector((state) => state.adminMailingLists.data);
  const isFailed = useTypedSelector((state) => state.adminMailingLists.index.isFailed);
  const isLoading = useTypedSelector((state) => state.adminMailingLists.index.isLoading);
  const pagination = useTypedSelector((state) => state.adminMailingLists.pagination);
  const tableSearch = useTypedSelector((state) => state.adminMailingLists.tableSearch);

  usePaginationWatch(pagination, [adminMailingListsOperations.index]);
  useTableSearchWatch<AdminMailingList>(tableSearch, [adminMailingListsOperations.index]);

  const [activeMailingList, setActiveMailingList] = useState<AdminMailingList | null>(null);
  const [createMailingListWindowOpen, onCreateMailingListWindowOpen, onCreateMailingListWindowClose] = useOpenHandler();
  const [updateMailingListWindowOpen, onUpdateMailingListWindowOpen, onUpdateMailingListWindowClose] = useOpenHandler();
  const [deleteMailingListWindowOpen, onDeleteMailingListWindowOpen, onDeleteMailingListWindowClose] = useOpenHandler();

  const getActionButtons = () => {
    if (hasPermission(role, ['adminMailingListsAdd'])) {
      return [
        {
          label: t('pages.adminMailingLists.create'),
          icon: <AddIcon />,
          onClick: onCreateMailingListWindowOpen
        }
      ];
    }

    return [];
  };

  return (
    <PageContainer>
      <PageHeader title={t(`pages.adminMailingLists.title`)} rightActionButtons={getActionButtons()} />
      <PageContent>
        <DataTable<AdminMailingList, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              isUrl: true,
              url: (adminMailingList) =>
                linksConstants.ADMINISTRATION.MAILING_LISTS.EDIT(adminMailingList.id, adminMailingList.type as string),
              name: 'name',
              sortable: true,
              render: (adminMailingList) => (
                <>
                  <div>{adminMailingList.name}</div>
                  <ExtraInfoIndicator>
                    {adminMailingList.type === MailingListType.TEST ? ' (test)' : ''}
                  </ExtraInfoIndicator>
                </>
              )
            },
            {
              name: 'actions',
              render: (adminMailingList) => (
                <TableActionButtonsContainer>
                  {adminMailingList.type === MailingListType.MAIN && (
                    <TableActionButton
                      icon={<EditIcon />}
                      tooltip={t('pages.adminMailingLists.table.buttons.edit')}
                      ButtonProps={{
                        disabled: !hasPermission(role, ['adminMailingListsEdit']),
                        onClick: () => {
                          setActiveMailingList(adminMailingList);
                          onUpdateMailingListWindowOpen();
                        }
                      }}
                    />
                  )}
                  {adminMailingList.type === MailingListType.MAIN && (
                    <TableActionButton
                      icon={<DeleteIcon />}
                      tooltip={t('pages.adminMailingLists.table.buttons.delete')}
                      ButtonProps={{
                        disabled: !hasPermission(role, ['adminMailingListsRemove']),
                        onClick: () => {
                          setActiveMailingList(adminMailingList);
                          onDeleteMailingListWindowOpen();
                        }
                      }}
                    />
                  )}
                </TableActionButtonsContainer>
              )
            },
            {
              isUrl: true,
              url: (adminMailingList) =>
                linksConstants.ADMINISTRATION.ORGANIZATIONS.USERS(adminMailingList.organization.id),
              name: 'organization',
              sortable: true,
              render: (adminMailingList) => (
                <>
                  <div>{adminMailingList.organization.name}</div>
                  <ExtraInfoIndicator>({adminMailingList.organization.accountNumber || '---'})</ExtraInfoIndicator>
                </>
              )
            },
            {
              name: 'activeMemberCount',
              sortable: true,
              render: (adminMailingList) => <>{adminMailingList.activeMemberCount}</>
            },
            {
              name: 'bouncedMemberCount',
              sortable: true,
              render: (adminMailingList) => <>{adminMailingList.bouncedMemberCount}</>
            },
            {
              name: 'unsubscribedMemberCount',
              sortable: true,
              render: (adminMailingList) => <>{adminMailingList.unsubscribedMemberCount}</>
            },
            {
              name: 'removedMemberCount',
              sortable: true,
              render: (adminMailingList) => <>{adminMailingList.removedMemberCount}</>
            }
          ]}
          tPath={'pages.adminMailingLists.table'}
          data={adminMailingLists}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.ADMIN_MAILING_LISTS
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.ADMIN_MAILING_LISTS
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>

      <CreateAdminMailingListWindow
        open={createMailingListWindowOpen}
        onCloseClick={onCreateMailingListWindowClose}
        fullScreenOnMobile
      />
      <UpdateAdminMailingListWindow
        adminMailingList={activeMailingList}
        open={updateMailingListWindowOpen}
        onCloseClick={onUpdateMailingListWindowClose}
        fullScreenOnMobile
      />
      <DeleteAdminMailingListWindow
        adminMailingList={activeMailingList}
        open={deleteMailingListWindowOpen}
        onCloseClick={onDeleteMailingListWindowClose}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

const ExtraInfoIndicator = styled.div`
  font-size: 0.8rem;
  font-weight: 100;
`;

export default AdminMailingListsPage;
