import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { AdminUser } from '../AdminUsers/types';
import { MailingListType } from '../MailingLists/types';
import { AdminOrganization } from '../AdminOrganizations/types';

export type AdminMailingListsState = StateWithPagination &
  StateWithTableSearch<AdminMailingList> & {
    data: NormalizedAdminMailingLists;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type AdminMailingList = {
  id: string;
  name: string;
  updatedAt: string;
  createdAt: string;
  externalId: string;
  uniqAdminMailingList: 'uniqAdminMailingList';
  createdBy: AdminUser;
  organization: AdminOrganization;
  type: MailingListType;
  activeMemberCount: number;
  bouncedMemberCount: number;
  removedMemberCount: number;
  unsubscribedMemberCount: number;
};

export type NormalizedAdminMailingLists = {
  entities: Dictionary<AdminMailingList>;
  result: string[];
};

export type AdminMailingListsIndexResponse = {
  data: AdminMailingList[];
  pagination: Pagination;
};

export type AdminMailingListsIndexActionBEGIN = {
  type: AdminMailingListsActionsConstants.INDEX_BEGIN;
};

export type AdminMailingListsIndexActionSUCCESS = {
  type: AdminMailingListsActionsConstants.INDEX_SUCCESS;
  payload: AdminMailingListsIndexResponse;
};

export type AdminMailingListsIndexActionFAILED = {
  type: AdminMailingListsActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type AdminMailingListsUpdateAction = {
  type: AdminMailingListsActionsConstants.UPDATE_MAILING_LIST;
  payload: {
    adminMailingList: AdminMailingList;
  };
};

export type IndexAdminMailingListsRequestParams = {
  per: number;
  page: number;
  q: string | null;
  order?: string[];
};

export type CreateAdminMailingListValues = Pick<AdminMailingList, 'name'> & {
  organization: {
    id: string;
    label: string;
  };
};
export type UpdateAdminMailingListValues = Pick<AdminMailingList, 'name'>;

export type AdminMailingListsResponse = {
  data: AdminMailingList[];
};

export enum AdminMailingListsActionsConstants {
  UPDATE_MAILING_LIST = 'ADMINISTRATION/MAILING_LISTS/UPDATE',
  INDEX_BEGIN = 'ADMINISTRATION/MAILING_LISTS/INDEX/BEGIN',
  INDEX_SUCCESS = 'ADMINISTRATION/MAILING_LISTS/INDEX/SUCCESS',
  INDEX_FAILED = 'ADMINISTRATION/MAILING_LISTS/INDEX/FAILED'
}

export type AdminMailingListsActionType =
  | AdminMailingListsUpdateAction
  | AdminMailingListsIndexActionBEGIN
  | AdminMailingListsIndexActionSUCCESS
  | AdminMailingListsIndexActionFAILED;
