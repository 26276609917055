import React, { FunctionComponent, useCallback } from 'react';
import { AdBanner, AdZone } from '../../../../../store/AdminAds/types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { DataTableHeaderCell } from '../../../../Shared/DataTable/DataTableStyled';
import AdBannerTableRow from './AdBannerTableRow';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { AdminEmailTemplate } from '../../../../../store/AdminEmailTemplates/types';

type EmailTemplateAdsAssignmentTablesProps = {
  size: string;
  banners: AdBanner[];
  emailTemplate: AdminEmailTemplate;
  bannerZones: { zone: AdZone; banner: AdBanner }[];
  adSpaces: { [key: string]: number };
};

const EmailTemplateAdsAssignmentTables: FunctionComponent<EmailTemplateAdsAssignmentTablesProps> = ({
  size,
  banners,
  adSpaces,
  bannerZones,
  emailTemplate
}) => {
  const { t } = useTranslation();

  const getAllMatchingBanners = useCallback(
    (size: string): { matchingSizes: AdBanner[]; displayChance: number } => {
      const matchingSizes = banners.filter((banner) => {
        if (size === `${banner.width}x${banner.height}`) {
          const today = moment();

          if (!banner.startDate) {
            return false;
          }

          if (banner.startDate && !banner.endDate) {
            return today.isAfter(moment(banner.startDate));
          }

          if (banner.startDate && banner.endDate) {
            return today.isBetween(moment(banner.startDate), moment(banner.endDate));
          }

          return false;
        }

        return false;
      });
      const multiplier = adSpaces[size] || 1;

      const displayChance = Math.min(Math.floor((100 * multiplier) / matchingSizes.length), 100);

      return { matchingSizes, displayChance };
    },
    [adSpaces]
  );
  const matchingBanners = getAllMatchingBanners(size);
  const renderTable = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <DataTableHeaderCell>{t('pages.adminAds.table.name')}</DataTableHeaderCell>
            </TableCell>
            <TableCell>
              <DataTableHeaderCell>{t('pages.adminAds.table.runDates')}</DataTableHeaderCell>
            </TableCell>
            <TableCell>
              <DataTableHeaderCell>{t('pages.adminAds.table.displayChance')}</DataTableHeaderCell>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {matchingBanners.matchingSizes.length === 0 && (
            <TableCell colSpan={3}>
              <Typography
                variant="subtitle1"
                color="primary"
                style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
              >
                <PhotoLibraryIcon style={{ marginRight: 10 }} /> {t('pages.adminAds.noMatchingAds')}
              </Typography>
            </TableCell>
          )}
          {matchingBanners.matchingSizes.length > 0 &&
            matchingBanners.matchingSizes.map((banner) => (
              <AdBannerTableRow
                key={banner.bannerId}
                context={'assignmentPreview'}
                banner={banner}
                displayChance={matchingBanners.displayChance}
              />
            ))}
        </TableBody>
      </Table>
    );
  };

  const getAllMatchingHouseAds = useCallback(
    (size: string, alreadyMatchedBanners: AdBanner[]): { matchingSizes: AdBanner[]; displayChance: number } => {
      const selectedZones = (emailTemplate.overrideAdZones || '')
        .split(';')
        .filter((val) => val)
        .map((sizeOverride) => {
          const [size, zonesIds] = sizeOverride.split('=');
          return {
            size,
            zones: (zonesIds || '')
              .split(',')
              .filter((savedZoneId) => bannerZones.find((z) => z.zone.zoneId.toString() === savedZoneId.toString()))
          };
        });

      const matchingSizes = bannerZones
        .filter((d) => {
          const siz = `${d.zone.width}x${d.zone.height}`;
          if (size === siz) {
            const selectedZoneData = selectedZones.find((sz) => sz.size === size);
            return selectedZoneData && selectedZoneData.zones.includes(d.zone.zoneId);
          }
          return false;
        })
        .map((b) => b.banner);

      const multiplier = Math.max(0, adSpaces[size] - alreadyMatchedBanners.length) || 1;
      const displayChance = Math.min(Math.floor((100 * multiplier) / matchingSizes.length), 100);

      return { matchingSizes, displayChance };
    },
    [bannerZones, adSpaces]
  );
  const matchingHouseAds = getAllMatchingHouseAds(size, matchingBanners.matchingSizes);

  const renderHouseAdsTable = () => {
    if (matchingBanners.matchingSizes.length >= adSpaces[size]) {
      return null;
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <DataTableHeaderCell>House Ads</DataTableHeaderCell>
            </TableCell>
            <TableCell>
              <DataTableHeaderCell>{t('pages.adminAds.table.displayChance')}</DataTableHeaderCell>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {matchingHouseAds.matchingSizes.length === 0 && (
            <TableCell colSpan={3}>
              <Typography
                variant="subtitle1"
                color="primary"
                style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
              >
                <PhotoLibraryIcon style={{ marginRight: 10 }} /> {t('pages.adminAds.noMatchingHouseAds')}
              </Typography>
            </TableCell>
          )}
          {matchingHouseAds.matchingSizes.length > 0 &&
            matchingHouseAds.matchingSizes.map((banner) => (
              <AdBannerTableRow
                key={banner.bannerId}
                context={'houseAdsPreview'}
                banner={banner}
                displayChance={matchingHouseAds.displayChance}
              />
            ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <>
      {renderTable()}
      {renderHouseAdsTable()}
    </>
  );
};

export default EmailTemplateAdsAssignmentTables;
