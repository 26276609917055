import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import LoginPage from './Login/LoginPage';
import ForgotPasswordPage from './ForgotPassword/ForgotPasswordPage';
import ResendVerificationPage from './ResendVerification/ResendVerificationPage';
import ValidateAndSetPasswordPage from './ValidateAndSetPassword/ValidateAndSetPasswordPage';

const AuthRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.AUTH.LOGIN,
    exact: true,
    name: 'login',
    public: true,
    show: true,
    component: LoginPage,
    scope: []
  },
  {
    path: linksConstants.AUTH.FORGOT_PASSWORD,
    exact: true,
    name: 'forgotPassword',
    public: true,
    show: true,
    component: ForgotPasswordPage,
    scope: []
  },
  {
    path: linksConstants.AUTH.RESEND_VERIFICATION,
    exact: true,
    name: 'resendVerification',
    public: true,
    show: true,
    component: ResendVerificationPage,
    scope: []
  },
  {
    path: linksConstants.AUTH.VERIFY,
    exact: true,
    name: 'verify',
    public: true,
    show: true,
    component: ValidateAndSetPasswordPage,
    scope: []
  },
  {
    path: linksConstants.AUTH.RESET_PASSWORD,
    exact: true,
    name: 'resetPassword',
    public: true,
    show: true,
    component: ValidateAndSetPasswordPage,
    scope: []
  }
];

export default AuthRoutes;
