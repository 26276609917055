import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';

export type PageHeaderVariant = 'default' | 'white';

export type HeaderActionButton = {
  onClick: (event?: any) => void;
  label: string;
  icon?: JSX.Element;
  variant?: 'outlined' | 'contained';
  disabled?: boolean;
  visible?: boolean;
  id?: string;
  style?: React.CSSProperties;
  size?: 'small' | 'large' | 'medium';
};

type PageHeaderProps = {
  title: string | JSX.Element | null;
  variant?: PageHeaderVariant;
  noBottomPadding?: boolean;
  leftActionButtons?: HeaderActionButton[];
  rightActionButtons?: HeaderActionButton[];
  style?: React.CSSProperties;
  rightContent?: JSX.Element;
  innerAdjustmentEnabled?: boolean;
};

const PageHeader: FunctionComponent<PageHeaderProps> = ({
  title,
  leftActionButtons,
  rightActionButtons,
  rightContent,
  variant,
  noBottomPadding,
  style,
  innerAdjustmentEnabled = false
}) => {
  return (
    <PageHeaderContainer
      variant={variant}
      noBottomPadding={noBottomPadding}
      style={style}
      disabled={innerAdjustmentEnabled}
    >
      <LeftActionButtons>
        {leftActionButtons
          ?.filter((button) => typeof button.visible === 'undefined' || button.visible === true)
          .map((button, i) => (
            <HeaderSecondaryButton
              key={i}
              data-item-id={button.id}
              startIcon={button.icon}
              color={'secondary'}
              onClick={button.onClick}
              variant={button.variant || 'outlined'}
              size={'small'}
              disabled={button.disabled}
            >
              {button.label}
            </HeaderSecondaryButton>
          ))}
      </LeftActionButtons>
      <Title>{title}</Title>
      <RightActionButtons>
        {rightActionButtons
          ?.filter((button) => typeof button.visible === 'undefined' || button.visible === true)
          .map((button, i) => (
            <HeaderPrimaryButton
              key={i}
              data-item-id={button.id}
              startIcon={button.icon}
              color={'secondary'}
              variant={button.variant || 'contained'}
              size={button.size || 'large'}
              onClick={button.onClick}
              disabled={button.disabled}
              style={button.style}
            >
              {button.label}
            </HeaderPrimaryButton>
          ))}
      </RightActionButtons>
      {rightContent}
    </PageHeaderContainer>
  );
};

const PageHeaderContainer = styled.div<{ variant?: PageHeaderVariant; noBottomPadding?: boolean; disabled: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: ${({ noBottomPadding }) => (noBottomPadding ? '0' : '2rem')};
  border-bottom: 1px solid #0000001f;

  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};

  ${({ variant }) =>
    variant === 'white' &&
    css`
      padding: 1.5rem;
      align-items: center;
      background: #fff;
    `}
`;
const Title = styled.div`
  flex-grow: 1;
  font-size: 2rem;
  display: flex;
  align-items: center;
`;
const LeftActionButtons = styled.div``;
const RightActionButtons = styled.div`
  text-align: right;
`;
export const HeaderPrimaryButton = styled(Button)`
  margin-left: 1rem;
  font-weight: bold;
`;
export const HeaderSecondaryButton = styled(Button)`
  margin-right: 1rem;
`;

export default PageHeader;
