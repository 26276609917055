import Axios, { AxiosResponse } from 'axios';
import sleep from './sleep';
import { userLatestActivity } from './index';

let headerTokenValue = '';

export async function runRequestRaw<T>(settings: BaseSettings) {
  const { headers, query, params, payload, onUploadProgress } = settings;

  const data: any = payload;

  userLatestActivity.set();

  // !!! Artificial timeout so request won't resolve so quickly - better resembles actual usage of the app in real life
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_THROTTLE_NETWORK_MS &&
    process.env.REACT_APP_THROTTLE_NETWORK_MS > 0
  ) {
    await sleep(process.env.REACT_APP_THROTTLE_NETWORK_MS);
  }

  const response = await Axios({
    headers: getHeaders(headers || {}),
    withCredentials: true,
    method: query.method,
    url: createUrl(query, params),
    data,
    onUploadProgress
  });

  return response as AxiosResponse<T>;
}

export async function runRequest<T>(settings: BaseSettings) {
  const response = await runRequestRaw<T>(settings);
  return response.data;
}

export function createUrl(query: Query, params?: any): string {
  let urlToReplace = '';
  if (typeof query.urlPrefix === 'string') {
    urlToReplace = `${query.urlPrefix}${query.url}`;
  } else {
    urlToReplace = `${process.env.REACT_APP_API_HOST || ''}${query.url}`;
  }

  if (params) {
    urlToReplace = replaceParamPlaceholders(urlToReplace, params);
  }

  if (params && params.q) {
    const encodedSearchString = encodeURIComponent(params.q);
    urlToReplace += urlToReplace.includes('?') ? `&q=${encodedSearchString}` : `?q=${encodedSearchString}`;
  }

  if (params && params.order && params.order.length) {
    urlToReplace += urlToReplace.includes('?') ? `&${params.order.join('&')}` : `?${params.order.join('&')}`;
  }

  return urlToReplace;
}

export function replaceParamPlaceholders(urlToReplace: string, params: any) {
  let url = urlToReplace;

  Object.keys(params).forEach((paramName: string) => {
    if (Array.isArray(params[paramName])) {
      const toReplace = params[paramName].map((param: string) => `${paramName}=${param}`).join('&');
      url = url.replace(`:${paramName}`, toReplace);
    } else {
      url = url.replace(`:${paramName}`, params[paramName]);
    }
  });

  return url.replace(/[&\?]?\w+=:\w+/g, '');
}

export const setHeaderTokenValue = (value: string) => {
  headerTokenValue = value;
};

export function getHeaders(headers: Headers): Headers {
  const values: Headers = {
    ...headers
  };

  if (headerTokenValue) {
    values.Authorization = `Bearer ${headerTokenValue}`;
  }

  return values;
}

// to axios
export type BaseSettings = {
  query: Query;
  headers?: Headers;
  payload?: any;
  params?: any;
  responseType?: ResponseType;
  onUploadProgress?: (progressEvent: ProgressEvent) => any;
};

// defined by user
export type RequestSettings<SettingsParams = {}, SettingsPayload = {}> = {
  requestParams?: SettingsParams;
  requestPayload?: SettingsPayload;
};

export type Headers = {
  Authorization?: string | null;
  filename?: string;
};

export type ResponseType = 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';

export type Query = {
  method: Method;
  url: string;
  urlPrefix?: string;
};

export type Method = 'patch' | 'get' | 'post' | 'put' | 'delete' | 'options';
