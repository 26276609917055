import React, { FunctionComponent } from 'react';
import { Avatar, withTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import { CampaignProjectType } from '../../../../store/Campaigns/types';
import { ReactComponent as StoriesIcon } from '../../../../assets/icons/navigation/stories.svg';
import { ReactComponent as PrintIcon } from '../../../../assets/icons/navigation/print.svg';
import { ReactComponent as EmailIcon } from '../../../../assets/icons/navigation/email.svg';

type CampaignProjectAvatarProps = {
  type: CampaignProjectType;
};

const CampaignProjectAvatar: FunctionComponent<CampaignProjectAvatarProps> = ({ type }) => {
  const iconStyle = {
    width: '20px',
    height: '20px',
    fill: '#fff',
    verticalAlign: 'middle'
  };

  switch (type) {
    case CampaignProjectType.PRINT:
      return (
        <PrintAvatar>
          <PrintIcon style={iconStyle} />
        </PrintAvatar>
      );

    case CampaignProjectType.EMAIL:
      return (
        <EmailAvatar>
          <EmailIcon style={iconStyle} />
        </EmailAvatar>
      );

    case CampaignProjectType.STORY:
      return (
        <StoryAvatar>
          <StoriesIcon style={iconStyle} />
        </StoryAvatar>
      );

    default:
      return (
        <MissingTemplate>
          <DeleteIcon />
        </MissingTemplate>
      );
  }
};

const CampaignProjectAvatarStyled = withTheme(styled(Avatar)`
  margin-right: 1rem;
  position: relative;
  overflow: visible;
`);
const PrintAvatar = withTheme(styled(CampaignProjectAvatarStyled)`
  background: ${({ theme }) => theme.palette.custom.projectTypes.print};
`);
const EmailAvatar = withTheme(styled(CampaignProjectAvatarStyled)`
  background: ${({ theme }) => theme.palette.custom.projectTypes.email};
`);
const StoryAvatar = withTheme(styled(CampaignProjectAvatarStyled)`
  background: ${({ theme }) => theme.palette.custom.projectTypes.story};
`);
const MissingTemplate = withTheme(styled(CampaignProjectAvatarStyled)`
  background: #939393;
`);

export default CampaignProjectAvatar;
