import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { QuickInviteContributorValues, Story, StoryContentContributor } from '../../../../store/Stories/types';
import { storiesOperations } from '../../../../store/Stories';
import Toast from '../../../Shared/Toast/Toast';
import { Row, Strong } from '../../../Shared/StyledComponents';
import { errorCodeToToast } from '../../../../utils';
import { AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import ModalFormWindow from '../../../Shared/Window/ModalFormWindow';

type DeleteStoryContributorWindowProps = {
  open: boolean;
  story: Story | null;
  contributor: StoryContentContributor | null;
  onCloseClick: () => void;
  onSuccessfulSubmit: () => void;
  fullScreenOnMobile: boolean;
};

const QuickInviteContributorWindow: FunctionComponent<DeleteStoryContributorWindowProps> = ({
  story,
  contributor,
  onCloseClick,
  onSuccessfulSubmit,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!story || !contributor) return null;

  const onSubmit = async (values: QuickInviteContributorValues) => {
    try {
      await dispatch(
        storiesOperations.inviteContentContributor(story.id, {
          email: contributor.email,
          firstName: contributor.firstName,
          lastName: contributor.lastName,
          infobox: values.infobox
        })
      );
      await dispatch(storiesOperations.indexStoryContributors(story.id));
      onCloseClick();
      onSuccessfulSubmit();
      Toast.success(t('notifications.story.contributorInvited'));
    } catch (e) {
      errorCodeToToast.handleContributorInvitationErrorCode(e, t);
    }
  };

  const formInputs = () => (
    <>
      <Row>{t('pages.story.windows.quickInviteContributor.content')}</Row>
      <Row>
        <Strong>{contributor.email}</Strong> {contributor.firstName} {contributor.lastName}
      </Row>
      <AdminTextInput t={t} name="infobox" section="story" multiline />
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      header={t('pages.story.windows.quickInviteContributor.header')}
      okButtonText={t('common.submit')}
      onCloseClick={onCloseClick}
      forceSubmitToBeUnlocked={true}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        infobox: ''
      }}
      validationSchema={Yup.object().shape({
        infobox: Yup.string().nullable().optional()
      })}
    />
  );
};

export default QuickInviteContributorWindow;
