import { imagesOperations } from '../../../../../../store/Images';
import app from '../../../../../../config/app/app';
import Toast from '../../../../../Shared/Toast/Toast';
import { TFunction } from 'i18next';
import { ImageData } from '../../types';

export const nativeImage = async (
  projectId: string,
  imageData: ImageData,
  file: File,
  t: TFunction,
  type: 'project' | 'template'
) => {
  const extension = file.name.split('.').pop();

  if (!app.validLogoExtensions.includes((extension || '').toLowerCase())) {
    Toast.error(t('notifications.uploadEmailFile.invalidExtension'));
    return null;
  }

  if (file.size > app.maxLogoSize) {
    Toast.error(t('notifications.uploadEmailFile.fileTooBig'));
    return null;
  }

  // upload image using brandFolder id
  return await imagesOperations.uploadImage(
    file,
    projectId,
    type,
    imageData.containerWidth || 80,
    imageData.containerHeight || 80,
    (progress) => {
      console.log('progress', progress);
    }
  );
};
