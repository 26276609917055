import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import {
  AdminCreatePasswordPolicyValues,
  AdminUpdatePasswordPolicyValues,
  IndexAdminPasswordPoliciesRequestParams
} from './types';

export const index = (settings: RequestSettings<IndexAdminPasswordPoliciesRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/password_policies?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{}, AdminCreatePasswordPolicyValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/password_policies'
  },
  payload: settings.requestPayload
});

export const update = (settings: RequestSettings<{}, AdminUpdatePasswordPolicyValues>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/password_policies/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deletePasswordPolicy = (settings: RequestSettings<{ id: string }>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/password_policies/:id'
  },
  params: settings.requestParams
});

export const getAll = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/password_policies/all'
  }
});
