import React, { useCallback } from 'react';
import AdminKeywordChip from './AdminKeywordChip';
import { IconButton, Popover, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { AdminGetAllKeywordsDataResponse, AdminKeyword, AdminKeywordGroup } from '../../../store/AdminKeywords/types';
import styled from 'styled-components';
import AdminKeywordGroupChip from './AdminKeywordGroupChip';
import { useTranslation } from 'react-i18next';

type AdminKeywordsListProps = {
  readOnly: boolean;
  allKeywords: AdminGetAllKeywordsDataResponse;
  keywords: AdminKeyword[];
  groups: AdminKeywordGroup[];
  onAdd: (tag: AdminKeyword) => void;
  onDelete: null | ((tag: AdminKeyword) => void);
  onAddGroup: (tag: AdminKeywordGroup) => void;
  onDeleteGroup: null | ((tag: AdminKeywordGroup) => void);
};

const AdminKeywordsList: React.FC<AdminKeywordsListProps> = ({
  keywords,
  allKeywords,
  onDelete,
  onAdd,
  readOnly,
  onDeleteGroup,
  onAddGroup,
  groups
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [searchValue, setSearchValue] = React.useState<string>('');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setTimeout(() => setSearchValue(''), 500);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const availableKeywords = allKeywords.keywords.filter((keyword) => !keywords.find((t) => t.id === keyword.id));
  const availableGroups = allKeywords.groups.filter((group) => !groups.find((t) => t.id === group.id));

  const getSearchResults = useCallback(
    (list: any[]): AdminKeyword[] | AdminKeywordGroup[] => {
      return list.filter((tag) => {
        if (searchValue) {
          return tag.name.toLowerCase().includes(searchValue.toLowerCase());
        }

        return tag;
      });
    },
    [searchValue]
  );

  const keywordsSearchResults = getSearchResults(availableKeywords) as AdminKeyword[];
  const groupsSearchResults = getSearchResults(availableGroups) as AdminKeywordGroup[];

  return (
    <Wrapper>
      <KeywordsList>
        {groups.map((group) => (
          <AdminKeywordGroupChip key={group.id} keywordGroup={group} onDelete={onDeleteGroup} />
        ))}
      </KeywordsList>
      <KeywordsList>
        {keywords.map((keyword) => (
          <AdminKeywordChip key={keyword.id} keyword={keyword} onDelete={onDelete} />
        ))}
      </KeywordsList>
      {!readOnly && (
        <AddButtonWrapper>
          <IconButton aria-describedby={id} size="small" onClick={handleClick}>
            <AddIcon />
          </IconButton>
        </AddButtonWrapper>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <PopoverKeywordsList>
          {availableKeywords.length === 0 && availableGroups.length === 0 && (
            <NoKeywords>No keywords to add...</NoKeywords>
          )}
          {(availableKeywords.length > 0 || availableGroups.length > 0) && (
            <TextField
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 60
              }}
              placeholder={`${t('common.search')}...`}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          )}

          {!groupsSearchResults.length && !keywordsSearchResults.length && <NoKeywords>No results...</NoKeywords>}

          {groupsSearchResults.length > 0 && (
            <>
              <KeywordsTypeTitle>Groups</KeywordsTypeTitle>
              {groupsSearchResults.map((tag) => (
                <PopoverKeywordListItem key={tag.id} onClick={() => onAddGroup(tag)}>
                  <PopoverKeywordListItemLabel>{tag.name}</PopoverKeywordListItemLabel>
                </PopoverKeywordListItem>
              ))}
            </>
          )}
          {keywordsSearchResults.length > 0 && (
            <>
              <KeywordsTypeTitle>Keywords</KeywordsTypeTitle>
              {keywordsSearchResults.map((tag) => (
                <PopoverKeywordListItem key={tag.id} onClick={() => onAdd(tag)}>
                  <PopoverKeywordListItemLabel>{tag.name}</PopoverKeywordListItemLabel>
                </PopoverKeywordListItem>
              ))}
            </>
          )}
        </PopoverKeywordsList>
      </Popover>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const KeywordsTypeTitle = styled.div`
  font-weight: bold;
  padding: 1rem 2rem 1rem 1rem;
  border-bottom: 1px solid #ccc;
`;

const KeywordsList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const AddButtonWrapper = styled.div`
  margin-left: 5px;
`;

const PopoverKeywordsList = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoKeywords = styled.div`
  padding: 7px 20px;
  font-style: italic;
`;

const PopoverKeywordListItem = styled.span`
  padding: 7px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;
const PopoverKeywordListItemLabel = styled.span``;

export default AdminKeywordsList;
