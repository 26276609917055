import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import Toast from '../../../Shared/Toast/Toast';
import { DoAppMerchant } from '../../../../store/DoApp/Merchants/types';
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';

type PromotionalCreditsConfigurationWindowProps = {
  open: boolean;
  merchant: DoAppMerchant;
  onCloseClick: () => void;
  onSubmit?: () => void;
  fullScreenOnMobile: boolean;
};

const PromotionalCreditsConfigurationWindow: FunctionComponent<PromotionalCreditsConfigurationWindowProps> = ({
  onCloseClick,
  onSubmit,
  merchant,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState<number>(1);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedOption(event.target.value as number);
  };

  const onFormSubmit = async () => {
    try {
      // TODO: add API call to set promotional credits option
      // await dispatch(doAppCreditTransactionsOperations.promotionalCreditsConfig(merchant.id, selectedOption));
      onCloseClick();
      Toast.success(t('notifications.promotionalCreditsConfiguration.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.promotionalCreditsConfiguration.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.myOrganization.windows.promotionalCreditsConfiguration.title')}
      okButtonText={t('pages.myOrganization.windows.promotionalCreditsConfiguration.submit')}
      onCloseClick={onCloseClick}
      onSubmit={onFormSubmit}
    >
      <FormControl fullWidth variant="outlined" size="small">
        <Select value={selectedOption} onChange={handleChange}>
          <MenuItem value={1}>
            <Typography variant="body2">
              {t('pages.myOrganization.windows.promotionalCreditsConfiguration.creditOption1')}
            </Typography>
          </MenuItem>
          <MenuItem value={2}>
            <Typography variant="body2">
              {t('pages.myOrganization.windows.promotionalCreditsConfiguration.creditOption2')}
            </Typography>
          </MenuItem>
          <MenuItem value={3}>
            <Typography variant="body2">
              {t('pages.myOrganization.windows.promotionalCreditsConfiguration.creditOption3')}
            </Typography>
          </MenuItem>
        </Select>
      </FormControl>
    </ModalConfirmationWindow>
  );
};

export default PromotionalCreditsConfigurationWindow;
