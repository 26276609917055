import AdminKeywordGroupsPage from './AdminKeywordGroupsPage';
import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';

const AdminKeywordGroupsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMINISTRATION.KEYWORD_GROUPS.INDEX,
    exact: true,
    name: 'keywordGroups',
    public: false,
    show: true,
    component: AdminKeywordGroupsPage,
    scope: []
  }
];

export default AdminKeywordGroupsRoutes;
