import update from 'immutability-helper';
import {
  AdminMailingListsActionsConstants,
  AdminMailingListsActionType,
  AdminMailingListsState,
  AdminMailingList
} from './types';
import { normalizeAdminMailingLists } from '../helpers';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: AdminMailingListsState = {
  data: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const adminMailingListsPaginationReducer = createPaginationReducer<AdminMailingListsState, AdminMailingList>(
  PaginationStoreModule.ADMIN_MAILING_LISTS
);

const adminMailingListsTableSearchReducer = createTableSearchReducer<AdminMailingList, AdminMailingListsState>(
  TableSearchStoreModule.ADMIN_MAILING_LISTS
);

export default function adminMailingListsReducer(
  state = initialState,
  action: WithLogoutAction<AdminMailingListsActionType>
) {
  switch (action.type) {
    case AdminMailingListsActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case AdminMailingListsActionsConstants.INDEX_SUCCESS: {
      const data = normalizeAdminMailingLists(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        data: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case AdminMailingListsActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        data: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AdminMailingListsActionsConstants.UPDATE_MAILING_LIST: {
      const mailingList = action.payload.adminMailingList;

      if (mailingList && state.data.entities[mailingList.id]) {
        return update(state, {
          data: {
            entities: {
              [mailingList.id]: { $set: mailingList }
            }
          }
        });
      }

      return state;
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return adminMailingListsTableSearchReducer(
        adminMailingListsPaginationReducer(state, action),
        action
      ) as AdminMailingListsState;
    }
  }
}
