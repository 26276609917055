import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { DoAppPromotionClaim } from '../../../../store/DoApp/PromotionClaims/types';
import { doAppPromotionClaimsOperations } from '../../../../store/DoApp/PromotionClaims';

type CancelDisputePromotionClaimWindowProps = {
  open: boolean;
  promotionClaim: DoAppPromotionClaim;
  onCloseClick: () => void;
  onSubmit: () => void;
  fullScreenOnMobile: boolean;
};

const CancelDisputePromotionClaimWindow: FunctionComponent<CancelDisputePromotionClaimWindowProps> = ({
  onCloseClick,
  onSubmit,
  promotionClaim,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onFormSubmit = async () => {
    try {
      await dispatch(doAppPromotionClaimsOperations.unReject(promotionClaim.id));
      onCloseClick();
      onSubmit();
      Toast.success(t('notifications.cancelDisputePromotionClaim.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.cancelDisputePromotionClaim.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.myOrganization.windows.cancelDisputePromotionClaim.title')}
      okButtonText={t('pages.myOrganization.windows.cancelDisputePromotionClaim.submit')}
      onCloseClick={onCloseClick}
      onSubmit={onFormSubmit}
    >
      <Row>
        {t('pages.myOrganization.windows.cancelDisputePromotionClaim.subscriber')}:{' '}
        <Strong>{promotionClaim.subscriber.email}</Strong>
        <br />
        {t('pages.myOrganization.windows.cancelDisputePromotionClaim.promotionName')}:{' '}
        <Strong>{promotionClaim.promotion.name}</Strong>
      </Row>
      <Row>
        {t('pages.myOrganization.windows.cancelDisputePromotionClaim.reason')}:{' '}
        <Strong>{promotionClaim.rejection?.comment}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default CancelDisputePromotionClaimWindow;
