import React, { FunctionComponent } from 'react';
import { Switch } from 'react-router';
import RoutesConfig from '../../../routes/RoutesConfig';
import SignedInRoute from '../../../routes/SignedInRoute';

type SignedInRoutesContainerProps = {};

const FullPageRoutesContainer: FunctionComponent<SignedInRoutesContainerProps> = () => {
  const fullPageRoutes = RoutesConfig.createFullPageRoutes();

  return (
    <Switch>
      {fullPageRoutes.map((route) => {
        return (
          <SignedInRoute
            name={route.name}
            key={route.name}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        );
      })}
    </Switch>
  );
};

export default FullPageRoutesContainer;
