import {
  AdminOnlineUsersActionsConstants,
  AdminOnlineUsersIndexActionBEGIN,
  AdminOnlineUsersIndexActionFAILED,
  AdminOnlineUsersIndexActionSUCCESS,
  AdminOnlineUsersIndexResponse
} from './types';

export const adminOnlineUsersIndexBegin = (): AdminOnlineUsersIndexActionBEGIN => ({
  type: AdminOnlineUsersActionsConstants.INDEX_BEGIN
});

export const adminOnlineUsersIndexSuccess = (
  payload: AdminOnlineUsersIndexResponse
): AdminOnlineUsersIndexActionSUCCESS => ({
  type: AdminOnlineUsersActionsConstants.INDEX_SUCCESS,
  payload
});

export const adminOnlineUsersIndexFailed = (error: any): AdminOnlineUsersIndexActionFAILED => ({
  type: AdminOnlineUsersActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});
