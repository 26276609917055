import React from 'react';
import { TFunction } from 'i18next';

//@ts-ignore
export default function translateNetworkError(error: NetworkError | null, t: TFunction, withErrorPrefix?: boolean) {
  if (!error) return '';
  return `${withErrorPrefix ? `Error ${error.statusCode}:` : ''} ${t([
    `networkErrors.${error.message}`,
    `networkErrors.fallbacks.${error.statusCode}`,
    `networkErrors.fallbacks.unspecified`
  ])}`;
}
