import React from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../utils';
import { push } from 'connected-react-router';
import linksConstants from '../config/app/linksConstants';
import { Route } from 'react-router';

interface SignedInRouteProps {
  exact: boolean;
  path: string;
  name: string;
  component: any;
}

const SignedInRoute = (props: SignedInRouteProps) => {
  const dispatch = useDispatch();
  const isAuthenticated = useTypedSelector((state) => state.auth.authenticated);

  if (!isAuthenticated && window.location.pathname !== '/logout') {
    dispatch(push(`${linksConstants.AUTH.LOGIN}?back_url=${window.location.pathname}`));
  }
  return <Route {...props} />;
};

export default SignedInRoute;
