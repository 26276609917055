import { EmailProjectRow, ProjectModelComponent } from '../../types';
import React from 'react';
import { TFunction } from 'i18next';
import { FormikProps } from 'formik';
import { socialLinks } from './Components/socialLinks';
import { adSpace } from './Components/adSpace';
import { separator } from './Components/separator';
import { row } from './Components/row';

export type ComponentEditFormReturnType<T> = {
  type: string;
  form: (props: FormikProps<any>) => JSX.Element;
  initialFields: { [key: string]: string | number | object | boolean };
  validationSchema: any;
  onSubmit: (formikValues: any, component?: any) => T;
};

export const getComponentEditForm = (
  component: ProjectModelComponent,
  t: TFunction,
  inProjectEditor: boolean
): ComponentEditFormReturnType<ProjectModelComponent> | null => {
  switch (component.type) {
    case 'social-links':
      return socialLinks(component, t);
    case 'ad-space':
      return adSpace(component, t);
    // case 'image':
    // case 'logo-horizontal':
    // case 'logo-vertical':
    //   return image(component, t, inProjectEditor);
    case 'separator':
      return separator(component, t);
    // case 'article-vertical':
    // case 'article-horizontal':
    //   return article(component, t);
  }

  return null;
};

export const componentHasConfig = (component: ProjectModelComponent, inProjectEditor: boolean) => {
  const componentsWithConfig: ProjectModelComponent['type'][] = [
    'social-links',
    'separator'
    // 'image',
    // 'logo-horizontal',
    // 'logo-vertical',
    // 'article-vertical',
    // 'article-horizontal'
  ];

  if (!inProjectEditor) {
    componentsWithConfig.push('ad-space');
  }

  return componentsWithConfig.includes(component.type);
};

export type RowEditFormReturnType<T> = {
  form: (props: FormikProps<any>) => JSX.Element;
  initialFields: {
    style: { [key: string]: string | number };
    role: EmailProjectRow['role'];
  };
  validationSchema: any;
  onSubmit: (formikValues: any, component?: T) => T;
};

export const getRowEditForm = (
  component: EmailProjectRow,
  t: TFunction,
  inProjectEditor: boolean
): RowEditFormReturnType<EmailProjectRow> | null => {
  return row(component, t, inProjectEditor);
};
