import linksConstants from '../config/app/linksConstants';
import { AdminRole, DefaultRoleName } from '../store/AdminRoles/types';

export function getRedirectByRole(role: AdminRole) {
  switch (role.name) {
    case DefaultRoleName.SYSTEM_OWNER:
    case DefaultRoleName.SUPPORT:
      return linksConstants.ADMINISTRATION.USERS.INDEX;

    default:
      return linksConstants.DASHBOARD.INDEX;
  }
}
