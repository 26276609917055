import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { TemplateTypeName } from '../Templates/types';
import { IndexEmailUserProjectsRequestParams } from './types';
import { ProjectModelV2 } from '../../components/Pages/Editors/EmailDndEditor/types';

export const create = (
  settings: RequestSettings<
    { templateId: string },
    { publishDate: string; type: TemplateTypeName; sourceProjectId: string | null; test: boolean }
  >
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/channel/email/:templateId'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const createAutomatedEmailContent = (
  settings: RequestSettings<{}, { type: TemplateTypeName; sourceProjectId: string }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/channel/email/create_automated_email'
  },
  payload: settings.requestPayload
});

export const getUserEmailProjects = (
  settings: RequestSettings<IndexEmailUserProjectsRequestParams, {}>
): BaseSettings => {
  return {
    query: {
      method: 'get',
      url: `/api/v1/channel/email?per=:per&page=:page`
    },
    params: settings.requestParams
  };
};

export const show = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/email/:projectId'
  },
  params: settings.requestParams
});

export const saveEmailProjectData = (
  settings: RequestSettings<{ projectId: string }, { name?: string; subject?: string }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/channel/email/:projectId'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const getEmailProjectPreviewUrl = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/email/:projectId/preview_url'
  },
  params: settings.requestParams
});

export const setProjectModel = (
  settings: RequestSettings<{ projectId: string }, { projectModel: ProjectModelV2 }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/channel/email/:projectId/model'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const prepareFinalPreview = (
  settings: RequestSettings<{ projectId: string }, { html: string }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/channel/email/:projectId/final_preview'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const removeProject = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/channel/email/:projectId'
  },
  params: settings.requestParams
});

export const shareProject = (
  settings: RequestSettings<{ projectId: string }, { organizations: string[] }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/channel/email/:projectId/share'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const automatedEmailOptOut = (settings: RequestSettings<{ printProjectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/channel/email/:printProjectId/automated_email_opt_out'
  },
  params: settings.requestParams
});

export const publishProject = (
  settings: RequestSettings<
    { projectId: string; mailingListId: string },
    { publishTime: Date; subject: string; publishDate: string }
  >
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/channel/email/:projectId/publish/:mailingListId'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const updatePublishDateEntity = (
  settings: RequestSettings<
    { projectId: string },
    { publishTime: Date; subject: string; publishDate: string; mailingListId: string }
  >
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/channel/email/:projectId/update_publish_date'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const sendToTestMailingList = (
  settings: RequestSettings<{ projectId: string; mailingListId: string }, { subject: string }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/channel/email/:projectId/publish_test/:mailingListId'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const cancelProjectPublication = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/channel/email/:projectId/publish_cancel'
  },
  params: settings.requestParams
});

export const getProjectModel = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/email/:projectId/model'
  },
  params: settings.requestParams
});

export const getBrandfolderAssets = (
  settings: RequestSettings<{ per: number; page: number; search: string }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/integration/assets?per=:per&page=:page&search=:search'
  },
  params: settings.requestParams
});

export const getBrandfolderSectionAssets = (
  settings: RequestSettings<{ sectionId: string; per: number; page: number; search: string }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/integration/sections/:sectionId/assets?per=:per&page=:page&search=:search'
  },
  params: settings.requestParams
});

export const getBrandfolderSections = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/integration/sections'
  }
});

export const getBrandfolderPins = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/integration/pins'
  }
});

export const getBrandfolderAttachments = (settings: RequestSettings<{ assetId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/integration/assets/:assetId/attachments'
  },
  params: settings.requestParams
});

export const saveProjectPublishDate = (
  settings: RequestSettings<{ projectId: string }, { name: string; publishDate: Date }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/channel/email/:projectId'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const checkIn = (settings: RequestSettings<{ projectId: string }, { checkInToken: string }>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/channel/email/:projectId/check_in'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const getOrganizationImages = (
  settings: RequestSettings<{ projectId: string; per: number; page: number; search: string }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/email/:projectId/images?per=:per&page=:page&search=:search'
  },
  params: settings.requestParams
});

export const toggleStickyImage = (
  settings: RequestSettings<{ projectId: string; imageId: string }, {}>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/channel/email/:projectId/images/:imageId/toggle_sticky'
  },
  params: settings.requestParams
});

export const removeImage = (settings: RequestSettings<{ projectId: string; imageId: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/channel/email/:projectId/images/:imageId'
  },
  params: settings.requestParams
});

export const uploadImage = (settings: RequestSettings<{ projectId: string }, File>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/channel/email/:projectId/images'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const uploadAttachment = (settings: RequestSettings<{ projectId: string }, File>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/channel/email/:projectId/attachment'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeAttachment = (
  settings: RequestSettings<{ projectId: string; attachmentId: string }, {}>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/channel/email/:projectId/attachment/:attachmentId'
  },
  params: settings.requestParams
});

export const emailCampaignStatsSubscribers = (settings: RequestSettings<{ projectId: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/email/:projectId/stats/subscribers'
  },
  params: settings.requestParams
});

export const emailCampaignStatsLinksActivity = (settings: RequestSettings<{ projectId: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/email/:projectId/stats/links'
  },
  params: settings.requestParams
});

export const emailCampaignStatsByLocation = (settings: RequestSettings<{ projectId: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/email/:projectId/stats/location'
  },
  params: settings.requestParams
});

export const emailCampaignStatsSummary = (settings: RequestSettings<{ projectId: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/email/:projectId/stats/summary'
  },
  params: settings.requestParams
});

export const getAutomatedEmailTemplate = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/email/automated_email_template'
  }
});

export const getSenders = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/email/senders'
  }
});

export const getAttachments = (settings: RequestSettings<{ projectId: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/email/:projectId/attachment'
  },
  params: settings.requestParams
});

export const cloneShared = (settings: RequestSettings<{ projectId: string }, { name: string }>): BaseSettings => ({
  query: {
    method: 'post',
    url: `/api/v1/channel/email/:projectId/clone_shared`
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const getProjectClones = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/email/:projectId/clones'
  },
  params: settings.requestParams
});

export const cloneProject = (settings: RequestSettings<{ projectId: string }, { name: string }>): BaseSettings => ({
  query: {
    method: 'post',
    url: `/api/v1/channel/email/:projectId/clone`
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const getEmailProjectCampaigns = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/email/:projectId/campaign'
  },
  params: settings.requestParams
});
