import React from 'react';
import styled from 'styled-components';

type TableActionButtonsContainerProps = {};

const TableActionButtonsContainer: React.FC<TableActionButtonsContainerProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export default TableActionButtonsContainer;
