import React from 'react';
import { PageContentPaper } from '../../PageStyledComponents';
import ProjectCampaigns from '../../Editors/Common/ProjectCampaigns';
import { AdminRole } from '../../../../store/AdminRoles/types';
import { User } from '../../../../store/Auth/types';
import { CampaignProjectType } from '../../../../store/Campaigns/types';

type CampaignsTabProps = {
  storyId: string;
  role: AdminRole;
  user: User;
  type: CampaignProjectType;
};

const CampaignsTab: React.FC<CampaignsTabProps> = ({ storyId, role, user, type }) => {
  return (
    <PageContentPaper style={{ margin: 0, padding: '1rem 2rem' }}>
      <ProjectCampaigns projectId={storyId} role={role} user={user} type={type} />
    </PageContentPaper>
  );
};

export default CampaignsTab;
