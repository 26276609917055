import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageContent } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import { RouteComponentProps } from 'react-router';
import { emailProjectsOperations } from '../../../store/EmailProjects';
import { EmailProject } from '../../../store/EmailProjects/types';
import EmailStatsSummary from './Partials/EmailStatsSummary';
import styled from 'styled-components';

type EmailStatisticsPageProps = RouteComponentProps<{ projectId: string }> & {};

const EmailStatisticsPage: FunctionComponent<EmailStatisticsPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const [project, setProject] = useState<EmailProject | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (match.params.projectId) {
        setProject(await emailProjectsOperations.show(match.params.projectId));
      }
    };

    fetchData();
  }, []);

  if (!project) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeader title={t('pages.emailStatistics.title')} />
      <PageContent>
        <EmailStatsSummary project={project} />
        {/* <EmailStatsLocation project={project} /> */}
      </PageContent>
    </PageContainer>
  );
};

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
`;

export const UniqueTotalBarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UniqueTotalBarBackground = styled.div`
  background-color: #369faf;
  width: 110px;
  height: 3px;
  position: relative;
  margin-left: 15px;
`;

export const UniqueTotalBar = styled.div`
  height: 3px;
  background-color: #f2c14a;
  position: absolute;
  left: 0;
  top: 0;
`;

export default EmailStatisticsPage;
