import { PrintPublishStep } from '../../types';

export const getHeaderBasedOnStep = (step: PrintPublishStep, onlyPreview: boolean): string => {
  if (onlyPreview) {
    return 'Print Project Preview';
  }
  switch (step) {
    case PrintPublishStep.REVIEW:
    default:
      return 'Publish Print Project';

    case PrintPublishStep.SELECT_EMAIL_TEMPLATE:
    case PrintPublishStep.EMAIL_PREVIEW:
      return '';

    case PrintPublishStep.LPI_EXPRESS:
      return 'Publish Print Project';

    case PrintPublishStep.ARTICLE_SELECTION:
      return 'Select/Re-order Email Articles';

    case PrintPublishStep.CHOOSE_EMAIL_OPTION:
      return 'Choose Email Method';

    case PrintPublishStep.SCHEDULE_FORM:
      return 'Schedule for publication';

    case 'cancel':
      return 'Are you sure?';
  }
};

export const getConfirmButtonTextBasedOnStep = (
  step: PrintPublishStep,
  emailOption: 'express' | 'custom',
  automatedFlowEnabled: boolean
): string => {
  switch (step) {
    case PrintPublishStep.REVIEW:
    default:
      return 'Next';

    case PrintPublishStep.EMAIL_PREVIEW:
      return 'Continue to Schedule';

    case PrintPublishStep.LPI_EXPRESS:
      return automatedFlowEnabled ? 'Next' : 'Finish';

    case PrintPublishStep.SCHEDULE_FORM:
      return 'Schedule';

    case PrintPublishStep.CANCEL:
      return 'Skip Email Creation';
  }
};

export const getCancelButtonTextBasedOnStep = (step: PrintPublishStep): string => {
  switch (step) {
    case PrintPublishStep.REVIEW:
    case PrintPublishStep.LPI_EXPRESS:
    default:
      return 'Cancel';

    case PrintPublishStep.ARTICLE_SELECTION:
    case PrintPublishStep.CANCEL:
    case PrintPublishStep.SCHEDULE_FORM:
    case PrintPublishStep.EMAIL_PREVIEW:
      return 'Back';
  }
};

export const getNextStep = (step: PrintPublishStep): PrintPublishStep => {
  switch (step) {
    case PrintPublishStep.REVIEW:
      return PrintPublishStep.LPI_EXPRESS;

    case PrintPublishStep.LPI_EXPRESS:
      return PrintPublishStep.CHOOSE_EMAIL_OPTION;

    case PrintPublishStep.ARTICLE_SELECTION:
      return PrintPublishStep.SCHEDULE_FORM;

    case PrintPublishStep.EMAIL_PREVIEW:
      return PrintPublishStep.SCHEDULE_FORM;

    default:
      return PrintPublishStep.REVIEW;
  }
};
