import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import UnsubscribedIcon from '../../../../assets/icons/unsubscribed.png';

type UnsubscribedPageProps = {};

const UnsubscribedPage: FunctionComponent<UnsubscribedPageProps> = () => {
  const { t } = useTranslation();

  return (
    <>
      <LogoWrapper>
        <Logo src={UnsubscribedIcon} />
      </LogoWrapper>
      <Typography variant="h4" gutterBottom>
        Thank you
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        You have been successfully removed from our subscriber list and won&apos;t receive any further emails from us.
      </Typography>
    </>
  );
};

const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const LogoWrapper = styled.div`
  width: 200px;
  padding: 2rem 2rem 2rem 0;
`;

export default UnsubscribedPage;
