import React, { ChangeEvent, FunctionComponent, useRef, useState } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { subscribersOperations } from '../../../../../store/Subscribers';
import { useDispatch } from 'react-redux';
import Toast from '../../../../Shared/Toast/Toast';
import { CreateSubscriberValues } from '../../../../../store/Subscribers/types';
import { AdminTextInput } from '../../../Admin/Shared/AdminFormInputs';
import app from '../../../../../config/app/app';
import DeleteSubscriberWindow from './DeleteSubscriberWindow';
import { PageContainer } from '../../../PageStyledComponents';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import styled from 'styled-components';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { createNetworkErrorObject } from '../../../../../utils';

type ImportFromCsvWindowProps = {
  open: boolean;
  mailingListId: string;
  onCloseClick: (isFormSubmitted: boolean) => void;
  fullScreenOnMobile: boolean;
};

const ImportFromCsvWindow: FunctionComponent<ImportFromCsvWindowProps> = ({ mailingListId, onCloseClick, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const csvUploaderRef = useRef<HTMLInputElement | null>(null);

  const importFromCsv = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      try {
        setLoading(true);
        await dispatch(subscribersOperations.importCsv(mailingListId, file));
        Toast.success(t('notifications.subscribers.successOperation'));

        if (csvUploaderRef.current) {
          csvUploaderRef.current.value = '';
        }
        onCloseClick(true);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (csvUploaderRef.current) {
          csvUploaderRef.current.value = '';
        }

        const err = createNetworkErrorObject(e);

        switch (err.message) {
          case 'no_valid_users_found':
            return Toast.error(t('notifications.subscribers.noValidUsersFound'));

          case 'max_users_limit':
            return Toast.error(t('notifications.subscribers.maxUsersLimit'));

          case 'mailing_list_not_found':
            return Toast.error(t('notifications.subscribers.mailingListNotFound'));

          case 'first_name_contains_invalid_characters':
            return Toast.error(t('notifications.subscribers.firstNameContainsInvalidCharacters'));

          case 'last_name_contains_invalid_characters':
            return Toast.error(t('notifications.subscribers.lastNameContainsInvalidCharacters'));

          default:
            return Toast.error(t('notifications.subscribers.errorOperation'));
        }
      }
    }
  };

  const onSubmit = async () => {
    csvUploaderRef.current && csvUploaderRef.current.click();
  };

  return (
    <ModalConfirmationWindow
      open={open}
      okButtonText={t('common.import')}
      header={t('pages.subscribers.importFromCsv')}
      onCloseClick={() => onCloseClick(false)}
      onSubmit={onSubmit}
      disableSubmitButton={loading}
    >
      <>
        <Row>{t('pages.subscribers.windows.importFromCsvInfo')}</Row>
        <Row>
          <Strong>{t('pages.subscribers.windows.importFromCsvInfo2')}</Strong>
        </Row>
        <Row>{t('pages.subscribers.windows.importFromCsvInfo3')}</Row>
        <Row>
          <CsvExampleTitle>{t('pages.subscribers.windows.csvExampleFull')}</CsvExampleTitle>
          <Code>
            email,firstName,lastName
            <br />
            full@example.com,John,Doe
            <br />
            only_email@example.com
            <br />
            only_first_name@example.com,John
            <br />
            only_last_name@example.com,,Doe
          </Code>
          <CsvExampleTitle>{t('pages.subscribers.windows.csvExampleOnlyEmail')}</CsvExampleTitle>
          <Code>
            email
            <br />
            one@example.com
            <br />
            two@example.com
            <br />
            three@example.com
            <br />
            four@example.com
          </Code>
          <CsvExampleTitle>{t('pages.subscribers.windows.csvExampleWithFirstName')}</CsvExampleTitle>
          <Code>
            email,firstName
            <br />
            john@example.com,John
            <br />
            kelly@example.com,Kelly
            <br />
            peter@example.com,Peter
            <br />
            kate@example.com,Kate
          </Code>
          <CsvExampleTitle>{t('pages.subscribers.windows.csvExampleWithLastName')}</CsvExampleTitle>
          <Code>
            email,lastName
            <br />
            john@example.com,Doe
            <br />
            kelly@example.com,Johnson
            <br />
            peter@example.com,Williams
            <br />
            kate@example.com,Miller
          </Code>
        </Row>
        <input
          type="file"
          id="csvUploader"
          accept=".csv"
          ref={csvUploaderRef}
          style={{ display: 'none' }}
          onChange={importFromCsv}
        />
      </>
    </ModalConfirmationWindow>
  );
};

const Code = styled.code`
  display: block;
  background: #e6e6e6;
  padding: 1rem;
  border: 1px solid #545871;
`;

const CsvExampleTitle = styled.div`
  font-weight: bold;
  margin-top: 1em;
`;

export default ImportFromCsvWindow;
