import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import LoginForm from './LoginForm';
import { LoginFormValues } from '../../../../store/Auth/types';
import { authOperations } from '../../../../store/Auth';
import { createNetworkErrorObject, useTypedSelector } from '../../../../utils';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import * as Yup from 'yup';
import { AuthPageForm, AuthPageTitle } from '../AuthStyledComponents';
import { APP_THEME } from '../../../../typings/environment';
import { isTheme } from '../../../../utils/environment';
import { getUserMainPage } from '../../../../utils/redirection';

type LoginPageProps = {};

const LoginPage: FunctionComponent<LoginPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<NetworkError | null>(null);
  const authenticated = useTypedSelector((state) => state.auth.authenticated);
  const user = useTypedSelector((state) => state.auth.user);

  if (authenticated) {
    dispatch(push(getUserMainPage(user)));
  }

  const onLoginSubmit = async (values: LoginFormValues) => {
    try {
      setError(null);
      await dispatch(authOperations.login(values));
    } catch (e) {
      const error = createNetworkErrorObject(e);
      setError(error);
    }
  };
  return (
    <>
      {isTheme(APP_THEME.LPI) && <AuthPageTitle>{t('pages.auth.login.title')}</AuthPageTitle>}
      <AuthPageForm>
        <Formik
          initialValues={{ email: '', password: '', rememberLogin: true }}
          onSubmit={onLoginSubmit}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(t('formValidationErrors.email'))
              .required(t('formValidationErrors.required', { fieldName: 'Email' })),
            password: Yup.string().required(t('formValidationErrors.required', { fieldName: 'Password' }))
          })}
        >
          {(props) => <LoginForm formikProps={props} error={error} />}
        </Formik>
      </AuthPageForm>
    </>
  );
};

export default LoginPage;
