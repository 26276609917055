import React from 'react';

export const BarChart = ({ data, xValue, xScale, yScale, yBarValue, color }) => {
  return (
    <>
      {data.map((d, i) => (
        <rect
          key={i}
          fill={color}
          x={xScale(xValue(d)) - xScale.bandwidth() / 2}
          y={yScale(yBarValue(d))}
          height={yScale(0) - yScale(yBarValue(d))}
          width={xScale.bandwidth()}
        >
          <title>{yBarValue(d)}</title>
        </rect>
      ))}
    </>
  );
};
