import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';
import EmailTemplateEditorPage from './Editor/EmailTemplateEditorPage';

const AdminEmailTemplatesEditorRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMINISTRATION.EMAIL_TEMPLATES.EDIT(':templateId'),
    exact: false,
    name: 'emailTemplateEdit',
    public: false,
    show: true,
    component: EmailTemplateEditorPage,
    scope: []
  }
];

export default AdminEmailTemplatesEditorRoutes;
