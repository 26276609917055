import AdminPasswordPoliciesPage from './AdminPasswordPoliciesPage';
import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';

const AdminPasswordPoliciesRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMINISTRATION.PASSWORD_POLICIES.INDEX,
    exact: true,
    name: 'passwordPolicies',
    public: false,
    show: true,
    component: AdminPasswordPoliciesPage,
    scope: []
  }
];

export default AdminPasswordPoliciesRoutes;
