import React from 'react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from '../../../types';
import styled from 'styled-components';
import { DesignProject } from '../../../../../../../store/DesignProjects/types';

interface DesignAssetProps {
  image: DesignProject;
}

export const DesignAsset: React.FC<DesignAssetProps> = ({ image }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.DESIGN_ASSET, id: image.id || '' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0.4 : 1;

  return (
    <ImageItemContainer ref={drag} style={{ opacity }}>
      <ImageItem src={image.thumbnailUrl} title={image.name} />
      <ImageItemName title={image.name}>{image.name}</ImageItemName>
    </ImageItemContainer>
  );
};

const ImageItemContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #f3f3f3;
  font-family: Rajdhani, sans-serif;
  width: 100px;
  cursor: pointer;
  text-align: center;
`;

const ImageItemName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px 3px;
  font-size: 12px;
`;

const ImageItem = styled.div<{ src: string }>`
  width: 100px;
  height: 80px;
  background-image: url('${({ src }) => src}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
`;
