import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import EmailDndEditorPage from './EmailDndEditor/EmailDndEditorPage';
import EmailPreviewPage from './EmailDndEditor/EmailPreviewPage';
import BulletinEditorPage from './Bulletin/BulletinEditorPage';
import DesignProjectEditorPage from './DesignProject/DesignProjectEditorPage';

const EditorRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.PROJECTS.BULLETIN.EDIT(':projectId'),
    exact: false,
    name: 'bulletinEditor',
    public: false,
    show: true,
    component: BulletinEditorPage,
    scope: []
  },

  {
    path: linksConstants.PROJECTS.EMAIL.EDIT(':projectId'),
    exact: true,
    name: 'email_editor',
    public: false,
    show: true,
    component: EmailDndEditorPage,
    scope: []
  },
  {
    path: linksConstants.PROJECTS.EMAIL.PREVIEW(':previewUrl', ':contentWidth'),
    exact: true,
    name: 'email_preview',
    public: false,
    show: true,
    component: EmailPreviewPage,
    scope: []
  },

  {
    path: linksConstants.PROJECTS.DESIGN.EDIT(':projectId'),
    exact: false,
    name: 'designEditor',
    public: false,
    show: true,
    component: DesignProjectEditorPage,
    scope: []
  }
];

export default EditorRoutes;
