import React, { FunctionComponent, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { FilterHeader } from '../../AdminStyledComponents';
import { AdminProjectsFilters } from '../../../../../store/AdminProjects/types';
import { useTranslation } from 'react-i18next';
import { systemSettingsOperations } from '../../../../../store/SystemSettings';
import { PrintCenter } from '../../../../../store/SystemSettings/types';

type DataCenterFiltersProps = {
  filters: AdminProjectsFilters['printCenter'];
  onChange: (dataCenterId: string) => void;
};

const DataCenterFilters: FunctionComponent<DataCenterFiltersProps> = ({ filters, onChange }) => {
  const { t } = useTranslation();
  const [printCenters, setPrintCenters] = useState<PrintCenter[]>([]);

  useEffect(() => {
    const fetchPrintCenters = async () => {
      const res = await systemSettingsOperations.getPrintCenters();
      setPrintCenters(res.data);
    };

    fetchPrintCenters();
  }, []);

  return (
    <>
      <FilterHeader>{t('pages.commonInputs.inputs.printCenter')}</FilterHeader>
      <FormGroup>
        {printCenters.map((printCenter) => (
          <FormControlLabel
            key={printCenter.id}
            control={<Checkbox checked={filters.includes(printCenter.id)} onChange={() => onChange(printCenter.id)} />}
            label={printCenter.name}
          />
        ))}
      </FormGroup>
    </>
  );
};

export default DataCenterFilters;
