import React from 'react';
import { Route } from 'react-router';

interface AuthLayoutRouteProps {
  exact: boolean;
  path: string;
  component: any;
  name: string;
}

const AuthLayoutRoute = ({ component: Component, ...props }: AuthLayoutRouteProps) => {
  return (
    <Route {...props}>
      <Component />
    </Route>
  );
};

export default AuthLayoutRoute;
