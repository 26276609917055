import React, { FunctionComponent, useState } from 'react';
import linksConstants from '../../../../config/app/linksConstants';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import ForgotPasswordForm from './ForgotPasswordForm';
import { createNetworkErrorObject, httpRequest } from '../../../../utils';
import { ResetPasswordInstructionsFormValues } from '../../../../store/Auth/types';
import { authRequests } from '../../../../store/Auth';
import * as Yup from 'yup';
import InfoBox from '../../../Shared/InfoBox/InfoBox';
import posed, { PoseGroup } from 'react-pose';
import { AdditionalLinkContainer, AuthPageForm, AuthPageTitle } from '../AuthStyledComponents';

type ForgotPasswordPageProps = {};

const AnimatedDiv = posed.div({
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
});

const ForgotPasswordPage: FunctionComponent<ForgotPasswordPageProps> = () => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState<NetworkError | null>(null);
  const [completed, setCompleted] = useState(false);
  const onResetPasswordSubmit = async (
    values: ResetPasswordInstructionsFormValues,
    formikHelpers: FormikHelpers<ResetPasswordInstructionsFormValues>
  ) => {
    try {
      setError(null);
      setCompleted(false);
      await httpRequest.runRequest<void>(
        authRequests.sendResetPasswordInstructions({
          requestPayload: values
        })
      );
    } catch (e) {
      const error = createNetworkErrorObject(e);
      setError(error);
    } finally {
      setCompleted(true);
    }
  };

  return (
    <>
      <AuthPageTitle>{t('pages.auth.forgotPassword.title')}</AuthPageTitle>

      <AuthPageForm>
        <Formik
          initialValues={{ email: '' }}
          onSubmit={onResetPasswordSubmit}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(t('formValidationErrors.email'))
              .required(t('formValidationErrors.required', { fieldName: 'Email' }))
          })}
        >
          {(props) => {
            return (
              <PoseGroup>
                {completed && !error ? (
                  <AnimatedDiv key={'success'} style={{ maxWidth: '30rem', marginBottom: 24 }}>
                    <InfoBox type={'success'} fullWidth>
                      {t('pages.auth.forgotPassword.success', { email: props.values.email })}
                    </InfoBox>
                  </AnimatedDiv>
                ) : (
                  <AnimatedDiv key={'form'}>
                    <ForgotPasswordForm formikProps={props} error={error} />
                  </AnimatedDiv>
                )}
              </PoseGroup>
            );
          }}
        </Formik>
        <AdditionalLinkContainer>
          <Link to={linksConstants.AUTH.LOGIN}>{t('pages.auth.backToLogin')}</Link>
        </AdditionalLinkContainer>
      </AuthPageForm>
    </>
  );
};

export default ForgotPasswordPage;
