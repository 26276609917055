import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';

export interface AddColumnProps {
  accept: string[];
  onDrop: (item: any, column: HTMLDivElement | null) => void;
  className?: string;
  children?: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
}

export const AddColumnDropzone: React.FC<AddColumnProps> = ({ accept, onDrop, children, style, className }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [{ canDrop, isOver }, drop] = useDrop({
    accept,
    drop: (item) => onDrop(item, wrapperRef.current),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = canDrop && isOver;
  let backgroundColor = 'transparent';
  let border = 'none';
  if (isActive) {
    backgroundColor = '#edf2fe';
    border = '1px dashed #9391c4';
  } else if (canDrop) {
    backgroundColor = '#f8fbfc';
    border = '1px dashed #9391c4';
  }

  return (
    <div
      ref={(node) => {
        wrapperRef.current = node;
        drop(node);
      }}
      style={{ backgroundColor, border, ...style }}
      className={className}
    >
      {/* {isActive ? 'Release to drop' : 'Drag a box here'} */}
      {children}
    </div>
  );
};
