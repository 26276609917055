import React, { FunctionComponent, useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import { validateUrl } from '../../../../../../../utils';
import { ArticleReadMoreType } from '../../../types';
import { WordpressPostResponse } from '../../../../../../../store/Stories/types';
import { storiesOperations } from '../../../../../../../store/Stories';
import Toast from '../../../../../../Shared/Toast/Toast';
import Loader from '../../../../../../Shared/Loading/Loader';

type ArticleHorizontalProps = {
  storyId?: string | null;

  sourceProjectId: string | null;
  flipbookReadMoreLink: string;

  showImage: boolean;
  setShowImage: (val: boolean) => void;

  showHeadline: boolean;
  setShowHeadline: (val: boolean) => void;

  showReadMore: boolean;
  setShowReadMore: (val: boolean) => void;

  customReadMoreText: string;
  setCustomReadMoreText: (val: string) => void;

  customReadMoreLink: string;
  setCustomReadMoreLink: (val: string) => void;

  imagePosition?: 'left' | 'right';
  setImagePosition?: React.Dispatch<React.SetStateAction<'left' | 'right'>>;

  readMoreType: ArticleReadMoreType;
  setReadMoreType: (type: ArticleReadMoreType) => void;
};

const ReadMoreConfigPopover: FunctionComponent<ArticleHorizontalProps> = ({
  showImage,
  storyId,
  sourceProjectId,
  setShowImage,
  showReadMore,
  setShowReadMore,
  customReadMoreLink,
  customReadMoreText,
  setCustomReadMoreLink,
  setCustomReadMoreText,
  imagePosition,
  setImagePosition,
  readMoreType,
  setReadMoreType,
  flipbookReadMoreLink,
  showHeadline,
  setShowHeadline
}) => {
  const { t } = useTranslation();
  const [storyLoading, setStoryLoading] = useState<boolean>(false);
  const [wordpressPosts, setWordpressPosts] = useState<WordpressPostResponse[]>([]);

  const getStoryWordpressPosts = async () => {
    if (storyId) {
      setStoryLoading(true);
      try {
        setWordpressPosts((await storiesOperations.getStoryWordpressPosts(storyId)).posts);
      } catch (e) {
        Toast.error(t('notifications.story.cannotFetchWordpressPosts'));
      } finally {
        setStoryLoading(false);
      }
    }
  };

  useEffect(() => {
    if (readMoreType === 'wordpress') {
      getStoryWordpressPosts();
    }
  }, [readMoreType]);

  return (
    <div className="toggle-show-read-more">
      <div onClick={() => setShowImage(!showImage)}>
        {t('common.showImage')}: <Switch checked={showImage} onChange={(e) => setShowImage(e.target.checked)} />
      </div>
      <div onClick={() => setShowHeadline(!showHeadline)}>
        {t('common.showHeadline')}:{' '}
        <Switch checked={showHeadline} onChange={(e) => setShowHeadline(e.target.checked)} />
      </div>
      {showImage && imagePosition && setImagePosition && (
        <Box display="flex" alignItems="center">
          {t('common.imagePosition')}:{' '}
          <RadioGroup
            aria-label="image-position"
            name="image-position"
            value={imagePosition}
            onChange={(e) => setImagePosition(e.target.value as 'left' | 'right')}
            row
            style={{ marginLeft: 5 }}
          >
            <FormControlLabel value="left" control={<Radio size="small" />} label={t('common.left')} />
            <FormControlLabel value="right" control={<Radio size="small" />} label={t('common.right')} />
          </RadioGroup>
        </Box>
      )}
      <div onClick={() => setShowReadMore(!showReadMore)}>
        {t('common.showReadMoreLink')}:{' '}
        <Switch checked={showReadMore} onChange={(e) => setShowReadMore(e.target.checked)} />
      </div>
      {showReadMore && (
        <div>
          <div>
            {t('common.articleReadMoreLinks.whereToPoint')}:{' '}
            <ButtonGroup size="small" aria-label="small outlined button group">
              {sourceProjectId && (
                <ReadMoreTypeButton type="default" selectedType={readMoreType} onClick={setReadMoreType} />
              )}
              {storyId && <ReadMoreTypeButton type="wordpress" selectedType={readMoreType} onClick={setReadMoreType} />}
              <ReadMoreTypeButton type="custom" selectedType={readMoreType} onClick={setReadMoreType} />
            </ButtonGroup>
          </div>

          <CustomReadMoreWrapper>
            <div>
              <ReadMoreTitleInput
                customReadMoreText={customReadMoreText}
                setCustomReadMoreText={setCustomReadMoreText}
              />
            </div>

            {readMoreType === 'default' && (
              <a href={flipbookReadMoreLink} rel="noopener noreferrer" target="_blank">
                {flipbookReadMoreLink}
              </a>
            )}
            {readMoreType === 'custom' && (
              <div>
                <TextField
                  variant="outlined"
                  label={t('common.newUrl')}
                  size="small"
                  fullWidth
                  placeholder="https://example.com"
                  value={customReadMoreLink}
                  onChange={(e) => setCustomReadMoreLink(e.target.value)}
                  required
                  error={!customReadMoreLink || !validateUrl(customReadMoreLink)}
                />
              </div>
            )}
            {readMoreType === 'wordpress' && (
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="wordpress-post"
                  name="wordpress-post"
                  value={customReadMoreLink}
                  onChange={(e) => setCustomReadMoreLink(e.target.value)}
                  row
                  style={{ marginLeft: 5 }}
                >
                  <>
                    {storyLoading && <Loader />}
                    {!storyLoading && wordpressPosts.length === 0 && t('common.articleReadMoreLinks.noWordpressPosts')}
                    {!storyLoading &&
                      wordpressPosts.map((post) => (
                        <>
                          <FormControlLabel
                            key={post.id}
                            value={post.url}
                            control={<Radio size="small" />}
                            label={post.name}
                          />
                          <Button onClick={() => window.open(post.url)} variant="outlined" size="small">
                            <OpenInNewIcon /> {t('common.open')}
                          </Button>
                        </>
                      ))}
                  </>
                </RadioGroup>
              </FormControl>
            )}
          </CustomReadMoreWrapper>
        </div>
      )}
    </div>
  );
};

type ReadMoreTypeButtonProps = {
  type: ArticleReadMoreType;
  selectedType: ArticleReadMoreType;
  onClick: (type: ArticleReadMoreType) => void;
};

const ReadMoreTypeButton: FunctionComponent<ReadMoreTypeButtonProps> = ({ type, selectedType, onClick }) => {
  const { t } = useTranslation();

  const isSelected = !selectedType || selectedType === type;

  return (
    <Button
      variant={isSelected ? 'outlined' : 'text'}
      color={isSelected ? 'secondary' : 'primary'}
      onClick={() => onClick(type)}
    >
      {t(`common.articleReadMoreLinks.types.${type}`)}
    </Button>
  );
};

const ReadMoreTitleInput: FunctionComponent<{
  customReadMoreText: string;
  setCustomReadMoreText: (val: string) => void;
}> = ({ customReadMoreText, setCustomReadMoreText }) => {
  const { t } = useTranslation();

  return (
    <TextField
      variant="outlined"
      size="small"
      label={t('common.title')}
      inputProps={{
        maxLength: 60
      }}
      fullWidth
      placeholder={t('common.title')}
      value={customReadMoreText}
      onChange={(e) => setCustomReadMoreText(e.target.value)}
      required
      error={!customReadMoreText}
    />
  );
};

const CustomReadMoreWrapper = styled.div`
  padding: 0 10px;

  > div {
    margin-bottom: 10px;
  }
`;

export default ReadMoreConfigPopover;
