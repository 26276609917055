import update from 'immutability-helper';
import {
  MyOrganizationUser,
  MyOrganizationActionsConstants,
  MyOrganizationActionType,
  MyOrganizationState
} from './types';
import { normalizeMyOrganizationUsers } from '../helpers';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: MyOrganizationState = {
  users: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const adminOrganizationUsersPaginationReducer = createPaginationReducer<MyOrganizationState, MyOrganizationUser>(
  PaginationStoreModule.MY_ORGANIZATION_USERS
);

const adminOrganizationUsersTableSearchReducer = createTableSearchReducer<MyOrganizationUser, MyOrganizationState>(
  TableSearchStoreModule.MY_ORGANIZATION_USERS
);

export default function myOrganizationReducer(
  state = initialState,
  action: WithLogoutAction<MyOrganizationActionType>
) {
  switch (action.type) {
    case MyOrganizationActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case MyOrganizationActionsConstants.INDEX_SUCCESS: {
      const data = normalizeMyOrganizationUsers(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        users: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case MyOrganizationActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        users: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return adminOrganizationUsersTableSearchReducer(
        adminOrganizationUsersPaginationReducer(state, action),
        action
      ) as MyOrganizationState;
    }
  }
}
