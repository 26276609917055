export type SystemSetting = {
  id: string;
  type: SystemSettingType;
  createdAt: Date;
  updatedAt: Date;
  value: string;
};

export type SystemSettingsNormalized = {
  [key in SystemSettingType]: string;
};

export type SystemSettingsResponse = {
  data: SystemSetting[];
};

export enum DamSystemName {
  ARK = 'ark',
  BF = 'bf'
}

export const damSystemName = [DamSystemName.ARK, DamSystemName.BF];

export type DamSystem = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: DamSystemName;
  url: string;
};

export type DamSystemForm = {
  name: DamSystemName;
  url: string;

  /** BrandFolder */
  apiKey?: string;

  /** Ark */
  s3AccessKey?: string;
  s3SecretKey?: string;
  s3Region?: string;
  s3BucketName?: string;
  s3BasePath?: string;
};

export type PrintCenter = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  url: string;
};

export type PrintCenterForm = {
  name: string;
  url: string;
  secret?: string;
  apiKey?: string;
};

export enum SystemSettingType {
  PUBLISH_HOUR = 'PUBLISH_HOUR',
  COLOR_SWATCHES_LIMIT = 'COLOR_SWATCHES_LIMIT',
  ARCHIVE_TIME_BULLETIN = 'ARCHIVE_TIME_BULLETIN',
  ARCHIVE_TIME_EMAIL = 'ARCHIVE_TIME_EMAIL',
  ARCHIVE_TIME_FLYER = 'ARCHIVE_TIME_FLYER',
  ARCHIVE_TIME_NEWSLETTER = 'ARCHIVE_TIME_NEWSLETTER',
  ARCHIVE_TIME_DIRECTORY = 'ARCHIVE_TIME_DIRECTORY',
  PROJECT_LEAVE_INACTIVITY_TIME = 'PROJECT_LEAVE_INACTIVITY_TIME',
  LOGOUT_TIME_INACTIVITY = 'LOGOUT_TIME_INACTIVITY',
  AUTOMATED_EMAIL_TIME = 'AUTOMATED_EMAIL_TIME',
  AUTOMATED_EMAIL_DATE_SCHEDULE = 'AUTOMATED_EMAIL_DATE_SCHEDULE',
  AUTOMATED_EMAIL_SUBJECT = 'AUTOMATED_EMAIL_SUBJECT',
  SENDER_EMAIL = 'SENDER_EMAIL',
  API_USER_DO_LAKE_HURON_APP = 'API_USER_DO_LAKE_HURON_APP'
}

export enum AUTOMATED_EMAIL_DATE_SCHEDULE {
  MINUS_3_DAYS = 'MINUS_3_DAYS',
  MINUS_2_DAYS = 'MINUS_2_DAYS',
  MINUS_1_DAY = 'MINUS_1_DAY',
  DISTRIBUTION_DATE = 'DISTRIBUTION_DATE',
  IMMEDIATELY = 'IMMEDIATELY',
  PLUS_1_DAY = 'PLUS_1_DAY',
  PLUS_2_DAYS = 'PLUS_2_DAYS',
  PLUS_3_DAYS = 'PLUS_3_DAYS'
}

export const automatedEmailDateSchedule = [
  AUTOMATED_EMAIL_DATE_SCHEDULE.IMMEDIATELY,
  AUTOMATED_EMAIL_DATE_SCHEDULE.MINUS_3_DAYS,
  AUTOMATED_EMAIL_DATE_SCHEDULE.MINUS_2_DAYS,
  AUTOMATED_EMAIL_DATE_SCHEDULE.MINUS_1_DAY,
  AUTOMATED_EMAIL_DATE_SCHEDULE.DISTRIBUTION_DATE,
  AUTOMATED_EMAIL_DATE_SCHEDULE.PLUS_1_DAY,
  AUTOMATED_EMAIL_DATE_SCHEDULE.PLUS_2_DAYS,
  AUTOMATED_EMAIL_DATE_SCHEDULE.PLUS_3_DAYS
];

export type PasswordPolicy = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  isGlobal: boolean;
  passwordLength: number;
  upper: boolean;
  lower: boolean;
  numbers: boolean;
  specialCharacters: boolean;
  forceRotation: boolean;
  rotationDays: number;
  forceUnique: boolean;
  historyLength: number;
  organizationId: string | null;
  inherited: boolean;
};

export type PasswordPolicySettings = Pick<
  PasswordPolicy,
  | 'passwordLength'
  | 'upper'
  | 'lower'
  | 'numbers'
  | 'specialCharacters'
  | 'forceRotation'
  | 'rotationDays'
  | 'forceUnique'
  | 'historyLength'
>;
