import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, FormControl, Select, MenuItem, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Filter } from '../../../../store/AdminAds/types';

type AdsStatisticsDateRangeFilterProps = {
  startDate: Date | null;
  endDate: Date | null;
  handleStartDateChange: (date: Date | null) => void;
  handleEndDateChange: (date: Date | null) => void;
  filter: Filter | null;
  handleFilterChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  filters: Filter[];
};

const useStyles = makeStyles({
  root: {
    marginLeft: '10px',
    marginRight: '20px',
    '& .MuiOutlinedInput-inputAdornedEnd': {
      paddingTop: '10.5px',
      paddingBottom: '10.5px'
    }
  }
});

const AdsStatisticsDateRangeFilter: FunctionComponent<AdsStatisticsDateRangeFilterProps> = ({
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  filter,
  handleFilterChange,
  filters
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper style={{ padding: '15px', display: 'flex', alignItems: 'center' }}>
      {t('pages.adsStatistics.filter')}:
      <FormControl variant="outlined" size="small" style={{ marginLeft: '10px', marginRight: '20px' }}>
        <Select id="filter-select" value={filter?.value || 0} onChange={handleFilterChange}>
          {filters.map((f) => (
            <MenuItem value={f.value} key={f.value}>
              {f.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {filter?.value === 7 && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {t('pages.adsStatistics.dateFrom')}:
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            margin="none"
            value={startDate}
            onChange={handleStartDateChange}
            maxDate={endDate || new Date()}
            className={classes.root}
          />
          {t('pages.adsStatistics.dateTo')}:
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            margin="none"
            value={endDate}
            onChange={handleEndDateChange}
            minDate={startDate}
            maxDate={new Date()}
            className={classes.root}
          />
        </MuiPickersUtilsProvider>
      )}
    </Paper>
  );
};

export default AdsStatisticsDateRangeFilter;
