import update from 'immutability-helper';
import {
  AdminPasswordPolicy,
  AdminPasswordPoliciesActionsConstants,
  AdminPasswordPoliciesActionType,
  AdminPasswordPoliciesState
} from './types';
import { normalizePasswordPolicies } from '../helpers';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: AdminPasswordPoliciesState = {
  passwordPolicies: {
    entities: {},
    result: []
  },
  allPasswordPolicies: [],
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const adminPasswordPoliciesPaginationReducer = createPaginationReducer<AdminPasswordPoliciesState, AdminPasswordPolicy>(
  PaginationStoreModule.ADMIN_PASSWORD_POLICIES
);

const adminPasswordPoliciesTableSearchReducer = createTableSearchReducer<
  AdminPasswordPolicy,
  AdminPasswordPoliciesState
>(TableSearchStoreModule.ADMIN_PASSWORD_POLICIES);

export default function adminPasswordPoliciesReducer(
  state = initialState,
  action: WithLogoutAction<AdminPasswordPoliciesActionType>
) {
  switch (action.type) {
    case AdminPasswordPoliciesActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case AdminPasswordPoliciesActionsConstants.INDEX_SUCCESS: {
      const data = normalizePasswordPolicies(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        passwordPolicies: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case AdminPasswordPoliciesActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        passwordPolicies: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return adminPasswordPoliciesTableSearchReducer(
        adminPasswordPoliciesPaginationReducer(state, action),
        action
      ) as AdminPasswordPoliciesState;
    }
  }
}
