import React, { FunctionComponent, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Button, withTheme } from '@material-ui/core';
import { useTypedSelector } from '../../../utils';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { appActions } from '../../../store/App';

const VideoPreview: FunctionComponent<{}> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const videoPreviewWindow = useTypedSelector((state) => state.app.videoPreviewWindow);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        dispatch(appActions.closeVideoPreviewModal());
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  if (!videoPreviewWindow.open) return null;

  return (
    <LoadingModalWrapper>
      <video width="80%" height="80%" controls autoPlay>
        <source src={videoPreviewWindow.src} />
      </video>
      <CloseButton
        color={'secondary'}
        variant="contained"
        size="small"
        onClick={() => dispatch(appActions.closeVideoPreviewModal())}
      >
        {t('common.close')}
      </CloseButton>
    </LoadingModalWrapper>
  );
};

const fadeIn = () => keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 40px;
`;

const LoadingModalWrapper = withTheme(styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  animation: ${fadeIn()} 0.2s ease-in;
  animation-fill-mode: forwards;
  z-index: 90000;
`);

export default VideoPreview;
