import {
  DesignProject,
  DesignProjectsActionsConstants,
  DesignProjectsIndexActionBEGIN,
  DesignProjectsIndexActionFAILED,
  DesignProjectsIndexActionSUCCESS,
  DesignProjectsIndexResponse,
  PrintDesignThumbnailGenerated
} from './types';

export const designProjectsIndexBegin = (): DesignProjectsIndexActionBEGIN => ({
  type: DesignProjectsActionsConstants.INDEX_BEGIN
});

export const designProjectsIndexSuccess = (payload: DesignProjectsIndexResponse): DesignProjectsIndexActionSUCCESS => ({
  type: DesignProjectsActionsConstants.INDEX_SUCCESS,
  payload
});

export const designProjectsIndexFailed = (error: any): DesignProjectsIndexActionFAILED => ({
  type: DesignProjectsActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const thumbnailGenerated = (data: DesignProject): PrintDesignThumbnailGenerated => ({
  type: DesignProjectsActionsConstants.PRINT_DESIGN_THUMBNAIL_GENERATED,
  payload: data
});
