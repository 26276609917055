import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import AddIcon from '@material-ui/icons/Add';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import EditIcon from '@material-ui/icons/Edit';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import { formatTableTimestamp, useTypedSelector } from '../../../../utils';
import { usePaginationWatch } from '../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../Shared/DataTable/useTableSearchWatch';
import { PaginationStoreModule } from '../../../../store/Pagination/types';
import DataTable from '../../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../../Shared/DataTable/TableActionButton/TableActionButton';
import { TableSearchStoreModule } from '../../../../store/TableSearch/types';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import AdminCreateTemplateWindow from './Windows/AdminCreateEmailTemplateWindow';
import AdminUpdateTemplateWindow from './Windows/AdminUpdateEmailTemplateWindow';
import AdminDeleteTemplateWindow from './Windows/AdminDeleteEmailTemplateWindow';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { adminEmailTemplatesOperations } from '../../../../store/AdminEmailTemplates';
import { AdminEmailTemplate } from '../../../../store/AdminEmailTemplates/types';
import CloudUpload from '@material-ui/icons/CloudUpload';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Toast from '../../../Shared/Toast/Toast';
import { useDispatch } from 'react-redux';
import AdminKeywordsList from '../../../Shared/Tags/AdminKeywordsList';
import { AdminKeyword, AdminKeywordGroup } from '../../../../store/AdminKeywords/types';
import { adminKeywordsOperations } from '../../../../store/AdminKeywords';
import { hasPermission } from '../../../../utils/permissions';
import TemplateThumbnail from '../../../Shared/Thumbnail/TemplateThumbnail';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AdminCopyEmailTemplateWindow from './Windows/AdminCopyEmailTemplateWindow';

type AdminEmailTemplatesPageProps = {};

const AdminEmailTemplatesPage: FunctionComponent<AdminEmailTemplatesPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const themeUploaderRef = useRef<HTMLInputElement | null>(null);
  const thumbnailUploaderRef = useRef<HTMLInputElement | null>(null);
  const role = useTypedSelector((state) => state.auth.role);
  const templates = useTypedSelector((state) => state.adminEmailTemplates.templates);
  const isFailed = useTypedSelector((state) => state.adminEmailTemplates.index.isFailed);
  const isLoading = useTypedSelector((state) => state.adminEmailTemplates.index.isLoading);
  const pagination = useTypedSelector((state) => state.adminEmailTemplates.pagination);
  const tableSearch = useTypedSelector((state) => state.adminEmailTemplates.tableSearch);
  const allKeywords = useTypedSelector((state) => state.adminKeywords.all);

  useEffect(() => {
    const fetchKeywords = async () => {
      await dispatch(adminKeywordsOperations.getAllKeywords());
    };
    fetchKeywords();
  }, []);

  usePaginationWatch(pagination, [adminEmailTemplatesOperations.index]);
  useTableSearchWatch<AdminEmailTemplate>(tableSearch, [adminEmailTemplatesOperations.index]);

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [activeTemplate, setActiveTemplate] = useState<AdminEmailTemplate | null>(null);
  const [createTemplateWindowOpen, onCreateTemplateWindowOpen, onCreateTemplateWindowClose] = useOpenHandler();
  const [updateTemplateWindowOpen, onUpdateTemplateWindowOpen, onUpdateTemplateWindowClose] = useOpenHandler();
  const [deleteTemplateWindowOpen, onDeleteTemplateWindowOpen, onDeleteTemplateWindowClose] = useOpenHandler();
  const [copyTemplateWindowOpen, onCopyTemplateWindowOpen, onCopyTemplateWindowClose] = useOpenHandler();

  const getActionButtons = () => {
    if (hasPermission(role, ['templatesAdd'])) {
      return [
        {
          label: t('pages.adminEmailTemplates.createTemplate'),
          icon: <AddIcon />,
          onClick: onCreateTemplateWindowOpen
        }
      ];
    }

    return [];
  };

  const downloadTemplatePackage = async (template: AdminEmailTemplate) => {
    await adminEmailTemplatesOperations.downloadEmailTemplateZip(template.id);
  };

  const uploadThemePackage = async (e: ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (activeTemplate && e && e.target && e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      try {
        await adminEmailTemplatesOperations.uploadEmailTemplateZip(file, activeTemplate, (progress) =>
          setUploadProgress(progress)
        );
        Toast.success(t('notifications.adminEmailTemplates.uploadSuccessful'));

        await dispatch(adminEmailTemplatesOperations.index());

        if (themeUploaderRef.current) {
          themeUploaderRef.current.value = '';
        }
      } catch (err) {
        if (themeUploaderRef.current) {
          themeUploaderRef.current.value = '';
        }
        Toast.error(t('notifications.adminEmailTemplates.uploadError'));
      }
    }
  };

  const uploadThumbnail = async (e: ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (activeTemplate && e && e.target && e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      try {
        await adminEmailTemplatesOperations.uploadEmailTemplateThumbnail(file, activeTemplate, (progress) =>
          setUploadProgress(progress)
        );
        Toast.success(t('notifications.adminEmailTemplates.uploadThumbnailSuccessful'));

        await dispatch(adminEmailTemplatesOperations.index());

        if (thumbnailUploaderRef.current) {
          thumbnailUploaderRef.current.value = '';
        }
      } catch (err) {
        if (thumbnailUploaderRef.current) {
          thumbnailUploaderRef.current.value = '';
        }
        Toast.error(t('notifications.adminEmailTemplates.uploadThumbnailError'));
      }
    }
  };

  const onAddKeywordToTemplate = useCallback((template: AdminEmailTemplate, keyword: AdminKeyword) => {
    dispatch(adminEmailTemplatesOperations.addKeywordToEmailTemplate(template, keyword));
  }, []);

  const onAddKeywordGroupToTemplate = useCallback((template: AdminEmailTemplate, keyword: AdminKeywordGroup) => {
    dispatch(adminEmailTemplatesOperations.addKeywordGroupToEmailTemplate(template, keyword));
  }, []);

  const onDeleteKeywordFromTemplate = useCallback((template: AdminEmailTemplate, keyword: AdminKeyword) => {
    dispatch(adminEmailTemplatesOperations.removeKeywordFromEmailTemplate(template, keyword));
  }, []);

  const onDeleteKeywordGroupFromTemplate = useCallback((template: AdminEmailTemplate, keyword: AdminKeywordGroup) => {
    dispatch(adminEmailTemplatesOperations.removeKeywordGroupFromEmailTemplate(template, keyword));
  }, []);

  return (
    <PageContainer>
      <PageHeader title={t('pages.adminEmailTemplates.title')} rightActionButtons={getActionButtons()} />
      <PageContent>
        <DataTable<AdminEmailTemplate, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'thumbnailUrl',
              render: (template) => (
                <TemplateThumbnail
                  key={`${template.id}-${template.thumbnailUrl}`}
                  thumbnailUrl={template.thumbnailUrl}
                  width="60px"
                  height="80px"
                />
              )
            },
            {
              name: 'name',
              sortable: true,
              render: (template) => (
                <>
                  <div>{template.name}</div>
                  {activeTemplate && activeTemplate.id === template.id && uploadProgress > 0 && uploadProgress !== 100 && (
                    <div>
                      ({t('common.uploading')} {uploadProgress}%)
                    </div>
                  )}
                </>
              )
            },
            {
              name: 'actions',
              style: { maxWidth: 'none' },
              render: (template) => (
                <TableActionButtonsContainer>
                  <TableActionButton
                    icon={<EditIcon />}
                    tooltip={t('pages.adminEmailTemplates.table.buttons.edit')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['templatesAdd']),
                      onClick: () => {
                        setActiveTemplate(template);
                        onUpdateTemplateWindowOpen();
                      }
                    }}
                  />
                  <TableActionButton
                    icon={<FileCopyIcon />}
                    tooltip={t('pages.adminEmailTemplates.table.buttons.copy')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['templatesAdd']),
                      onClick: () => {
                        setActiveTemplate(template);
                        onCopyTemplateWindowOpen();
                      }
                    }}
                  />
                  <TableActionButton
                    icon={<LineStyleIcon />}
                    tooltip={t('pages.adminEmailTemplates.table.buttons.editTemplateModel')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['templatesUpload']) || !template.uploaded,
                      onClick: () => {
                        dispatch(push(linksConstants.ADMINISTRATION.EMAIL_TEMPLATES.EDIT(template.id)));
                      }
                    }}
                  />

                  <TableActionButton
                    icon={<QueuePlayNextIcon />}
                    tooltip={t('pages.adminEmailTemplates.table.buttons.assignAds')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['adsView']),
                      onClick: () => {
                        dispatch(push(linksConstants.ADMINISTRATION.ADS.ASSIGN_ADS(template.id)));
                      }
                    }}
                  />
                  <TableActionButton
                    icon={<DeleteIcon />}
                    tooltip={t('pages.adminEmailTemplates.table.buttons.delete')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['templatesRemove']),
                      onClick: () => {
                        setActiveTemplate(template);
                        onDeleteTemplateWindowOpen();
                      }
                    }}
                  />
                  <>
                    <TableActionButton
                      icon={<CloudUpload />}
                      tooltip={t('pages.adminEmailTemplates.table.buttons.upload')}
                      ButtonProps={{
                        disabled: !hasPermission(role, ['templatesUpload']),
                        onClick: () => {
                          setActiveTemplate(template);
                          themeUploaderRef.current && themeUploaderRef.current.click();
                        }
                      }}
                    />
                    <input
                      type="file"
                      id="themeUploader"
                      accept=".zip,.html"
                      ref={themeUploaderRef}
                      style={{ display: 'none' }}
                      onChange={uploadThemePackage}
                    />
                  </>
                  {template.uploaded && (
                    <TableActionButton
                      icon={<CloudDownload />}
                      tooltip={t('pages.adminEmailTemplates.table.buttons.download')}
                      ButtonProps={{
                        disabled: !hasPermission(role, ['templatesDownload']),
                        onClick: () => downloadTemplatePackage(template)
                      }}
                    />
                  )}
                  <>
                    <TableActionButton
                      icon={<WallpaperIcon />}
                      tooltip={t('pages.adminEmailTemplates.table.buttons.uploadThumbnail')}
                      ButtonProps={{
                        disabled: !hasPermission(role, ['templatesAdd']),
                        onClick: () => {
                          setActiveTemplate(template);
                          thumbnailUploaderRef.current && thumbnailUploaderRef.current.click();
                        }
                      }}
                    />
                    <input
                      type="file"
                      id="thumbnailUploader"
                      accept=".jpg,.jpeg,.png,.gif"
                      ref={thumbnailUploaderRef}
                      style={{ display: 'none' }}
                      onChange={uploadThumbnail}
                    />
                  </>
                  <TableActionButton
                    icon={<PlaylistAddCheckIcon />}
                    tooltip={t('pages.adminEmailTemplates.table.buttons.setAutomatedDefaultTemplate')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['templatesAdd']) || template.automatedDefault,
                      onClick: async () => {
                        await adminEmailTemplatesOperations.setAutomatedDefaultTemplate(template.id);
                        await dispatch(adminEmailTemplatesOperations.index());
                      }
                    }}
                  />
                </TableActionButtonsContainer>
              )
            },
            {
              name: 'keywords',
              render: (template) => (
                <AdminKeywordsList
                  readOnly={!hasPermission(role, ['keywordsAssociate'])}
                  allKeywords={allKeywords}
                  keywords={template.keywords}
                  groups={template.keywordGroups}
                  onDelete={
                    hasPermission(role, ['keywordsAssociate'])
                      ? (keyword: AdminKeyword) => onDeleteKeywordFromTemplate(template, keyword)
                      : null
                  }
                  onDeleteGroup={
                    hasPermission(role, ['keywordsAssociate'])
                      ? (keyword: AdminKeywordGroup) => onDeleteKeywordGroupFromTemplate(template, keyword)
                      : null
                  }
                  onAdd={(keyword: AdminKeyword) => onAddKeywordToTemplate(template, keyword)}
                  onAddGroup={(keyword: AdminKeywordGroup) => onAddKeywordGroupToTemplate(template, keyword)}
                />
              )
            },
            {
              name: 'description',
              sortable: true,
              render: (template) => <>{template.description}</>
            },
            {
              name: 'type',
              isTranslate: true,
              sortable: true
            },
            {
              name: 'updatedAt',
              render: (template) => <>{formatTableTimestamp(template.updatedAt)}</>,
              sortable: true
            },
            {
              name: 'uploadedAt',
              render: (template) => <>{template.uploadedAt ? formatTableTimestamp(template.uploadedAt) : 'N/A'}</>,
              sortable: true
            }
          ]}
          tPath={'pages.adminEmailTemplates.table'}
          data={templates}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.ADMIN_EMAIL_TEMPLATES
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.ADMIN_EMAIL_TEMPLATES
            // onPageChange: () => {},
            // onPerChange: () => {}
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>

      <AdminCreateTemplateWindow
        open={createTemplateWindowOpen}
        onCloseClick={onCreateTemplateWindowClose}
        fullScreenOnMobile
      />
      <AdminUpdateTemplateWindow
        template={activeTemplate}
        open={updateTemplateWindowOpen}
        onCloseClick={onUpdateTemplateWindowClose}
        fullScreenOnMobile
      />
      <AdminDeleteTemplateWindow
        template={activeTemplate}
        open={deleteTemplateWindowOpen}
        onCloseClick={onDeleteTemplateWindowClose}
        fullScreenOnMobile
      />
      {copyTemplateWindowOpen && activeTemplate && (
        <AdminCopyEmailTemplateWindow
          template={activeTemplate}
          open={copyTemplateWindowOpen}
          onCloseClick={onCopyTemplateWindowClose}
          fullScreenOnMobile
        />
      )}
    </PageContainer>
  );
};

export default AdminEmailTemplatesPage;
