import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../../../Shared/StyledComponents';
import { DesignerStory } from '../../../../../../store/PrintProjects/types';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

type SelectArticleImageWindowProps = {
  open: boolean;
  article: DesignerStory;
  onCloseClick: () => void;
  onSubmit: (index: number) => void;
};

const SelectArticleImageWindow: FunctionComponent<SelectArticleImageWindowProps> = ({
  onCloseClick,
  article,
  open,
  onSubmit
}) => {
  const { t } = useTranslation();
  const selectedIndex = article.images.findIndex((img) => img.selected);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(selectedIndex === -1 ? 0 : selectedIndex);

  useEffect(() => {
    const selectedIndex = article.images.findIndex((img) => img.selected);
    setSelectedImageIndex(selectedIndex === -1 ? 0 : selectedIndex);
  }, [article]);

  const onSave = () => {
    onSubmit(selectedImageIndex);
    onCloseClick();
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.projectPublish.selectImagesWindow.header')}
      okButtonText={t('common.save')}
      onCloseClick={onCloseClick}
      onSubmit={onSave}
    >
      <Row>
        {article.images.map((img, i) => (
          <ArticleImageContainer
            key={`${img.url}-${i}`}
            active={selectedImageIndex === i}
            onClick={() => setSelectedImageIndex(i)}
          >
            {selectedImageIndex === i && (
              <CheckboxContainer>
                <CheckBoxIcon color="secondary" />
              </CheckboxContainer>
            )}
            <ArticleImage src={img.url} alt="Article Image" />
          </ArticleImageContainer>
        ))}
      </Row>
    </ModalConfirmationWindow>
  );
};

const ArticleImageContainer = withTheme(styled.div<{ active: boolean }>`
  width: 120px;
  height: 120px;
  display: inline-flex;
  justify-content: center;
  margin: 1rem;
  outline: 5px solid #d7d7d7;
  position: relative;
  vertical-align: top;

  ${({ active, theme }) =>
    active &&
    `
    outline: 5px solid ${theme.palette.secondary.main}
  `};

  :hover {
    cursor: pointer;
  }
`);

const ArticleImage = styled.img`
  object-fit: contain;
  max-width: 120px;
`;

const CheckboxContainer = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
`;

export default SelectArticleImageWindow;
