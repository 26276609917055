import { BaseSettings, RequestSettings } from '../../../utils/httpRequest';
import {
  CreateCreditTransactionRequestDto,
  IndexDoAppCreditTransactionsMineRequestParams,
  IndexDoAppCreditTransactionsRequestParams
} from './types';

export const index = (settings: RequestSettings<IndexDoAppCreditTransactionsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/credit-transactions?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const indexMine = (
  settings: RequestSettings<IndexDoAppCreditTransactionsMineRequestParams, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/do-app/v1/credit-transactions/mine/?per=:per&page=:page&status=:status'
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{}, CreateCreditTransactionRequestDto>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/do-app/v1/credit-transactions'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const reject = (settings: RequestSettings<{ id: string }, { comment: string }>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/do-app/v1/credit-transactions/:id/reject'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const unReject = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/do-app/v1/credit-transactions/:id/un-reject'
  },
  params: settings.requestParams
});
