import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../../../Shared/StyledComponents';
import ColorPicker from '../../../../../Shared/ColorPicker/ColorPicker';
import { Grid, TextField } from '@material-ui/core';

type TextSettingsProps = {
  open: boolean;
  onCloseClick: () => void;
  onFormSubmit: (values: {
    backgroundColor: string;
    marginTop: string;
    marginRight: string;
    marginBottom: string;
    marginLeft: string;
    paddingTop: string;
    paddingRight: string;
    paddingBottom: string;
    paddingLeft: string;
    borderTopLeftRadius: string;
    borderTopRightRadius: string;
    borderBottomLeftRadius: string;
    borderBottomRightRadius: string;
  }) => void;
  backgroundColor: string;
  marginTop: string;
  marginRight: string;
  marginBottom: string;
  marginLeft: string;
  paddingTop: string;
  paddingRight: string;
  paddingBottom: string;
  paddingLeft: string;
  borderTopLeftRadius: string;
  borderTopRightRadius: string;
  borderBottomLeftRadius: string;
  borderBottomRightRadius: string;
};

const TextSettingsWindow: FunctionComponent<TextSettingsProps> = ({
  open,
  onCloseClick,
  onFormSubmit,
  backgroundColor,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomLeftRadius,
  borderBottomRightRadius
}) => {
  const { t } = useTranslation();
  const [textBackgroundColor, setTextBackgroundColor] = useState<string>(backgroundColor);
  const [textMarginTop, setTextMarginTop] = useState<string>(
    marginTop.includes('px') ? marginTop.slice(0, -2).trim() : '0'
  );
  const [textMarginRight, setTextMarginRight] = useState<string>(
    marginRight.includes('px') ? marginRight.slice(0, -2).trim() : '0'
  );
  const [textMarginBottom, setTextMarginBottom] = useState<string>(
    marginBottom.includes('px') ? marginBottom.slice(0, -2).trim() : '0'
  );
  const [textMarginLeft, setTextMarginLeft] = useState<string>(
    marginLeft.includes('px') ? marginLeft.slice(0, -2).trim() : '0'
  );
  const [textPaddingTop, setTextPaddingTop] = useState<string>(
    paddingTop.includes('px') ? paddingTop.slice(0, -2).trim() : '0'
  );
  const [textPaddingRight, setTextPaddingRight] = useState<string>(
    paddingRight.includes('px') ? paddingRight.slice(0, -2).trim() : '0'
  );
  const [textPaddingBottom, setTextPaddingBottom] = useState<string>(
    paddingBottom.includes('px') ? paddingBottom.slice(0, -2).trim() : '0'
  );
  const [textPaddingLeft, setTextPaddingLeft] = useState<string>(
    paddingLeft.includes('px') ? paddingLeft.slice(0, -2).trim() : '0'
  );
  const [textBorderTopLeftRadius, setTextBorderTopLeftRadius] = useState<string>(
    borderTopLeftRadius.includes('px') ? borderTopLeftRadius.slice(0, -2).trim() : '0'
  );
  const [textBorderTopRightRadius, setTextBorderTopRightRadius] = useState<string>(
    borderTopRightRadius.includes('px') ? borderTopRightRadius.slice(0, -2).trim() : '0'
  );
  const [textBorderBottomLeftRadius, setTextBorderBottomLeftRadius] = useState<string>(
    borderBottomLeftRadius.includes('px') ? borderBottomLeftRadius.slice(0, -2).trim() : '0'
  );
  const [textBorderBottomRightRadius, setTextBorderBottomRightRadius] = useState<string>(
    borderBottomRightRadius.includes('px') ? borderBottomRightRadius.slice(0, -2).trim() : '0'
  );

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.emailEditor.textSettings.header')}
      onCloseClick={onCloseClick}
      maxWidth="md"
      onSubmit={() =>
        onFormSubmit({
          backgroundColor: textBackgroundColor,
          marginTop: textMarginTop,
          marginRight: textMarginRight,
          marginBottom: textMarginBottom,
          marginLeft: textMarginLeft,
          paddingTop: textPaddingTop,
          paddingRight: textPaddingRight,
          paddingBottom: textPaddingBottom,
          paddingLeft: textPaddingLeft,
          borderTopLeftRadius: textBorderTopLeftRadius,
          borderTopRightRadius: textBorderTopRightRadius,
          borderBottomLeftRadius: textBorderBottomLeftRadius,
          borderBottomRightRadius: textBorderBottomRightRadius
        })
      }
    >
      <Row>
        <div>{t('pages.emailEditor.textSettings.backgroundColor')}:</div>
        <ColorPicker color={textBackgroundColor} onChange={setTextBackgroundColor} />
      </Row>
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.textSettings.margins')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div>{t('pages.emailEditor.textSettings.marginTop')}:</div>
            <TextField
              id="text-margin-top"
              type="number"
              value={textMarginTop}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = Math.abs(Number(event.target.value)) || '';
                setTextMarginTop((value as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setTextMarginTop('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <div>{t('pages.emailEditor.textSettings.marginRight')}:</div>
            <TextField
              id="text-margin-right"
              type="number"
              value={textMarginRight}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = Math.abs(Number(event.target.value)) || '';
                setTextMarginRight((value as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setTextMarginRight('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <div>{t('pages.emailEditor.textSettings.marginBottom')}:</div>
            <TextField
              id="text-margin-bottom"
              type="number"
              value={textMarginBottom}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = Math.abs(Number(event.target.value)) || '';
                setTextMarginBottom((value as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setTextMarginBottom('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <div>{t('pages.emailEditor.textSettings.marginLeft')}:</div>
            <TextField
              id="text-margin-left"
              type="number"
              value={textMarginLeft}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = Math.abs(Number(event.target.value)) || '';
                setTextMarginLeft((value as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setTextMarginLeft('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </fieldset>
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.textSettings.paddings')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div>{t('pages.emailEditor.textSettings.paddingTop')}:</div>
            <TextField
              id="text-padding-top"
              type="number"
              value={textPaddingTop}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = Math.abs(Number(event.target.value)) || '';
                setTextPaddingTop((value as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setTextPaddingTop('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <div>{t('pages.emailEditor.textSettings.paddingRight')}:</div>
            <TextField
              id="text-padding-right"
              type="number"
              value={textPaddingRight}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = Math.abs(Number(event.target.value)) || '';
                setTextPaddingRight((value as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setTextPaddingRight('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <div>{t('pages.emailEditor.textSettings.paddingBottom')}:</div>
            <TextField
              id="text-padding-bottom"
              type="number"
              value={textPaddingBottom}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = Math.abs(Number(event.target.value)) || '';
                setTextPaddingBottom((value as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setTextPaddingBottom('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <div>{t('pages.emailEditor.textSettings.paddingLeft')}:</div>
            <TextField
              id="text-padding-left"
              type="number"
              value={textPaddingLeft}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = Math.abs(Number(event.target.value)) || '';
                setTextPaddingLeft((value as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setTextPaddingLeft('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </fieldset>
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.textSettings.borderRadius')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div>{t('pages.emailEditor.textSettings.borderTopLeftRadius')}:</div>
            <TextField
              id="text-border-top-left-radius"
              type="number"
              value={textBorderTopLeftRadius}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const radius = Math.abs(Number(event.target.value)) || '';
                setTextBorderTopLeftRadius((radius as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setTextBorderTopLeftRadius('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <div>{t('pages.emailEditor.textSettings.borderTopRightRadius')}:</div>
            <TextField
              id="text-border-top-right-radius"
              type="number"
              value={textBorderTopRightRadius}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const radius = Math.abs(Number(event.target.value)) || '';
                setTextBorderTopRightRadius((radius as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setTextBorderTopRightRadius('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <div>{t('pages.emailEditor.textSettings.borderBottomLeftRadius')}:</div>
            <TextField
              id="text-border-bottom-left-radius"
              type="number"
              value={textBorderBottomLeftRadius}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const radius = Math.abs(Number(event.target.value)) || '';
                setTextBorderBottomLeftRadius((radius as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setTextBorderBottomLeftRadius('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <div>{t('pages.emailEditor.textSettings.borderBottomRightRadius')}:</div>
            <TextField
              id="text-border-bottom-right-radius"
              type="number"
              value={textBorderBottomRightRadius}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const radius = Math.abs(Number(event.target.value)) || '';
                setTextBorderBottomRightRadius((radius as unknown) as string);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setTextBorderBottomRightRadius('0');
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </fieldset>
    </ModalConfirmationWindow>
  );
};

export default TextSettingsWindow;
