import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { printProjectsOperations } from '../../../../../store/PrintProjects';
import { formatLpiExpressDate, useTypedSelector } from '../../../../../utils';
import Toast from '../../../../Shared/Toast/Toast';
import * as qs from 'query-string';
import { PrintProject } from '../../../../../store/PrintProjects/types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DefaultRoleName } from '../../../../../store/AdminRoles/types';
import styled from 'styled-components';
import { PrintCenter } from '../../../../../store/SystemSettings/types';

type PrintPublishLpiExpressStepProps = {
  project: PrintProject;
  numberOfPages: number;
  pdfUrl: string;
  reScheduling?: boolean;
  setNextButtonDisabled: (state: boolean) => void;
  printCenter: PrintCenter | null;
};

const PrintPublishLpiExpressStep: FunctionComponent<PrintPublishLpiExpressStepProps> = ({
  project,
  pdfUrl,
  numberOfPages,
  setNextButtonDisabled,
  reScheduling,
  printCenter
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authState = useTypedSelector((state) => state.auth);

  useEffect(() => {
    window.addEventListener('message', onIframeMessage, false);
    setNextButtonDisabled(true);

    return () => {
      window.removeEventListener('message', onIframeMessage);
    };
  }, [printCenter]);

  const onIframeMessage = async (e: any) => {
    if (e && e.data && e.data.task === 'clear') {
      if (!project || !printCenter) return;

      setNextButtonDisabled(false);

      try {
        await dispatch(printProjectsOperations.publishPrintProject(project.id, printCenter.id, numberOfPages));
        Toast.success(t('notifications.projectPublished.success'));
      } catch (e) {
        Toast.error(t('notifications.projectPublished.error'));
      }
    }
  };

  const uploadFormQueryParameters = {
    wiz_step: authState.role.name === DefaultRoleName.SYSTEM_OWNER ? 'cust_select' : 'contract_select',
    user_type: authState.role.name === DefaultRoleName.SYSTEM_OWNER ? 'admin' : 'regular',
    publication: `${numberOfPages}+PG+${project.type?.charAt(0).toUpperCase() + project.type?.slice(1)}`,
    pub_date: formatLpiExpressDate(project.publishDate),
    apiuser: authState.user.email,
    file_url: pdfUrl,
    pid: project.id,
    return_domain: window.location.origin,
    resubmit: !!reScheduling
  };

  if (!printCenter) return null;

  return (
    <EmbeddedSubmissionForm
      width="100%"
      height="100%"
      src={`${printCenter.url}/lpi/embedded_express/wizard?${qs.stringify(uploadFormQueryParameters)}`}
      frameBorder="0"
    />
  );
};

const EmbeddedSubmissionForm = styled.iframe`
  width: 100%;
  height: 100%;
`;

export default PrintPublishLpiExpressStep;
