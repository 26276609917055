import { httpRequest } from '../../utils';
import {
  AdminCreateOrganizationUserValues,
  AdminOrganizationUsersIndexResponse,
  AdminUpdateOrganizationUserValues
} from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { adminOrganizationUsersActions, adminOrganizationUsersRequests } from './index';

export const index = (organizationId: string) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { adminOrganizationUsers } = getState();

  try {
    dispatch(adminOrganizationUsersActions.organizationUsersIndexBegin());

    const response = await httpRequest.runRequest<AdminOrganizationUsersIndexResponse>(
      adminOrganizationUsersRequests.index({
        requestParams: {
          organizationId,
          page: adminOrganizationUsers.pagination.page,
          per: adminOrganizationUsers.pagination.per,
          q: adminOrganizationUsers.pagination.q,
          order: Object.entries(adminOrganizationUsers.tableSearch.order).length
            ? Object.entries(adminOrganizationUsers.tableSearch.order)
                .filter(([, order]) => Boolean(order))
                .map(([column, order]) => `order=${column},${order && order.order}`)
            : []
        }
      })
    );

    dispatch(adminOrganizationUsersActions.organizationUsersIndexSuccess(response));
  } catch (e) {
    dispatch(adminOrganizationUsersActions.organizationUsersIndexFailed(e));
  }
};

export const create = (organizationId: string, values: AdminCreateOrganizationUserValues) => async () => {
  return await httpRequest.runRequest<void>(
    adminOrganizationUsersRequests.create({
      requestPayload: values,
      requestParams: { organizationId }
    })
  );
};

export const update = (
  organizationId: string,
  organizationUserId: string,
  values: AdminUpdateOrganizationUserValues
) => async () => {
  return await httpRequest.runRequest<void>(
    adminOrganizationUsersRequests.update({
      requestPayload: values,
      requestParams: { organizationId, userId: organizationUserId }
    })
  );
};

export const deleteOrganizationUser = (organizationId: string, organizationUserId: string) => async () => {
  return await httpRequest.runRequest<void>(
    adminOrganizationUsersRequests.deleteOrganizationUser({
      requestParams: { organizationId, userId: organizationUserId }
    })
  );
};
