import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../../components/Shared/DataTable/types';

export enum DoAppPromotionClaimStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  PENDING = 'pending'
}

export type DoAppPromotionClaim = {
  id: string;
  createdAt: Date;
  promotion: {
    id: string;
    name: string;
  };
  merchant: {
    id: string;
    name: string;
  };
  subscriber: {
    id: string;
    email: string;
  };
  acceptedAt: Date | null;
  rejection: null | {
    createdAt: Date;
    comment: string;
    userId: string;
  };
  invoiceId: string | null;
};

export type DoAppPromotionClaimsIndexResponse = {
  data: DoAppPromotionClaim[];
  pagination: Pagination;
};

export type IndexDoAppPromotionClaimsRequestParams = {
  per: number;
  page: number;
  q: string | null;
  order?: string[];
};

export type CreatePromotionClaimRequestBodyDto = {
  promotionId: string;
  email: string;
  deviceId: string;
};

export type DoAppPromotionClaimsMineIndexResponse = {
  data: DoAppPromotionClaim[];
  pagination: Pagination;
};

export type IndexDoAppPromotionClaimsMineRequestParams = {
  per: number;
  page: number;
  promotionId?: string;
  q: string | null;
  order?: string[];
  status?: DoAppPromotionClaimStatus;
};

export type DoAppMerchantPromotionClaimsState = StateWithPagination &
  StateWithTableSearch<DoAppPromotionClaim> & {
    data: NormalizedDoAppMerchantPromotionClaims;
    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedDoAppMerchantPromotionClaims = {
  entities: Dictionary<DoAppPromotionClaim>;
  result: string[];
};

export type DoAppMerchantPromotionClaimsIndexActionBEGIN = {
  type: DoAppMerchantPromotionClaimsActionsConstants.INDEX_BEGIN;
};

export type DoAppMerchantPromotionClaimsIndexActionSUCCESS = {
  type: DoAppMerchantPromotionClaimsActionsConstants.INDEX_SUCCESS;
  payload: DoAppPromotionClaimsMineIndexResponse;
};

export type DoAppMerchantPromotionClaimsIndexActionFAILED = {
  type: DoAppMerchantPromotionClaimsActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export enum DoAppMerchantPromotionClaimsActionsConstants {
  INDEX_BEGIN = 'DO_APP/MERCHANT_PROMOTION_CLAIMS/INDEX/BEGIN',
  INDEX_SUCCESS = 'DO_APP/MERCHANT_PROMOTION_CLAIMS/INDEX/SUCCESS',
  INDEX_FAILED = 'DO_APP/MERCHANT_PROMOTION_CLAIMS/INDEX/FAILED'
}

export type DoAppMerchantPromotionClaimsActionType =
  | DoAppMerchantPromotionClaimsIndexActionBEGIN
  | DoAppMerchantPromotionClaimsIndexActionSUCCESS
  | DoAppMerchantPromotionClaimsIndexActionFAILED;
