import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { AdminSelectInput, AdminTextInput } from '../../Shared/AdminFormInputs';
import { Grid, MenuItem } from '@material-ui/core';
import { FormikProps } from 'formik';
import { AdminReportsGenerateCsvValues, ReportDateRange } from '../../../../../store/AdminReports/types';
import app from '../../../../../config/app/app';
import { parseDateRangeStringToObject } from '../../../../../utils/dateTime';

type AdminReportsGenerateCsvWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  onSubmit: (values: AdminReportsGenerateCsvValues) => void;
};

const AdminReportsGenerateCsvWindow: FunctionComponent<AdminReportsGenerateCsvWindowProps> = ({
  onCloseClick,
  open,
  onSubmit
}) => {
  const { t } = useTranslation();

  const submit = async (values: AdminReportsGenerateCsvValues) => {
    onSubmit(values);
  };

  const setDateInputValue = (e: any, props: FormikProps<any>) => {
    const range = parseDateRangeStringToObject(e.target.value);
    if (range) {
      props.setFieldValue('startDate', range.from.format('YYYY-MM-DD'));
      props.setFieldValue('endDate', range.to.format('YYYY-MM-DD'));
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminSelectInput onChange={(val) => setDateInputValue(val, props)} t={t} name="dateRange" section="adminReports">
        <MenuItem value="all">{t(`pages.adminReports.all`)}</MenuItem>
        <MenuItem value="thisYear">{t(`pages.adminReports.thisYear`)}</MenuItem>
        <MenuItem value="lastYear">{t(`pages.adminReports.lastYear`)}</MenuItem>
        <MenuItem value="thisQuarter">{t(`pages.adminReports.thisQuarter`)}</MenuItem>
        <MenuItem value="lastQuarter">{t(`pages.adminReports.lastQuarter`)}</MenuItem>
        <MenuItem value="thisMonth">{t(`pages.adminReports.thisMonth`)}</MenuItem>
        <MenuItem value="lastMonth">{t(`pages.adminReports.lastMonth`)}</MenuItem>
        <MenuItem value="thisWeek">{t(`pages.adminReports.thisWeek`)}</MenuItem>
        <MenuItem value="lastWeek">{t(`pages.adminReports.lastWeek`)}</MenuItem>
        <MenuItem value="lastDay">{t(`pages.adminReports.lastDay`)}</MenuItem>
        <MenuItem value="today">{t(`pages.adminReports.today`)}</MenuItem>
        <MenuItem value="custom">{t(`pages.adminReports.custom`)}</MenuItem>
      </AdminSelectInput>
      {props.values.dateRange !== 'all' && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <AdminTextInput
              t={t}
              disabled={props.values.dateRange !== ReportDateRange.CUSTOM}
              section="adminReports"
              name="startDate"
              type="date"
              inputProps={{ max: moment().format(app.dateFormats.inputValueDate) }}
            />
          </Grid>
          <Grid item xs={6}>
            <AdminTextInput
              t={t}
              disabled={props.values.dateRange !== ReportDateRange.CUSTOM}
              section="adminReports"
              name="endDate"
              type="date"
              inputProps={{ max: moment().format(app.dateFormats.inputValueDate) }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.download')}
      header={t('pages.adminReports.windows.downloadCsv')}
      onCloseClick={onCloseClick}
      onSubmit={submit}
      forceSubmitToBeUnlocked
      formInputs={formInputs}
      initialValues={{
        dateRange: 'lastMonth',
        startDate: parseDateRangeStringToObject(ReportDateRange.LAST_MONTH)?.from.format('YYYY-MM-DD'),
        endDate: parseDateRangeStringToObject(ReportDateRange.LAST_MONTH)?.to.format('YYYY-MM-DD')
      }}
      validationSchema={Yup.object().shape({
        dateRange: Yup.string().required(),
        startDate: Yup.date(),
        endDate: Yup.date()
      })}
    />
  );
};

export default AdminReportsGenerateCsvWindow;
