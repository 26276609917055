import React, { FunctionComponent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { AdminCreateUserValues, UserType } from '../../../../../store/AdminUsers/types';
import { adminUsersOperations } from '../../../../../store/AdminUsers';
import { useDispatch } from 'react-redux';
import {
  AdminAutocompleteInput,
  AdminCheckboxInput,
  AdminSelectInput,
  AdminTextInput
} from '../../Shared/AdminFormInputs';
import { MenuItem } from '@material-ui/core';
import Toast from '../../../../Shared/Toast/Toast';
import app from '../../../../../config/app/app';
import { administrativeRoleNames, AdminRole, DefaultRoleName } from '../../../../../store/AdminRoles/types';
import { adminRolesOperations } from '../../../../../store/AdminRoles';
import { FormikProps } from 'formik';
import { getRoleNameElement } from '../../../../../utils';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';
import { AdminOrganization } from '../../../../../store/AdminOrganizations/types';
import Loader from '../../../../Shared/Loading/Loader';
import { adminOrganizationsOperations } from '../../../../../store/AdminOrganizations';

type AdminCreateUserWindowProps = {
  open: boolean;
  userTypes: UserType[];
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminCreateUserWindow: FunctionComponent<AdminCreateUserWindowProps> = ({ onCloseClick, open, userTypes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<AdminRole[]>([]);
  const [organizations, setOrganizations] = useState<AdminOrganization[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        setLoading(true);
        const organizationsResponse = await adminOrganizationsOperations.getAll();
        setOrganizations(organizationsResponse.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    fetchData();
  }, [open]);

  const isAdministrativeUserType = (userTypeId: string) => {
    const type = userTypes.find((type) => type.id === userTypeId);
    return type && administrativeRoleNames.includes(type.name as DefaultRoleName);
  };

  const onSubmit = async (values: AdminCreateUserValues) => {
    try {
      await dispatch(adminUsersOperations.create(values));
      await dispatch(adminUsersOperations.index());
      onCloseClick();
      Toast.success(t('notifications.adminUsers.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminUsers.errorOperation'));
    }
  };

  const onUserTypeChange = async (e: any, props: FormikProps<any>) => {
    try {
      const typeId = e.target.value;

      props.setFieldValue('roleId', '');

      const rolesOfType = await adminRolesOperations.getRolesOfUserType(typeId);

      setRoles(rolesOfType.data);

      if (!isAdministrativeUserType(typeId)) {
        const typeData = userTypes.find((type) => type.id === typeId);
        if (typeData) {
          const defaultRoleData = rolesOfType.data.find((role) => role.name === typeData.name);
          if (defaultRoleData) {
            props.setFieldValue('roleId', defaultRoleData.id);
          }
        }
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="email" section="adminUsers" />
      <AdminTextInput t={t} name="firstName" section="adminUsers" />
      <AdminTextInput t={t} name="lastName" section="adminUsers" />
      {loading && <Loader />}
      {!loading && (
        <AdminAutocompleteInput
          t={t}
          name="organization"
          section="adminUsers"
          options={organizations.map((org) => ({ label: org.name, id: org.id }))}
        />
      )}
      <AdminCheckboxInput t={t} name="isOwner" section="adminUsers" />
      <AdminSelectInput onChange={(e) => onUserTypeChange(e, props)} t={t} name="typeId" section="adminRoles">
        {userTypes.map((userType) => (
          <MenuItem key={userType.id} value={userType.id}>
            {t(`common.userTypes.${userType.name}`)}
          </MenuItem>
        ))}
      </AdminSelectInput>
      {isAdministrativeUserType(props.values.typeId) && (
        <AdminSelectInput t={t} name="roleId" section="adminUsers">
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {getRoleNameElement(t, role)}
            </MenuItem>
          ))}
        </AdminSelectInput>
      )}
      {!isAdministrativeUserType(props.values.typeId) && props.values.roleId && (
        <div style={{ marginTop: 20 }}>
          <InfoBox type="info">{t('pages.adminUsers.windows.rolesAppliedInOrganizations')}</InfoBox>
        </div>
      )}
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.create')}
      header={t('pages.adminUsers.windows.createUser')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        roleId: '',
        typeId: '',
        isOwner: false,
        organization: { id: null, label: null }
      }}
      validationSchema={Yup.object().shape({
        roleId: Yup.string().required(),
        typeId: Yup.string().required(),
        isOwner: Yup.boolean().required(),
        organization: Yup.object().shape({
          id: Yup.string().required(),
          label: Yup.string().required()
        }),
        email: Yup.string().max(app.maxInputLength).email().required(),
        firstName: Yup.string().max(app.maxInputLength).nullable(),
        lastName: Yup.string().max(app.maxInputLength).nullable()
      })}
    />
  );
};

export default AdminCreateUserWindow;
