import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import { EmailProjectClone } from '../../../../store/EmailProjects/types';
import { printProjectsOperations } from '../../../../store/PrintProjects';
import { PrintProjectClone } from '../../../../store/PrintProjects/types';
import { formatTableTimestamp, isEmailProject, isPrintProject, useTypedSelector } from '../../../../utils';
import Loader from '../../../Shared/Loading/Loader';
import Toast from '../../../Shared/Toast/Toast';
import styled from 'styled-components';
import OrganizationTile from '../../MyOrganization/Partials/OrganizationTree/OrganizationTile';
import ProjectStatusCell from './ProjectStatusCell';
import linksConstants from '../../../../config/app/linksConstants';
import { hasAccessToAction } from './hasAccessToAction';
import { ProjectTitle } from './ProjectNameCell';
import { AnyProject } from '../../../../store/Dashboard/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import { buildOrganizationStructure } from '../../MyOrganization/Partials/OrganizationTree/OrganizationTree';
import { AdminOrganization } from '../../../../store/AdminOrganizations/types';
import { Story, StoryChannel, StoryChannelType, StoryClone } from '../../../../store/Stories/types';
import ChannelChip from '../../Stories/Partials/ChannelChip';
import { isStory } from '../../../../utils/projectTypeUtils';
import { storiesOperations } from '../../../../store/Stories';
import { Box } from '@material-ui/core';

type ProjectClonesProps = {
  project: AnyProject | Story;
  open: boolean;
  context?: 'modal';
};

const ProjectClones: React.FC<ProjectClonesProps> = ({ project, open, context }) => {
  const { t } = useTranslation();
  const role = useTypedSelector((state) => state.auth.role);

  const [loading, setLoading] = useState(false);
  const [clones, setClones] = useState<(PrintProjectClone | EmailProjectClone | StoryClone)[]>([]);
  const [organizations, setOrganizations] = useState<AdminOrganization[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const organizationTree = await myOrganizationOperations.getOrganizationTree();

        let projectClonesResponse: (PrintProjectClone | EmailProjectClone | StoryClone)[] = [];
        let alreadyShared: string[] = [];
        if (isEmailProject(project as AnyProject)) {
          projectClonesResponse = (await emailProjectsOperations.getProjectClones(project.id)).data;
          alreadyShared = (await emailProjectsOperations.show(project.id)).shares.map((share) => share.organizationId);
        }
        if (isPrintProject(project as AnyProject)) {
          projectClonesResponse = (await printProjectsOperations.getProjectClones(project.id)).data;
          alreadyShared = (await printProjectsOperations.show(project.id)).shares.map((share) => share.organizationId);
        }
        if (isStory(project)) {
          projectClonesResponse = (await storiesOperations.getStoryClones(project.id)).data;
          alreadyShared = (await storiesOperations.show(project.id)).shares.map((share) => share.organizationId);
        }

        const noActionOrgs = buildOrganizationStructure(organizationTree.data).filter((org) => {
          if (alreadyShared.includes(org.id)) {
            for (const clone of projectClonesResponse) {
              if (clone.target.organization && clone.target.organization.id === org.id) {
                return false;
              }
            }
            return true;
          }
          return false;
        });

        setOrganizations(noActionOrgs);
        setClones(projectClonesResponse);
        setLoading(false);
      } catch (e) {
        Toast.error(t('notifications.common.errorOperation'));
      }
    };

    if (open) {
      fetchData();
    }
  }, [open]);

  const renderProjectClone = (clone: PrintProjectClone | EmailProjectClone | StoryClone) => {
    const hasAction = hasAccessToAction(clone.target as AnyProject, role);

    return (
      <div key={`clone-${clone.id}`}>
        {clone.target.organization && (
          <TreeTileContainer>
            <OrganizationTile organization={clone.target.organization} />
          </TreeTileContainer>
        )}
        <TreeUl>
          <TreeLi>
            {isStory(project)
              ? t('pages.sharingCenter.projectClones.storyHasBeenClonedBy')
              : t('pages.sharingCenter.projectClones.projectHasBeenClonedBy')}
            &nbsp;
            <strong>{clone.createdBy.fullName}</strong>&nbsp;@&nbsp;
            {formatTableTimestamp(clone.createdAt)}
          </TreeLi>
          {!isStory(project) && (
            <TreeLi>
              {t('pages.sharingCenter.projectClones.currentStatus')}:&nbsp;
              <ProjectStatusCell project={clone.target as AnyProject} />
            </TreeLi>
          )}
          <TreeLi>
            {t('pages.sharingCenter.projectClones.name')}:&nbsp;
            {isStory(project) ? (
              <ProjectTitle
                to={{
                  pathname: linksConstants.STORY.EDIT(clone.target.id),
                  state: {
                    from: context === 'modal' ? linksConstants.DASHBOARD.SHARING_CENTER : linksConstants.STORY.INDEX
                  }
                }}
              >
                {clone.target.name}
              </ProjectTitle>
            ) : (
              <ProjectTitle disabled={!hasAction('access')} to={linksConstants.PROJECT_DETAILS(clone.target.id)}>
                {clone.target.name}
              </ProjectTitle>
            )}
          </TreeLi>
          {isStory(project) && (
            <TreeLi style={{ alignItems: 'flex-start' }}>
              {t('pages.sharingCenter.projectClones.channels')}:&nbsp;
              {renderStoryChannels((clone.target as Story).channels)}
            </TreeLi>
          )}
        </TreeUl>
      </div>
    );
  };

  const renderStoryChannels = (channels: StoryChannel[]) => {
    const storyChannels = channels.filter((cd) => cd.type !== StoryChannelType.CALENDAR_EVENT);
    if (storyChannels.length > 0) {
      return (
        <Box display="flex" alignItems="center" flexWrap="wrap">
          {storyChannels.map((channel) => (
            <ChannelChip key={channel.id} channel={channel} />
          ))}
        </Box>
      );
    } else {
      return <div>{t('pages.story.table.noChannels')}</div>;
    }
  };

  const renderNoActionOrganization = (organization: AdminOrganization) => {
    return (
      <div key={`organization-${organization.id}`}>
        <TreeTileContainer>
          <OrganizationTile organization={organization} />
        </TreeTileContainer>
        <TreeUl>
          <TreeLi>
            {isStory(project)
              ? t('pages.sharingCenter.projectClones.storyNotYetCloned')
              : t('pages.sharingCenter.projectClones.projectNotYetCloned')}
          </TreeLi>
        </TreeUl>
      </div>
    );
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && clones.length > 0 && <>{clones.map(renderProjectClone)}</>}
      {!loading && organizations.length > 0 && <>{organizations.map(renderNoActionOrganization)}</>}
    </>
  );
};

const TreeUl = styled.ul`
  margin-left: 20px;
  & .hasChildren:nth-last-child(2):after {
    border-left: 0;
  }
  & li:has(> ul):nth-child(2):before {
    border: 0;
  }
  & li:has(> ul):last-child:before {
    border: 0;
  }
  &:has(> .root) {
    margin-left: 0;
  }
`;

const TreeLi = styled.li`
  list-style-type: none;
  margin: 10px 0 10px 10px;
  position: relative;
  display: flex;
  align-items: center;
  &.root {
    &:before,
    &:after {
      display: none;
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -20px;
    border-left: 1px dashed #ddd;
    border-bottom: 1px dashed #ddd;
    width: 15px;
    height: 20px;
  }
  &:after {
    position: absolute;
    content: '';
    top: -10px;
    left: -20px;
    border-left: 1px dashed #ddd;
    border-top: 0;
    width: 20px;
    height: calc(100% + 10px);
  }
  &:last-child:after {
    display: none;
  }
`;

const TreeTileContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default ProjectClones;
