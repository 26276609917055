import React, { FunctionComponent } from 'react';
import { Stepper, Step, StepLabel, withStyles, StepConnector, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export enum PrintPublishStep {
  Review,
  Print,
  Email,
  Finalize
}

type PrintPublishStepperProps = {
  activeStep: PrintPublishStep;
};

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 'auto',
    bottom: 10,
    left: 'calc(-50% + 5px)',
    right: 'calc(50% + 5px)'
  },
  active: {
    '& $line': {
      borderColor: '#009a9f'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#009a9f'
    }
  },
  line: {
    borderColor: '#009a9f',
    borderTopWidth: 2,
    borderRadius: 1
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  active: {
    color: '#009a9f'
  },
  circle: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    border: '2px solid #009a9f'
  },
  completed: {
    color: '#009a9f',
    zIndex: 1,
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  }
});

const useStepLabelStyles = makeStyles({
  alternativeLabel: {
    flexDirection: 'column-reverse !important' as 'column-reverse'
  }
});

function QontoStepIcon(props: { active: boolean; completed: boolean }) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div className={classes.root} style={{ color: active ? '#009a9f' : '#eaeaf0' }}>
      {completed ? <div className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

function getSteps(t: TFunction) {
  return [
    t('pages.projectPublish.stepper.review'),
    t('pages.projectPublish.stepper.print'),
    t('pages.projectPublish.stepper.email'),
    t('pages.projectPublish.stepper.finalize')
  ];
}

const PrintPublishStepper: FunctionComponent<PrintPublishStepperProps> = ({ activeStep }) => {
  const { t } = useTranslation();
  const classes = useStepLabelStyles();
  const steps = getSteps(t);

  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />} style={{ paddingTop: 0 }}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={QontoStepIcon} className={classes.alternativeLabel}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default PrintPublishStepper;
