import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { withTheme, Paper as PaperMUI } from '@material-ui/core';
import { Link } from 'react-router-dom';

type TemplateTypePaperProps = {
  title: string | JSX.Element;
  icon?: JSX.Element;
  color: string;
  link: string;
  name: string;
};

const TemplateTypePaper: FunctionComponent<TemplateTypePaperProps> = ({ title, icon, color, link, name }) => {
  const [elevation, setElevation] = useState(2);

  return (
    <LinkNoUnderline to={link}>
      <Paper
        data-item-id={name}
        elevation={elevation}
        onMouseEnter={() => setElevation(5)}
        onMouseLeave={() => setElevation(2)}
      >
        <Icon color={color}>{icon}</Icon>
        <Title>{title}</Title>
      </Paper>
    </LinkNoUnderline>
  );
};

const LinkNoUnderline = styled(Link)`
  text-decoration: none;
  margin-right: 2rem;
`;
const Paper = styled(PaperMUI)`
  max-width: 10rem;
  cursor: pointer;
`;
const Icon = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  color: #fff;
  padding: 1rem;
  display: flex;
  height: 160px;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
  }
`;
const Title = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  padding: 1rem;
  background: ${({ theme }) => theme.palette.common.white};
  letter-spacing: 0.08rem;
`);

export default TemplateTypePaper;
