import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { AdminOrganization } from '../AdminOrganizations/types';

export type AdminOrganizationUsersState = StateWithPagination &
  StateWithTableSearch<AdminOrganizationUser> & {
    users: NormalizedAdminOrganizationUsers;
    organization: AdminOrganization | null;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedAdminOrganizationUsers = {
  entities: Dictionary<AdminOrganizationUser>;
  result: string[];
};

export type AdminOrganizationUsersIndexResponse = {
  organization: AdminOrganization;
  data: AdminOrganizationUser[];
  pagination: Pagination;
};

export type AdminOrganizationUsersIndexActionBEGIN = {
  type: AdminOrganizationUsersActionsConstants.INDEX_BEGIN;
};

export type AdminOrganizationUsersIndexActionSUCCESS = {
  type: AdminOrganizationUsersActionsConstants.INDEX_SUCCESS;
  payload: AdminOrganizationUsersIndexResponse;
};

export type IndexAdminOrganizationUsersRequestParams = {
  per: number;
  page: number;
  organizationId: string;
  order?: string[];
  q: string | null;
};
export type AdminCreateOrganizationUserValues = Pick<
  AdminOrganizationUser,
  'email' | 'firstName' | 'lastName' | 'isOwner'
>;
export type AdminUpdateOrganizationUserValues = Pick<AdminOrganizationUser, 'roleId' | 'isOwner'>;

export type AdminOrganizationUsersIndexActionFAILED = {
  type: AdminOrganizationUsersActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type AdminOrganizationUser = {
  id: string;
  isOwner: boolean;
  email: string;
  firstName: string;
  lastName: string;
  uniqAdminOrganizationUser: 'uniqAdminOrganizationUser';
  createdAt: string;
  updatedAt: string;
  roleId: string;
  typeId: string;
};

export type AdminOrganizationUsersResponse = {
  data: AdminOrganizationUser[];
};

export enum AdminOrganizationUsersActionsConstants {
  INDEX_BEGIN = 'ADMIN_ORGANIZATION_USERS/INDEX/BEGIN',
  INDEX_SUCCESS = 'ADMIN_ORGANIZATION_USERS/INDEX/SUCCESS',
  INDEX_FAILED = 'ADMIN_ORGANIZATION_USERS/INDEX/FAILED'
}

export type AdminOrganizationUsersActionType =
  | AdminOrganizationUsersIndexActionBEGIN
  | AdminOrganizationUsersIndexActionSUCCESS
  | AdminOrganizationUsersIndexActionFAILED;
