import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from '../../../../Shared/Toast/Toast';
import Loader from '../../../../Shared/Loading/Loader';
import InfoIcon from '@material-ui/icons/Info';
import { Light, OutlinedInfoBoxContent, Strong } from '../../../../Shared/StyledComponents';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';
import { Button, TextField } from '@material-ui/core';
import { EmailProjectCreatorState } from '../../types';
import styled from 'styled-components';
import { NoResultsFound } from '../../../PageStyledComponents';
import ProjectPaper from '../../../../Shared/Layout/ProjectPaper';
import { formatPublishDate } from '../../../../../utils';
import { PrintProject, PrintProjectStatus } from '../../../../../store/PrintProjects/types';
import { Pagination } from '@material-ui/lab';
import { getPrintProjects } from '../../../../../store/PrintProjects/operations';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

type SelectPrintProductProps = {
  onSubmit: (project: PrintProject | null) => void;
  setCurrentState: (state: EmailProjectCreatorState) => void;
  publishDate: Date;
};

const SelectPrintProduct: FunctionComponent<SelectPrintProductProps> = ({ publishDate, onSubmit, setCurrentState }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<PrintProject[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 10, total: 0, totalPages: 0, q: null });

  const fetchData = async (page: number, search: string | null) => {
    setLoading(true);
    try {
      const response = await getPrintProjects(page, pagination.per, search, [], [PrintProjectStatus.DELIVERED]);

      setProjects(response.data);
      setPagination(response.pagination);
    } catch (e) {
      Toast.error(t('notifications.selectPrintProductFetch.error'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pagination.page, pagination.q);
  }, []);

  const handleSearch = (page: number, val: string) => {
    fetchData(page, val);
  };

  return (
    <div>
      <TopBarContainer>
        <InfoBoxWrapper data-item-id="start-from-scratch-wrapper">
          <InfoBox type="outlinedInfo">
            <OutlinedInfoBoxContent>
              <InfoIcon fontSize="large" />
              <div>
                <Strong>{t('pages.dashboard.createWizard.printProductSelector.selectProduct')}</Strong>{' '}
                <Light>{t('common.or')}</Light>
                <Button
                  data-item-id="start-from-scratch-button"
                  color={'secondary'}
                  variant="contained"
                  size="large"
                  onClick={() => onSubmit(null)}
                >
                  {t('pages.dashboard.createWizard.printProductSelector.startFromScratch')}
                </Button>
              </div>
            </OutlinedInfoBoxContent>
          </InfoBox>
        </InfoBoxWrapper>
        <PublishDateWrapper>
          <span>
            <Light>{t('common.publishDate')}</Light>: {formatPublishDate(publishDate)}
            <Button color={'secondary'} variant="outlined" size="large" onClick={() => setCurrentState('publishDate')}>
              {t('common.change')}
            </Button>
          </span>
        </PublishDateWrapper>
      </TopBarContainer>
      {loading && <Loader />}
      {!loading && (
        <ListWrapper>
          {!projects.length && (
            <NoResultsFound>
              {t('pages.dashboard.createWizard.printProductSelector.noPrintProductsForThisDate')}
            </NoResultsFound>
          )}
          {projects.length > 0 && (
            <>
              <div style={{ display: 'flex', maxWidth: '300px', marginLeft: 'auto', marginBottom: '20px' }}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder={`${t('common.search')}...`}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  InputProps={{
                    endAdornment: searchValue !== '' && (
                      <IconButton
                        color={'primary'}
                        size="small"
                        onClick={() => {
                          setSearchValue('');
                          handleSearch(1, '');
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )
                  }}
                />
                <IconButton color={'secondary'} size="small" onClick={() => handleSearch(1, searchValue)}>
                  <SearchIcon />
                </IconButton>
              </div>
              <ProjectsListContainer>
                {projects.map((project) => (
                  <ProjectPaper
                    key={project.id}
                    project={project}
                    onSelect={(project) => onSubmit(project as PrintProject)}
                  />
                ))}
              </ProjectsListContainer>
              <Pagination
                count={pagination.totalPages}
                page={pagination.page}
                shape="rounded"
                onChange={(e, page) => fetchData(page, searchValue)}
                size="small"
                style={{ display: 'flex', justifyContent: 'center' }}
              />
            </>
          )}
        </ListWrapper>
      )}
    </div>
  );
};

const ListWrapper = styled.div`
  margin-top: 0.5rem;
`;

const ProjectsListContainer = styled.div`
  text-align: center;
`;

const TopBarContainer = styled.div`
  display: flex;
  align-items: center;

  .MuiButtonBase-root {
    margin-left: 1rem;
  }
`;
const InfoBoxWrapper = styled.div``;
const PublishDateWrapper = styled.div`
  font-size: 1.1rem;
  flex-grow: 1;
  text-align: right;

  .MuiButtonBase-root {
  }
`;

export default SelectPrintProduct;
