import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Promotions } from '../../types';
import { useTranslation } from 'react-i18next';
import useOpenHandler from '../../../../../../hooks/useOpenHandler';
import { ComponentActionIcons, StyledEditIcon } from '../../../../../Shared/StyledComponents';
import { ReactComponent as DateRangeIcon } from '../../../../../../assets/icons/date_range.svg';
import { ReactComponent as SettingsIcon } from '../../../../../../assets/icons/settings.svg';
import { StoryChannel, StoryFieldName } from '../../../../../../store/Stories/types';
import { myOrganizationOperations } from '../../../../../../store/MyOrganization';
import { Range } from 'react-date-range';
import { format, parseISO } from 'date-fns';
import DateRangePickerWindow from '../../../../Stories/Windows/DateRangePickerWindow';
import Loader from '../../../../../Shared/Loading/Loader';
import Toast from '../../../../../Shared/Toast/Toast';
import { addNegativeTimezoneOffset, formatPublishDate, trimHtml } from '../../../../../../utils';
import { stripHtml } from '../../Utils';
import PromotionsSettingsWindow from '../Windows/PromotionsSettingsWindow';
import { AdminOrganization } from '../../../../../../store/AdminOrganizations/types';

type PromotionsProps = {
  data: Promotions;
  onChange: (data: Promotions) => void;
  inProjectEditor: boolean;
};

const PromotionsComponent: FunctionComponent<PromotionsProps> = ({ data, onChange, inProjectEditor }) => {
  const { t } = useTranslation();
  const [dateRangePickerWindowOpen, onDateRangePickerWindowOpen, onDateRangePickerWindowClose] = useOpenHandler();
  const [
    promotionsSettingsWindowOpen,
    onPromotionsSettingsWindowOpen,
    onPromotionsSettingsWindowClose
  ] = useOpenHandler();

  const [loading, setLoading] = useState<boolean>(true);
  const [promotions, setPromotions] = useState<StoryChannel[]>([]);
  const [organization, setOrganization] = useState<AdminOrganization | null>(null);
  const [promotionPeriod, setPromotionPeriod] = useState<Range[]>([
    {
      startDate: addNegativeTimezoneOffset(data.params.startDate),
      endDate: addNegativeTimezoneOffset(data.params.endDate),
      key: 'selection'
    }
  ]);

  const fetchPromotions = async (startDate: string, endDate: string) => {
    setLoading(true);
    try {
      const res = await myOrganizationOperations.getPromotions(startDate, endDate);
      setPromotions(res.promotions);
      setOrganization(res.organization);
    } catch (error) {
      Toast.error(t('notifications.story.errorFetchingPromotions'));
    } finally {
      setLoading(false);
    }
  };

  const promotionsHeader = useMemo(() => {
    const { headerVariable, style, customHeaderText, startDate, endDate } = data.params;
    const headerPosition = style.headerPosition;

    const formatDateRange = (start: string, end: string) => {
      return `Date: ${format(parseISO(start), 'LLLL d')} - ${format(parseISO(end), 'LLLL d')}`;
    };

    const getOrganizationHeader = () => {
      if (headerVariable === 'region') {
        return organization?.region || '';
      }
      if (headerVariable === 'district') {
        return organization?.district || '';
      }
      return '';
    };

    // Custom header case
    if (headerVariable === 'custom') {
      return `
        <p style="line-height: 1; text-align: ${headerPosition};">
          ${customHeaderText || t('pages.emailEditor.promotions.promotionsHeader')}
        </p>
        <p style="line-height: 1; font-size: 16px; text-align: ${headerPosition};">
          ${formatDateRange(startDate, endDate)}
        </p>
      `;
    }

    // Organization header case
    if (organization) {
      return `
        <p style="line-height: 1; text-align: ${headerPosition};">
          ${getOrganizationHeader()} ${t('pages.emailEditor.promotions.promotionsHeader')}
        </p>
        <p style="line-height: 1; font-size: 16px; text-align: ${headerPosition};">
          ${formatDateRange(startDate, endDate)}
        </p>
      `;
    }

    // Default case
    return '';
  }, [
    data.params.startDate,
    data.params.endDate,
    data.params.headerVariable,
    data.params.customHeaderText,
    organization
  ]);

  useEffect(() => {
    fetchPromotions(
      format(parseISO(data.params.startDate), 'yyyy-MM-dd'),
      format(parseISO(data.params.endDate), 'yyyy-MM-dd')
    );
  }, []);

  useEffect(() => {
    onChange({
      ...data,
      params: {
        ...data.params,
        header: promotionsHeader
      }
    });
  }, [data.params.startDate, data.params.endDate, promotionsHeader]);

  return (
    <div style={data.wrapperStyle}>
      <div style={{ fontSize: '24px', padding: '15px' }}>
        <div dangerouslySetInnerHTML={{ __html: data.params.header }} />
      </div>
      {loading && <Loader />}
      {!loading && promotions.length === 0 && (
        <div style={{ padding: '15px' }}>{t('pages.emailEditor.promotions.noPromotions')}</div>
      )}
      {!loading &&
        promotions.map((promotion) => {
          let title = '';
          let body = '';

          const emailChannelContents =
            promotion.fields.find((field) => field.name === StoryFieldName.BODY)?.value.toString() || '';

          const parts = emailChannelContents.split('\n');

          if (parts.length) {
            // The first sentence
            title = trimHtml((parts.shift() || '').trim(), {
              limit: 10000,
              preserveTags: false
            }).html;

            // The rest of the string
            body = trimHtml(parts.join('\n').trim(), {
              limit: 10000,
              preserveTags: false
            }).html;
          }

          return (
            <div
              key={promotion.id}
              style={{
                display: 'flex',
                backgroundColor: data.params.style.backgroundColor,
                borderWidth: data.params.style.borderWidth,
                borderColor: data.params.style.borderColor,
                borderStyle: data.params.style.borderStyle,
                borderRadius: data.params.style.borderRadius,
                marginBottom: data.params.style.height || '5px'
              }}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', padding: '10px 15px', color: '#000', width: '100%' }}
              >
                {promotion.attachments.length > 0 && promotion.attachments[0].url && (
                  <div style={{ width: '200px', height: '150px', marginRight: '15px' }}>
                    <img
                      src={promotion.attachments[0].url}
                      alt=""
                      style={{ objectFit: 'cover', width: '200px', height: '150px' }}
                    />
                  </div>
                )}
                {promotion.attachments.length === 0 && (
                  <div
                    style={{
                      width: '200px',
                      height: '150px',
                      marginRight: '15px',
                      background: '#f1f1f1',
                      color: data.params.style.color,
                      fontSize: '18px',
                      fontWeight: 'bold',
                      paddingTop: '60px',
                      textAlign: 'center'
                    }}
                  >
                    Promotion!
                  </div>
                )}
                <div style={{ textAlign: 'center', width: '425px' }}>
                  <p
                    style={{
                      fontSize: '18px',
                      marginBottom: '10px',
                      color: data.params.style.color,
                      lineHeight: '22px'
                    }}
                  >
                    {stripHtml(title.toString())}
                  </p>
                  <p style={{ fontSize: '16px', color: data.params.style.color, lineHeight: '22px' }}>
                    {stripHtml(body.toString())}
                  </p>
                  {organization && (
                    <p
                      style={{
                        fontSize: '12px',
                        marginTop: '20px',
                        textAlign: 'right',
                        color: data.params.style.color,
                        lineHeight: '22px'
                      }}
                    >
                      <strong>{organization.name}</strong>
                    </p>
                  )}
                  <p
                    style={{
                      fontSize: '12px',
                      textAlign: 'right',
                      color: data.params.style.color,
                      lineHeight: '22px'
                    }}
                  >
                    {t('pages.emailEditor.promotions.promotionExpiresOn')}{' '}
                    <strong>{formatPublishDate(promotion.story.publicationDateTo)}</strong>
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      {inProjectEditor && (
        <ComponentActionIcons style={{ left: 'calc(100% - 75px)' }}>
          <StyledEditIcon onClick={() => onDateRangePickerWindowOpen()}>
            <DateRangeIcon style={{ width: 22, fill: '#fff' }} />
          </StyledEditIcon>
          <StyledEditIcon onClick={() => onPromotionsSettingsWindowOpen()}>
            <SettingsIcon style={{ width: 22, fill: '#fff' }} />
          </StyledEditIcon>
        </ComponentActionIcons>
      )}

      {dateRangePickerWindowOpen && (
        <DateRangePickerWindow
          range={promotionPeriod}
          open={dateRangePickerWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onDateRangePickerWindowClose}
          onSaveClick={(period: Range[]) => {
            setPromotionPeriod(period);
            if (period[0].startDate && period[0].endDate) {
              const startDateString = format(period[0].startDate, 'yyyy-MM-dd');
              const endDateString = format(period[0].endDate, 'yyyy-MM-dd');

              onChange({
                ...data,
                params: {
                  ...data.params,
                  startDate: startDateString,
                  endDate: endDateString
                }
              });
              fetchPromotions(startDateString, endDateString);
            }
          }}
          label={t('pages.emailEditor.promotions.promotionsDateRange')}
        />
      )}
      {promotionsSettingsWindowOpen && (
        <PromotionsSettingsWindow
          open={promotionsSettingsWindowOpen}
          onCloseClick={onPromotionsSettingsWindowClose}
          onFormSubmit={(values) => {
            onChange({
              ...data,
              params: {
                ...data.params,
                headerVariable: values.headerVariable,
                customHeaderText: values.customHeaderText,
                style: {
                  backgroundColor: values.backgroundColor,
                  color: values.color,
                  borderRadius: values.borderRadius,
                  borderStyle: values.borderStyle,
                  borderWidth: values.borderWidth,
                  borderColor: values.borderColor,
                  headerPosition: values.headerPosition,
                  height: values.height
                }
              }
            });
            onPromotionsSettingsWindowClose();
          }}
          backgroundColor={(data.params.style && data.params.style.backgroundColor) || 'transparent'}
          color={(data.params.style && data.params.style.color) || '#000'}
          borderRadius={(data.params.style && data.params.style.borderRadius) || 'inherit'}
          borderStyle={(data.params.style && data.params.style.borderStyle) || 'inherit'}
          borderWidth={(data.params.style && data.params.style.borderWidth) || 'inherit'}
          borderColor={(data.params.style && data.params.style.borderColor) || 'inherit'}
          headerPosition={(data.params.style && data.params.style.headerPosition) || 'center'}
          headerVariable={(data.params && data.params.headerVariable) || 'custom'}
          customHeaderText={
            (data.params && data.params.customHeaderText) || t('pages.emailEditor.promotions.promotionsHeader')
          }
          height={(data.params.style && data.params.style.height) || '5px'}
        />
      )}
    </div>
  );
};

export default PromotionsComponent;
