import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { NotesProjectType } from './types';

export const getProjectNotes = (
  settings: RequestSettings<{ projectId: string; projectType: NotesProjectType }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/notes/:projectType/:projectId'
  },
  params: settings.requestParams
});

export const addNoteToProject = (
  settings: RequestSettings<{ projectId: string; projectType: NotesProjectType }, { content: string }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/notes/:projectType/:projectId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const updateProjectNote = (
  settings: RequestSettings<{ projectId: string; noteId: string; projectType: NotesProjectType }, { content: string }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/notes/:projectType/:projectId/:noteId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeProjectNote = (
  settings: RequestSettings<{ noteId: string; projectType: NotesProjectType }, {}>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/notes/:projectType/:noteId'
  },
  params: settings.requestParams
});
