import AdminRolesPage from './AdminRolesPage';
import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';
import AdminCreateRolePage from './AdminCreateRolePage';

const AdminRolesRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMINISTRATION.ROLES.INDEX,
    exact: true,
    name: 'roles',
    public: false,
    show: true,
    component: AdminRolesPage,
    scope: []
  },
  {
    path: linksConstants.ADMINISTRATION.ROLES.CREATE,
    exact: true,
    name: 'createRole',
    public: false,
    show: true,
    component: AdminCreateRolePage,
    scope: []
  },
  {
    path: linksConstants.ADMINISTRATION.ROLES.EDIT(':roleId'),
    exact: false,
    name: 'editRole',
    public: false,
    show: true,
    component: AdminCreateRolePage,
    scope: []
  }
];

export default AdminRolesRoutes;
