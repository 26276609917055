import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';
import { Divider, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Story } from '../../../../store/Stories/types';

type DesignEditWarningWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  stories: Story[];
};

const DesignEditWarningWindow: FunctionComponent<DesignEditWarningWindowProps> = ({ onCloseClick, open, stories }) => {
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('windows.designEditWarningWindow.header')}
      onCloseClick={onCloseClick}
      hideActionButtons
    >
      <Row>
        <Alert severity="warning">
          <Typography variant="body1">
            <strong>{t('windows.designEditWarningWindow.content')}</strong>
          </Typography>
          <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
          {stories.map((story) => (
            <ol key={`story-${story.id}`} style={{ listStyle: 'disc', marginLeft: '20px' }}>
              <li>
                <strong>{story.name}</strong>
                <ul style={{ listStyle: 'circle', marginLeft: '20px' }}>
                  {story.channels.map((channel, i) => (
                    <li key={`channel-${channel.id}`}>{t(`pages.story.channels.${channel.type}`)}</li>
                  ))}
                </ul>
              </li>
            </ol>
          ))}
        </Alert>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DesignEditWarningWindow;
