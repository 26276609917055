import React from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import { AdminKeywordGroup } from '../../../store/AdminKeywords/types';
import { useTranslation } from 'react-i18next';

type AdminKeywordGroupChipProps = {
  keywordGroup: AdminKeywordGroup;
  onDelete: null | ((tag: AdminKeywordGroup) => void);
};

const AdminKeywordGroupChip: React.FC<AdminKeywordGroupChipProps> = ({ keywordGroup, onDelete }) => {
  const { t } = useTranslation();

  const tooltipContent = (
    <div>
      <h4>{t('pages.adminKeywords.keywordsInGroup')}</h4>
      {keywordGroup.keywords.map((keyword) => (
        <p key={`${keywordGroup.id}-${keyword.id}`}>{keyword.name}</p>
      ))}
    </div>
  );

  return (
    <div>
      <Tooltip title={tooltipContent} placement="top">
        <Chip
          size="small"
          color="primary"
          onDelete={onDelete ? () => onDelete(keywordGroup) : undefined}
          label={`${keywordGroup.name} (${keywordGroup.keywords.length})`}
          style={{ margin: '0 2px 2px 0' }}
        />
      </Tooltip>
    </div>
  );
};

export default AdminKeywordGroupChip;
