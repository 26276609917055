import React from 'react';
import { Typography } from '@material-ui/core';

type WidgetFetchErrorProps = {
  title: string;
};

export const WidgetFetchError = (props: WidgetFetchErrorProps) => {
  return (
    <Typography variant="h6" style={{ textAlign: 'center', marginTop: 20 }}>
      {props.title}
    </Typography>
  );
};
