import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { subscribersOperations } from '../../../../../store/Subscribers';
import { useDispatch } from 'react-redux';
import Toast from '../../../../Shared/Toast/Toast';
import { CreateSubscriberValues } from '../../../../../store/Subscribers/types';
import { AdminTextInput } from '../../../Admin/Shared/AdminFormInputs';
import app from '../../../../../config/app/app';
import { FormikProps } from 'formik';
import { createNetworkErrorObject } from '../../../../../utils';

type CreateSubscriberWindowProps = {
  open: boolean;
  mailingListId: string;
  onCloseClick: (isFormSubmitted: boolean) => void;
  fullScreenOnMobile: boolean;
};

const CreateSubscriberWindow: FunctionComponent<CreateSubscriberWindowProps> = ({
  mailingListId,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = async (values: CreateSubscriberValues) => {
    try {
      await dispatch(subscribersOperations.create(mailingListId, values));
      onCloseClick(true);
      Toast.success(t('notifications.subscribers.successOperation'));
    } catch (e) {
      const error = createNetworkErrorObject(e);

      switch (error.message) {
        case 'on_suppression_list':
          return Toast.error(t('notifications.subscribers.onSuppressionList'));
        default:
          Toast.error(t('notifications.subscribers.errorOperation'));
      }
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="email" section="subscribers" />
      <AdminTextInput t={t} name="firstName" section="subscribers" />
      <AdminTextInput t={t} name="lastName" section="subscribers" />
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.create')}
      header={t('pages.subscribers.windows.createSubscriber')}
      onCloseClick={() => onCloseClick(false)}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{ email: '', firstName: null, lastName: null }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .max(app.maxInputLength)
          .test(
            'You cannot use characters <, >, ", \', %, &, +, ?',
            'You cannot use characters <, >, ", \', %, &, +, ?',
            (value: string) => !(value || '').match(/[<>"'%&+?]/)
          )
          .nullable(),
        lastName: Yup.string()
          .max(app.maxInputLength)
          .test(
            'You cannot use characters <, >, ", \', %, &, +, ?',
            'You cannot use characters <, >, ", \', %, &, +, ?',
            (value: string) => !(value || '').match(/[<>"'%&+?]/)
          )
          .nullable(),
        email: Yup.string().max(app.maxInputLength).email().required()
      })}
    />
  );
};

export default CreateSubscriberWindow;
