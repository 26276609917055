import { createOrderArray, formatPublishDate, httpRequest } from '../../utils';
import { printProjectsActions, printProjectsRequests } from './index';
import {
  GetDesignerArticlesResponse,
  GetUserPrintProjectsResponse,
  PrintProject,
  PrintProjectClonesResponse,
  PrintProjectStatus
} from './types';
import { PrintProjectTemplateTypeName, Template } from '../Templates/types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { Campaign } from '../Campaigns/types';

export const getUserPrintProjects = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { printProjects } = getState();

  try {
    dispatch(printProjectsActions.projectsIndexBegin());

    const response = await getPrintProjects(
      printProjects.pagination.page,
      printProjects.pagination.per,
      printProjects.pagination.q,
      createOrderArray(printProjects.tableSearch.order)
    );

    dispatch(printProjectsActions.projectsIndexSuccess(response));
  } catch (e) {
    dispatch(printProjectsActions.projectsIndexFailed(e));
  }
};

export const getPrintProjects = async (
  page: number,
  per: number,
  q: string | null,
  order?: string[],
  status?: PrintProjectStatus[]
) => {
  return await httpRequest.runRequest<GetUserPrintProjectsResponse>(
    printProjectsRequests.getUserPrintProjects({
      requestParams: {
        page,
        per,
        q,
        order,
        status: status && status.length ? status.join(',') : ''
      }
    })
  );
};

export const create = async (
  template: Template,
  publishDate: Date,
  type: PrintProjectTemplateTypeName,
  testProject: boolean
) => {
  return await httpRequest.runRequest<PrintProject>(
    printProjectsRequests.create({
      requestParams: { templateId: template.id },
      requestPayload: {
        publishDate: formatPublishDate(publishDate),
        type,
        test: testProject
      }
    })
  );
};

export const show = async (projectId: string) => {
  return await httpRequest.runRequest<PrintProject>(
    printProjectsRequests.show({
      requestParams: { projectId }
    })
  );
};

export const getArticles = async (projectId: string) => {
  return await httpRequest.runRequest<GetDesignerArticlesResponse>(
    printProjectsRequests.getArticles({
      requestParams: { projectId }
    })
  );
};

export const savePrintProjectName = async (project: PrintProject, name: string) => {
  return await httpRequest.runRequest<PrintProject>(
    printProjectsRequests.savePrintProjectName({
      requestParams: { projectId: project.id },
      requestPayload: { name }
    })
  );
};

export const removePrintProject = (projectId: string) => async (dispatch: Dispatch) => {
  await httpRequest.runRequest<PrintProject>(
    printProjectsRequests.removePrintProject({
      requestParams: { projectId }
    })
  );

  dispatch(printProjectsActions.removeProject(projectId));
};

export const shareProject = (projectId: string, organizations: string[]) => async (dispatch: Dispatch) => {
  await httpRequest.runRequest<PrintProject>(
    printProjectsRequests.shareProject({
      requestParams: { projectId },
      requestPayload: { organizations }
    })
  );

  dispatch(printProjectsActions.shareProject(projectId));
};

export const getProjectClones = async (projectId: string) => {
  return await httpRequest.runRequest<PrintProjectClonesResponse>(
    printProjectsRequests.getProjectClones({
      requestParams: { projectId }
    })
  );
};

export const publishPrintProject = (projectId: string, printCenterId: string, pageCount: number) => async (
  dispatch: Dispatch
) => {
  const project = await httpRequest.runRequest<PrintProject>(
    printProjectsRequests.publishPrintProject({
      requestParams: {
        projectId
      },
      requestPayload: {
        printCenterId,
        pageCount
      }
    })
  );

  dispatch(printProjectsActions.publishProject(project));

  return project;
};

export const generatePrintPdf = async (projectId: string) => {
  return await httpRequest.runRequest<{ url: string; pages: number }>(
    printProjectsRequests.generatePrintPdf({
      requestParams: { projectId }
    })
  );
};

export const getProjectPreviewUrl = async (project: PrintProject) => {
  return await httpRequest.runRequest<{ url: string; pages: number }>(
    printProjectsRequests.getPrintProjectPreviewUrl({
      requestParams: { projectId: project.id }
    })
  );
};

export const saveProjectPublishDate = async (project: PrintProject, newPublishDate: Date) => {
  return await httpRequest.runRequest<PrintProject>(
    printProjectsRequests.saveProjectPublishDate({
      requestParams: { projectId: project.id },
      requestPayload: { name: project.name, publishDate: newPublishDate }
    })
  );
};

export const checkIn = async (projectId: string, checkInToken: string) => {
  return await httpRequest.runRequest<PrintProject>(
    printProjectsRequests.checkIn({
      requestParams: { projectId },
      requestPayload: { checkInToken }
    })
  );
};

export const clone = async (projectId: string, publishDate: Date, testProject: boolean) => {
  return await httpRequest.runRequest<PrintProject>(
    printProjectsRequests.clone({
      requestParams: { projectId },
      requestPayload: { publishDate, test: testProject }
    })
  );
};

export const cloneShared = async (projectId: string, name: string) => {
  return await httpRequest.runRequest<PrintProject>(
    printProjectsRequests.cloneShared({
      requestParams: { projectId },
      requestPayload: { name }
    })
  );
};

export const generateFlipBookResources = async (projectId: string) => {
  return await httpRequest.runRequest<void>(
    printProjectsRequests.generateFlipBookResources({
      requestParams: { projectId }
    })
  );
};

export const saveContent = async (projectId: string) => {
  return await httpRequest.runRequest<{ status: string }>(
    printProjectsRequests.saveContent({
      requestParams: { projectId }
    })
  );
};

export const getPrintProjectCampaigns = async (projectId: string) => {
  return await httpRequest.runRequest<{ data: Campaign[] }>(
    printProjectsRequests.getPrintProjectCampaigns({
      requestParams: { projectId }
    })
  );
};
