import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { AdBanner, AdCategory, AdZone } from '../../../../../../store/AdminAds/types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core';
import AdBannerTableRow, { SubtitleUrl, Title } from '../AdBannerTableRow';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import SaveIcon from '@material-ui/icons/Save';

type HouseAdsTableProps = {
  onSave: (size: string, selectedZonesIds: string[]) => void;
  size: string;
  categories: AdCategory[];
  adSpaces: { [key: string]: number };
  bannerZones: { zone: AdZone; banner: AdBanner }[];
  selectedZones: { size: string; zones: string[] }[];
  removeAssignment: (size: string, banner: AdBanner, zone: AdZone) => void;
};

const HouseAdsTable: FunctionComponent<HouseAdsTableProps> = ({
  onSave,
  categories,
  size,
  selectedZones,
  bannerZones,
  removeAssignment,
  adSpaces
}) => {
  const { t } = useTranslation();

  const selectedZonesData = selectedZones.find((s) => s.size === size);

  const [assigningMode, setAssigningMode] = useState<boolean>(false);
  const [selectedZonesIds, setSelectedZonesIds] = useState<string[]>(selectedZonesData ? selectedZonesData.zones : []);

  useEffect(() => {
    const selectedZonesData = selectedZones.find((s) => s.size === size);

    if (selectedZonesData) {
      setSelectedZonesIds(selectedZonesData.zones);
    }
  }, [assigningMode]);

  const getAllMatchingBanners = useCallback(
    (size: string, type: 'by-size' | 'by-size-and-selection'): { zone: AdZone; banner: AdBanner }[] => {
      const bannersBySize = bannerZones.filter((data) => size === `${data.zone.width}x${data.zone.height}`);

      if (type === 'by-size') {
        return bannersBySize;
      }

      if (type === 'by-size-and-selection') {
        const selectedZonesData = selectedZones.find((selected) => selected.size === size);
        if (selectedZonesData) {
          return bannersBySize.filter((data) => selectedZonesData.zones.includes(data.zone.zoneId));
        }
      }

      return [];
    },
    [selectedZones]
  );

  const matchingBannersBySizeAndSelection = getAllMatchingBanners(size, 'by-size-and-selection');
  const matchingBannersBySize = getAllMatchingBanners(size, 'by-size');

  const noResults = (
    <TableRow>
      <TableCell colSpan={3}>
        <Typography
          variant="subtitle1"
          color="primary"
          style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
        >
          <PhotoLibraryIcon style={{ marginRight: 10 }} /> {t('pages.adminAds.noMatchingHouseAds')}
        </Typography>
      </TableCell>
    </TableRow>
  );

  const onAdZoneSelected = (checked: boolean, zone: AdZone) => {
    if (checked) {
      setSelectedZonesIds([...selectedZonesIds, zone.zoneId]);
    } else {
      setSelectedZonesIds(selectedZonesIds.filter((id) => id !== zone.zoneId));
    }
  };

  return (
    <>
      <TableContainer component={Paper} style={{ margin: '1rem 0', padding: '1rem 2rem' }}>
        <TableHeadWrapper>
          <Typography variant="h5" color="secondary" gutterBottom>
            {size} <em style={{ fontSize: '1rem' }}>(x{adSpaces[size]})</em>
          </Typography>
          {!assigningMode && (
            <Button color={'secondary'} variant={'contained'} size="medium" onClick={() => setAssigningMode(true)}>
              <AddIcon /> {t('pages.adminAds.assignNewHouseAds')}
            </Button>
          )}
        </TableHeadWrapper>

        {!assigningMode && (
          <Table>
            <TableBody>
              {matchingBannersBySizeAndSelection.length === 0 && noResults}
              {matchingBannersBySizeAndSelection.length > 0 &&
                matchingBannersBySizeAndSelection.map((banner) => (
                  <AdBannerTableRow
                    key={banner.banner.bannerId}
                    context={'houseAdsAssignment'}
                    banner={banner.banner}
                    categories={categories}
                    onRemoveAdBanner={(bn) => removeAssignment(size, bn, banner.zone)}
                  />
                ))}
            </TableBody>
          </Table>
        )}
        {assigningMode && (
          <Table>
            <TableBody>
              {matchingBannersBySize.length === 0 && noResults}
              {matchingBannersBySize.length > 0 &&
                matchingBannersBySize.map((banner) => (
                  <TableRow key={`assign-${banner.banner.bannerId}-${size}`}>
                    <TableCell style={{ width: 50, border: 0 }}>
                      <Checkbox
                        checked={selectedZonesIds.includes(banner.zone.zoneId)}
                        onChange={(e) => onAdZoneSelected(e.target.checked, banner.zone)}
                      />
                    </TableCell>
                    <TableCell style={{ border: 0 }}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <img
                          src={banner.banner.imageURL}
                          alt={banner.banner.alt}
                          style={{
                            width: '50px',
                            height: '50px',
                            objectFit: 'contain',
                            objectPosition: 'center',
                            border: '1px solid #E5E5E5'
                          }}
                        />
                        <div style={{ flexGrow: 1 }}>
                          <Title>{banner.banner.bannerName}</Title>
                          <SubtitleUrl>{banner.banner.url}</SubtitleUrl>
                        </div>
                        <CategoryName>
                          {categories.find((cat) => cat.id === banner.banner.categoryId)?.name}
                        </CategoryName>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      {assigningMode && (
        <ActionButtons>
          <Button color={'secondary'} variant={'outlined'} size="medium" onClick={() => setAssigningMode(false)}>
            {t('common.cancel')}
          </Button>
          <Button
            color={'secondary'}
            variant={'contained'}
            size="medium"
            onClick={() => {
              onSave(size, selectedZonesIds);
              setAssigningMode(false);
            }}
          >
            <SaveIcon /> {t('common.save')}
          </Button>
        </ActionButtons>
      )}
    </>
  );
};

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > button {
    margin-left: 1rem;
  }
`;

const TableHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CategoryName = styled.div`
  font-style: italic;
  font-size: 0.8rem;
`;

export default HouseAdsTable;
