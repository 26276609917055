import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminPasswordPolicy } from '../../../../../store/AdminPasswordPolicies/types';
import { adminPasswordPoliciesOperations } from '../../../../../store/AdminPasswordPolicies';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';
import Toast from '../../../../Shared/Toast/Toast';
import { createNetworkErrorObject } from '../../../../../utils';

type AdminDeletePasswordPolicyWindowProps = {
  open: boolean;
  passwordPolicy: AdminPasswordPolicy | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminDeletePasswordPolicyWindow: FunctionComponent<AdminDeletePasswordPolicyWindowProps> = ({
  passwordPolicy,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!passwordPolicy) return null;

  const onSubmit = async () => {
    try {
      await dispatch(adminPasswordPoliciesOperations.deletePasswordPolicy(passwordPolicy.id));
      await dispatch(adminPasswordPoliciesOperations.index());
      onCloseClick();
      Toast.success(t('notifications.adminPasswordPolicies.successOperation'));
    } catch (e) {
      const networkError = createNetworkErrorObject(e);

      switch (networkError.message) {
        case 'password_policy_in_use':
          Toast.info(t('notifications.adminPasswordPolicies.passwordPolicyInUse'));
          break;
        default:
          Toast.error(t('notifications.adminPasswordPolicies.errorOperation'));
          break;
      }
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminPasswordPolicies.windows.deletePasswordPolicy')}
      okButtonText={t('pages.adminPasswordPolicies.windows.completelyDelete')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.adminPasswordPolicies.windows.deletePasswordPolicyContent')}</Row>
      <Row>
        <Strong>{passwordPolicy.name}</Strong>
      </Row>
      <Row>
        <InfoBox type="warning">{t('pages.adminPasswordPolicies.windows.cannotBeUndone')}</InfoBox>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default AdminDeletePasswordPolicyWindow;
