import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

export type InfoBoxType = 'error' | 'info' | 'warning' | 'success' | 'outlinedInfo';

type InfoBoxProps = {
  type?: InfoBoxType;
  fullWidth?: boolean;
  style?: CSSProperties;
  className?: string;
};

const getStyledBox = (type: InfoBoxType) => {
  switch (type) {
    case 'success':
      return SuccessBox;

    case 'error':
      return ErrorBox;

    case 'warning':
      return WarningBox;

    case 'info':
      return InfoBoxStyled;

    case 'outlinedInfo':
      return OutlinedInfoBoxStyled;
  }
};

const InfoBox: React.FC<InfoBoxProps> = ({ children, type = 'error', fullWidth, style, className }) => {
  const Component = getStyledBox(type);

  return <Component>{children}</Component>;
};

const Box = withTheme(styled.div`
  border: 1px solid #ccc;
  padding: 1rem;
  font-size: 0.9rem;
  border-radius: 6px;
  color: #fff;
`);

const SuccessBox = withTheme(styled(Box)`
  background: ${(props) => props.theme.palette.success.main};
`);

const ErrorBox = withTheme(styled(Box)`
  background: ${(props) => props.theme.palette.error.main};
`);

const WarningBox = withTheme(styled(Box)`
  background: ${(props) => props.theme.palette.warning.main};
`);

const InfoBoxStyled = withTheme(styled(Box)`
  background: ${(props) => props.theme.palette.info.main};
`);

const OutlinedInfoBoxStyled = withTheme(styled(Box)`
  border: 1px solid #5458711a;
  color: ${(props) => props.theme.palette.primary.main};

  svg {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`);

export default InfoBox;
