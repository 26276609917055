import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { CreateMailingListValues, IndexMailingListsRequestParams, UpdateMailingListValues } from './types';

export const index = (settings: RequestSettings<IndexMailingListsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/mailing_list?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const getAll = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/mailing_list/all'
  }
});

export const create = (settings: RequestSettings<{}, CreateMailingListValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/mailing_list'
  },
  payload: settings.requestPayload
});

export const update = (settings: RequestSettings<{}, UpdateMailingListValues>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/mailing_list/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteMailingList = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/mailing_list/:id'
  },
  params: settings.requestParams
});

export const setWebsiteFormDefault = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/mailing_list/:id/set_website_default'
  },
  params: settings.requestParams
});

export const getTestMailingList = (settings: RequestSettings<{ mailingListId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/mailing_list/:mailingListId/get_test_list'
  },
  params: settings.requestParams
});

export const reSynchronize = (settings: RequestSettings<{ mailingListId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/mailing_list/:mailingListId/sync_moosend_subscribers'
  },
  params: settings.requestParams
});

export const downloadCsv = (settings: RequestSettings<{ mailingListId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/mailing_list/:mailingListId/download_csv'
  },
  params: settings.requestParams
});
