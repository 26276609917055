import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import { createNetworkErrorObject, formatTableTimestamp, useTypedSelector } from '../../../../utils';
import { usePaginationWatch } from '../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../Shared/DataTable/useTableSearchWatch';
import { PaginationStoreModule } from '../../../../store/Pagination/types';
import DataTable from '../../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../../Shared/DataTable/TableActionButton/TableActionButton';
import { TableSearchStoreModule } from '../../../../store/TableSearch/types';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import AdminCreateTemplateWindow from './Windows/AdminCreatePrintTemplateWindow';
import AdminUpdateTemplateWindow from './Windows/AdminUpdatePrintTemplateWindow';
import AdminDeleteTemplateWindow from './Windows/AdminDeletePrintTemplateWindow';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { adminPrintTemplatesOperations } from '../../../../store/AdminPrintTemplates';
import { AdminPrintTemplate } from '../../../../store/AdminPrintTemplates/types';
import CloudUpload from '@material-ui/icons/CloudUpload';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Toast from '../../../Shared/Toast/Toast';
import { useDispatch } from 'react-redux';
import AdminKeywordsList from '../../../Shared/Tags/AdminKeywordsList';
import { AdminKeyword, AdminKeywordGroup } from '../../../../store/AdminKeywords/types';
import { adminKeywordsOperations } from '../../../../store/AdminKeywords';
import { hasPermission, isSystemOwner } from '../../../../utils/permissions';
import TemplateThumbnail from '../../../Shared/Thumbnail/TemplateThumbnail';
import { TemplateTypeName } from '../../../../store/Templates/types';

type AdminPrintTemplatesPageProps = {};

const AdminPrintTemplatesPage: FunctionComponent<AdminPrintTemplatesPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const themeUploaderRef = useRef<HTMLInputElement | null>(null);
  const thumbnailUploaderRef = useRef<HTMLInputElement | null>(null);
  const authState = useTypedSelector((state) => state.auth);
  const templates = useTypedSelector((state) => state.adminPrintTemplates.templates);
  const isFailed = useTypedSelector((state) => state.adminPrintTemplates.index.isFailed);
  const isLoading = useTypedSelector((state) => state.adminPrintTemplates.index.isLoading);
  const pagination = useTypedSelector((state) => state.adminPrintTemplates.pagination);
  const tableSearch = useTypedSelector((state) => state.adminPrintTemplates.tableSearch);
  const allKeywords = useTypedSelector((state) => state.adminKeywords.all);

  useEffect(() => {
    const fetchKeywords = async () => {
      await dispatch(adminKeywordsOperations.getAllKeywords());
    };
    fetchKeywords();
  }, []);

  usePaginationWatch(pagination, [adminPrintTemplatesOperations.index]);
  useTableSearchWatch<AdminPrintTemplate>(tableSearch, [adminPrintTemplatesOperations.index]);

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [activeTemplate, setActiveTemplate] = useState<AdminPrintTemplate | null>(null);
  const [createTemplateWindowOpen, onCreateTemplateWindowOpen, onCreateTemplateWindowClose] = useOpenHandler();
  const [updateTemplateWindowOpen, onUpdateTemplateWindowOpen, onUpdateTemplateWindowClose] = useOpenHandler();
  const [deleteTemplateWindowOpen, onDeleteTemplateWindowOpen, onDeleteTemplateWindowClose] = useOpenHandler();

  const getActionButtons = () => {
    if (hasPermission(authState.role, ['templatesAdd'])) {
      return [
        {
          label: t('pages.adminPrintTemplates.createTemplate'),
          icon: <AddIcon />,
          onClick: onCreateTemplateWindowOpen
        }
      ];
    }

    return [];
  };

  const downloadTemplatePackage = async (template: AdminPrintTemplate) => {
    await adminPrintTemplatesOperations.downloadPrintTemplateZip(template.id);
  };

  const uploadThemePackage = async (e: ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (activeTemplate && e && e.target && e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      try {
        await adminPrintTemplatesOperations.uploadPrintTemplateZip(file, activeTemplate, (progress) =>
          setUploadProgress(progress)
        );
        Toast.success(t('notifications.adminPrintTemplates.uploadSuccessful'));

        await dispatch(adminPrintTemplatesOperations.index());

        if (themeUploaderRef.current) {
          themeUploaderRef.current.value = '';
        }
      } catch (err) {
        if (themeUploaderRef.current) {
          themeUploaderRef.current.value = '';
        }
        Toast.error(
          t('notifications.adminPrintTemplates.uploadError', { rawError: `(${createNetworkErrorObject(err).message})` })
        );
      }
    }
  };

  const uploadThumbnail = async (e: ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (activeTemplate && e && e.target && e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      try {
        await adminPrintTemplatesOperations.uploadPrintTemplateThumbnail(file, activeTemplate, (progress) =>
          setUploadProgress(progress)
        );
        Toast.success(t('notifications.adminPrintTemplates.uploadThumbnailSuccessful'));

        await dispatch(adminPrintTemplatesOperations.index());

        if (thumbnailUploaderRef.current) {
          thumbnailUploaderRef.current.value = '';
        }
      } catch (err) {
        if (thumbnailUploaderRef.current) {
          thumbnailUploaderRef.current.value = '';
        }
        Toast.error(t('notifications.adminPrintTemplates.uploadThumbnailError'));
      }
    }
  };

  const onAddKeywordToTemplate = useCallback((template: AdminPrintTemplate, keyword: AdminKeyword) => {
    dispatch(adminPrintTemplatesOperations.addKeywordToPrintTemplate(template, keyword));
  }, []);

  const onAddKeywordGroupToTemplate = useCallback((template: AdminPrintTemplate, group: AdminKeywordGroup) => {
    dispatch(adminPrintTemplatesOperations.addKeywordGroupToPrintTemplate(template, group));
  }, []);

  const onDeleteKeywordFromTemplate = useCallback((template: AdminPrintTemplate, keyword: AdminKeyword) => {
    dispatch(adminPrintTemplatesOperations.removeKeywordFromPrintTemplate(template, keyword));
  }, []);

  const onDeleteKeywordGroupFromTemplate = useCallback((template: AdminPrintTemplate, group: AdminKeywordGroup) => {
    dispatch(adminPrintTemplatesOperations.removeKeywordGroupFromPrintTemplate(template, group));
  }, []);

  return (
    <PageContainer>
      <PageHeader title={t('pages.adminPrintTemplates.title')} rightActionButtons={getActionButtons()} />
      <PageContent>
        <DataTable<AdminPrintTemplate, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'thumbnailUrl',
              render: (template) => (
                <TemplateThumbnail
                  key={`${template.id}-${template.thumbnailUrl}`}
                  thumbnailUrl={template.thumbnailUrl}
                  width="60px"
                  height="80px"
                />
              )
            },
            {
              name: 'name',
              sortable: true,
              render: (template) => (
                <>
                  <div>{template.name}</div>
                  {activeTemplate && activeTemplate.id === template.id && uploadProgress > 0 && uploadProgress !== 100 && (
                    <div>
                      ({t('common.uploading')} {uploadProgress}%)
                    </div>
                  )}
                </>
              )
            },
            {
              name: 'actions',
              render: (template) => (
                <TableActionButtonsContainer>
                  <TableActionButton
                    icon={<EditIcon />}
                    tooltip={t('pages.adminPrintTemplates.table.buttons.edit')}
                    ButtonProps={{
                      disabled: !hasPermission(authState.role, ['templatesAdd']),
                      onClick: () => {
                        setActiveTemplate(template);
                        onUpdateTemplateWindowOpen();
                      }
                    }}
                  />
                  <TableActionButton
                    icon={<DeleteIcon />}
                    tooltip={t('pages.adminPrintTemplates.table.buttons.delete')}
                    ButtonProps={{
                      disabled: !isSystemOwner(authState),
                      onClick: () => {
                        setActiveTemplate(template);
                        onDeleteTemplateWindowOpen();
                      }
                    }}
                  />
                  <>
                    <TableActionButton
                      icon={<CloudUpload />}
                      tooltip={t('pages.adminPrintTemplates.table.buttons.upload')}
                      ButtonProps={{
                        disabled: !hasPermission(authState.role, ['templatesUpload']),
                        onClick: () => {
                          setActiveTemplate(template);
                          themeUploaderRef.current && themeUploaderRef.current.click();
                        }
                      }}
                    />
                    <input
                      type="file"
                      id="themeUploader"
                      accept=".zip,.html"
                      ref={themeUploaderRef}
                      style={{ display: 'none' }}
                      onChange={uploadThemePackage}
                    />
                  </>
                  {template.uploaded && (
                    <TableActionButton
                      icon={<CloudDownload />}
                      tooltip={t('pages.adminPrintTemplates.table.buttons.download')}
                      ButtonProps={{
                        disabled: !hasPermission(authState.role, ['templatesDownload']),
                        onClick: () => downloadTemplatePackage(template)
                      }}
                    />
                  )}
                  <>
                    <TableActionButton
                      icon={<WallpaperIcon />}
                      tooltip={t('pages.adminPrintTemplates.table.buttons.uploadThumbnail')}
                      ButtonProps={{
                        disabled: !hasPermission(authState.role, ['templatesAdd']),
                        onClick: () => {
                          setActiveTemplate(template);
                          thumbnailUploaderRef.current && thumbnailUploaderRef.current.click();
                        }
                      }}
                    />
                    <input
                      type="file"
                      id="thumbnailUploader"
                      accept=".jpg,.jpeg,.png,.gif"
                      ref={thumbnailUploaderRef}
                      style={{ display: 'none' }}
                      onChange={uploadThumbnail}
                    />
                  </>
                </TableActionButtonsContainer>
              )
            },
            {
              name: 'keywords',
              render: (template) => (
                <AdminKeywordsList
                  readOnly={!hasPermission(authState.role, ['keywordsAssociate'])}
                  allKeywords={allKeywords}
                  keywords={template.keywords}
                  groups={template.keywordGroups}
                  onDelete={
                    hasPermission(authState.role, ['keywordsAssociate'])
                      ? (keyword: AdminKeyword) => onDeleteKeywordFromTemplate(template, keyword)
                      : null
                  }
                  onDeleteGroup={
                    hasPermission(authState.role, ['keywordsAssociate'])
                      ? (group: AdminKeywordGroup) => onDeleteKeywordGroupFromTemplate(template, group)
                      : null
                  }
                  onAdd={(keyword: AdminKeyword) => onAddKeywordToTemplate(template, keyword)}
                  onAddGroup={(group: AdminKeywordGroup) => onAddKeywordGroupToTemplate(template, group)}
                />
              )
            },
            {
              name: 'description',
              sortable: true,
              render: (template) => <>{template.description}</>
            },
            {
              name: 'type',
              isTranslate: true,
              sortable: true
            },
            {
              name: 'designProject',
              sortable: true,
              render: (template) => <>{template.designProject ? t('common.yes') : t('common.no')}</>
            },
            {
              name: 'updatedAt',
              render: (template) => <>{formatTableTimestamp(template.updatedAt)}</>,
              sortable: true
            },
            {
              name: 'uploadedAt',
              render: (template) => <>{template.uploadedAt ? formatTableTimestamp(template.uploadedAt) : 'N/A'}</>,
              sortable: true
            }
          ]}
          tPath={'pages.adminPrintTemplates.table'}
          data={templates}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.ADMIN_PRINT_TEMPLATES
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.ADMIN_PRINT_TEMPLATES
            // onPageChange: () => {},
            // onPerChange: () => {}
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>

      <AdminCreateTemplateWindow
        open={createTemplateWindowOpen}
        onCloseClick={onCreateTemplateWindowClose}
        fullScreenOnMobile
      />
      <AdminUpdateTemplateWindow
        template={activeTemplate}
        open={updateTemplateWindowOpen}
        onCloseClick={onUpdateTemplateWindowClose}
        fullScreenOnMobile
      />
      <AdminDeleteTemplateWindow
        template={activeTemplate}
        open={deleteTemplateWindowOpen}
        onCloseClick={onDeleteTemplateWindowClose}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

export default AdminPrintTemplatesPage;
