import React, { FunctionComponent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { StoryChannel, StoryChannelType, nonSocialStoryChannelTypes } from '../../../../store/Stories/types';
import { storiesOperations } from '../../../../store/Stories';
import Toast from '../../../Shared/Toast/Toast';
import { AdminCheckboxInput, AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import ModalFormWindow from '../../../Shared/Window/ModalFormWindow';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Box, Grid, useTheme } from '@material-ui/core';
import { createNetworkErrorObject } from '../../../../utils';
import moment from 'moment';
import { FormikProps } from 'formik';
import { AdminInputLabel } from '../../Admin/AdminStyledComponents';
import {
  WordpressCategory,
  WordpressIntegration,
  WordpressIntegrationForm
} from '../../../../store/MyOrganization/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import { handleWordpressIntegrationError } from '../../../../utils/errorCodeToToast';
import { NoResultsFound } from '../../PageStyledComponents';
import Loader from '../../../Shared/Loading/Loader';
import WordPressIntegrationForm from '../Partials/WordPressIntegrationForm';
import WordpressIntegrationsList from '../../MyOrganization/Partials/Integrations/WordpressIntegrationsList';
import { getChannelIcon } from '../Partials/Channels';

type PublishSelectedChannelsWindowProps = {
  open: boolean;
  channels: StoryChannel[];
  onCloseClick: () => void;
  onSubmit: (publishedChannels: StoryChannel[]) => void;
  fullScreenOnMobile: boolean;
};

type ChannelFormValues = {
  publishDate: string;
  publishTime: string;
  publishNow: boolean;
};

type NonSocialChannelFormValues = {
  publishDate: string;
  publishTime: string;
  publishNow: boolean;
  wordpressIntegrations: string[];
};

type FormValues = {
  channels: {
    [key: string]: ChannelFormValues;
  };
  nonSocialChannels?: NonSocialChannelFormValues;
};

const PublishSelectedChannelsWindow: FunctionComponent<PublishSelectedChannelsWindowProps> = ({
  onCloseClick,
  onSubmit,
  open,
  channels
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [publishing, setPublishing] = useState(false);
  const [wordpressIntegrations, setWordpressIntegrations] = useState<WordpressIntegration[]>([]);
  const [selectedWordpressIntegrations, setSelectedWordpressIntegrations] = useState<string[]>([]);

  const [selectedCategories, setSelectedCategories] = useState<{ [integrationId: string]: WordpressCategory[] }>({});

  const handleCategorySelect = (integrationId: string, categories: WordpressCategory[]) => {
    setSelectedCategories((prevSelectedCategories) => ({
      ...prevSelectedCategories,
      [integrationId]: categories
    }));
  };

  const fetchIntegrations = async () => {
    setLoading(true);
    setError(false);
    try {
      const wordpressIntegrations = await myOrganizationOperations.getWordpressIntegrations({ withCategories: true });
      setWordpressIntegrations(wordpressIntegrations.data);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const createIntegration = async (formData: WordpressIntegrationForm) => {
    try {
      await myOrganizationOperations.createWordpressIntegration(formData);
      await fetchIntegrations();
      Toast.success(t('notifications.story.wordpressIntegrationCreated'));
    } catch (e) {
      handleWordpressIntegrationError(e, t);
    }
  };

  useEffect(() => {
    if (open && channels.find((ch) => ch.type === StoryChannelType.BLOG)) {
      setPublishing(false);
      fetchIntegrations();
    }
  }, [open]);

  const initialValues: FormValues = {
    channels: channels.reduce((acc, channel) => {
      if (!nonSocialStoryChannelTypes.includes(channel.type)) {
        acc[channel.type] = {
          publishDate: '',
          publishTime: '',
          publishNow: false
        };
      }
      return acc;
    }, {} as { [key: string]: ChannelFormValues }),
    ...(channels.some((channel) => channel.type === StoryChannelType.BLOG) && {
      nonSocialChannels: {
        publishDate: '',
        publishTime: '',
        publishNow: false,
        wordpressIntegrations: []
      }
    })
  };

  const validationSchema = Yup.object().shape({
    channels: Yup.object().shape(
      channels.reduce((acc, channel) => {
        if (!nonSocialStoryChannelTypes.includes(channel.type)) {
          (acc as any)[channel.type] = Yup.object().shape({
            publishDate: Yup.string().when('publishNow', {
              is: false,
              then: Yup.string().required('Publish Date is required'),
              otherwise: Yup.string().notRequired()
            }),
            publishTime: Yup.string().when('publishNow', {
              is: false,
              then: Yup.string().required('Publish Time is required'),
              otherwise: Yup.string().notRequired()
            }),
            publishNow: Yup.boolean().required()
          });
        }
        return acc;
      }, {} as { [key: string]: Yup.ObjectSchema<ChannelFormValues> })
    ),
    ...(channels.some((channel) => channel.type === StoryChannelType.BLOG) && {
      nonSocialChannels: Yup.object().shape({
        publishDate: Yup.string().when('publishNow', {
          is: false,
          then: Yup.string().required('Publish Date is required'),
          otherwise: Yup.string().nullable()
        }),
        publishTime: Yup.string().when('publishNow', {
          is: false,
          then: Yup.string().required('Publish Time is required'),
          otherwise: Yup.string().nullable()
        }),
        publishNow: Yup.boolean().required(),
        wordpressIntegrations: Yup.array()
      })
    })
  });

  type NetworkError = {
    message: string;
  };

  const errorMessages: { [key: string]: string } = {
    social_account_not_connected: 'notifications.story.channelPublishErrorSocialAccountNotConnected',
    post_body_empty: 'notifications.story.channelPublishErrorPostBodyEmpty',
    already_published: 'notifications.story.channelPublishErrorAlreadyPublished',
    bad_configuration: 'notifications.story.channelWordpressBadConfiguration'
  };

  const handlePublishError = (e: Error) => {
    const networkError = createNetworkErrorObject(e) as NetworkError;

    const messageKey =
      errorMessages[networkError.message as keyof typeof errorMessages] || 'notifications.story.channelPublishError';
    Toast.error(t(messageKey));
  };

  const onPublishSocial = async (channel: StoryChannel, publishDate: string, publishTime: string) => {
    try {
      const publishDateAndTime = new Date(`${publishDate}T${publishTime}`);
      await storiesOperations.publishChannel(channel.storyId, channel.id, {
        publishDate: publishDate,
        publishTime: publishDateAndTime
      });
    } catch (e) {
      handlePublishError(e as Error);
    }
  };

  const onPublishBlog = async (
    channel: StoryChannel,
    publishDate: string,
    publishTime: string,
    selectedWordpressIntegrations: string[]
  ) => {
    try {
      const publishDateAndTime = new Date(`${publishDate}T${publishTime}`);
      const wordpressIntegrations = selectedWordpressIntegrations.map((id) => ({
        id,
        categories: selectedCategories[id]?.map((cat) => cat.id) || []
      }));
      await storiesOperations.publishChannel(channel.storyId, channel.id, {
        publishDate: publishDate,
        publishTime: publishDateAndTime,
        wordpressIntegrations
      });
    } catch (e) {
      handlePublishError(e as Error);
    }
  };

  const onPublish = async (values: FormValues) => {
    setPublishing(true);
    const publishedChannels: StoryChannel[] = [];

    const handlePublish = async (
      channel: StoryChannel,
      publishNow: boolean,
      publishDate: string,
      publishTime: string,
      publishCallback: (channel: StoryChannel, publishDate: string, publishTime: string) => Promise<void>
    ) => {
      const now = moment();
      const publishDateTime = {
        publishDate: publishNow ? now.format('YYYY-MM-DD') : publishDate,
        publishTime: publishNow ? now.format('HH:mm') : publishTime
      };
      await publishCallback(channel, publishDateTime.publishDate, publishDateTime.publishTime);
    };

    for (const [channelType, channelFormValues] of Object.entries(values.channels)) {
      const channel = channels.find((ch) => ch.type.toString() === channelType);
      if (channel) {
        await handlePublish(
          channel,
          channelFormValues.publishNow,
          channelFormValues.publishDate,
          channelFormValues.publishTime,
          onPublishSocial
        );
        publishedChannels.push(channel);
      }
    }

    if (values.nonSocialChannels) {
      const channel = channels.find((ch) => ch.type === StoryChannelType.BLOG);
      if (channel) {
        await handlePublish(
          channel,
          values.nonSocialChannels.publishNow,
          values.nonSocialChannels.publishDate,
          values.nonSocialChannels.publishTime,
          async (channel, publishDate, publishTime) => {
            await onPublishBlog(channel, publishDate, publishTime, selectedWordpressIntegrations);
          }
        );
        publishedChannels.push(channel);
      }
    }

    onCloseClick();
    onSubmit(publishedChannels);
    setPublishing(false);
    Toast.success(t('notifications.story.storyChannelsScheduled'));
  };

  const formInputs = (props: FormikProps<FormValues>) => {
    const { values } = props;

    return (
      <>
        {channels
          .filter((ch) => !nonSocialStoryChannelTypes.includes(ch.type))
          .map((channel) => {
            const channelValues = values.channels[channel.type];
            return (
              <Grid
                container
                key={`channel-publish-${channel.id}`}
                style={{
                  marginBottom: 10,
                  paddingBottom: 5,
                  borderBottom: '1px solid #ccc'
                }}
              >
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" fontWeight="bold">
                      {getChannelIcon(channel.type, theme)}&nbsp;{t(`pages.story.channels.${channel.type}`)}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <AdminInputLabel>{t('pages.story.windows.publish.publishNow')}</AdminInputLabel>
                      <AdminCheckboxInput t={t} section="story" name={`channels.${channel.type}.publishNow`} />
                    </Grid>
                    <Grid item xs={1} justify="center">
                      <AdminInputLabel>{t('common.or')}</AdminInputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <AdminTextInput
                        t={t}
                        section="story"
                        name={`channels.${channel.type}.publishDate`}
                        type="date"
                        disabled={channelValues.publishNow}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <AdminTextInput
                        t={t}
                        section="story"
                        name={`channels.${channel.type}.publishTime`}
                        type="time"
                        inputProps={{
                          step: 300
                        }}
                        disabled={channelValues.publishNow}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        {channels.filter((ch) => ch.type === StoryChannelType.BLOG).length === 1 && (
          <>
            {loading && <Loader />}
            {error && <NoResultsFound>{t('pages.story.windows.wordpressIntegration.error')}</NoResultsFound>}
            {!loading && !error && (
              <Grid container>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" fontWeight="bold">
                      {getChannelIcon(StoryChannelType.BLOG, theme)}&nbsp;
                      {t(`pages.story.channels.${StoryChannelType.BLOG}`)}
                    </Box>
                  </Box>
                </Grid>
                {wordpressIntegrations.length === 0 && <WordPressIntegrationForm onSubmit={createIntegration} />}
                {wordpressIntegrations.length > 0 && values.nonSocialChannels && (
                  <Grid item xs={12}>
                    <div style={{ textAlign: 'center' }}>
                      <WordpressIntegrationsList
                        wordpressIntegrations={wordpressIntegrations}
                        checkboxes
                        categories
                        setSelectedWordpressIntegrations={setSelectedWordpressIntegrations}
                        selectedCategories={selectedCategories}
                        handleCategorySelect={handleCategorySelect}
                      />
                    </div>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <AdminInputLabel>{t('pages.story.windows.publish.publishNow')}</AdminInputLabel>
                        <AdminCheckboxInput t={t} section="story" name={`nonSocialChannels.publishNow`} />
                      </Grid>
                      <Grid item xs={1} justify="center">
                        <AdminInputLabel>{t('common.or')}</AdminInputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <AdminTextInput
                          t={t}
                          section="story"
                          name="nonSocialChannels.publishDate"
                          type="date"
                          disabled={values.nonSocialChannels.publishNow}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <AdminTextInput
                          t={t}
                          section="story"
                          name="nonSocialChannels.publishTime"
                          type="time"
                          inputProps={{
                            step: 300
                          }}
                          disabled={values.nonSocialChannels.publishNow}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <ModalFormWindow
      open={open}
      okButtonText={publishing ? t('pages.story.windows.publish.publishing') : t('common.publish')}
      forceDisableSubmit={publishing}
      header={t('pages.story.windows.publishSelected.header')}
      onCloseClick={onCloseClick}
      onSubmit={onPublish}
      formInputs={formInputs}
      initialValues={initialValues}
      validationSchema={validationSchema}
      maxWidth="lg"
    />
  );
};

export default PublishSelectedChannelsWindow;
