import { createOrderArray, httpRequest } from '../../../utils';
import {
  CreateCreditTransactionRequestDto,
  DoAppCreditTransactionsIndexResponse,
  DoAppCreditTransactionStatus
} from './types';
import { doAppCreditTransactionsActions, doAppCreditTransactionsRequests } from './index';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../rootReducer';

export const indexRaw = (page: number, per: number, q: string | null, order: any) => {
  return httpRequest.runRequest<DoAppCreditTransactionsIndexResponse>(
    doAppCreditTransactionsRequests.index({
      requestParams: {
        page: page,
        per: per,
        q: q,
        order: createOrderArray(order)
      }
    })
  );
};

export const indexMineRaw = (
  page: number,
  per: number,
  q: string | null,
  order: any,
  status?: DoAppCreditTransactionStatus
) => {
  return httpRequest.runRequest<DoAppCreditTransactionsIndexResponse>(
    doAppCreditTransactionsRequests.indexMine({
      requestParams: {
        page: page,
        per: per,
        q: q,
        order: createOrderArray(order),
        status
      }
    })
  );
};

export const indexMerchantCreditTransactions = (status?: DoAppCreditTransactionStatus) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  const { doAppMerchantCreditTransactions } = getState();

  try {
    dispatch(doAppCreditTransactionsActions.doAppCreditTransactionsIndexBegin());

    const response = await indexMineRaw(
      doAppMerchantCreditTransactions.pagination.page,
      doAppMerchantCreditTransactions.pagination.per,
      doAppMerchantCreditTransactions.pagination.q,
      doAppMerchantCreditTransactions.tableSearch.order,
      status
    );

    dispatch(doAppCreditTransactionsActions.doAppCreditTransactionsIndexSuccess(response));
  } catch (e) {
    dispatch(doAppCreditTransactionsActions.doAppCreditTransactionsIndexFailed(e));
  }
};

export const createTransaction = async (data: CreateCreditTransactionRequestDto) => {
  return httpRequest.runRequest(
    doAppCreditTransactionsRequests.create({
      requestPayload: data
    })
  );
};

export const reject = (id: string, comment: string) => async () => {
  return await httpRequest.runRequest<void>(
    doAppCreditTransactionsRequests.reject({
      requestPayload: { comment },
      requestParams: { id }
    })
  );
};

export const unReject = (id: string) => async () => {
  return await httpRequest.runRequest<void>(
    doAppCreditTransactionsRequests.unReject({
      requestParams: { id }
    })
  );
};
