import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminUser } from '../../../../../store/AdminUsers/types';
import { adminUsersOperations } from '../../../../../store/AdminUsers';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import Toast from '../../../../Shared/Toast/Toast';

type AdminToggleBlockUserWindowProps = {
  open: boolean;
  user: AdminUser | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminToggleBlockUserWindow: FunctionComponent<AdminToggleBlockUserWindowProps> = ({
  user,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!user) return null;

  const onSubmit = async () => {
    try {
      if (user.blocked) {
        await dispatch(adminUsersOperations.unblockUser(user.id));
      } else {
        await dispatch(adminUsersOperations.blockUser(user.id));
      }

      await dispatch(adminUsersOperations.index());

      onCloseClick();
      Toast.success(t('notifications.adminUsers.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminUsers.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={user.blocked ? t('pages.adminUsers.windows.unblockUser') : t('pages.adminUsers.windows.blockUser')}
      okButtonText={user.blocked ? t('common.unblock') : t('common.block')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>
        {t('pages.adminUsers.windows.toggleBlockUserContent', {
          block: user.blocked ? t('common.unblock').toLocaleLowerCase() : t('common.block').toLocaleLowerCase()
        })}{' '}
        <Strong>{user.email}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default AdminToggleBlockUserWindow;
