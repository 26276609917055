import React, { FunctionComponent, useEffect, useState } from 'react';
import * as qs from 'query-string';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme
} from '@material-ui/core';
import { myOrganizationOperations } from '../../../store/MyOrganization';
import MonthCalendarElement, {
  getCalendarStyle
} from '../Editors/EmailDndEditor/Partials/Components/Utils/MonthCalendarElement';
import {
  MonthCalendarStyle,
  MonthCalendarStyles,
  OrganizationMonthCalendar,
  OrganizationMonthCalendarDay
} from '../../../store/MyOrganization/types';
import moment from 'moment';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import useOpenHandler from '../../../hooks/useOpenHandler';
import CalendarSettingsWindow from './Windows/CalendarSettingsWindow';
import PaletteOutlinedIcon from '@material-ui/icons/PaletteOutlined';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import linksConstants from '../../../config/app/linksConstants';
import styled from 'styled-components';
import _ from 'lodash';

type PublicOrganizationCalendarPageProps = {
  organizationId?: string;
};

const PublicOrganizationCalendarPage: FunctionComponent<PublicOrganizationCalendarPageProps> = ({ organizationId }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const parsedSearch = qs.parse(window.location.search);
  const [viewType, setViewType] = useState<'month' | 'week'>(
    localStorage.getItem('sc-calendar-view-type') === 'week' ? 'week' : 'month'
  );
  const [organizationCalendar, setOrganizationCalendar] = useState<OrganizationMonthCalendar | null>(null);
  const [selectedStyle, setSelectedStyle] = useState<MonthCalendarStyles>(MonthCalendarStyles.MAIN);
  const [calendarRows, setCalendarRows] = useState<OrganizationMonthCalendarDay[][]>([]);
  const [calendarStyles, setCalendarStyles] = useState<MonthCalendarStyle>(
    getCalendarStyle(MonthCalendarStyles.MAIN, useTheme())
  );

  const [calendarSettingsWindowOpen, onCalendarSettingsWindowOpen, onCalendarSettingsWindowClose] = useOpenHandler();

  const now = moment();

  const fetchOrganizationCalendar = async (date: string) => {
    if (organizationId || parsedSearch.oid) {
      const response = await myOrganizationOperations.getOrganizationMonthCalendar(
        organizationId || (parsedSearch.oid as string),
        date,
        (parsedSearch.type as 'month' | 'week') || viewType
      );
      setOrganizationCalendar(response);
      if (response.style) {
        const style = _.omit(response.style, ['id', 'createdAt', 'updatedAt', 'style']);
        setCalendarStyles(style);
        setSelectedStyle(response.style.style);
      }

      const daysCount = response.days.length;
      const rows: OrganizationMonthCalendarDay[][] = [];
      for (let i = 0; i < daysCount / 7; i++) {
        rows.push(response.days.splice(0, 7));
      }
      setCalendarRows(rows);
    }
  };

  useEffect(() => {
    fetchOrganizationCalendar(now.format('YYYY-MM-DD'));
  }, [viewType]);

  return (
    <Wrapper className="main-article" color={parsedSearch.color as string}>
      <Box display="flex" alignItems="center" mb={1}>
        <IconButton
          aria-label="previous"
          onClick={async () => {
            if (organizationCalendar) {
              const prevMonth = moment(organizationCalendar.date).subtract(1, viewType);
              await fetchOrganizationCalendar(prevMonth.format('YYYY-MM-DD'));
            }
          }}
        >
          <KeyboardArrowLeftIcon fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="next"
          onClick={async () => {
            if (organizationCalendar) {
              const nextMonth = moment(organizationCalendar.date).add(1, viewType);
              await fetchOrganizationCalendar(nextMonth.format('YYYY-MM-DD'));
            }
          }}
        >
          <KeyboardArrowRightIcon fontSize="small" />
        </IconButton>
        {organizationCalendar && (
          <Typography variant="h6" style={{ marginLeft: 10 }}>
            {organizationCalendar.monthName} {organizationCalendar.year}
          </Typography>
        )}
        {location.pathname === linksConstants.CALENDAR && (
          <Box display="flex" style={{ marginLeft: 'auto' }}>
            <FormControl variant="outlined" size="small">
              <InputLabel id="style-select-label">{t('pages.emailEditor.monthCalendar.type')}</InputLabel>
              <Select
                labelId="style-select-label"
                id="style-select"
                value={viewType}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const calendarViewType = event.target.value as 'month' | 'week';
                  localStorage.setItem('sc-calendar-view-type', calendarViewType);
                  setViewType(calendarViewType);
                }}
                label={t('pages.emailEditor.monthCalendar.type')}
              >
                <MenuItem value="week">{t(`pages.emailEditor.monthCalendar.types.week`)}</MenuItem>
                <MenuItem value="month">{t(`pages.emailEditor.monthCalendar.types.month`)}</MenuItem>
              </Select>
            </FormControl>

            <Button
              onClick={onCalendarSettingsWindowOpen}
              size="small"
              variant="outlined"
              color="primary"
              style={{ marginLeft: 5 }}
              startIcon={<PaletteOutlinedIcon />}
            >
              {t('pages.calendar.settings')}
            </Button>
          </Box>
        )}
      </Box>
      <div>
        {organizationCalendar && (
          <MonthCalendarElement
            calendarRows={calendarRows}
            calendarEvents={organizationCalendar.events}
            styles={calendarStyles}
          />
        )}
      </div>
      <CalendarSettingsWindow
        onCloseClick={onCalendarSettingsWindowClose}
        open={calendarSettingsWindowOpen}
        styles={calendarStyles}
        setStyles={setCalendarStyles}
        selectedStyle={selectedStyle}
        setSelectedStyle={setSelectedStyle}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ color?: string }>`
  background-color: ${({ color }) => (color ? `#${color}` : '#fff')};
`;

export default PublicOrganizationCalendarPage;
