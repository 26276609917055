import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageContentPaper } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import { Grid } from '@material-ui/core';
import PublicOrganizationCalendarPage from './PublicOrganizationCalendarPage';
import { useTypedSelector } from '../../../utils';
import useOpenHandler from '../../../hooks/useOpenHandler';
import EmbedCalendarWindow from './Windows/EmbedCalendarWindow';

type CalendarPageProps = {};

const CalendarPage: FunctionComponent<CalendarPageProps> = () => {
  const { t } = useTranslation();
  const currentOrganizationId = useTypedSelector((state) => state.auth.organizationId);

  const [embedCodeWindowOpen, onEmbedCodeWindowOpen, onEmbedCodeWindowClose] = useOpenHandler();

  return (
    <PageContainer>
      <PageHeader
        title={t('pages.calendar.title')}
        rightActionButtons={[
          {
            onClick: () => onEmbedCodeWindowOpen(),
            label: t('pages.calendar.embedButton')
          }
        ]}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageContentPaper>
            {currentOrganizationId && <PublicOrganizationCalendarPage organizationId={currentOrganizationId} />}
          </PageContentPaper>
        </Grid>
      </Grid>
      <EmbedCalendarWindow open={embedCodeWindowOpen} onCloseClick={onEmbedCodeWindowClose} />
    </PageContainer>
  );
};

export default CalendarPage;
