import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';
import { GeneratedAiContent, StoryChannel, StoryChannelType } from '../../../../store/Stories/types';
import Loader from '../../../Shared/Loading/Loader';
import { Box, Button } from '@material-ui/core';
import Toast from '../../../Shared/Toast/Toast';
import { storiesOperations } from '../../../../store/Stories';
import GeneratedContentForm from '../Partials/GeneratedContentForm';
import { getPromptValue } from '../Utils/storyUtils';

type GenerateAiContentWindowProps = {
  channelType: StoryChannelType;
  channelData: StoryChannel;
  open: boolean;
  onCloseClick: () => void;
  onCopyToChannelClick: (content: GeneratedAiContent[]) => void;
  fullScreenOnMobile: boolean;
};

const GenerateAiContentWindow: FunctionComponent<GenerateAiContentWindowProps> = ({
  channelType,
  channelData,
  onCloseClick,
  onCopyToChannelClick,
  open
}) => {
  const { t } = useTranslation();
  const [isGenerating, setIsGenerating] = useState<boolean>(true);
  const [generatedContent, setGeneratedContent] = useState<GeneratedAiContent[]>([]);

  useEffect(() => {
    if (open) {
      generateAiContent();
    }
  }, [open]);

  const generateAiContent = async () => {
    setIsGenerating(true);
    try {
      const response = await storiesOperations.generateAiContent(
        getPromptValue(channelData, channelType, t),
        channelType
      );
      setGeneratedContent(response.content);
    } catch (error) {
      Toast.error(t('pages.story.errorGeneratingAiContent'));
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={`${t(`pages.story.channels.${channelType}`)} - ${t('pages.story.generatedContent')}`}
      onCloseClick={onCloseClick}
      hideActionButtons
      maxWidth="md"
    >
      <Row>
        {isGenerating && <Loader />}
        {!isGenerating && (
          <>
            <GeneratedContentForm content={generatedContent} />
            <Box display="flex" alignItems="center" justifyContent="space-between" marginTop={1}>
              <Button color={'secondary'} variant={'outlined'} size="medium" onClick={onCloseClick}>
                {t('common.close')}
              </Button>
              <Button
                color={'secondary'}
                variant={'contained'}
                size="medium"
                onClick={() => {
                  onCopyToChannelClick(generatedContent);
                }}
              >
                {t('pages.story.copyToChannel')}
              </Button>
            </Box>
          </>
        )}
      </Row>
    </ModalConfirmationWindow>
  );
};

export default GenerateAiContentWindow;
