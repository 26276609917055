import * as React from 'react';
import { useContext } from 'react';
import {
  ColumnDefinition,
  CustomRenderColumnDefinition,
  DataTableColumnCellProps,
  ExtendedColumnDefinition,
  TranslateColumnDefinition,
  UrlColumnDefinition
} from './types';
import { BasicColumnCell } from './ColumnCell/BasicColumnCell';
import { UrlColumnCell } from './ColumnCell/UrlColumnCell';
import { CustomRenderColumnCell } from './ColumnCell/CustomRenderColumnCell';
import { TranslateColumnCell } from './ColumnCell/TranslateColumnCell';
import { DataTableContext } from './DataTable';
import { EmptyColumnCell } from './ColumnCell/EmptyColumnCell';

export function isUrlColumnDefinition<E, UCN extends string = never>(
  definition: ColumnDefinition<E, UCN>
): definition is UrlColumnDefinition<E, UCN> {
  return (definition as UrlColumnDefinition<E, UCN>).isUrl;
}

export function isTranslateColumnDefinition<E, UCN extends string = never>(
  definition: ColumnDefinition<E, UCN>
): definition is TranslateColumnDefinition<E, UCN> {
  return (definition as TranslateColumnDefinition<E, UCN>).isTranslate;
}

export function isEmptyColumnDefinition<E, UCN extends string = never>(
  definition: ColumnDefinition<E, UCN>
): definition is Required<ExtendedColumnDefinition<E, UCN>> {
  return (definition as ExtendedColumnDefinition<E, UCN>).isEmpty !== undefined;
}

export function DataTableColumnCell<E = never, UCN extends string = never>({
  definition,
  value,
  style
}: DataTableColumnCellProps<E, UCN>) {
  const { tPath } = useContext(DataTableContext);

  if (isEmptyColumnDefinition(definition)) {
    const { isEmpty } = definition;

    if (isEmpty(value as E)) {
      return <EmptyColumnCell />;
    }
  }

  if (typeof definition === 'string') {
    return <BasicColumnCell<E> value={value as ValueOf<E>} />;
  }

  if (isUrlColumnDefinition(definition)) {
    const { render, url, urlExternal } = definition;
    return <UrlColumnCell<E> url={url} value={value} render={render} urlExternal={urlExternal} />;
  }

  if (isTranslateColumnDefinition(definition)) {
    return <TranslateColumnCell<E> value={value as ValueOf<E>} tPath={definition.tPath || tPath} />;
  }

  const { render } = definition as CustomRenderColumnDefinition<E>;
  return <CustomRenderColumnCell<E> value={value as E} render={render} style={style} />;
}
