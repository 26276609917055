import update from 'immutability-helper';
import { Campaign, CampaignsActionsConstants, CampaignsActionType, CampaignsState } from './types';
import { normalizeCampaigns } from '../helpers';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: CampaignsState = {
  data: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const campaignsPaginationReducer = createPaginationReducer<CampaignsState, Campaign>(PaginationStoreModule.CAMPAIGNS);

const campaignsTableSearchReducer = createTableSearchReducer<Campaign, CampaignsState>(
  TableSearchStoreModule.CAMPAIGNS
);

export default function campaignsReducer(state = initialState, action: WithLogoutAction<CampaignsActionType>) {
  switch (action.type) {
    case CampaignsActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case CampaignsActionsConstants.INDEX_SUCCESS: {
      const data = normalizeCampaigns(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        data: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case CampaignsActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        data: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case CampaignsActionsConstants.UPDATE_CAMPAIGN: {
      const campaign = action.payload.campaign;

      if (campaign && state.data.entities[campaign.id]) {
        return update(state, {
          data: {
            entities: {
              [campaign.id]: { $set: campaign }
            }
          }
        });
      }

      return state;
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return campaignsTableSearchReducer(campaignsPaginationReducer(state, action), action) as CampaignsState;
    }
  }
}
