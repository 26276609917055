import React from 'react';
import * as Yup from 'yup';
import { SetPasswordFormValues } from '../../../../store/Auth/types';
import Done from '@material-ui/icons/Done';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { useTranslation } from 'react-i18next';
import { PasswordPolicy } from '../../../../store/SystemSettings/types';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

type PasswordPolicyProps = {
  formValues: SetPasswordFormValues;
  passwordValidationSchema: Yup.StringSchema<string>;
  passwordPolicy: PasswordPolicy;
};

type PasswordRules = {
  length: boolean;
  upper: boolean;
  lower: boolean;
  numbers: boolean;
  specialCharacters: boolean;
};

/**
 * This function takes form values from Formik form and a password validation schema from Yup as parameters.
 * Then it creates an object which keys and values represent optional rules that at least 3 need to be passed
 * before a password is accepted
 * @param formValues
 * @param passwordValidationSchema
 * @param passwordPolicy
 */
const createPasswordValidationPassedRulesObject = (
  formValues: SetPasswordFormValues,
  passwordValidationSchema: Yup.StringSchema<string>,
  passwordPolicy: PasswordPolicy
) => {
  const passwordRules: PasswordRules = {
    length: true,
    upper: passwordPolicy.upper,
    lower: passwordPolicy.lower,
    numbers: passwordPolicy.numbers,
    specialCharacters: passwordPolicy.specialCharacters
  };
  try {
    passwordValidationSchema.validateSync(formValues.password, { abortEarly: false });
  } catch (e) {
    Object.keys(passwordRules).forEach((rule) => {
      if (e.errors.includes(rule)) {
        passwordRules[rule as keyof PasswordRules] = false;
      }
    });
  }
  return passwordRules;
};

const PasswordPolicyInfo: React.FC<PasswordPolicyProps> = ({
  formValues,
  passwordValidationSchema,
  passwordPolicy
}) => {
  const passwordRules = createPasswordValidationPassedRulesObject(formValues, passwordValidationSchema, passwordPolicy);
  const { t } = useTranslation();

  const getIconForRule = function (ruleName: keyof PasswordRules) {
    return passwordRules[ruleName] ? <Done /> : <ErrorOutline />;
  };

  const hasAnyRuleApplied = () => {
    return passwordPolicy.upper || passwordPolicy.lower || passwordPolicy.numbers || passwordPolicy.specialCharacters;
  };

  return (
    <Wrapper>
      <Title>{t('passwordPolicy.requiredRules')}</Title>
      <Content>
        <PolicyItem success={passwordRules.length}>
          {getIconForRule('length')}
          {t('passwordPolicy.infoBoxRules.length', { passwordLength: passwordPolicy.passwordLength })}
        </PolicyItem>
        {hasAnyRuleApplied() && <ShouldAlsoContain>{t('passwordPolicy.shouldContain')}</ShouldAlsoContain>}
        <Content>
          {passwordPolicy.upper && (
            <PolicyItem success={passwordRules.upper}>
              {getIconForRule('upper')}
              {t('passwordPolicy.infoBoxRules.upper')}
            </PolicyItem>
          )}
          {passwordPolicy.lower && (
            <PolicyItem success={passwordRules.lower}>
              {getIconForRule('lower')}
              {t('passwordPolicy.infoBoxRules.lower')}
            </PolicyItem>
          )}
          {passwordPolicy.numbers && (
            <PolicyItem success={passwordRules.numbers}>
              {getIconForRule('numbers')}
              {t('passwordPolicy.infoBoxRules.numbers')}
            </PolicyItem>
          )}
          {passwordPolicy.specialCharacters && (
            <PolicyItem success={passwordRules.specialCharacters}>
              {getIconForRule('specialCharacters')}
              {t('passwordPolicy.infoBoxRules.specialCharacters')}
            </PolicyItem>
          )}
        </Content>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #fff;
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid #e6dbda;
  font-size: 0.9rem;
`;
const Title = styled.div`
  font-weight: bold;
`;
const Content = styled.div`
  padding: 1rem 1rem 0 1rem;

  svg {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
  }
`;
const PolicyItem = withTheme(styled.div<{ success: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ success, theme }) => (success ? theme.palette.success.main : theme.palette.error.main)};
  padding: 0.2rem 0;
`);
const ShouldAlsoContain = styled.div`
  margin-top: 1rem;
`;

export default PasswordPolicyInfo;
