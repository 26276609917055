import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import Loader from '../../../../Shared/Loading/Loader';
import { DoAppCreditTransaction } from '../../../../../store/DoApp/CreditTransactions/types';
import { doAppCreditTransactionsOperations } from '../../../../../store/DoApp/CreditTransactions';
import { WidgetTitle } from '../../../../Shared/ListingWidget/WidgetTitle';
import { WidgetSearchInput } from '../../../../Shared/ListingWidget/WidgetSearchInput';
import { WidgetPagination } from '../../../../Shared/ListingWidget/WidgetPagination';
import { WidgetFetchError } from '../../../../Shared/ListingWidget/WidgetFetchError';
import { formatPublishDate } from '../../../../../utils';
import DoAppCreateCreditTransactionForm from './DoAppCreateCreditTransactionForm';

type DoAppCreditTransactionsListProps = {
  onTransactionCreated: () => void;
};

const DoAppCreditTransactionsList: FunctionComponent<DoAppCreditTransactionsListProps> = ({ onTransactionCreated }) => {
  const { t } = useTranslation();
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [lastSearchValue, setLastSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [creditTransactions, setCreditTransactions] = useState<DoAppCreditTransaction[]>([]);
  const [activePromotion, setActivePromotion] = useState<DoAppCreditTransaction | null>(null);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 10, q: '', total: 0, totalPages: 0 });

  const fetchData = async (page: number, search: string) => {
    setFetchError(false);
    try {
      const creditTransactions = await doAppCreditTransactionsOperations.indexRaw(page, 10, search, {});
      setCreditTransactions(creditTransactions.data);
      setPagination(creditTransactions.pagination);
      setLastSearchValue(search);
    } catch (e) {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1, '');
  }, []);

  useEffect(() => {
    const onEnter = (e: any) => {
      if (e.key === 'Enter') {
        fetchData(1, searchValue);
      }
    };
    document.addEventListener('keydown', onEnter);
    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [searchValue]);

  const renderList = () => {
    return (
      <>
        <WidgetTitle
          title={t('pages.doApp.creditTransactions.title')}
          subtitle={t('pages.doApp.creditTransactions.subtitle')}
        />
        <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
        {loading && <Loader />}
        <WidgetSearchInput
          placeholder={`${t('common.search')}...`}
          value={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={fetchData}
        />

        {fetchError && <WidgetFetchError title={t('pages.doApp.creditTransactions.fetchError')} />}
        <List>
          {creditTransactions.map((transaction) => (
            <React.Fragment key={transaction.id}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={`$${(transaction.amount / 100).toFixed(2)}`}
                  secondary={formatPublishDate(transaction.createdAt)}
                />
                <ListItemText primary={transaction.merchant.name} />
                <ListItemText primary={transaction.subscriber.email} />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {pagination.total > 0 && (
            <WidgetPagination
              pagination={{ page: pagination.page, totalPages: pagination.totalPages }}
              handlePaginationChange={(val) => fetchData(val, searchValue)}
            />
          )}
        </div>
        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
        <DoAppCreateCreditTransactionForm
          onTransactionCreated={() => {
            onTransactionCreated();
            fetchData(pagination.page, '');
          }}
        />
      </>
    );
  };

  return renderList();
};

export default DoAppCreditTransactionsList;
