import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';

type CloseToastButtonProps = { closeToast?: () => void };

const CloseToastButton: React.FC<CloseToastButtonProps> = ({ closeToast }) => {
  return (
    <IconButton className="closeToastButton" onClick={closeToast}>
      <Clear />
    </IconButton>
  );
};

export default CloseToastButton;
