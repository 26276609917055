import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { mailingListsOperations } from '../../../../store/MailingLists';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import { MailingList } from '../../../../store/MailingLists/types';

type DeleteMailingListWindowProps = {
  open: boolean;
  mailingList: MailingList | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const DeleteMailingListWindow: FunctionComponent<DeleteMailingListWindowProps> = ({
  mailingList,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!mailingList) return null;

  const onSubmit = async () => {
    try {
      await dispatch(mailingListsOperations.deleteMailingList(mailingList.id));
      await dispatch(mailingListsOperations.index());
      onCloseClick();
      Toast.success(t('notifications.mailingLists.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.mailingLists.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.mailingLists.windows.deleteMailingList')}
      okButtonText={t('pages.mailingLists.windows.delete')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.mailingLists.windows.deleteMailingListContent')}</Row>
      <Row>
        <Strong>{mailingList.name}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteMailingListWindow;
