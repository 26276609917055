import { isEmailProject, isPrintProject } from '../../../../utils';
import { hasPermission } from '../../../../utils/permissions';
import { AnyProject } from '../../../../store/Dashboard/types';
import { AdminRole } from '../../../../store/AdminRoles/types';

export const hasAccessToAction = (project: AnyProject, role: AdminRole) => (
  type: 'access' | 'edit' | 'publish' | 'remove' | 'resetCheckIn'
) => {
  if (isPrintProject(project)) {
    switch (type) {
      case 'access':
        return hasPermission(role, ['projectsPrintAccess']);
      case 'edit':
        return hasPermission(role, ['projectsPrintEdition']);
      case 'publish':
        return hasPermission(role, ['projectsPrintPublishing']);
      case 'remove':
        return hasPermission(role, ['projectsPrintRemove']);
      case 'resetCheckIn':
        return hasPermission(role, ['projectsPrintResetCheckIn']);
    }
  }
  if (isEmailProject(project)) {
    switch (type) {
      case 'access':
        return hasPermission(role, ['projectsEmailAccess']);
      case 'edit':
        return hasPermission(role, ['projectsEmailEdition']);
      case 'publish':
        return hasPermission(role, ['projectsEmailPublishing']);
      case 'remove':
        return hasPermission(role, ['projectsEmailRemove']);
      case 'resetCheckIn':
        return hasPermission(role, ['projectsEmailResetCheckIn']);
    }
  }
  return false;
};

export const hasPermissionToAnyAction = (project: AnyProject, role: AdminRole, projectLocked: boolean) => {
  if (isPrintProject(project)) {
    if (projectLocked && !hasPermission(role, ['projectsPrintResetCheckIn'])) {
      return false;
    }

    return hasPermission(role, [
      'projectsPrintAccess',
      'projectsPrintEdition',
      'projectsPrintPublishing',
      'projectsPrintRemove',
      'projectsPrintResetCheckIn',
      'projectsPrintReSubmitJob'
    ]);
  }
  if (isEmailProject(project)) {
    if (projectLocked && !hasPermission(role, ['projectsEmailResetCheckIn'])) {
      return false;
    }

    return hasPermission(role, [
      'projectsEmailAccess',
      'projectsEmailEdition',
      'projectsEmailPublishing',
      'projectsEmailRemove',
      'projectsEmailResetCheckIn'
    ]);
  }

  return false;
};
