import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';
import ReportsPage from './ReportsPage';

const ReportsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMIN_REPORTS.INDEX,
    exact: true,
    name: 'adminReportsDashboard',
    public: false,
    show: true,
    component: ReportsPage,
    scope: []
  }
];

export default ReportsRoutes;
