import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminOrganizationUser } from '../../../../../../store/AdminOrganizationUsers/types';
import { adminOrganizationUsersOperations } from '../../../../../../store/AdminOrganizationUsers';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../../Shared/StyledComponents';
import InfoBox from '../../../../../Shared/InfoBox/InfoBox';
import Toast from '../../../../../Shared/Toast/Toast';

type AdminDeleteOrganizationUserWindowProps = {
  open: boolean;
  organizationId: string;
  organizationUser: AdminOrganizationUser | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminDeleteOrganizationUserUserWindow: FunctionComponent<AdminDeleteOrganizationUserWindowProps> = ({
  organizationUser,
  onCloseClick,
  organizationId,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!organizationUser) return null;

  const onSubmit = async () => {
    try {
      await dispatch(adminOrganizationUsersOperations.deleteOrganizationUser(organizationId, organizationUser.id));
      await dispatch(adminOrganizationUsersOperations.index(organizationId));
      onCloseClick();
      Toast.success(t('notifications.adminOrganizationUsers.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminOrganizationUsers.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminOrganizationUsers.windows.deleteOrganizationUser')}
      okButtonText={t('pages.adminOrganizationUsers.windows.completelyDelete')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.adminOrganizationUsers.windows.deleteOrganizationUserContent')}</Row>
      <Row>
        <Strong>{organizationUser.email}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default AdminDeleteOrganizationUserUserWindow;
