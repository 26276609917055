import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { useDispatch } from 'react-redux';
import { AdminOrganization } from '../../../../store/AdminOrganizations/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import OrganizationTree, {
  buildOrganizationStructure
} from '../../MyOrganization/Partials/OrganizationTree/OrganizationTree';
import Loader from '../../../Shared/Loading/Loader';
import { Story } from '../../../../store/Stories/types';
import { storiesOperations } from '../../../../store/Stories';
import { PageContentPaper } from '../../PageStyledComponents';
import { Button } from '@material-ui/core';

type ShareTabProps = {
  story: Story;
};

const ShareTab: FunctionComponent<ShareTabProps> = ({ story }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [tree, setTree] = useState<AdminOrganization[]>([]);
  const [organizations, setOrganizations] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await myOrganizationOperations.getOrganizationTree();

        setTree(buildOrganizationStructure(res.data));

        let alreadyShared: string[] = [];
        if (story && story.id) {
          alreadyShared = (await storiesOperations.show(story.id)).shares.map((share) => share.organizationId);
        }

        setOrganizations(alreadyShared);

        setLoading(false);
      } catch (e) {
        Toast.error(t('notifications.myOrganization.cannotFetchOrganizationStructure'));
      }
    };

    fetchData();
  }, []);

  if (!story) return null;

  const onSubmit = async () => {
    setIsSaving(true);
    try {
      if (story.id) {
        await storiesOperations.shareStory(story.id, organizations);
      }

      await dispatch(storiesOperations.index());
      Toast.success(t('notifications.projectShared.successStory'));
    } catch (e) {
      Toast.error(t('notifications.projectShared.error'));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <PageContentPaper style={{ margin: 0, padding: '1rem 2rem' }}>
      <Row>{t('windows.projectShare.infoStory')}</Row>
      <Row>
        {loading && <Loader />}
        {!loading && (
          <>
            <OrganizationTree items={tree} selectedIds={organizations} setOrganizations={setOrganizations} />
            <Button
              color="secondary"
              variant="contained"
              onClick={onSubmit}
              size="medium"
              disabled={isSaving}
              style={{ display: 'block', marginLeft: 'auto', marginTop: '20px' }}
            >
              {isSaving ? t('common.saving') : t('common.save')}
            </Button>
          </>
        )}
      </Row>
    </PageContentPaper>
  );
};

export default ShareTab;
