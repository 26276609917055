import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { RolePermissions } from '../../../../../store/AdminRoles/types';
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { UserType } from '../../../../../store/AdminUsers/types';

type PermissionsGridProps = {
  permissions: RolePermissions;
  userType?: UserType;
  unlockAllPermissions: boolean;
  onPermissionChange: (permissionName: keyof RolePermissions, enabled: boolean) => void;
};

const PermissionsGrid: FunctionComponent<PermissionsGridProps> = ({
  permissions,
  onPermissionChange,
  userType,
  unlockAllPermissions
}) => {
  const { t } = useTranslation();

  if (!userType || !userType.defaultRole) {
    return null;
  }

  const defaultUserTypePermissions = userType.defaultRole.permissions;

  const permissionGroups: { [key: string]: string } = {
    projectsPrint: t('acl.permissionGroups.projectsPrint'),
    projectsEmail: t('acl.permissionGroups.projectsEmail'),
    stories: t('acl.permissionGroups.stories'),
    mailingLists: t('acl.permissionGroups.mailingLists'),
    swatches: t('acl.permissionGroups.colorSwatches'),
    projectNotes: t('acl.permissionGroups.projectNotes'),
    organizations: t('acl.permissionGroups.organizations'),
    users: t('acl.permissionGroups.users'),
    roles: t('acl.permissionGroups.roles'),
    keywords: t('acl.permissionGroups.keywords'),
    templates: t('acl.permissionGroups.templates'),
    systemSettings: t('acl.permissionGroups.systemSettings'),
    passwordPolicies: t('acl.permissionGroups.passwordPolicies'),
    ads: t('acl.permissionGroups.adsAdministration'),
    debug: t('acl.permissionGroups.debug'),
    adminReports: t('acl.permissionGroups.adminReports'),
    backoffice: t('acl.permissionGroups.backoffice')
  };

  const keys = Object.keys(permissions) as Array<keyof RolePermissions>;

  const isChecked = (key: keyof RolePermissions) => {
    if (unlockAllPermissions) {
      return permissions[key];
    }
    return !defaultUserTypePermissions[key] ? false : permissions[key];
  };

  const isDisabled = (key: keyof RolePermissions) => {
    if (unlockAllPermissions) {
      return false;
    }
    return !defaultUserTypePermissions[key];
  };

  return (
    <>
      {Object.keys(permissionGroups).map((group) => (
        <Group key={group}>
          <Typography variant="h6" gutterBottom>
            {permissionGroups[group]}
          </Typography>
          <Grid container spacing={3}>
            {keys.map(
              (key) =>
                key.startsWith(group) && (
                  <Grid item md={4} sm={6} xs={12} key={key}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={isDisabled(key)}
                          onChange={(e) => onPermissionChange(key, e.target.checked)}
                          checked={isChecked(key)}
                        />
                      }
                      label={t(`acl.permissions.${key}`)}
                    />
                  </Grid>
                )
            )}
          </Grid>
        </Group>
      ))}
    </>
  );
};

const Group = styled.div`
  border-top: 1px solid #eee;
  margin-bottom: 1rem;
  padding-top: 1rem;
`;

export default PermissionsGrid;
