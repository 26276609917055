import React, { FunctionComponent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { adminMailingListsOperations } from '../../../../../store/AdminMailingLists';
import { useDispatch } from 'react-redux';
import Toast from '../../../../Shared/Toast/Toast';
import { CreateAdminMailingListValues } from '../../../../../store/AdminMailingLists/types';
import { AdminAutocompleteInput, AdminTextInput } from '../../Shared/AdminFormInputs';
import app from '../../../../../config/app/app';
import { FormikProps } from 'formik';
import { adminOrganizationsOperations } from '../../../../../store/AdminOrganizations';
import { AdminRole } from '../../../../../store/AdminRoles/types';
import { AdminOrganization } from '../../../../../store/AdminOrganizations/types';

type CreateAdminMailingListWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const CreateAdminMailingListWindow: FunctionComponent<CreateAdminMailingListWindowProps> = ({ onCloseClick, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<AdminOrganization[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const organizationsResponse = await adminOrganizationsOperations.getAll();
        setOrganizations(organizationsResponse.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    if (open) {
      fetchData();
    }
  }, [open]);

  const onSubmit = async (values: CreateAdminMailingListValues) => {
    try {
      await dispatch(adminMailingListsOperations.create(values));
      await dispatch(adminMailingListsOperations.index());
      onCloseClick();
      Toast.success(t('notifications.adminMailingLists.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminMailingLists.errorOperation'));
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="name" section="adminMailingLists" />
      {!loading && (
        <AdminAutocompleteInput
          t={t}
          name="organization"
          section="adminUsers"
          options={organizations.map((org) => ({ label: org.name, id: org.id }))}
        />
      )}
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.create')}
      header={t('pages.adminMailingLists.windows.createAdminMailingList')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{ name: '', organization: { id: null, label: null } }}
      validationSchema={Yup.object().shape({
        organization: Yup.object().shape({
          id: Yup.string().required(),
          label: Yup.string().required()
        }),
        name: Yup.string()
          .max(app.maxInputLength)
          .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric characters are allowed for this field')
          .required()
      })}
    />
  );
};

export default CreateAdminMailingListWindow;
