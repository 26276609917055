import { PaginationActionsConstants, PaginationActionType } from './types';
import update from 'immutability-helper';
import { EntityToPaginationStoreModule, StateWithPagination } from '../../components/Shared/DataTable/types';

export function createPaginationReducer<State extends StateWithPagination, Entity = never>(
  module: EntityToPaginationStoreModule<Entity>
) {
  return function (state: State, action: PaginationActionType) {
    if (action.module !== module) {
      return state;
    }
    switch (action.type) {
      case PaginationActionsConstants.CHANGE_PAGE:
        return update<StateWithPagination>(state, {
          pagination: {
            page: {
              $set: action.payload.page
            }
          }
        }) as State;
      case PaginationActionsConstants.CHANGE_PER:
        return update<StateWithPagination>(state, {
          pagination: {
            per: {
              $set: action.payload.per
            }
          }
        }) as State;
      case PaginationActionsConstants.CHANGE_SEARCH_QUERY:
        return update<StateWithPagination>(state, {
          pagination: {
            page: {
              $set: 1
            },
            q: {
              $set: action.payload.q ? action.payload.q.trim() : action.payload.q
            }
          }
        }) as State;
      case PaginationActionsConstants.CHANGE_PAGINATION:
        return update<StateWithPagination>(state, {
          pagination: {
            per: {
              $set: action.payload.per
            },
            page: {
              $set: action.payload.page
            },
            q: {
              $set: action.payload.q
            }
          }
        }) as State;

      case PaginationActionsConstants.CLEAR_ALL: {
        return update<StateWithPagination>(state, {
          pagination: {
            per: {
              $set: state.pagination.per
            },
            page: {
              $set: 1
            },
            q: {
              $set: null
            }
          }
        }) as State;
      }
      default:
        return state;
    }
  };
}
