import * as H from 'history';
import { AuthTicketValidationData } from '../../../../store/Auth/types';

export default function getAuthAuthTicketValidationData(
  location: H.Location<unknown>,
  params: { ticketId?: string }
): AuthTicketValidationData {
  const { ticketId = '' } = params;
  if (location) {
    const urlParts = location.pathname.split('/');
    if (urlParts[1]) {
      switch (urlParts[1]) {
        case 'reset': {
          return {
            ticketId,
            ticketType: 'FP'
          };
        }
        case 'verify':
        default:
          return {
            ticketId,
            ticketType: 'AV'
          };
      }
    }
    return {
      ticketId,
      ticketType: 'FP'
    };
  } else {
    return {
      ticketId,
      ticketType: 'FP'
    };
  }
}
