import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { DoAppCreditTransaction } from '../../../../store/DoApp/CreditTransactions/types';
import { doAppCreditTransactionsOperations } from '../../../../store/DoApp/CreditTransactions';
import { MenuItem, Select, TextField, FormControl } from '@material-ui/core';

type DisputeCreditTransactionWindowProps = {
  open: boolean;
  creditTransaction: DoAppCreditTransaction;
  onCloseClick: () => void;
  onSubmit: () => void;
  fullScreenOnMobile: boolean;
};

const DisputeCreditTransactionWindow: FunctionComponent<DisputeCreditTransactionWindowProps> = ({
  onCloseClick,
  onSubmit,
  creditTransaction,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState<string>('test');
  const [comments, setComments] = useState<string>('');
  const [commentsError, setCommentsError] = useState<boolean>(false);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setSelectedOption(value);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComments(event.target.value);
  };

  const onFormSubmit = async () => {
    setCommentsError(false);
    if (comments === '' && selectedOption === 'other') {
      setCommentsError(true);
      return;
    }
    try {
      await dispatch(
        doAppCreditTransactionsOperations.reject(
          creditTransaction.id,
          selectedOption === 'test' ? t('pages.myOrganization.windows.disputeCreditTransaction.test') : comments
        )
      );
      onCloseClick();
      onSubmit();
      Toast.success(t('notifications.disputeCreditTransaction.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.disputeCreditTransaction.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.myOrganization.windows.disputeCreditTransaction.title')}
      okButtonText={t('pages.myOrganization.windows.disputeCreditTransaction.dispute')}
      onCloseClick={onCloseClick}
      onSubmit={onFormSubmit}
    >
      <Row>
        {t('pages.myOrganization.windows.disputeCreditTransaction.subscriber')}:{' '}
        <Strong>{creditTransaction.subscriber.email}</Strong>
        <br />
        {t('pages.myOrganization.windows.disputeCreditTransaction.merchant')}:{' '}
        <Strong>{creditTransaction.merchant.name}</Strong>
        <br />
        {t('pages.myOrganization.windows.disputeCreditTransaction.amount')}:{' '}
        <Strong>{`$${(creditTransaction.amount / 100).toFixed(2)}`}</Strong>
      </Row>
      <Row>{t('pages.myOrganization.windows.disputeCreditTransaction.content')}</Row>
      <FormControl fullWidth variant="outlined" size="small">
        <Select value={selectedOption} onChange={handleSelectChange} fullWidth>
          <MenuItem value="test">{t('pages.myOrganization.windows.disputeCreditTransaction.test')}</MenuItem>
          <MenuItem value="other">{t('pages.myOrganization.windows.disputeCreditTransaction.other')}</MenuItem>
        </Select>
      </FormControl>
      {selectedOption === 'other' && (
        <TextField
          label={t('pages.myOrganization.windows.disputeCreditTransaction.additionalDetails')}
          value={comments}
          onChange={handleCommentChange}
          fullWidth
          variant="outlined"
          margin="normal"
          size="small"
          error={commentsError}
        />
      )}
    </ModalConfirmationWindow>
  );
};

export default DisputeCreditTransactionWindow;
