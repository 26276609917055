import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ImageData } from '../../../types';
import styled from 'styled-components';

type InnerImageElementProps = {
  data: ImageData;
  onChange: (data: ImageData) => void;
  innerAdjustmentEnabled: boolean;
  setInnerAdjustmentEnabled: (active: boolean) => void;
  placeholderText: string;
};

type StyledImageProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  adjusting: boolean;
};

const InnerImageElement: FunctionComponent<InnerImageElementProps> = ({
  data,
  onChange,
  innerAdjustmentEnabled,
  setInnerAdjustmentEnabled,
  placeholderText
}) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const resizeHandleRef = useRef<HTMLDivElement | null>(null);
  const adjustmentPrevState = useRef<boolean>(false);
  const startCoords = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0
  });
  const [width, setWidth] = useState<number>(parseInt(String(data.width)) || 80);
  const [height, setHeight] = useState<number>(parseInt(String(data.height)) || 20);
  const [positionX, setPositionX] = useState<number>(parseInt(String(data.positionX)) || 0);
  const [positionY, setPositionY] = useState<number>(parseInt(String(data.positionY)) || 0);

  useEffect(() => {
    if (adjustmentPrevState.current && !innerAdjustmentEnabled && imageRef.current) {
      onChange({
        ...data,
        width: imageRef.current.offsetWidth,
        height: imageRef.current.offsetHeight,
        positionX: parseInt(imageRef.current.style.left) || 0,
        positionY: parseInt(imageRef.current.style.top) || 0
      });
    }
    adjustmentPrevState.current = innerAdjustmentEnabled;
  }, [innerAdjustmentEnabled]);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    startCoords.current = {
      x: e.clientX,
      y: e.clientY
    };
    document.addEventListener('mouseup', stopAdjustment);
  };

  const startResize = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onMouseDown(e);
    document.addEventListener('mousemove', onImageResize);
  };

  const startMoving = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onMouseDown(e);
    document.addEventListener('mousemove', onImageMove);
  };

  const stopAdjustment = () => {
    document.removeEventListener('mousemove', onImageResize);
    document.removeEventListener('mousemove', onImageMove);

    if (imageRef.current) {
      setWidth(imageRef.current.offsetWidth);
      setHeight(imageRef.current.offsetHeight);

      setPositionX(parseInt(imageRef.current.style.left) || 0);
      setPositionY(parseInt(imageRef.current.style.top) || 0);
    }
  };

  const onImageMove = (e: MouseEvent) => {
    if (imageRef.current) {
      const newPositionX = Math.floor(e.clientX - startCoords.current.x) + positionX;
      const newPositionY = Math.floor(e.clientY - startCoords.current.y) + positionY;

      imageRef.current.style.top = `${newPositionY}px`;
      imageRef.current.style.left = `${newPositionX}px`;

      if (resizeHandleRef.current) {
        resizeHandleRef.current.style.top = `${imageRef.current.offsetTop + imageRef.current.height - 10}px`;
        resizeHandleRef.current.style.left = `${imageRef.current.offsetLeft + imageRef.current.width - 10}px`;
      }
    }
  };

  const onImageResize = (e: MouseEvent) => {
    if (imageRef.current) {
      let newWidth = width + (e.clientX - startCoords.current.x);

      if (newWidth < 50) {
        newWidth = 50;
      }

      const ratio = newWidth / width;

      const newHeight = height * ratio;

      imageRef.current.style.width = `${newWidth}px`;
      imageRef.current.style.height = `${newHeight}px`;

      if (resizeHandleRef.current) {
        resizeHandleRef.current.style.top = `${imageRef.current.offsetTop + imageRef.current.height - 10}px`;
        resizeHandleRef.current.style.left = `${imageRef.current.offsetLeft + imageRef.current.width - 10}px`;
      }
    }
  };

  const imgElement = data.url ? (
    <InnerImageStyled
      onMouseDown={innerAdjustmentEnabled ? startMoving : undefined}
      draggable={false}
      ref={imageRef}
      x={positionX}
      y={positionY}
      width={width}
      height={height}
      adjusting={innerAdjustmentEnabled}
      src={data.url}
      alt=""
    />
  ) : (
    <InnerImageEmpty placeholderText={placeholderText} />
  );

  return (
    <>
      {imgElement}
      {innerAdjustmentEnabled && imageRef.current && (
        <ResizeHandle
          onMouseDown={startResize}
          ref={resizeHandleRef}
          style={{
            top: imageRef.current.offsetTop + imageRef.current.height - 10,
            left: imageRef.current.offsetLeft + imageRef.current.width - 10
          }}
        />
      )}
    </>
  );
};

const ResizeHandle = styled.div`
  position: absolute;
  border: 2px dashed #ccc;
  width: 20px;
  height: 20px;
  z-index: 22;
  cursor: nwse-resize;
`;

const InnerImageStyled = styled.img<StyledImageProps>`
  cursor: move;
  position: absolute;
  top: ${({ y }) => `${y}px`};
  left: ${({ x }) => `${x}px`};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  ${({ adjusting }) => (adjusting ? `z-index: 21` : 'pointer-events: none')};
`;

const InnerImageEmpty = styled.div<{ placeholderText: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #b2b2b2;
  border: 2px dashed #797979;

  &:after {
    content: ${({ placeholderText }) => `'${placeholderText}'`};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    font-style: italic;
    color: #edecec;
  }
`;

export default InnerImageElement;
