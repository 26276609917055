import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Link, List, ListItem, ListItemText } from '@material-ui/core';
import Loader from '../../../../Shared/Loading/Loader';
import { DoAppSponsor } from '../../../../../store/DoApp/Sponsors/types';
import { doAppSponsorsOperations } from '../../../../../store/DoApp/Sponsors';
import { WidgetTitle } from '../../../../Shared/ListingWidget/WidgetTitle';
import { WidgetSearchInput } from '../../../../Shared/ListingWidget/WidgetSearchInput';
import { WidgetPagination } from '../../../../Shared/ListingWidget/WidgetPagination';
import { WidgetFetchError } from '../../../../Shared/ListingWidget/WidgetFetchError';
import DoAppCreateSponsorForm from './DoAppSponsorForm';
import DoAppSponsorRegions from '../windows/DoAppSponsorRegions';

type DoAppSponsorsListProps = {};

const DoAppSponsorsList: FunctionComponent<DoAppSponsorsListProps> = () => {
  const { t } = useTranslation();
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [lastSearchValue, setLastSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [sponsors, setSponsors] = useState<DoAppSponsor[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 10, q: '', total: 0, totalPages: 0 });

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [activeSponsor, setActiveSponsor] = useState<DoAppSponsor | null>(null);

  const fetchData = async (page: number, search: string) => {
    setFetchError(false);
    try {
      const sponsors = await doAppSponsorsOperations.indexRaw(page, 10, search, {});
      setSponsors(sponsors.data);
      setPagination(sponsors.pagination);
      setLastSearchValue(search);
    } catch (e) {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1, '');
  }, []);

  const handleOpenDialog = (sponsor: DoAppSponsor) => {
    setActiveSponsor(sponsor);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setActiveSponsor(null);
  };

  useEffect(() => {
    const onEnter = (e: any) => {
      if (e.key === 'Enter') {
        fetchData(1, searchValue);
      }
    };
    document.addEventListener('keydown', onEnter);
    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [searchValue]);

  const renderList = () => {
    return (
      <>
        <WidgetTitle title={t('pages.doApp.sponsors.title')} />
        <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
        {loading && <Loader />}
        <WidgetSearchInput
          placeholder={`${t('common.search')}...`}
          value={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={fetchData}
        />

        {fetchError && <WidgetFetchError title={t('pages.doApp.sponsors.fetchError')} />}
        <List>
          {sponsors.map((s) => (
            <React.Fragment key={s.email}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={s.name}
                  secondary={
                    <>
                      {s.email}
                      <br />
                      {t('pages.doApp.sponsors.regions')}:{' '}
                      <Link
                        component="button"
                        color="secondary"
                        variant="body2"
                        onClick={() => handleOpenDialog(s)}
                        style={{ fontWeight: 'bold' }}
                      >
                        {s.regions.length}
                      </Link>
                    </>
                  }
                />
                <ListItemText
                  primary={`${t('pages.doApp.sponsors.amount')}: $${(s.amount / 100).toFixed(2)}`}
                  secondary={
                    <>
                      {t('pages.doApp.sponsors.amountToGive')}: ${(s.amountToGive / 100).toFixed(2)}
                      <br />
                      {t('pages.doApp.sponsors.availableAmount')}: ${(s.availableAmount / 100).toFixed(2)}
                      <br />
                      {t('pages.doApp.sponsors.poolTarget')}:{' '}
                      {t(`pages.doApp.sponsors.poolTargetOptions.${s.poolTarget}`)}
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {pagination.total > 0 && (
            <WidgetPagination
              pagination={{ page: pagination.page, totalPages: pagination.totalPages }}
              handlePaginationChange={(val) => fetchData(val, searchValue)}
            />
          )}
        </div>
        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
        <DoAppCreateSponsorForm onSponsorCreated={() => fetchData(pagination.page, '')} />

        {activeSponsor && (
          <DoAppSponsorRegions open={openDialog} regions={activeSponsor.regions} onCloseClick={handleCloseDialog} />
        )}
      </>
    );
  };

  return renderList();
};

export default DoAppSponsorsList;
