import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { NoResultsFound, PageContainer, PageContent } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import { useTypedSelector } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../config/app/linksConstants';
import Loader from '../../Shared/Loading/Loader';
import { Button } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { hasPermission, hasPermissionToAnyChannel } from '../../../utils/permissions';
import { User } from '../../../store/Auth/types';
import { TableSearchStoreModule } from '../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../store/Pagination/types';
import DataTable from '../../Shared/DataTable/DataTable';
import ProjectNameCell from './WorkInProgressTable/ProjectNameCell';
import { usePaginationWatch } from '../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../Shared/DataTable/useTableSearchWatch';
import LatestNoteCell from './WorkInProgressTable/LatestNoteCell';
import LatestActivityCell from './WorkInProgressTable/LatestActivityCell';
import PublicationDateCell from './WorkInProgressTable/PublicationDateCell';
import ProjectStatusCell from './WorkInProgressTable/ProjectStatusCell';
import ProjectActionsCell from './WorkInProgressTable/ProjectActionsCell';
import { PrintProject } from '../../../store/PrintProjects/types';
import { printProjectsOperations } from '../../../store/PrintProjects';
import ProjectRemoveWindow from './Windows/ProjectRemoveWindow';
import ResetProjectCheckInStatusWindow from './Windows/ResetProjectCheckInStatusWindow';
import ProjectPublishPrintWindow from './Windows/ProjectPublishPrintWindow';
import { useHandleProjectAction } from './WorkInProgressTable/useHandleProjectAction';
import ProjectShareWindow from './Windows/ProjectShareWindow';
import ProjectExpandableContent from './WorkInProgressTable/ProjectExpandableContent';

type PrintProjectsPageProps = RouteComponentProps<{}> & {};

const PrintProjectsPage: FunctionComponent<PrintProjectsPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const user = useTypedSelector((state) => state.auth.user);
  const role = useTypedSelector((state) => state.auth.role);
  const projects = useTypedSelector((state) => state.printProjects.projects);

  const isFailed = useTypedSelector((state) => state.printProjects.index.isFailed);
  const loading = useTypedSelector((state) => state.printProjects.index.isLoading);
  const pagination = useTypedSelector((state) => state.printProjects.pagination);
  const tableSearch = useTypedSelector((state) => state.printProjects.tableSearch);
  const {
    handleAction,
    activeProject,

    shareProjectWindowOpen,
    removeProjectWindowOpen,
    resetCheckInWindowOpen,
    publishPrintWindowOpen,
    previewPrintWindowOpen,

    onShareProjectWindowClose,
    onRemoveProjectWindowClose,
    onPublishPrintWindowClose,
    onResetCheckInWindowClose,
    onPreviewPrintWindowClose
  } = useHandleProjectAction();

  useEffect(() => {
    if (!loading) {
      setInitialLoading(false);
    }
  }, [loading]);

  usePaginationWatch(pagination, [printProjectsOperations.getUserPrintProjects]);
  useTableSearchWatch<PrintProject>(tableSearch, [printProjectsOperations.getUserPrintProjects]);

  const getActionButtons = () => {
    if (hasPermission(role, ['projectsPrintAccess'])) {
      return [
        {
          onClick: () => dispatch(push(linksConstants.DASHBOARD.NEW_PROJECT)),
          label: t('pages.dashboard.createProjectButton')
        }
      ];
    }

    return [];
  };

  const getUserFullName = (user: User) => {
    return `${user.firstName} ${user.lastName}`.trim() || user.email;
  };

  const title = (
    <div>
      <WelcomeBackText>{t('pages.dashboard.printProjects')},</WelcomeBackText>
      <div>{getUserFullName(user)}!</div>
    </div>
  );

  return (
    <PageContainer>
      <PageHeader title={title} rightActionButtons={getActionButtons()} />
      <PageContent>
        {initialLoading && <Loader />}
        {!initialLoading && !projects.result.length && !pagination.q && (
          <>
            <NoResultsFound>
              <div style={{ marginBottom: '2rem' }}>{t('pages.dashboard.noProjectsInProgress')}</div>

              {hasPermissionToAnyChannel(role) && (
                <div>
                  <Button
                    color={'secondary'}
                    variant={'contained'}
                    size="large"
                    onClick={() => dispatch(push(linksConstants.DASHBOARD.NEW_PROJECT))}
                  >
                    {t('pages.dashboard.createProjectButton')}
                  </Button>
                </div>
              )}
            </NoResultsFound>
          </>
        )}
        {((!initialLoading && projects.result.length > 0) || pagination.q) && (
          <DataTable<PrintProject, 'actions' | 'latestNote'>
            enableSearch={true}
            wrapperStyle={{
              padding: '0.5rem 2rem 0'
            }}
            headingStyle={{
              fontSize: '1.4rem',
              color: '#014b72',
              textAlign: 'left',
              padding: '0.5rem',
              fontWeight: 'normal'
            }}
            columnDefinitions={[
              {
                name: 'name',
                sortable: true,
                render: (project) => <ProjectNameCell project={project} user={user} role={role} />
              },
              {
                name: 'latestNote',
                sortable: false,
                render: (project) => <LatestNoteCell project={project} />
              },
              {
                name: 'updatedBy',
                sortable: false,
                render: (project) => <LatestActivityCell project={project} user={user} />
              },
              {
                name: 'publishDate',
                sortable: true,
                render: (project) => <PublicationDateCell project={project} />
              },
              {
                name: 'status',
                sortable: true,
                render: (project) => <ProjectStatusCell project={project} />
              },
              {
                name: 'actions',
                sortable: false,
                render: (project) => (
                  <ProjectActionsCell project={project} user={user} role={role} handleEvent={handleAction} />
                )
              }
            ]}
            tPath={'pages.dashboard.table'}
            data={projects}
            tableSearchProps={{
              tableSearch,
              module: TableSearchStoreModule.PRINT_PROJECTS
            }}
            paginationProps={{
              pagination,
              module: PaginationStoreModule.PRINT_PROJECTS
              // onPageChange: () => {},
              // onPerChange: () => {}
            }}
            isFailed={isFailed}
            isLoading={loading}
            expandableKey={'isShared'}
            expandableContent={(entity, open) => <ProjectExpandableContent entity={entity} open={open} />}
          />
        )}
      </PageContent>
      <ProjectShareWindow
        context="print"
        open={shareProjectWindowOpen}
        project={activeProject}
        onCloseClick={onShareProjectWindowClose}
        fullScreenOnMobile={true}
      />
      <ProjectRemoveWindow
        context="print"
        open={removeProjectWindowOpen}
        project={activeProject}
        onCloseClick={onRemoveProjectWindowClose}
        fullScreenOnMobile={true}
      />
      <ResetProjectCheckInStatusWindow
        open={resetCheckInWindowOpen}
        project={activeProject}
        onCloseClick={onResetCheckInWindowClose}
        fullScreenOnMobile={true}
      />
      {publishPrintWindowOpen && (
        <ProjectPublishPrintWindow
          open={publishPrintWindowOpen}
          project={activeProject as PrintProject}
          onCloseClick={onPublishPrintWindowClose}
          fullScreenOnMobile={true}
          onSuccessfulSubmit={() => dispatch(printProjectsOperations.getUserPrintProjects())}
        />
      )}
      {previewPrintWindowOpen && (
        <ProjectPublishPrintWindow
          onlyPreview={true}
          open={previewPrintWindowOpen}
          project={activeProject as PrintProject}
          onCloseClick={onPreviewPrintWindowClose}
          fullScreenOnMobile={true}
        />
      )}
    </PageContainer>
  );
};

const WelcomeBackText = styled.div`
  font-size: 1.4rem;
  font-weight: normal;
`;

export default PrintProjectsPage;
