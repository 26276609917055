import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { PrintProjectTemplateTypeName } from '../Templates/types';
import { IndexPrintUserProjectsRequestParams } from './types';

export const getUserPrintProjects = (
  settings: RequestSettings<IndexPrintUserProjectsRequestParams, {}>
): BaseSettings => {
  return {
    query: {
      method: 'get',
      url: `/api/v1/channel/print?per=:per&page=:page&status=:status`
    },
    params: settings.requestParams
  };
};

export const create = (
  settings: RequestSettings<
    { templateId: string },
    { publishDate: string; type: PrintProjectTemplateTypeName; test: boolean }
  >
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/channel/print/:templateId'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const show = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/print/:projectId'
  },
  params: settings.requestParams
});

export const savePrintProjectName = (
  settings: RequestSettings<{ projectId: string }, { name: string }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/channel/print/:projectId'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const generateFlipBookResources = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/channel/print/:projectId/generate_flipbook'
  },
  params: settings.requestParams
});

export const getPrintProjectPreviewUrl = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/print/:projectId/preview_url'
  },
  params: settings.requestParams
});

export const removePrintProject = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/channel/print/:projectId'
  },
  params: settings.requestParams
});

export const shareProject = (
  settings: RequestSettings<{ projectId: string }, { organizations: string[] }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/channel/print/:projectId/share'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const publishPrintProject = (
  settings: RequestSettings<{ projectId: string }, { printCenterId: string; pageCount: number }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/channel/print/:projectId/publish'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const getArticles = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/print/:projectId/get_articles'
  },
  params: settings.requestParams
});

export const generatePrintPdf = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/print/:projectId/generate_print_pdf'
  },
  params: settings.requestParams
});

export const saveProjectPublishDate = (
  settings: RequestSettings<{ projectId: string }, { name: string; publishDate: Date }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/channel/print/:projectId'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const checkIn = (settings: RequestSettings<{ projectId: string }, { checkInToken: string }>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/channel/print/:projectId/check_in'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const clone = (
  settings: RequestSettings<{ projectId: string }, { publishDate: Date; test: boolean }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: `/api/v1/channel/print/:projectId/clone`
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const cloneShared = (settings: RequestSettings<{ projectId: string }, { name: string }>): BaseSettings => ({
  query: {
    method: 'post',
    url: `/api/v1/channel/print/:projectId/clone_shared`
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const saveContent = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'post',
    url: `/api/v1/channel/print/:projectId/save`
  },
  params: settings.requestParams
});

export const getProjectClones = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/print/:projectId/clones'
  },
  params: settings.requestParams
});

export const getPrintProjectCampaigns = (settings: RequestSettings<{ projectId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/channel/print/:projectId/campaign'
  },
  params: settings.requestParams
});
