import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { IndexAdminProjectsProjectsRequestParams, IndexAdminProjectsProjectsRequestParamsFilters } from './types';

export const index = (settings: RequestSettings<IndexAdminProjectsProjectsRequestParams, {}>): BaseSettings => {
  const parameters: (keyof IndexAdminProjectsProjectsRequestParamsFilters)[] = [
    'publicationType',
    'status',
    'projectName',
    'organizationNumber',
    'organizationName',
    'dataCenter',
    'publicationDate',
    'publicationDateRange',
    'testProjects',
    'onlyTestProjects',
    'externalId',
    'automatedEmail'
  ];

  return {
    query: {
      method: 'get',
      url: `/api/v1/admin/projects?format=:format&per=:per&page=:page&${parameters
        .map((param) => `${param}=:${param}`)
        .join('&')}`
    },
    params: settings.requestParams
  };
};

export const syncMailStatistics = (): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/projects/sync_mail_statistics`
  }
});
