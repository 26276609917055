import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { DesignerStory } from '../../../../../../store/PrintProjects/types';
import { Checkbox, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { useDrag, useDrop } from 'react-dnd';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

type DesignerArticleProps = {
  id: any;
  article: DesignerStory;
  selected: boolean;
  onSelect: (selected: boolean) => void;
  moveArticle: (dragIndex: number, hoverIndex: number) => void;
  findArticle: (id: number) => { index: number };
  onSelectArticleImages: (article: DesignerStory) => void;
};

interface Item {
  type: string;
  id: number;
  originalIndex: number;
}

const DesignerArticle: FunctionComponent<DesignerArticleProps> = ({
  id,
  article,
  onSelect,
  selected,
  moveArticle,
  findArticle,
  onSelectArticleImages
}) => {
  const { t } = useTranslation();

  const selectedImage = article.images.find((img) => img.selected);
  const originalIndex = findArticle(id).index;
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'article', id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveArticle(droppedId, originalIndex);
      }
    }
  });

  const [, drop] = useDrop({
    accept: 'article',
    canDrop: () => false,
    hover({ id: draggedId }: Item) {
      if (draggedId !== id) {
        const { index: overIndex } = findArticle(id);
        moveArticle(draggedId, overIndex);
      }
    }
  });

  const opacity = isDragging ? 0.2 : 1;

  const handleStyle: React.CSSProperties = {
    width: '35px',
    height: '35px',
    cursor: 'move'
  };

  return preview(
    <div ref={(node) => drop(node)} style={{ opacity }}>
      <ListItem dense>
        {drag(
          <div style={handleStyle}>
            <SwapVertIcon fontSize="large" htmlColor="#656565" />
          </div>
        )}
        <ListItemIcon>
          <Checkbox checked={selected} onChange={(e) => onSelect(e.target.checked)} />
        </ListItemIcon>
        {article.images.length > 0 && (
          <ListItemAvatar style={{ marginRight: 20 }}>
            <img
              src={selectedImage ? selectedImage.url : article.images[0].url}
              width={60}
              height={60}
              style={{ objectFit: 'cover' }}
            />
          </ListItemAvatar>
        )}
        <ListItemText
          primary={
            article.title && (
              <ArticleTitle
                variant="subtitle2"
                gutterBottom
                style={{ fontWeight: 'bold' }}
                dangerouslySetInnerHTML={{ __html: article.title }}
              />
            )
          }
          secondary={article.body && <ArticleBody dangerouslySetInnerHTML={{ __html: article.body }} />}
        />
        <ActionButtons>
          <Tooltip title={`${t('pages.projectPublish.selectArticleImage')}`}>
            <span>
              <IconButton
                onClick={() => onSelectArticleImages(article)}
                color="secondary"
                disabled={article.images.length <= 1}
              >
                <PhotoLibraryOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        </ActionButtons>
      </ListItem>
    </div>
  );
};

const ArticleTitle = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;

  a {
    pointer-events: none;
  }
`;

const ArticleBody = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;

  a {
    pointer-events: none;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
`;

export default DesignerArticle;
