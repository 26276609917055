import React, { FunctionComponent } from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';
import styled from 'styled-components';
import { Button, Grid, Paper, useTheme, withTheme } from '@material-ui/core';
import { OutlinedInfoBoxContent, Strong } from '../../../../Shared/StyledComponents';
import { AnyProject } from '../../../../../store/Dashboard/types';
import { formatPublishDate } from '../../../../../utils';
import ProjectAvatar from '../../../../Shared/Avatar/ProjectAvatar';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import { Template } from '../../../../../store/Templates/types';
import TemplatePaper from '../../../../Shared/Layout/TemplatePaper';

type CloneProjectConfirmationScreenProps = {
  onSubmit: () => void;
  project: AnyProject | null;
  template: Template | null;
  publishDate: Date;
};

const CloneProjectConfirmationScreen: FunctionComponent<CloneProjectConfirmationScreenProps> = ({
  project,
  publishDate,
  onSubmit,
  template
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container>
      <InfoBox type="outlinedInfo">
        <OutlinedInfoBoxContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div style={{ display: 'flex' }}>
                <InfoIcon fontSize="large" />
                <div>
                  {t('pages.dashboard.createWizard.areYouSureToCloneProject')}{' '}
                  <Strong>{formatPublishDate(publishDate)}</Strong>?
                </div>
              </div>
            </Grid>
            <Grid item xs={8}>
              {project && (
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={5}>
                    <ProjectCopyContainer>
                      <Texts>
                        <ProjectAvatar type={project.type} />
                        <div>
                          <Title>{project.name}</Title>
                          <PublishDate>
                            <CalendarIcon style={{ fontSize: 12 }} /> {formatPublishDate(project.publishDate)}
                          </PublishDate>
                          <Description>{project.templateDescription}</Description>
                        </div>
                      </Texts>
                    </ProjectCopyContainer>
                  </Grid>
                  <Grid container xs={1} justify="center">
                    <ArrowForwardOutlinedIcon />
                  </Grid>
                  <Grid item xs={6}>
                    <Paper>
                      <Texts>
                        <ProjectAvatar type={project.type} />
                        <div>
                          <Title>{project.name} - Copy</Title>
                          <PublishDate>
                            <CalendarIcon style={{ fontSize: 12 }} /> {formatPublishDate(publishDate)}
                          </PublishDate>
                          <Description>{project.templateDescription}</Description>
                        </div>
                      </Texts>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'right', marginTop: 20 }}>
                    <ConfirmButton
                      onClick={onSubmit}
                      endIcon={<NavigateNextIcon />}
                      color={'secondary'}
                      variant="contained"
                      size="large"
                    >
                      {t('common.confirm')}
                    </ConfirmButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {template && (
              <Grid item xs={4} spacing={3}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div>
                    <TemplatePaper
                      thumbnailUrl={template.thumbnailUrl}
                      id={template.id}
                      title={template.name}
                      description={template.description}
                      color={theme.palette.custom.templateTypes[template.type]}
                    />
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </OutlinedInfoBoxContent>
      </InfoBox>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const ConfirmButton = styled(Button)`
  margin-bottom: 2rem;
  svg {
    color: #fff;
  }
`;
const Texts = withTheme(styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  letter-spacing: 0.08rem;
`);
const TextHolder = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;
const Title = withTheme(styled(TextHolder)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  margin-bottom: 0.1rem;
  font-size: 14px;
  letter-spacing: normal;
`);
const Description = withTheme(styled(TextHolder)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 0.8rem;
  letter-spacing: normal;
  line-height: normal;
  word-break: break-word;
`);

const PublishDate = withTheme(styled(TextHolder)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 0.8rem;
  letter-spacing: normal;
  margin-bottom: 0.4rem;
  svg {
    margin-right: 0;
  }
`);
const ProjectCopyContainer = styled.div`
  border: 2px dashed #ccc;
`;

export default CloneProjectConfirmationScreen;
