import {
  DoAppMerchantCreditTransactionsActionsConstants,
  DoAppMerchantCreditTransactionsIndexActionBEGIN,
  DoAppMerchantCreditTransactionsIndexActionFAILED,
  DoAppMerchantCreditTransactionsIndexActionSUCCESS,
  DoAppCreditTransactionsMineIndexResponse
} from './types';

export const doAppCreditTransactionsIndexBegin = (): DoAppMerchantCreditTransactionsIndexActionBEGIN => ({
  type: DoAppMerchantCreditTransactionsActionsConstants.INDEX_BEGIN
});

export const doAppCreditTransactionsIndexSuccess = (
  payload: DoAppCreditTransactionsMineIndexResponse
): DoAppMerchantCreditTransactionsIndexActionSUCCESS => ({
  type: DoAppMerchantCreditTransactionsActionsConstants.INDEX_SUCCESS,
  payload
});

export const doAppCreditTransactionsIndexFailed = (error: any): DoAppMerchantCreditTransactionsIndexActionFAILED => ({
  type: DoAppMerchantCreditTransactionsActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});
