import { Theme, useMediaQuery } from '@material-ui/core';

export default function useViewportSize() {
  const smallSmartphone = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));
  const smartphone = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const tablet = useMediaQuery<Theme>((theme) => theme.breakpoints.only('md'));
  const desktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  return {
    smallSmartphone,
    smartphone,
    tablet,
    desktop,
    mobile
  };
}
