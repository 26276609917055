import { Select, TextField } from '@material-ui/core';
import { DataTablePaginationProps } from '../types';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import PreviousPage from '@material-ui/icons/ChevronLeft';
import NextPage from '@material-ui/icons/ChevronRight';
import MenuItem from '@material-ui/core/MenuItem';
import { usePagination } from './usePagination';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ClearIcon from '@material-ui/icons/Clear';

export function DataTablePagination<E = never>({
  pagination,
  onPageChange,
  onPerChange,
  enableSearch,
  rowsPerPageOptions,
  disablePagination,
  module,
  isLoading,
  noUrlUpdate = false
}: DataTablePaginationProps<E>): JSX.Element {
  const { t } = useTranslation();
  const { handlePageChange, handlePerChange, onPage, handleSearchQueryChange } = usePagination({
    pagination,
    onPerChange,
    onPageChange,
    module,
    noUrlUpdate
  });
  const [searchString, setSearchString] = useState(pagination.q || '');

  useEffect(() => {
    const onEnter = (e: any) => {
      if (enableSearch && e.key === 'Enter') {
        handleSearchQueryChange(searchString);
      }
    };
    if (enableSearch) {
      document.addEventListener('keydown', onEnter);
    }
    return () => {
      if (enableSearch) {
        document.removeEventListener('keydown', onEnter);
      }
    };
  }, [searchString]);

  return (
    <Wrapper>
      <TableSearchContainer>
        {enableSearch && (
          <>
            <TextField
              placeholder={`${t('common.search')}...`}
              type="text"
              variant="standard"
              size="small"
              value={searchString}
              style={{ width: 220 }}
              onChange={(e) => setSearchString(e.target.value)}
              InputProps={{
                endAdornment: searchString !== '' && (
                  <IconButton
                    color={'primary'}
                    size="small"
                    onClick={() => {
                      setSearchString('');
                      handleSearchQueryChange('');
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )
              }}
            />
            <IconButton
              color={'secondary'}
              size="medium"
              onClick={() => {
                handleSearchQueryChange(searchString);
              }}
            >
              <SearchIcon />
            </IconButton>
          </>
        )}
      </TableSearchContainer>
      <PaginationCell>
        {disablePagination && <>{pagination.total} Results</>}
        {!disablePagination && (
          <>
            <div>
              <RowsPerPage value={pagination.per} onChange={handlePerChange} disabled={isLoading}>
                {(rowsPerPageOptions || [1, 5, 10, 50, 100]).map((opt) => (
                  <MenuItem key={opt} value={opt}>
                    {opt}
                  </MenuItem>
                ))}
              </RowsPerPage>
            </div>
            <PaginationNumbers>
              {pagination.total === 0 ? 0 : (pagination.page - 1) * pagination.per + 1} - {onPage} {t('common.of')}{' '}
              {pagination.total}
            </PaginationNumbers>
            <div>
              <IconButton
                disabled={pagination.totalPages === 0 || isLoading || pagination.page === 1}
                size="small"
                onClick={() => handlePageChange(1)}
              >
                <FirstPage />
              </IconButton>
              <IconButton
                disabled={pagination.totalPages === 0 || isLoading || pagination.page === 1}
                size="small"
                onClick={() => handlePageChange(pagination.page - 1)}
              >
                <PreviousPage />
              </IconButton>
              <IconButton
                disabled={pagination.totalPages === 0 || isLoading || pagination.page === pagination.totalPages}
                size="small"
                onClick={() => handlePageChange(pagination.page + 1)}
              >
                <NextPage />
              </IconButton>
              <IconButton
                disabled={pagination.totalPages === 0 || isLoading || pagination.page === pagination.totalPages}
                size="small"
                onClick={() => handlePageChange(pagination.totalPages)}
              >
                <LastPage />
              </IconButton>
            </div>
          </>
        )}
      </PaginationCell>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-content: center;
  height: 52px;
`;

const TableSearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PaginationCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > div {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  //@include smartphone() {
  //  padding-right: 6px;
  //}
`;

const PaginationNumbers = styled.div`
  font-size: 0.8rem;
`;

const RowsPerPage = styled(Select)`
  text-decoration: none !important;
  padding: 0.3rem 0.5rem;
  height: 2rem;
  background: none;
  border: none;

  div {
    font-size: 0.8rem;
  }
`;
