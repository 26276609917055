import React, { FunctionComponent } from 'react';
import SelectTemplate from '../../Creators/Partials/SelectTemplate';
import { Template, TemplateTypeName } from '../../../../../store/Templates/types';
import { useTranslation } from 'react-i18next';

type PrintPublishTemplateChooserProps = {
  onCancel: () => void;
  onSubmit: (template: Template) => void;
  publishDate: Date;
};

const PrintPublishTemplateChooser: FunctionComponent<PrintPublishTemplateChooserProps> = ({
  onSubmit,
  publishDate,
  onCancel
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <SelectTemplate
        onSubmit={onSubmit}
        showSimplifiedTopBar
        onBackClick={onCancel}
        templateTypeName={TemplateTypeName.EMAIL}
        sourceProject={null}
        setCurrentState={() => {
          /* */
        }}
        publishDate={publishDate}
        infoText={t('pages.dashboard.createWizard.templateSelector.selectEmailTemplate')}
        showSourceProject={false}
        showTopBar={false}
      />
    </div>
  );
};

export default PrintPublishTemplateChooser;
