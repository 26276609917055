import React, { FunctionComponent, useEffect, useState } from 'react';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { useTypedSelector } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { hasPermission } from '../../../../utils/permissions';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import AddIcon from '@material-ui/icons/Add';
import StoryPreview from './StoryPreview';
import { Grid } from '@material-ui/core';
import { storiesOperations } from '../../../../store/Stories';
import Toast from '../../../Shared/Toast/Toast';
import { Story } from '../../../../store/Stories/types';
import Loader from '../../../Shared/Loading/Loader';
import StoryContributorsTable from './StoryContributorsTable';
import InviteStoryContributorWindow from '../Windows/InviteStoryContributorWindow';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import OrganizationContributorsList from '../../MyOrganization/Partials/OrganizationContributorsList';

type StoryContentContributorsProps = RouteComponentProps<{ storyId: string }> & {};

const StoryContentContributors: FunctionComponent<StoryContentContributorsProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [reFetchRequired, setReFetchRequired] = useState(false);
  const [story, setStory] = useState<Story | null>(null);
  const role = useTypedSelector((state) => state.auth.role);
  const [inviteContributorWindowOpen, onInviteContributorWindowOpen, onInviteContributorWindowClose] = useOpenHandler();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const story = await storiesOperations.show(match.params.storyId);
        setStory(story);
      } catch (e) {
        Toast.error(t('notifications.story.cannotFetchStory'));
      } finally {
        setInitialLoading(false);
      }
    };
    fetchData();
  }, []);

  const getActionButtons = () => {
    if (hasPermission(role, ['storiesContributors'])) {
      return [
        {
          label: t('pages.story.contentContributors.inviteButton'),
          icon: <AddIcon />,
          onClick: () => onInviteContributorWindowOpen()
        }
      ];
    }

    return [];
  };

  const backButton = {
    onClick: () => dispatch(push(linksConstants.STORY.INDEX)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  return (
    <PageContainer>
      <PageHeader
        title={t('pages.story.contentContributors.header')}
        leftActionButtons={[backButton]}
        rightActionButtons={getActionButtons()}
      />
      <PageContent>
        {initialLoading && <Loader />}

        {!initialLoading && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {story && <StoryPreview story={story} />}
            </Grid>

            <Grid item xs={12}>
              {story && (
                <OrganizationContributorsList
                  reFetchRequired={reFetchRequired}
                  onReFetchCompleted={() => setReFetchRequired(false)}
                  story={story}
                  display="grid"
                  title={t('pages.story.contentContributors.contributorsListHeader')}
                  subtitle={t('pages.story.contentContributors.contributorsListSubheader')}
                  clickable
                />
              )}
            </Grid>

            <Grid item xs={12}>
              {story && <StoryContributorsTable story={story} onContributorRemoved={() => setReFetchRequired(true)} />}
            </Grid>
          </Grid>
        )}
      </PageContent>
      {inviteContributorWindowOpen && story && (
        <InviteStoryContributorWindow
          open={inviteContributorWindowOpen}
          story={story}
          onCloseClick={onInviteContributorWindowClose}
          fullScreenOnMobile
        />
      )}
    </PageContainer>
  );
};

export default StoryContentContributors;
