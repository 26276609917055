import React, { FunctionComponent, useState } from 'react';
import { MyOrganizationUser } from '../../../../store/MyOrganization/types';
import TableActionButtonsContainer from '../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../../Shared/DataTable/TableActionButton/TableActionButton';
import EditIcon from '@material-ui/icons/Edit';
import { isOrganizationOwner } from '../../../../utils/permissions';
import DeleteIcon from '@material-ui/icons/Delete';
import { TableSearchStoreModule } from '../../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../../store/Pagination/types';
import DataTable from '../../../Shared/DataTable/DataTable';
import UpdateMyOrganizationUserWindow from '../Windows/UpdateMyOrganizationUserWindow';
import DeleteMyOrganizationUserWindow from '../Windows/DeleteMyOrganizationUserWindow';
import { usePaginationWatch } from '../../../Shared/DataTable/Pagination/usePaginationWatch';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import { useTableSearchWatch } from '../../../Shared/DataTable/useTableSearchWatch';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import { useTranslation } from 'react-i18next';
import { formatTableTimestamp, useTypedSelector } from '../../../../utils';
import { Strong } from '../../../Shared/StyledComponents';

type OrganizationUsersTableProps = {};

const OrganizationUsersTable: FunctionComponent<OrganizationUsersTableProps> = () => {
  const { t } = useTranslation();
  const authState = useTypedSelector((state) => state.auth);
  const organizationUsers = useTypedSelector((state) => state.myOrganization.users);
  const isFailed = useTypedSelector((state) => state.myOrganization.index.isFailed);
  const isLoading = useTypedSelector((state) => state.myOrganization.index.isLoading);
  const pagination = useTypedSelector((state) => state.myOrganization.pagination);
  const tableSearch = useTypedSelector((state) => state.myOrganization.tableSearch);
  const { organizationId, user } = authState;

  usePaginationWatch(pagination, [myOrganizationOperations.index.bind(null, organizationId || '')], [organizationId]);
  useTableSearchWatch<MyOrganizationUser>(tableSearch, [
    myOrganizationOperations.index.bind(null, organizationId || '')
  ]);

  const [activeOrganizationUser, setActiveOrganizationUser] = useState<MyOrganizationUser | null>(null);

  const [
    updateOrganizationUserWindowOpen,
    onUpdateOrganizationUserWindowOpen,
    onUpdateOrganizationUserWindowClose
  ] = useOpenHandler();
  const [
    deleteOrganizationUserWindowOpen,
    onDeleteOrganizationUserWindowOpen,
    onDeleteOrganizationUserWindowClose
  ] = useOpenHandler();

  if (!organizationId) {
    return null;
  }

  const organizationOwnersIds = organizationUsers.result.filter((userId) => organizationUsers.entities[userId].isOwner);

  return (
    <>
      <DataTable<MyOrganizationUser, 'actions'>
        enableSearch={true}
        columnDefinitions={[
          {
            name: 'email',
            sortable: true,
            render: (organizationUser) => (
              <>
                <div>{organizationUser.email}</div> {organizationUser.isOwner && <Strong>({t('common.owner')})</Strong>}
              </>
            )
          },
          {
            name: 'actions',
            render: (organizationUser) => (
              <TableActionButtonsContainer>
                <TableActionButton
                  icon={<EditIcon />}
                  tooltip={t('pages.myOrganization.table.buttons.edit')}
                  ButtonProps={{
                    disabled: !isOrganizationOwner(authState) || organizationUser.id === user.id,
                    onClick: () => {
                      setActiveOrganizationUser(organizationUser);
                      onUpdateOrganizationUserWindowOpen();
                    }
                  }}
                />
                <TableActionButton
                  icon={<DeleteIcon />}
                  tooltip={t('pages.myOrganization.table.buttons.delete')}
                  ButtonProps={{
                    disabled:
                      !isOrganizationOwner(authState) ||
                      organizationUser.id === user.id ||
                      (organizationUser.isOwner && organizationOwnersIds.length <= 1),
                    onClick: () => {
                      setActiveOrganizationUser(organizationUser);
                      onDeleteOrganizationUserWindowOpen();
                    }
                  }}
                />
              </TableActionButtonsContainer>
            )
          },
          {
            name: 'createdAt',
            render: (organizationUser) => <>{formatTableTimestamp(organizationUser.createdAt)}</>,
            sortable: true
          }
        ]}
        tPath={'pages.myOrganization.table'}
        data={organizationUsers}
        tableSearchProps={{
          tableSearch,
          module: TableSearchStoreModule.MY_ORGANIZATION_USERS
        }}
        paginationProps={{
          pagination,
          module: PaginationStoreModule.MY_ORGANIZATION_USERS
          // onPageChange: () => {},
          // onPerChange: () => {}
        }}
        isFailed={isFailed}
        isLoading={isLoading}
      />

      <UpdateMyOrganizationUserWindow
        organizationId={organizationId}
        organizationUser={activeOrganizationUser}
        open={updateOrganizationUserWindowOpen}
        onCloseClick={onUpdateOrganizationUserWindowClose}
        fullScreenOnMobile
        disableSetAsOwnerCheckbox={Boolean(
          activeOrganizationUser &&
            organizationOwnersIds.length === 1 &&
            organizationOwnersIds.includes(activeOrganizationUser.id)
        )}
      />
      <DeleteMyOrganizationUserWindow
        type="user"
        organizationId={organizationId}
        organizationUser={activeOrganizationUser}
        open={deleteOrganizationUserWindowOpen}
        onCloseClick={onDeleteOrganizationUserWindowClose}
        fullScreenOnMobile
      />
    </>
  );
};

export default OrganizationUsersTable;
