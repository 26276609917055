import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

type PropertyProps = {
  title: string;
  value: any;
};

const Property: React.FC<PropertyProps> = ({ title, value }) => {
  return (
    <PropertyWrapper>
      <Typography variant="subtitle1">{title}:</Typography> <Typography>{value}</Typography>
    </PropertyWrapper>
  );
};

const PropertyWrapper = styled.div`
  width: 100%;
`;

export default Property;
