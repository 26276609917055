import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import MyOrganizationPage from './MyOrganizationPage';

const MyOrganizationRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.MY_ORGANIZATION,
    exact: true,
    name: 'myOrganization',
    public: false,
    show: true,
    component: MyOrganizationPage,
    scope: []
  }
];

export default MyOrganizationRoutes;
