import { TFunction } from 'i18next';

const HorizonalRulePlugin = (editor: any, t: TFunction) => {
  editor.ui.registry.addButton('hrCustom', {
    icon: 'horizontal-rule',
    onAction: function () {
      // Open window
      editor.windowManager.open({
        title: t('tinyMce.plugins.hrCustom.title'),
        body: {
          type: 'panel',
          items: [
            {
              type: 'listbox',
              name: 'align',
              label: t('tinyMce.plugins.hrCustom.alignment'),
              items: [
                { text: t('tinyMce.plugins.hrCustom.left'), value: 'left' },
                { text: t('tinyMce.plugins.hrCustom.center'), value: 'center' },
                { text: t('tinyMce.plugins.hrCustom.right'), value: 'right' }
              ]
            },
            {
              type: 'colorinput',
              name: 'color',
              label: t('tinyMce.plugins.hrCustom.color'),
              onAction: createColorPickerAction
            },
            {
              type: 'input',
              name: 'width',
              label: t('tinyMce.plugins.hrCustom.width'),
              placeholder: '100'
            },
            {
              type: 'input',
              name: 'height',
              label: t('tinyMce.plugins.hrCustom.height'),
              placeholder: '2'
            }
          ]
        },
        buttons: [
          {
            type: 'cancel',
            text: t('common.close')
          },
          {
            type: 'submit',
            text: t('common.save'),
            primary: true
          }
        ],
        // Generate and insert HTML upon submitting dialog
        onSubmit: function (api: any) {
          const data = api.getData();

          const hr = document.createElement('hr');

          // Set alignment
          switch (data.align) {
            case 'center':
              hr.style.marginLeft = 'auto';
              hr.style.marginRight = 'auto';
              break;
            case 'right':
              hr.style.marginLeft = 'auto';
              hr.style.marginRight = '0';
              break;
            default:
              hr.style.marginRight = 'auto';
              hr.style.marginLeft = '0';
              break;
          }

          // Set color
          hr.style.backgroundColor = data.color || '#000000';

          const unitRegex = /^\d+$/;
          // Set width
          hr.style.width = unitRegex.test(data.width) ? data.width + '%' : '100%';
          // Set height (thickness)
          hr.style.height = unitRegex.test(data.height) ? data.height + 'px' : '2px';

          // Set other styles
          hr.style.marginTop = '5px';
          hr.style.marginBottom = '5px';
          hr.style.overflow = 'hidden';

          // Insert content when the window form is submitted
          // editor.insertContent(hr.outerHTML);
          editor.undoManager.transact(function () {
            editor.selection.setContent(hr.outerHTML);
          });
          api.close();
        }
      });

      const defaultColor = '#000000'; // Set your default color value here
      const input = document.querySelector('.tox-color-input input');
      if (input) {
        input.setAttribute('value', defaultColor);
        input.dispatchEvent(new Event('change', { bubbles: true })); // Trigger change event
      }
    }
  });

  function createColorPickerAction() {
    const callback = editor.settings.color_picker_callback;
    if (callback) {
      return () => {
        callback.call(
          editor,
          (value: any) => {
            editor.value(value).fire('change');
          },
          editor.value()
        );
      };
    }
  }
};

export default HorizonalRulePlugin;
