import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core';
import Toast from '../../../Shared/Toast/Toast';
import { PrintCenter, PrintCenterForm } from '../../../../store/SystemSettings/types';
import { systemSettingsOperations } from '../../../../store/SystemSettings';
import Loader from '../../../Shared/Loading/Loader';
import { hasPermission } from '../../../../utils/permissions';
import { AdminRole } from '../../../../store/AdminRoles/types';
import { Alert } from '@material-ui/lab';
import { handleDamSystemError } from '../../../../utils/errorCodeToToast';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import LanguageIcon from '@material-ui/icons/Language';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DeletePrintCenterWindow from '../Windows/DeletePrintCenterWindow';
import CreateOrUpdatePrintCenter from '../Windows/CreateOrUpdatePrintCenter';

type PrintCentersProps = {
  role: AdminRole;
};

const PrintCenters: FunctionComponent<PrintCentersProps> = ({ role }) => {
  const { t } = useTranslation();
  const [printCenters, setPrintCenters] = useState<PrintCenter[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [activePrintCenter, setActivePrintCenter] = useState<PrintCenter | null>(null);
  const [deletePrintCenterWindowOpen, onDeletePrintCenterWindowOpen, onDeletePrintCenterWindowClose] = useOpenHandler();
  const [updatePrintCenterWindowOpen, onUpdatePrintCenterWindowOpen, onUpdatePrintCenterWindowClose] = useOpenHandler();
  const [createPrintCenterWindowOpen, onCreatePrintCenterWindowOpen, onCreatePrintCenterWindowClose] = useOpenHandler();

  const fetchPrintCenters = async () => {
    setLoading(true);
    try {
      const response = await systemSettingsOperations.getPrintCenters();
      setPrintCenters(response.data);
    } catch (e) {
      Toast.error(t('notifications.printCenters.getPrintCentersError'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrintCenters();
  }, []);

  const hasPermissionsToEdit = hasPermission(role, ['systemSettingsManage']);

  return (
    <>
      {loading && <Loader />}

      {!loading && (
        <>
          <Box marginTop={5} marginBottom={1}>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              {t('pages.systemSettings.printCenters.title')}
              <Button
                color="secondary"
                disabled={!hasPermissionsToEdit}
                size="small"
                variant="contained"
                style={{
                  marginLeft: 12
                }}
                onClick={() => {
                  setActivePrintCenter(null);
                  onCreatePrintCenterWindowOpen();
                }}
              >
                + {t('pages.systemSettings.printCenters.addNew')}
              </Button>
            </Typography>
          </Box>
          {printCenters.length === 0 && (
            <Alert variant="outlined" severity="info">
              {t('pages.systemSettings.printCenters.noPrintCenters')}
            </Alert>
          )}
          {printCenters.length > 0 && (
            <List dense>
              {printCenters.map((printCenter) => (
                <React.Fragment key={printCenter.id}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <LanguageIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={printCenter.name}
                      secondary={
                        <Link href={printCenter.url} target="_blank">
                          {printCenter.url}
                        </Link>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="update"
                        disabled={!hasPermissionsToEdit}
                        onClick={() => {
                          setActivePrintCenter(printCenter);
                          onUpdatePrintCenterWindowOpen();
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        disabled={!hasPermissionsToEdit}
                        aria-label="delete"
                        onClick={() => {
                          setActivePrintCenter(printCenter);
                          onDeletePrintCenterWindowOpen();
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )}
          {deletePrintCenterWindowOpen && (
            <DeletePrintCenterWindow
              open={deletePrintCenterWindowOpen}
              printCenter={activePrintCenter}
              onCloseClick={onDeletePrintCenterWindowClose}
              onSuccessfulSubmit={fetchPrintCenters}
              fullScreenOnMobile
            />
          )}
          {createPrintCenterWindowOpen && (
            <CreateOrUpdatePrintCenter
              type="create"
              open={createPrintCenterWindowOpen}
              onCloseClick={onCreatePrintCenterWindowClose}
              onSubmit={async (formData: PrintCenterForm) => {
                try {
                  await systemSettingsOperations.createPrintCenter(formData);
                  onCreatePrintCenterWindowClose();
                  Toast.success(t('notifications.printCenters.created'));
                  await fetchPrintCenters();
                } catch (e) {
                  handleDamSystemError(e, t);
                }
              }}
              fullScreenOnMobile
            />
          )}
          {updatePrintCenterWindowOpen && (
            <CreateOrUpdatePrintCenter
              type="update"
              open={updatePrintCenterWindowOpen}
              onCloseClick={onUpdatePrintCenterWindowClose}
              onSubmit={async (formData: PrintCenterForm) => {
                if (activePrintCenter) {
                  try {
                    await systemSettingsOperations.updatePrintCenter(activePrintCenter.id, formData);
                    onUpdatePrintCenterWindowClose();
                    Toast.success(t('notifications.printCenters.updated'));
                    await fetchPrintCenters();
                  } catch (e) {
                    handleDamSystemError(e, t);
                  }
                }
              }}
              printCenter={activePrintCenter}
              fullScreenOnMobile
            />
          )}
        </>
      )}
    </>
  );
};

export default PrintCenters;
