import React, { FunctionComponent, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { isEmailProject, isPrintProject, useTypedSelector } from '../../../utils';
import { PageContainer } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import { Grid, Paper, withTheme } from '@material-ui/core';
import Toast from '../../Shared/Toast/Toast';
import Loader from '../../Shared/Loading/Loader';
import styled from 'styled-components';
import { EmailProjectStatus } from '../../../store/EmailProjects/types';
import { PrintProjectStatus } from '../../../store/PrintProjects/types';
import EmailProjectDetailsPage from './EmailProjectDetailsPage';
import { dashboardOperations } from '../../../store/Dashboard';
import PrintProjectDetailsPage from './PrintProjectDetailsPage';
import ProjectDetailsErrorInfo from './Shared/ProjectDetailsErrorInfo';
import { AnyProject } from '../../../store/Dashboard/types';

type ProjectDetailsPageProps = RouteComponentProps<{ projectId: string }> & {};

const ProjectDetailsPage: FunctionComponent<ProjectDetailsPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { project, error, isFailed, isLoading } = useTypedSelector((state) => state.dashboard.details);
  const user = useTypedSelector((state) => state.auth.user);
  const projectId = match.params.projectId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(dashboardOperations.getProjectById(projectId));
      } catch (e) {
        Toast.error(t('notifications.getProjectDetails.error'));
      }
    };
    fetchData();
  }, [projectId]);

  const forceCheckOut = async (project: AnyProject) => {
    try {
      if (project) {
        await dashboardOperations.forceCheckOut(project.id);
        await dispatch(dashboardOperations.getProjectById(projectId));
      }
    } catch (e) {
      Toast.error(t('notifications.resetCheckIn.imNotEditingAnymoreError'));
    }
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.projectDetails.title')} />
      <Grid container spacing={3}>
        {!isLoading && isFailed && (
          <Grid item xs={12}>
            <ProjectDetailsErrorInfo error={error} />
          </Grid>
        )}
        {isLoading && (
          <Grid item xs={12}>
            <Loader />
          </Grid>
        )}
        {!isLoading && project && isEmailProject(project) && (
          <EmailProjectDetailsPage
            project={project}
            forceCheckOut={forceCheckOut}
            user={user}
            reFetchData={async () => {
              await dispatch(dashboardOperations.getProjectById(projectId));
            }}
          />
        )}

        {!isLoading && project && isPrintProject(project) && (
          <PrintProjectDetailsPage
            project={project}
            forceCheckOut={forceCheckOut}
            user={user}
            reFetchData={async () => {
              await dispatch(dashboardOperations.getProjectById(projectId));
            }}
          />
        )}
      </Grid>
    </PageContainer>
  );
};

export const ButtonsContainer = styled.div`
  text-align: right;
  & > * {
    margin: 0.2rem;
  }
`;

export const TableCellStatus = withTheme(styled.div<{ status: EmailProjectStatus | PrintProjectStatus }>`
  color: ${({ theme, status }) => {
    switch (status) {
      case PrintProjectStatus.IN_PROGRESS:
      case EmailProjectStatus.IN_PROGRESS:
        return theme.palette.warning.main;

      case PrintProjectStatus.SCHEDULED:
      case EmailProjectStatus.SCHEDULED:
        return theme.palette.primary.main;

      case PrintProjectStatus.DELIVERED:
      case EmailProjectStatus.PUBLISHED:
        return theme.palette.success.main;

      case EmailProjectStatus.ERROR:
        return theme.palette.error.main;
    }
  }};

  svg {
    position: relative;
    top: -3px;
    vertical-align: middle;
  }
`);

export default ProjectDetailsPage;
