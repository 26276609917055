import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../../Shared/StyledComponents';
import { Typography } from '@material-ui/core';

type DoAppSponsorRegionsProps = {
  open: boolean;
  regions: string[];
  onCloseClick: () => void;
};

const DoAppSponsorRegions: FunctionComponent<DoAppSponsorRegionsProps> = ({ open, regions, onCloseClick }) => {
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.doApp.sponsors.regions')}
      hideActionButtons
      onCloseClick={onCloseClick}
    >
      <Row>
        {regions.map((r) => (
          <Typography key={r} variant="subtitle1" gutterBottom>
            {r}
          </Typography>
        ))}
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DoAppSponsorRegions;
