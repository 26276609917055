import {
  AdminPrintTemplate,
  AdminPrintTemplatesActionsConstants,
  AdminPrintTemplatesIndexActionBEGIN,
  AdminPrintTemplatesIndexActionFAILED,
  AdminPrintTemplatesIndexActionSUCCESS,
  AdminPrintTemplatesIndexResponse,
  AdminPrintTemplatesKeywordAddedAction,
  AdminPrintTemplatesKeywordGroupAddedAction,
  AdminPrintTemplatesKeywordGroupRemovedAction,
  AdminPrintTemplatesKeywordRemovedAction
} from './types';

export const templatesIndexBegin = (): AdminPrintTemplatesIndexActionBEGIN => ({
  type: AdminPrintTemplatesActionsConstants.INDEX_BEGIN
});

export const templatesIndexSuccess = (
  payload: AdminPrintTemplatesIndexResponse
): AdminPrintTemplatesIndexActionSUCCESS => ({
  type: AdminPrintTemplatesActionsConstants.INDEX_SUCCESS,
  payload
});

export const templatesIndexFailed = (error: any): AdminPrintTemplatesIndexActionFAILED => ({
  type: AdminPrintTemplatesActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const keywordAdded = (template: AdminPrintTemplate): AdminPrintTemplatesKeywordAddedAction => ({
  type: AdminPrintTemplatesActionsConstants.KEYWORD_ADDED,
  payload: template
});

export const keywordGroupAdded = (template: AdminPrintTemplate): AdminPrintTemplatesKeywordGroupAddedAction => ({
  type: AdminPrintTemplatesActionsConstants.KEYWORD_GROUP_ADDED,
  payload: template
});

export const keywordRemoved = (template: AdminPrintTemplate): AdminPrintTemplatesKeywordRemovedAction => ({
  type: AdminPrintTemplatesActionsConstants.KEYWORD_REMOVED,
  payload: template
});

export const keywordGroupRemoved = (template: AdminPrintTemplate): AdminPrintTemplatesKeywordGroupRemovedAction => ({
  type: AdminPrintTemplatesActionsConstants.KEYWORD_GROUP_REMOVED,
  payload: template
});
