import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, GridList, GridListTile, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Alert, Pagination } from '@material-ui/lab';
import Loader from '../../../../../../Shared/Loading/Loader';
import { useTranslation } from 'react-i18next';
import { designProjectsOperations } from '../../../../../../../store/DesignProjects';
import { DesignProject } from '../../../../../../../store/DesignProjects/types';
import { DesignAsset } from '../Helpers/DesignAsset';
import ClearIcon from '@material-ui/icons/Clear';

const DesignImages: FunctionComponent = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [images, setImages] = useState<DesignProject[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 12, total: 0, totalPages: 0, q: null });
  const [searchValue, setSearchValue] = useState<string>('');

  const fetchData = async (page: number, search: string) => {
    setLoading(true);
    try {
      const data = await designProjectsOperations.getDesignProjects(page, 12, search);
      setImages(data.data);
      setPagination(data.pagination);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1, '');
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
    fetchData(page, searchValue);
  };

  const handleSearch = (event: React.SyntheticEvent) => {
    event.preventDefault();
    fetchData(1, searchValue);
  };

  const handleSearchClear = () => {
    setSearchValue('');
    fetchData(1, '');
  };

  const CenteredLoader = () => (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );

  const AssetsContent = () => {
    if (loading) {
      return <>{loading && <CenteredLoader />}</>;
    }

    return (
      <>
        {loading && <CenteredLoader />}
        {!loading && (
          <>
            <GridList cellHeight={105} cols={3} spacing={10} style={{ padding: '10px', width: '400px' }}>
              {images.map((image) => (
                <GridListTile key={image.id}>
                  <DesignAsset image={image} />
                </GridListTile>
              ))}
            </GridList>
            {images.length === 0 && (
              <Alert severity="info" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                {t('imagesPalette.noDesignAssetsFound')}
              </Alert>
            )}
          </>
        )}
        {pagination.total > 0 && (
          <Pagination
            count={pagination.totalPages}
            page={pagination.page}
            shape="rounded"
            onChange={handleChange}
            size="small"
            style={{ marginTop: 'auto' }}
          />
        )}
      </>
    );
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column" flexGrow={1}>
      <SearchBarRow>
        <form onSubmit={handleSearch} style={{ width: '100%', marginTop: '10px', marginRight: '10px' }}>
          <TextField
            placeholder={t('brandfolder.search')}
            type="text"
            variant="outlined"
            size="small"
            value={searchValue}
            fullWidth
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              endAdornment: searchValue !== '' && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleSearchClear}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </form>
      </SearchBarRow>
      <AssetsContent />
    </Box>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  height: 20rem;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const SearchBarRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 370px;
`;

export default DesignImages;
