import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageContentPaper } from '../../PageStyledComponents';
import { useTypedSelector } from '../../../../utils';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { AdminSelectInput, AdminTextInput } from '../Shared/AdminFormInputs';
import { Form, Formik, FormikProps } from 'formik';
import { Box, Button, Grid, MenuItem, Typography } from '@material-ui/core';
import { UserType } from '../../../../store/AdminUsers/types';
import Toast from '../../../Shared/Toast/Toast';
import { adminUsersOperations } from '../../../../store/AdminUsers';
import {
  AdminRole,
  AdminUpdateRoleValues,
  DefaultRoleName,
  defaultRoleNames,
  RolePermissions
} from '../../../../store/AdminRoles/types';
import * as Yup from 'yup';
import app from '../../../../config/app/app';
import PermissionsGrid from './Partials/PermissionsGrid';
import { getDefaultPermissions } from '../../../../utils/permissions';
import { adminRolesOperations } from '../../../../store/AdminRoles';
import { RouteComponentProps } from 'react-router';

type AdminCreateRolePageProps = RouteComponentProps<{ roleId?: string }> & {};

const AdminCreateRolePage: FunctionComponent<AdminCreateRolePageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [role, setRole] = useState<AdminRole | null>(null);
  const [userTypes, setUserTypes] = useState<UserType[]>([]);
  const [permissions, setPermissions] = useState<RolePermissions>(getDefaultPermissions(true));

  const isEditing = !!match.params.roleId;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const types = await adminUsersOperations.getUserTypes();
        setUserTypes(types.data);

        if (isEditing && match.params.roleId) {
          const fetchedRole = await adminRolesOperations.show(match.params.roleId);
          setRole(fetchedRole);
          setPermissions(fetchedRole.permissions);
        }
      } catch (e) {
        Toast.error(t('notifications.getUserTypes.error'));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const backButton = {
    onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ROLES.INDEX)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const createRole = async (values: AdminUpdateRoleValues) => {
    try {
      await adminRolesOperations.create(values, permissions);
      Toast.success(t('notifications.adminRoles.success'));
      dispatch(push(linksConstants.ADMINISTRATION.ROLES.INDEX));
    } catch (e) {
      Toast.error(t('notifications.adminRoles.error'));
    } finally {
      setLoading(false);
    }
  };

  const updateRole = async (values: AdminUpdateRoleValues) => {
    if (!role) return;
    try {
      await adminRolesOperations.update(role.id, values, permissions);
      Toast.success(t('notifications.adminRoles.success'));
      dispatch(push(linksConstants.ADMINISTRATION.ROLES.INDEX));
    } catch (e) {
      Toast.error(t('notifications.adminRoles.error'));
    } finally {
      setLoading(false);
    }
  };

  const renderBasicDataForm = (props: FormikProps<AdminUpdateRoleValues>) => (
    <Form>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <AdminTextInput
            t={t}
            name="name"
            section="adminRoles"
            disabled={isEditing && role ? role.isDefault : false}
          />
        </Grid>
        <Grid item xs={6}>
          <AdminSelectInput
            t={t}
            name="userTypeId"
            section="adminRoles"
            disabled={isEditing && role ? role.isDefault : false}
            onChange={(e) => {
              const userTypeId = e.target.value;
              const userType = userTypes.find((userType) => userType.id === userTypeId);
              if (userType && userType.defaultRole) {
                setPermissions(userType.defaultRole.permissions);
              }
            }}
          >
            {userTypes.map((userType) => (
              <MenuItem key={userType.id} value={userType.id}>
                {t(`common.userTypes.${userType.name}`)}
              </MenuItem>
            ))}
          </AdminSelectInput>
        </Grid>

        <Grid item xs={12}>
          <AdminTextInput t={t} name="description" section="adminRoles" />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <Button
          style={{ marginTop: '1rem' }}
          color="secondary"
          variant="contained"
          onClick={props.submitForm}
          size="medium"
          type="submit"
          disabled={loading || props.isSubmitting || !props.isValid}
        >
          {t('common.save')}
        </Button>
      </Box>

      {props.values.userTypeId && (
        <Box mt={2}>
          <PermissionsGrid
            permissions={permissions}
            userType={userTypes.find((userType) => userType.id === props.values.userTypeId)}
            unlockAllPermissions={isEditing && role ? role.isDefault : false}
            onPermissionChange={(permissionName, enabled) =>
              setPermissions({
                ...permissions,
                [permissionName]: enabled
              })
            }
          />
        </Box>
      )}

      {props.values.userTypeId && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            style={{ marginTop: '1rem' }}
            color="secondary"
            variant="contained"
            onClick={props.submitForm}
            size="medium"
            type="submit"
            disabled={loading || props.isSubmitting || !props.isValid}
          >
            {t('common.save')}
          </Button>
        </Box>
      )}
    </Form>
  );

  if (isEditing && !role) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeader
        title={t(`pages.adminRoles.${isEditing ? 'edit' : 'create'}.title`)}
        leftActionButtons={[backButton]}
      />
      <PageContentPaper>
        <Formik
          initialValues={{
            name: isEditing && role ? role.name : '',
            description: isEditing && role ? role.description : '',
            userTypeId: isEditing && role ? role.userTypeId : ''
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .test('default-role-name', 'Role name is not allowed', (value: string) => {
                if (role && role.isDefault) {
                  return true;
                }
                return !defaultRoleNames.includes(value as DefaultRoleName);
              })
              .max(app.maxInputLength)
              .required(),
            description: Yup.string().max(app.maxInputLength).nullable(),
            userTypeId: Yup.string().required()
          })}
          onSubmit={isEditing ? updateRole : createRole}
        >
          {renderBasicDataForm}
        </Formik>
      </PageContentPaper>
    </PageContainer>
  );
};

export default AdminCreateRolePage;
