import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { campaignsOperations } from '../../../../store/Campaigns';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import { CampaignProject } from '../../../../store/Campaigns/types';

type UnassignProjectWindowProps = {
  open: boolean;
  campaignId: string;
  project: CampaignProject | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  onSuccessfulSubmit: () => void;
};

const UnassignProjectWindow: FunctionComponent<UnassignProjectWindowProps> = ({
  campaignId,
  project,
  onCloseClick,
  open,
  onSuccessfulSubmit
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!project) return null;

  const onSubmit = async () => {
    try {
      await dispatch(campaignsOperations.unassign(campaignId, project.id, project.type));
      onCloseClick();
      onSuccessfulSubmit();
      Toast.success(t('notifications.campaigns.projectUnassigned'));
    } catch (e) {
      Toast.error(t('notifications.campaigns.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.campaigns.windows.unassignProject')}
      okButtonText={t('pages.campaigns.windows.unassign')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.campaigns.windows.unassignProjectContent')}</Row>
      <Row>
        <Strong>{project.name}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default UnassignProjectWindow;
