import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, withTheme } from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import linksConstants from '../../../../config/app/linksConstants';
import { SharedProject } from '../../../../store/SharingCenter/types';

type SharedStoryNameCellProps = {
  project: SharedProject;
  customId?: string;
};

const SharedStoryNameCell: FunctionComponent<SharedStoryNameCellProps> = ({ project, customId }) => {
  const { t } = useTranslation();

  return (
    <ProjectArea>
      <StoryAvatar>S</StoryAvatar>
      <div>
        <ProjectTitle
          to={{
            pathname: linksConstants.STORY.EDIT(customId || project.id),
            state: { from: linksConstants.DASHBOARD.SHARING_CENTER }
          }}
        >
          {project.name}
        </ProjectTitle>
        <SubTitle>{t('common.story')}</SubTitle>
      </div>
    </ProjectArea>
  );
};

const ProjectArea = styled.div`
  display: flex;
`;

export const ProjectTitle = withTheme(styled(Link)<{ disabled?: boolean }>`
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  word-break: normal;
  ${({ disabled }) => (disabled ? `pointer-events: none;` : '')}
`);

const SubTitle = withTheme(styled.div`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 0.8rem;
  padding-right: 2rem;
  word-break: normal;
`);

const ProjectAvatarStyled = withTheme(styled(Avatar)`
  margin-right: 1rem;
  position: relative;
  overflow: visible;
`);

const StoryAvatar = withTheme(styled(ProjectAvatarStyled)`
  background: #d85959;
`);

export default SharedStoryNameCell;
