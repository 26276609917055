import AdminEmailTemplatesPage from './AdminEmailTemplatesPage';
import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';

const AdminEmailTemplatesRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMINISTRATION.EMAIL_TEMPLATES.INDEX,
    exact: true,
    name: 'emailTemplates',
    public: false,
    show: true,
    component: AdminEmailTemplatesPage,
    scope: []
  }
];

export default AdminEmailTemplatesRoutes;
