import * as qs from 'query-string';

export function getLocationPaginationState() {
  const parsedSearch = qs.parse(window.location.search);

  const parsedPage = Number(parsedSearch.page) % 1 === 0 ? Number(parsedSearch.page) : 1;
  const parsedPer = Number(parsedSearch.per) % 1 === 0 ? Number(parsedSearch.per) : 10;
  const parsedQ = parsedSearch.q ? decodeURIComponent(parsedSearch.q as string) : null;

  return {
    page: parsedPage,
    per: parsedPer,
    q: parsedQ,
    total: 0,
    totalPages: 0
  };
}
