import update from 'immutability-helper';
import { AdminAdsActionsConstants, AdminAdsActionType, AdminAdsState } from './types';
import { AuthActionsConstants } from '../Auth/types';

const initialState: AdminAdsState = {
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

export default function adminAdsReducer(state = initialState, action: WithLogoutAction<AdminAdsActionType>) {
  switch (action.type) {
    case AdminAdsActionsConstants.INDEX_ADBANNERS_SUCCESS: {
      return state;
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return state;
    }
  }
}
