import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Paper, Tab, Tabs } from '@material-ui/core';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { EmailProject } from '../../../../../../../store/EmailProjects/types';
import { useTranslation } from 'react-i18next';
import UploadedImages from './UploadedImages';
import DesignImages from './DesignImages';

type ImagesProps = {
  project: EmailProject | null;
};

const AntTabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid #e8e8e8'
    },
    indicator: {
      backgroundColor: theme.palette.secondary.main
    }
  })
)(Tabs);

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      '&:hover': {
        color: theme.palette.secondary.main,
        opacity: 1
      },
      '&$selected': {
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium
      },
      '&:focus': {
        color: theme.palette.secondary.main
      }
    },
    selected: {}
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabProps {
  label: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%'
  },
  padding: {
    padding: theme.spacing(3)
  },
  tabs: {
    backgroundColor: theme.palette.background.paper
  }
}));

const Images: FunctionComponent<ImagesProps> = ({ project }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);

  const handleImagesTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <StyledPaper>
      <div className={classes.root}>
        <div className={classes.tabs}>
          <AntTabs value={tabValue} onChange={handleImagesTabChange}>
            <AntTab label={t('imagesPalette.imageListTitle')} />
            <AntTab label={t('imagesPalette.designProjects')} />
          </AntTabs>
        </div>
      </div>
      {tabValue === 0 && <UploadedImages project={project} />}
      {tabValue === 1 && <DesignImages />}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  min-height: 480px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  align-items: center;
`;

export default Images;
