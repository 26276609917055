import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Paper, withTheme } from '@material-ui/core';
import TemplateThumbnail from '../Thumbnail/TemplateThumbnail';

type TemplatePaperProps = {
  title: string | JSX.Element;
  description: string | JSX.Element;
  onClick?: () => void;
  color: string;
  id: string;
  thumbnailUrl: string | null;
};

const TemplatePaper: FunctionComponent<TemplatePaperProps> = ({ title, thumbnailUrl, onClick, id, description }) => {
  const [elevation, setElevation] = useState(2);

  return (
    <LinkNoUnderline onClick={onClick}>
      <Paper
        data-item-id={id}
        elevation={elevation}
        onMouseEnter={() => setElevation(5)}
        onMouseLeave={() => setElevation(2)}
        style={{
          cursor: onClick ? 'pointer' : 'default'
        }}
      >
        <TemplateThumbnail thumbnailUrl={thumbnailUrl} width="240px" height="320px" />
        <Texts>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Texts>
      </Paper>
    </LinkNoUnderline>
  );
};

const LinkNoUnderline = styled.div`
  margin: 0 2rem 2rem 0;
  display: inline-block;
  vertical-align: top;
  text-align: left;
`;
const Image = withTheme(styled.div<{ src: string }>`
  margin-bottom: 0.5rem;
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  min-width: 15rem;
  min-height: 18rem;
`);
const Texts = withTheme(styled.div`
  padding: 1rem;
  background: ${({ theme }) => theme.palette.common.white};
  letter-spacing: 0.08rem;
`);
const TextHolder = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-width: 12rem;
  letter-spacing: normal;
  line-height: normal;
  word-break: break-all;
`;
const Title = withTheme(styled(TextHolder)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  margin-bottom: 0.5rem;
`);
const Description = withTheme(styled(TextHolder)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 0.8rem;
`);

export default TemplatePaper;
