import { createOrderArray, httpRequest } from '../../utils';
import { DesignProject, DesignProjectsIndexResponse } from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { designProjectsActions, designProjectsRequests } from './index';
import { PrintProject } from '../PrintProjects/types';

export const index = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { designProjects } = getState();

  try {
    dispatch(designProjectsActions.designProjectsIndexBegin());

    const response = await getDesignProjects(
      designProjects.pagination.page,
      designProjects.pagination.per,
      designProjects.pagination.q,
      createOrderArray(designProjects.tableSearch.order)
    );

    dispatch(designProjectsActions.designProjectsIndexSuccess(response));
  } catch (e) {
    dispatch(designProjectsActions.designProjectsIndexFailed(e));
  }
};

export const getDesignProjects = async (page: number, per: number, q: string | null, order?: string[]) => {
  return await httpRequest.runRequest<DesignProjectsIndexResponse>(
    designProjectsRequests.index({
      requestParams: {
        page,
        per,
        q,
        order
      }
    })
  );
};

export const create = async (templateId: string, channelId?: string) => {
  return await httpRequest.runRequest<DesignProject>(
    designProjectsRequests.create({
      requestPayload: { templateId, channelId }
    })
  );
};

export const show = async (projectId: string) => {
  return await httpRequest.runRequest<DesignProject>(
    designProjectsRequests.show({
      requestParams: { projectId }
    })
  );
};

export const deleteDesignProject = (id: string) => async () => {
  await httpRequest.runRequest<void>(
    designProjectsRequests.deleteDesignProject({
      requestParams: { id }
    })
  );
};

export const saveContent = async (id: string) => {
  await httpRequest.runRequest<void>(
    designProjectsRequests.saveContent({
      requestParams: { id }
    })
  );
};

export const associateToChannel = async (projectId: string, channelId: string) => {
  await httpRequest.runRequest<DesignProject>(
    designProjectsRequests.associateToChannel({
      requestParams: { projectId, channelId }
    })
  );
};

export const saveName = async (project: DesignProject, name: string) => {
  return await httpRequest.runRequest<DesignProject>(
    designProjectsRequests.saveName({
      requestParams: { projectId: project.id },
      requestPayload: { name }
    })
  );
};

export const cloneProject = async (projectId: string, name: string) => {
  return await httpRequest.runRequest<DesignProject>(
    designProjectsRequests.cloneProject({
      requestParams: { projectId },
      requestPayload: { name }
    })
  );
};
