import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageContentPaper } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import { Button, Grid, Typography } from '@material-ui/core';
import { Row, Strong } from '../../Shared/StyledComponents';
import { useTypedSelector } from '../../../utils';
import pkg from '../../../../package.json';
import { push } from 'connected-react-router';
import linksConstants from '../../../config/app/linksConstants';
import { useDispatch } from 'react-redux';
import app from '../../../config/app/app';
import { getUserMainPage } from '../../../utils/redirection';

type SupportPageProps = {};

const SupportPage: FunctionComponent<SupportPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const version = useTypedSelector((state) => state.auth.version);
  const user = useTypedSelector((state) => state.auth.user);

  return (
    <PageContainer>
      <PageHeader title={t('pages.support.title')} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <PageContentPaper>
              <Typography variant="h6" gutterBottom>
                {t('pages.support.supportHintLine2')}
              </Typography>
              <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
              <Row>{t('pages.support.supportHintLine1')}</Row>
              <Row>
                {t('pages.support.emailLabel')}:{' '}
                <a target="_blank" rel="noopener noreferrer" href={`mailto:${t('pages.support.emailValue')}`}>
                  {t('pages.support.emailValue')}
                </a>
              </Row>
              <Row>
                {t('pages.support.phoneLabel')}:{' '}
                <a href={`tel:${t('pages.support.phoneValueHref')}`}>{t('pages.support.phoneValue')}</a>
              </Row>
            </PageContentPaper>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <PageContentPaper>
              <Typography variant="h6" gutterBottom>
                {t('pages.support.versions')}
              </Typography>
              <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
              <Row>
                <Strong>
                  {t('pages.support.webAppVersion')}: {pkg.version}
                </Strong>
              </Row>
              <Row>
                <Strong>
                  {t('pages.support.apiVersion')}: {version}
                </Strong>
              </Row>
            </PageContentPaper>
          </Grid>
        </Grid>
        {app.enableApplicationGuide && (
          <Grid item xs={12} md={6}>
            <Grid item xs={12}>
              <PageContentPaper>
                <Typography variant="h6" gutterBottom>
                  {t('pages.support.tutorial')}
                </Typography>
                <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
                <Row>{t('pages.support.tutorialInfo')}</Row>
                <Row>
                  <Button
                    style={{ marginTop: '1rem' }}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      dispatch(push(getUserMainPage(user)));
                      // @ts-ignore
                      window.userGuiding.previewGuide(18387);
                    }}
                    size="medium"
                    type="submit"
                  >
                    {t('pages.support.runTutorial')}
                  </Button>
                </Row>
              </PageContentPaper>
            </Grid>
          </Grid>
        )}
      </Grid>
    </PageContainer>
  );
};

export default SupportPage;
