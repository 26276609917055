import {
  AdminProjectsActionsConstants,
  AdminProjectsIndexResponse,
  FilterCheckboxWithStringValue,
  ProjectsIndexActionBEGIN,
  ProjectsIndexActionFAILED,
  ProjectsIndexActionSUCCESS,
  PublicationDateFilterChangePayload,
  SetAutomatedEmailFilterAction,
  SetOrganizationPrintCenterFilterAction,
  SetOrganizationNameFilterAction,
  SetOrganizationNumberFilterAction,
  SetProjectExternalIdFilterAction,
  SetProjectNameFilterAction,
  SetProjectStatusFilterAction,
  SetPublicationDateFilterAction,
  SetPublicationTypeFilterAction,
  SetTestProjectsFilterAction,
  TestProjectFilterOptions
} from './types';
import { TemplateTypeName } from '../Templates/types';
import { AnyProjectStatus } from '../Dashboard/types';

export const projectsIndexBegin = (): ProjectsIndexActionBEGIN => ({
  type: AdminProjectsActionsConstants.INDEX_BEGIN
});

export const projectsIndexSuccess = (payload: AdminProjectsIndexResponse): ProjectsIndexActionSUCCESS => ({
  type: AdminProjectsActionsConstants.INDEX_SUCCESS,
  payload
});

export const projectsIndexFailed = (error: any): ProjectsIndexActionFAILED => ({
  type: AdminProjectsActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const setPublicationTypeFilter = (payload: TemplateTypeName | 'orphan'): SetPublicationTypeFilterAction => ({
  type: AdminProjectsActionsConstants.SET_FILTER_PUBLICATION_TYPE,
  payload
});

export const setProjectStatusFilter = (status: AnyProjectStatus | 'locked'): SetProjectStatusFilterAction => ({
  type: AdminProjectsActionsConstants.SET_FILTER_PROJECT_STATUS,
  payload: status
});

export const setTestProjectsFilter = (payload: TestProjectFilterOptions): SetTestProjectsFilterAction => ({
  type: AdminProjectsActionsConstants.SET_TEST_PROJECTS_FILTER,
  payload
});

export const setAutomatedEmailFilter = (payload: boolean): SetAutomatedEmailFilterAction => ({
  type: AdminProjectsActionsConstants.SET_AUTOMATED_EMAIL_FILTER,
  payload
});

export const setPublicationDateFilter = (
  payload: PublicationDateFilterChangePayload
): SetPublicationDateFilterAction => ({
  type: AdminProjectsActionsConstants.SET_FILTER_PUBLICATION_DATE,
  payload
});

export const setPrintCenterFilter = (dataCenterId: string): SetOrganizationPrintCenterFilterAction => ({
  type: AdminProjectsActionsConstants.SET_FILTER_ORGANIZATION_EXTERNAL_DATA_CENTER,
  payload: dataCenterId
});

export const setOrganizationNameFilter = (payload: FilterCheckboxWithStringValue): SetOrganizationNameFilterAction => ({
  type: AdminProjectsActionsConstants.SET_FILTER_ORGANIZATION_NAME,
  payload
});

export const setOrganizationNumberFilter = (
  payload: FilterCheckboxWithStringValue
): SetOrganizationNumberFilterAction => ({
  type: AdminProjectsActionsConstants.SET_FILTER_ORGANIZATION_NUMBER,
  payload
});

export const setProjectNameFilter = (payload: FilterCheckboxWithStringValue): SetProjectNameFilterAction => ({
  type: AdminProjectsActionsConstants.SET_FILTER_PROJECT_NAME,
  payload
});

export const setProjectExternalIdFilter = (
  payload: FilterCheckboxWithStringValue
): SetProjectExternalIdFilterAction => ({
  type: AdminProjectsActionsConstants.SET_FILTER_PROJECT_EXTERNAL_ID,
  payload
});
