import {
  DoAppMerchantSubscribersActionsConstants,
  DoAppMerchantSubscribersIndexActionBEGIN,
  DoAppMerchantSubscribersIndexActionFAILED,
  DoAppMerchantSubscribersIndexActionSUCCESS,
  DoAppSubscribersIndexResponse
} from './types';

export const doAppSubscribersIndexBegin = (): DoAppMerchantSubscribersIndexActionBEGIN => ({
  type: DoAppMerchantSubscribersActionsConstants.INDEX_BEGIN
});

export const doAppSubscribersIndexSuccess = (
  payload: DoAppSubscribersIndexResponse
): DoAppMerchantSubscribersIndexActionSUCCESS => ({
  type: DoAppMerchantSubscribersActionsConstants.INDEX_SUCCESS,
  payload
});

export const doAppSubscribersIndexFailed = (error: any): DoAppMerchantSubscribersIndexActionFAILED => ({
  type: DoAppMerchantSubscribersActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});
