import React, { FunctionComponent } from 'react';
import { Button, Checkbox, FormControlLabel, Paper, withTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DesignerSettings } from './types';

type DesignerSettingsMenuProps = {
  onClose: () => void;
  onChange: (setting: keyof DesignerSettings, value: boolean) => void;
  onOpenMarginsSettings: () => void;
  settings: DesignerSettings;
};

const DesignerSettingsMenu: FunctionComponent<DesignerSettingsMenuProps> = ({
  onOpenMarginsSettings,
  onClose,
  settings,
  onChange
}) => {
  const { t } = useTranslation();

  const openMarginAndColumnsSettings = () => {
    onOpenMarginsSettings();
    onClose();
  };

  return (
    <SettingsPaper>
      <SettingsList>
        <Button onClick={openMarginAndColumnsSettings} fullWidth color={'primary'} variant="text" size="medium">
          {t('designerSettings.openMarginAndColumnsSettings')}
        </Button>
        <hr />

        {/*// @ts-ignore*/}
        {Object.keys(settings).map((settingName: any) => (
          <SettingItem
            key={settingName}
            control={
              <Checkbox
                checked={settings[settingName as keyof DesignerSettings]}
                onChange={(e) => onChange(settingName, e.target.checked)}
                name={settingName}
              />
            }
            label={t(`designerSettings.${settingName}`)}
          />
        ))}
      </SettingsList>

      <CloseButton onClick={onClose}>{t('common.close')}</CloseButton>
    </SettingsPaper>
  );
};
const SettingsPaper = styled(Paper)`
  padding: 1rem;
  width: 300px;
  max-height: 70vh;
  overflow: hidden;

  .MuiPaper-root {
    width: 300px;
    max-height: 70vh;
  }
`;

const SettingsList = styled.div`
  overflow: auto;
  max-height: calc(70vh - 110px);
`;

const SettingItem = styled(FormControlLabel)`
  display: block;
`;

const CloseButton = withTheme(styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-top: 0.8rem;
  display: inline-block;
`);

export default DesignerSettingsMenu;
