import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { MyOrganizationSwatchColor } from '../../../../store/MyOrganization/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';

type DeleteSwatchcolorWindowProps = {
  open: boolean;
  swatchColor: MyOrganizationSwatchColor;
  onCloseClick: () => void;
  onSubmit: () => void;
  fullScreenOnMobile: boolean;
};

const DeleteSwatchcolorWindow: FunctionComponent<DeleteSwatchcolorWindowProps> = ({
  onCloseClick,
  onSubmit,
  swatchColor,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onFormSubmit = async () => {
    try {
      await dispatch(myOrganizationOperations.deleteOrganizationSwatchColor(swatchColor.id));
      onCloseClick();
      onSubmit();
      Toast.success(t('notifications.myOrganization.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.myOrganization.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.myOrganization.windows.deleteOrganizationSwatchColor')}
      okButtonText={t('pages.myOrganization.windows.completelyDeleteSwatchColor')}
      onCloseClick={onCloseClick}
      onSubmit={onFormSubmit}
    >
      <Row>{t('pages.myOrganization.windows.deleteOrganizationSwatchColorContent')}</Row>
      <Row>
        <Strong>{swatchColor.name ? swatchColor.name : swatchColor.hex}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteSwatchcolorWindow;
