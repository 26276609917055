import {
  Campaign,
  CampaignsActionsConstants,
  CampaignsIndexActionBEGIN,
  CampaignsIndexActionFAILED,
  CampaignsIndexActionSUCCESS,
  CampaignsIndexResponse,
  CampaignsUpdateAction
} from './types';

export const campaignsIndexBegin = (): CampaignsIndexActionBEGIN => ({
  type: CampaignsActionsConstants.INDEX_BEGIN
});

export const campaignsIndexSuccess = (payload: CampaignsIndexResponse): CampaignsIndexActionSUCCESS => ({
  type: CampaignsActionsConstants.INDEX_SUCCESS,
  payload
});

export const campaignsIndexFailed = (error: any): CampaignsIndexActionFAILED => ({
  type: CampaignsActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const updateCampaign = (campaign: Campaign): CampaignsUpdateAction => ({
  type: CampaignsActionsConstants.UPDATE_CAMPAIGN,
  payload: {
    campaign
  }
});
