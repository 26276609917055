import React, { FunctionComponent, useState } from 'react';
import { Tooltip, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  StoryChannel,
  StoryChannelStatus,
  StoryChannelType,
  StoryEmailChannelPriority
} from '../../../../store/Stories/types';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ReportIcon from '@material-ui/icons/Report';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import UndoIcon from '@material-ui/icons/Undo';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import { useTypedSelector } from '../../../../utils';
import { hasPermission } from '../../../../utils/permissions';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import ChannelStatusButton from './ChannelStatusButton';
import InfoIcon from '@material-ui/icons/Info';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import WordpressPostsWindow from '../Windows/WordpressPostsWindow';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import { WordpressPost } from '../../../../store/MyOrganization/types';
import EmailApproveButton from './EmailApproveButton';

type ChannelStatusButtonsProps = {
  channel: StoryChannel;
  setChannelStatus: (
    channel: StoryChannel,
    status: StoryChannelStatus,
    muteToast: boolean,
    prevStatus?: StoryChannelStatus,
    priority?: StoryEmailChannelPriority
  ) => void;
  onPublishWindowOpen: () => void;
  onPublishErrorWindowOpen: () => void;
  isFormValid: boolean;
  expanded: false | StoryChannelType;
  isOwnerOfStory: boolean;
  disabled: boolean;
};

const ChannelStatusButtons: FunctionComponent<ChannelStatusButtonsProps> = ({
  channel,
  setChannelStatus,
  onPublishWindowOpen,
  onPublishErrorWindowOpen,
  isFormValid,
  expanded,
  isOwnerOfStory,
  disabled
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const role = useTypedSelector((state) => state.auth.role);
  const [wordpressPostsWindowOpen, onWordpressPostsWindowOpen, onWordpressPostsWindowClose] = useOpenHandler();
  const [wordpressPosts, setWordpressPosts] = useState<WordpressPost[]>();

  const approvalAllowed = isOwnerOfStory && hasPermission(role, ['storiesApproval']) && !disabled;
  const publishingAllowed = isOwnerOfStory && hasPermission(role, ['storiesPublish']) && !disabled;
  const editChannelsAllowed = isOwnerOfStory && hasPermission(role, ['storiesEditChannels']) && !disabled;
  const channelHasPublishButton = [
    StoryChannelType.FACEBOOK,
    StoryChannelType.TWITTER,
    StoryChannelType.INSTAGRAM,
    StoryChannelType.LINKEDIN,
    StoryChannelType.BLOG
  ].includes(channel.type);

  const readyToReviewButton = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!isFormValid && (
        <Tooltip title={t('pages.story.fillRequiredFields').toString()} placement="left">
          <InfoIcon fontSize="small" color="error" style={{ marginRight: 5 }} />
        </Tooltip>
      )}
      <ChannelStatusButton
        disabled={!editChannelsAllowed || !isFormValid}
        variant="outlined"
        color="#10adaf"
        startIcon={<ThumbUpAltOutlinedIcon />}
        label={t('pages.story.readyToReview')}
        onClick={() => setChannelStatus(channel, StoryChannelStatus.READY_TO_REVIEW, false)}
      />
    </div>
  );

  const approveButton = (type: StoryChannelType) => {
    if (type === StoryChannelType.EMAIL) {
      return (
        <EmailApproveButton approvalAllowed={approvalAllowed} channel={channel} setChannelStatus={setChannelStatus} />
      );
    }
    return (
      <ChannelStatusButton
        disabled={!approvalAllowed}
        variant="outlined"
        color={theme.palette.success.main}
        startIcon={<ThumbUpAltOutlinedIcon />}
        label={t('pages.story.approve')}
        onClick={() => setChannelStatus(channel, StoryChannelStatus.APPROVED, false)}
      />
    );
  };

  const rejectButton = () => (
    <ChannelStatusButton
      disabled={!approvalAllowed}
      variant="outlined"
      color={theme.palette.error.main}
      startIcon={<ThumbDownAltOutlinedIcon />}
      label={t('pages.story.reject')}
      onClick={() => setChannelStatus(channel, StoryChannelStatus.REJECTED, false)}
    />
  );

  // const backToInProgressButton = () => (
  //   <ChannelStatusButton
  //     disabled={!editChannelsAllowed}
  //     variant="outlined"
  //     color={theme.palette.secondary.main}
  //     startIcon={<EditOutlinedIcon />}
  //     label={t('pages.story.backToInProgress')}
  //     onClick={() => setChannelStatus(channel, StoryChannelStatus.IDLE)}
  //   />
  // );

  const approvedButton = () => (
    <ChannelStatusButton
      disabled={!approvalAllowed}
      variant="outlined"
      color={theme.palette.primary.main}
      startIcon={<UndoIcon />}
      label={t('pages.story.backToInProgress')}
      onClick={() => setChannelStatus(channel, StoryChannelStatus.IDLE, false)}
    />
  );

  const rejectedButton = () => (
    <ChannelStatusButton
      disabled={!editChannelsAllowed}
      variant="outlined"
      color={theme.palette.primary.main}
      startIcon={<UndoIcon />}
      label={t('pages.story.backToInProgress')}
      onClick={() => setChannelStatus(channel, StoryChannelStatus.IDLE, false)}
    />
  );

  const publishErrorButton = () => (
    <ChannelStatusButton
      disabled={!approvalAllowed}
      variant="outlined"
      color={theme.palette.error.main}
      startIcon={<ReportIcon />}
      label={t('pages.story.publishError')}
      onClick={() => onPublishErrorWindowOpen()}
    />
  );

  const scheduledButton = () => (
    <ChannelStatusButton
      disabled={!publishingAllowed}
      variant="outlined"
      color={theme.palette.primary.main}
      startIcon={<UndoIcon />}
      label={t('pages.story.unschedule')}
      onClick={() => setChannelStatus(channel, StoryChannelStatus.APPROVED, false, StoryChannelStatus.SCHEDULED)}
    />
  );

  const publishedButton = () =>
    channel.type !== StoryChannelType.BLOG && (
      <ChannelStatusButton
        disabled={!publishingAllowed}
        variant="text"
        color={theme.palette.secondary.main}
        startIcon={<EmojiEmotionsOutlinedIcon />}
        label={t('pages.story.published')}
      />
    );

  return (
    <>
      {channel.status === StoryChannelStatus.IDLE && expanded === channel.type && readyToReviewButton()}
      {channel.status === StoryChannelStatus.READY_TO_REVIEW && (
        <>
          {approveButton(channel.type)}
          {rejectButton()}
        </>
      )}
      {channel.status === StoryChannelStatus.PUBLISH_ERROR && publishErrorButton()}
      {channel.status === StoryChannelStatus.APPROVED && approvedButton()}
      {channel.status === StoryChannelStatus.REJECTED && rejectedButton()}
      {channel.status === StoryChannelStatus.SCHEDULED && scheduledButton()}
      {channel.status === StoryChannelStatus.PUBLISHED && publishedButton()}

      {channelHasPublishButton && channel.status === StoryChannelStatus.APPROVED && (
        <ChannelStatusButton
          onClick={() => onPublishWindowOpen()}
          disabled={!publishingAllowed}
          startIcon={<PublishOutlinedIcon />}
          buttonVariantColor="secondary"
          variant="contained"
          label={t('pages.story.publish')}
        />
      )}
      {channel.type === StoryChannelType.BLOG && channel.status === StoryChannelStatus.PUBLISHED && (
        <ChannelStatusButton
          onClick={() => {
            if (channel.wordpressPosts) {
              if (channel.wordpressPosts.length === 1) {
                const integration = channel.wordpressPosts[0].integration;
                if (integration) {
                  // Open the single WordPress post for editing in a new tab
                  window.open(
                    `${integration.url}/wp-admin/post.php?post=${channel.wordpressPosts[0].postId}&action=edit`,
                    '_blank'
                  );
                }
              } else if (channel.wordpressPosts.length > 1) {
                // Set WordPress posts and open the window to choose from multiple posts
                setWordpressPosts(channel.wordpressPosts);
                onWordpressPostsWindowOpen();
              }
            }
          }}
          startIcon={<OpenInNewIcon />}
          buttonVariantColor="secondary"
          variant="contained"
          label={t('pages.story.openWordpressPost')}
        />
      )}
      {wordpressPostsWindowOpen && wordpressPosts && (
        <WordpressPostsWindow
          open={wordpressPostsWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onWordpressPostsWindowClose}
          wordpressPosts={wordpressPosts}
        />
      )}
    </>
  );
};

export default ChannelStatusButtons;
