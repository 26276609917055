import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { AdminCreateEmailTemplateValues } from '../../../../../store/AdminEmailTemplates/types';
import { adminEmailTemplatesOperations } from '../../../../../store/AdminEmailTemplates';
import { useDispatch } from 'react-redux';
import { AdminSelectInput, AdminTextInput } from '../../Shared/AdminFormInputs';
import { MenuItem } from '@material-ui/core';
import Toast from '../../../../Shared/Toast/Toast';
import { EmailProjectTemplateTypeName } from '../../../../../store/Templates/types';
import app from '../../../../../config/app/app';
import { emailTemplateTypes, printTemplateTypes } from '../../../../../utils/projectTypeUtils';
import { FormikProps } from 'formik';
import { useTypedSelector } from '../../../../../utils';
import { isSystemOwner } from '../../../../../utils/permissions';

type AdminCreateEmailTemplateWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminCreateEmailTemplateWindow: FunctionComponent<AdminCreateEmailTemplateWindowProps> = ({
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authState = useTypedSelector((state) => state.auth);

  const onSubmit = async (values: AdminCreateEmailTemplateValues) => {
    try {
      await dispatch(adminEmailTemplatesOperations.create(values));
      await dispatch(adminEmailTemplatesOperations.index());
      onCloseClick();
      Toast.success(t('notifications.adminEmailTemplates.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminEmailTemplates.errorOperation'));
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="name" section="adminEmailTemplates" />
      <AdminTextInput t={t} name="description" section="adminEmailTemplates" />
      {isSystemOwner(authState) && <AdminTextInput t={t} name="overrideAdZones" section="adminEmailTemplates" />}
      <AdminSelectInput t={t} name="type" section="adminEmailTemplates">
        {emailTemplateTypes.map((name) => (
          <MenuItem key={name} value={name}>
            {t(`common.projectType.${name}`)}
          </MenuItem>
        ))}
      </AdminSelectInput>
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.create')}
      header={t('pages.adminEmailTemplates.windows.createEmailTemplate')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{ name: '', description: '', type: EmailProjectTemplateTypeName.EMAIL, overrideAdZones: null }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(app.maxInputLength).required(),
        description: Yup.string().max(app.maxInputLength).required(),
        overrideAdZones: Yup.string().max(app.maxInputLength).nullable(),
        type: Yup.string().required()
      })}
    />
  );
};

export default AdminCreateEmailTemplateWindow;
