import { createOrderArray, httpRequest } from '../../utils';
import { AdminRole, AdminRolesIndexResponse, AdminUpdateRoleValues, RolePermissions } from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { adminRolesActions, adminRolesRequests } from './index';
import { AdminKeyword } from '../AdminKeywords/types';

export const index = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { adminRoles } = getState();

  try {
    dispatch(adminRolesActions.rolesIndexBegin());

    const response = await httpRequest.runRequest<AdminRolesIndexResponse>(
      adminRolesRequests.index({
        requestParams: {
          page: adminRoles.pagination.page,
          per: adminRoles.pagination.per,
          q: adminRoles.pagination.q,
          order: createOrderArray(adminRoles.tableSearch.order)
        }
      })
    );

    dispatch(adminRolesActions.rolesIndexSuccess(response));
  } catch (e) {
    dispatch(adminRolesActions.rolesIndexFailed(e));
  }
};

export const addKeywordToRole = (role: AdminRole, keyword: AdminKeyword) => async (dispatch: Dispatch) => {
  const updatedRole = await httpRequest.runRequest<AdminRole>(
    adminRolesRequests.addKeywordToRole({
      requestParams: { roleId: role.id },
      requestPayload: { keywordId: keyword.id }
    })
  );
  dispatch(adminRolesActions.keywordAdded(updatedRole));
};

export const removeKeywordFromRole = (role: AdminRole, keyword: AdminKeyword) => async (dispatch: Dispatch) => {
  const updatedRole = await httpRequest.runRequest<AdminRole>(
    adminRolesRequests.removeKeywordFromRole({
      requestParams: { roleId: role.id },
      requestPayload: { keywordId: keyword.id }
    })
  );
  dispatch(adminRolesActions.keywordRemoved(updatedRole));
};

export const create = async (values: AdminUpdateRoleValues, permissions: RolePermissions) => {
  return await httpRequest.runRequest<AdminRole>(
    adminRolesRequests.create({
      requestPayload: {
        ...values,
        permissions
      }
    })
  );
};

export const update = async (id: string, values: AdminUpdateRoleValues, permissions: RolePermissions) => {
  return await httpRequest.runRequest<AdminRole>(
    adminRolesRequests.update({
      requestParams: {
        id
      },
      requestPayload: {
        ...values,
        permissions
      }
    })
  );
};

export const deleteRole = async (role: AdminRole) => {
  return await httpRequest.runRequest<AdminRole>(
    adminRolesRequests.deleteRole({
      requestParams: {
        id: role.id
      }
    })
  );
};

export const show = async (id: string) => {
  return await httpRequest.runRequest<AdminRole>(
    adminRolesRequests.show({
      requestParams: {
        id
      }
    })
  );
};

export const getRolesOfUserType = async (userTypeId: string) => {
  return await httpRequest.runRequest<{ data: AdminRole[] }>(
    adminRolesRequests.getRolesOfUserType({
      requestParams: {
        userTypeId
      }
    })
  );
};
