import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import PageHeader from '../../../Shared/Layout/PageHeader';
import EmailCampaignsReport from './Partials/EmailCampaignsReport';
import OrganizationMailingListSizeReport from './Partials/OrganizationMailingListSizeReport';
import DailyOrganizationEmailActivityReport from './Partials/DailyOrganizationEmailActivityReport';
import DailyOrganizationPrintActivityReport from './Partials/DailyOrganizationsPrintActivityReport';
import DailyAdsReport from './Partials/DailyAdsReport';
import { hasPermission } from '../../../../utils/permissions';
import { useTypedSelector } from '../../../../utils';
import SubmittedPrintProjectsReport from './Partials/SubmittedPrintProjectsReport';
import DetailedEmailCampaignsReport from './Partials/DetailedEmailCampaignsReport';
import AdPlacementReport from './Partials/AdPlacementReport';

type ReportsPageProps = {};

const ReportsPage: FunctionComponent<ReportsPageProps> = () => {
  const { t } = useTranslation();
  const role = useTypedSelector((state) => state.auth.role);

  return (
    <PageContainer>
      <PageHeader title={t('pages.adminReports.title')} />
      <PageContent>
        {hasPermission(role, ['adminReportsAdPlacementReport']) && <AdPlacementReport />}
        {hasPermission(role, ['adminReportsSubmittedPrintProjectsReport']) && <SubmittedPrintProjectsReport />}
        {hasPermission(role, ['adminReportsDetailedEmailCampaignsReport']) && <DetailedEmailCampaignsReport />}
        {hasPermission(role, ['adminReportsEmailCampaignsReport']) && <EmailCampaignsReport />}
        {hasPermission(role, ['adminReportsOrganizationMailingListSizeReport']) && (
          <OrganizationMailingListSizeReport />
        )}
        {hasPermission(role, ['adminReportsDailyOrganizationEmailActivityReport']) && (
          <DailyOrganizationEmailActivityReport />
        )}
        {hasPermission(role, ['adminReportsDailyOrganizationPrintActivityReport']) && (
          <DailyOrganizationPrintActivityReport />
        )}
        {hasPermission(role, ['adminReportsDailyAdsReport']) && <DailyAdsReport />}
      </PageContent>
    </PageContainer>
  );
};

export default ReportsPage;
