import update from 'immutability-helper';
import { createPaginationReducer } from '../../Pagination/reducers';
import { PaginationStoreModule } from '../../Pagination/types';
import { createNetworkErrorObject } from '../../../utils';
import { AuthActionsConstants } from '../../Auth/types';
import { createTableSearchReducer } from '../../TableSearch/reducers';
import { TableSearchStoreModule } from '../../TableSearch/types';
import {
  DoAppSubscriber,
  DoAppMerchantSubscribersActionsConstants,
  DoAppMerchantSubscribersActionType,
  DoAppMerchantSubscribersState
} from './types';
import { normalizeDoAppMerchantSubscribers } from '../../helpers';

const initialState: DoAppMerchantSubscribersState = {
  data: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: {
    page: 1,
    per: 10,
    q: null,
    total: 0,
    totalPages: 0
  },
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const doAppMerchantSubscribersPaginationReducer = createPaginationReducer<
  DoAppMerchantSubscribersState,
  DoAppSubscriber
>(PaginationStoreModule.DO_APP_MERCHANT_SUBSCRIBERS);

const doAppMerchantSubscribersTableSearchReducer = createTableSearchReducer<
  DoAppSubscriber,
  DoAppMerchantSubscribersState
>(TableSearchStoreModule.DO_APP_MERCHANT_SUBSCRIBERS);

export default function doAppMerchantSubscribersReducer(
  state = initialState,
  action: WithLogoutAction<DoAppMerchantSubscribersActionType>
) {
  switch (action.type) {
    case DoAppMerchantSubscribersActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case DoAppMerchantSubscribersActionsConstants.INDEX_SUCCESS: {
      const data = normalizeDoAppMerchantSubscribers(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        data: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case DoAppMerchantSubscribersActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        data: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return doAppMerchantSubscribersTableSearchReducer(
        doAppMerchantSubscribersPaginationReducer(state, action),
        action
      ) as DoAppMerchantSubscribersState;
    }
  }
}
