import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Toast from '../../../Shared/Toast/Toast';
import Loader from '../../../Shared/Loading/Loader';
import { AdminRole } from '../../../../store/AdminRoles/types';
import { User } from '../../../../store/Auth/types';
import { Campaign, CampaignProjectType } from '../../../../store/Campaigns/types';
import { printProjectsOperations } from '../../../../store/PrintProjects';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import ProjectCampaignItem from './ProjectCampaignItem';
import { storiesOperations } from '../../../../store/Stories';
import { hasPermission } from '../../../../utils/permissions';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@material-ui/core';

type ProjectCampaignsProps = {
  projectId: string;
  role: AdminRole;
  user: User;
  type: CampaignProjectType;
};

const ProjectCampaigns: React.FC<ProjectCampaignsProps> = ({ projectId, type, role, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let campaigns: {
          data: Campaign[];
        } = { data: [] };
        if (type === 'email') {
          campaigns = await emailProjectsOperations.getEmailProjectCampaigns(projectId);
        } else if (type === 'print') {
          campaigns = await printProjectsOperations.getPrintProjectCampaigns(projectId);
        } else if (type === 'story') {
          campaigns = await storiesOperations.getStoryCampaigns(projectId);
        }
        setCampaigns(campaigns.data);
      } catch (e) {
        Toast.error(t('notifications.campaigns.cannotFetchCampaigns'));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const onUnassignCampaign = async (campaign: Campaign) => {
    setCampaigns(campaigns.filter((c) => c.id !== campaign.id));
  };

  return (
    <CampaignsList>
      {loading && <Loader />}

      {!loading && !campaigns.length && <NoCampaigns>{t('pages.campaigns.noCampaignsAssigned')}</NoCampaigns>}

      {!loading &&
        campaigns.map((campaign) => (
          <ProjectCampaignItem
            onUnassignCampaign={onUnassignCampaign}
            key={campaign.id}
            campaign={campaign}
            projectId={projectId}
            type={type}
            role={role}
            user={user}
          />
        ))}

      {!loading && (
        <Box display="flex" justifyContent="flex-end" marginTop={2}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => dispatch(push(linksConstants.CAMPAIGNS.ASSIGN(projectId, type)))}
            size="small"
            disabled={!hasPermission(role, ['campaignsEdit'])}
          >
            {t('pages.campaigns.assignToCampaign')}
          </Button>
        </Box>
      )}
    </CampaignsList>
  );
};

const CampaignsList = styled.div`
  width: 100%;
`;

const NoCampaigns = styled.div`
  text-align: center;
  margin: 1rem 0;
  font-size: 0.8rem;
  font-style: italic;
  opacity: 0.8;
`;

export default ProjectCampaigns;
