import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Story, StoryContentContributor } from '../../../../store/Stories/types';
import { storiesOperations } from '../../../../store/Stories';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';

type DeleteStoryContributorWindowProps = {
  open: boolean;
  story: Story | null;
  contributor: StoryContentContributor | null;
  onSuccessfulSubmit: () => void;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const DeleteStoryContributorWindow: FunctionComponent<DeleteStoryContributorWindowProps> = ({
  story,
  contributor,
  onCloseClick,
  onSuccessfulSubmit,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!story || !contributor) return null;

  const onSubmit = async () => {
    try {
      await dispatch(storiesOperations.removeStoryContributor(story.id, contributor.id));
      await dispatch(storiesOperations.indexStoryContributors(story.id));
      onCloseClick();
      onSuccessfulSubmit();
      Toast.success(t('notifications.story.storyContentContributorRemoved'));
    } catch (e) {
      Toast.error(t('notifications.common.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.story.windows.removeStoryContentContributor.header')}
      okButtonText={t('common.remove')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.story.windows.removeStoryContentContributor.content')}</Row>
      <Row>
        <Strong>{contributor.email}</Strong> {contributor.firstName} {contributor.lastName}
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteStoryContributorWindow;
