import update from 'immutability-helper';
import { createPaginationReducer } from '../../Pagination/reducers';
import { PaginationStoreModule } from '../../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../../utils';
import { AuthActionsConstants } from '../../Auth/types';
import { createTableSearchReducer } from '../../TableSearch/reducers';
import { TableSearchStoreModule } from '../../TableSearch/types';
import { DoAppMerchant, DoAppMerchantsActionsConstants, DoAppMerchantsActionType, DoAppMerchantsState } from './types';
import { normalizeDoAppMerchants } from '../../helpers';

const initialState: DoAppMerchantsState = {
  data: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const doAppMerchantsPaginationReducer = createPaginationReducer<DoAppMerchantsState, DoAppMerchant>(
  PaginationStoreModule.DO_APP_MERCHANTS
);

const doAppMerchantsTableSearchReducer = createTableSearchReducer<DoAppMerchant, DoAppMerchantsState>(
  TableSearchStoreModule.DO_APP_MERCHANTS
);

export default function doAppMerchantsReducer(
  state = initialState,
  action: WithLogoutAction<DoAppMerchantsActionType>
) {
  switch (action.type) {
    case DoAppMerchantsActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case DoAppMerchantsActionsConstants.INDEX_SUCCESS: {
      const data = normalizeDoAppMerchants(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        data: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case DoAppMerchantsActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        data: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return doAppMerchantsTableSearchReducer(
        doAppMerchantsPaginationReducer(state, action),
        action
      ) as DoAppMerchantsState;
    }
  }
}
