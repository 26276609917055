import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageContent } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import AdsStatisticsChart from './Partials/AdsStatisticsChart';
import AdsStatisticsTable from './Partials/AdsStatisticsTable';
import AdsStatisticsSummary from './Partials/AdsStatisticsSummary';
import AdsStatisticsDateRangeFilter from './Partials/AdsStatisticsDateRangeFilter';
import { adminAdsOperations } from '../../../store/AdminAds';
import { AdvertiserStatisticsData, Filter } from '../../../store/AdminAds/types';
import Loader from '../../Shared/Loading/Loader';
import moment from 'moment';

type AdsStatisticsPageProps = {};

const AdsStatisticsPage: FunctionComponent<AdsStatisticsPageProps> = () => {
  const { t } = useTranslation();
  const [advertiserStats, setAdvertiserStats] = useState<AdvertiserStatisticsData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedDateFrom, setSelectedDateFrom] = useState<Date | null>(new Date());
  const [selectedDateTo, setSelectedDateTo] = useState<Date | null>(new Date());
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [filter, setFilter] = useState<Filter | null>(null);

  const [impressionsSum, setImpressionsSum] = useState<number>(0);
  const [clicksSum, setClicksSum] = useState<number>(0);
  const [ctr, setCtr] = useState<number>(0);

  const handleDateFromChange = (date: Date | null) => {
    setSelectedDateFrom(date);
  };

  const handleDateToChange = (date: Date | null) => {
    setSelectedDateTo(date);
  };

  const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilter(filters.find((f) => f.value === event.target.value) || null);
  };

  const fetchAdsStatistics = async (startDate = '', endDate = '') => {
    const stats = await adminAdsOperations.getAdvertiserStatistics(startDate, endDate);
    setAdvertiserStats(stats.data.advertisers.data);

    let impressions = 0;
    let clicks = 0;
    let ctr = 0;
    setImpressionsSum(0);
    setClicksSum(0);
    setCtr(0);

    stats.data.advertisers.data.forEach((advertiserStat) => {
      impressions += parseInt(advertiserStat.impressions);
      clicks += parseInt(advertiserStat.clicks);
    });

    setImpressionsSum(impressions);
    setClicksSum(clicks);

    ctr = Math.round(((clicks / impressions) * 100 + Number.EPSILON) * 100) / 100;
    setCtr(ctr ? ctr : 0);

    setLoading(false);
  };

  useEffect(() => {
    let dateFrom = '';
    let dateTo = '';

    switch (filter?.value) {
      case 1:
        // yesterday
        dateFrom = moment().subtract(1, 'days').format('YYYY-MM-DD');
        dateTo = dateFrom;
        break;
      case 2:
        // this week
        dateFrom = moment().startOf('isoWeek').format('YYYY-MM-DD');
        dateTo = moment().endOf('isoWeek').format('YYYY-MM-DD');
        break;
      case 3:
        // last week
        dateFrom = moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
        dateTo = moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
        break;
      case 4:
        // last 7 days
        dateFrom = moment().subtract(6, 'days').format('YYYY-MM-DD');
        dateTo = moment().format('YYYY-MM-DD');
        break;
      case 5:
        // this month
        dateFrom = moment().startOf('month').format('YYYY-MM-DD');
        dateTo = moment().endOf('month').format('YYYY-MM-DD');
        break;
      case 6:
        // last month
        dateFrom = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
        dateTo = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        break;
      case 7:
        // specific dates
        dateFrom = moment(selectedDateFrom).format('YYYY-MM-DD');
        dateTo = moment(selectedDateTo).format('YYYY-MM-DD');
        break;
      default:
        // today
        dateFrom = moment().format('YYYY-MM-DD');
        dateTo = dateFrom;
        break;
    }

    setStartDate(dateFrom);
    setEndDate(dateTo);
    setLoading(true);
    fetchAdsStatistics(dateFrom, dateTo);
  }, [selectedDateFrom, selectedDateTo, filter]);

  const filters: Filter[] = [
    {
      value: 0,
      name: t('pages.adsStatistics.filters.today')
    },
    {
      value: 1,
      name: t('pages.adsStatistics.filters.yesterday')
    },
    {
      value: 2,
      name: t('pages.adsStatistics.filters.thisWeek')
    },
    {
      value: 3,
      name: t('pages.adsStatistics.filters.lastWeek')
    },
    {
      value: 4,
      name: t('pages.adsStatistics.filters.last7Days')
    },
    {
      value: 5,
      name: t('pages.adsStatistics.filters.thisMonth')
    },
    {
      value: 6,
      name: t('pages.adsStatistics.filters.lastMonth')
    },
    {
      value: 7,
      name: t('pages.adsStatistics.filters.specificDates')
    }
  ];

  return (
    <PageContainer>
      <PageHeader title={t('pages.adsStatistics.title')} />
      <PageContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <AdsStatisticsDateRangeFilter
              startDate={selectedDateFrom}
              endDate={selectedDateTo}
              handleStartDateChange={handleDateFromChange}
              handleEndDateChange={handleDateToChange}
              filter={filter}
              handleFilterChange={handleFilterChange}
              filters={filters}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '16px', marginBottom: '16px' }}>
          <Grid item xs={4}>
            {loading && <Loader />}
            {!loading && <AdsStatisticsSummary impressions={impressionsSum} clicks={clicksSum} ctr={ctr} />}
          </Grid>
          <Grid item xs={8}>
            <AdsStatisticsChart />
          </Grid>
        </Grid>
        {loading && <Loader />}
        {!loading && <AdsStatisticsTable advertiserStats={advertiserStats} startDate={startDate} endDate={endDate} />}
      </PageContent>
    </PageContainer>
  );
};

export const AdsStatisticsTitle = styled.div`
  font-weight: bold;
  color: #545871;
`;

export default AdsStatisticsPage;
