import update from 'immutability-helper';
import { normalizeProjects } from '../helpers';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { AdminProjectsActionsConstants, AdminProjectsActionType, AdminProjectsState, AnyProjectAdmin } from './types';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';
import { DashboardActionsConstants } from '../Dashboard/types';

const initialState: AdminProjectsState = {
  adminProjectsStateUnique: 'adminProjectsStateUnique',
  projects: {
    entities: {},
    result: []
  },
  onlineUsers: 0,
  totalOrganizations: 0,
  filters: {
    automatedEmail: false,
    testProjects: {
      include: false,
      only: false
    },
    publicationType: [],
    organizationName: {
      enabled: false,
      value: ''
    },
    organizationAccountNumber: {
      enabled: false,
      value: ''
    },
    projectName: {
      enabled: false,
      value: ''
    },
    externalId: {
      enabled: false,
      value: ''
    },
    status: [],
    publicationDateSelectedOption: null,
    publicationDateExact: new Date(),
    publicationDateRange: {
      from: new Date(),
      to: new Date()
    },
    printCenter: []
  },
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  },
  details: {
    error: null,
    isFailed: false,
    isLoading: false,
    project: null
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState()
};

const adminProjectsProjectsPaginationReducer = createPaginationReducer<AdminProjectsState, AnyProjectAdmin>(
  // @ts-ignore
  PaginationStoreModule.ADMIN_PROJECTS
);

const adminProjectsProjectsTableSearchReducer = createTableSearchReducer<AnyProjectAdmin, AdminProjectsState>(
  // @ts-ignore
  TableSearchStoreModule.ADMIN_PROJECTS
);

export default function adminProjectsReducer(state = initialState, action: WithLogoutAction<AdminProjectsActionType>) {
  switch (action.type) {
    case AdminProjectsActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case AdminProjectsActionsConstants.INDEX_SUCCESS: {
      const data = normalizeProjects(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        projects: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        onlineUsers: { $set: action.payload.onlineUsers },
        totalOrganizations: { $set: action.payload.totalOrganizations },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case AdminProjectsActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        projects: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AdminProjectsActionsConstants.SET_FILTER_PUBLICATION_TYPE: {
      const alreadyThere = state.filters.publicationType.includes(action.payload);

      return update(state, {
        filters: {
          publicationType: {
            $set: alreadyThere
              ? state.filters.publicationType.filter((val) => val !== action.payload)
              : [...state.filters.publicationType, action.payload]
          }
        }
      });
    }

    case AdminProjectsActionsConstants.SET_FILTER_PROJECT_STATUS: {
      const alreadyThere = state.filters.status.includes(action.payload);

      return update(state, {
        filters: {
          status: {
            $set: alreadyThere
              ? state.filters.status.filter((val) => val !== action.payload)
              : [...state.filters.status, action.payload]
          }
        }
      });
    }

    case AdminProjectsActionsConstants.SET_AUTOMATED_EMAIL_FILTER: {
      return update(state, {
        filters: {
          automatedEmail: { $set: action.payload }
        }
      });
    }

    case AdminProjectsActionsConstants.SET_TEST_PROJECTS_FILTER: {
      return update(state, {
        filters: {
          testProjects: { $set: action.payload }
        }
      });
    }

    case AdminProjectsActionsConstants.SET_FILTER_ORGANIZATION_EXTERNAL_DATA_CENTER: {
      const alreadyThere = state.filters.printCenter.includes(action.payload);

      return update(state, {
        filters: {
          printCenter: {
            $set: alreadyThere
              ? state.filters.printCenter.filter((val) => val !== action.payload)
              : [...state.filters.printCenter, action.payload]
          }
        }
      });
    }

    case AdminProjectsActionsConstants.SET_FILTER_PUBLICATION_DATE: {
      return update(state, {
        filters: {
          publicationDateSelectedOption: { $set: action.payload.publicationDateSelectedOption },
          publicationDateExact: { $set: action.payload.publicationDateExact },
          publicationDateRange: { $set: action.payload.publicationDateRange }
        }
      });
    }

    case DashboardActionsConstants.TEST_FLAG_TOGGLED: {
      const project = state.projects.entities[action.payload.id];
      if (!project) {
        return state;
      }

      return update(state, {
        projects: {
          entities: {
            [action.payload.id]: {
              test: { $set: action.payload.test }
            }
          }
        }
      });
    }

    case AdminProjectsActionsConstants.SET_FILTER_ORGANIZATION_NAME: {
      return update(state, { filters: { organizationName: { $set: action.payload } } });
    }

    case AdminProjectsActionsConstants.SET_FILTER_ORGANIZATION_NUMBER: {
      return update(state, { filters: { organizationAccountNumber: { $set: action.payload } } });
    }

    case AdminProjectsActionsConstants.SET_FILTER_PROJECT_NAME: {
      return update(state, { filters: { projectName: { $set: action.payload } } });
    }

    case AdminProjectsActionsConstants.SET_FILTER_PROJECT_EXTERNAL_ID: {
      return update(state, { filters: { externalId: { $set: action.payload } } });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return adminProjectsProjectsTableSearchReducer(
        adminProjectsProjectsPaginationReducer(state, action),
        action
      ) as AdminProjectsState;
    }
  }
}
