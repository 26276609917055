import { httpRequest } from '../../utils';
import { templatesRequests } from './index';
import { Template, TemplateTypeName, TemplateTypesResponse, UserTemplatesResponse } from './types';

export const getTemplates = async (type: TemplateTypeName, { showOnlyDesigns }: { showOnlyDesigns: boolean }) => {
  return await httpRequest.runRequest<UserTemplatesResponse>(
    templatesRequests.getTemplates({
      requestParams: { type, showOnlyDesigns }
    })
  );
};
export const getTemplate = async (id: string) => {
  return await httpRequest.runRequest<Template>(
    templatesRequests.getTemplate({
      requestParams: { id }
    })
  );
};
export const getTemplateHtml = async (id: string) => {
  return await httpRequest.runRequest<{ html: string }>(
    templatesRequests.getTemplateHtml({
      requestParams: { id }
    })
  );
};
export const getTemplateTypes = async () => {
  return await httpRequest.runRequest<TemplateTypesResponse>(templatesRequests.getTemplateTypes());
};
