import React, { useCallback, useEffect } from 'react';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import { CreateStoryValues, StoryEventChannelValues } from '../../../../store/Stories/types';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type SaveFormButtonProps = {
  props: FormikProps<CreateStoryValues> | FormikProps<StoryEventChannelValues>;
  saveForm: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
};

const SaveFormButton: React.FC<SaveFormButtonProps> = ({ props, saveForm, disabled = false, style }) => {
  const { t } = useTranslation();

  // formik autosave functionality
  const debounceMs = 1000;
  const formik = useFormikContext();
  const debouncedSubmit = useCallback(_.debounce(formik.submitForm, debounceMs), [formik.submitForm, debounceMs]);

  useEffect(() => {
    formik.dirty && debouncedSubmit();
  }, [debouncedSubmit, formik.dirty, formik.values]);

  return (
    <Button
      style={{ ...style, marginTop: '1rem', marginLeft: 'auto' }}
      color="secondary"
      variant="contained"
      onClick={saveForm}
      size="medium"
      type="submit"
      disabled={props.isSubmitting || !props.isValid || disabled}
    >
      {props.isSubmitting ? t('common.saving') : t('common.save')}
    </Button>
  );
};

export default SaveFormButton;
