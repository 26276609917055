import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

export const TableCell = styled.td`
  vertical-align: middle;
  padding: 1rem 16px 1rem 0;
  max-width: 250px;
`;

export const SubTitle = withTheme(styled.div`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 0.8rem;
  padding-right: 2rem;
  word-break: break-word;
`);
