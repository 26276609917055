import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { WordpressIntegration } from '../../../../store/MyOrganization/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';

type DeleteMyOrganizationUserWindowProps = {
  open: boolean;
  integration: WordpressIntegration | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  onSuccessfulSubmit?: () => void;
};

const DeleteWordpressIntegrationWindow: FunctionComponent<DeleteMyOrganizationUserWindowProps> = ({
  onCloseClick,
  integration,
  open,
  onSuccessfulSubmit
}) => {
  const { t } = useTranslation();

  if (!integration) return null;

  const onSubmit = async () => {
    try {
      await myOrganizationOperations.deleteWordpressIntegration(integration.id);

      if (onSuccessfulSubmit) {
        onSuccessfulSubmit();
      }
      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.myOrganization.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.myOrganization.windows.deleteWordpressIntegration.title')}
      okButtonText={t('common.remove')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.myOrganization.windows.deleteWordpressIntegration.content')}</Row>
      <Row>
        <Strong>{integration.name}</Strong>
      </Row>
      <Row>
        <Strong>{integration.url}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteWordpressIntegrationWindow;
