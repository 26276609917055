import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import CampaignsPage from './CampaignsPage';
import ManageCampaignPage from './ManageCampaignPage';
import CampaignProjectsPage from './CampaignProjectsPage';
import AssignCampaignPage from './AssignCampaignPage';

const CampaignsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.CAMPAIGNS.INDEX,
    exact: true,
    name: 'campaigns',
    public: false,
    show: true,
    component: CampaignsPage,
    scope: []
  },
  {
    path: linksConstants.CAMPAIGNS.ADD_PROJECTS(':id'),
    exact: false,
    name: 'add_projects',
    public: false,
    show: true,
    component: CampaignProjectsPage,
    scope: []
  },
  {
    path: linksConstants.CAMPAIGNS.ASSIGN(':projectId', ':projectType'),
    exact: true,
    name: 'assign',
    public: false,
    show: true,
    component: AssignCampaignPage,
    scope: []
  },
  {
    path: linksConstants.CAMPAIGNS.EDIT(':id'),
    exact: false,
    name: 'edit',
    public: false,
    show: true,
    component: ManageCampaignPage,
    scope: []
  }
];

export default CampaignsRoutes;
