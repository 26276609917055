import update from 'immutability-helper';
import {
  AdminEmailTemplate,
  AdminEmailTemplatesActionsConstants,
  AdminEmailTemplatesActionType,
  AdminEmailTemplatesState
} from './types';
import { normalizeEmailTemplates } from '../helpers';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: AdminEmailTemplatesState = {
  templates: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const adminEmailTemplatesPaginationReducer = createPaginationReducer<AdminEmailTemplatesState, AdminEmailTemplate>(
  PaginationStoreModule.ADMIN_EMAIL_TEMPLATES
);

const adminEmailTemplatesTableSearchReducer = createTableSearchReducer<AdminEmailTemplate, AdminEmailTemplatesState>(
  TableSearchStoreModule.ADMIN_EMAIL_TEMPLATES
);

export default function adminEmailTemplatesReducer(
  state = initialState,
  action: WithLogoutAction<AdminEmailTemplatesActionType>
) {
  switch (action.type) {
    case AdminEmailTemplatesActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case AdminEmailTemplatesActionsConstants.INDEX_SUCCESS: {
      const data = normalizeEmailTemplates(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        templates: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case AdminEmailTemplatesActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        templates: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    case AdminEmailTemplatesActionsConstants.KEYWORD_GROUP_REMOVED:
    case AdminEmailTemplatesActionsConstants.KEYWORD_GROUP_ADDED:
    case AdminEmailTemplatesActionsConstants.KEYWORD_REMOVED:
    case AdminEmailTemplatesActionsConstants.KEYWORD_ADDED: {
      return update(state, {
        templates: {
          entities: {
            [action.payload.id]: {
              keywords: { $set: action.payload.keywords },
              keywordGroups: { $set: action.payload.keywordGroups }
            }
          }
        }
      });
    }

    default: {
      return adminEmailTemplatesTableSearchReducer(
        adminEmailTemplatesPaginationReducer(state, action),
        action
      ) as AdminEmailTemplatesState;
    }
  }
}
