import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import {
  AdminCreateUserValues,
  AdminUpdateUserValues,
  AdminUserIdOnlyParam,
  AdminVerifyUserPayload,
  IndexAdminUsersRequestParams
} from './types';
import { SetPasswordFormValues } from '../Auth/types';

export const index = (settings: RequestSettings<IndexAdminUsersRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/users?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{}, AdminCreateUserValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/users'
  },
  payload: settings.requestPayload
});

export const update = (settings: RequestSettings<{}, AdminUpdateUserValues>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/users/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const show = (settings: RequestSettings<AdminUserIdOnlyParam>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/users/:id'
  },
  params: settings.requestParams
});

export const blockUser = (settings: RequestSettings<AdminUserIdOnlyParam>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/users/:id/block'
  },
  params: settings.requestParams
});

export const unblockUser = (settings: RequestSettings<AdminUserIdOnlyParam>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/users/:id/unblock'
  },
  params: settings.requestParams
});

export const deleteUser = (settings: RequestSettings<AdminUserIdOnlyParam>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/users/:id'
  },
  params: settings.requestParams
});

export const loginAsUser = (settings: RequestSettings<AdminUserIdOnlyParam>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/users/:id/login'
  },
  params: settings.requestParams
});

export const verify = (settings: RequestSettings<AdminUserIdOnlyParam, AdminVerifyUserPayload>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/users/:id/verify'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const changePassword = (
  settings: RequestSettings<AdminUserIdOnlyParam, SetPasswordFormValues>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/users/:id/change_password'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const addKeywordToUser = (
  settings: RequestSettings<{ userId: string }, { keywordId: string }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/users/:userId/keywords'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const addKeywordGroupToUser = (
  settings: RequestSettings<{ userId: string }, { keywordGroupId: string }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/users/:userId/keyword_groups'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeKeywordFromUser = (
  settings: RequestSettings<{ userId: string }, { keywordId: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/users/:userId/keywords'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeKeywordGroupFromUser = (
  settings: RequestSettings<{ userId: string }, { keywordGroupId: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/users/:userId/keyword_groups'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const getUserTypes = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/user_type'
  }
});

export const getUserPasswordPolicy = (settings: RequestSettings<{ userId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/users/:userId/password_policy'
  },
  params: settings.requestParams
});
