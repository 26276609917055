import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { GeneratedAiContent, StoryFieldName } from '../../../../store/Stories/types';
import { useTranslation } from 'react-i18next';

type GeneratedContentFormProps = {
  content: GeneratedAiContent[];
};

const GeneratedContentForm: React.FC<GeneratedContentFormProps> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <>
      {content.map((item, index) => (
        <Box mt={2} key={`${t(`pages.story.inputs.${item.name}`)}-${index}`}>
          <TextField
            label={t(`pages.story.inputs.${item.name}`)}
            variant="outlined"
            fullWidth
            value={item.value === '' ? t('pages.story.noContentGenerated') : item.value}
            disabled
            multiline={item.name === StoryFieldName.BODY}
            rows={item.name === StoryFieldName.BODY ? 10 : undefined}
            inputProps={{
              style: {
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '14px'
              }
            }}
            size="small"
          />
        </Box>
      ))}
    </>
  );
};

export default GeneratedContentForm;
