import { Dictionary } from 'lodash';
import { EmailProject } from '../EmailProjects/types';
import { PrintProject } from '../PrintProjects/types';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';

export type SharedProject = (EmailProject | PrintProject) & {
  sharedAt: Date;
  sharedProjectUnique: 'sharedProjectUnique';
  sourceOrganizationName: string;
  projectId: string;
  shareContext?: boolean;
};

export type SharingCenterState = StateWithPagination &
  StateWithTableSearch<SharedProject> & {
    type: SharedProjectsListingType;
    projects: NormalizedSharedProjects;
    sharingCenterUnique: 'sharingCenterUnique';

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type IndexSharedProjectsRequestParams = {
  per: number;
  page: number;
  order?: string[];
  q: string | null;
};

export type SharedProjectsIndexResponse = {
  data: SharedProject[];
  pagination: Pagination;
};

export type NormalizedSharedProjects = {
  entities: Dictionary<SharedProject>;
  result: string[];
};

export type SharedProjectsIndexActionBEGIN = {
  type: SharingCenterActionsConstants.INDEX_BEGIN;
};

export type SharedProjectsIndexActionSUCCESS = {
  type: SharingCenterActionsConstants.INDEX_SUCCESS;
  payload: SharedProjectsIndexResponse;
};

export type SharedProjectsIndexActionFAILED = {
  type: SharingCenterActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type SharedProjectsSetTypeAction = {
  type: SharingCenterActionsConstants.SET_LISTING_TYPE;
  payload: SharedProjectsListingType;
};

export enum SharingCenterActionsConstants {
  INDEX_BEGIN = 'SHARING_CENTER/INDEX/BEGIN',
  INDEX_SUCCESS = 'SHARING_CENTER/INDEX/SUCCESS',
  INDEX_FAILED = 'SHARING_CENTER/INDEX/FAILED',
  SET_LISTING_TYPE = 'SHARING_CENTER/SET_LISTING_TYPE'
}

export enum SharedProjectsListingType {
  SHARED_WITH_ME,
  I_SHARED_WITH
}

export type SharingCenterActionType =
  | SharedProjectsSetTypeAction
  | SharedProjectsIndexActionBEGIN
  | SharedProjectsIndexActionSUCCESS
  | SharedProjectsIndexActionFAILED;

export type ProjectShare = {
  id: string;
  organizationId: string;
  projectId: string;
  createdAt: string;
  updatedAt: string;
};
