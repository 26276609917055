import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../PageStyledComponents';
import { Grid } from '@material-ui/core';
import Toast from '../../Shared/Toast/Toast';
import Loader from '../../Shared/Loading/Loader';
import styled from 'styled-components';
import { appOperations } from '../../../store/App';

const FlipBookPublicPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const initFlipBook = async () => {
    setLoading(true);
    try {
      const el = document.getElementById('flip-book');
      const response = await appOperations.getMainStorageUrl();

      if (el) {
        // @ts-ignore
        const newPageFlip = new window.ePub.FlipBook(el, {
          baseMetaUrl: response.mainStorageUrl,
          /** Optional. Default: true */
          insertNavigationButtons: true,
          /** Optional. Default: '1px solid #dcdcdc' */
          mainBorder: '1px solid #dcdcdc',
          width: 614,
          height: 792,
          size: 'stretch',
          minWidth: 307,
          maxWidth: 614,
          minHeight: 396,
          maxHeight: 1584,
          autoSize: true,
          usePortrait: true,
          backgroundColor: '#F6F7FF'
        });

        // @ts-ignore
        window.newPageFlip = newPageFlip;
      }
    } catch (e) {
      Toast.error(t('notifications.generateFlipBook.error'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initFlipBook();
  }, []);

  return (
    <PageContainer>
      <Grid container spacing={3}>
        {loading && <Loader />}
        <FlipBookContainer item xs={12} style={{ width: '100%', height: '100%' }}>
          <FlipBook id="flip-book" />
        </FlipBookContainer>
      </Grid>
    </PageContainer>
  );
};

const FlipBook = styled.div`
  margin: 1rem auto;
`;

const FlipBookContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FlipBookPublicPage;
