import DoAppMerchantsPage from './DoAppMerchantsPage';
import { ApplicationRoute } from '../../../../../typings';
import linksConstants from '../../../../../config/app/linksConstants';

const DoAppMerchantsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.DO_APP.MERCHANTS.INDEX,
    exact: true,
    name: 'doAppMerchants',
    public: false,
    show: true,
    component: DoAppMerchantsPage,
    scope: []
  }
];

export default DoAppMerchantsRoutes;
