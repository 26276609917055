import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';
import { StoryChannel, StoryChannelType } from '../../../../store/Stories/types';
import { TextField, Box, Select, MenuItem, FormControl, Button, Divider } from '@material-ui/core';
import { getPromptValue, getSavedValuesFromLocalStorage, saveValueToLocalStorage } from '../Utils/storyUtils';
import { storiesOperations } from '../../../../store/Stories';
import Toast from '../../../Shared/Toast/Toast';
import PreviouslyEnteredPromptsWindow from './PreviouslyEnteredPromptsWindow';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import { ReactComponent as PreviousPromptsIcon } from '../../../../assets/icons/previous_prompts.svg';

type CreateWithAiWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  onHandleCreated: () => void;
  fullScreenOnMobile: boolean;
  activeChannelTypes: StoryChannelType[];
  selectedChannels: StoryChannelType[];
  channelsData: StoryChannel[];
  storyId: string;
  userId: string;
};

const CreateWithAiWindow: FunctionComponent<CreateWithAiWindowProps> = ({
  onCloseClick,
  onHandleCreated,
  open,
  activeChannelTypes,
  selectedChannels,
  channelsData,
  storyId,
  userId
}) => {
  const { t } = useTranslation();
  const [
    previouslyEnteredPromptsWindowOpen,
    onPreviouslyEnteredPromptsWindowOpen,
    onPreviouslyEnteredPromptsWindowClose
  ] = useOpenHandler();

  const LOCAL_STORAGE_KEY = `content-ai-prompt-${userId}`;
  const MAX_VALUES_TO_SAVE = 10;

  // Create an array of suggestions from the localStorage values
  const suggestions = getSavedValuesFromLocalStorage(LOCAL_STORAGE_KEY);

  const [sourceChannel, setSourceChannel] = useState<number>(-1);
  const [promptValue, setPromptValue] = useState<string>('');

  const handleSourceChannelSet = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const type: StoryChannelType = event.target.value as StoryChannelType;
    setSourceChannel(type as number);

    if (event.target.value === -1) {
      setPromptValue('');
      return;
    }

    const channelData = channelsData.find((channel) => channel.type === type);

    if (!channelData) return;

    setPromptValue(getPromptValue(channelData, type, t));
  };

  const handleCreate = async () => {
    try {
      await storiesOperations.generateAiContentForChannels(storyId, promptValue, selectedChannels);
      saveValueToLocalStorage(promptValue, LOCAL_STORAGE_KEY, MAX_VALUES_TO_SAVE);
      onCloseClick();
      onHandleCreated();
    } catch (error) {
      Toast.error(t('notifications.common.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.story.windows.createWithAi.header')}
      onCloseClick={onCloseClick}
      hideActionButtons
      maxWidth="md"
    >
      <Row>
        <Box display="flex" alignItems="center" mb={1}>
          {activeChannelTypes.length > 0 && (
            <>
              <span>{t('pages.story.windows.createWithAi.selectExisting')}</span>
              <FormControl variant="outlined" size="small" style={{ marginLeft: 5, marginRight: 5 }}>
                <Select
                  labelId="channel-select-label"
                  id="channel-select"
                  value={sourceChannel}
                  onChange={handleSourceChannelSet}
                >
                  <MenuItem value={-1}>{t('pages.story.windows.createWithAi.selectChannel')}</MenuItem>
                  {activeChannelTypes.map((channelType) => (
                    <MenuItem key={`source-channel-${channelType}`} value={channelType}>
                      {t(`pages.story.channels.${channelType}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          {suggestions.length === 0 && activeChannelTypes.length === 0 && (
            <span>{t('pages.story.windows.createWithAi.writeYourOwnPrompt')}:</span>
          )}
          {suggestions.length === 0 && activeChannelTypes.length > 0 && (
            <span>{t('pages.story.windows.createWithAi.writeYourOwn')}:</span>
          )}
        </Box>
        {suggestions.length > 0 && (
          <Box display="flex" alignItems="center" mb={1}>
            <div>
              {activeChannelTypes.length > 0 && <span>{t('pages.story.windows.createWithAi.or')}&nbsp;</span>}
              <span>{t('pages.story.windows.createWithAi.loadA')}</span>
              <Button
                color={'primary'}
                variant={'contained'}
                size="small"
                onClick={onPreviouslyEnteredPromptsWindowOpen}
                style={{ margin: '0 6px' }}
              >
                <PreviousPromptsIcon width={16} fill="#fff" style={{ marginRight: 5 }} />
                {t('pages.story.windows.createWithAi.previouslyEnteredPrompt')}
              </Button>
              <span>{t('pages.story.windows.createWithAi.writeYourOwn')}:</span>
            </div>
          </Box>
        )}
        <TextField
          id="standard-multiline-static"
          multiline
          rows={10}
          value={promptValue}
          onChange={(e) => setPromptValue(e.target.value)}
          fullWidth
          variant="outlined"
          inputProps={{
            style: {
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '14px'
            }
          }}
        />
      </Row>
      <Row>
        <Divider style={{ margin: '10px 0' }} />
        <Box display="flex" alignItems="center" justifyContent="space-between" marginTop={2}>
          <Button color={'secondary'} variant={'outlined'} size="medium" onClick={onCloseClick}>
            {t('common.close')}
          </Button>
          <Button
            color={'secondary'}
            variant={'contained'}
            size="medium"
            disabled={promptValue === '' || selectedChannels.length === 0}
            onClick={handleCreate}
          >
            {t('pages.story.windows.createWithAi.create')}
          </Button>
        </Box>
      </Row>
      {previouslyEnteredPromptsWindowOpen && (
        <PreviouslyEnteredPromptsWindow
          prompts={suggestions}
          onPromptLoad={(prompt) => {
            setPromptValue(prompt);
            onPreviouslyEnteredPromptsWindowClose();
          }}
          open={previouslyEnteredPromptsWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onPreviouslyEnteredPromptsWindowClose}
        />
      )}
    </ModalConfirmationWindow>
  );
};

export default CreateWithAiWindow;
