import { OrderColumnPayload, TableSearchActionsConstants, TableSearchStoreModule } from './types';

export const orderColumn = <Entity>(module: TableSearchStoreModule, payload: OrderColumnPayload<Entity>) => ({
  type: TableSearchActionsConstants.ORDER_COLUMN,
  payload,
  module
});

export const clearAllOrder = (module: TableSearchStoreModule) => ({
  type: TableSearchActionsConstants.CLEAR_ALL_ORDERS,
  module
});
