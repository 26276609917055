import React, { FunctionComponent } from 'react';
import { ScaleLinear } from 'd3';

type AxisRightProps = {
  yScale: ScaleLinear<number, number, never>;
  innerWidth: number;
  tickOffset?: number;
};

export const AxisRight: FunctionComponent<AxisRightProps> = ({ yScale, innerWidth, tickOffset = 3 }) => {
  return (
    <>
      {yScale
        .ticks()
        .filter(Number.isInteger)
        .map((tickValue, i) => (
          <g className="tick" transform={`translate(${innerWidth},${yScale(tickValue)})`} key={i}>
            <line x1={-innerWidth} />
            <text x={tickOffset} dy=".32em">
              {tickValue}
            </text>
          </g>
        ))}
    </>
  );
};
