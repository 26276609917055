import { createOrderArray, httpRequest } from '../../utils';
import {
  AdminCreateKeywordGroupValues,
  AdminCreateKeywordValues,
  AdminGetAllKeywordsDataResponse,
  AdminKeyword,
  AdminKeywordGroup,
  AdminKeywordGroupsIndexResponse,
  AdminKeywordsIndexResponse,
  AdminUpdateKeywordGroupValues,
  AdminUpdateKeywordValues
} from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { adminKeywordsActions, adminKeywordsRequests } from './index';

export const index = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { adminKeywords } = getState();

  try {
    dispatch(adminKeywordsActions.keywordsIndexBegin());

    const response = await httpRequest.runRequest<AdminKeywordsIndexResponse>(
      adminKeywordsRequests.index({
        requestParams: {
          page: adminKeywords.pagination.page,
          per: adminKeywords.pagination.per,
          q: adminKeywords.pagination.q,
          order: createOrderArray(adminKeywords.tableSearch.order)
        }
      })
    );

    dispatch(adminKeywordsActions.keywordsIndexSuccess(response));
  } catch (e) {
    dispatch(adminKeywordsActions.keywordsIndexFailed(e));
  }
};

export const create = (values: AdminCreateKeywordValues) => async () => {
  return await httpRequest.runRequest<void>(
    adminKeywordsRequests.create({
      requestPayload: values
    })
  );
};

export const update = (id: string, values: AdminUpdateKeywordValues) => async () => {
  return await httpRequest.runRequest<void>(
    adminKeywordsRequests.update({
      requestPayload: values,
      requestParams: { id }
    })
  );
};

export const deleteKeyword = (keywordId: string) => async () => {
  return await httpRequest.runRequest<void>(
    adminKeywordsRequests.deleteKeyword({
      requestParams: { id: keywordId }
    })
  );
};

export const getAllKeywords = () => async (dispatch: Dispatch) => {
  const response = await httpRequest.runRequest<AdminGetAllKeywordsDataResponse>(
    adminKeywordsRequests.getAllKeywords()
  );

  dispatch(adminKeywordsActions.getAllKeywords(response));
};

export const indexGroups = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { adminKeywordGroups } = getState();

  try {
    dispatch(adminKeywordsActions.keywordGroupsIndexBegin());

    const response = await httpRequest.runRequest<AdminKeywordGroupsIndexResponse>(
      adminKeywordsRequests.indexGroups({
        requestParams: {
          page: adminKeywordGroups.pagination.page,
          per: adminKeywordGroups.pagination.per,
          q: adminKeywordGroups.pagination.q,
          order: createOrderArray(adminKeywordGroups.tableSearch.order)
        }
      })
    );

    dispatch(adminKeywordsActions.keywordGroupsIndexSuccess(response));
  } catch (e) {
    dispatch(adminKeywordsActions.keywordGroupsIndexFailed(e));
  }
};

export const createGroup = (values: AdminCreateKeywordGroupValues) => async () => {
  return await httpRequest.runRequest<void>(
    adminKeywordsRequests.createGroup({
      requestPayload: values
    })
  );
};

export const updateGroup = (id: string, values: AdminUpdateKeywordGroupValues) => async () => {
  return await httpRequest.runRequest<void>(
    adminKeywordsRequests.updateGroup({
      requestPayload: values,
      requestParams: { id }
    })
  );
};

export const deleteKeywordGroup = (keywordGroupId: string) => async () => {
  return await httpRequest.runRequest<void>(
    adminKeywordsRequests.deleteKeywordGroup({
      requestParams: { id: keywordGroupId }
    })
  );
};

export const addKeywordToGroup = (group: AdminKeywordGroup, keyword: AdminKeyword) => async (dispatch: Dispatch) => {
  const updatedKeywordGroup = await httpRequest.runRequest<AdminKeywordGroup>(
    adminKeywordsRequests.addKeywordToGroup({
      requestParams: { id: group.id },
      requestPayload: { keywordId: keyword.id }
    })
  );
  dispatch(adminKeywordsActions.keywordAdded(updatedKeywordGroup));
};

export const removeKeywordFromGroup = (group: AdminKeywordGroup, keyword: AdminKeyword) => async (
  dispatch: Dispatch
) => {
  const updatedKeywordGroup = await httpRequest.runRequest<AdminKeywordGroup>(
    adminKeywordsRequests.removeKeywordFromGroup({
      requestParams: { id: group.id },
      requestPayload: { keywordId: keyword.id }
    })
  );
  dispatch(adminKeywordsActions.keywordRemoved(updatedKeywordGroup));
};
