import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { useDispatch } from 'react-redux';
import { isEmailProject, isPrintProject } from '../../../../utils';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import { printProjectsOperations } from '../../../../store/PrintProjects';
import { dashboardOperations } from '../../../../store/Dashboard';
import { AdminOrganization } from '../../../../store/AdminOrganizations/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import OrganizationTree, {
  buildOrganizationStructure
} from '../../MyOrganization/Partials/OrganizationTree/OrganizationTree';
import Loader from '../../../Shared/Loading/Loader';
import { sharingCenterOperations } from '../../../../store/SharingCenter';
import { SharedProject } from '../../../../store/SharingCenter/types';
import { AnyProject } from '../../../../store/Dashboard/types';
import { isSharedProject, isStory } from '../../../../utils/projectTypeUtils';
import { Story } from '../../../../store/Stories/types';
import { storiesOperations } from '../../../../store/Stories';

type ProjectShareWindowProps = {
  open: boolean;
  context: 'email' | 'print' | 'dashboard' | 'sharingCenter' | 'story';
  project: Story | SharedProject | AnyProject | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  onSuccessfulSubmit?: () => void;
};

const ProjectShareWindow: FunctionComponent<ProjectShareWindowProps> = ({
  project,
  context,
  onCloseClick,
  open,
  onSuccessfulSubmit
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [tree, setTree] = useState<AdminOrganization[]>([]);
  const [organizations, setOrganizations] = useState<string[]>([]);

  const projectId = project
    ? context === 'sharingCenter'
      ? isSharedProject(project as SharedProject | AnyProject)
        ? (project as SharedProject).projectId
        : project.id
      : project.id
    : null;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await myOrganizationOperations.getOrganizationTree();

        setTree(buildOrganizationStructure(res.data));

        let alreadyShared: string[] = [];
        if (project && projectId && isEmailProject(project as AnyProject | SharedProject)) {
          alreadyShared = (await emailProjectsOperations.show(projectId)).shares.map((share) => share.organizationId);
        }
        if (project && projectId && isPrintProject(project as AnyProject | SharedProject)) {
          alreadyShared = (await printProjectsOperations.show(projectId)).shares.map((share) => share.organizationId);
        }
        if (project && projectId && isStory(project as AnyProject | SharedProject)) {
          alreadyShared = (await storiesOperations.show(projectId)).shares.map((share) => share.organizationId);
        }

        setOrganizations(alreadyShared);

        setLoading(false);
      } catch (e) {
        Toast.error(t('notifications.myOrganization.cannotFetchOrganizationStructure'));
      }
    };

    if (open) {
      fetchData();
    }
  }, [open]);

  if (!project) return null;

  const onSubmit = async () => {
    try {
      if (projectId && isEmailProject(project as AnyProject | SharedProject)) {
        await dispatch(emailProjectsOperations.shareProject(projectId, organizations));
      }
      if (projectId && isPrintProject(project as AnyProject | SharedProject)) {
        await dispatch(printProjectsOperations.shareProject(projectId, organizations));
      }
      if (projectId && isStory(project as AnyProject | SharedProject)) {
        await storiesOperations.shareStory(projectId, organizations);
      }

      switch (context) {
        case 'dashboard':
          await dispatch(dashboardOperations.getUserProjectsInProgress());
          break;
        case 'email':
          await dispatch(emailProjectsOperations.getUserEmailProjects());
          break;
        case 'print':
          await dispatch(printProjectsOperations.getUserPrintProjects());
          break;
        case 'sharingCenter':
          await dispatch(sharingCenterOperations.getProjectsSharedWithMe());
          break;
        case 'story':
          await dispatch(storiesOperations.index());
          break;
      }
      if (isStory(project)) {
        Toast.success(t('notifications.projectShared.successStory'));
      } else {
        Toast.success(t('notifications.projectShared.success'));
      }

      if (onSuccessfulSubmit) {
        onSuccessfulSubmit();
      }

      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.projectShared.error'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={isStory(project) ? t('windows.projectShare.headerStory') : t('windows.projectShare.headerProject')}
      okButtonText={t('common.share')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{isStory(project) ? t('windows.projectShare.infoStory') : t('windows.projectShare.infoProject')}</Row>
      <Row>
        {loading && <Loader />}
        {!loading && <OrganizationTree items={tree} selectedIds={organizations} setOrganizations={setOrganizations} />}
      </Row>
    </ModalConfirmationWindow>
  );
};

export default ProjectShareWindow;
