import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { withTheme } from '@material-ui/core';

const fadeIn = () => keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const motion1 = () => keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const motion2 = () => keyframes`
   0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`;
const motion3 = () => keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const OpacityWrapper = styled.div`
  opacity: 0;
  animation: ${fadeIn()} 0.5s ease-in;
  animation-fill-mode: forwards;
`;

const LoaderSpinner = withTheme(styled.div`
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 auto;

  div > div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primary.main};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div > div:nth-child(1) {
    left: 6px;
    animation: ${motion1()} 0.6s infinite;
  }
  div > div:nth-child(2) {
    left: 6px;
    animation: ${motion2()} 0.6s infinite;
  }
  div > div:nth-child(3) {
    left: 26px;
    animation: ${motion2()} 0.6s infinite;
  }
  div > div:nth-child(4) {
    left: 45px;
    animation: ${motion3()} 0.6s infinite;
  }
`);

const Loader: FunctionComponent<{ style?: any }> = ({ style }) => {
  const [delayPassed, setDelayPassed] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayPassed(true);
    }, 700);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <LoaderSpinner style={style}>
      {delayPassed && (
        <OpacityWrapper>
          <div />
          <div />
          <div />
          <div />
        </OpacityWrapper>
      )}
    </LoaderSpinner>
  );
};

export default Loader;
