import React, { FunctionComponent, useEffect } from 'react';
import Loader from '../../../../Shared/Loading/Loader';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NoResultsFound } from '../../../PageStyledComponents';

type PrintPublishEmailPreviewStepProps = {
  onInit: () => void;
  loading: boolean;
  previewUrl: string;
};

const PrintPublishEmailPreviewStep: FunctionComponent<PrintPublishEmailPreviewStepProps> = ({
  loading,
  previewUrl,
  onInit
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    onInit();
  }, []);

  return (
    <>
      {loading && (
        <LoaderWrapper>
          <Loader />
          <NoResultsFound>{t('pages.projectPublish.loadingPreview')}</NoResultsFound>
        </LoaderWrapper>
      )}
      {!loading && <PreviewIframe src={previewUrl} />}
    </>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const PreviewIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

export default PrintPublishEmailPreviewStep;
