import { Dictionary } from 'lodash';
import { EmailProject } from '../EmailProjects/types';
import { PrintProject } from '../PrintProjects/types';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { TemplateTypeName } from '../Templates/types';
import { AnyProjectStatus, TestFlagToggledAction } from '../Dashboard/types';

export type AnyProjectAdmin = (EmailProject | PrintProject) & {
  organizationId: string;
  organizationName: string;
  dataCenterId: string | null;
  dataCenterName: string | null;
  externalId: number | null;
  accountNumber: string;
  emailCampaignRecipients: number | null;
  anyProjectAdminUnique: 'anyProjectAdmin';
};

export type AdminProjectsState = StateWithPagination &
  StateWithTableSearch<AnyProjectAdmin> & {
    projects: NormalizedAdminProjectsProjects;

    onlineUsers: number;
    totalOrganizations: number;
    filters: AdminProjectsFilters;

    adminProjectsStateUnique: 'adminProjectsStateUnique';
    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
    details: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
      project: AnyProjectAdmin | null;
    };
  };

export type FilterCheckboxWithStringValue = {
  enabled: boolean;
  value: string;
};

export type PublicationDateFilterSelectedOption = 'exact' | 'range' | null;

export type PublicationDateFilterChangePayload = {
  publicationDateSelectedOption: PublicationDateFilterSelectedOption;
  publicationDateExact: AdminProjectsFilters['publicationDateExact'];
  publicationDateRange: AdminProjectsFilters['publicationDateRange'];
};

export type AdminProjectsFilters = {
  publicationType: (TemplateTypeName | 'orphan')[];
  organizationName: FilterCheckboxWithStringValue;
  organizationAccountNumber: FilterCheckboxWithStringValue;
  projectName: FilterCheckboxWithStringValue;
  externalId: FilterCheckboxWithStringValue;
  status: (AnyProjectStatus | 'locked')[];
  publicationDateSelectedOption: PublicationDateFilterSelectedOption;
  publicationDateExact: Date | null;
  publicationDateRange: {
    from: Date | null;
    to: Date | null;
  };
  printCenter: string[];
  testProjects: TestProjectFilterOptions;
  automatedEmail: boolean;
};

export type TestProjectFilterOptions = {
  include: boolean;
  only: boolean;
};

export type IndexAdminProjectsProjectsRequestParams = {
  per: number;
  page: number;
  format: string;
  order?: string[];
  q: string | null;
} & IndexAdminProjectsProjectsRequestParamsFilters;

export type IndexAdminProjectsProjectsRequestParamsFilters = {
  publicationType?: string;
  status?: string;
  testProjects?: string;
  onlyTestProjects?: string;
  organizationName?: string;
  organizationNumber?: string;
  projectName?: string;
  dataCenter?: string;
  publicationDate?: string;
  publicationDateRange?: string;
  externalId?: string;
  automatedEmail?: string;
};

export type AdminProjectsIndexResponse = {
  data: AnyProjectAdmin[];
  onlineUsers: number;
  totalOrganizations: number;
  pagination: Pagination;
};

export type NormalizedAdminProjectsProjects = {
  entities: Dictionary<AnyProjectAdmin>;
  result: string[];
};

export type ProjectsIndexActionBEGIN = {
  type: AdminProjectsActionsConstants.INDEX_BEGIN;
};

export type ProjectsIndexActionSUCCESS = {
  type: AdminProjectsActionsConstants.INDEX_SUCCESS;
  payload: AdminProjectsIndexResponse;
};

export type ProjectsIndexActionFAILED = {
  type: AdminProjectsActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type SetPublicationTypeFilterAction = {
  type: AdminProjectsActionsConstants.SET_FILTER_PUBLICATION_TYPE;
  payload: TemplateTypeName | 'orphan';
};

export type SetProjectStatusFilterAction = {
  type: AdminProjectsActionsConstants.SET_FILTER_PROJECT_STATUS;
  payload: AnyProjectStatus | 'locked';
};

export type SetTestProjectsFilterAction = {
  type: AdminProjectsActionsConstants.SET_TEST_PROJECTS_FILTER;
  payload: TestProjectFilterOptions;
};

export type SetAutomatedEmailFilterAction = {
  type: AdminProjectsActionsConstants.SET_AUTOMATED_EMAIL_FILTER;
  payload: boolean;
};

export type SetPublicationDateFilterAction = {
  type: AdminProjectsActionsConstants.SET_FILTER_PUBLICATION_DATE;
  payload: PublicationDateFilterChangePayload;
};

export type SetOrganizationPrintCenterFilterAction = {
  type: AdminProjectsActionsConstants.SET_FILTER_ORGANIZATION_EXTERNAL_DATA_CENTER;
  payload: string;
};

export type SetFilterCheckboxWithStringValueAction<T> = {
  type: T;
  payload: FilterCheckboxWithStringValue;
};

export type SetOrganizationNameFilterAction = SetFilterCheckboxWithStringValueAction<
  AdminProjectsActionsConstants.SET_FILTER_ORGANIZATION_NAME
>;

export type SetOrganizationNumberFilterAction = SetFilterCheckboxWithStringValueAction<
  AdminProjectsActionsConstants.SET_FILTER_ORGANIZATION_NUMBER
>;

export type SetProjectNameFilterAction = SetFilterCheckboxWithStringValueAction<
  AdminProjectsActionsConstants.SET_FILTER_PROJECT_NAME
>;

export type SetProjectExternalIdFilterAction = SetFilterCheckboxWithStringValueAction<
  AdminProjectsActionsConstants.SET_FILTER_PROJECT_EXTERNAL_ID
>;

export enum AdminProjectsActionsConstants {
  SET_AUTOMATED_EMAIL_FILTER = 'ADMIN_PROJECTS/FILTERS/SET_AUTOMATED_EMAIL_FILTER',
  SET_FILTER_PROJECT_EXTERNAL_ID = 'ADMIN_PROJECTS/FILTERS/SET_FILTER_PROJECT_EXTERNAL_ID',
  SET_FILTER_PROJECT_NAME = 'ADMIN_PROJECTS/FILTERS/SET_FILTER_PROJECT_NAME',
  SET_FILTER_ORGANIZATION_NAME = 'ADMIN_PROJECTS/FILTERS/SET_FILTER_ORGANIZATION_NAME',
  SET_FILTER_ORGANIZATION_NUMBER = 'ADMIN_PROJECTS/FILTERS/SET_FILTER_ORGANIZATION_NUMBER',
  SET_FILTER_PUBLICATION_TYPE = 'ADMIN_PROJECTS/FILTERS/SET_FILTER_PUBLICATION_TYPE',
  SET_TEST_PROJECTS_FILTER = 'ADMIN_PROJECTS/FILTERS/SET_TEST_PROJECTS_FILTER',
  SET_FILTER_PROJECT_STATUS = 'ADMIN_PROJECTS/FILTERS/SET_FILTER_PROJECT_STATUS',
  SET_FILTER_PUBLICATION_DATE = 'ADMIN_PROJECTS/FILTERS/SET_FILTER_PUBLICATION_DATE',
  SET_FILTER_ORGANIZATION_EXTERNAL_DATA_CENTER = 'ADMIN_PROJECTS/FILTERS/SET_FILTER_ORGANIZATION_EXTERNAL_DATA_CENTER',
  INDEX_BEGIN = 'ADMIN_PROJECTS/INDEX/BEGIN',
  INDEX_SUCCESS = 'ADMIN_PROJECTS/INDEX/SUCCESS',
  INDEX_FAILED = 'ADMIN_PROJECTS/INDEX/FAILED'
}

export type AdminProjectsActionType =
  | SetPublicationDateFilterAction
  | SetOrganizationPrintCenterFilterAction
  | SetProjectStatusFilterAction
  | SetOrganizationNameFilterAction
  | SetOrganizationNumberFilterAction
  | SetProjectExternalIdFilterAction
  | SetProjectNameFilterAction
  | ProjectsIndexActionBEGIN
  | ProjectsIndexActionSUCCESS
  | ProjectsIndexActionFAILED
  | SetTestProjectsFilterAction
  | SetAutomatedEmailFilterAction
  | TestFlagToggledAction
  | SetPublicationTypeFilterAction;

export type AdminProjectsColumnsSettings = {
  name: boolean;
  accountNumber: boolean;
  accountName: boolean;
  status: boolean;
  latestActivity: boolean;
  publishDate: boolean;
  dataCenter: boolean;
  actions: boolean;
  emailCampaignRecipients: boolean;
  externalId: boolean;
  automatedEmail: boolean;
};
