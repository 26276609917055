import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import UnsubscribedPage from './Unsubscribed/UnsubscribedPage';

const CommonRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.COMMON.UNSUBSCRIBED,
    exact: true,
    name: 'unsubscribed',
    public: true,
    show: true,
    component: UnsubscribedPage,
    scope: []
  }
];

export default CommonRoutes;
