import React, { FunctionComponent, useEffect, useState } from 'react';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import PageHeader, { HeaderActionButton } from '../../../Shared/Layout/PageHeader';
import { useTypedSelector } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Loader from '../../../Shared/Loading/Loader';
import { Grid, Paper } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { adminProjectsOperations } from '../../../../store/AdminProjects';
import { usePaginationWatch } from '../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../Shared/DataTable/useTableSearchWatch';
import DataTable from '../../../Shared/DataTable/DataTable';
import ProjectNameCell from './WorkInProgressTable/ProjectNameCell';
import PublicationDateCell from './WorkInProgressTable/PublicationDateCell';
import { TableSearchStoreModule } from '../../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../../store/Pagination/types';
import {
  AdminProjectsColumnsSettings,
  AnyProjectAdmin,
  TestProjectFilterOptions
} from '../../../../store/AdminProjects/types';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import styled from 'styled-components';
import PublicationTypeFilters from './Partials/PublicationTypeFilters';
import FilterCheckboxWithInput from './Partials/FilterCheckboxWithInput';
import ProjectStatusFilters from './Partials/ProjectStatusFilters';
import DataCenterFilters from './Partials/DataCenterFilters';
import PublicationDateFilter from './Partials/PublicationDateFilter';
import { useHandleProjectAction } from './WorkInProgressTable/useHandleProjectAction';
import ProjectRemoveWindow from '../../Dashboard/Windows/ProjectRemoveWindow';
import ResetProjectCheckInStatusWindow from '../../Dashboard/Windows/ResetProjectCheckInStatusWindow';
import ProjectPublishNewsletterWindow from '../../Dashboard/Windows/ProjectPublishNewsletterWindow';
import { EmailProject } from '../../../../store/EmailProjects/types';
import ProjectCancelPublishWindow from '../../Dashboard/Windows/ProjectCalcelPublishWindow';
import ProjectPublishPrintWindow from '../../Dashboard/Windows/ProjectPublishPrintWindow';
import { PrintProject } from '../../../../store/PrintProjects/types';
import ProjectActionsCell from './WorkInProgressTable/ProjectActionsCell';
import TestProjectsFilters from './Partials/TestProjectsFilters';
import { ColumnDefinition } from '../../../Shared/DataTable/types';
import SelectedColumnsFilters from './Partials/SelectedColumnsFilters';
import LatestActivityCell from '../../Dashboard/WorkInProgressTable/LatestActivityCell';
import { Link } from 'react-router-dom';
import linksConstants from '../../../../config/app/linksConstants';
import { hasPermission } from '../../../../utils/permissions';
import Toast from '../../../Shared/Toast/Toast';
import AutomatedEmailFilter from './Partials/AutomatedEmailFilter';
import PreviewEmailWindow from '../../Dashboard/Windows/PreviewEmailWindow';
import GenerateLinkWindow from '../../Dashboard/Windows/GenerateLinkWindow';
import ProjectStatusCell from '../../Dashboard/WorkInProgressTable/ProjectStatusCell';

type AdminProjectsPageProps = RouteComponentProps<{}> & {};

const AdminProjectsPage: FunctionComponent<AdminProjectsPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [syncingEmailData, setSyncingEmailData] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [selectedColumns, setSelectedColumns] = useState<AdminProjectsColumnsSettings>({
    name: true,
    accountNumber: true,
    accountName: true,
    status: true,
    latestActivity: false,
    publishDate: true,
    dataCenter: true,
    actions: true,
    automatedEmail: true,
    emailCampaignRecipients: false,
    externalId: false
  });
  const user = useTypedSelector((state) => state.auth.user);
  const role = useTypedSelector((state) => state.auth.role);
  const projects = useTypedSelector((state) => state.adminProjects.projects);
  const onlineUsers = useTypedSelector((state) => state.adminProjects.onlineUsers);
  const totalOrganizations = useTypedSelector((state) => state.adminProjects.totalOrganizations);

  const isFailed = useTypedSelector((state) => state.adminProjects.index.isFailed);
  const loading = useTypedSelector((state) => state.adminProjects.index.isLoading);
  const pagination = useTypedSelector((state) => state.adminProjects.pagination);
  const tableSearch = useTypedSelector((state) => state.adminProjects.tableSearch);
  const filters = useTypedSelector((state) => state.adminProjects.filters);

  useEffect(() => {
    if (!loading) {
      setInitialLoading(false);
    }
  }, [loading]);

  usePaginationWatch(pagination, [adminProjectsOperations.index]);
  useTableSearchWatch<AnyProjectAdmin>(tableSearch, [adminProjectsOperations.index]);

  const getActionButtons = () => {
    const buttons: HeaderActionButton[] = [];

    if (hasPermission(role, ['backofficeProjectsDashboard'])) {
      buttons.push({
        onClick: async () => {
          setSyncingEmailData(true);
          try {
            await adminProjectsOperations.syncMailStatistics();
            Toast.success(t('notifications.adminProjects.syncSuccess'));
            await dispatch(adminProjectsOperations.index());
          } catch (e) {
            Toast.error(t('notifications.adminProjects.syncError'));
          } finally {
            setSyncingEmailData(false);
          }
        },
        variant: 'outlined',
        disabled: syncingEmailData,
        label: syncingEmailData ? `${t('common.synchronizing')}...` : t('pages.adminProjects.syncMailStatistics'),
        size: 'medium' as const,
        icon: <SaveAltIcon />
      });
    }

    buttons.push({
      onClick: () => {
        dispatch(adminProjectsOperations.downloadCsv());
      },
      label: t('pages.adminProjects.exportDashboard'),
      size: 'medium' as const,
      icon: <SaveAltIcon />
    });

    return buttons;
  };

  const {
    handleAction,
    activeProject,

    publishPrintWindowOpen,
    previewPrintWindowOpen,
    previewEmailWindowOpen,
    generateLinkWindowOpen,
    removeProjectWindowOpen,
    publishProjectWindowOpen,
    rePublishPrintWindowOpen,
    resetCheckInWindowOpen,
    cancelPublishProjectWindowOpen,

    onRemoveProjectWindowClose,
    onPublishProjectWindowClose,
    onResetCheckInWindowClose,
    onPublishPrintWindowClose,
    onRePublishPrintWindowClose,
    onPreviewPrintWindowClose,
    onPreviewEmailWindowClose,
    onGenerateLinkWindowClose,
    onCancelPublishProjectWindowClose
  } = useHandleProjectAction();

  const columnsDefinitions: ColumnDefinition<
    AnyProjectAdmin,
    'accountNumber' | 'accountName' | 'dataCenterName' | 'actions'
  >[] = [];

  if (selectedColumns.name) {
    columnsDefinitions.push({
      name: 'name',
      sortable: true,
      render: (project) => <ProjectNameCell project={project} user={user} role={role} />
    });
  }

  if (selectedColumns.accountNumber) {
    columnsDefinitions.push({
      name: 'accountNumber',
      sortable: true,
      render: (project) => <div>{project.accountNumber}</div>
    });
  }

  if (selectedColumns.accountName) {
    columnsDefinitions.push({
      name: 'accountName',
      sortable: true,
      render: (project) => <div>{project.organizationName}</div>
    });
  }

  if (selectedColumns.status) {
    columnsDefinitions.push({
      name: 'status',
      sortable: true,
      render: (project) => <ProjectStatusCell project={project} />
    });
  }

  if (selectedColumns.automatedEmail) {
    columnsDefinitions.push({
      name: 'automated',
      sortable: true,
      render: (project) => <>{project.kind === 'email' && (project.automated ? t('common.yes') : t('common.no'))}</>,
      isEmpty: (project) => project.kind !== 'email'
    });
  }

  if (selectedColumns.latestActivity) {
    columnsDefinitions.push({
      name: 'updatedBy',
      sortable: false,
      render: (project) => <LatestActivityCell project={project} user={user} />
    });
  }

  if (selectedColumns.publishDate) {
    columnsDefinitions.push({
      name: 'publishDate',
      sortable: true,
      render: (project) => <PublicationDateCell project={project} />
    });
  }

  if (selectedColumns.dataCenter) {
    columnsDefinitions.push({
      name: 'dataCenterName',
      label: t('pages.commonInputs.inputs.printCenter'),
      render: (project) => <>{project.dataCenterName}</>,
      isEmpty: (project) => !project.dataCenterName,
      sortable: true
    });
  }

  if (selectedColumns.emailCampaignRecipients) {
    columnsDefinitions.push({
      name: 'emailCampaignRecipients',
      render: (project) => <>{project.emailCampaignRecipients}</>,
      isEmpty: (project) => project.emailCampaignRecipients === null,
      sortable: true
    });
  }

  if (selectedColumns.externalId) {
    columnsDefinitions.push({
      name: 'externalId',
      render: (project) => <>{project.externalId}</>,
      isEmpty: (project) => project.externalId === null,
      sortable: true
    });
  }

  if (selectedColumns.actions) {
    columnsDefinitions.push({
      name: 'actions',
      sortable: false,
      render: (project) => <ProjectActionsCell project={project} user={user} role={role} handleEvent={handleAction} />
    });
  }

  return (
    <PageContainer>
      <PageHeader title={t('pages.adminProjects.title')} rightActionButtons={getActionButtons()} />
      <PageContent>
        {initialLoading && <Loader />}
        {(!initialLoading || pagination.q) && (
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TopBar>
                <TopBarItemClickable to={linksConstants.ADMINISTRATION.ONLINE_USERS.INDEX}>
                  {onlineUsers} {t('pages.adminProjects.usersLoggedIn')}
                </TopBarItemClickable>
                <TopBarItemClickable to={linksConstants.ADMINISTRATION.ORGANIZATIONS.INDEX}>
                  {totalOrganizations} {t('pages.adminProjects.totalOrganizations')}
                </TopBarItemClickable>
                <div>{pagination.total} Projects</div>
              </TopBar>
              <DataTable<AnyProjectAdmin, 'accountNumber' | 'accountName' | 'dataCenterName' | 'actions'>
                enableSearch={true}
                wrapperStyle={{
                  padding: '0.5rem 2rem 0'
                }}
                headingStyle={{
                  // fontSize: '0.9rem',
                  color: '#014b72',
                  textAlign: 'left',
                  paddingRight: '0.5rem',
                  fontWeight: 'normal'
                }}
                columnDefinitions={columnsDefinitions}
                tPath={'pages.adminProjects.table'}
                data={projects}
                tableSearchProps={{
                  tableSearch,
                  module: TableSearchStoreModule.ADMIN_PROJECTS
                }}
                // @ts-ignore
                paginationProps={{
                  pagination,
                  module: PaginationStoreModule.ADMIN_PROJECTS
                  // onPageChange: () => {},
                  // onPerChange: () => {}
                }}
                isFailed={isFailed}
                isLoading={loading}
              />
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{
                  padding: '1.5em'
                }}
              >
                <SelectedColumnsFilters
                  columns={selectedColumns}
                  onChange={(columnName) =>
                    setSelectedColumns({
                      ...selectedColumns,
                      [columnName]: !selectedColumns[columnName]
                    })
                  }
                />
                <hr />
                <PublicationTypeFilters
                  filters={filters.publicationType}
                  onChange={(type) => dispatch(adminProjectsOperations.setPublicationTypeFilter(type))}
                />
                <hr />
                <FilterCheckboxWithInput
                  header={t('pages.adminProjects.filters.accountName')}
                  filter={filters.organizationName}
                  onChange={(payload) => dispatch(adminProjectsOperations.setOrganizationNameFilter(payload))}
                />
                <FilterCheckboxWithInput
                  header={t('pages.adminProjects.filters.accountNumber')}
                  filter={filters.organizationAccountNumber}
                  onChange={(payload) => dispatch(adminProjectsOperations.setOrganizationNumberFilter(payload))}
                />
                <FilterCheckboxWithInput
                  header={t('pages.adminProjects.filters.projectName')}
                  filter={filters.projectName}
                  onChange={(payload) => dispatch(adminProjectsOperations.setProjectNameFilter(payload))}
                />
                <FilterCheckboxWithInput
                  header={t('pages.adminProjects.filters.externalId')}
                  filter={filters.externalId}
                  onChange={(payload) => dispatch(adminProjectsOperations.setProjectExternalIdFilter(payload))}
                />
                <hr />
                <ProjectStatusFilters
                  filters={filters.status}
                  onChange={(status) => dispatch(adminProjectsOperations.setProjectStatusFilter(status))}
                />

                <hr />
                <PublicationDateFilter
                  publicationDateSelectedOption={filters.publicationDateSelectedOption}
                  publicationDateExact={filters.publicationDateExact}
                  publicationDateRange={filters.publicationDateRange}
                  onChange={(payload) => dispatch(adminProjectsOperations.setPublicationDateFilter(payload))}
                />
                <hr />
                <DataCenterFilters
                  filters={filters.printCenter}
                  onChange={(dataCenterId) => dispatch(adminProjectsOperations.setPrintCenterFilter(dataCenterId))}
                />
                <hr />
                <TestProjectsFilters
                  filters={filters.testProjects}
                  onChange={(payload: TestProjectFilterOptions) =>
                    dispatch(adminProjectsOperations.setTestProjectsFilter(payload))
                  }
                />
                <hr />
                <AutomatedEmailFilter
                  automatedEmail={filters.automatedEmail}
                  onChange={(checked: boolean) => dispatch(adminProjectsOperations.setAutomatedEmailFilter(checked))}
                />
              </Paper>
            </Grid>
          </Grid>
        )}
      </PageContent>
      <ProjectRemoveWindow
        context="backoffice"
        open={removeProjectWindowOpen}
        project={activeProject}
        onCloseClick={onRemoveProjectWindowClose}
        fullScreenOnMobile={true}
      />
      <ResetProjectCheckInStatusWindow
        open={resetCheckInWindowOpen}
        project={activeProject}
        onCloseClick={onResetCheckInWindowClose}
        fullScreenOnMobile={true}
      />
      {publishProjectWindowOpen && (
        <ProjectPublishNewsletterWindow
          open={publishProjectWindowOpen}
          project={activeProject as EmailProject}
          onCloseClick={onPublishProjectWindowClose}
          fullScreenOnMobile={true}
        />
      )}
      <ProjectCancelPublishWindow
        open={cancelPublishProjectWindowOpen}
        project={activeProject as EmailProject}
        onCloseClick={onCancelPublishProjectWindowClose}
        fullScreenOnMobile={true}
      />
      {publishPrintWindowOpen && (
        <ProjectPublishPrintWindow
          open={publishPrintWindowOpen}
          project={activeProject as PrintProject}
          onCloseClick={onPublishPrintWindowClose}
          fullScreenOnMobile={true}
          onSuccessfulSubmit={() => dispatch(adminProjectsOperations.index())}
        />
      )}
      {rePublishPrintWindowOpen && (
        <ProjectPublishPrintWindow
          open={rePublishPrintWindowOpen}
          project={activeProject as PrintProject}
          onCloseClick={onRePublishPrintWindowClose}
          fullScreenOnMobile={true}
          onSuccessfulSubmit={() => dispatch(adminProjectsOperations.index())}
          reScheduling={true}
        />
      )}
      <ProjectPublishPrintWindow
        onlyPreview={true}
        open={previewPrintWindowOpen}
        project={activeProject as PrintProject}
        onCloseClick={onPreviewPrintWindowClose}
        fullScreenOnMobile={true}
      />
      {previewEmailWindowOpen && (
        <PreviewEmailWindow
          open={previewEmailWindowOpen}
          project={activeProject as EmailProject}
          onCloseClick={onPreviewEmailWindowClose}
        />
      )}
      {generateLinkWindowOpen && (
        <GenerateLinkWindow
          open={generateLinkWindowOpen}
          project={activeProject as EmailProject}
          onCloseClick={onGenerateLinkWindowClose}
        />
      )}
    </PageContainer>
  );
};

const TopBar = styled.div`
  background-color: #014b72;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-around;

  > div,
  a {
    padding: 1rem;
    border-right: 1px solid #fff;
    flex: 1;
    text-align: center;

    &:last-child {
      border-right: none;
    }
  }
`;

const TopBarItemClickable = styled(Link)`
  cursor: pointer;
  display: block;
  text-decoration: none;

  &:hover {
    background-color: #013956;
  }
`;

export default AdminProjectsPage;
