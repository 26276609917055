import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import { formatTableTimestamp, useTypedSelector } from '../../../../utils';
import { usePaginationWatch } from '../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../Shared/DataTable/useTableSearchWatch';
import { PaginationStoreModule } from '../../../../store/Pagination/types';
import DataTable from '../../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../../Shared/DataTable/TableActionButton/TableActionButton';
import { TableSearchStoreModule } from '../../../../store/TableSearch/types';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import AdminCreatePasswordPolicyWindow from './Windows/AdminCreatePasswordPolicyWindow';
import AdminUpdatePasswordPolicyWindow from './Windows/AdminUpdatePasswordPolicyWindow';
import AdminDeletePasswordPolicyWindow from './Windows/AdminDeletePasswordPolicyWindow';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { adminPasswordPoliciesOperations } from '../../../../store/AdminPasswordPolicies';
import { AdminPasswordPolicy } from '../../../../store/AdminPasswordPolicies/types';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import app from '../../../../config/app/app';
import { hasPermission } from '../../../../utils/permissions';

type AdminPasswordPoliciesPageProps = {};

const AdminPasswordPoliciesPage: FunctionComponent<AdminPasswordPoliciesPageProps> = () => {
  const { t } = useTranslation();
  const role = useTypedSelector((state) => state.auth.role);
  const passwordPolicies = useTypedSelector((state) => state.adminPasswordPolicies.passwordPolicies);
  const isFailed = useTypedSelector((state) => state.adminPasswordPolicies.index.isFailed);
  const isLoading = useTypedSelector((state) => state.adminPasswordPolicies.index.isLoading);
  const pagination = useTypedSelector((state) => state.adminPasswordPolicies.pagination);
  const tableSearch = useTypedSelector((state) => state.adminPasswordPolicies.tableSearch);

  usePaginationWatch(pagination, [adminPasswordPoliciesOperations.index]);
  useTableSearchWatch<AdminPasswordPolicy>(tableSearch, [adminPasswordPoliciesOperations.index]);

  const [activePasswordPolicy, setActivePasswordPolicy] = useState<AdminPasswordPolicy | null>(null);
  const [
    createPasswordPolicyWindowOpen,
    onCreatePasswordPolicyWindowOpen,
    onCreatePasswordPolicyWindowClose
  ] = useOpenHandler();
  const [
    updatePasswordPolicyWindowOpen,
    onUpdatePasswordPolicyWindowOpen,
    onUpdatePasswordPolicyWindowClose
  ] = useOpenHandler();
  const [
    deletePasswordPolicyWindowOpen,
    onDeletePasswordPolicyWindowOpen,
    onDeletePasswordPolicyWindowClose
  ] = useOpenHandler();

  const getActionButtons = () => {
    if (hasPermission(role, ['passwordPoliciesAdd'])) {
      return [
        {
          label: t('pages.adminPasswordPolicies.createPasswordPolicy'),
          icon: <AddIcon />,
          onClick: onCreatePasswordPolicyWindowOpen
        }
      ];
    }

    return [];
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.adminPasswordPolicies.title')} rightActionButtons={getActionButtons()} />
      <PageContent>
        <DataTable<AdminPasswordPolicy, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'name',
              sortable: true,
              render: (passwordPolicy) => (
                <div>
                  {passwordPolicy.isGlobal && <div>{t('pages.systemSettings.passwordPolicyTitle')}</div>}
                  {!passwordPolicy.isGlobal && <div>{passwordPolicy.name}</div>}
                </div>
              )
            },
            {
              name: 'description',
              sortable: true,
              render: (passwordPolicy) => (
                <div>{!passwordPolicy.isGlobal && <div>{passwordPolicy.description}</div>}</div>
              )
            },
            {
              name: 'actions',
              render: (passwordPolicy) => (
                <TableActionButtonsContainer>
                  <TableActionButton
                    icon={<EditIcon />}
                    tooltip={t('pages.adminPasswordPolicies.table.buttons.edit')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['passwordPoliciesEdit']) || passwordPolicy.isGlobal,
                      onClick: () => {
                        setActivePasswordPolicy(passwordPolicy);
                        onUpdatePasswordPolicyWindowOpen();
                      }
                    }}
                  />
                  <TableActionButton
                    icon={<DeleteIcon />}
                    tooltip={t('pages.adminPasswordPolicies.table.buttons.delete')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['passwordPoliciesRemove']) || passwordPolicy.isGlobal,
                      onClick: () => {
                        setActivePasswordPolicy(passwordPolicy);
                        onDeletePasswordPolicyWindowOpen();
                      }
                    }}
                  />
                </TableActionButtonsContainer>
              )
            },
            {
              name: 'createdAt',
              render: (passwordPolicy) => <>{formatTableTimestamp(passwordPolicy.createdAt)}</>,
              sortable: true
            }
          ]}
          tPath={'pages.adminPasswordPolicies.table'}
          data={passwordPolicies}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.ADMIN_PASSWORD_POLICIES
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.ADMIN_PASSWORD_POLICIES
            // onPageChange: () => {},
            // onPerChange: () => {}
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>

      <AdminCreatePasswordPolicyWindow
        open={createPasswordPolicyWindowOpen}
        onCloseClick={onCreatePasswordPolicyWindowClose}
        fullScreenOnMobile
      />
      <AdminUpdatePasswordPolicyWindow
        passwordPolicy={activePasswordPolicy}
        open={updatePasswordPolicyWindowOpen}
        onCloseClick={onUpdatePasswordPolicyWindowClose}
        fullScreenOnMobile
      />
      <AdminDeletePasswordPolicyWindow
        passwordPolicy={activePasswordPolicy}
        open={deletePasswordPolicyWindowOpen}
        onCloseClick={onDeletePasswordPolicyWindowClose}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

export default AdminPasswordPoliciesPage;
