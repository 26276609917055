import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List, ListItem, ListItemText, Button } from '@material-ui/core';
import Loader from '../../../../Shared/Loading/Loader';
import { DoAppSubscriber } from '../../../../../store/DoApp/Subscribers/types';
import { doAppSubscribersOperations } from '../../../../../store/DoApp/Subscribers';
import { WidgetTitle } from '../../../../Shared/ListingWidget/WidgetTitle';
import { WidgetSearchInput } from '../../../../Shared/ListingWidget/WidgetSearchInput';
import { WidgetPagination } from '../../../../Shared/ListingWidget/WidgetPagination';
import { WidgetFetchError } from '../../../../Shared/ListingWidget/WidgetFetchError';
import Toast from '../../../../Shared/Toast/Toast';
import DoAppUpdateSubscriberCredits from '../windows/DoAppUpdateSubscriberCredits';

type DoAppSubscribersListProps = {};

const DoAppSubscribersList = forwardRef<{ refreshSubscribers: () => void }, DoAppSubscribersListProps>((props, ref) => {
  const { t } = useTranslation();
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [lastSearchValue, setLastSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [subscribers, setSubscribers] = useState<DoAppSubscriber[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 10, q: '', total: 0, totalPages: 0 });

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [activeSubscriber, setActiveSubscriber] = useState<DoAppSubscriber | null>(null);
  const [credits, setCredits] = useState<number>(0);

  const fetchData = async (page: number, search: string) => {
    setFetchError(false);
    try {
      const subscribers = await doAppSubscribersOperations.indexRaw(page, 10, search, {});
      setSubscribers(subscribers.data);
      setPagination(subscribers.pagination);
      setLastSearchValue(search);
    } catch (e) {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    refreshSubscribers: () => fetchData(pagination.page, '')
  }));

  useEffect(() => {
    fetchData(1, '');
  }, []);

  const handleOpenDialog = (subscriber: DoAppSubscriber) => {
    setActiveSubscriber(subscriber);
    setCredits(subscriber.credits);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setActiveSubscriber(null);
  };

  const handleSubmitCreditsUpdate = async (newCredits: number) => {
    if (activeSubscriber) {
      try {
        await doAppSubscribersOperations.updateCredits(activeSubscriber.id, newCredits);
        handleCloseDialog();
        fetchData(pagination.page, searchValue);
        Toast.success(t('pages.doApp.subscribers.updateSuccess'));
      } catch (error) {
        Toast.error(t('pages.doApp.subscribers.updateError'));
        console.error('Error updating credits:', error);
      }
    }
  };

  const renderList = () => {
    return (
      <>
        <WidgetTitle title={t('pages.doApp.subscribers.title')} subtitle={t('pages.doApp.subscribers.subtitle')} />
        <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
        {loading && <Loader />}
        <WidgetSearchInput
          placeholder={`${t('common.search')}...`}
          value={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={fetchData}
        />

        {fetchError && <WidgetFetchError title={t('pages.doApp.subscribers.fetchError')} />}
        <List>
          {subscribers.map((subscriber) => (
            <React.Fragment key={subscriber.id}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={subscriber.email}
                  secondary={
                    <div>
                      <div>
                        {t('pages.doApp.subscribers.credits')}: ${(subscriber.credits / 100).toFixed(2)}
                      </div>
                      <div>{subscriber.id}</div>
                    </div>
                  }
                />
                <Button variant="outlined" color="primary" onClick={() => handleOpenDialog(subscriber)}>
                  {t('pages.doApp.subscribers.updateCredits')}
                </Button>
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {pagination.total > 0 && (
            <WidgetPagination
              pagination={{ page: pagination.page, totalPages: pagination.totalPages }}
              handlePaginationChange={(val) => fetchData(val, searchValue)}
            />
          )}
        </div>

        {activeSubscriber && (
          <DoAppUpdateSubscriberCredits
            open={openDialog}
            credits={credits}
            onCloseClick={handleCloseDialog}
            onSubmit={handleSubmitCreditsUpdate}
          />
        )}
      </>
    );
  };

  return renderList();
});

export default DoAppSubscribersList;
