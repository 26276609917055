import React from 'react';
import { Typography } from '@material-ui/core';

type WidgetTitleProps = {
  title: string;
  subtitle?: string;
};

export const WidgetTitle = (props: WidgetTitleProps) => {
  return (
    <>
      <Typography variant="h6">{props.title}</Typography>
      {props.subtitle && (
        <Typography variant="caption" gutterBottom style={{ fontStyle: 'italic' }}>
          {props.subtitle}
        </Typography>
      )}
    </>
  );
};
