import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailCampaignStandardResponse, EmailProject } from '../../../../store/EmailProjects/types';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { DataTableHeaderCell } from '../../../Shared/DataTable/DataTableStyled';
import { Dot, UniqueTotalBarBackground, UniqueTotalBarContainer, UniqueTotalBar } from '../EmailStatisticsPage';
import Loader from '../../../Shared/Loading/Loader';

type EmailStatsLinksActivityProps = {
  project: EmailProject;
};

const EmailStatsLinksActivity: FunctionComponent<EmailStatsLinksActivityProps> = ({ project }) => {
  const { t } = useTranslation();
  const [stats, setStats] = useState<EmailCampaignStandardResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      if (project) {
        setLoading(true);
        const response = await emailProjectsOperations.emailCampaignStatsLinksActivity(project.id);
        setStats(response);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <TableContainer component={Paper} style={{ marginTop: '32px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <DataTableHeaderCell>{t('pages.emailStatistics.table.linkUrl')}</DataTableHeaderCell>
            </TableCell>
            <TableCell>
              <DataTableHeaderCell>
                <div>
                  <Dot style={{ backgroundColor: '#F2C14A' }} /> {t('pages.emailStatistics.table.uniqueClicks')}
                  <div style={{ width: '20px' }}></div>
                  <Dot style={{ backgroundColor: '#369FAF' }} /> {t('pages.emailStatistics.table.totalClicks')}
                </div>
              </DataTableHeaderCell>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={2}>
                <Loader />
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            stats &&
            stats.data.map((stat) => (
              <TableRow key={stat.context}>
                <TableCell>{stat.context}</TableCell>
                <TableCell>
                  <UniqueTotalBarContainer>
                    {stat.unique} / {stat.total}
                    <UniqueTotalBarBackground>
                      <UniqueTotalBar style={{ width: `${(stat.unique * 100) / stat.total}%` }}></UniqueTotalBar>
                    </UniqueTotalBarBackground>
                  </UniqueTotalBarContainer>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EmailStatsLinksActivity;
