import React, { FunctionComponent, useEffect, useState } from 'react';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Toast from '../../../Shared/Toast/Toast';
import Loader from '../../../Shared/Loading/Loader';
import { RouteComponentProps } from 'react-router';
import { AnyProject } from '../../../../store/Dashboard/types';
import { dashboardOperations } from '../../../../store/Dashboard';
import { CloneProjectCreatorState } from '../types';
import { Template } from '../../../../store/Templates/types';
import { printProjectsOperations } from '../../../../store/PrintProjects';
import { createProjectErrorHandler } from '../Creators/Utils';
import { isPrintProject } from '../../../../utils';
import SelectPublishDate from '../Creators/Partials/SelectPublishDate';
import CreateProjectStepper from '../Creators/Partials/CreateProjectStepper';
import LoadingModal from '../../../Shared/Loading/LoadingModal';
import CloneProjectConfirmationScreen from '../Creators/Partials/CloneProjectConfirmationScreen';
import { templatesOperations } from '../../../../store/Templates';

type CloneProjectPageProps = RouteComponentProps<{ projectId: string }> & {};

const CloneProjectPage: FunctionComponent<CloneProjectPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const steps: CloneProjectCreatorState[] = ['publishDate', 'confirm', 'finish'];
  const [templateData, setTemplateData] = useState<Template | null>(null);
  const [projectData, setProjectData] = useState<AnyProject | null>(null);
  const [publishDate, setPublishDate] = useState<Date>(new Date());
  const [currentState, setCurrentState] = useState<CloneProjectCreatorState>('publishDate');
  const [loading, setLoading] = useState<boolean>(true);
  const [creatingProject, setCreatingProject] = useState(false);
  const [testProject, setTestProject] = useState(false);

  const { projectId } = match.params;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (projectId) {
          const data = await dashboardOperations.getProjectByIdRaw(projectId);
          setProjectData(data);
          setTestProject(data.test);
          const template = await templatesOperations.getTemplate(data.templateId);
          setTemplateData(template);
        }
      } catch (e) {
        Toast.error(t('notifications.cloneProject.fetchingError'));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const onPublishDateSelected = (date: Date) => setPublishDate(date);

  const cloneProject = async () => {
    setCreatingProject(true);
    if (projectData) {
      try {
        setCurrentState('finish');

        if (isPrintProject(projectData)) {
          const newProject = await printProjectsOperations.clone(projectData.id, publishDate, testProject);
          dispatch(push(linksConstants.PROJECTS.BULLETIN.EDIT(newProject.id)));
        }
        // if (isEmailProject(projectData)) {
        //   const newProject = await emailProjectsOperations.clone(projectData.id, publishDate);
        //   dispatch(push(linksConstants.PROJECTS.EMAIL.EDIT(newProject.id)));
        // }
        Toast.success(t('notifications.cloneProject.success'));
      } catch (e) {
        createProjectErrorHandler(e, t);
        setCreatingProject(false);
        setCurrentState('confirm');
      }
    }
  };

  const backButton = {
    onClick: () => {
      switch (currentState) {
        case 'publishDate':
          return dispatch(push(linksConstants.DASHBOARD.INDEX));

        case 'confirm':
        case 'finish':
          return setCurrentState('publishDate');
      }
    },
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const getCurrentSection = () => {
    switch (currentState) {
      case 'publishDate':
        return (
          <SelectPublishDate
            onSubmit={() => setCurrentState('confirm')}
            testProject={testProject}
            onTestProjectChecked={() => setTestProject(!testProject)}
            onSelect={onPublishDateSelected}
            date={publishDate}
            checkReadMoreLinks={false}
            infoText="pages.dashboard.createWizard.publishDateSelector.cloneProject"
          />
        );

      case 'finish':
      case 'confirm':
        return (
          <CloneProjectConfirmationScreen
            project={projectData}
            template={templateData}
            publishDate={publishDate}
            onSubmit={cloneProject}
          />
        );
    }
  };

  const HeaderTitle = (
    <>
      <div>{t('pages.dashboard.createWizard.cloneProject')}</div>
      <div>
        <CreateProjectStepper currentState={currentState} steps={steps} />
      </div>
    </>
  );

  return (
    <PageContainer>
      {creatingProject && <LoadingModal message={t('pages.dashboard.creatingYourProject')} />}
      <PageHeader title={HeaderTitle} leftActionButtons={[backButton]} noBottomPadding />
      {loading && <Loader />}
      {!loading && <PageContent>{getCurrentSection()}</PageContent>}
    </PageContainer>
  );
};

export default CloneProjectPage;
