import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import AdsStatisticsPage from './AdsStatisticsPage';

const AdsStatisticsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADS_STATISTICS,
    exact: false,
    name: 'adsStatisticsPage',
    public: false,
    show: true,
    component: AdsStatisticsPage,
    scope: []
  }
];

export default AdsStatisticsRoutes;
