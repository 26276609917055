import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import { useTypedSelector } from '../../../../utils';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import PageHeader, { HeaderActionButton } from '../../../Shared/Layout/PageHeader';
import { useDispatch } from 'react-redux';
import { adminAdsOperations } from '../../../../store/AdminAds';
import { AdBanner, AdCategory, Advertiser, HouseAdsBannersCategory } from '../../../../store/AdminAds/types';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import AddIcon from '@material-ui/icons/Add';
import { AdminEmailTemplate } from '../../../../store/AdminEmailTemplates/types';
import { adminEmailTemplatesOperations } from '../../../../store/AdminEmailTemplates';
import AdminCreateAdBannerWindow from './Windows/AdBanner/AdminCreateAdBannerWindow';
import AdminUpdateAdBannerWindow from './Windows/AdBanner/AdminUpdateAdBannerWindow';
import AdminDeleteAdBannerWindow from './Windows/AdBanner/AdminDeleteAdBannerWindow';
import { hasPermission } from '../../../../utils/permissions';
import AdminCreateAdCategoryWindow from './Windows/AdCategory/AdminCreateAdCategoryWindow';
import AdminUpdateAdCategoryWindow from './Windows/AdCategory/AdminUpdateAdCategoryWindow';
import AdminDeleteAdCategoryWindow from './Windows/AdCategory/AdminDeleteAdCategoryWindow';
import HouseAdCategorySection from './Partials/HouseAds/HouseAdCategorySection';

type AdminHouseAdsPageProps = {};

const AdminHouseAdsPage: FunctionComponent<AdminHouseAdsPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);
  const isLoading = useTypedSelector((state) => state.adminAds.index.isLoading);
  const [emailTemplate, setEmailTemplate] = useState<AdminEmailTemplate | null>(null);
  const [banners, setBanners] = useState<AdBanner[]>([]);
  const [houseAdsCategories, setHouseAdsCategories] = useState<HouseAdsBannersCategory[]>([]);

  const [advertiser, setAdvertiser] = useState<Advertiser | null>(null);
  const [activeBanner, setActiveBanner] = useState<AdBanner | null>(null);
  const [activeAdCategory, setActiveAdCategory] = useState<AdCategory | null>(null);

  const [updateAdBannerWindowOpen, onUpdateAdBannerWindowOpen, onUpdateAdBannerWindowClose] = useOpenHandler();
  const [deleteAdBannerWindowOpen, onDeleteAdBannerWindowOpen, onDeleteAdBannerWindowClose] = useOpenHandler();
  const [createAdBannerWindowOpen, onCreateAdBannerWindowOpen, onCreateAdBannerWindowClose] = useOpenHandler();

  const [updateAdCategoryWindowOpen, onUpdateAdCategoryWindowOpen, onUpdateAdCategoryWindowClose] = useOpenHandler();
  const [deleteAdCategoryWindowOpen, onDeleteAdCategoryWindowOpen, onDeleteAdCategoryWindowClose] = useOpenHandler();
  const [createAdCategoryWindowOpen, onCreateAdCategoryWindowOpen, onCreateAdCategoryWindowClose] = useOpenHandler();

  const parseHouseAdsCategories = (categories: AdCategory[], banners: AdBanner[]) => {
    return [
      {
        category: {
          id: 'uncategorized',
          name: t('pages.adminAds.uncategorized'),
          description: t('pages.adminAds.uncategorizedDescription')
        },
        banners: banners.filter((banner) => !categories.find((cat) => cat.id === banner.categoryId))
      },
      ...categories.map((cat) => ({
        category: cat,
        banners: banners.filter((banner) => banner.categoryId === cat.id)
      }))
    ];
  };

  const fetchHouseAds = async () => {
    const houseAds = await adminAdsOperations.indexHouseAds();
    setBanners(houseAds.data);
    setAdvertiser(houseAds.advertiser);
    setHouseAdsCategories(parseHouseAdsCategories(houseAds.categories, houseAds.data));
  };

  const fetchHouseAdsCategories = async () => {
    const categories = await adminAdsOperations.indexHouseAdsCategories();
    setHouseAdsCategories(parseHouseAdsCategories(categories.data, banners));
  };

  useEffect(() => {
    const fetchFirstEmailTemplate = async () => {
      setEmailTemplate(await adminEmailTemplatesOperations.getFirstEmailTemplate());
    };
    fetchFirstEmailTemplate();
    fetchHouseAds();
  }, []);

  const onRemoveAdBanner = (banner: AdBanner) => {
    setActiveBanner(banner);
    onDeleteAdBannerWindowOpen();
  };
  const onUpdateAdBanner = (banner: AdBanner) => {
    setActiveBanner(banner);
    onUpdateAdBannerWindowOpen();
  };

  const onRemoveAdCategory = (adCategory: AdCategory) => {
    setActiveAdCategory(adCategory);
    onDeleteAdCategoryWindowOpen();
  };
  const onUpdateAdCategory = (adCategory: AdCategory) => {
    setActiveAdCategory(adCategory);
    onUpdateAdCategoryWindowOpen();
  };

  const getActionButtons = () => {
    const buttons: HeaderActionButton[] = [];

    buttons.push({
      label: t('pages.adminAds.houseAdsToTemplatesAssignments'),
      variant: 'outlined' as const,
      icon: <VisibilityIcon />,
      onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ADS.ASSIGN_ADS(emailTemplate?.id || '')))
    });

    if (hasPermission(role, ['adsAdd'])) {
      buttons.push({
        label: t('pages.adminAds.addNewCategory'),
        icon: <AddIcon />,
        variant: 'outlined' as const,
        onClick: () => onCreateAdCategoryWindowOpen()
      });
      buttons.push({
        label: t('pages.adminAds.addNewBanner'),
        icon: <AddIcon />,
        onClick: () => onCreateAdBannerWindowOpen()
      });
    }

    return buttons;
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.adminAds.title')} rightActionButtons={getActionButtons()} />
      <PageContent>
        {houseAdsCategories.map((categoryData) => (
          <HouseAdCategorySection
            key={categoryData.category.id}
            banners={categoryData.banners}
            category={categoryData.category}
            onRemoveAdBanner={onRemoveAdBanner}
            onUpdateAdBanner={onUpdateAdBanner}
            onUpdateAdCategory={onUpdateAdCategory}
            onRemoveAdCategory={onRemoveAdCategory}
          />
        ))}
      </PageContent>

      {advertiser && (
        <AdminCreateAdBannerWindow
          advertiserId={advertiser.advertiserId}
          open={createAdBannerWindowOpen}
          categories={houseAdsCategories}
          onCloseClick={onCreateAdBannerWindowClose}
          fullScreenOnMobile={true}
          onFormSuSubmit={fetchHouseAds}
          showDates={false}
        />
      )}
      {advertiser && (
        <AdminUpdateAdBannerWindow
          advertiserId={advertiser.advertiserId}
          banner={activeBanner}
          categories={houseAdsCategories}
          open={updateAdBannerWindowOpen}
          onCloseClick={onUpdateAdBannerWindowClose}
          fullScreenOnMobile={true}
          onFormSuSubmit={fetchHouseAds}
          showDates={false}
        />
      )}
      <AdminDeleteAdBannerWindow
        banner={activeBanner}
        open={deleteAdBannerWindowOpen}
        onCloseClick={onDeleteAdBannerWindowClose}
        fullScreenOnMobile={true}
        onFormSuSubmit={fetchHouseAds}
      />

      <AdminCreateAdCategoryWindow
        open={createAdCategoryWindowOpen}
        onCloseClick={onCreateAdCategoryWindowClose}
        fullScreenOnMobile={true}
        onFormSuSubmit={fetchHouseAdsCategories}
      />
      <AdminUpdateAdCategoryWindow
        category={activeAdCategory}
        open={updateAdCategoryWindowOpen}
        onCloseClick={onUpdateAdCategoryWindowClose}
        fullScreenOnMobile={true}
        onFormSuSubmit={fetchHouseAdsCategories}
      />
      <AdminDeleteAdCategoryWindow
        category={activeAdCategory}
        open={deleteAdCategoryWindowOpen}
        onCloseClick={onDeleteAdCategoryWindowClose}
        fullScreenOnMobile={true}
        onFormSuSubmit={fetchHouseAdsCategories}
      />
    </PageContainer>
  );
};

/**
 <AdminAdsTable
 banners={banners}
 isLoading={isLoading}
 onUpdateAdBanner={onUpdateAdBanner}
 onRemoveAdBanner={onRemoveAdBanner}
 showStartDate={false}
 showEndDate={false}
 />
 */

export default AdminHouseAdsPage;
