import React, { FunctionComponent } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { FilterHeader } from '../../AdminStyledComponents';
import { AdminProjectsFilters, TestProjectFilterOptions } from '../../../../../store/AdminProjects/types';
import { useTranslation } from 'react-i18next';

type TestProjectsFiltersProps = {
  filters: AdminProjectsFilters['testProjects'];
  onChange: (payload: TestProjectFilterOptions) => void;
};

const TestProjectsFilters: FunctionComponent<TestProjectsFiltersProps> = ({ filters, onChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <FilterHeader>{t('pages.adminProjects.filters.testProjects')}</FilterHeader>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="include"
              disabled={filters.only}
              checked={filters.include}
              onChange={() => onChange({ include: !filters.include, only: filters.only })}
            />
          }
          label={t('pages.adminProjects.filters.includeTestProjects')}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="only"
              checked={filters.only}
              onChange={() => onChange({ include: true, only: !filters.only })}
            />
          }
          label={t('pages.adminProjects.filters.onlyTestProjects')}
        />
      </FormGroup>
    </>
  );
};

export default TestProjectsFilters;
