import { AppActionsConstants, AppActionType, AppState } from './types';
import update from 'immutability-helper';
import { createNetworkErrorObject } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { WithLogoutAction } from '../../typings';

const initialState: AppState = {
  versions: {
    loading: false,
    error: null,
    versions: {
      api: '',
      web: '',
      designer: ''
    },
    designerVersionFromIframe: ''
  },
  videoPreviewWindow: {
    open: false,
    src: ''
  }
};

export default function appReducer(state = initialState, action: WithLogoutAction<AppActionType>) {
  switch (action.type) {
    case AppActionsConstants.GET_VERSIONS_BEGIN: {
      return update(state, {
        versions: {
          loading: { $set: true },
          error: { $set: null }
        }
      });
    }
    case AppActionsConstants.GET_VERSIONS_SUCCESS: {
      return update(state, {
        versions: {
          loading: { $set: false },
          versions: {
            api: { $set: action.payload.api },
            web: { $set: action.payload.web },
            designer: { $set: action.payload.designer }
          }
        }
      });
    }
    case AppActionsConstants.SET_DESIGNER_VERSION_FROM_IFRAME: {
      return update(state, {
        versions: {
          designerVersionFromIframe: { $set: action.payload }
        }
      });
    }

    case AppActionsConstants.GET_VERSIONS_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        versions: {
          loading: { $set: false },
          error: { $set: networkError }
        }
      });
    }

    case AppActionsConstants.OPEN_VIDEO_MODAL: {
      return update(state, {
        videoPreviewWindow: {
          open: { $set: true },
          src: { $set: action.payload.src }
        }
      });
    }

    case AppActionsConstants.CLOSE_VIDEO_MODAL: {
      return update(state, {
        videoPreviewWindow: {
          open: { $set: false },
          src: { $set: '' }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return state;
    }
  }
}
