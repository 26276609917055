import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import Toast from '../../../../../Shared/Toast/Toast';
import app from '../../../../../../config/app/app';
import { FormikProps } from 'formik';
import { AdCategory, AdminUpdateAdCategoryValues } from '../../../../../../store/AdminAds/types';
import { adminAdsOperations } from '../../../../../../store/AdminAds';
import { AdminTextInput } from '../../../Shared/AdminFormInputs';

type AdminUpdateAdCategoryWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  category: AdCategory | null;
  onFormSuSubmit?: () => void;
  fullScreenOnMobile: boolean;
};

const AdminUpdateAdCategoryWindow: FunctionComponent<AdminUpdateAdCategoryWindowProps> = ({
  onCloseClick,
  category,
  onFormSuSubmit,
  open
}) => {
  const { t } = useTranslation();

  if (!category) return null;

  const onSubmit = async (values: AdminUpdateAdCategoryValues) => {
    try {
      await adminAdsOperations.updateAdCategory(category.id, values);
      onCloseClick();
      if (onFormSuSubmit) {
        onFormSuSubmit();
      }
      Toast.success(t('notifications.adminAds.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminAds.errorOperation'));
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="name" section="adminAds" />
      <AdminTextInput t={t} name="description" section="adminAds" />
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.create')}
      header={t('pages.adminAds.windows.updateAdCategory')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        name: category.name,
        description: category.description
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(app.maxInputLength).required(),
        description: Yup.string().max(app.maxInputLength)
      })}
      maxWidth="md"
    />
  );
};

export default AdminUpdateAdCategoryWindow;
