import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';
import { StoryChannelType } from '../../../../store/Stories/types';

type AttachmentsGuidelinesWindowProps = {
  channelType: StoryChannelType;
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AttachmentsGuidelinesWindow: FunctionComponent<AttachmentsGuidelinesWindowProps> = ({
  channelType,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      open={open}
      header={`${t(`pages.story.channels.${channelType}`)} ${t('attachments.header')}`}
      onCloseClick={onCloseClick}
      hideActionButtons
      maxWidth="sm"
    >
      <Row
        dangerouslySetInnerHTML={{
          __html: t(`attachments.guidelines.${channelType}`)
        }}
      />
    </ModalConfirmationWindow>
  );
};

export default AttachmentsGuidelinesWindow;
