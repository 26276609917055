import React, { FunctionComponent } from 'react';
import PrintPublishStepper, { PrintPublishStep } from './Items/PrintPublishStepper';

type PrintPublishExpressEmailProps = {
  form: JSX.Element | null;
};

const PrintPublishFinalizeStep: FunctionComponent<PrintPublishExpressEmailProps> = ({ form }) => {
  return (
    <>
      <PrintPublishStepper activeStep={PrintPublishStep.Finalize} />
      <div>{form}</div>
    </>
  );
};

export default PrintPublishFinalizeStep;
