import React, { FunctionComponent } from 'react';
import PublicLayoutRoutesContainer from './PublicLayoutRoutesContainer';

type PublicLayoutProps = {};

const PublicLayout: FunctionComponent<PublicLayoutProps> = () => {
  return <PublicLayoutRoutesContainer />;
};

export default PublicLayout;
