import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminPasswordPolicy } from '../../../../../store/AdminPasswordPolicies/types';
import { Checkbox } from '@material-ui/core';
import { Row } from '../../../../Shared/StyledComponents';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import Loader from '../../../../Shared/Loading/Loader';

type AdminShowPasswordPolicyWindowProps = {
  open: boolean;
  passwordPolicy: AdminPasswordPolicy | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminShowPasswordPolicyWindow: FunctionComponent<AdminShowPasswordPolicyWindowProps> = ({
  passwordPolicy,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();

  if (!passwordPolicy) return null;

  return (
    <ModalConfirmationWindow
      open={open}
      header={passwordPolicy.isGlobal ? t('pages.systemSettings.passwordPolicyTitle') : passwordPolicy.name}
      hideActionButtons
      onCloseClick={onCloseClick}
    >
      {!passwordPolicy && <Loader />}
      {passwordPolicy && (
        <>
          {passwordPolicy.description && <Row>{passwordPolicy.description}</Row>}
          <Row>
            {t('pages.adminPasswordPolicies.inputs.passwordLength')}: <strong>{passwordPolicy.passwordLength}</strong>
          </Row>
          <Row>
            {t('pages.adminPasswordPolicies.inputs.upper')}: <Checkbox checked={passwordPolicy.upper} disabled />
          </Row>
          <Row>
            {t('pages.adminPasswordPolicies.inputs.lower')}: <Checkbox checked={passwordPolicy.lower} disabled />
          </Row>
          <Row>
            {t('pages.adminPasswordPolicies.inputs.numbers')}: <Checkbox checked={passwordPolicy.numbers} disabled />
          </Row>
          <Row>
            {t('pages.adminPasswordPolicies.inputs.specialCharacters')}:{' '}
            <Checkbox checked={passwordPolicy.specialCharacters} disabled />
          </Row>
          <Row>
            {t('pages.adminPasswordPolicies.inputs.forceRotation')}:{' '}
            <Checkbox checked={passwordPolicy.forceRotation} disabled />
          </Row>
          <Row>
            {t('pages.adminPasswordPolicies.inputs.rotationDays')}: <strong>{passwordPolicy.rotationDays}</strong>
          </Row>
          <Row>
            {t('pages.adminPasswordPolicies.inputs.forceUnique')}:{' '}
            <Checkbox checked={passwordPolicy.forceUnique} disabled />
          </Row>
          <Row>
            {t('pages.adminPasswordPolicies.inputs.historyLength')}: <strong>{passwordPolicy.historyLength}</strong>
          </Row>
        </>
      )}
    </ModalConfirmationWindow>
  );
};

export default AdminShowPasswordPolicyWindow;
