import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';

export type AdminPasswordPoliciesState = StateWithPagination &
  StateWithTableSearch<AdminPasswordPolicy> & {
    passwordPolicies: NormalizedAdminPasswordPolicies;
    allPasswordPolicies: AdminPasswordPolicy[];

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedAdminPasswordPolicies = {
  entities: Dictionary<AdminPasswordPolicy>;
  result: string[];
};
export type CreateAdminPasswordPolicyResponse = AdminPasswordPolicy;
export type RemoveAdminPasswordPolicyResponse = AdminPasswordPolicy;

export type AdminPasswordPoliciesIndexResponse = {
  data: AdminPasswordPolicy[];
  pagination: Pagination;
};

export type AdminPasswordPoliciesIndexActionBEGIN = {
  type: AdminPasswordPoliciesActionsConstants.INDEX_BEGIN;
};

export type AdminPasswordPoliciesIndexActionSUCCESS = {
  type: AdminPasswordPoliciesActionsConstants.INDEX_SUCCESS;
  payload: AdminPasswordPoliciesIndexResponse;
};

export type IndexAdminPasswordPoliciesRequestParams = {
  per: number;
  page: number;
  order?: string[];
  q: string | null;
};

export type AdminCreatePasswordPolicyValues = Pick<
  AdminPasswordPolicy,
  | 'name'
  | 'description'
  | 'passwordLength'
  | 'upper'
  | 'lower'
  | 'numbers'
  | 'specialCharacters'
  | 'forceRotation'
  | 'rotationDays'
  | 'forceUnique'
  | 'historyLength'
>;

export type AdminUpdatePasswordPolicyValues = Pick<
  AdminPasswordPolicy,
  | 'name'
  | 'description'
  | 'passwordLength'
  | 'upper'
  | 'lower'
  | 'numbers'
  | 'specialCharacters'
  | 'forceRotation'
  | 'rotationDays'
  | 'forceUnique'
  | 'historyLength'
>;

export type AdminPasswordPoliciesIndexActionFAILED = {
  type: AdminPasswordPoliciesActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type AdminPasswordPolicy = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  isGlobal: boolean;
  passwordLength: number;
  upper: boolean;
  lower: boolean;
  numbers: boolean;
  specialCharacters: boolean;
  forceRotation: boolean;
  rotationDays: number;
  forceUnique: boolean;
  historyLength: number;
  organizationId: string | null;
  inherited: boolean;
  name: string;
  description: string;
};

export type AdminPasswordPoliciesResponse = {
  data: AdminPasswordPolicy[];
};

export enum AdminPasswordPoliciesActionsConstants {
  INDEX_BEGIN = 'ADMIN_PASSWORD_POLICIES/INDEX/BEGIN',
  INDEX_SUCCESS = 'ADMIN_PASSWORD_POLICIES/INDEX/SUCCESS',
  INDEX_FAILED = 'ADMIN_PASSWORD_POLICIES/INDEX/FAILED'
}

export type AdminPasswordPoliciesActionType =
  | AdminPasswordPoliciesIndexActionBEGIN
  | AdminPasswordPoliciesIndexActionSUCCESS
  | AdminPasswordPoliciesIndexActionFAILED;
