import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import { AdPlacementStatus } from '../../types';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Strong } from '../../../../../Shared/StyledComponents';

export type AdSpacesConstraintStatusProps = {
  availableAdSpaces: AdPlacementStatus[];
};

export const AdSpacesConstraintStatus: React.FC<AdSpacesConstraintStatusProps> = ({ availableAdSpaces }) => {
  const { t } = useTranslation();
  const invalidAdSpaces = availableAdSpaces.filter((space) => !space.isValid);

  const tooltip = invalidAdSpaces.length ? (
    <div>
      <Strong>{t('common.notAllAdsConstraintsFulfilled')}</Strong>
      {invalidAdSpaces.map((adSpace) => {
        if (!adSpace.placed) {
          return (
            <InvalidAdRow>
              <b>
                {adSpace.width}x{adSpace.height}
              </b>{' '}
              Ad is not placed in the email content.
            </InvalidAdRow>
          );
        }
        if (adSpace.currentRowIndex !== null && adSpace.currentRowIndex > adSpace.requiredRowIndex) {
          return (
            <InvalidAdRow>
              <b>
                {adSpace.width}x{adSpace.height}
              </b>{' '}
              Ad is too low, please move it higher. It has to be placed on row no. {adSpace.requiredRowIndex + 1} or
              above.
            </InvalidAdRow>
          );
        }

        return null;
      })}
    </div>
  ) : (
    t('common.allAdsConstraintsFulfilled')
  );

  return (
    <Wrapper>
      <Tooltip title={tooltip}>{invalidAdSpaces.length ? <WarningIconStyled /> : <OkIconStyled />}</Tooltip>
    </Wrapper>
  );
};

const InvalidAdRow = styled.p`
  margin: 8px 0;
`;

const WarningIconStyled = styled(WarningIcon)`
  color: #f5852b;
`;

const OkIconStyled = styled(CheckCircleIcon)`
  color: #11862d;
`;

const Wrapper = styled.span`
  font-size: 0.8rem;
  vertical-align: middle;
  font-style: italic;
  opacity: 0.8;
  margin-left: 2rem;
`;
