import { Dispatch } from 'redux';
import { httpRequest } from '../../utils';
import { GetMainStorageUrlResponse, GetVersionsResponse } from './types';
import { appActions, appRequests } from './index';

const getVersions = () => async (dispatch: Dispatch) => {
  dispatch(appActions.getVersionsBegin());
  try {
    const response = await getVersionsRaw();
    dispatch(appActions.getVersionsSuccess(response));
    return response;
  } catch (e) {
    dispatch(appActions.getVersionsFailed(e));
    return null;
  }
};

const getVersionsRaw = async () => {
  return await httpRequest.runRequest<GetVersionsResponse>(appRequests.getVersions());
};

const getMainStorageUrl = async () => {
  return await httpRequest.runRequest<GetMainStorageUrlResponse>(appRequests.getMainStorageUrl());
};

export default {
  getVersions,
  getVersionsRaw,
  getMainStorageUrl
};
