import {
  AdminOrganizationUsersActionsConstants,
  AdminOrganizationUsersIndexActionBEGIN,
  AdminOrganizationUsersIndexActionFAILED,
  AdminOrganizationUsersIndexActionSUCCESS,
  AdminOrganizationUsersIndexResponse
} from './types';

export const organizationUsersIndexBegin = (): AdminOrganizationUsersIndexActionBEGIN => ({
  type: AdminOrganizationUsersActionsConstants.INDEX_BEGIN
});

export const organizationUsersIndexSuccess = (
  payload: AdminOrganizationUsersIndexResponse
): AdminOrganizationUsersIndexActionSUCCESS => ({
  type: AdminOrganizationUsersActionsConstants.INDEX_SUCCESS,
  payload
});

export const organizationUsersIndexFailed = (error: any): AdminOrganizationUsersIndexActionFAILED => ({
  type: AdminOrganizationUsersActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});
