import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import { formatTableTimestamp, timestampToTime, useTypedSelector } from '../../../../utils';
import { usePaginationWatch } from '../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../Shared/DataTable/useTableSearchWatch';
import { PaginationStoreModule } from '../../../../store/Pagination/types';
import DataTable from '../../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../../Shared/DataTable/TableActionButton/TableActionButton';
import { TableSearchStoreModule } from '../../../../store/TableSearch/types';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import AdminDeleteOrganizationUserWindow from './Windows/AdminDeleteOrganizationWindow';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { adminOrganizationsOperations } from '../../../../store/AdminOrganizations';
import { AdminOrganization } from '../../../../store/AdminOrganizations/types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import { hasPermission } from '../../../../utils/permissions';
import { adminPasswordPoliciesOperations } from '../../../../store/AdminPasswordPolicies';
import { AdminPasswordPolicy } from '../../../../store/AdminPasswordPolicies/types';
import AdminShowPasswordPolicyWindow from './Windows/AdminShowPasswordPolicyWindow';
import { Link, Typography, withTheme } from '@material-ui/core';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import NotVerifiedIcon from '@material-ui/icons/HighlightOff';
import { adminKeywordsOperations } from '../../../../store/AdminKeywords';
import AdminKeywordsList from '../../../Shared/Tags/AdminKeywordsList';
import { AdminKeyword, AdminKeywordGroup } from '../../../../store/AdminKeywords/types';
import { adminEmailTemplatesOperations } from '../../../../store/AdminEmailTemplates';
import { AdminEmailTemplate } from '../../../../store/AdminEmailTemplates/types';

type AdminOrganizationsPageProps = {};

const AdminOrganizationsPage: FunctionComponent<AdminOrganizationsPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);
  const organizations = useTypedSelector((state) => state.adminOrganizations.organizations);
  const isFailed = useTypedSelector((state) => state.adminOrganizations.index.isFailed);
  const isLoading = useTypedSelector((state) => state.adminOrganizations.index.isLoading);
  const pagination = useTypedSelector((state) => state.adminOrganizations.pagination);
  const tableSearch = useTypedSelector((state) => state.adminOrganizations.tableSearch);
  const allKeywords = useTypedSelector((state) => state.adminKeywords.all);
  const [passwordPolicies, setPasswordPolicies] = useState<AdminPasswordPolicy[]>([]);
  const [emailTemplates, setEmailTemplates] = useState<AdminEmailTemplate[]>([]);
  const userRole = useTypedSelector((state) => state.auth.role);

  useEffect(() => {
    const fetchKeywords = async () => {
      await dispatch(adminKeywordsOperations.getAllKeywords());
    };
    fetchKeywords();
  }, []);

  useEffect(() => {
    const fetchPasswordPolicies = async () => {
      const policies = await adminPasswordPoliciesOperations.getAll();
      setPasswordPolicies(policies.data);
      const emailTemplates = await adminEmailTemplatesOperations.getAll();
      setEmailTemplates(emailTemplates);
    };
    fetchPasswordPolicies();
  }, []);

  usePaginationWatch(pagination, [adminOrganizationsOperations.index]);
  useTableSearchWatch<AdminOrganization>(tableSearch, [adminOrganizationsOperations.index]);

  const [activeOrganization, setActiveOrganization] = useState<AdminOrganization | null>(null);
  const [activePasswordPolicy, setActivePasswordPolicy] = useState<AdminPasswordPolicy | null>(null);
  const [
    deleteOrganizationWindowOpen,
    onDeleteOrganizationWindowOpen,
    onDeleteOrganizationWindowClose
  ] = useOpenHandler();
  const [
    showPasswordPolicyWindowOpen,
    onShowPasswordPolicyWindowOpen,
    onShowPasswordPolicyWindowClose
  ] = useOpenHandler();

  const getActionButtons = () => {
    if (hasPermission(role, ['organizationsAdd'])) {
      return [
        {
          label: t('pages.adminOrganizations.createOrganization'),
          icon: <AddIcon />,
          onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.CREATE))
        }
      ];
    }

    return [];
  };

  const getPasswordPolicyName = (id: string) => {
    const passwordPolicy = passwordPolicies.find((passwordPolicy) => passwordPolicy.id === id);
    if (passwordPolicy) {
      return passwordPolicy.isGlobal ? t('pages.systemSettings.passwordPolicyTitle') : passwordPolicy.name;
    }
    return '';
  };

  const getPasswordPolicy = (id: string) => {
    const passwordPolicy = passwordPolicies.find((passwordPolicy) => passwordPolicy.id === id);
    if (passwordPolicy) {
      return passwordPolicy;
    }
    return null;
  };

  const onPasswordPolicyClick = (id: string) => {
    setActivePasswordPolicy(getPasswordPolicy(id));
    onShowPasswordPolicyWindowOpen();
  };

  const onAddKeywordToOrganization = useCallback((organization: AdminOrganization, keyword: AdminKeyword) => {
    dispatch(adminOrganizationsOperations.addKeywordToOrganization(organization, keyword));
  }, []);

  const onAddKeywordGroupToOrganization = useCallback((organization: AdminOrganization, group: AdminKeywordGroup) => {
    dispatch(adminOrganizationsOperations.addKeywordGroupToOrganization(organization, group));
  }, []);

  const onDeleteKeywordFromOrganization = useCallback((organization: AdminOrganization, keyword: AdminKeyword) => {
    dispatch(adminOrganizationsOperations.removeKeywordFromOrganization(organization, keyword));
  }, []);

  const onDeleteKeywordGroupFromOrganization = useCallback(
    (organization: AdminOrganization, group: AdminKeywordGroup) => {
      dispatch(adminOrganizationsOperations.removeKeywordGroupFromOrganization(organization, group));
    },
    []
  );

  return (
    <PageContainer>
      <PageHeader title={t('pages.adminOrganizations.title')} rightActionButtons={getActionButtons()} />
      <PageContent>
        <DataTable<AdminOrganization, 'actions' | 'damSystem'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'name',
              sortable: true,
              style: { minWidth: '250px' },
              render: (organization) => (
                <>
                  {hasPermission(role, ['organizationsView']) ? (
                    <TableLink
                      onClick={() => dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.USERS(organization.id)))}
                    >
                      {organization.name}
                    </TableLink>
                  ) : (
                    <TableLinkDisabled>{organization.name}</TableLinkDisabled>
                  )}
                  <Typography variant="caption" display="block">
                    {organization.accountNumber}
                  </Typography>
                </>
              )
            },
            {
              name: 'passwordPolicyId',
              sortable: true,
              render: (organization) => (
                <TableLink onClick={() => onPasswordPolicyClick(organization.passwordPolicyId)}>
                  {getPasswordPolicyName(organization.passwordPolicyId)}
                </TableLink>
              )
            },
            {
              name: 'actions',
              render: (organization) => (
                <TableActionButtonsContainer>
                  <TableActionButton
                    icon={<VisibilityIcon />}
                    tooltip={t('pages.adminOrganizations.table.buttons.open')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['organizationsView']),
                      onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.USERS(organization.id)))
                    }}
                  />
                  <TableActionButton
                    icon={<FileCopyIcon />}
                    tooltip={t('pages.adminOrganizations.table.buttons.ads')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['adsView']),
                      onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.ADS(organization.id)))
                    }}
                  />
                  <TableActionButton
                    icon={<QueuePlayNextIcon />}
                    tooltip={t('pages.adminOrganizations.table.buttons.adsAssignment')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['adsView']),
                      onClick: () =>
                        dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.ADS_ASSIGNMENT(organization.id)))
                    }}
                  />
                  <TableActionButton
                    icon={<EditIcon />}
                    tooltip={t('pages.adminOrganizations.table.buttons.edit')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['organizationsEdit']),
                      onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.EDIT(organization.id)))
                      // onClick: () => {
                      //   setActiveOrganization(organization);
                      //   onUpdateOrganizationWindowOpen();
                      // }
                    }}
                  />
                  <TableActionButton
                    icon={<DeleteIcon />}
                    tooltip={t('pages.adminOrganizations.table.buttons.delete')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['organizationsRemove']),
                      onClick: () => {
                        setActiveOrganization(organization);
                        onDeleteOrganizationWindowOpen();
                      }
                    }}
                  />
                </TableActionButtonsContainer>
              )
            },
            {
              name: 'keywords',
              render: (organization) => (
                <>
                  <AdminKeywordsList
                    readOnly={!hasPermission(userRole, ['keywordsAssociate'])}
                    allKeywords={allKeywords}
                    keywords={organization.keywords}
                    groups={organization.keywordGroups}
                    onDelete={
                      hasPermission(userRole, ['keywordsAssociate'])
                        ? (keyword: AdminKeyword) => onDeleteKeywordFromOrganization(organization, keyword)
                        : null
                    }
                    onDeleteGroup={
                      hasPermission(userRole, ['keywordsAssociate'])
                        ? (group: AdminKeywordGroup) => onDeleteKeywordGroupFromOrganization(organization, group)
                        : null
                    }
                    onAdd={(keyword: AdminKeyword) => onAddKeywordToOrganization(organization, keyword)}
                    onAddGroup={(group: AdminKeywordGroup) => onAddKeywordGroupToOrganization(organization, group)}
                  />
                </>
              )
            },
            {
              name: 'colorSwatchesLimit',
              render: (organization) => (
                <>{organization.colorSwatchesLimit ? organization.colorSwatchesLimit : 'N/A'}</>
              ),
              sortable: true
            },
            {
              name: 'printCenters',
              label: t('pages.commonInputs.inputs.printCenter'),
              render: (organization) => (
                <>
                  {organization.printCenters.map((printCenter) => (
                    <div key={`${organization.id}-pc-${printCenter.id}`}>
                      <strong>{printCenter.name}</strong>
                      <br />
                      <Link href={printCenter.url} target="_blank">
                        {printCenter.url}
                      </Link>
                    </div>
                  ))}
                </>
              ),
              isEmpty: (organization) => !organization.printCenters.length,
              sortable: false
            },
            {
              name: 'injectLogoToPrintProjects',
              render: (organization) =>
                organization.injectLogoToPrintProjects ? <CheckIconStyled /> : <CrossIconStyled />,
              sortable: true
            },
            {
              name: 'stripProtocolFromPrintInjectedUrls',
              render: (organization) =>
                organization.stripProtocolFromPrintInjectedUrls ? <CheckIconStyled /> : <CrossIconStyled />,
              sortable: true
            },
            {
              name: 'createdAt',
              render: (organization) => <>{formatTableTimestamp(organization.createdAt)}</>,
              sortable: true
            },
            {
              name: 'readMoreLink',
              render: (organization) => (
                <>
                  {organization.readMoreLink ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      title={organization.readMoreLink}
                      href={organization.readMoreLink}
                    >
                      {organization.readMoreLink}
                    </a>
                  ) : (
                    'N/A'
                  )}
                </>
              ),
              sortable: true
            },
            {
              name: 'emailWizardEnabled',
              render: (organization) => (organization.emailWizardEnabled ? <CheckIconStyled /> : <CrossIconStyled />),
              sortable: true
            },
            {
              name: 'automatedEmailEnabled',
              render: (organization) =>
                organization.automatedEmailEnabled ? (
                  <>
                    <CheckIconStyled />
                    {organization.automatedEmailDateSchedule && (
                      <p>{t(`common.automatedEmailDateSchedule.${organization.automatedEmailDateSchedule}`)}</p>
                    )}
                    {organization.automatedEmailTime && <p>{timestampToTime(organization.automatedEmailTime)}</p>}
                  </>
                ) : (
                  <CrossIconStyled />
                ),
              sortable: true
            },
            {
              name: 'defaultAutomatedEmailMailingList',
              render: (organization) => <>{organization.defaultAutomatedEmailMailingList?.name || '---'}</>,
              sortable: true
            },
            {
              name: 'damSystem',
              render: (organization) => (
                <>
                  {organization.damIntegrations.length && organization.damIntegrations[0].system ? (
                    <div>
                      <p>{t(`common.damSystem.${organization.damIntegrations[0].system.name}`)}</p>
                      <a
                        style={{ fontSize: '0.7rem' }}
                        title={organization.damIntegrations[0].system.url}
                        href={organization.damIntegrations[0].system.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {organization.damIntegrations[0].system.url}
                      </a>
                    </div>
                  ) : (
                    '---'
                  )}
                </>
              ),
              sortable: false
            },
            {
              name: 'defaultAutomatedEmailTemplateId',
              render: (organization) => {
                const template =
                  organization.defaultAutomatedEmailTemplateId &&
                  emailTemplates.find((template) => template.id === organization.defaultAutomatedEmailTemplateId);
                return <>{template ? template.name : '---'}</>;
              },
              sortable: false
            },
            {
              name: 'senderEmail',
              render: (organization) => <>{organization.senderEmail || '---'}</>,
              sortable: true
            },
            {
              name: 'socialPublishing',
              render: (organization) => (organization.socialPublishing ? <CheckIconStyled /> : <CrossIconStyled />),
              sortable: true
            },
            {
              name: 'region',
              render: (organization) => <>{organization.region || '---'}</>,
              sortable: true
            },
            {
              name: 'district',
              render: (organization) => <>{organization.district || '---'}</>,
              sortable: true
            }
          ]}
          tPath={'pages.adminOrganizations.table'}
          data={organizations}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.ADMIN_ORGANIZATIONS
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.ADMIN_ORGANIZATIONS
            // onPageChange: () => {},
            // onPerChange: () => {}
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>

      <AdminDeleteOrganizationUserWindow
        organization={activeOrganization}
        open={deleteOrganizationWindowOpen}
        onCloseClick={onDeleteOrganizationWindowClose}
        fullScreenOnMobile
      />
      <AdminShowPasswordPolicyWindow
        passwordPolicy={activePasswordPolicy}
        open={showPasswordPolicyWindowOpen}
        onCloseClick={onShowPasswordPolicyWindowClose}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

export const TableLink = withTheme(styled(Link)`
  text-decoration: none !important;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  cursor: pointer;
`);

export const TableLinkDisabled = withTheme(styled(Link)`
  text-decoration: none !important;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
`);

const CheckIconStyled = withTheme(styled(CheckIcon)`
  color: ${({ theme }) => theme.palette.success.main};
`);

const CrossIconStyled = withTheme(styled(NotVerifiedIcon)`
  color: ${({ theme }) => theme.palette.warning.main};
`);

export default AdminOrganizationsPage;
