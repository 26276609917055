import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@material-ui/core';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import Toast from '../../../Shared/Toast/Toast';
import { AdminOrganization } from '../../../../store/AdminOrganizations/types';
import { PageContentPaper } from '../../PageStyledComponents';
import OrganizationTree, { buildOrganizationStructure } from './OrganizationTree/OrganizationTree';

type OrganizationStructureProps = {};

const OrganizationStructure: FunctionComponent<OrganizationStructureProps> = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tree, setTree] = useState<AdminOrganization[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await myOrganizationOperations.getOrganizationTree();

        setTree(buildOrganizationStructure(res.data));

        setLoading(false);
      } catch (e) {
        Toast.error(t('notifications.myOrganization.cannotFetchOrganizationStructure'));
      }
    };

    fetchData();
  }, []);

  if (loading || tree.length === 0) return null;

  return (
    <PageContentPaper>
      <Grid container justify="space-between" alignItems="center">
        <Typography variant="h6">{t('pages.myOrganization.organizationTree.title')}</Typography>
      </Grid>
      <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
      <Box>
        <OrganizationTree items={tree} showCheckboxes={false} />
      </Box>
    </PageContentPaper>
  );
};

export default OrganizationStructure;
