import {
  CancelEmailProjectPublicationAction,
  CheckInEmailProjectAction,
  EmailProject,
  EmailProjectsActionsConstants,
  EmailProjectsIndexActionBEGIN,
  EmailProjectsIndexActionFAILED,
  EmailProjectsIndexActionSUCCESS,
  EmailProjectsIndexResponse,
  PublishEmailProjectAction,
  RemoveEmailProjectAction,
  SaveEmailProjectSubjectAction,
  ShareEmailProjectAction
} from './types';
import { ShortUser } from '../Auth/types';

export const projectsIndexBegin = (): EmailProjectsIndexActionBEGIN => ({
  type: EmailProjectsActionsConstants.INDEX_BEGIN
});

export const projectsIndexSuccess = (payload: EmailProjectsIndexResponse): EmailProjectsIndexActionSUCCESS => ({
  type: EmailProjectsActionsConstants.INDEX_SUCCESS,
  payload
});

export const projectsIndexFailed = (error: any): EmailProjectsIndexActionFAILED => ({
  type: EmailProjectsActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const removeProject = (projectId: string): RemoveEmailProjectAction => ({
  type: EmailProjectsActionsConstants.REMOVE_PROJECT,
  payload: projectId
});

export const shareProject = (projectId: string): ShareEmailProjectAction => ({
  type: EmailProjectsActionsConstants.SHARE_PROJECT,
  payload: projectId
});

export const publishProject = (project: EmailProject): PublishEmailProjectAction => ({
  type: EmailProjectsActionsConstants.PUBLISH_PROJECT,
  payload: project
});

export const saveProjectSubject = (project: EmailProject): SaveEmailProjectSubjectAction => ({
  type: EmailProjectsActionsConstants.SAVE_PROJECT_SUBJECT,
  payload: project
});

export const cancelProjectPublication = (project: EmailProject): CancelEmailProjectPublicationAction => ({
  type: EmailProjectsActionsConstants.CANCEL_PUBLISH_PROJECT,
  payload: project
});

export const checkIn = (projectId: string, user: ShortUser): CheckInEmailProjectAction => ({
  type: EmailProjectsActionsConstants.CHECK_IN_PROJECT,
  payload: {
    projectId,
    user
  }
});
