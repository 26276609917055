import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminKeyword } from '../../../../../store/AdminKeywords/types';
import { adminKeywordsOperations } from '../../../../../store/AdminKeywords';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';
import Toast from '../../../../Shared/Toast/Toast';

type AdminDeleteKeywordWindowProps = {
  open: boolean;
  keyword: AdminKeyword | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminDeleteKeywordWindow: FunctionComponent<AdminDeleteKeywordWindowProps> = ({
  keyword,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!keyword) return null;

  const onSubmit = async () => {
    try {
      await dispatch(adminKeywordsOperations.deleteKeyword(keyword.id));
      await dispatch(adminKeywordsOperations.index());
      onCloseClick();
      Toast.success(t('notifications.adminKeywords.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminKeywords.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminKeywords.windows.deleteKeyword')}
      okButtonText={t('pages.adminKeywords.windows.completelyDelete')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.adminKeywords.windows.deleteKeywordContent')}</Row>
      <Row>
        <Strong>{keyword.name}</Strong>
      </Row>
      <Row>
        <InfoBox type="warning">{t('pages.adminKeywords.windows.cannotBeUndone')}</InfoBox>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default AdminDeleteKeywordWindow;
