import React from 'react';
import { withTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import app from '../../../../config/app/app';
import { StyledTableCell } from '../../StyledComponents';

export type UrlColumnCellProps<E = never> = {
  value: E | ValueOf<E>;
  url: string | ((entity: E) => string);
  render?: (entity: E) => JSX.Element;
  urlExternal?: boolean;
};

export function UrlColumnCell<E = never>({ value, render, url, urlExternal }: UrlColumnCellProps<E>) {
  return (
    <StyledTableCell style={{ maxWidth: app.maxTableColumnWidth }}>
      {urlExternal && (
        <TableLink target="_blank" rel="noopener noreferrer" href={typeof url === 'string' ? url : url(value as E)}>
          {render ? render(value as E) : value}
        </TableLink>
      )}
      {!urlExternal && (
        <TableLinkExternal to={typeof url === 'string' ? url : url(value as E)}>
          {render ? render(value as E) : value}
        </TableLinkExternal>
      )}
    </StyledTableCell>
  );
}

export const TableLink = withTheme(styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  cursor: pointer;
`);

export const TableLinkExternal = withTheme(styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
`);

export const TableLinkDisabled = withTheme(styled(Link)`
  text-decoration: none !important;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  cursor: default;
`);
