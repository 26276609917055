import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import MyAccountPage from './MyAccountPage';

const MyAccountRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.MY_ACCOUNT,
    exact: true,
    name: 'myAccount',
    public: false,
    show: true,
    component: MyAccountPage,
    scope: []
  }
];

export default MyAccountRoutes;
