import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker, Range } from 'react-date-range';
import { addDays } from 'date-fns';
import { createStyles, makeStyles } from '@material-ui/core';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';

type DateRangePickerWindowProps = {
  range?: Range[];
  open: boolean;
  onCloseClick: () => void;
  onSaveClick: (range: Range[]) => void;
  fullScreenOnMobile: boolean;
  label?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    dateRangePicker: {
      '& .rdrDefinedRangesWrapper': {
        display: 'none'
      }
    }
  })
);

const DateRangePickerWindow: FunctionComponent<DateRangePickerWindowProps> = ({
  onCloseClick,
  open,
  range,
  onSaveClick,
  label
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [publicationPeriod, setPublicationPeriod] = useState<Range[]>(
    range
      ? range
      : [
          {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
          }
        ]
  );

  return (
    <ModalConfirmationWindow
      open={open}
      header={label || ''}
      okButtonText={t('common.save')}
      onCloseClick={onCloseClick}
      onSubmit={() => {
        onSaveClick(publicationPeriod);
        onCloseClick();
      }}
      maxWidth="lg"
    >
      <Row>
        <DateRangePicker
          onChange={(item) => setPublicationPeriod([item.selection])}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={publicationPeriod}
          direction="horizontal"
          staticRanges={[]}
          inputRanges={[]}
          showDateDisplay={false}
          className={classes.dateRangePicker}
        />
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DateRangePickerWindow;
