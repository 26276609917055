import React, { FunctionComponent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { StoryChannel, StoryChannelType } from '../../../../store/Stories/types';
import { storiesOperations } from '../../../../store/Stories';
import Toast from '../../../Shared/Toast/Toast';
import { AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import ModalFormWindow from '../../../Shared/Window/ModalFormWindow';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Button, Grid, Typography } from '@material-ui/core';
import { Row, Strong } from '../../../Shared/StyledComponents';
import styled from 'styled-components';
import Loader from '../../../Shared/Loading/Loader';
import { createNetworkErrorObject } from '../../../../utils';
import moment from 'moment';
import { FormikProps } from 'formik';
import { Alert } from '@material-ui/lab';

type PublishSocialChannelWindowProps = {
  type: StoryChannelType;
  open: boolean;
  channel: StoryChannel;
  onCloseClick: () => void;
  onSubmit: () => void;
  fullScreenOnMobile: boolean;
};

const PublishSocialChannelWindow: FunctionComponent<PublishSocialChannelWindowProps> = ({
  onCloseClick,
  type,
  onSubmit,
  open,
  channel
}) => {
  const { t } = useTranslation();

  const onPublish = async (values: { publishDate: string; publishTime: string }) => {
    try {
      const publishDateAndTime = new Date(`${values.publishDate}T${values.publishTime}`);
      await storiesOperations.publishChannel(channel.storyId, channel.id, {
        publishDate: values.publishDate,
        publishTime: publishDateAndTime
      });
      onCloseClick();
      onSubmit();
    } catch (e) {
      const networkError = createNetworkErrorObject(e);
      switch (networkError.message) {
        case 'social_account_not_connected':
          return Toast.error(t('notifications.story.channelPublishErrorSocialAccountNotConnected'));
        case 'post_body_empty':
          return Toast.error(t('notifications.story.channelPublishErrorPostBodyEmpty'));
        case 'already_published':
          return Toast.error(t('notifications.story.channelPublishErrorAlreadyPublished'));
        default:
          return Toast.error(t('notifications.story.channelPublishError'));
      }
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <Grid container>
      <Grid item xs={12}>
        <div style={{ textAlign: 'center' }}>
          <Row>{t('pages.story.windows.publish.youAreAboutToPublish')}</Row>
          <Button
            style={{ margin: '1rem 0 0.5rem 0' }}
            color="secondary"
            variant="contained"
            onClick={async () => {
              const now = moment();
              await onPublish({
                publishDate: now.format('YYYY-MM-DD'),
                publishTime: now.format('HH:mm')
              });
            }}
            size="large"
          >
            {t('pages.story.windows.publish.publishNow')}
          </Button>
          <Row>
            <Typography variant="h6">{t('common.or')}</Typography>
            <Strong>{t('pages.story.windows.publish.selectSpecificDateTime')}</Strong>
          </Row>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <AdminTextInput t={t} section="story" name="publishDate" type="date" />
          </Grid>
          <Grid item xs={6}>
            <AdminTextInput
              t={t}
              section="story"
              name="publishTime"
              type="time"
              inputProps={{
                step: 300
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.publish')}
      header={t(`pages.story.windows.publish.header.${type}`)}
      onCloseClick={onCloseClick}
      onSubmit={onPublish}
      formInputs={formInputs}
      initialValues={{
        publishDate: '',
        publishTime: ''
      }}
      validationSchema={Yup.object().shape({
        publishDate: Yup.string().required(),
        publishTime: Yup.string().required()
      })}
      maxWidth="lg"
    />
  );
};

export default PublishSocialChannelWindow;
