import { createOrderArray, httpRequest } from '../../../utils';
import { DoAppPromotionRangeQuery, DoAppPromotionsIndexResponse, DoAppPromotionsMineIndexResponse } from './types';
import { doAppPromotionsActions, doAppPromotionsRequests } from './index';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../rootReducer';

export const indexRaw = (page: number, per: number, q: string | null, order: any) => {
  return httpRequest.runRequest<DoAppPromotionsIndexResponse>(
    doAppPromotionsRequests.index({
      requestParams: {
        page: page,
        per: per,
        q: q,
        order: createOrderArray(order)
      }
    })
  );
};

export const indexMineRaw = (
  page: number,
  per: number,
  q: string | null,
  order: any,
  range?: DoAppPromotionRangeQuery
) => {
  return httpRequest.runRequest<DoAppPromotionsMineIndexResponse>(
    doAppPromotionsRequests.indexMine({
      requestParams: {
        page: page,
        per: per,
        q: q,
        order: createOrderArray(order),
        range
      }
    })
  );
};

export const indexMerchantPromotions = (range?: DoAppPromotionRangeQuery) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  const { doAppMerchantPromotions } = getState();

  try {
    dispatch(doAppPromotionsActions.doAppPromotionsIndexBegin());

    const response = await indexMineRaw(
      doAppMerchantPromotions.pagination.page,
      doAppMerchantPromotions.pagination.per,
      doAppMerchantPromotions.pagination.q,
      doAppMerchantPromotions.tableSearch.order,
      range
    );

    dispatch(doAppPromotionsActions.doAppPromotionsIndexSuccess(response));
  } catch (e) {
    dispatch(doAppPromotionsActions.doAppPromotionsIndexFailed(e));
  }
};

export const getAllMinePromotions = () => {
  return httpRequest.runRequest<DoAppPromotionsIndexResponse>(doAppPromotionsRequests.getAllMinePromotions());
};
