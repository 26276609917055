import React from 'react';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { emailTemplateVariables } from '../../../types';
import { useTranslation } from 'react-i18next';
import Toast from '../../../../../../Shared/Toast/Toast';

export const VariableElements: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <StyledPaper>
      <Heading>{t('pages.emailEditor.variableTitle')}</Heading>
      <HeadingSecondary>{t('pages.emailEditor.variableInfo')}</HeadingSecondary>
      {emailTemplateVariables.map((variableKey, i) => (
        <VariableWrapper
          key={`variable-element-${i}`}
          onClick={() => {
            try {
              navigator.clipboard.writeText(variableKey);
              Toast.success(t('pages.emailEditor.variableCopied'));
            } catch (e) {
              Toast.success(t('pages.emailEditor.variableCopiedError'));
            }
          }}
        >
          <Title>{t(`pages.emailEditor.variables.${variableKey}`)}</Title>
          <Value>{variableKey}</Value>
        </VariableWrapper>
      ))}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  padding: 1rem 2rem;
`;

const VariableWrapper = styled.div`
  border: 1px solid rgb(212, 212, 212);
  background-color: white;
  padding: 0.7rem 1rem 0.4rem;
  cursor: pointer;
  border-radius: 10px;
  color: rgb(113, 113, 117);
  text-align: left;
  margin-bottom: 8px;
`;
const Title = styled.h2`
  font-weight: bold;
`;
const Value = styled.p`
  font-style: italic;
  font-size: 0.8rem;
`;

const Heading = styled.h2`
  font-weight: bold;
`;

const HeadingSecondary = styled.p`
  font-style: italic;
  font-size: 0.8rem;
  margin-bottom: 1rem;
`;
