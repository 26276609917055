import {
  AuthTicketValidationData,
  ChangePasswordFormValues,
  LoginFormValues,
  LoginRequestResponse,
  MyAccountUpdatePayload,
  OrganizationData,
  SetPasswordFormValues,
  SignUpFormValues,
  ValidateTokenRequestResponse
} from './types';
import { Dispatch } from 'redux';
import * as authRequests from './requests';
import { createNetworkErrorObject, httpRequest } from '../../utils';
import { authActions } from './index';
import { ApplicationState } from '../rootReducer';
import { PasswordPolicy } from '../SystemSettings/types';
import { AyrshareSocialAccount } from '../MyOrganization/types';

export const login = (formValues: LoginFormValues) => async (dispatch: Dispatch) => {
  const response = await httpRequest.runRequest<LoginRequestResponse>(
    authRequests.login({
      requestPayload: formValues
    })
  );
  dispatch(authActions.login(response));
};

export const logout = () => async (dispatch: Dispatch) => {
  try {
    dispatch(authActions.logout());
    await httpRequest.runRequest<void>(authRequests.logout());
  } catch (e) {
    const error = createNetworkErrorObject(e);
    console.error('Logout failed', error);
  }
};

export const validateToken = (payload: { cookie: boolean }) => async (dispatch: Dispatch) => {
  const response = await httpRequest.runRequest<ValidateTokenRequestResponse>(
    authRequests.validateToken({
      requestParams: {
        cookie: payload.cookie
      }
    })
  );
  dispatch(authActions.validateToken(response));
};

export const verifyUser = (
  authTicketValidationData: AuthTicketValidationData,
  setPasswordFormValues: SetPasswordFormValues
) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  await httpRequest.runRequest(
    authRequests.verifyUser({
      requestParams: {
        ticketId: authTicketValidationData.ticketId
      },
      requestPayload: {
        password: setPasswordFormValues.password,
        confirmPassword: setPasswordFormValues.confirmPassword
      }
    })
  );
};

export const setPassword = (
  authTicketValidationData: AuthTicketValidationData,
  setPasswordFormValues: SetPasswordFormValues
) => async () => {
  await httpRequest.runRequest(
    authRequests.setPassword({
      requestParams: {
        ticketId: authTicketValidationData.ticketId
      },
      requestPayload: {
        password: setPasswordFormValues.password,
        confirmPassword: setPasswordFormValues.confirmPassword
      }
    })
  );
};

export const validateAuthTicket = async (authTicketValidationData: AuthTicketValidationData) => {
  return await httpRequest.runRequest<PasswordPolicy>(
    authRequests.validateAuthTicket({
      requestParams: authTicketValidationData
    })
  );
};

export const signUp = (payload: SignUpFormValues) => async () => {
  await httpRequest.runRequest<void>(
    authRequests.signUp({
      requestPayload: {
        email: payload.email
      }
    })
  );
};

export const getOrganizationData = async () => {
  return await httpRequest.runRequest<OrganizationData>(authRequests.getOrganizationData());
};

export const setOrganizationData = async (payload: OrganizationData) => {
  return await httpRequest.runRequest<OrganizationData>(
    authRequests.setOrganizationData({
      requestPayload: payload
    })
  );
};

export const updateAccount = async (payload: MyAccountUpdatePayload) => {
  return await httpRequest.runRequest<ValidateTokenRequestResponse>(
    authRequests.updateAccount({
      requestPayload: payload
    })
  );
};

export const changePassword = async (payload: ChangePasswordFormValues) => {
  return await httpRequest.runRequest(
    authRequests.changePassword({
      requestPayload: payload
    })
  );
};

export const getOrganizationLogo = async () => {
  return await httpRequest.runRequest<{ url: string }>(authRequests.getOrganizationLogo());
};

export const uploadMainLogo = async (payload: File) => {
  const formData = new FormData();
  formData.append('file', payload);
  formData.append('type', 'MAIN');
  formData.append('filename', payload.name);

  return await httpRequest.runRequest<{ url: string }>(
    authRequests.uploadMainLogo({
      requestPayload: formData
    })
  );
};

export const removeMainLogo = async () => {
  return await httpRequest.runRequest<void>(
    authRequests.removeMainLogo({
      // @ts-ignore
      requestPayload: {
        type: 'MAIN'
      }
    })
  );
};

export const switchOrganization = (organizationId: string) => async (dispatch: Dispatch) => {
  await httpRequest.runRequest<void>(
    authRequests.switchOrganization({
      requestParams: { organizationId }
    })
  );

  dispatch(authActions.switchOrganization(organizationId));
};

export const getOrganizationSocialAccountsData = () => async (dispatch: Dispatch) => {
  const response = await httpRequest.runRequest<{ data: AyrshareSocialAccount[] }>(
    authRequests.getOrganizationSocialAccountsData()
  );

  dispatch(authActions.getOrganizationSocialAccountsData(response.data));
};
