import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Input, Slider } from '@material-ui/core';

type ColorPickerSliderProps = {
  label: string;
  color: string;
  value: number;
  onValueChange: (val: number) => void;
};

const ColorPickerSlider: FunctionComponent<ColorPickerSliderProps> = ({ label, onValueChange, color, value }) => {
  const handleSliderChange = (event: any, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      onValueChange(newValue[0]);
    } else {
      onValueChange(newValue);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(Number(event.target.value) || 0);
  };

  const handleBlur = () => {
    if (value < 0) {
      onValueChange(0);
    } else if (value > 100) {
      onValueChange(100);
    }
  };

  return (
    <Wrapper>
      <div>
        <ColorLabel>{label}</ColorLabel>
      </div>
      <SliderWrapper color={color}>
        <Slider value={value} onChange={handleSliderChange} aria-labelledby="input-slider" />
      </SliderWrapper>
      <div>
        <Input
          value={value}
          margin="dense"
          onChange={handleInputChange}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            min: 0,
            max: 100,
            type: 'number',
            'aria-labelledby': 'input-slider'
          }}
        />
      </div>
      <div>%</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SliderWrapper = styled.div<{ color: string }>`
  flex-grow: 1;
  padding: 0 20px;

  .MuiSlider-root {
    color: ${({ color }) => color};
  }

  .MuiSlider-track {
    height: 4px;
  }

  .MuiSlider-rail {
    opacity: 0.8;
    height: 4px;
    box-shadow: #979797 2px 2px 2px 1px;
  }

  .MuiSlider-thumb {
    width: 14px;
    height: 14px;
  }
`;

const ColorLabel = styled.div``;

export default ColorPickerSlider;
