import React, { FunctionComponent, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Backdrop, Fade, Modal, TableCell, TableRow, withTheme } from '@material-ui/core';
import { formatTableTimestamp, useTypedSelector } from '../../../../../utils';
import { AdBanner, AdCategory } from '../../../../../store/AdminAds/types';
import { useTranslation } from 'react-i18next';
import TableActionButtonsContainer from '../../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableActionButton from '../../../../Shared/DataTable/TableActionButton/TableActionButton';
import app from '../../../../../config/app/app';
import { hasPermission } from '../../../../../utils/permissions';

type AdBannerTableRowProps = {
  context: 'assignmentPreview' | 'adsAdministration' | 'houseAdsAssignment' | 'houseAdsPreview';
  banner: AdBanner;
  displayChance?: number;
  onRemoveAdBanner?: (banner: AdBanner) => void;
  onUpdateAdBanner?: (banner: AdBanner) => void;
  showStartDate?: boolean;
  categories?: AdCategory[];
  showEndDate?: boolean;
};

const AdBannerTableRow: FunctionComponent<AdBannerTableRowProps> = ({
  banner,
  categories,
  onUpdateAdBanner,
  onRemoveAdBanner,
  displayChance,
  context,
  showStartDate = true,
  showEndDate = true
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [activeBanner, setActiveBanner] = useState<AdBanner | null>(null);
  const role = useTypedSelector((state) => state.auth.role);

  const handleOpen = (banner: AdBanner) => {
    setActiveBanner(banner);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const noBorderStyle = {
    border: 0
  };

  const startDate = moment(banner.startDate);
  const endDate = moment(banner.endDate);

  const isAdsAdministration = context === 'adsAdministration';
  const isAssignmentPreview = context === 'assignmentPreview';
  const isHouseAdsPreview = context === 'houseAdsPreview';
  const isHouseAdsAssignment = context === 'houseAdsAssignment';

  return (
    <TableRow>
      {isAdsAdministration && <TableCell style={noBorderStyle} />}
      <TableCell style={noBorderStyle}>
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleOpen(banner)}>
          <img
            src={banner.imageURL}
            alt={banner.alt}
            style={{
              width: '50px',
              height: '50px',
              objectFit: 'contain',
              objectPosition: 'center',
              border: '1px solid #E5E5E5'
            }}
          />
          <div style={{ flexGrow: 1 }}>
            <Title>{banner.bannerName}</Title>
            {(isAssignmentPreview || isHouseAdsAssignment) && <SubtitleUrl>{banner.url}</SubtitleUrl>}
          </div>
          {isHouseAdsAssignment && (
            <div>
              <CategoryName>{categories?.find((cat) => cat.id === banner.categoryId)?.name}</CategoryName>
            </div>
          )}
        </div>
      </TableCell>
      {isAssignmentPreview && (
        <TableCell style={noBorderStyle}>
          {startDate.isValid() ? startDate.format(app.dateFormats.projectPublishDate) : 'N/A'} -{' '}
          {endDate.isValid() ? endDate.format(app.dateFormats.projectPublishDate) : <>&infin;</>}
        </TableCell>
      )}
      {(isAssignmentPreview || isHouseAdsPreview) && <TableCell style={noBorderStyle}>{displayChance}%</TableCell>}
      {isAdsAdministration && (
        <TableCell style={noBorderStyle}>
          <TableActionButtonsContainer>
            <TableActionButton
              icon={<EditIcon />}
              tooltip={t('pages.adminAds.table.buttons.editBanner')}
              ButtonProps={{
                disabled: !hasPermission(role, ['adsEdit']),
                onClick: () => {
                  if (onUpdateAdBanner) {
                    onUpdateAdBanner(banner);
                  }
                }
              }}
            />
            <TableActionButton
              icon={<DeleteIcon />}
              tooltip={t('pages.adminAds.table.buttons.deleteBanner')}
              ButtonProps={{
                disabled: !hasPermission(role, ['adsRemove']),
                onClick: () => {
                  if (onRemoveAdBanner) {
                    onRemoveAdBanner(banner);
                  }
                }
              }}
            />
          </TableActionButtonsContainer>
        </TableCell>
      )}
      {isAdsAdministration && <TableCell style={noBorderStyle}>{`${banner.width}x${banner.height}`}</TableCell>}
      {isAdsAdministration && showStartDate && (
        <TableCell style={noBorderStyle}>
          {startDate.isValid() ? startDate.format(app.dateFormats.projectPublishDate) : 'N/A'}
        </TableCell>
      )}
      {isAdsAdministration && showEndDate && (
        <TableCell style={noBorderStyle}>
          {endDate.isValid() ? endDate.format(app.dateFormats.projectPublishDate) : 'N/A'}
        </TableCell>
      )}
      {isAdsAdministration && <TableCell style={noBorderStyle}>{banner.url}</TableCell>}
      {isAdsAdministration && <TableCell style={noBorderStyle}>{formatTableTimestamp(banner.updated)}</TableCell>}

      {isHouseAdsAssignment && (
        <TableCell style={noBorderStyle}>
          <TableActionButtonsContainer>
            <TableActionButton
              icon={<DeleteIcon />}
              tooltip={t('pages.adminAds.table.buttons.deleteBanner')}
              ButtonProps={{
                disabled: false,
                onClick: () => {
                  if (onRemoveAdBanner) {
                    onRemoveAdBanner(banner);
                  }
                }
              }}
            />
          </TableActionButtonsContainer>
        </TableCell>
      )}
      <ImageModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div style={{ outline: 'none' }}>
            {activeBanner && (
              <img
                src={activeBanner.imageURL}
                alt={activeBanner.alt}
                style={{ width: activeBanner.width, height: activeBanner.height, maxWidth: '90vw', maxHeight: '90vh' }}
              />
            )}
          </div>
        </Fade>
      </ImageModal>
    </TableRow>
  );
};

const TextHolder = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-width: 12rem;
`;
export const Title = withTheme(styled(TextHolder)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  margin-left: 0.5rem;
`);
export const SubtitleUrl = withTheme(styled(TextHolder)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-left: 0.5rem;
  font-size: 0.8rem;
  font-weight: normal;
  width: 100%;
  display: block;
`);
const ImageModal = withTheme(styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`);
const CategoryName = styled.div`
  font-style: italic;
  font-size: 0.8rem;
`;

export default AdBannerTableRow;
