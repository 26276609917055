import React from 'react';
import { PageContentPaper } from '../../PageStyledComponents';
import Loader from '../../../Shared/Loading/Loader';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import CalendarEvent from '../Partials/CalendarEvent';
import { useTranslation } from 'react-i18next';
import { Story, StoryChannel, StoryChannelStatus, StoryEmailChannelPriority } from '../../../../store/Stories/types';
import { hasPermission } from '../../../../utils/permissions';
import { AdminRole } from '../../../../store/AdminRoles/types';
import { Range } from 'react-date-range';

type CalendarEventTabProps = {
  story: Story;
  calendarEvent: boolean;
  creatingCalendarEvent: boolean;
  calendarEventData: StoryChannel | null;
  handleCalendarEventChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  role: AdminRole;
  isOwnerOfStory: boolean;
  calendarEventPeriod: Range[];
  setCalendarEventPeriod: React.Dispatch<React.SetStateAction<Range[]>>;
  setChannelStatus: (
    channel: StoryChannel,
    status: StoryChannelStatus,
    muteToast: boolean,
    prevStatus?: StoryChannelStatus,
    priority?: StoryEmailChannelPriority
  ) => Promise<void>;
};

const CalendarEventTab: React.FC<CalendarEventTabProps> = ({
  story,
  calendarEvent,
  creatingCalendarEvent,
  calendarEventData,
  handleCalendarEventChange,
  role,
  isOwnerOfStory,
  calendarEventPeriod,
  setCalendarEventPeriod,
  setChannelStatus
}) => {
  const { t } = useTranslation();

  return (
    <PageContentPaper style={{ margin: 0, padding: '1rem 2rem' }}>
      {creatingCalendarEvent && <Loader />}
      {!calendarEvent && (
        <FormControlLabel
          control={<Checkbox checked={calendarEvent} onChange={handleCalendarEventChange} name="calendarEvent" />}
          label={t('pages.story.calendarEvent.calendarEvent')}
          disabled={!isOwnerOfStory || !hasPermission(role, ['storiesEdit'])}
        />
      )}
      {calendarEvent && calendarEventData && !creatingCalendarEvent && (
        <CalendarEvent
          isOwnerOfStory={isOwnerOfStory}
          storyId={story.id}
          calendarEvent={calendarEvent}
          calendarEventData={calendarEventData}
          calendarEventPeriod={calendarEventPeriod}
          handleCalendarEventChange={handleCalendarEventChange}
          setCalendarEventPeriod={setCalendarEventPeriod}
          setChannelStatus={setChannelStatus}
          defaultEventName={story.name}
        />
      )}
    </PageContentPaper>
  );
};

export default CalendarEventTab;
