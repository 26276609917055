import React from 'react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from '../../../types';
import { OrganizationImage } from '../../../../../../../store/EmailProjects/types';
import { Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface BrandfolderAssetProps {
  image: OrganizationImage;
  removeImage: (image: OrganizationImage) => void;
}

export const OrganizationUploadedAsset: React.FC<BrandfolderAssetProps> = ({ image, removeImage }) => {
  const { t } = useTranslation();
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.ORGANIZATION_UPLOADED_ASSET, id: image.id || '' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0.4 : 1;

  return (
    <ImageItemContainer ref={drag} style={{ opacity }}>
      <ImageItem src={image.thumbnailUrl} title={image.filename}>
        <Tooltip title={t('imagesPalette.removeImage') as string}>
          <RemoveIcon onClick={() => removeImage(image)}>
            <DeleteIcon />
          </RemoveIcon>
        </Tooltip>
      </ImageItem>
      <ImageItemName title={image.filename}>{image.filename}</ImageItemName>
    </ImageItemContainer>
  );
};

const ImageItemContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #f3f3f3;
  font-family: Rajdhani, sans-serif;
  width: 100px;
  cursor: pointer;
  text-align: center;
`;

const ImageItem = styled.div<{ src: string }>`
  width: 100px;
  height: 80px;
  background-image: url('${({ src }) => src}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
`;

const ImageItemName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px 3px;
  font-size: 12px;
`;

const RemoveIcon = styled.div`
  background: #f8fafc;
  border-radius: 0 0 0 10px;
  position: absolute;
  width: 20px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1px;
  opacity: 0.9;

  svg {
    width: 15px;
    color: #ef6666;
  }
`;
