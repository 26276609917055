import { TableCell, withTheme } from '@material-ui/core';
import styled from 'styled-components';
import { isTheme } from '../../utils/environment';
import { APP_THEME } from '../../typings/environment';
import { AnyProjectStatus } from '../../store/Dashboard/types';
import { EmailProjectStatus } from '../../store/EmailProjects/types';
import { PrintProjectStatus } from '../../store/PrintProjects/types';

export const TestProjectIndicator = styled.span`
  font-size: 0.8rem;
  font-style: italic;
  font-weight: bold;
`;

export const InputLabel = withTheme(styled.label`
  font-size: 1.2rem;
  margin: 1rem 0;
  display: block;
  color: ${(props) => props.theme.palette.primary.dark};
`);

export const Strong = styled.strong`
  font-weight: bold;
`;

export const Light = styled.span`
  font-weight: 300;
`;

export const Row = styled.div`
  margin: 1rem 0;
  line-height: 1.2rem;
`;

export const RowCenter = styled(Row)`
  text-align: center;
`;

export const ApplicationInfo = styled.div`
  font-size: 10px;
  opacity: 0.7;
  color: #fff;
  display: flex;
  ${isTheme(APP_THEME.NHM) || isTheme(APP_THEME.BTL)
    ? `flex-direction: column;
    align-items: flex-end;
    margin-right: 3em;`
    : `justify-content: space-between;`}
`;

export const ApplicationInfoSplash = styled(ApplicationInfo)`
  ${isTheme(APP_THEME.NHM) || isTheme(APP_THEME.BTL)
    ? ''
    : `position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;`}
`;

export const Version =
  isTheme(APP_THEME.NHM) || isTheme(APP_THEME.BTL)
    ? withTheme(styled.div`
        color: ${(props) => props.theme.palette.primary.dark};
        margin-top: 3em;
      `)
    : styled.div``;

export const PoweredBy = isTheme(APP_THEME.NHM)
  ? withTheme(styled.div`
      color: ${(props) => props.theme.palette.primary.dark};
      display: flex;

      img {
        padding: 0 0 0 5px;
      }
    `)
  : styled.div``;

export const OutlinedInfoBoxContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  line-height: 2rem;

  svg {
    margin-right: 1rem;
  }
`;

export const StyledTableCell = styled(TableCell)`
  word-break: normal;
`;

export const ComponentActionIcons = styled.div`
  position: absolute;
  left: calc(100% - 15px);
  top: calc(50% - 15px);
  z-index: 2;
  display: flex;
`;

export const ImageActionIcons = styled.div`
  position: absolute;
  left: calc(50% - 15px);
  top: calc(100% - 15px);
  z-index: 12;
  display: flex;
`;

export const StyledEditIcon = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #34a5aa;
  color: #fff;
  border-radius: 20px;
  display: none;
  align-items: center;
  justify-content: center;
`;

export const ProjectStatusCellWrapper = withTheme(styled.div<{ status: AnyProjectStatus; hasErrors: boolean }>`
  color: ${({ theme, status, hasErrors }) => {
    switch (status) {
      case EmailProjectStatus.IN_PROGRESS:
      case PrintProjectStatus.IN_PROGRESS: {
        if (hasErrors) {
          return theme.palette.error.main;
        }
        return theme.palette.warning.main;
      }
      case EmailProjectStatus.ERROR:
        return theme.palette.error.main;
      case PrintProjectStatus.SCHEDULED:
      case EmailProjectStatus.SCHEDULED:
        return theme.palette.primary.main;
      case PrintProjectStatus.DELIVERED:
      case EmailProjectStatus.PUBLISHED:
        return theme.palette.success.main;
    }
  }};

  svg {
    position: relative;
    top: -3px;
    vertical-align: middle;
  }
`);
