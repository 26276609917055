import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './en-US.json';
import { setLocale } from 'yup';
import _ from 'lodash';

let themeTranslations = {};
try {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  themeTranslations = require(`./${window.env.__APP_THEME__}/en-US.json`);
} catch (err) {
  console.warn(err);
}

const translations = _.merge(enTranslations, themeTranslations);

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translations
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })
  .then((t) => {
    setLocale({
      mixed: {
        required: t('formValidationErrors.genericRequired')
      },
      string: {
        email: t('formValidationErrors.email')
      }
    });
  });

export default i18n;
