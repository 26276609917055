import React from 'react';
import { StoryChannel } from '../../../../../../../store/Stories/types';
import { getCalendarEventData } from './getCalendarEventData';

type UpcomingEventElementProps = {
  event: StoryChannel;
};

const UpcomingEventElement: React.FC<UpcomingEventElementProps> = ({ event }) => {
  const eventData = getCalendarEventData(event);

  return (
    <div style={{ display: 'flex', borderBottom: '1px solid #ccc' }}>
      <div style={{ width: '20%', padding: '10px' }}>
        {eventData.isSingleDayEvent && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{ fontWeight: 'bold' }}>{eventData.startMonth}</div>
            <div style={{ fontWeight: 'bold', fontSize: '28px' }}>{eventData.startDay}</div>
          </div>
        )}
        {!eventData.isSingleDayEvent && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ fontWeight: 'bold' }}>{eventData.startMonth}</div>
              <div style={{ fontWeight: 'bold', fontSize: '28px' }}>{eventData.startDay}</div>
            </div>
            <div style={{ fontWeight: 'bold', fontSize: '20px', margin: '25px 5px 0' }}>-</div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ fontWeight: 'bold' }}>{eventData.endMonth}</div>
              <div style={{ fontWeight: 'bold', fontSize: '28px' }}>{eventData.endDay}</div>
            </div>
          </div>
        )}
      </div>
      <div style={{ width: '80%', display: 'flex', alignItems: 'center', padding: '10px 15px 10px 0' }}>
        {eventData.thumbnailUrl && (
          <div style={{ width: '100px', height: '70px', marginRight: '15px' }}>
            <img src={eventData.thumbnailUrl} alt="" style={{ objectFit: 'cover', width: '100px', height: '70px' }} />
          </div>
        )}
        <div>
          <strong>{eventData.name}</strong>
          <p>{eventData.description}</p>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEventElement;
