import React, { FunctionComponent } from 'react';
import AuthRoutesContainer from './AuthRoutesContainer';
import { AuthContainer, AuthContentContainer, SplashImageContainer } from '../../Pages/Auth/AuthStyledComponents';
import SplashArea from '../../Pages/Auth/Shared/SplashArea';
import authLayoutImage from '../../../assets/splash-image.png';
import { isTheme } from '../../../utils/environment';
import { APP_THEME } from '../../../typings/environment';

type AuthLayoutProps = {};

const AuthLayout: FunctionComponent<AuthLayoutProps> = () => {
  return (
    <AuthContainer>
      <SplashArea />
      {(isTheme(APP_THEME.NHM) || isTheme(APP_THEME.BTL)) && (
        <SplashImageContainer>
          <img src={authLayoutImage} alt="" style={{ width: '100%' }} />
        </SplashImageContainer>
      )}
      <AuthContentContainer>
        <AuthRoutesContainer />
      </AuthContentContainer>
    </AuthContainer>
  );
};

export default AuthLayout;
