import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';

export type AdminKeywordsState = StateWithPagination &
  StateWithTableSearch<AdminKeyword> & {
    keywords: NormalizedAdminKeywords;
    all: {
      keywords: AdminKeyword[];
      groups: AdminKeywordGroup[];
    };

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type AdminKeywordGroupsState = StateWithPagination &
  StateWithTableSearch<AdminKeywordGroup> & {
    groups: NormalizedAdminKeywordGroups;
    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedAdminKeywords = {
  entities: Dictionary<AdminKeyword>;
  result: string[];
};

export type NormalizedAdminKeywordGroups = {
  entities: Dictionary<AdminKeywordGroup>;
  result: string[];
};

export type AdminKeywordsIndexResponse = {
  data: AdminKeyword[];
  pagination: Pagination;
};

export type AdminKeywordGroupsIndexResponse = {
  data: AdminKeywordGroup[];
  pagination: Pagination;
};

export type AdminKeywordsIndexActionBEGIN = {
  type: AdminKeywordsActionsConstants.INDEX_BEGIN;
};

export type AdminKeywordGroupsIndexActionBEGIN = {
  type: AdminKeywordsActionsConstants.INDEX_GROUPS_BEGIN;
};

export type AdminKeywordsIndexActionSUCCESS = {
  type: AdminKeywordsActionsConstants.INDEX_SUCCESS;
  payload: AdminKeywordsIndexResponse;
};

export type AdminKeywordGroupsIndexActionSUCCESS = {
  type: AdminKeywordsActionsConstants.INDEX_GROUPS_SUCCESS;
  payload: AdminKeywordGroupsIndexResponse;
};

export type IndexAdminKeywordsRequestParams = {
  per: number;
  page: number;
  order?: string[];
  q: string | null;
};
export type AdminCreateKeywordValues = Pick<AdminKeyword, 'name'>;
export type AdminUpdateKeywordValues = Pick<AdminKeyword, 'name'>;
export type AdminCreateKeywordGroupValues = Pick<AdminKeywordGroup, 'name'>;
export type AdminUpdateKeywordGroupValues = Pick<AdminKeywordGroup, 'name'>;

export type AdminKeywordsIndexActionFAILED = {
  type: AdminKeywordsActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type AdminKeywordGroupsIndexActionFAILED = {
  type: AdminKeywordsActionsConstants.INDEX_GROUPS_FAILED;
  payload: {
    error: any;
  };
};

export type AdminKeyword = {
  id: string;
  name: string;
  uniqAdminKeyword: 'uniqAdminKeyword';
  createdAt: string;
  updatedAt: string;
};

export type AdminKeywordGroup = {
  id: string;
  name: string;
  keywords: AdminKeyword[];
  uniqAdminKeywordGroup: 'uniqAdminKeywordGroup';
  createdAt: string;
  updatedAt: string;
};

export type AdminGetAllKeywordsDataResponse = {
  keywords: AdminKeyword[];
  groups: AdminKeywordGroup[];
};

export type AdminKeywordsResponse = {
  data: AdminKeyword[];
};

export type AdminKeywordGroupsResponse = {
  data: AdminKeywordGroup[];
};

export type AdminGetAllTagsAction = {
  type: AdminKeywordsActionsConstants.GET_ALL_KEYWORDS;
  payload: AdminGetAllKeywordsDataResponse;
};

export type AdminKeywordGroupKeywordAdded = {
  type: AdminKeywordsActionsConstants.KEYWORD_ADDED;
  payload: AdminKeywordGroup;
};

export type AdminKeywordGroupKeywordRemoved = {
  type: AdminKeywordsActionsConstants.KEYWORD_REMOVED;
  payload: AdminKeywordGroup;
};

export enum AdminKeywordsActionsConstants {
  KEYWORD_ADDED = 'ADMIN_KEYWORDS/KEYWORD_ADDED_TO_GROUP',
  KEYWORD_REMOVED = 'ADMIN_KEYWORDS/KEYWORD_REMOVED_FROM_GROUP',
  GET_ALL_KEYWORDS = 'ADMIN_KEYWORDS/GET_ALL_KEYWORDS',
  INDEX_BEGIN = 'ADMIN_KEYWORDS/INDEX/BEGIN',
  INDEX_SUCCESS = 'ADMIN_KEYWORDS/INDEX/SUCCESS',
  INDEX_FAILED = 'ADMIN_KEYWORDS/INDEX/FAILED',
  INDEX_GROUPS_BEGIN = 'ADMIN_KEYWORDS/INDEX_GROUPS/BEGIN',
  INDEX_GROUPS_SUCCESS = 'ADMIN_KEYWORDS/INDEX_GROUPS/SUCCESS',
  INDEX_GROUPS_FAILED = 'ADMIN_KEYWORDS/INDEX_GROUPS/FAILED'
}

export type AdminKeywordsActionType =
  | AdminGetAllTagsAction
  | AdminKeywordsIndexActionBEGIN
  | AdminKeywordsIndexActionSUCCESS
  | AdminKeywordsIndexActionFAILED;

export type AdminKeywordGroupsActionType =
  | AdminKeywordGroupKeywordAdded
  | AdminKeywordGroupKeywordRemoved
  | AdminKeywordGroupsIndexActionBEGIN
  | AdminKeywordGroupsIndexActionSUCCESS
  | AdminKeywordGroupsIndexActionFAILED;
