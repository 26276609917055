import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, GridList, GridListTile, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { emailProjectsOperations } from '../../../../../../../store/EmailProjects';
import { EmailProject, OrganizationImage } from '../../../../../../../store/EmailProjects/types';
import { Alert, Pagination } from '@material-ui/lab';
import Loader from '../../../../../../Shared/Loading/Loader';
import { useTranslation } from 'react-i18next';
import app from '../../../../../../../config/app/app';
import Toast from '../../../../../../Shared/Toast/Toast';
import { OrganizationUploadedAsset } from '../Helpers/OrganizationUploadedAsset';
import ClearIcon from '@material-ui/icons/Clear';

type UploadedImagesProps = {
  project: EmailProject | null;
};

const UploadedImages: FunctionComponent<UploadedImagesProps> = ({ project }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [dragOver, setDragOver] = useState<boolean>(false);
  const [images, setImages] = useState<OrganizationImage[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 12, total: 0, totalPages: 0, q: null });
  const [searchValue, setSearchValue] = useState<string>('');

  const fetchData = async (page: number, search: string) => {
    if (project) {
      setLoading(true);
      try {
        const data = await emailProjectsOperations.getOrganizationImages(project.id, 12, page, search);
        setImages(data.data);
        setPagination(data.pagination);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData(1, '');
  }, []);

  if (!project) {
    return null;
  }

  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
    fetchData(page, searchValue);
  };

  const handleSearch = (event: React.SyntheticEvent) => {
    event.preventDefault();
    fetchData(1, searchValue);
  };

  const handleSearchClear = () => {
    setSearchValue('');
    fetchData(1, '');
  };

  const removeImage = async (image: OrganizationImage) => {
    await emailProjectsOperations.removeImage(project.id, image.id);
    await fetchData(pagination.page, searchValue);
  };

  const CenteredLoader = () => (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );

  const containsFiles = (e: any) => {
    if (e && e.dataTransfer && e.dataTransfer.types) {
      for (let i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] == 'Files') {
          return true;
        }
      }
    }

    return false;
  };

  const onFileDrop = async (e: any) => {
    try {
      setProgress(0);
      if (containsFiles(e)) {
        let file: File | null = null;
        if (e && e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files[0]) {
          file = e.dataTransfer.files[0];
        }
        if (file) {
          const extension = file.name.split('.').pop();

          if (!app.validLogoExtensions.includes((extension || '').toLowerCase())) {
            Toast.error(t('notifications.uploadEmailFile.invalidExtension'));
            setUploading(false);
            setDragOver(false);
            return;
          }

          if (file.size > app.maxLogoSize) {
            Toast.error(t('notifications.uploadEmailFile.fileTooBig'));
            setUploading(false);
            setDragOver(false);
            return;
          }

          setUploading(true);
          await emailProjectsOperations.uploadImage(file, project.id, (progress) => {
            setProgress(progress);
          });
          setUploading(false);
          await fetchData(pagination.page, searchValue);
        }
      }
    } catch (e) {
      setUploading(false);
      console.warn('Error occurred while uploading file', e);
    }

    setDragOver(false);
  };

  const AssetsContent = () => {
    if (loading) {
      return <>{loading && <CenteredLoader />}</>;
    }

    return (
      <>
        {loading && <CenteredLoader />}
        {!loading && (
          <>
            <GridList cellHeight={105} cols={3} spacing={10} style={{ padding: '10px', width: '400px' }}>
              {images.map((image) => (
                <GridListTile key={image.id}>
                  <OrganizationUploadedAsset image={image} removeImage={removeImage} />
                </GridListTile>
              ))}
            </GridList>
            {images.length === 0 && (
              <Alert severity="info" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                {t('imagesPalette.noAssetsFound')}
              </Alert>
            )}
          </>
        )}
        {pagination.total > 0 && (
          <Pagination
            count={pagination.totalPages}
            page={pagination.page}
            shape="rounded"
            onChange={handleChange}
            size="small"
            style={{ marginTop: 'auto' }}
          />
        )}
      </>
    );
  };

  return (
    <div
      style={{ position: 'relative', flexGrow: 1, display: 'flex' }}
      onDragEnter={(e) => {
        if (containsFiles(e)) {
          setDragOver(true);
        }
      }}
    >
      <Box display="flex" alignItems="center" flexDirection="column">
        <SearchBarRow>
          <form onSubmit={handleSearch} style={{ width: '100%', marginTop: '10px', marginRight: '10px' }}>
            <TextField
              placeholder={t('brandfolder.search')}
              type="text"
              variant="outlined"
              size="small"
              value={searchValue}
              fullWidth
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                endAdornment: searchValue !== '' && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleSearchClear}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </form>
        </SearchBarRow>
        <AssetsContent />
        {(dragOver || uploading) && (
          <DropHereInfo onDrop={(e) => onFileDrop(e)} onDragLeave={() => setDragOver(false)}>
            {uploading
              ? progress === 100
                ? t('imagesPalette.processing')
                : `${t('imagesPalette.uploading')} (${progress}%)`
              : t('imagesPalette.dropHere')}
          </DropHereInfo>
        )}
      </Box>
    </div>
  );
};

const DropHereInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 1.5rem;
  font-style: italic;
  color: #fff;
  border-radius: 4px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  height: 20rem;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const SearchBarRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 370px;
`;

export default UploadedImages;
