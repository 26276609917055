import { AuthActionsConstants, AuthActionType, AuthState } from './types';
import update from 'immutability-helper';
import { WithLogoutAction } from '../../typings';
import { getDefaultPermissions } from '../../utils/permissions';

const initialState: AuthState = {
  user: {
    firstName: '',
    lastName: '',
    verified: false,
    updatedAt: new Date(),
    createdAt: new Date(),
    passwordUpdatedAt: new Date(),
    email: '',
    keywords: [],
    id: '',
    type: {
      id: '',
      name: '',
      updatedAt: new Date(),
      createdAt: new Date()
    },
    brandfolderCollection: null
  },
  webAppUrl: '',
  passwordPolicy: null,
  role: {
    id: '',
    name: '',
    description: '',
    isDefault: false,
    userTypeId: '',
    userType: {
      id: '',
      name: '',
      landingPage: '',
      createdAt: '',
      updatedAt: ''
    },
    createdAt: '',
    updatedAt: '',
    keywords: [],
    permissions: getDefaultPermissions()
  },
  organizations: [],
  socialAccounts: [],
  systemSettings: [],
  organization: null,
  organizationId: null,
  version: '...',
  designerUrl: '',
  designerAt: '',
  designerBackendUrl: '',
  mainStorageUrl: '',
  token: '',
  authenticated: false,
  chatId: '',
  relativeNucleusPath: '',
  colorGuidePdfUrl: '',
  colors: []
};

export default function authReducer(state = initialState, action: WithLogoutAction<AuthActionType>) {
  switch (action.type) {
    case AuthActionsConstants.LOGIN:
    case AuthActionsConstants.VALIDATE_TOKEN: {
      const { user, role } = action.payload;

      /** Set boolean flags to determine if user has access to type of project.
       * Needed for UserGuiding segmentation.
       */
      const { bulletin, newsletter, flyer, directory, email } = action.payload.organization || {};
      window.ePubHasBulletin = !!bulletin;
      window.ePubHasEmailNewsletter = !!email;
      window.ePubHasFlyer = !!flyer;
      window.ePubHasNewsletter = !!newsletter;
      window.ePubHasDirectory = !!directory;

      return update(state, {
        user: { $set: user },
        role: { $set: role },
        version: { $set: action.payload.version },
        designerUrl: { $set: action.payload.designerUrl },
        designerAt: { $set: action.payload.designerAt },
        designerBackendUrl: { $set: action.payload.designerBackendUrl },
        token: { $set: action.payload.token },
        organizationId: { $set: action.payload.organizationId },
        passwordPolicy: { $set: action.payload.passwordPolicy },
        organizations: { $set: action.payload.organizations },
        systemSettings: { $set: action.payload.systemSettings },
        webAppUrl: { $set: action.payload.webAppUrl },
        mainStorageUrl: { $set: action.payload.mainStorageUrl },
        organization: {
          $set: action.payload.organization || null
        },
        authenticated: { $set: true },
        chatId: { $set: action.payload.chatId },
        relativeNucleusPath: { $set: action.payload.relativeNucleusPath },
        colorGuidePdfUrl: { $set: action.payload.colorGuidePdfUrl },
        colors: { $set: action.payload.colors }
      });
    }
    case AuthActionsConstants.LOGOUT: {
      window.ePubHasBulletin = false;
      window.ePubHasEmailNewsletter = false;
      window.ePubHasFlyer = false;
      window.ePubHasNewsletter = false;
      window.ePubHasDirectory = false;

      return initialState;
    }
    case AuthActionsConstants.UPDATE_ACCOUNT: {
      return update(state, {
        user: { $set: action.payload }
      });
    }
    case AuthActionsConstants.UPDATE_COLORS: {
      return update(state, {
        colors: { $set: action.payload }
      });
    }
    case AuthActionsConstants.GET_ORGANIZATION_SOCIAL_ACCOUNT: {
      return update(state, {
        socialAccounts: { $set: action.payload }
      });
    }
    default:
      return state;
  }
}
