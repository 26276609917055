import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { AdminOrganization } from '../AdminOrganizations/types';
import { ShortUser } from '../Auth/types';
import { Story, StoryChannelAttachment } from '../Stories/types';

export type DesignProjectsState = StateWithPagination &
  StateWithTableSearch<DesignProject> & {
    list: NormalizedDesignProjects;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };

    printDesignThumbnailGenerated: DesignProject | null;
  };

export type DesignProject = {
  id: string;
  name: string;
  templateId: string;
  templateDescription: string;
  createdAt: Date;
  createdBy?: ShortUser;
  externalId: number;
  hash: string;
  designProjectUnique: 'designProjectUnique';
  thumbnailUrl: string;
  previewUrl: string;
  organization?: AdminOrganization;
  organizationId: string;
  storyChannelAttachments: StoryChannelAttachment[];
  stories: Story[];
};

export type NormalizedDesignProjects = {
  entities: Dictionary<DesignProject>;
  result: string[];
};

export type DesignProjectsIndexResponse = {
  data: DesignProject[];
  pagination: Pagination;
};

export type DesignProjectsIndexActionBEGIN = {
  type: DesignProjectsActionsConstants.INDEX_BEGIN;
};

export type DesignProjectsIndexActionSUCCESS = {
  type: DesignProjectsActionsConstants.INDEX_SUCCESS;
  payload: DesignProjectsIndexResponse;
};

export type DesignProjectsIndexActionFAILED = {
  type: DesignProjectsActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type IndexDesignProjectsRequestParams = {
  per: number;
  page: number;
  q: string | null;
  order?: string[];
};

export type DesignProjectsResponse = {
  data: DesignProject[];
};

export type PrintDesignThumbnailGenerated = {
  type: DesignProjectsActionsConstants.PRINT_DESIGN_THUMBNAIL_GENERATED;
  payload: DesignProject;
};

export enum DesignProjectsActionsConstants {
  INDEX_BEGIN = 'DESIGN_PROJECTS/INDEX/BEGIN',
  INDEX_SUCCESS = 'DESIGN_PROJECTS/INDEX/SUCCESS',
  INDEX_FAILED = 'DESIGN_PROJECTS/INDEX/FAILED',
  PRINT_DESIGN_THUMBNAIL_GENERATED = 'PROJECTS/STORY/PRINT_DESIGN_THUMBNAIL_GENERATED'
}

export type DesignProjectsActionType =
  | DesignProjectsIndexActionBEGIN
  | DesignProjectsIndexActionSUCCESS
  | DesignProjectsIndexActionFAILED
  | PrintDesignThumbnailGenerated;
