import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoResultsFound, PageContainer, PageContent } from '../../PageStyledComponents';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { useDispatch } from 'react-redux';
import { adminAdsOperations } from '../../../../store/AdminAds';
import { AdBanner, AdCategory, Advertiser, AdZone, HouseAdsBannersCategory } from '../../../../store/AdminAds/types';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Template } from '../../../../store/Templates/types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paper, TextField } from '@material-ui/core';
import Loader from '../../../Shared/Loading/Loader';
import { RouteComponentProps } from 'react-router';
import HouseAdToEmailTemplateAssignment from './Partials/HouseAds/HouseAdToEmailTemplateAssignment';
import styled from 'styled-components';
import { AdminEmailTemplate } from '../../../../store/AdminEmailTemplates/types';
import { adminEmailTemplatesOperations } from '../../../../store/AdminEmailTemplates';

type AdminHouseAdsToTemplateAssignmentPageProps = RouteComponentProps<{ emailTemplateId: string }> & {};

const AdminHouseAdsToTemplateAssignmentPage: FunctionComponent<AdminHouseAdsToTemplateAssignmentPageProps> = ({
  match
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [advertiser, setAdvertiser] = useState<Advertiser | null>(null);
  const [banners, setBanners] = useState<AdBanner[]>([]);
  const [emailTemplates, setEmailTemplates] = useState<AdminEmailTemplate[]>([]);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<AdminEmailTemplate | null>(null);
  const [houseAdsCategories, setHouseAdsCategories] = useState<AdCategory[]>([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const categories = await adminAdsOperations.indexHouseAdsCategories();
      setHouseAdsCategories(categories.data);

      if (!emailTemplates.length) {
        const bannersResponse = await adminAdsOperations.indexHouseAds();
        setBanners(bannersResponse.data);
        setEmailTemplates(bannersResponse.emailTemplates);
        setAdvertiser(bannersResponse.advertiser);
      }

      const emailTemplate = await adminEmailTemplatesOperations.show(match.params.emailTemplateId);
      setSelectedEmailTemplate(emailTemplate);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [match.params.emailTemplateId]);

  const backButton = {
    onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ADS.INDEX)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const emailTemplateSelector = (
    <EmailTemplateSelector>
      <div>{t('pages.adminAds.emailTemplate')}:</div>
      <div>
        <Autocomplete
          onChange={(event, value) => {
            if (value?.id) {
              const emailTemplate = emailTemplates.find((temp) => temp.id === value.id);
              if (emailTemplate) {
                dispatch(push(linksConstants.ADMINISTRATION.ADS.ASSIGN_ADS(value.id)));
                setSelectedEmailTemplate(emailTemplate);
              }
            }
          }}
          value={{ id: match.params.emailTemplateId, label: selectedEmailTemplate?.name || '' }}
          size="small"
          getOptionSelected={(option, value) => {
            return option.id === value.id;
          }}
          renderInput={(params) => <TextField {...params} autoComplete="off" variant="outlined" />}
          options={emailTemplates.map((template) => ({ label: template.name, id: template.id }))}
          getOptionLabel={(option) => option.label}
        />
      </div>
    </EmailTemplateSelector>
  );

  return (
    <PageContainer>
      <PageHeader leftActionButtons={[backButton]} title={t('pages.adminAds.titleHouseAssignment')} />
      <PageContent>
        {loading && <Loader />}
        {!loading && emailTemplates.length <= 0 && (
          <NoResultsFound>{t('pages.adminAds.noEmailTemplates')}</NoResultsFound>
        )}
        {!loading && selectedEmailTemplate && advertiser && (
          <HouseAdToEmailTemplateAssignment
            key={selectedEmailTemplate.id}
            emailTemplate={selectedEmailTemplate}
            categories={houseAdsCategories}
            allBanners={banners}
            advertiser={advertiser}
            emailTemplateSelector={emailTemplateSelector}
          />
        )}
      </PageContent>
    </PageContainer>
  );
};

const EmailTemplateSelector = styled(Paper)`
  padding: 1rem;
  display: flex;
  width: 100%;
  align-items: center;

  > div:first-child {
    margin-right: 10px;
  }

  > div:not(:first-child) {
    flex-grow: 1;
  }
`;

export default AdminHouseAdsToTemplateAssignmentPage;
