import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { Row } from '../../../../Shared/StyledComponents';
import { PageContentPaper, PageContentPaperHeading } from '../../../PageStyledComponents';
import SaveIcon from '@material-ui/icons/Save';
import {
  DailyOrganizationEmailActivityReporData,
  DailyOrganizationEmailActivityReportResponse
} from '../../../../../store/AdminReports/types';
import { adminReportsOperations } from '../../../../../store/AdminReports';
import { extent, scaleBand, scaleLinear } from 'd3';
import './Chart/Chart.scss';
import { AxisLeft } from './Chart/AxisLeft';
import { Legend } from './Chart/Legend';
import { LegendElement } from './Chart/types';
import { Text } from '@visx/text';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import AdminReportsGenerateCsvWindow from '../Windows/AdminReportsGenerateCsvWindow';
import { LineChart } from './Chart/LineChart';
import { AxisRight } from './Chart/AxisRight';

type DailyOrganizationEmailActivityReportProps = {};

const DailyOrganizationEmailActivityReport: FunctionComponent<DailyOrganizationEmailActivityReportProps> = () => {
  const { t } = useTranslation();
  const [reportData, setReportData] = useState<DailyOrganizationEmailActivityReportResponse['data']>([]);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [csvDownloadWindowOpen, onCsvDownloadWindowOpen, onCsvDownloadWindowClose] = useOpenHandler();

  const width = 960;
  const height = 500;
  const margin = { top: 40, right: 60, bottom: 120, left: 50 };

  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;
  const xAxisLabelOffset = 80;
  const yAxisLeftLabelOffset = 30;
  const yAxisRightLabelOffset = 55;

  const organizationsColor = '#e3ba22';
  const campaignsColor = '#42a5b3';
  const opensColor = '#388e3c';

  useEffect(() => {
    const fetchData = async () => {
      setError(false);
      try {
        const response = await adminReportsOperations.dailyOrganizationEmailActivity('json');

        if (response) {
          setReportData(response.data);
        }
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    };

    fetchData();
  }, []);

  if (loading && !reportData) {
    return <>Loading...</>;
  }

  const xAxisLabel = 'Day (Total Organizations)';
  const yAxisLeftLabel = 'Campaigns';
  const yAxisRightLabel = 'Emails unique opens';

  const xValue = (d: DailyOrganizationEmailActivityReporData) => d.date;
  const yBarValue = (d: DailyOrganizationEmailActivityReporData) => d.organizations;
  const yBar2Value = (d: DailyOrganizationEmailActivityReporData) => d.campaigns;
  const yLineValue = (d: DailyOrganizationEmailActivityReporData) => d.uniqueOpens;

  let maxYValue = 0;
  for (const key in reportData) {
    maxYValue = Math.max(maxYValue, reportData[key].organizations, reportData[key].campaigns);
  }
  const yRightExtent = extent(reportData, yLineValue);

  const xScale = scaleBand().domain(reportData.map(xValue)).range([0, innerWidth]).padding(0.5);

  const yLeftScale = scaleLinear()
    .domain([0, Math.ceil(maxYValue / 5) * 5])
    .range([innerHeight, 0])
    .nice();
  const yRightScale = scaleLinear()
    .domain([0, Math.ceil((yRightExtent[1] ?? 0) / 5) * 5])
    .range([innerHeight, 0])
    .nice();

  const legendData: LegendElement[] = [
    { type: 'rect', text: 'Organizations that sent campaign', color: organizationsColor, marginLeft: 160 },
    { type: 'rect', text: 'Campaigns sent', color: campaignsColor, marginLeft: 410 },
    { type: 'line', text: 'Emails unique opens', color: opensColor, marginLeft: 560 }
  ];

  return (
    <PageContentPaper>
      <PageContentPaperHeading>
        <Typography variant="h6" gutterBottom>
          {t('pages.adminReports.dailyOrganizationEmailActivity.title')}
        </Typography>
        <Button color={'secondary'} variant={'contained'} size="medium" onClick={onCsvDownloadWindowOpen}>
          <SaveIcon /> {t('pages.adminReports.generateCsvReport')}
        </Button>
      </PageContentPaperHeading>
      <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
      {error && <div>Something went wrong...</div>}
      {!error && (
        <Row style={{ maxWidth: 800 }}>
          {!loading && (
            <svg viewBox={`0 0 ${width} ${height}`}>
              <g transform={`translate(${margin.left},${margin.top})`}>
                <Legend legendData={legendData} positionLeft={60} positionTop={-30} />
                {xScale.domain().map((tickValue, i) => (
                  <g className="tick" key={tickValue} transform={`translate(${xScale(tickValue)},0)`}>
                    <line y2={innerHeight} />
                    <Text textAnchor="middle" verticalAnchor="start" dy=".71em" y={innerHeight + 7} width={80}>
                      {`${tickValue} (${reportData[i].totalOrganizations})`}
                    </Text>
                  </g>
                ))}
                <text
                  className="axis-label"
                  textAnchor="middle"
                  transform={`translate(${-yAxisLeftLabelOffset},${innerHeight / 2}) rotate(-90)`}
                >
                  {yAxisLeftLabel}
                </text>
                <text
                  className="axis-label"
                  textAnchor="middle"
                  transform={`translate(${innerWidth + yAxisRightLabelOffset},${innerHeight / 2}) rotate(-90)`}
                >
                  {yAxisRightLabel}
                </text>
                <AxisLeft yScale={yLeftScale} innerWidth={innerWidth} tickOffset={7} />
                <AxisRight yScale={yRightScale} innerWidth={innerWidth} tickOffset={7} />
                <text className="axis-label" x={innerWidth / 2} y={innerHeight + xAxisLabelOffset} textAnchor="middle">
                  {xAxisLabel}
                </text>
                <g className="marks">
                  {reportData.map((d, i) => (
                    <rect
                      key={i}
                      fill={organizationsColor}
                      x={(xScale(xValue(d)) || 0) - xScale.bandwidth() / 2}
                      y={yLeftScale(yBarValue(d))}
                      height={yLeftScale(0) - yLeftScale(yBarValue(d))}
                      width={xScale.bandwidth() / 2}
                    >
                      <title>{yBarValue(d)}</title>
                    </rect>
                  ))}
                  {reportData.map((d, i) => (
                    <rect
                      key={i}
                      fill={campaignsColor}
                      x={xScale(xValue(d)) || 0}
                      y={yLeftScale(yBar2Value(d))}
                      height={yLeftScale(0) - yLeftScale(yBar2Value(d))}
                      width={xScale.bandwidth() / 2}
                    >
                      <title>{yBar2Value(d)}</title>
                    </rect>
                  ))}
                  <LineChart
                    data={reportData}
                    xValue={xValue}
                    yLineValue={yLineValue}
                    xScale={xScale}
                    yScale={yRightScale}
                    circleRadius={3}
                    color={opensColor}
                  />
                </g>
              </g>
            </svg>
          )}
        </Row>
      )}
      <AdminReportsGenerateCsvWindow
        open={csvDownloadWindowOpen}
        onCloseClick={onCsvDownloadWindowClose}
        onSubmit={async (range) => await adminReportsOperations.dailyOrganizationEmailActivity('csv', range)}
      />
    </PageContentPaper>
  );
};

export default DailyOrganizationEmailActivityReport;
