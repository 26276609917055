import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../../Shared/StyledComponents';
import { DoAppRunningPromotionReport } from '../../../../../store/DoApp/Reports/types';
import { doAppReportsOperations } from '../../../../../store/DoApp/Reports';
import Toast from '../../../../Shared/Toast/Toast';

type DoAppDeleteReportWindowProps = {
  open: boolean;
  report: DoAppRunningPromotionReport | null;
  onCloseClick: () => void;
  onSubmit: () => void;
};

const DoAppDeleteReportWindow: FunctionComponent<DoAppDeleteReportWindowProps> = ({
  open,
  onCloseClick,
  report,
  onSubmit
}) => {
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.doApp.runningPromotionReports.deleteReportWindow.header')}
      onCloseClick={onCloseClick}
      okButtonText={t('common.remove')}
      onSubmit={async () => {
        if (report) {
          try {
            await doAppReportsOperations.removeReportWithEntries(report.id);
            onCloseClick();
            onSubmit();
            Toast.success(t('notifications.common.successOperation'));
          } catch (e) {
            Toast.error(t('notifications.common.errorOperation'));
          }
        }
      }}
    >
      <Row>{t('pages.doApp.runningPromotionReports.deleteReportWindow.content')}</Row>
    </ModalConfirmationWindow>
  );
};

export default DoAppDeleteReportWindow;
