import React, { FunctionComponent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Toast from '../../../Shared/Toast/Toast';
import app from '../../../../config/app/app';
import { MenuItem } from '@material-ui/core';
import { MyOrganizationUser, UpdateMyOrganizationUserValues } from '../../../../store/MyOrganization/types';
import { AdminRole, DefaultRoleName } from '../../../../store/AdminRoles/types';
import { adminRolesOperations } from '../../../../store/AdminRoles';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import { AdminCheckboxInput, AdminSelectInput, AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import ModalFormWindow from '../../../Shared/Window/ModalFormWindow';
import { FormikProps } from 'formik';
import { adminUsersOperations } from '../../../../store/AdminUsers';
import { getRoleNameElement } from '../../../../utils';
import { AdminUpdateOrganizationUserValues } from '../../../../store/AdminOrganizationUsers/types';

type UpdateMyOrganizationUserWindowProps = {
  open: boolean;
  organizationId: string;
  disableSetAsOwnerCheckbox: boolean;
  organizationUser: MyOrganizationUser | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const UpdateMyOrganizationUserWindow: FunctionComponent<UpdateMyOrganizationUserWindowProps> = ({
  organizationUser,
  organizationId,
  onCloseClick,
  open,
  disableSetAsOwnerCheckbox
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [roles, setRoles] = useState<AdminRole[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      if (organizationUser) {
        await fetchRolesData(organizationUser.isOwner ? 'owner' : 'contact');
      }
    };

    if (open) {
      fetchRoles();
    }
  }, [open]);

  const fetchRolesData = async (type: 'owner' | 'contact') => {
    const typeToSearch = type === 'contact' ? DefaultRoleName.ORGANIZATION_CONTACT : DefaultRoleName.ORGANIZATION_OWNER;
    const userTypes = await adminUsersOperations.getUserTypes();
    const contactUserType = userTypes.data.find((type) => type.name === typeToSearch);
    if (contactUserType) {
      const rolesData = await adminRolesOperations.getRolesOfUserType(contactUserType.id);

      setRoles(rolesData.data);
    }
  };

  const formInputs = (props: FormikProps<AdminUpdateOrganizationUserValues>) => (
    <>
      <AdminTextInput t={t} name="email" section="myOrganization" disabled />
      <AdminCheckboxInput
        t={t}
        disabled={disableSetAsOwnerCheckbox}
        name="isOwner"
        onChange={(e) => {
          props.setFieldValue('roleId', '');
          e.target.checked ? fetchRolesData('owner') : fetchRolesData('contact');
          return true;
        }}
        section="myOrganization"
      />
      <AdminSelectInput t={t} name="roleId" section="myOrganization">
        {roles.map((role) => (
          <MenuItem key={role.id} value={role.id}>
            {getRoleNameElement(t, role)}
          </MenuItem>
        ))}
      </AdminSelectInput>
    </>
  );

  const onSubmit = async (values: UpdateMyOrganizationUserValues) => {
    try {
      if (organizationUser) {
        await dispatch(
          myOrganizationOperations.update(organizationId, organizationUser.id, {
            roleId: values.roleId,
            isOwner: values.isOwner
          })
        );
        Toast.success(t('notifications.myOrganization.successOperation'));

        await dispatch(myOrganizationOperations.index(organizationId));

        onCloseClick();
      }
    } catch (e) {
      Toast.error(t('notifications.myOrganization.errorOperation'));
    }
  };

  if (!organizationUser) return null;

  return (
    <ModalFormWindow
      open={open}
      header={t('pages.myOrganization.windows.updateOrganizationUser')}
      okButtonText={t('common.update')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        email: organizationUser.email,
        roleId: organizationUser.roleId,
        isOwner: organizationUser.isOwner
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().max(app.maxInputLength).email().required(),
        isOwner: Yup.boolean().required(),
        roleId: Yup.string().required()
      })}
    />
  );
};

export default UpdateMyOrganizationUserWindow;
