import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { CreateCampaignValues, IndexCampaignsRequestParams, UpdateCampaignValues } from './types';

export const index = (settings: RequestSettings<IndexCampaignsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/campaign?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{}, CreateCampaignValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/campaign'
  },
  payload: settings.requestPayload
});

export const show = (settings: RequestSettings<{ campaignId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/campaign/:campaignId'
  },
  params: settings.requestParams
});

export const update = (settings: RequestSettings<{}, UpdateCampaignValues>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/campaign/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteCampaign = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/campaign/:id'
  },
  params: settings.requestParams
});

export const assign = (settings: RequestSettings<{ id: string }, { itemId: string; type: string }>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/campaign/:id/assign'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const unassign = (
  settings: RequestSettings<{ id: string }, { itemId: string; type: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/campaign/:id/unassign'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});
