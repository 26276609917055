import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { IndexAdminOnlineUsersRequestParams } from './types';

export const index = (settings: RequestSettings<IndexAdminOnlineUsersRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/online_users?per=:per&page=:page`
  },
  params: settings.requestParams
});
