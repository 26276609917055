import update from 'immutability-helper';
import {
  AdminOnlineUser,
  AdminOnlineUsersActionsConstants,
  AdminOnlineUsersActionType,
  AdminOnlineUsersState
} from './types';
import { normalizeOnlineUsers } from '../helpers';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: AdminOnlineUsersState = {
  users: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const adminOnlineUsersPaginationReducer = createPaginationReducer<AdminOnlineUsersState, AdminOnlineUser>(
  PaginationStoreModule.ADMIN_ONLINE_USERS
);

const adminOnlineUsersTableSearchReducer = createTableSearchReducer<AdminOnlineUser, AdminOnlineUsersState>(
  TableSearchStoreModule.ADMIN_ONLINE_USERS
);

export default function adminOnlineUsersReducer(
  state = initialState,
  action: WithLogoutAction<AdminOnlineUsersActionType>
): AdminOnlineUsersState {
  switch (action.type) {
    case AdminOnlineUsersActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case AdminOnlineUsersActionsConstants.INDEX_SUCCESS: {
      const data = normalizeOnlineUsers(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        users: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case AdminOnlineUsersActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        users: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return adminOnlineUsersTableSearchReducer(
        adminOnlineUsersPaginationReducer(state, action),
        action
      ) as AdminOnlineUsersState;
    }
  }
}
