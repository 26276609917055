import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type PrintPublishNotSendingAnEmailProps = {
  setNextButtonDisabled: (state: boolean) => void;
};

const PrintPublishNotSendingAnEmail: FunctionComponent<PrintPublishNotSendingAnEmailProps> = ({
  setNextButtonDisabled
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setNextButtonDisabled(false);
  }, []);

  return (
    <div>
      <Line bold>{t('pages.projectPublish.cancelAutomatedEmailText1')}</Line>
    </div>
  );
};

const Line = styled.p<{ bold?: boolean }>`
  margin: 2em 1em;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

export default PrintPublishNotSendingAnEmail;
