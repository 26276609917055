import React, { FunctionComponent } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { TemplateTypeName } from '../../../../../store/Templates/types';
import { FilterHeader } from '../../AdminStyledComponents';
import { AdminProjectsFilters } from '../../../../../store/AdminProjects/types';
import { useTranslation } from 'react-i18next';

type PublicationTypeFiltersProps = {
  filters: AdminProjectsFilters['publicationType'];
  onChange: (type: TemplateTypeName | 'orphan') => void;
};

const PublicationTypeFilters: FunctionComponent<PublicationTypeFiltersProps> = ({ filters, onChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <FilterHeader>{t('pages.adminProjects.filters.publicationType')}</FilterHeader>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="bulletins"
              onChange={() => onChange(TemplateTypeName.BULLETIN)}
              checked={filters.includes(TemplateTypeName.BULLETIN)}
            />
          }
          label={t('pages.dashboard.projectType.bulletin')}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="newsletters"
              onChange={() => onChange(TemplateTypeName.NEWSLETTER)}
              checked={filters.includes(TemplateTypeName.NEWSLETTER)}
            />
          }
          label={t('pages.dashboard.projectType.newsletter')}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="flyer"
              onChange={() => onChange(TemplateTypeName.FLYER)}
              checked={filters.includes(TemplateTypeName.FLYER)}
            />
          }
          label={t('pages.dashboard.projectType.flyer')}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="directory"
              onChange={() => onChange(TemplateTypeName.DIRECTORY)}
              checked={filters.includes(TemplateTypeName.DIRECTORY)}
            />
          }
          label={t('pages.dashboard.projectType.directory')}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="emails"
              onChange={() => onChange(TemplateTypeName.EMAIL)}
              checked={filters.includes(TemplateTypeName.EMAIL)}
            />
          }
          label={t('pages.dashboard.projectType.email')}
        />
        <FormControlLabel
          control={<Checkbox name="orphans" onChange={() => onChange('orphan')} checked={filters.includes('orphan')} />}
          label="Orphans"
        />
      </FormGroup>
    </>
  );
};

export default PublicationTypeFilters;
