import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { StoryChannelType } from '../../../../store/Stories/types';
import { storiesOperations } from '../../../../store/Stories';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';

type DeleteStoryChannelWindowProps = {
  open: boolean;
  channelType: StoryChannelType;
  channelId: string | null;
  storyId: string | null;
  onCloseClick: () => void;
  onSuccess: () => void;
  fullScreenOnMobile: boolean;
};

const DeleteStoryChannelWindow: FunctionComponent<DeleteStoryChannelWindowProps> = ({
  channelType,
  channelId,
  storyId,
  onCloseClick,
  onSuccess,
  open
}) => {
  const { t } = useTranslation();

  const onSubmit = async () => {
    try {
      if (storyId && channelId) {
        await storiesOperations.removeChannel(storyId, channelId);
      }
      onSuccess();
      Toast.success(t('notifications.story.storyChannelRemoved'));
    } catch (e) {
      Toast.error(t('notifications.common.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.story.windows.removeStoryChannel.header')}
      okButtonText={t('common.remove')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.story.windows.removeStoryChannel.content')}</Row>
      <Row>
        <Strong>{t(`pages.story.channels.${channelType}`)}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteStoryChannelWindow;
