import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { getEmptyColumnsArray } from '../../Utils';
import { StructureIcon } from '../Styling/StructureIcon';
import { EmailProjectRow, ProjectModelColumn, structureElements } from '../../types';

export interface ColumnInlineSelectorProps {
  onColumnPlaceholderClick: (id: number, cols: ProjectModelColumn[]) => void;
  row: EmailProjectRow;
  index: number;
}

export const ColumnInlineSelector: React.FC<ColumnInlineSelectorProps> = ({ row, onColumnPlaceholderClick, index }) => {
  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      {structureElements.map((structureEl, i) => (
        <Grid item xs={3} key={`structure-placeholder-${i}`}>
          <StructurePlaceholder
            onClick={() => onColumnPlaceholderClick(index, getEmptyColumnsArray(structureEl.sizes))}
          >
            <StructureIcon sizes={structureEl.sizes} />
            <Name>{structureEl.name}</Name>
          </StructurePlaceholder>
        </Grid>
      ))}
    </Grid>
  );
};

const StructurePlaceholder = styled.div`
  border: 1px solid #d4d4d4;
  background-color: white;
  padding: 0.7rem 1rem 0.4rem;
  cursor: pointer;
  border-radius: 10px;
  color: #717175;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  align-items: center;
  width: 100%;
`;

const Name = styled.div`
  margin-top: 5px;
`;
