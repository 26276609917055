import { AdSpace } from '../../../types';
import { TFunction } from 'i18next';
import { AdminTextInput } from '../../../../../Admin/Shared/AdminFormInputs';
import * as Yup from 'yup';
import update from 'immutability-helper';
import React from 'react';
import { ComponentEditFormReturnType } from '../index';

export const adSpace = (component: AdSpace, t: TFunction): ComponentEditFormReturnType<AdSpace> => {
  type Values = {
    adSpace: {
      width: number;
      height: number;
    };
  };

  const form = () => (
    <>
      <AdminTextInput type="number" t={t} name="adSpace.width" section="emailEditor" />
      <AdminTextInput type="number" t={t} name="adSpace.height" section="emailEditor" />
    </>
  );

  const initialFields: Values = {
    adSpace: {
      width: component.params.width,
      height: component.params.height
    }
  };

  const validationSchema = {
    adSpace: Yup.object().shape({
      width: Yup.number().max(700).required(),
      height: Yup.number().required()
    })
  };

  return {
    type: 'adSpace',
    form,
    initialFields,
    validationSchema,
    onSubmit: (params: Values, component: AdSpace) =>
      update(component, {
        params: {
          width: { $set: params.adSpace.width },
          height: { $set: params.adSpace.height }
        }
      })
  };
};
