import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  useTheme
} from '@material-ui/core';
import ColorPicker from '../../../../../../Shared/ColorPicker/ColorPicker';
import { useTranslation } from 'react-i18next';
import {
  MonthCalendarStyle,
  MonthCalendarStyles,
  monthCalendarStyles
} from '../../../../../../../store/MyOrganization/types';
import { getCalendarStyle } from './MonthCalendarElement';

type MonthCalendarSettingsProps = {
  selectedStyle: MonthCalendarStyles;
  setSelectedStyle: React.Dispatch<React.SetStateAction<MonthCalendarStyles>>;
  styles: MonthCalendarStyle;
  setStyles: React.Dispatch<React.SetStateAction<MonthCalendarStyle>>;
};

const MonthCalendarSettings: React.FC<MonthCalendarSettingsProps> = ({
  styles,
  setStyles,
  selectedStyle,
  setSelectedStyle
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [style, setStyle] = useState(selectedStyle || MonthCalendarStyles.MAIN);

  const handleStyleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedCalendarStyle = event.target.value as MonthCalendarStyles;
    const loadedStyle = getCalendarStyle(selectedCalendarStyle, theme);

    setStyle(selectedCalendarStyle);
    setSelectedStyle(selectedCalendarStyle);
    setEventBackgroundColor(loadedStyle.eventBackgroundColor);
    setEventTextColor(loadedStyle.eventTextColor);
    setEventBorderRadius(loadedStyle.eventBorderRadius);
    setHeaderBackgroundColor(loadedStyle.headerBackgroundColor);
    setHeaderTextColor(loadedStyle.headerTextColor);
    setBorderColor(loadedStyle.borderColor);
    setBackgroundColor(loadedStyle.backgroundColor);
    setBackgroundPrevNextColor(loadedStyle.backgroundPrevNextColor);
    setDayBackgroundColor(loadedStyle.dayBackgroundColor);
    setDayTextColor(loadedStyle.dayTextColor);
    setDayBorderRadius(loadedStyle.dayBorderRadius);
    setDayPosition(loadedStyle.dayPosition);
  };

  const [eventBackgroundColor, setEventBackgroundColor] = useState<string>(
    styles.eventBackgroundColor || theme.palette.custom.calendar[selectedStyle].eventBackgroundColor
  );
  const [eventTextColor, setEventTextColor] = useState<string>(
    styles.eventTextColor || theme.palette.custom.calendar[selectedStyle].eventTextColor
  );
  const [eventBorderRadius, setEventBorderRadius] = useState<number>(
    styles.eventBorderRadius || theme.palette.custom.calendar[selectedStyle].eventBorderRadius
  );
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState<string>(
    styles.headerBackgroundColor || theme.palette.custom.calendar[selectedStyle].headerBackgroundColor
  );
  const [headerTextColor, setHeaderTextColor] = useState<string>(
    styles.headerTextColor || theme.palette.custom.calendar[selectedStyle].headerTextColor
  );
  const [borderColor, setBorderColor] = useState<string>(
    styles.borderColor || theme.palette.custom.calendar[selectedStyle].borderColor
  );
  const [backgroundColor, setBackgroundColor] = useState<string>(
    styles.backgroundColor || theme.palette.custom.calendar[selectedStyle].backgroundColor
  );
  const [backgroundPrevNextColor, setBackgroundPrevNextColor] = useState<string>(
    styles.backgroundPrevNextColor || theme.palette.custom.calendar[selectedStyle].backgroundPrevNextColor
  );
  const [dayBackgroundColor, setDayBackgroundColor] = useState<string>(
    styles.dayBackgroundColor || theme.palette.custom.calendar[selectedStyle].dayBackgroundColor
  );
  const [dayTextColor, setDayTextColor] = useState<string>(
    styles.dayTextColor || theme.palette.custom.calendar[selectedStyle].dayTextColor
  );
  const [dayBorderRadius, setDayBorderRadius] = useState<number>(
    styles.dayBorderRadius || theme.palette.custom.calendar[selectedStyle].dayBorderRadius
  );
  const [dayPosition, setDayPosition] = useState<'l' | 'r'>(
    styles.dayPosition || theme.palette.custom.calendar[selectedStyle].dayPosition
  );

  const handleDayPositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDayPosition((event.target as HTMLInputElement).value as 'l' | 'r');
  };

  useEffect(() => {
    setStyles({
      eventBackgroundColor,
      eventTextColor,
      eventBorderRadius,
      headerBackgroundColor,
      headerTextColor,
      borderColor,
      backgroundColor,
      backgroundPrevNextColor,
      dayBackgroundColor,
      dayTextColor,
      dayBorderRadius,
      dayPosition
    });
  }, [
    eventBackgroundColor,
    eventTextColor,
    eventBorderRadius,
    headerBackgroundColor,
    headerTextColor,
    borderColor,
    backgroundColor,
    backgroundPrevNextColor,
    dayBackgroundColor,
    dayTextColor,
    dayBorderRadius,
    dayPosition
  ]);

  return (
    <>
      <FormControl variant="outlined" size="small" style={{ float: 'right' }}>
        <InputLabel id="style-select-label">{t('pages.emailEditor.monthCalendar.style')}</InputLabel>
        <Select
          labelId="style-select-label"
          id="style-select"
          value={style}
          onChange={handleStyleChange}
          label={t('pages.emailEditor.monthCalendar.style')}
        >
          {monthCalendarStyles.map((style) => (
            <MenuItem key={style} value={style}>
              {t(`pages.emailEditor.monthCalendar.styles.${style}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.monthCalendar.general')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.monthCalendar.backgroundColor')}:</div>
            <ColorPicker color={backgroundColor} onChange={setBackgroundColor} />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.monthCalendar.backgroundPrevNextColor')}:</div>
            <ColorPicker color={backgroundPrevNextColor} onChange={setBackgroundPrevNextColor} />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.monthCalendar.borderColor')}:</div>
            <ColorPicker color={borderColor} onChange={setBorderColor} />
          </Grid>
        </Grid>
      </fieldset>
      <fieldset style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px' }}>
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.monthCalendar.header')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.monthCalendar.headerBackgroundColor')}:</div>
            <ColorPicker color={headerBackgroundColor} onChange={setHeaderBackgroundColor} />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.monthCalendar.headerTextColor')}:</div>
            <ColorPicker color={headerTextColor} onChange={setHeaderTextColor} />
          </Grid>
        </Grid>
      </fieldset>
      <fieldset style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px' }}>
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.monthCalendar.event')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.monthCalendar.eventBackgroundColor')}:</div>
            <ColorPicker color={eventBackgroundColor} onChange={setEventBackgroundColor} />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.monthCalendar.eventTextColor')}:</div>
            <ColorPicker color={eventTextColor} onChange={setEventTextColor} />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.monthCalendar.eventBorderRadius')}:</div>
            <TextField
              id="event-border-radius"
              type="number"
              value={eventBorderRadius}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const radius = Math.abs(Number(event.target.value)) || '';
                setEventBorderRadius((radius as unknown) as number);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setEventBorderRadius(0);
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </fieldset>
      <fieldset style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px' }}>
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.monthCalendar.day')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.monthCalendar.dayBackgroundColor')}:</div>
            <ColorPicker color={dayBackgroundColor} onChange={setDayBackgroundColor} />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.monthCalendar.dayTextColor')}:</div>
            <ColorPicker color={dayTextColor} onChange={setDayTextColor} />
          </Grid>
          <Grid item xs={4}>
            <div>{t('pages.emailEditor.monthCalendar.dayBorderRadius')}:</div>
            <TextField
              id="day-border-radius"
              type="number"
              value={dayBorderRadius}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const radius = Math.abs(Number(event.target.value)) || '';
                setDayBorderRadius((radius as unknown) as number);
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setDayBorderRadius(0);
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{ fontSize: '14px', color: '#000' }}>
            {t('pages.emailEditor.monthCalendar.dayPosition')}:
          </FormLabel>
          <RadioGroup
            aria-label="gender"
            name="day-position"
            value={dayPosition}
            onChange={handleDayPositionChange}
            row
          >
            <FormControlLabel
              value="l"
              control={<Radio size="small" />}
              label={t('pages.emailEditor.monthCalendar.dayPositionLeft')}
            />
            <FormControlLabel
              value="r"
              control={<Radio size="small" />}
              label={t('pages.emailEditor.monthCalendar.dayPositionRight')}
            />
          </RadioGroup>
        </FormControl>
      </fieldset>
    </>
  );
};

export default MonthCalendarSettings;
