import React, { FunctionComponent, useState } from 'react';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { RouteComponentProps } from 'react-router';
import { DesignProjectCreatorState } from '../types';
import { Template, TemplateTypeName } from '../../../../store/Templates/types';
import CreateProjectStepper from '../Creators/Partials/CreateProjectStepper';
import LoadingModal from '../../../Shared/Loading/LoadingModal';
import SelectTemplate from './Partials/SelectTemplate';
import Toast from '../../../Shared/Toast/Toast';
import { createProjectErrorHandler } from './Utils';
import { designProjectsOperations } from '../../../../store/DesignProjects';

type DesignProjectCreatorProps = RouteComponentProps<{}> & {
  location: { state: { channelId?: string; storyId?: string } };
};

const DesignProjectCreator: FunctionComponent<DesignProjectCreatorProps> = ({ location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const steps: DesignProjectCreatorState[] = ['template', 'finish'];
  const [currentState, setCurrentState] = useState<DesignProjectCreatorState>('template');
  const [creatingProject, setCreatingProject] = useState(false);

  const channelId = location.state && location.state.channelId;
  const storyId = location.state && location.state.storyId;

  const createProject = async (template: Template) => {
    setCurrentState('finish');
    setCreatingProject(true);
    try {
      const newProject = await designProjectsOperations.create(template.id, channelId);
      Toast.success(t('notifications.projectCreate.success'));

      dispatch(push(linksConstants.PROJECTS.DESIGN.EDIT(newProject.id), { storyId }));
    } catch (e) {
      createProjectErrorHandler(e, t);

      setCreatingProject(false);
      setCurrentState('template');
    }
  };

  const backButton = {
    onClick: () => {
      if (storyId) {
        dispatch(push(linksConstants.STORY.EDIT(storyId)));
      } else {
        dispatch(push(linksConstants.DASHBOARD.DESIGN_PROJECTS));
      }
    },
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const getCurrentSection = () => {
    switch (currentState) {
      case 'template':
      case 'finish':
        return (
          <SelectTemplate
            showSourceProject={false}
            showOnlyDesigns
            showTopBar
            onSubmit={createProject}
            templateTypeName={TemplateTypeName.BULLETIN}
            publishDate={new Date()}
            sourceProject={null}
            infoText={t('pages.dashboard.createWizard.templateSelector.selectDesignProjectTemplate')}
            setCurrentState={() => {
              console.log('aa');
            }}
          />
        );
    }
  };

  const HeaderTitle = (
    <>
      <div>{t('pages.dashboard.createWizard.designProject')}</div>
      <div>
        <CreateProjectStepper currentState={currentState} steps={steps} />
      </div>
    </>
  );

  return (
    <PageContainer>
      {creatingProject && <LoadingModal message={t('pages.dashboard.creatingYourProject')} />}
      <PageHeader title={HeaderTitle} leftActionButtons={[backButton]} noBottomPadding />
      <PageContent>{getCurrentSection()}</PageContent>
    </PageContainer>
  );
};

export default DesignProjectCreator;
