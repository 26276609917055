import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';

type SaveBeforeExitWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  onSubmit: () => void;
  fullScreenOnMobile: boolean;
};

const SaveBeforeExitWindow: FunctionComponent<SaveBeforeExitWindowProps> = ({ onCloseClick, open, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('windows.saveBeforeExit.header')}
      okButtonText={t('windows.saveBeforeExit.continue')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('windows.saveBeforeExit.areYouSure')}</Row>
    </ModalConfirmationWindow>
  );
};

export default SaveBeforeExitWindow;
