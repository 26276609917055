import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  typography: {
    fontSize: 14,
    fontFamily: 'Muli, sans-serif'
  },
  palette: {
    type: 'light',

    primary: {
      light: '#0583c4',
      main: '#014b72',
      dark: '#00334e'
    },
    secondary: {
      light: '#00c8cd',
      main: '#00A5AA',
      dark: '#007b7f'
    },
    background: {
      default: '#F6F7FF'
    },
    // @ts-ignore
    custom: {
      navigation: {
        fontColor: '#0583c4'
      },
      templateTypes: {
        bulletin: '#fbaf17',
        email: '#007988',
        flyer: '#832b77',
        newsletter: '#f05a28',
        directory: '#8cc63e'
      },
      projectTypes: {
        print: '#fbaf17',
        email: '#007988',
        story: '#832b77'
      },
      calendar: {
        main: {
          eventBackgroundColor: '#117FE9',
          eventTextColor: '#fff',
          eventBorderRadius: 12,
          headerBackgroundColor: '#117FE9',
          headerTextColor: '#fff',
          borderColor: '#ccc',
          backgroundColor: '#fff',
          backgroundPrevNextColor: '#f5f5f5',
          dayBackgroundColor: 'none',
          dayTextColor: '#000',
          dayBorderRadius: 50,
          dayPosition: 'l'
        },
        light: {
          eventBackgroundColor: '#ced4d9',
          eventTextColor: '#34495d',
          eventBorderRadius: 12,
          headerBackgroundColor: '#ced4d9',
          headerTextColor: '#34495d',
          borderColor: '#ced4d9',
          backgroundColor: '#fff',
          backgroundPrevNextColor: '#f5f5f5',
          dayBackgroundColor: 'none',
          dayTextColor: '#000',
          dayBorderRadius: 50,
          dayPosition: 'l'
        },
        dark: {
          eventBackgroundColor: '#4a90e2',
          eventTextColor: '#fff',
          eventBorderRadius: 12,
          headerBackgroundColor: '#34495d',
          headerTextColor: '#ced4d9',
          borderColor: '#7e8c8d',
          backgroundColor: '#1e2c3a',
          backgroundPrevNextColor: '#111a24',
          dayBackgroundColor: 'none',
          dayTextColor: '#95a5a6',
          dayBorderRadius: 50,
          dayPosition: 'l'
        }
      }
    }
  },
  overrides: {
    MuiTableCell: {
      body: {
        color: '#545871'
      }
    }
  }
});

export default theme;
