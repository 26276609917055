import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { StoryChannelAttachment } from '../../../../store/Stories/types';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';

type DeleteStoryChannelAttachmentWindowProps = {
  open: boolean;
  attachment: StoryChannelAttachment;
  onCloseClick: () => void;
  onSuccess: (attachment: StoryChannelAttachment) => void;
  fullScreenOnMobile: boolean;
};

const DeleteStoryChannelAttachmentWindow: FunctionComponent<DeleteStoryChannelAttachmentWindowProps> = ({
  attachment,
  onCloseClick,
  onSuccess,
  open
}) => {
  const { t } = useTranslation();

  const onSubmit = async () => onSuccess(attachment);

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.story.windows.removeStoryChannelAttachment.header')}
      okButtonText={t('common.remove')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.story.windows.removeStoryChannelAttachment.content')}</Row>
      <Row>
        <Strong>{attachment.filename}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteStoryChannelAttachmentWindow;
