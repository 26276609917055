import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';

type IncentiveCreditsInfoWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const IncentiveCreditsInfoWindow: FunctionComponent<IncentiveCreditsInfoWindowProps> = ({ onCloseClick, open }) => {
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.myOrganization.windows.incentiveCreditsInfo.title')}
      hideActionButtons
      onCloseClick={onCloseClick}
    >
      <Row>{t('pages.myOrganization.windows.incentiveCreditsInfo.content')}</Row>
    </ModalConfirmationWindow>
  );
};

export default IncentiveCreditsInfoWindow;
