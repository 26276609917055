import React, { useEffect, useState } from 'react';
import { Button, Collapse, IconButton, InputAdornment, MenuItem, Select, TextField } from '@material-ui/core';
import { doAppSponsorsOperations } from '../../../../../store/DoApp/Sponsors';
import { WidgetTitle } from '../../../../Shared/ListingWidget/WidgetTitle';
import { Alert, Color } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import Toast from '../../../../Shared/Toast/Toast';
import { SponsorPoolTarget } from '../../../../../store/DoApp/Sponsors/types';

type DoAppCreateSponsorFormProps = {
  onSponsorCreated: () => void;
};

const DoAppCreateSponsorForm: React.FC<DoAppCreateSponsorFormProps> = ({ onSponsorCreated }) => {
  const { t } = useTranslation();
  const [regionApps, setRegionApps] = useState<{ app: string; name: string }[]>([]);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [regions, setRegions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<Color>('success');
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState<string>('0');
  const [amountToGive, setAmountToGive] = useState<string>('0');
  const [poolTarget, setPoolTarget] = useState<SponsorPoolTarget>(SponsorPoolTarget.SUBSCRIBER);

  useEffect(() => {
    const fetchRegionApps = async () => {
      const regionApps = await doAppSponsorsOperations.getRegionApps();
      setRegionApps(regionApps.data);
    };
    fetchRegionApps();
  }, []);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Allow only numbers and one dot
    const validValue = inputValue.match(/^\d*\.?\d*$/); // Regex allows numbers and a single dot
    if (validValue) {
      setAmount(inputValue);
    }
  };

  const handleAmountToGiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Allow only numbers and one dot
    const validValue = inputValue.match(/^\d*\.?\d*$/); // Regex allows numbers and a single dot
    if (validValue) {
      setAmountToGive(inputValue);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setOpen(false);
    setMessage(null);

    const parsedAmount = parseFloat(amount);
    const parsedAmountToGive = parseFloat(amountToGive);

    if (isNaN(parsedAmount) || parsedAmount < 0) {
      Toast.error(t('pages.doApp.sponsors.invalidAmount'));
      return;
    }
    if (isNaN(parsedAmountToGive) || parsedAmountToGive < 0) {
      Toast.error(t('pages.doApp.sponsors.invalidAmountToGive'));
      return;
    }
    try {
      const data = {
        email,
        name,
        amount: Math.round(parsedAmount * 100),
        amountToGive: Math.round(parsedAmountToGive * 100),
        regions,
        poolTarget
      };
      await doAppSponsorsOperations.createSponsor(data);
      setMessage(t('pages.doApp.sponsors.creationSuccess'));
      setSeverity('success');
      setName('');
      setEmail('');
      setAmount('0');
      setAmountToGive('0');
      setRegions([]);
      setOpen(true);
      onSponsorCreated();
    } catch (err) {
      setMessage(t('pages.doApp.sponsors.creationError'));
      setSeverity('error');
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <WidgetTitle title={t('pages.doApp.sponsors.createNewSponsor')} />
      <Collapse in={open}>
        <Alert
          severity={severity}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={() => setOpen(false)}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Collapse>
      <TextField
        label={t('pages.doApp.sponsors.name')}
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        variant="outlined"
        size="small"
        style={{ marginBottom: 16, marginTop: 16 }}
      />
      <TextField
        label={t('pages.doApp.sponsors.email')}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        variant="outlined"
        size="small"
        style={{ marginBottom: 16 }}
      />
      <TextField
        label={t('pages.doApp.sponsors.amount')}
        type="text"
        value={amount}
        onChange={handleAmountChange}
        fullWidth
        variant="outlined"
        size="small"
        style={{ marginBottom: 16 }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        }}
      />
      <TextField
        label={t('pages.doApp.sponsors.amountToGive')}
        type="text"
        value={amountToGive}
        onChange={handleAmountToGiveChange}
        fullWidth
        variant="outlined"
        size="small"
        style={{ marginBottom: 16 }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        }}
      />
      <Select
        label={t('pages.doApp.sponsors.regions')}
        value={regions}
        onChange={(e) => {
          setRegions(e.target.value as string[]);
        }}
        fullWidth
        multiple
        variant="outlined"
        style={{ marginBottom: 16 }}
      >
        {regionApps.map((regionApp) => (
          <MenuItem key={regionApp.app} value={regionApp.app}>
            {regionApp.name}
          </MenuItem>
        ))}
      </Select>
      <Select
        value={poolTarget}
        onChange={(e: React.ChangeEvent<{ value: unknown }>) => setPoolTarget(e.target.value as SponsorPoolTarget)}
        fullWidth
        variant="outlined"
        style={{ marginBottom: 16 }}
      >
        <MenuItem value="merchant">{t('pages.doApp.sponsors.poolTargetOptions.merchant')}</MenuItem>
        <MenuItem value="subscriber">{t('pages.doApp.sponsors.poolTargetOptions.subscriber')}</MenuItem>
      </Select>
      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
        {loading ? t('pages.doApp.sponsors.creating') : t('pages.doApp.sponsors.createSponsorSubmit')}
      </Button>
    </div>
  );
};

export default DoAppCreateSponsorForm;
