import {
  DoAppMerchantPromotionClaimsActionsConstants,
  DoAppMerchantPromotionClaimsIndexActionBEGIN,
  DoAppMerchantPromotionClaimsIndexActionFAILED,
  DoAppMerchantPromotionClaimsIndexActionSUCCESS,
  DoAppPromotionClaimsMineIndexResponse
} from './types';

export const doAppPromotionClaimsIndexBegin = (): DoAppMerchantPromotionClaimsIndexActionBEGIN => ({
  type: DoAppMerchantPromotionClaimsActionsConstants.INDEX_BEGIN
});

export const doAppPromotionClaimsIndexSuccess = (
  payload: DoAppPromotionClaimsMineIndexResponse
): DoAppMerchantPromotionClaimsIndexActionSUCCESS => ({
  type: DoAppMerchantPromotionClaimsActionsConstants.INDEX_SUCCESS,
  payload
});

export const doAppPromotionClaimsIndexFailed = (error: any): DoAppMerchantPromotionClaimsIndexActionFAILED => ({
  type: DoAppMerchantPromotionClaimsActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});
