import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { TextField } from '@material-ui/core';
import { sharingCenterOperations } from '../../../../store/SharingCenter';
import { isEmailProject, isPrintProject, isStory } from '../../../../utils';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import { printProjectsOperations } from '../../../../store/PrintProjects';
import { SharedProject } from '../../../../store/SharingCenter/types';
import LoadingModal from '../../../Shared/Loading/LoadingModal';
import { storiesOperations } from '../../../../store/Stories';

type CloneProjectWindowProps = {
  open: boolean;
  project: SharedProject;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const CloneProjectWindow: FunctionComponent<CloneProjectWindowProps> = ({ project, onCloseClick, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [projectName, setProjectName] = useState<string>(project.name);
  const [copyingProject, setCopyingProject] = useState<boolean>(false);

  const onSubmit = async () => {
    try {
      setCopyingProject(true);
      if (isEmailProject(project)) {
        await emailProjectsOperations.cloneShared(project.projectId, projectName);
      }
      if (isPrintProject(project)) {
        await printProjectsOperations.cloneShared(project.projectId, projectName);
      }
      if (isStory(project)) {
        await storiesOperations.cloneShared((project as SharedProject).projectId, projectName);
      }

      await dispatch(sharingCenterOperations.getProjectsSharedWithMe());
      onCloseClick();
      if (isStory(project)) {
        Toast.success(t('notifications.sharingCenter.cloneStorySuccess'));
      } else {
        Toast.success(t('notifications.sharingCenter.cloneProjectSuccess'));
      }
      setCopyingProject(false);
    } catch (e) {
      if (isStory(project)) {
        Toast.error(t('notifications.sharingCenter.cloneStoryError'));
      } else {
        Toast.error(t('notifications.sharingCenter.cloneProjectError'));
      }
    }
  };

  useEffect(() => {
    if (open) {
      setProjectName(project.name);
    }
  }, [open]);

  return (
    <>
      {copyingProject && <LoadingModal message={t('pages.sharingCenter.copyingProject')} />}
      <ModalConfirmationWindow
        open={open}
        header={
          isStory(project) ? t('pages.sharingCenter.windows.cloneStory') : t('pages.sharingCenter.windows.cloneProject')
        }
        okButtonText={t('pages.sharingCenter.windows.clone')}
        onCloseClick={onCloseClick}
        onSubmit={onSubmit}
        disableSubmitButton={projectName === ''}
      >
        <Row>
          {isStory(project)
            ? t('pages.sharingCenter.windows.cloneStoryContent')
            : t('pages.sharingCenter.windows.cloneProjectContent')}
        </Row>
        <Row>
          <TextField
            id="project-name"
            value={projectName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProjectName(event.target.value);
            }}
            size="small"
            fullWidth
            label={t('pages.sharingCenter.windows.name')}
            variant="outlined"
          />
        </Row>
      </ModalConfirmationWindow>
    </>
  );
};

export default CloneProjectWindow;
