import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { campaignsOperations } from '../../../../store/Campaigns';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import { Campaign } from '../../../../store/Campaigns/types';

type DeleteCampaignWindowProps = {
  open: boolean;
  campaign: Campaign | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const DeleteCampaignWindow: FunctionComponent<DeleteCampaignWindowProps> = ({ campaign, onCloseClick, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!campaign) return null;

  const onSubmit = async () => {
    try {
      await dispatch(campaignsOperations.deleteCampaign(campaign.id));
      await dispatch(campaignsOperations.index());
      onCloseClick();
      Toast.success(t('notifications.campaigns.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.campaigns.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.campaigns.windows.deleteCampaign')}
      okButtonText={t('pages.campaigns.windows.delete')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.campaigns.windows.deleteCampaignContent')}</Row>
      <Row>
        <Strong>{campaign.name}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteCampaignWindow;
