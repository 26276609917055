import React, { FunctionComponent, useEffect, useState } from 'react';
import { NoResultsFound, PageContainer, PageContent } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import { useTypedSelector } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Loader from '../../Shared/Loading/Loader';
import { RouteComponentProps } from 'react-router';
import { hasPermission } from '../../../utils/permissions';
import { TableSearchStoreModule } from '../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../store/Pagination/types';
import DataTable from '../../Shared/DataTable/DataTable';
import { usePaginationWatch } from '../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../Shared/DataTable/useTableSearchWatch';
import AddIcon from '@material-ui/icons/Add';
import { TableLink } from '../../Shared/DataTable/ColumnCell/UrlColumnCell';
import { Box, Chip, Tooltip } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { DesignProject } from '../../../store/DesignProjects/types';
import { designProjectsOperations } from '../../../store/DesignProjects';
import { push } from 'connected-react-router';
import linksConstants from '../../../config/app/linksConstants';
import TableActionButtonsContainer from '../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../Shared/DataTable/TableActionButton/TableActionButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import useOpenHandler from '../../../hooks/useOpenHandler';
import DeleteDesignWindow from './Windows/DeleteDesignWindow';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DesignProjectThumbnail } from './WorkInProgressTable/DesignProjectThumbnail';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloneDesignProjectWindow from './Windows/CloneDesignProjectWindow';

type DesignsPageProps = RouteComponentProps<{}> & {};

const DesignsPage: FunctionComponent<DesignsPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [activeDesign, setActiveDesign] = useState<DesignProject | null>(null);
  const organization = useTypedSelector((state) => state.auth.organization);
  const user = useTypedSelector((state) => state.auth.user);
  const role = useTypedSelector((state) => state.auth.role);
  const designs = useTypedSelector((state) => state.designProjects.list);

  const isFailed = useTypedSelector((state) => state.designProjects.index.isFailed);
  const loading = useTypedSelector((state) => state.designProjects.index.isLoading);
  const pagination = useTypedSelector((state) => state.designProjects.pagination);
  const tableSearch = useTypedSelector((state) => state.designProjects.tableSearch);

  const [deleteDesignWindowOpen, onDeleteDesignWindowOpen, onDeleteDesignWindowClose] = useOpenHandler();
  const [copyDesignWindowOpen, onCopyDesignWindowOpen, onCopyDesignWindowClose] = useOpenHandler();

  useEffect(() => {
    if (!loading) {
      setInitialLoading(false);
    }
  }, [loading]);

  usePaginationWatch(pagination, [designProjectsOperations.index]);
  useTableSearchWatch<DesignProject>(tableSearch, [designProjectsOperations.index]);

  const getActionButtons = () => {
    if (hasPermission(role, ['storiesAdd'])) {
      return [
        {
          onClick: () => dispatch(push(linksConstants.DASHBOARD.PROJECT_CREATOR.design)),
          icon: <AddIcon />,
          label: t('pages.designProjects.createProjectButton')
        }
      ];
    }

    return [];
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.designProjects.heading')} rightActionButtons={getActionButtons()} />
      <PageContent>
        {initialLoading && <Loader />}
        {!initialLoading && !designs.result.length && !pagination.q && (
          <>
            <NoResultsFound>
              <div style={{ marginBottom: '2rem' }}>{t('pages.designProjects.noResults')}</div>
            </NoResultsFound>
          </>
        )}
        {((!initialLoading && designs.result.length > 0) || pagination.q) && (
          <DataTable<DesignProject, 'thumbnail' | 'actions'>
            enableSearch={true}
            wrapperStyle={{
              padding: '0.5rem 2rem 0'
            }}
            headingStyle={{
              fontSize: '1.4rem',
              color: '#014b72',
              textAlign: 'left',
              padding: '0.5rem',
              fontWeight: 'normal'
            }}
            columnDefinitions={[
              {
                name: 'thumbnail',
                style: { maxWidth: 50 },
                render: (design) => <DesignProjectThumbnail project={design} />
              },
              {
                name: 'name',
                sortable: true,
                style: { minWidth: '300px', maxWidth: 'none' },
                render: (design) => (
                  <div
                    onClick={() => dispatch(push(linksConstants.PROJECTS.DESIGN.EDIT(design.id)))}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableLink>{design.name}</TableLink>
                    <Box display="flex" alignItems="center">
                      <AssignmentIndIcon titleAccess={t('pages.story.author')} fontSize="small" />
                      <div style={{ fontSize: '13px', marginLeft: '3px' }}>
                        {design.createdBy ? (
                          <Tooltip title={design.createdBy.email || ''} placement="bottom" arrow>
                            <div>{design.createdBy.fullName}</div>
                          </Tooltip>
                        ) : (
                          'N/A'
                        )}
                      </div>
                    </Box>
                  </div>
                )
              },
              {
                name: 'stories',
                sortable: false,
                render: (design) => (
                  <StoryList>
                    {design.stories.map((story) => (
                      <Chip
                        key={story.id}
                        size="small"
                        variant="outlined"
                        label={
                          <div>
                            <strong>{story.name}</strong>
                            <br />
                            {story.channels.map((channel, i) => (
                              <>
                                <span key={channel.id}>{t(`pages.story.channels.${channel.type}`)}</span>
                                {i < story.channels.length - 1 ? ', ' : ''}
                              </>
                            ))}
                          </div>
                        }
                        style={{ margin: '0 3px 3px 0', height: 'auto', borderRadius: '6px' }}
                        clickable
                        component={Link}
                        to={{
                          pathname: linksConstants.STORY.EDIT(story.id),
                          state: {
                            from: linksConstants.DASHBOARD.DESIGN_PROJECTS
                          }
                        }}
                      />
                    ))}
                  </StoryList>
                )
              },
              {
                name: 'actions',
                render: (design) => (
                  <TableActionButtonsContainer>
                    <TableActionButton
                      icon={<EditIcon />}
                      tooltip={t('pages.designProjects.table.buttons.edit')}
                      ButtonProps={{
                        // disabled: !hasPermission(role, ['designsEdit']),
                        onClick: () => dispatch(push(linksConstants.PROJECTS.DESIGN.EDIT(design.id)))
                      }}
                    />
                    <TableActionButton
                      icon={<FileCopyIcon />}
                      tooltip={t('common.copy')}
                      ButtonProps={{
                        // disabled: !hasPermission(role, ['designsRemove']),
                        onClick: () => {
                          setActiveDesign(design);
                          onCopyDesignWindowOpen();
                        }
                      }}
                    />
                    <TableActionButton
                      icon={<DeleteIcon />}
                      tooltip={t('pages.designProjects.table.buttons.delete')}
                      ButtonProps={{
                        // disabled: !hasPermission(role, ['designsRemove']),
                        onClick: () => {
                          setActiveDesign(design);
                          onDeleteDesignWindowOpen();
                        }
                      }}
                    />
                  </TableActionButtonsContainer>
                )
              }
            ]}
            tPath={'pages.designProjects.table'}
            data={designs}
            tableSearchProps={{
              tableSearch,
              module: TableSearchStoreModule.DESIGN_PROJECTS
            }}
            paginationProps={{
              pagination,
              module: PaginationStoreModule.DESIGN_PROJECTS
              // onPageChange: () => {},
              // onPerChange: () => {}
            }}
            isFailed={isFailed}
            isLoading={loading}
          />
        )}
      </PageContent>
      <DeleteDesignWindow
        design={activeDesign}
        open={deleteDesignWindowOpen}
        onCloseClick={onDeleteDesignWindowClose}
        fullScreenOnMobile
      />
      <CloneDesignProjectWindow
        project={activeDesign}
        open={copyDesignWindowOpen}
        onCloseClick={onCopyDesignWindowClose}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

const StoryList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export default DesignsPage;
