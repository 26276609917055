import { imagesOperations } from '../../../../../../store/Images';
import { ImageData } from '../../types';

export const brandfolderImage = async (
  projectId: string,
  imageData: ImageData,
  fileId: string,
  type: 'project' | 'template'
) => {
  // upload image using brandFolder id
  return await imagesOperations.uploadBrandFolderImage(
    projectId,
    fileId,
    type,
    imageData.containerWidth || 80,
    imageData.containerHeight || 80
  );
};
