import MailingListsPage from './MailingListsPage';
import MailingListEditPage from './MailingListEditPage';
import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';

const MailingListsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.MAILING_LISTS.INDEX,
    exact: true,
    name: 'mailingLists',
    public: false,
    show: true,
    component: MailingListsPage,
    scope: []
  },
  {
    path: linksConstants.MAILING_LISTS.EDIT(':mailingListId', ':mailingListType'),
    exact: false,
    name: 'mailingListsEdit',
    public: false,
    show: true,
    component: MailingListEditPage,
    scope: []
  }
];

export default MailingListsRoutes;
