import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageContentPaper } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import { formatNoteDate, getPasswordExpirationData, useTypedSelector } from '../../../utils';
import { Button, Grid, Typography, withTheme } from '@material-ui/core';
import MyAccountProfileForm from './Partials/MyAccountProfileForm';
import ChangePasswordWindow from './Windows/ChangePasswordWindow';
import useOpenHandler from '../../../hooks/useOpenHandler';
import ChangePasswordIcon from '@material-ui/icons/VpnKey';
import styled from 'styled-components';
import moment from 'moment';
import { PasswordPolicy } from '../../../store/SystemSettings/types';
import { myOrganizationOperations } from '../../../store/MyOrganization';
import app from '../../../config/app/app';

type MyAccountPageProps = {};

const MyAccountPage: FunctionComponent<MyAccountPageProps> = () => {
  const { t } = useTranslation();
  const user = useTypedSelector((state) => state.auth.user);
  const role = useTypedSelector((state) => state.auth.role);
  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicy | null>(null);

  useEffect(() => {
    const fetchPasswordPolicy = async () => {
      if (user) {
        setPasswordPolicy(await myOrganizationOperations.getPasswordPolicy());
      }
    };
    fetchPasswordPolicy();
  }, []);

  const [changePasswordWindowOpen, onChangePasswordWindowOpen, onChangePasswordWindowClose] = useOpenHandler();

  if (!passwordPolicy) {
    return null;
  }

  const { percentOfTimeLeft, daysToExpire, humanReadableTimeToExpire, rotationEnabled } = getPasswordExpirationData(
    passwordPolicy,
    user
  );

  return (
    <PageContainer>
      <PageHeader title={t('pages.myAccount.title')} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageContentPaper>
            <Typography variant="h6" gutterBottom>
              {t('pages.myAccount.accountDetails')}
            </Typography>
            <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
            <PasswordRow>
              <ChangePasswordRow>
                <span>{t('pages.myAccount.password')}: ********</span>
                <Button variant="outlined" size="small" color="primary" onClick={onChangePasswordWindowOpen}>
                  <ChangePasswordIcon /> &nbsp;{t('pages.myAccount.changePassword')}
                </Button>
              </ChangePasswordRow>

              <LatestUpdateInfo>
                <span>
                  {t('pages.myAccount.passwordUpdatedAt')} {formatNoteDate(user.passwordUpdatedAt)}
                </span>{' '}
                {rotationEnabled && (
                  <PasswordExpirationInfo type={percentOfTimeLeft > 20 && daysToExpire >= 0 ? 'info' : 'warning'}>
                    {daysToExpire >= 0 && (
                      <>
                        {t('pages.myAccount.passwordWillExpireIn')} {humanReadableTimeToExpire}
                      </>
                    )}
                    {daysToExpire < 0 && <>{t('pages.myAccount.passwordHasExpired')}</>}
                  </PasswordExpirationInfo>
                )}
              </LatestUpdateInfo>
            </PasswordRow>
            <MyAccountProfileForm user={user} role={role} />
          </PageContentPaper>
        </Grid>
      </Grid>
      <ChangePasswordWindow
        open={changePasswordWindowOpen}
        onCloseClick={onChangePasswordWindowClose}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

const PasswordRow = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.primary.dark};
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 1em;
  font-size: 1rem;
`);

const ChangePasswordRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LatestUpdateInfo = styled.div`
  font-style: italic;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const PasswordExpirationInfo = withTheme(styled.span<{ type: 'info' | 'warning' }>`
  color: ${({ type, theme }) => (type === 'info' ? theme.palette.primary.main : theme.palette.warning.dark)};
`);

export default MyAccountPage;
