import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Strong } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { useDispatch } from 'react-redux';
import { AnyProject } from '../../../../store/Dashboard/types';
import { Button } from '@material-ui/core';
import app from '../../../../config/app/app';
import { dashboardOperations } from '../../../../store/Dashboard';

type ResetProjectCheckInStatusWindowProps = {
  open: boolean;
  project: AnyProject | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  onSuccessfulSubmit?: () => void;
};

const ResetProjectCheckInStatusWindow: FunctionComponent<ResetProjectCheckInStatusWindowProps> = ({
  project,
  onCloseClick,
  open,
  onSuccessfulSubmit
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [notified, setNotified] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(app.secondsToWaitBeforeForceCheckOut);

  let timerInterval: null | number = null;

  useEffect(() => {
    if (open) {
      setNotified(false);
      setTimeLeft(app.secondsToWaitBeforeForceCheckOut);

      if (timerInterval) {
        clearInterval(timerInterval);
      }
    }
  }, [open]);

  const onSubmit = async () => {
    try {
      if (project) {
        await dashboardOperations.notifyUserToCheckOut(project.id);

        setNotified(true);

        timerInterval = setInterval(() => {
          setTimeLeft((time) => {
            if (time <= 1 && timerInterval) {
              clearInterval(timerInterval);
            }

            return time - 1;
          });
        }, 1000);
      }
    } catch (e) {
      Toast.error(t('notifications.resetCheckIn.error'));
    }
  };

  const forceCheckOut = async () => {
    try {
      if (project) {
        await dashboardOperations.forceCheckOut(project.id);

        onCloseClick();
      }
    } catch (e) {
      Toast.error(t('notifications.resetCheckIn.error'));
    }
  };

  if (!project) return null;

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('windows.resetCheckIn.header')}
      okButtonText={t('common.notify')}
      onCloseClick={onCloseClick}
      disableSubmitButton={notified}
      onSubmit={onSubmit}
    >
      {!notified && <Row>{t('windows.resetCheckIn.notifyUserInfo')}</Row>}
      <Row>
        <div>
          {t('windows.resetCheckIn.projectName')}: <Strong>{project.name}</Strong>
        </div>
        <div>
          {t('windows.resetCheckIn.editingUser')}:{' '}
          <Strong>
            {project.checkedInBy ? `${project.checkedInBy.fullName} (${project.checkedInBy.email})` : '---'}
          </Strong>
        </div>
      </Row>
      {notified && <Row>{t('windows.resetCheckIn.userHasBeenNotified', { timeLeft })}</Row>}
      {notified && (
        <Row style={{ textAlign: 'center' }}>
          <Button disabled={timeLeft > 0} color={'primary'} variant="contained" size="small" onClick={forceCheckOut}>
            {t('windows.resetCheckIn.forceResetCheckInStatus')}
          </Button>
        </Row>
      )}
    </ModalConfirmationWindow>
  );
};

export default ResetProjectCheckInStatusWindow;
