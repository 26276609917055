import { createNetworkErrorObject } from '../../../../../utils';
import Toast from '../../../../Shared/Toast/Toast';
import { TFunction } from 'i18next';

export const createProjectErrorHandler = (e: any, t: TFunction) => {
  const networkError = createNetworkErrorObject(e);

  switch (networkError.message) {
    case 'template_not_found':
      Toast.info(t('notifications.projectCreate.errorTemplateNotFound'));
      break;
    case 'template_not_uploaded':
      Toast.info(t('notifications.projectCreate.errorTemplateNotUploaded'));
      break;
    default:
      Toast.error(t('notifications.projectCreate.error', { rawError: `(${networkError.message})` }));
  }
};
