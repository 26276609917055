import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { Row } from '../../../../Shared/StyledComponents';
import { PageContentPaper, PageContentPaperHeading } from '../../../PageStyledComponents';
import SaveIcon from '@material-ui/icons/Save';
import { EmailCampaignsReportChartData, EmailCampaignsReportResponse } from '../../../../../store/AdminReports/types';
import { adminReportsOperations } from '../../../../../store/AdminReports';
import { extent, scaleBand, scaleLinear } from 'd3';
import './Chart/Chart.scss';
import { AxisBottom } from './Chart/AxisBottom';
import { AxisLeft } from './Chart/AxisLeft';
import { Legend } from './Chart/Legend';
import { LegendElement } from './Chart/types';
import { BarChart } from './Chart/BarChart';
import { LineChart } from './Chart/LineChart';
import { AxisRight } from './Chart/AxisRight';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import AdminReportsGenerateCsvWindow from '../Windows/AdminReportsGenerateCsvWindow';

type EmailCampaignsReportProps = {};

const EmailCampaignsReport: FunctionComponent<EmailCampaignsReportProps> = () => {
  const { t } = useTranslation();
  const [reportData, setReportData] = useState<EmailCampaignsReportResponse['data']>([]);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [csvDownloadWindowOpen, onCsvDownloadWindowOpen, onCsvDownloadWindowClose] = useOpenHandler();

  const width = 960;
  const height = 500;
  const margin = { top: 40, right: 60, bottom: 65, left: 50 };
  const xAxisLabelOffset = 50;
  const yAxisLeftLabelOffset = 30;
  const yAxisRightLabelOffset = 55;

  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  const campaignsColor = '#e3ba22';
  const recipientsColor = '#137b80';

  useEffect(() => {
    const fetchData = async () => {
      setError(false);
      try {
        const response = await adminReportsOperations.emailCampaignsReport('json');

        if (response) {
          setReportData(response.data);
        }
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    };

    fetchData();
  }, []);

  if (loading && !reportData) {
    return <>Loading...</>;
  }

  const xValue = (d: EmailCampaignsReportChartData) => d.date;
  const xAxisLabel = 'Day';

  const yBarValue = (d: EmailCampaignsReportChartData) => d.campaigns;
  const yAxisLeftLabel = 'Email Campaigns';

  const yLineValue = (d: EmailCampaignsReportChartData) => d.recipients;
  const yAxisRightLabel = 'Recipients';

  const yLeftExtent = extent(reportData, yBarValue);
  const yRightExtent = extent(reportData, yLineValue);

  const xScale = scaleBand().domain(reportData.map(xValue)).range([0, innerWidth]).padding(0.5);

  const yLeftScale = scaleLinear()
    .domain([0, Math.ceil((yLeftExtent[1] ?? 0) / 5) * 5])
    .range([innerHeight, 0])
    .nice();

  const yRightScale = scaleLinear()
    .domain([0, Math.ceil((yRightExtent[1] ?? 0) / 5) * 5])
    .range([innerHeight, 0])
    .nice();

  const legendData: LegendElement[] = [
    { type: 'rect', text: 'Email Campaigns Sent', color: campaignsColor },
    { type: 'line', text: 'Recipients', color: recipientsColor }
  ];

  return (
    <PageContentPaper>
      <PageContentPaperHeading>
        <Typography variant="h6" gutterBottom>
          {t('pages.adminReports.emailCampaigns.title')}
        </Typography>
        <Button color={'secondary'} variant={'contained'} size="medium" onClick={onCsvDownloadWindowOpen}>
          <SaveIcon /> {t('pages.adminReports.generateCsvReport')}
        </Button>
      </PageContentPaperHeading>
      <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
      {error && <div>Something went wrong...</div>}
      {!error && (
        <Row style={{ maxWidth: 800 }}>
          {!loading && (
            <svg viewBox={`0 0 ${width} ${height}`}>
              <g transform={`translate(${margin.left},${margin.top})`}>
                <Legend legendData={legendData} positionLeft={240} positionTop={-30} />
                <AxisBottom xScale={xScale} innerHeight={innerHeight} tickOffset={7} />
                <text
                  className="axis-label"
                  textAnchor="middle"
                  transform={`translate(${-yAxisLeftLabelOffset},${innerHeight / 2}) rotate(-90)`}
                >
                  {yAxisLeftLabel}
                </text>
                <text
                  className="axis-label"
                  textAnchor="middle"
                  transform={`translate(${innerWidth + yAxisRightLabelOffset},${innerHeight / 2}) rotate(-90)`}
                >
                  {yAxisRightLabel}
                </text>
                <AxisLeft yScale={yLeftScale} innerWidth={innerWidth} tickOffset={7} />
                <AxisRight yScale={yRightScale} innerWidth={innerWidth} tickOffset={7} />
                <text className="axis-label" x={innerWidth / 2} y={innerHeight + xAxisLabelOffset} textAnchor="middle">
                  {xAxisLabel}
                </text>
                <g className="marks">
                  <BarChart
                    data={reportData}
                    xValue={xValue}
                    xScale={xScale}
                    yScale={yLeftScale}
                    yBarValue={yBarValue}
                    color={campaignsColor}
                  />
                  <LineChart
                    data={reportData}
                    xValue={xValue}
                    yLineValue={yLineValue}
                    xScale={xScale}
                    yScale={yRightScale}
                    circleRadius={3}
                    color={recipientsColor}
                  />
                </g>
              </g>
            </svg>
          )}
        </Row>
      )}
      <AdminReportsGenerateCsvWindow
        open={csvDownloadWindowOpen}
        onCloseClick={onCsvDownloadWindowClose}
        onSubmit={async (range) => await adminReportsOperations.emailCampaignsReport('csv', range)}
      />
    </PageContentPaper>
  );
};

export default EmailCampaignsReport;
