import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AdminRole } from '../../../../../store/AdminRoles/types';
import { adminRolesOperations } from '../../../../../store/AdminRoles';
import Toast from '../../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import { createNetworkErrorObject } from '../../../../../utils';

type DeleteSubscriberWindowProps = {
  open: boolean;
  role: AdminRole | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const DeleteRoleWindow: FunctionComponent<DeleteSubscriberWindowProps> = ({ role, onCloseClick, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!role) return null;

  const onSubmit = async () => {
    try {
      await adminRolesOperations.deleteRole(role);
      await dispatch(adminRolesOperations.index());
      onCloseClick();
      Toast.success(t('notifications.adminRoles.success'));
    } catch (e) {
      const error = createNetworkErrorObject(e);

      switch (error.statusCode) {
        case 403:
          return Toast.error(t('notifications.adminRoles.noPermissions'));
        case 422:
          return Toast.error(t('notifications.adminRoles.roleAssignedToUsers'));

        default:
          return Toast.error(t('notifications.adminRoles.errorOperation'));
      }
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminRoles.windows.deleteHeader')}
      okButtonText={t('pages.adminRoles.windows.delete')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.adminRoles.windows.deleteRoleContent')}</Row>
      <Row>
        <Strong>{role.name}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteRoleWindow;
