import React, { FunctionComponent, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CreateStoryValues } from '../../../../store/Stories/types';
import { storiesOperations } from '../../../../store/Stories';
import Toast from '../../../Shared/Toast/Toast';
import { AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import ModalFormWindow from '../../../Shared/Window/ModalFormWindow';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import { Range } from 'react-date-range';
import { addDays } from 'date-fns';
import { Grid } from '@material-ui/core';
import DateRangePickerComponent from '../Partials/DateRangePickerComponent';

type CreateStoryWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const CreateStoryWindow: FunctionComponent<CreateStoryWindowProps> = ({ onCloseClick, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [publicationPeriod, setPublicationPeriod] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const onSubmit = async (values: CreateStoryValues) => {
    try {
      if (publicationPeriod[0].startDate && publicationPeriod[0].endDate) {
        const story = await storiesOperations.create(
          values.name,
          new Date(publicationPeriod[0].startDate.setHours(0, 0, 0, 0)),
          new Date(publicationPeriod[0].endDate.setHours(23, 59, 59, 999))
        );
        dispatch(push(linksConstants.STORY.EDIT(story.id)));
        Toast.success(t('notifications.story.storySaved'));
      }
    } catch (e) {
      Toast.error(t('notifications.story.errorSave'));
    }
  };

  const formInputs = () => (
    <Grid container spacing={2} style={{ minWidth: '600px' }}>
      <Grid item xs={8}>
        <AdminTextInput t={t} name="name" section="story" autoFocus />
      </Grid>
      <Grid item xs={4}>
        <DateRangePickerComponent
          range={publicationPeriod}
          setRange={setPublicationPeriod}
          label={t('pages.story.inputs.publicationWeek')}
        />
      </Grid>
    </Grid>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.create')}
      header={t('pages.story.windows.createStory.header')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        name: ''
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required()
      })}
      maxWidth="lg"
    />
  );
};

export default CreateStoryWindow;
