import React, { FunctionComponent } from 'react';
import { StoryChannel, StoryChannelHistory, StoryChannelStatus } from '../../../../store/Stories/types';
import { formatNoteDate } from '../../../../utils';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Divider, Tooltip } from '@material-ui/core';
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';

type ChannelLatestChangeProps = {
  channel: StoryChannel;
};

const ChannelLatestChange: FunctionComponent<ChannelLatestChangeProps> = ({ channel }) => {
  const { t } = useTranslation();

  if (channel.status === StoryChannelStatus.IDLE) return null;
  if (!channel.history.length) return null;

  const latestStatusToShow = channel.history.find((item) =>
    [StoryChannelStatus.APPROVED, StoryChannelStatus.REJECTED, StoryChannelStatus.READY_TO_REVIEW].includes(
      item.transition
    )
  );

  if (!latestStatusToShow) return null;

  const getTooltipContent = (history: StoryChannelHistory[]) => {
    return (
      <div>
        <strong>{t('pages.story.channelStatusUpdates')}</strong>
        <div>{history.map((item, i) => getHistoryItem(item, i))}</div>
      </div>
    );
  };

  const getHistoryItem = (item: StoryChannelHistory, i: number) => {
    const createdBy = item.createdBy || null;

    return (
      <div key={`history-${item.id}-${i}`}>
        <span>{t(`pages.story.statuses.${item.transition}`)}</span>{' '}
        {createdBy && (
          <span>
            by <Email>{createdBy.email}</Email>{' '}
          </span>
        )}
        at {formatNoteDate(item.createdAt)}
      </div>
    );
  };

  return (
    <>
      <Tooltip title={getTooltipContent(channel.history)} placement="left">
        <SpeakerNotesOutlinedIcon color="disabled" />
      </Tooltip>
      <Divider orientation="vertical" flexItem style={{ margin: '0 0.5rem' }} />
    </>
  );
};

const Email = styled.span`
  margin-right: 0.2rem;
  font-weight: bold;
`;

export default ChannelLatestChange;
