import { TableSearchActionsConstants, TableSearchActionType } from './types';
import { EntityToTableSearchStoreModule, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import update from 'immutability-helper';

export function createTableSearchReducer<Entity, State extends StateWithTableSearch<Entity>>(
  module: EntityToTableSearchStoreModule<Entity>
) {
  return function (state: State, action: TableSearchActionType<Entity>) {
    if (action.module !== module) {
      return state;
    }

    switch (action.type) {
      // case TableSearchActionsConstants.ADD_FILTER:
      //   return update<StateWithTableSearch<Entity>>(state, {
      //     tableSearch: {
      //       filters: {
      //         $set: {
      //           ...state.tableSearch.filters,
      //           [action.payload.filter.name]: [action.payload.filter]
      //         }
      //       }
      //     }
      //   });
      // case TableSearchActionsConstants.REMOVE_FILTER:
      //   return update<StateWithTableSearch<Entity>>(state, {
      //     tableSearch: {
      //       filters: {
      //         $set: {
      //           ...state.tableSearch.filters,
      //           [action.payload.name]: undefined
      //         }
      //       }
      //     }
      //   });
      case TableSearchActionsConstants.ORDER_COLUMN: {
        let orderToApply: 'asc' | 'desc' | undefined;

        switch (state.tableSearch.order[action.payload.order.name]?.order) {
          case undefined:
            orderToApply = 'asc';
            break;
          case 'asc':
            orderToApply = 'desc';
            break;
          case 'desc':
            orderToApply = undefined;
            break;
        }

        return update<StateWithTableSearch<Entity>>(state, {
          tableSearch: {
            order: {
              $set: {
                ...state.tableSearch.order,
                [action.payload.order.name]: orderToApply
                  ? {
                      name: action.payload.order.name,
                      order: orderToApply
                    }
                  : undefined
              }
            }
          }
        });
      }
      // case TableSearchActionsConstants.CLEAR_ALL_FILTERS:
      //   return update<StateWithTableSearch<Entity>>(state, {
      //     tableSearch: {
      //       filters: {
      //         $set: {}
      //       }
      //     }
      //   });
      case TableSearchActionsConstants.CLEAR_ALL_ORDERS:
        return update<StateWithTableSearch<Entity>>(state, {
          tableSearch: {
            order: {
              $set: {}
            }
          }
        });
      default:
        return state;
    }
  };
}
