import React, { ChangeEvent, FormEvent, FunctionComponent, ReactEventHandler, useState } from 'react';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { useTranslation } from 'react-i18next';
import { DamSystem, DamSystemForm, damSystemName, DamSystemName } from '../../../../store/SystemSettings/types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { TFunction } from 'i18next';

type CreateOrUpdateDamSystemProps = {
  type: 'create' | 'update';
  open: boolean;
  onCloseClick: () => void;
  onSubmit: (form: DamSystemForm) => Promise<void>;
  fullScreenOnMobile: boolean;
  dam?: DamSystem | null;
};

type FormField = keyof DamSystemForm;

const CreateOrUpdateDamSystem: FunctionComponent<CreateOrUpdateDamSystemProps> = ({
  onCloseClick,
  onSubmit,
  type,
  open,
  dam
}) => {
  const { t } = useTranslation();

  const getInitialFormData = (): DamSystemForm => {
    if (dam) {
      return {
        name: dam.name,
        url: dam.url
      };
    }

    return {
      name: DamSystemName.ARK,
      url: ''
    };
  };

  const [formData, setFormData] = useState<DamSystemForm>(getInitialFormData());
  const [formErrors, setFormErrors] = useState<Record<FormField, boolean>>({
    name: false,
    url: false,
    s3AccessKey: false,
    apiKey: false,
    s3SecretKey: false,
    s3BucketName: false,
    s3Region: false,
    s3BasePath: false
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    const errors: Record<FormField, boolean> = {
      name: false,
      url: false,
      s3AccessKey: false,
      apiKey: false,
      s3SecretKey: false,
      s3BucketName: false,
      s3Region: false,
      s3BasePath: false
    };

    let isValid = true;

    for (const key in formData) {
      if (formData[key as FormField] === '') {
        if (key !== 'meta') {
          errors[key as FormField] = true;
          isValid = false;
        }
      } else {
        errors[key as FormField] = false;
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const submit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateForm()) {
      await onSubmit(formData);
    }
  };

  return (
    <ModalConfirmationWindow
      onCloseClick={onCloseClick}
      open={open}
      header={
        type === 'create'
          ? t('pages.systemSettings.dam.createDamWindowTitle')
          : t('pages.systemSettings.dam.updateDamWindowTitle')
      }
      okButtonText={type === 'create' ? t('common.create') : t('common.update')}
      hideActionButtons
    >
      <div>
        <form onSubmit={submit}>
          <FormControl variant="outlined" size="small" fullWidth>
            <Select
              name="name"
              error={formErrors.name}
              onChange={(e) => {
                const { name, value } = e.target;
                if (name && value) {
                  setFormData({
                    ...formData,
                    [name]: value
                  });
                }
              }}
              value={formData.name}
            >
              {damSystemName.map((name) => {
                return (
                  <MenuItem key={name} value={name}>
                    {t(`common.damSystem.${name}`)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormFieldInput
            t={t}
            name="url"
            label={t('pages.systemSettings.dam.form.url')}
            helperText={formErrors.url && t('pages.systemSettings.dam.form.urlError')}
            error={formErrors.url}
            onChange={handleInputChange}
            value={formData.url}
          />

          <Typography variant="h6">{t('pages.systemSettings.dam.form.specificFields')}:</Typography>

          {formData.name === DamSystemName.ARK && (
            <>
              <FormFieldInput
                t={t}
                name="s3AccessKey"
                label={t('pages.systemSettings.dam.form.s3AccessKey')}
                error={formErrors.s3AccessKey}
                onChange={handleInputChange}
                value={formData.s3AccessKey}
              />
              <FormFieldInput
                t={t}
                name="s3SecretKey"
                label={t('pages.systemSettings.dam.form.s3SecretKey')}
                error={formErrors.s3SecretKey}
                onChange={handleInputChange}
                value={formData.s3SecretKey}
              />
              <FormFieldInput
                t={t}
                name="s3BucketName"
                label={t('pages.systemSettings.dam.form.s3BucketName')}
                error={formErrors.s3BucketName}
                onChange={handleInputChange}
                value={formData.s3BucketName}
              />
              <FormFieldInput
                t={t}
                name="s3Region"
                label={t('pages.systemSettings.dam.form.s3Region')}
                error={formErrors.s3Region}
                onChange={handleInputChange}
                value={formData.s3Region}
              />
              <FormFieldInput
                t={t}
                name="s3BasePath"
                label={t('pages.systemSettings.dam.form.s3BasePath')}
                error={formErrors.s3BasePath}
                onChange={handleInputChange}
                value={formData.s3BasePath}
              />
            </>
          )}

          {formData.name === DamSystemName.BF && (
            <FormFieldInput
              t={t}
              name="apiKey"
              label={t('pages.systemSettings.dam.form.apiKey')}
              error={formErrors.apiKey}
              onChange={handleInputChange}
              value={formData.apiKey}
            />
          )}
          <div style={{ textAlign: 'right', marginBottom: 16 }}>
            <Button type="submit" variant="contained" color="primary" style={{ marginTop: 10 }}>
              {t('common.submit')}
            </Button>
          </div>
        </form>
      </div>
    </ModalConfirmationWindow>
  );
};

type FormFieldProps = {
  t: TFunction;
  error: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string | undefined;
  helperText?: any;
  name: string;
  label: string;
};

const FormFieldInput: FunctionComponent<FormFieldProps> = (props) => (
  <TextField
    name={props.name}
    label={props.label}
    variant="outlined"
    size="small"
    fullWidth
    margin="normal"
    error={props.error}
    helperText={props.helperText}
    onChange={props.onChange}
    value={props.value}
  />
);

export default CreateOrUpdateDamSystem;
