import React, { FunctionComponent } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@material-ui/core';
import { FilterHeader } from '../../AdminStyledComponents';
import { AdminProjectsColumnsSettings } from '../../../../../store/AdminProjects/types';
import { useTranslation } from 'react-i18next';

type SelectedColumnsFiltersProps = {
  columns: AdminProjectsColumnsSettings;
  onChange: (columnName: keyof AdminProjectsColumnsSettings) => void;
};

const SelectedColumnsFilters: FunctionComponent<SelectedColumnsFiltersProps> = ({ columns, onChange }) => {
  const { t } = useTranslation();

  console.log('columns', columns);

  const columnNamesCol1 = Object.keys(columns) as (keyof AdminProjectsColumnsSettings)[];
  const columnNamesCol2 = columnNamesCol1.splice(0, Math.floor(columnNamesCol1.length / 2));

  return (
    <>
      <FilterHeader>{t('pages.adminProjects.filters.selectColumns')}</FilterHeader>
      <FormGroup>
        <Grid container>
          <Grid item sm={6}>
            {columnNamesCol2.map((columnName) => (
              <FormControlLabel
                key={columnName}
                control={
                  <Checkbox name={columnName} onChange={() => onChange(columnName)} checked={columns[columnName]} />
                }
                label={t(`pages.adminProjects.table.${columnName}`)}
              />
            ))}
          </Grid>
          <Grid item sm={6}>
            {columnNamesCol1.map((columnName) => (
              <FormControlLabel
                key={columnName}
                control={
                  <Checkbox name={columnName} onChange={() => onChange(columnName)} checked={columns[columnName]} />
                }
                label={t(`pages.adminProjects.table.${columnName}`)}
              />
            ))}
          </Grid>
        </Grid>
      </FormGroup>
    </>
  );
};

export default SelectedColumnsFilters;
