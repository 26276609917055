import { User } from '../store/Auth/types';
import linksConstants from '../config/app/linksConstants';
import { DefaultRoleName } from '../store/AdminRoles/types';

export const getUserMainPage = (user: User) => {
  let defaultUrl = linksConstants.DASHBOARD.INDEX;

  if (user && user.type.name === DefaultRoleName.CONTENT_CONTRIBUTOR) {
    defaultUrl = linksConstants.STORY.INDEX;
  }

  const params = new URLSearchParams(window.location.search);
  const backUrl = params.get('back_url');

  if (backUrl === '/logout') {
    return defaultUrl;
  }

  return backUrl || defaultUrl;
};
