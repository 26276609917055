import React, { FunctionComponent } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { FilterHeader } from '../../AdminStyledComponents';
import { AdminProjectsFilters } from '../../../../../store/AdminProjects/types';
import { useTranslation } from 'react-i18next';

type AutomatedEmailFilterProps = {
  automatedEmail: AdminProjectsFilters['automatedEmail'];
  onChange: (checked: boolean) => void;
};

const AutomatedEmailFilter: FunctionComponent<AutomatedEmailFilterProps> = ({ automatedEmail, onChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <FilterHeader>{t('pages.adminProjects.filters.automatedEmails')}</FilterHeader>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox name="only" checked={automatedEmail} onChange={() => onChange(!automatedEmail)} />}
          label={t('pages.adminProjects.filters.onlyAutomatedEmails')}
        />
      </FormGroup>
    </>
  );
};

export default AutomatedEmailFilter;
