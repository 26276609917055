import React from 'react';
import { Slide, toast, ToastOptions } from 'react-toastify';
import { Check, InfoOutlined, Warning } from '@material-ui/icons';

const ToastMessage: React.FC<any> = ({ message, variantClassName }) => {
  return (
    <div className={`toastMessageBase ${variantClassName}`}>
      {variantClassName === 'toastSuccess' ? (
        <div>
          <Check style={{ width: 16, height: 16, fill: '#00D600' }} />
        </div>
      ) : variantClassName === 'toastError' ? (
        <Warning style={{ width: 24, height: 24, fill: '#E01E00' }} />
      ) : (
        <InfoOutlined style={{ width: 24, height: 24, fill: '#0061E0' }} />
      )}
      <div style={{ marginLeft: 16 }}>{message}</div>
    </div>
  );
};

function customToast(message: any, variantClassName: string, variantProgressClassName: string, options?: ToastOptions) {
  toast.configure({ limit: 1 });
  return toast(<ToastMessage message={message} variantClassName={variantClassName} />, {
    className: `toastBase`,
    progressClassName: `progressBase ${variantProgressClassName}`,
    autoClose: 3000,
    transition: Slide,
    position: 'bottom-center',
    ...options
  });
}

export const success = (message: string, options?: ToastOptions) =>
  customToast(message, 'toastSuccess', 'progressSuccess', options);

export const error = (message: any, options?: ToastOptions) =>
  customToast(message, 'toastError', 'progressError', options);

export const info = (message: string, options?: ToastOptions) =>
  customToast(message, 'toastInfo', 'progressInfo', options);

export default {
  success,
  error,
  info
};
