import { createOrderArray, httpRequest } from '../../../utils';
import {
  CreatePromotionClaimRequestBodyDto,
  DoAppPromotionClaimsIndexResponse,
  DoAppPromotionClaimStatus
} from './types';
import { doAppPromotionClaimsActions, doAppPromotionClaimsRequests } from './index';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../rootReducer';

export const indexRaw = (page: number, per: number, q: string | null, order: any) => {
  return httpRequest.runRequest<DoAppPromotionClaimsIndexResponse>(
    doAppPromotionClaimsRequests.index({
      requestParams: {
        page: page,
        per: per,
        q: q,
        order: createOrderArray(order)
      }
    })
  );
};

export const indexMineRaw = (
  page: number,
  per: number,
  q: string | null,
  order: any,
  promotionId?: string,
  status?: DoAppPromotionClaimStatus
) => {
  return httpRequest.runRequest<DoAppPromotionClaimsIndexResponse>(
    doAppPromotionClaimsRequests.indexMine({
      requestParams: {
        page: page,
        per: per,
        q: q,
        order: createOrderArray(order),
        status,
        promotionId
      }
    })
  );
};

export const indexMerchantPromotionClaims = (status?: DoAppPromotionClaimStatus, promotionId?: string) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  const { doAppMerchantPromotionClaims } = getState();

  try {
    dispatch(doAppPromotionClaimsActions.doAppPromotionClaimsIndexBegin());

    const response = await indexMineRaw(
      doAppMerchantPromotionClaims.pagination.page,
      doAppMerchantPromotionClaims.pagination.per,
      doAppMerchantPromotionClaims.pagination.q,
      doAppMerchantPromotionClaims.tableSearch.order,
      promotionId,
      status
    );

    dispatch(doAppPromotionClaimsActions.doAppPromotionClaimsIndexSuccess(response));
  } catch (e) {
    dispatch(doAppPromotionClaimsActions.doAppPromotionClaimsIndexFailed(e));
  }
};

export const createPromotionClaim = async (data: CreatePromotionClaimRequestBodyDto) => {
  return httpRequest.runRequest(
    doAppPromotionClaimsRequests.create({
      requestPayload: data
    })
  );
};

export const reject = (id: string, comment: string) => async () => {
  return await httpRequest.runRequest<void>(
    doAppPromotionClaimsRequests.reject({
      requestPayload: { comment },
      requestParams: { id }
    })
  );
};

export const unReject = (id: string) => async () => {
  return await httpRequest.runRequest<void>(
    doAppPromotionClaimsRequests.unReject({
      requestParams: { id }
    })
  );
};
