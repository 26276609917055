import React, { FunctionComponent } from 'react';
import styled, { keyframes } from 'styled-components';
import Loader from './Loader';
import { withTheme } from '@material-ui/core';

const LoadingModal: FunctionComponent<{ message: string }> = ({ message }) => {
  return (
    <LoadingModalWrapper>
      <Loader />
      <Message>{message}</Message>
    </LoadingModalWrapper>
  );
};

const fadeIn = () => keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const LoadingModalWrapper = withTheme(styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  animation: ${fadeIn()} 0.2s ease-in;
  animation-fill-mode: forwards;
  z-index: 90000;
`);

const Message = styled.div`
  margin: 2rem 0;
  font-size: 2rem;
`;

export default LoadingModal;
