import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import { Field, FieldProps, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Box, Button, Grid, InputAdornment, TextField } from '@material-ui/core';
import { MyAccountUpdateValues, User } from '../../../../store/Auth/types';
import app from '../../../../config/app/app';
import Toast from '../../../Shared/Toast/Toast';
import { authOperations } from '../../../../store/Auth';
import { administrativeRoleNames, AdminRole, DefaultRoleName } from '../../../../store/AdminRoles/types';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import getFormInputError from '../../../Shared/Forms/getFormInputError';
import { ErrorOutline } from '@material-ui/icons';
import { getRoleNameElement } from '../../../../utils';

type MyAccountProfileFormProps = {
  user: User;
  role: AdminRole;
};

const MyAccountProfileForm: FunctionComponent<MyAccountProfileFormProps> = ({ user, role }) => {
  const { t } = useTranslation();

  const saveUserAuthData = async (values: MyAccountUpdateValues, props: FormikHelpers<any>) => {
    try {
      await authOperations.updateAccount({
        firstName: values.firstName,
        lastName: values.lastName
      });
      Toast.success(t('notifications.setAuthUserData.success'));
      props.resetForm({ values });
    } catch (e) {
      Toast.error(t('notifications.setAuthUserData.error'));
    }
  };

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const isAdministrativeUserType = administrativeRoleNames.includes(user.type.name as DefaultRoleName);

  const renderUserAuthForm = (props: FormikProps<MyAccountUpdateValues>) => (
    <Form>
      <Grid container spacing={isSmall ? 0 : 3}>
        <Grid item xs={12} md={6}>
          <AdminTextInput t={t} name="email" section="myAccount" disabled />
          {isAdministrativeUserType && <AdminTextInput t={t} name="type" section="myAccount" disabled />}
          <AdminTextInput t={t} name="role" section="myAccount" disabled />
        </Grid>
        <Grid item xs={12} md={6}>
          <AdminTextInput t={t} name="firstName" section="myAccount" />
          <AdminTextInput t={t} name="lastName" section="myAccount" />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <Button
          style={{ marginTop: '1rem' }}
          color="secondary"
          variant="contained"
          onClick={props.submitForm}
          size="medium"
          type="submit"
          disabled={props.isSubmitting || !props.isValid || !props.dirty}
        >
          {t('common.save')}
        </Button>
      </Box>
    </Form>
  );

  return (
    <Formik
      initialValues={{
        email: user.email,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        type: t(`common.userTypes.${user.type.name}`),
        role: getRoleNameElement(t, role, true) as string
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().max(app.maxInputLength).email().required(),
        firstName: Yup.string().max(app.maxInputLength).required(),
        lastName: Yup.string().max(app.maxInputLength).required(),
        type: Yup.string().max(app.maxInputLength).required(),
        role: Yup.string().max(app.maxInputLength).required()
      })}
      onSubmit={saveUserAuthData}
    >
      {renderUserAuthForm}
    </Formik>
  );
};

export default MyAccountProfileForm;
