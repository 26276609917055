import React, { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, Button, Typography } from '@material-ui/core';
import { Row } from '../../../Shared/StyledComponents';
import styled from 'styled-components';
import app from '../../../../config/app/app';
import Toast from '../../../Shared/Toast/Toast';
import { authOperations } from '../../../../store/Auth';
import Loader from '../../../Shared/Loading/Loader';
import { useTypedSelector } from '../../../../utils';
import { isOrganizationOwner } from '../../../../utils/permissions';

type OrganizationLogoFormProps = {};

const OrganizationLogoForm: FunctionComponent<OrganizationLogoFormProps> = () => {
  const { t } = useTranslation();
  const mainLogoUploadRef = useRef<HTMLInputElement | null>(null);
  const [mainLogoUrl, setMainLogoUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const authState = useTypedSelector((state) => state.auth);
  const currentOrganizationId = authState.organizationId;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await authOperations.getOrganizationLogo();
        setMainLogoUrl(data.url);
      } catch (e) {
        setMainLogoUrl(null);
        console.log('Could not fetch organization logo', e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentOrganizationId]);

  const uploadMainLogo = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const extension = file.name.split('.').pop();

      if (!app.validLogoExtensions.includes((extension || '').toLowerCase())) {
        Toast.error(t('notifications.uploadLogo.invalidExtension'));
        return;
      }

      if (file.size > app.maxLogoSize) {
        Toast.error(t('notifications.uploadLogo.fileTooBig'));
        return;
      }

      try {
        const data = await authOperations.uploadMainLogo(file);
        setMainLogoUrl(data.url);

        Toast.success(t('notifications.setBusinessUserData.uploadSuccessful'));

        if (mainLogoUploadRef.current) {
          mainLogoUploadRef.current.value = '';
        }
      } catch (err) {
        if (mainLogoUploadRef.current) {
          mainLogoUploadRef.current.value = '';
        }
        Toast.error(t('notifications.setBusinessUserData.uploadError'));
      }
    }
  };

  const removeMainLogo = async () => {
    try {
      await authOperations.removeMainLogo();
      setMainLogoUrl(null);

      Toast.success(t('notifications.setBusinessUserData.removeSuccessful'));
    } catch (err) {
      Toast.error(t('notifications.setBusinessUserData.removeError'));
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <Typography variant="body2" color="primary" gutterBottom>
            {t('pages.myAccount.mainLogo')}
          </Typography>
          <Box textAlign="center">
            <Button
              disabled={!isOrganizationOwner(authState)}
              color="secondary"
              variant="contained"
              size="large"
              onClick={() => mainLogoUploadRef.current && mainLogoUploadRef.current.click()}
            >
              {t('pages.myAccount.uploadMainLogo')}
            </Button>
            <UploadInfo>
              <div>{t('pages.myAccount.uploadMainLogoInfoLine1')}</div>
              <div>{t('pages.myAccount.uploadMainLogoInfoLine2')}</div>
              <div>{t('pages.myAccount.uploadMainLogoInfoLine3')}</div>
            </UploadInfo>
          </Box>

          <Row>
            {mainLogoUrl && (
              <Box display="flex" flexDirection="column" alignItems="center">
                <LogoImage src={mainLogoUrl} />
                <Box p={1}>
                  <Button
                    disabled={!isOrganizationOwner(authState)}
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={removeMainLogo}
                    startIcon={<DeleteIcon />}
                  >
                    {t('pages.myAccount.removeMainLogo')}
                  </Button>
                </Box>
              </Box>
            )}
          </Row>
          <input
            type="file"
            id="themeUploader"
            accept="image/jpeg,image/jpg,image/png"
            ref={mainLogoUploadRef}
            style={{ display: 'none' }}
            onChange={uploadMainLogo}
          />
        </>
      )}
    </>
  );
};

const LogoImage = styled.img`
  max-width: 100%;
`;

const UploadInfo = styled.span`
  display: block;
  opacity: 0.7;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  font-style: italic;
  line-height: 0.9rem;
`;

export default OrganizationLogoForm;
