import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { StoryChannel, StoryChannelPublishError } from '../../../../store/Stories/types';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import { storiesOperations } from '../../../../store/Stories';

type StoryChannelPublishFailedWindowProps = {
  open: boolean;
  storyId: string;
  channel: StoryChannel | null;
  onCloseClick: () => void;
  onSuccessfulSubmit: () => void;
  fullScreenOnMobile: boolean;
};

const StoryChannelPublishFailedWindow: FunctionComponent<StoryChannelPublishFailedWindowProps> = ({
  storyId,
  onCloseClick,
  open,
  onSuccessfulSubmit,
  channel
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [errorDetails, setErrorDetails] = useState<StoryChannelPublishError[]>([]);

  useEffect(() => {
    const fetchErrorDetails = async () => {
      if (storyId && channel) {
        const response = await storiesOperations.getChannelPublishErrors(storyId, channel.id);
        setErrorDetails(response.data);
      }
    };
    if (open) {
      setErrorDetails([]);
      fetchErrorDetails();
    } else {
      setErrorDetails([]);
    }
  }, [open]);

  if (!storyId || !channel) return null;

  const onSubmit = async () => {
    try {
      await onSuccessfulSubmit();
      onCloseClick();
      Toast.success(t('notifications.story.storyBackInProgress'));
    } catch (e) {
      Toast.error(t('notifications.common.storyBackInProgressError'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.story.windows.publishFailed.header')}
      okButtonText={t('pages.story.windows.publishFailed.submit')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.story.windows.publishFailed.content')}</Row>
      {errorDetails.map((error) => (
        <Row key={error.id}>
          <Strong>{error.message}</Strong>
        </Row>
      ))}
    </ModalConfirmationWindow>
  );
};

export default StoryChannelPublishFailedWindow;
