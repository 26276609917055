import React, { FunctionComponent, useEffect, useState } from 'react';
import linksConstants from '../../../../config/app/linksConstants';
import { Redirect } from 'react-router-dom';
import { PageContainer, PageTitle } from '../../PageStyledComponents';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { authOperations } from '../../../../store/Auth';

const SignOutPage: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const signOut = async () => {
      await dispatch(authOperations.logout());
      setLoading(false);
    };
    signOut();
  }, []);

  return (
    <PageContainer>
      <PageTitle>{t('pages.auth.signOut.title')}</PageTitle>
      {!loading && <Redirect to={linksConstants.AUTH.LOGIN} />}
    </PageContainer>
  );
};

export default SignOutPage;
