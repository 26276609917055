import React from 'react';
import { useCallback, useState } from 'react';
import CheckboxList from './CheckboxList';
import { updateItemStates } from './updateItemStates';
import { AdminOrganization } from '../../../../../store/AdminOrganizations/types';
import { OrganizationTreeStructure } from '../../../../../store/MyOrganization/types';

type OrganizationTreeProps = {
  items: AdminOrganization[];
  showCheckboxes?: boolean;
  selectedIds?: string[];
  setOrganizations?: React.Dispatch<React.SetStateAction<string[]>>;
};

export enum CheckboxState {
  UNCHECKED,
  CHECKED
  // INDETERMINATE
}

export type ItemState = {
  id: string;
  state: CheckboxState;
};

export const buildOrganizationStructure = (data: OrganizationTreeStructure) => {
  const orgs: AdminOrganization[] = [];

  const extractOrganizations = (obj: OrganizationTreeStructure) => {
    for (const key in obj) {
      if (key === 'org') {
        orgs.push(obj.org);
      } else if (key === 'children') {
        obj.children.forEach((child) => {
          extractOrganizations(child);
        });
      }
    }
  };

  extractOrganizations(data);
  return orgs;
};

const OrganizationTree: React.FC<OrganizationTreeProps> = ({
  items,
  showCheckboxes = true,
  selectedIds = [],
  setOrganizations
}) => {
  const [itemStates, setItemStates] = useState<ItemState[]>(
    items.map((i) => ({
      id: i.id,
      state: selectedIds.find((id) => id === i.id) ? CheckboxState.CHECKED : CheckboxState.UNCHECKED
    }))
  );
  const getStateForId = useCallback(
    (id: string) => {
      const itemState = itemStates.find((i) => i.id === id);
      return itemState ? itemState.state : CheckboxState.UNCHECKED;
    },
    [itemStates]
  );
  const clickHandler = useCallback(
    (id) => {
      const updatedItemStates = updateItemStates(itemStates, items, id);
      if (setOrganizations) {
        setOrganizations(updatedItemStates.filter((item) => item.state === 1).map((org) => org.id));
      }
      setItemStates(updatedItemStates);
    },
    [itemStates]
  );

  return (
    <CheckboxList items={items} onClick={clickHandler} getStateForId={getStateForId} showCheckboxes={showCheckboxes} />
  );
};

export default OrganizationTree;
