import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { Row } from '../../../../Shared/StyledComponents';
import { PageContentPaper, PageContentPaperHeading } from '../../../PageStyledComponents';
import SaveIcon from '@material-ui/icons/Save';
import {
  OrganizationMailingListSizeReportData,
  OrganizationMailingListSizeReportResponse
} from '../../../../../store/AdminReports/types';
import { adminReportsOperations } from '../../../../../store/AdminReports';
import { extent, scaleLinear, scaleBand } from 'd3';
import './Chart/Chart.scss';
import { AxisBottom } from './Chart/AxisBottom';
import { AxisLeft } from './Chart/AxisLeft';
import { BarChart } from './Chart/BarChart';

type OrganizationMailingListSizeReportProps = {};

const OrganizationMailingListSizeReport: FunctionComponent<OrganizationMailingListSizeReportProps> = () => {
  const { t } = useTranslation();
  const [reportData, setReportData] = useState<OrganizationMailingListSizeReportResponse['data']>([]);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const width = 960;
  const height = 500;
  const margin = { top: 40, right: 30, bottom: 120, left: 50 };

  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  const organizationsColor = '#e3ba22';

  useEffect(() => {
    const fetchData = async () => {
      setError(false);
      try {
        const response = await adminReportsOperations.organizationMailingListSize('json');

        if (response) {
          setReportData(response.data);
        }
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    };

    fetchData();
  }, []);

  if (loading && !reportData) {
    return <>Loading...</>;
  }

  const xValue = (d: OrganizationMailingListSizeReportData) =>
    `${d.organization} ${d.accountNumber ? `(${d.accountNumber})` : ''}`;
  const yBarValue = (d: OrganizationMailingListSizeReportData) => d.subscribers;

  const yExtent = extent(reportData, yBarValue);

  const xScale = scaleBand().domain(reportData.map(xValue)).range([0, innerWidth]).padding(0.5);

  const yScale = scaleLinear()
    .domain([0, yExtent[1] ?? 0])
    .range([innerHeight, 0])
    .nice();

  return (
    <PageContentPaper>
      <PageContentPaperHeading>
        <Typography variant="h6" gutterBottom>
          {t('pages.adminReports.organizationMailingListSize.title')}
        </Typography>
        <Button
          color={'secondary'}
          variant={'contained'}
          size="medium"
          onClick={async () => await adminReportsOperations.organizationMailingListSize('csv')}
        >
          <SaveIcon /> {t('pages.adminReports.downloadCsvReport')}
        </Button>
      </PageContentPaperHeading>
      <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
      {error && <div>Something went wrong...</div>}
      {!error && (
        <Row style={{ maxWidth: 800 }}>
          {!loading && (
            <svg viewBox={`0 0 ${width} ${height}`}>
              <g transform={`translate(${margin.left},${margin.top})`}>
                <AxisBottom xScale={xScale} innerHeight={innerHeight} tickOffset={7} textWrap />
                <AxisLeft yScale={yScale} innerWidth={innerWidth} tickOffset={7} />
                <g className="marks">
                  <BarChart
                    data={reportData}
                    xValue={xValue}
                    xScale={xScale}
                    yScale={yScale}
                    yBarValue={yBarValue}
                    color={organizationsColor}
                  />
                </g>
              </g>
            </svg>
          )}
        </Row>
      )}
    </PageContentPaper>
  );
};

export default OrganizationMailingListSizeReport;
