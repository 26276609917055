import React from 'react';
import { ReactComponent as Structure12 } from '../../../../../../assets/dnd/structure-12.svg';
import { ReactComponent as Structure66 } from '../../../../../../assets/dnd/structure-6-6.svg';
import { ReactComponent as Structure444 } from '../../../../../../assets/dnd/structure-4-4-4.svg';
import { ReactComponent as Structure3333 } from '../../../../../../assets/dnd/structure-3-3-3-3.svg';
import { ReactComponent as Structure48 } from '../../../../../../assets/dnd/structure-4-8.svg';
import { ReactComponent as Structure84 } from '../../../../../../assets/dnd/structure-8-4.svg';
import { ReactComponent as Structure39 } from '../../../../../../assets/dnd/structure-3-9.svg';
import { ReactComponent as Structure93 } from '../../../../../../assets/dnd/structure-9-3.svg';

export type StructureIconProps = {
  sizes: number[];
};

export const StructureIcon: React.FC<StructureIconProps> = ({ sizes }) => {
  const iconParam = sizes.join('-');

  switch (iconParam) {
    case '12':
      return <Structure12 />;
    case '6-6':
      return <Structure66 />;
    case '4-4-4':
      return <Structure444 />;
    case '3-3-3-3':
      return <Structure3333 />;
    case '4-8':
      return <Structure48 />;
    case '8-4':
      return <Structure84 />;
    case '3-9':
      return <Structure39 />;
    case '9-3':
      return <Structure93 />;
    default:
      return <></>;
  }
};
