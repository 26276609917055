import { createOrderArray, httpRequest } from '../../utils';
import { AdminOnlineUsersIndexResponse } from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { adminOnlineUsersActions, adminOnlineUsersRequests } from './index';

export const index = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { adminOnlineUsers } = getState();

  try {
    dispatch(adminOnlineUsersActions.adminOnlineUsersIndexBegin());

    const response = await httpRequest.runRequest<AdminOnlineUsersIndexResponse>(
      adminOnlineUsersRequests.index({
        requestParams: {
          q: adminOnlineUsers.pagination.q,
          order: createOrderArray(adminOnlineUsers.tableSearch.order)
        }
      })
    );

    dispatch(adminOnlineUsersActions.adminOnlineUsersIndexSuccess(response));
  } catch (e) {
    dispatch(adminOnlineUsersActions.adminOnlineUsersIndexFailed(e));
  }
};
