import { TFunction } from 'i18next';
import {
  BulkAiContentGenerationChannelDoneAction,
  BulkAiContentGenerationDoneAction,
  BulkAiContentGenerationStartedAction,
  GetStoriesResponse,
  RemoveStoryAction,
  SetAiGenerationAction,
  StoriesActionsConstants,
  StoriesIndexActionBEGIN,
  StoriesIndexActionFAILED,
  StoriesIndexActionSUCCESS,
  StoryChannel,
  StoryContentContributorsIndexActionBEGIN,
  StoryContentContributorsIndexActionFAILED,
  StoryContentContributorsIndexActionSUCCESS
} from './types';

export const storiesIndexBegin = (): StoriesIndexActionBEGIN => ({
  type: StoriesActionsConstants.INDEX_BEGIN
});

export const storiesIndexSuccess = (payload: GetStoriesResponse): StoriesIndexActionSUCCESS => ({
  type: StoriesActionsConstants.INDEX_SUCCESS,
  payload
});

export const storiesIndexFailed = (error: any): StoriesIndexActionFAILED => ({
  type: StoriesActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const storyContentContributorsIndexBegin = (): StoryContentContributorsIndexActionBEGIN => ({
  type: StoriesActionsConstants.INDEX_CONTRIBUTORS_BEGIN
});

export const storyContentContributorsIndexSuccess = (
  payload: GetStoriesResponse
): StoryContentContributorsIndexActionSUCCESS => ({
  type: StoriesActionsConstants.INDEX_CONTRIBUTORS_SUCCESS,
  payload
});

export const storyContentContributorsIndexFailed = (error: any): StoryContentContributorsIndexActionFAILED => ({
  type: StoriesActionsConstants.INDEX_CONTRIBUTORS_FAILED,
  payload: {
    error
  }
});

export const removeStory = (storyId: string): RemoveStoryAction => ({
  type: StoriesActionsConstants.REMOVE_STORY,
  payload: storyId
});

export const shareStory = (storyId: string): RemoveStoryAction => ({
  type: StoriesActionsConstants.REMOVE_STORY,
  payload: storyId
});

export const bulkAiContentGenerationStarted = (storyId: string): BulkAiContentGenerationStartedAction => ({
  type: StoriesActionsConstants.BULK_AI_CONTENT_GENERATION_STARTED,
  payload: storyId
});

export const bulkAiContentGenerationChannelDone = (
  storyId: string,
  channel: StoryChannel
): BulkAiContentGenerationChannelDoneAction => ({
  type: StoriesActionsConstants.BULK_AI_CONTENT_GENERATION_CHANNEL_DONE,
  payload: { storyId, channel }
});

export const bulkAiContentGenerationDone = (storyId: string, t: TFunction): BulkAiContentGenerationDoneAction => ({
  type: StoriesActionsConstants.BULK_AI_CONTENT_GENERATION_DONE,
  payload: { storyId, t }
});

export const setAiGeneration = (storyId: string, channelsDone?: string[]): SetAiGenerationAction => ({
  type: StoriesActionsConstants.SET_AI_GENERATION,
  payload: { storyId, channelsDone }
});
