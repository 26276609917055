import { ColumnOrderQuery } from '../../components/Shared/DataTable/types';

export enum TableSearchStoreModule {
  DASHBOARD_PROJECTS = '/dashboard',
  STORY = '/story',
  STORY_CONTENT_CONTRIBUTORS = '/story_contributors',
  PRINT_PROJECTS = '/print_projects',
  EMAIL_PROJECTS = '/email_projects',
  ADMIN_USERS = '/users',
  SUBSCRIBERS = '/subscribers',
  ADMIN_ROLES = '/roles_administration',
  ADMIN_KEYWORDS = '/keywords_administration',
  ADMIN_ONLINE_USERS = '/online_users',
  ADMIN_KEYWORD_GROUPS = '/keyword_groups_administration',
  ADMIN_PASSWORD_POLICIES = '/password_policies_administration',
  ADMIN_ORGANIZATIONS = '/organizations_administration',
  ADMIN_ORGANIZATION_USERS = '/organization_users',
  MY_ORGANIZATION_USERS = '/my_organization',
  ADMIN_PRINT_TEMPLATES = '/print_templates_administration',
  ADMIN_EMAIL_TEMPLATES = '/email_templates_administration',
  MAILING_LISTS = '/mailing_lists',
  ADMIN_MAILING_LISTS = '/mailing_lists_dashboard',
  DESIGN_PROJECTS = '/design_projects',
  ADMIN_PROJECTS = '/projects_administration',
  SHARING_CENTER = '/sharing_center',
  CAMPAIGNS = '/campaigns',
  DO_APP_MERCHANTS = '/doapp/merchants',
  DO_APP_MERCHANT_PROMOTIONS = '/doapp/merchant_promotions',
  DO_APP_MERCHANT_PROMOTION_CLAIMS = '/doapp/merchant_promotion_claims',
  DO_APP_MERCHANT_CREDIT_TRANSACTIONS = '/doapp/merchant_credit_transactions',
  DO_APP_MERCHANT_SUBSCRIBERS = '/doapp/merchant_subscribers'
}
export type OrderColumnAction<Entity> = {
  type: TableSearchActionsConstants.ORDER_COLUMN;
  module: TableSearchStoreModule;
  payload: OrderColumnPayload<Entity>;
};

export type OrderColumnPayload<Entity> = {
  order: ColumnOrderQuery<Entity>;
};

export type ClearAllOrderAction = {
  type: TableSearchActionsConstants.CLEAR_ALL_ORDERS;
  module: TableSearchStoreModule;
};

export enum TableSearchActionsConstants {
  ORDER_COLUMN = 'TABLE_SEARCH/ORDER_COLUMN',
  CLEAR_ALL_ORDERS = 'TABLE_SEARCH/CLEAR_ALL_ORDERS'
}

export type TableSearchActionType<Entity> = OrderColumnAction<Entity> | ClearAllOrderAction;
