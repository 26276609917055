import {
  DoAppMerchantsActionsConstants,
  DoAppMerchantsIndexActionBEGIN,
  DoAppMerchantsIndexActionFAILED,
  DoAppMerchantsIndexActionSUCCESS,
  DoAppMerchantsIndexResponse
} from './types';

export const doAppMerchantsIndexBegin = (): DoAppMerchantsIndexActionBEGIN => ({
  type: DoAppMerchantsActionsConstants.INDEX_BEGIN
});

export const doAppMerchantsIndexSuccess = (payload: DoAppMerchantsIndexResponse): DoAppMerchantsIndexActionSUCCESS => ({
  type: DoAppMerchantsActionsConstants.INDEX_SUCCESS,
  payload
});

export const doAppMerchantsIndexFailed = (error: any): DoAppMerchantsIndexActionFAILED => ({
  type: DoAppMerchantsActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});
