import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { adminMailingListsOperations } from '../../../../../store/AdminMailingLists';
import Toast from '../../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import { AdminMailingList } from '../../../../../store/AdminMailingLists/types';

type DeleteAdminMailingListWindowProps = {
  open: boolean;
  adminMailingList: AdminMailingList | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const DeleteAdminMailingListWindow: FunctionComponent<DeleteAdminMailingListWindowProps> = ({
  adminMailingList,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!adminMailingList) return null;

  const onSubmit = async () => {
    try {
      await dispatch(adminMailingListsOperations.deleteAdminMailingList(adminMailingList.id));
      await dispatch(adminMailingListsOperations.index());
      onCloseClick();
      Toast.success(t('notifications.adminMailingLists.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminMailingLists.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminMailingLists.windows.deleteAdminMailingList')}
      okButtonText={t('pages.adminMailingLists.windows.delete')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.adminMailingLists.windows.deleteAdminMailingListContent')}</Row>
      <Row>
        <Strong>{adminMailingList.name}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteAdminMailingListWindow;
