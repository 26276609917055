import React, { FunctionComponent } from 'react';
import FullPageRoutesContainer from './FullPageRoutesContainer';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core';
import NewVersionNotification from '../../Shared/NewVersionNotification';

type FullPageLayoutProps = {};

const FullPageLayout: FunctionComponent<FullPageLayoutProps> = () => {
  return (
    <FullPageContainer>
      <NewVersionNotification />
      <ContentArea>
        <FullPageRoutesContainer />
      </ContentArea>
    </FullPageContainer>
  );
};

const FullPageContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: 'system-update' 'content';
  height: 100%;
  overflow: hidden;
`;

const ContentArea = withTheme(styled.div`
  grid-area: content;
  height: 100%;
  color: ${({ theme }) => theme.palette.primary.main};
  overflow: hidden;
`);

export default FullPageLayout;
