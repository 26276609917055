import { createOrderArray, httpRequest } from '../../utils';
import { saveAs } from 'file-saver';
import {
  AdminCreateEmailTemplateValues,
  AdminEmailTemplate,
  AdminEmailTemplatesIndexResponse,
  AdminUpdateEmailTemplateValues
} from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { adminEmailTemplatesActions, adminEmailTemplatesRequests } from './index';
import { createUrl } from '../../utils/httpRequest';
import { AdminKeyword, AdminKeywordGroup } from '../AdminKeywords/types';
import { ProjectModelV2 } from '../../components/Pages/Editors/EmailDndEditor/types';
import { EmailProject } from '../EmailProjects/types';

export const index = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { adminEmailTemplates } = getState();

  try {
    dispatch(adminEmailTemplatesActions.templatesIndexBegin());

    const response = await httpRequest.runRequest<AdminEmailTemplatesIndexResponse>(
      adminEmailTemplatesRequests.index({
        requestParams: {
          page: adminEmailTemplates.pagination.page,
          per: adminEmailTemplates.pagination.per,
          q: adminEmailTemplates.pagination.q,
          order: createOrderArray(adminEmailTemplates.tableSearch.order)
        }
      })
    );

    dispatch(adminEmailTemplatesActions.templatesIndexSuccess(response));
  } catch (e) {
    dispatch(adminEmailTemplatesActions.templatesIndexFailed(e));
  }
};

export const getFirstEmailTemplate = async () => {
  const response = await httpRequest.runRequest<AdminEmailTemplatesIndexResponse>(
    adminEmailTemplatesRequests.index({
      requestParams: {
        page: 1,
        per: 1,
        q: null,
        order: ['order=name,asc']
      }
    })
  );

  return response.data[0] || null;
};

export const getAll = async () => {
  const response = await httpRequest.runRequest<{ data: AdminEmailTemplate[] }>(adminEmailTemplatesRequests.getAll());

  return response.data;
};

export const create = (values: AdminCreateEmailTemplateValues) => async () => {
  return await httpRequest.runRequest<void>(
    adminEmailTemplatesRequests.create({
      requestPayload: values
    })
  );
};

export const update = (id: string, values: AdminUpdateEmailTemplateValues) => async () => {
  return await httpRequest.runRequest<void>(
    adminEmailTemplatesRequests.update({
      requestPayload: values,
      requestParams: { id }
    })
  );
};

export const deleteEmailTemplate = (templateId: string) => async () => {
  return await httpRequest.runRequest<void>(
    adminEmailTemplatesRequests.deleteEmailTemplate({
      requestParams: { id: templateId }
    })
  );
};

export const downloadEmailTemplateZip = async (templateId: string) => {
  const url = createUrl(
    adminEmailTemplatesRequests.downloadEmailTemplate({
      requestParams: { id: templateId }
    }).query,
    { id: templateId }
  );
  await saveAs(url);
};

export const uploadEmailTemplateZip = async (
  zipFile: File,
  template: AdminEmailTemplate,
  onProgress: (progress: number) => void
) => {
  const formData = new FormData();

  formData.append('file', zipFile);
  formData.append('filename', zipFile.name);

  return await httpRequest.runRequest<void>({
    ...adminEmailTemplatesRequests.uploadEmailTemplateZip({
      requestParams: { id: template.id },
      requestPayload: formData
    }),
    onUploadProgress: (progressEvent) => {
      const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
      onProgress(progress);
    }
  });
};

export const getEmailTemplateThumbnail = async (templateId: string) => {
  return await httpRequest.runRequest<{ url: string }>(
    adminEmailTemplatesRequests.getEmailTemplateThumbnail({
      requestParams: { id: templateId }
    })
  );
};
export const show = async (templateId: string) => {
  return await httpRequest.runRequest<AdminEmailTemplate>(
    adminEmailTemplatesRequests.show({
      requestParams: { templateId }
    })
  );
};

export const uploadEmailTemplateThumbnail = async (
  imageFile: File,
  template: AdminEmailTemplate,
  onProgress: (progress: number) => void
) => {
  const formData = new FormData();

  formData.append('file', imageFile);

  return await httpRequest.runRequest<void>({
    ...adminEmailTemplatesRequests.uploadEmailTemplateThumbnail({
      requestParams: { id: template.id },
      requestPayload: formData
    }),
    onUploadProgress: (progressEvent) => {
      const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
      onProgress(progress);
    }
  });
};

export const addKeywordToEmailTemplate = (template: AdminEmailTemplate, keyword: AdminKeyword) => async (
  dispatch: Dispatch
) => {
  const updatedEmailTemplate = await httpRequest.runRequest<AdminEmailTemplate>(
    adminEmailTemplatesRequests.addKeywordToEmailTemplate({
      requestParams: { templateId: template.id },
      requestPayload: { keywordId: keyword.id }
    })
  );
  dispatch(adminEmailTemplatesActions.keywordAdded(updatedEmailTemplate));
};

export const addKeywordGroupToEmailTemplate = (template: AdminEmailTemplate, keywordGroup: AdminKeywordGroup) => async (
  dispatch: Dispatch
) => {
  const updatedEmailTemplate = await httpRequest.runRequest<AdminEmailTemplate>(
    adminEmailTemplatesRequests.addKeywordGroupToEmailTemplate({
      requestParams: { templateId: template.id },
      requestPayload: { keywordGroupId: keywordGroup.id }
    })
  );
  dispatch(adminEmailTemplatesActions.keywordGroupAdded(updatedEmailTemplate));
};

export const removeKeywordFromEmailTemplate = (template: AdminEmailTemplate, keyword: AdminKeyword) => async (
  dispatch: Dispatch
) => {
  const updatedEmailTemplate = await httpRequest.runRequest<AdminEmailTemplate>(
    adminEmailTemplatesRequests.removeKeywordFromEmailTemplate({
      requestParams: { templateId: template.id },
      requestPayload: { keywordId: keyword.id }
    })
  );
  dispatch(adminEmailTemplatesActions.keywordRemoved(updatedEmailTemplate));
};

export const removeKeywordGroupFromEmailTemplate = (
  template: AdminEmailTemplate,
  keywordGroup: AdminKeywordGroup
) => async (dispatch: Dispatch) => {
  const updatedEmailTemplate = await httpRequest.runRequest<AdminEmailTemplate>(
    adminEmailTemplatesRequests.removeKeywordGroupFromEmailTemplate({
      requestParams: { templateId: template.id },
      requestPayload: { keywordGroupId: keywordGroup.id }
    })
  );
  dispatch(adminEmailTemplatesActions.keywordGroupRemoved(updatedEmailTemplate));
};

export const getTemplateModel = async (templateId: string) => {
  return await httpRequest.runRequest<{ model: ProjectModelV2; template: AdminEmailTemplate }>(
    adminEmailTemplatesRequests.getTemplateModel({
      requestParams: { templateId }
    })
  );
};

export const saveModel = async (templateId: string, projectModel: ProjectModelV2) => {
  return await httpRequest.runRequest<EmailProject>(
    adminEmailTemplatesRequests.saveModel({
      requestParams: { templateId },
      requestPayload: { projectModel }
    })
  );
};

export const setAutomatedDefaultTemplate = async (templateId: string) => {
  return await httpRequest.runRequest<AdminEmailTemplate>(
    adminEmailTemplatesRequests.setAutomatedDefaultTemplate({
      requestParams: { templateId }
    })
  );
};

export const copyTemplate = async (templateId: string, name: string) => {
  return await httpRequest.runRequest<AdminEmailTemplate>(
    adminEmailTemplatesRequests.copyTemplate({ requestParams: { templateId }, requestPayload: { name } })
  );
};
