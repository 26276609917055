import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import { FullSizePageContainer } from '../../PageStyledComponents';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../Shared/Layout/PageHeader';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Editor } from './Partials/Editor';
import { RouteComponentProps } from 'react-router';
import nes from '@hapi/nes/lib/client';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import { v4 } from 'uuid';
import { connectToProjectChannel } from '../../../../utils/webSocket';
import {
  closeFullscreen,
  createNetworkErrorObject,
  openFullscreen,
  toCamelCase,
  useTypedSelector
} from '../../../../utils';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import { printProjectsOperations } from '../../../../store/PrintProjects';
import Toast from '../../../Shared/Toast/Toast';
import { MyOrganizationSwatchColor, MyOrganizationSwatchGroup } from '../../../../store/MyOrganization/types';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import { EmailProject } from '../../../../store/EmailProjects/types';
import { DesignerStory } from '../../../../store/PrintProjects/types';
import Loader from '../../../Shared/Loading/Loader';
import { sanitizeToV2Model } from './Utils';
import { AdPlacementStatus, PlacedAd, ProjectModelV1, ProjectModelV2 } from './types';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import { hasPermission } from '../../../../utils/permissions';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ProjectAlreadyCheckedIn from '../Windows/ProjectAlreadyCheckedIn';
import { Fade, Popper } from '@material-ui/core';
import ProjectNotes from '../Common/ProjectNotes';
import styled from 'styled-components';
import { AdSpacesConstraintStatus } from './Partials/Mechanics/AdSpacesConstraintStatus';
import app from '../../../../config/app/app';
import SaveBeforeExitWindow from '../Windows/SaveBeforeExitWindow';
import { preventDragEvent } from './Utils/Uploaders';
import InputChangeOnBlur from '../../../Shared/Forms/InputChangeOnBlur';
import { storiesOperations } from '../../../../store/Stories';
import { Story, StoryChannelType } from '../../../../store/Stories/types';
import { DamSystemName } from '../../../../store/SystemSettings/types';

type EmailDndEditorPageProps = RouteComponentProps<{ projectId: string }> & {};

let autoSaveTimeout: null | number = null;

const EmailDndEditorPage: FunctionComponent<EmailDndEditorPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [previewing, setPreviewing] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [contentWidth, setContentWidth] = useState<string | null>(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [autoSavedIndicator, setAutoSavedIndicator] = useState(true);
  const [damStatus, setDamStatus] = useState<{ damActive: boolean; system: DamSystemName | null }>({
    damActive: false,
    system: null
  });
  const [projectAlreadyCheckedInErrorMessage, setProjectAlreadyCheckedInErrorMessage] = useState('');
  const [swatchesGroups, setSwatchesGroups] = useState<MyOrganizationSwatchGroup[]>([]);
  const [colors, setColors] = useState<MyOrganizationSwatchColor[]>([]);
  const [selectedColorSwatches, setSelectedColorSwatches] = useState<{ [key: string]: MyOrganizationSwatchColor }>({});
  const [notesAnchorEl, setNotesAnchorEl] = useState(null);
  const [
    projectAlreadyCheckedInWindowOpen,
    onProjectAlreadyCheckedInWindowOpen,
    onProjectAlreadyCheckedInWindowClose
  ] = useOpenHandler();
  const [projectModel, setProjectModel] = useState<ProjectModelV2 | null>(null);
  const [project, setProject] = useState<EmailProject | null>(null);
  const [designerStories, setDesignerStories] = useState<DesignerStory[]>([]);
  const [storyList, setStoryList] = useState<Story[]>([]);
  const [saveBeforeExitWindowOpen, onSaveBeforeExitWindowOpen, onSaveBeforeExitWindowClose] = useOpenHandler();
  const [innerAdjustmentEnabled, setInnerAdjustmentEnabled] = useState<boolean>(false);

  const authData = useTypedSelector((state) => state.auth);
  const { role, user } = authData;

  const projectId = match.params.projectId;

  const backButton = {
    onClick: () => {
      if (!autoSavedIndicator) {
        return onSaveBeforeExitWindowOpen();
      }
      dispatch(push(linksConstants.DASHBOARD.INDEX));
    },
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const onProjectModelUpdate = (updatedModel: ProjectModelV2) => {
    setAutoSavedIndicator(false);
    setProjectModel(updatedModel);

    if (autoSaveTimeout) {
      clearTimeout(autoSaveTimeout);
    }
    autoSaveTimeout = setTimeout(
      () => saveProject({ inBackground: true, forcedModel: updatedModel }),
      app.emailProjectAutoSaveTimeout
    );
  };

  const saveProject = useCallback(
    async ({ inBackground, forcedModel }: { inBackground: boolean; forcedModel?: ProjectModelV2 }) => {
      if (!inBackground) {
        setSaving(true);
      }

      try {
        if (forcedModel || projectModel) {
          const modelToUse = forcedModel || projectModel;
          if (modelToUse) {
            await emailProjectsOperations.setProjectModel(projectId, {
              ...modelToUse,
              colorSwatches: selectedColorSwatches
            });

            setAutoSavedIndicator(true);
          }
        }

        if (!inBackground) {
          Toast.success(t('notifications.projectSave.success'));
          setSaving(false);
        }
        return true;
      } catch (e) {
        if (!inBackground) {
          Toast.error(t('notifications.projectSave.error'));
        }
        setSaving(false);
        throw 'project_save_error';
      }
    },
    [projectModel, selectedColorSwatches]
  );

  const openNotes = (event: any) => {
    setNotesAnchorEl(notesAnchorEl ? null : event.currentTarget);
  };

  const toggleFullscreen = () => {
    if (fullscreen) {
      closeFullscreen();
      setFullscreen(false);
    } else {
      openFullscreen();
      setFullscreen(true);
    }
  };

  const applyColor = (swatchGroup: MyOrganizationSwatchGroup, color: MyOrganizationSwatchColor | null) => {
    try {
      const elements = document.getElementsByClassName(swatchGroup.name);
      if (color) {
        setSelectedColorSwatches({
          ...selectedColorSwatches,
          [swatchGroup.name]: color
        });
      } else {
        const updatedSwatches = {
          ...selectedColorSwatches
        };

        delete updatedSwatches[swatchGroup.name];

        setSelectedColorSwatches(updatedSwatches);

        if (elements) {
          for (const item of elements) {
            if (item instanceof HTMLElement) {
              const defaultColor = item.dataset[`${swatchGroup.name}Default`];
              if (defaultColor) {
                item.style[toCamelCase(swatchGroup.cssParam) as any] = defaultColor;
              }
            }
          }
        }
      }
    } catch (e) {
      console.warn('Could not apply color to elements', e);
    }
  };

  const previewProject = async () => {
    if (project) {
      setPreviewing(true);
      setSaving(true);
      try {
        await saveProject({ inBackground: true });
        setSaving(false);

        const data = await emailProjectsOperations.prepareFinalPreview(project);
        setPreviewUrl(data.url);
        setContentWidth(data.contentWidth.toString());
        setPreviewing(false);
      } catch (e) {
        setPreviewing(false);
        setSaving(false);
      }
    }
  };

  const rightActionButtons = [
    {
      onClick: openNotes,
      label: t('common.notes'),
      icon: <CommentOutlinedIcon />,
      variant: 'outlined' as const,
      id: 'notes',
      disabled: !hasPermission(role, ['projectNotesView'])
    },
    {
      onClick: toggleFullscreen,
      label: t('common.fullscreen'),
      icon: fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />,
      variant: 'outlined' as const,
      id: 'fullscreen'
    },
    {
      onClick: () => {
        if (previewUrl && contentWidth) {
          const encodedUrl = encodeURIComponent(previewUrl);
          const previewRouteUrl = linksConstants.PROJECTS.EMAIL.PREVIEW(encodedUrl, contentWidth);

          window.open(previewRouteUrl, '_blank');
        }
        setPreviewUrl(null);
      },
      label: t('common.previewReady'),
      icon: <VisibilityOutlinedIcon />,
      id: 'preview-ready',
      visible: previewUrl !== null,
      disabled: !hasPermission(role, ['projectsEmailEdition']) || previewing
    },
    {
      onClick: previewProject,
      label: previewing ? t('common.generatingPreview') : t('common.preview'),
      icon: <VisibilityOutlinedIcon />,
      variant: 'outlined' as const,
      id: 'preview',
      visible: previewUrl === null,
      disabled: !hasPermission(role, ['projectsEmailEdition']) || previewing
    },
    {
      onClick: () => saveProject({ inBackground: false }),
      label: t('common.save'),
      icon: <SaveIcon />,
      id: 'save',
      disabled: !hasPermission(role, ['projectsEmailEdition']) || saving
    }
  ];

  const saveProjectName = async (name: string) => {
    if (name && project) {
      try {
        setProject({ ...project, name });
        await emailProjectsOperations.saveProjectName(project, name);
        Toast.success(t('notifications.projectNameChange.success'));
      } catch (e) {
        Toast.error(t('notifications.projectNameChange.error'));
      }
    }
  };

  useEffect(() => {
    let webSocketClient: nes.Client | null = null;

    const fetchData = async () => {
      try {
        await emailProjectsOperations.checkIn(projectId, v4());
        webSocketClient = await connectToProjectChannel(projectId, authData.token, dispatch);
      } catch (e) {
        const error = createNetworkErrorObject(e);
        setProjectAlreadyCheckedInErrorMessage(error.message || '');
        onProjectAlreadyCheckedInWindowOpen();
        return;
      }

      try {
        const damStatusResponse = await myOrganizationOperations.getDamStatus();
        setDamStatus(damStatusResponse);
      } catch (e) {
        console.warn('Error occurred when trying to fetch DAM status', e);
      }

      try {
        const swatchesGroupsResponse = await myOrganizationOperations.getOrganizationSwatches();
        setSwatchesGroups(swatchesGroupsResponse.data);
        const swatchesColorsResponse = await myOrganizationOperations.getOrganizationColors();
        setColors(swatchesColorsResponse.data);
      } catch (e) {
        console.warn('Error occurred when trying to fetch color swatches', e);
      }

      try {
        const projectData = await emailProjectsOperations.show(projectId);
        setProject(projectData);
        if (projectData.source) {
          const storiesResponse = await printProjectsOperations.getArticles(projectData.source);
          setDesignerStories(storiesResponse.data);
        }

        const projectContents = await emailProjectsOperations.getProjectModel(projectId);
        if (projectContents.projectModel.version === 2) {
          setProjectModel((projectContents.projectModel as unknown) as ProjectModelV2);
        } else {
          setProjectModel(sanitizeToV2Model((projectContents.projectModel as unknown) as ProjectModelV1));
        }

        setSelectedColorSwatches(projectContents.projectModel.colorSwatches || {});

        const storiesData = await storiesOperations.getStories(projectData.publishDate, StoryChannelType.EMAIL);
        setStoryList(storiesData.stories);
      } catch (e) {
        Toast.error(
          t('notifications.newsletterProjectFetch.error', { rawError: `(${createNetworkErrorObject(e).message})` })
        );
      } finally {
        setLoading(false);
      }
    };
    fetchData();

    document.addEventListener('drop', preventDragEvent);
    document.addEventListener('dragdrop', preventDragEvent);
    document.addEventListener('dragenter', preventDragEvent);
    document.addEventListener('dragleave', preventDragEvent);
    document.addEventListener('dragover', preventDragEvent);

    return () => {
      if (autoSaveTimeout) {
        clearTimeout(autoSaveTimeout);
      }
      document.removeEventListener('drop', preventDragEvent);
      document.removeEventListener('dragdrop', preventDragEvent);
      document.removeEventListener('dragenter', preventDragEvent);
      document.removeEventListener('dragleave', preventDragEvent);
      document.removeEventListener('dragover', preventDragEvent);

      let unsubscribeTimeout: number | undefined;
      let runs = 0;
      const maxRuns = 10;

      const unsubscribeFromWebSocket = () => {
        clearTimeout(unsubscribeTimeout);

        unsubscribeTimeout = setTimeout(() => {
          if (webSocketClient) {
            webSocketClient.disconnect();
            return;
          }
          if (runs++ >= maxRuns) {
            return;
          }

          unsubscribeFromWebSocket();
        }, 2000);
      };

      if (webSocketClient) {
        webSocketClient.disconnect();
      } else {
        unsubscribeFromWebSocket();
      }
    };
  }, []);

  const getAvailableAdSpaces = (): AdPlacementStatus[] => {
    if (!projectModel) return [];

    const requiredAds = projectModel.constraints?.ads || [];
    const currentlyPlacedAds = projectModel.sizes.large.reduce((placedAds, row, rowIndex) => {
      for (const column of row.cols) {
        for (const component of column.components) {
          if (component.type === 'ad-space') {
            placedAds.push({
              width: component.params.width,
              height: component.params.height,
              rowIndex,
              counted: false
            });
          }
        }
      }

      return placedAds;
    }, [] as PlacedAd[]);

    return requiredAds.map((requiredAd) => {
      const alreadyInserted = currentlyPlacedAds.find(
        (placedAd) => placedAd.width === requiredAd.width && placedAd.height === requiredAd.height && !placedAd.counted
      );

      if (alreadyInserted) {
        alreadyInserted.counted = true;
        return {
          width: requiredAd.width,
          height: requiredAd.height,
          currentRowIndex: alreadyInserted.rowIndex,
          isValid: alreadyInserted.rowIndex <= requiredAd.maxRow,
          placed: true,
          requiredRowIndex: requiredAd.maxRow
        };
      } else {
        return {
          width: requiredAd.width,
          height: requiredAd.height,
          currentRowIndex: null,
          isValid: false,
          placed: false,
          requiredRowIndex: requiredAd.maxRow
        };
      }
    });
  };

  const availableAdSpaces = getAvailableAdSpaces();

  return (
    <FullSizePageContainer>
      <PageHeader
        variant="white"
        title={
          project ? (
            <>
              <InputChangeOnBlur initialValue={project.name} onSave={saveProjectName} />
              <AutoSavedInfo>{autoSavedIndicator ? t('common.allChangesSaved') : t('common.notSaved')}</AutoSavedInfo>
              <AdSpacesConstraintStatus availableAdSpaces={availableAdSpaces} />
            </>
          ) : null
        }
        leftActionButtons={[backButton]}
        rightActionButtons={rightActionButtons}
        innerAdjustmentEnabled={innerAdjustmentEnabled}
      />

      {loading && <Loader />}
      {!loading && project && projectModel && (
        <DndProvider backend={HTML5Backend}>
          <Editor
            model={projectModel}
            damStatus={damStatus}
            setModel={onProjectModelUpdate}
            projectId={projectId}
            swatchesGroups={swatchesGroups}
            applyColor={applyColor}
            selectedColorSwatches={selectedColorSwatches}
            colors={colors}
            project={project}
            sourceProjectId={project.source}
            designerStories={designerStories}
            storyList={storyList}
            availableAdSpaces={availableAdSpaces}
            inProjectEditor
            onInnerAdjustmentEnabledChange={setInnerAdjustmentEnabled}
          />
        </DndProvider>
      )}
      <ProjectAlreadyCheckedIn
        open={projectAlreadyCheckedInWindowOpen}
        onCloseClick={onProjectAlreadyCheckedInWindowClose}
        errorMessage={projectAlreadyCheckedInErrorMessage}
        fullScreenOnMobile
      />
      <SaveBeforeExitWindow
        open={saveBeforeExitWindowOpen}
        onCloseClick={onSaveBeforeExitWindowClose}
        fullScreenOnMobile
        onSubmit={() => dispatch(push(linksConstants.DASHBOARD.INDEX))}
      />
      <NotesPopper open={Boolean(notesAnchorEl)} anchorEl={notesAnchorEl} transition disablePortal={false}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <ProjectNotes
              onClose={() => setNotesAnchorEl(null)}
              projectId={projectId}
              type="email"
              role={role}
              user={user}
            />
          </Fade>
        )}
      </NotesPopper>
    </FullSizePageContainer>
  );
};

const NotesPopper = styled(Popper)`
  z-index: 1290;
`;

const AutoSavedInfo = styled.span`
  font-size: 0.8rem;
  vertical-align: middle;
  font-style: italic;
  opacity: 0.8;
  margin-left: 2rem;
  flex-grow: 1;
`;

export default EmailDndEditorPage;
