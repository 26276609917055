import { AdminRole, DefaultRoleName, defaultRoleNames, RolePermissions } from '../store/AdminRoles/types';
import { AuthState } from '../store/Auth/types';
import { EmailProject } from '../store/EmailProjects/types';
import { PrintProject } from '../store/PrintProjects/types';
import { AdminOrganization } from '../store/AdminOrganizations/types';

export const channelsAccessPermissions: (keyof RolePermissions)[] = [
  'projectsPrintAccess',
  'projectsEmailAccess',
  'storiesAccess'
];
export const systemReportsPermissions: (keyof RolePermissions)[] = [
  'adminReportsDailyAdsReport',
  'adminReportsDailyOrganizationEmailActivityReport',
  'adminReportsDailyOrganizationPrintActivityReport',
  'adminReportsEmailCampaignsReport',
  'adminReportsOrganizationMailingListSizeReport'
];
export const administrationAccessPermissions: (keyof RolePermissions)[] = [
  ...systemReportsPermissions,
  'mailingListsView',
  'usersView',
  'rolesView',
  'keywordsView',
  'passwordPoliciesView',
  'organizationsView',
  'templatesView',
  'adsView',
  'backofficeProjectsDashboard',
  'systemSettingsView'
];

export const isOrganizationOwner = (authState: AuthState) => {
  const organization = authState.organizations.find((organization) => organization.id === authState.organizationId);
  if (!organization) {
    return false;
  }

  return organization.isOwner;
};

export const isSystemOwner = (authState: AuthState) => {
  return authState.user.type.name === DefaultRoleName.SYSTEM_OWNER;
};

export const hasPermission = (role: AdminRole, permissions: (keyof RolePermissions)[]) => {
  return permissions.some((permission) => role.permissions[permission]);
};

export const projectBelongsToOrganization = (org: AdminOrganization | null, project: EmailProject | PrintProject) => {
  return org && org.id === project.organizationId;
};

export const canEditUserType = (authState: AuthState, userTypeName: DefaultRoleName) => {
  const currentUserTypeIndex = defaultRoleNames.indexOf(authState.user.type.name as DefaultRoleName);
  const userTypeToEdit = defaultRoleNames.indexOf(userTypeName);
  if (userTypeToEdit < 0 || currentUserTypeIndex < 0) {
    return false;
  }

  return userTypeToEdit >= currentUserTypeIndex;
};

export const hasPermissionToSystemReports = (role: AdminRole) => {
  return systemReportsPermissions.some((permission) => role.permissions[permission]);
};

export const hasPermissionToAnyChannel = (role: AdminRole) => {
  return channelsAccessPermissions.some((permission) => role.permissions[permission]);
};

export const hasPermissionToAnyAdministration = (role: AdminRole) => {
  return administrationAccessPermissions.some((permission) => role.permissions[permission]);
};

export const getDefaultPermissions = (defaultFlag = false): RolePermissions => ({
  projectsPrintAccess: defaultFlag,
  projectsPrintEdition: defaultFlag,
  projectsPrintPublishing: defaultFlag,
  projectsPrintRemove: defaultFlag,
  projectsPrintResetCheckIn: defaultFlag,
  projectsPrintReSubmitJob: defaultFlag,
  projectNotesView: defaultFlag,
  projectNotesEdit: defaultFlag,
  projectsEmailAccess: defaultFlag,
  projectsEmailEdition: defaultFlag,
  projectsEmailPublishing: defaultFlag,
  projectsEmailRemove: defaultFlag,
  projectsEmailResetCheckIn: defaultFlag,
  projectsEmailAdvancedEditor: defaultFlag,
  mailingListsView: defaultFlag,
  mailingListsAdd: defaultFlag,
  mailingListsEdit: defaultFlag,
  mailingListsRemove: defaultFlag,
  adminMailingListsView: defaultFlag,
  adminMailingListsAdd: defaultFlag,
  adminMailingListsEdit: defaultFlag,
  adminMailingListsRemove: defaultFlag,
  usersView: defaultFlag,
  usersAdd: defaultFlag,
  usersRemove: defaultFlag,
  usersLoginAs: defaultFlag,
  usersBlock: defaultFlag,
  usersChangePassword: defaultFlag,
  usersChangeBrandfolderCollection: defaultFlag,
  rolesView: defaultFlag,
  rolesAdd: defaultFlag,
  rolesRemove: defaultFlag,
  keywordsView: defaultFlag,
  keywordsAdd: defaultFlag,
  keywordsAssociate: defaultFlag,
  keywordsRemove: defaultFlag,
  passwordPoliciesView: defaultFlag,
  passwordPoliciesAdd: defaultFlag,
  passwordPoliciesEdit: defaultFlag,
  passwordPoliciesRemove: defaultFlag,
  templatesView: defaultFlag,
  templatesAdd: defaultFlag,
  templatesDownload: defaultFlag,
  templatesUpload: defaultFlag,
  templatesRemove: defaultFlag,
  systemSettingsView: defaultFlag,
  systemSettingsManage: defaultFlag,
  organizationsView: defaultFlag,
  organizationsAdd: defaultFlag,
  organizationsEdit: defaultFlag,
  organizationsRemove: defaultFlag,
  swatchesView: defaultFlag,
  swatchesAdd: defaultFlag,
  swatchesEdit: defaultFlag,
  swatchesRemove: defaultFlag,
  adsView: defaultFlag,
  adsAdd: defaultFlag,
  adsEdit: defaultFlag,
  adsRemove: defaultFlag,
  debugJsonEmailTemplateEditor: defaultFlag,
  debugFlipBookPreview: defaultFlag,
  debugFlipBookRegeneration: defaultFlag,
  debugTestProjectsCreation: defaultFlag,
  debugExternalProjectIdDisplay: defaultFlag,
  adminReportsDailyAdsReport: defaultFlag,
  adminReportsDailyOrganizationEmailActivityReport: defaultFlag,
  adminReportsDailyOrganizationPrintActivityReport: defaultFlag,
  adminReportsEmailCampaignsReport: defaultFlag,
  adminReportsOrganizationMailingListSizeReport: defaultFlag,
  adminReportsSubmittedPrintProjectsReport: defaultFlag,
  adminReportsDetailedEmailCampaignsReport: defaultFlag,
  adminReportsAdPlacementReport: defaultFlag,
  backofficeProjectsDashboard: defaultFlag,
  storiesAccess: defaultFlag,
  storiesEdit: defaultFlag,
  storiesEditChannels: defaultFlag,
  storiesAdd: defaultFlag,
  storiesDelete: defaultFlag,
  storiesContributors: defaultFlag,
  storiesApproval: defaultFlag,
  storiesPublish: defaultFlag,
  storiesAiContent: defaultFlag,
  campaignsView: defaultFlag,
  campaignsAdd: defaultFlag,
  campaignsEdit: defaultFlag,
  campaignsRemove: defaultFlag
});
