import React, { FunctionComponent, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';
import styled from 'styled-components';
import { Button, Checkbox, FormControlLabel, Paper, withTheme } from '@material-ui/core';
import { Light, OutlinedInfoBoxContent, Strong } from '../../../../Shared/StyledComponents';
import { useTypedSelector } from '../../../../../utils';
import { authOperations } from '../../../../../store/Auth';
import Toast from '../../../../Shared/Toast/Toast';
import { OrganizationData } from '../../../../../store/Auth/types';
import Loader from '../../../../Shared/Loading/Loader';
import { Link } from 'react-router-dom';
import linksConstants from '../../../../../config/app/linksConstants';
import { hasPermission } from '../../../../../utils/permissions';

type SelectPublishDateProps = {
  onSubmit: () => void;
  onSelect: (date: Date) => void;
  onTestProjectChecked: () => void;
  testProject: boolean;
  date: Date;
  infoText: string;
  checkReadMoreLinks: boolean;
};

const SelectPublishDate: FunctionComponent<SelectPublishDateProps> = ({
  onSelect,
  onSubmit,
  date,
  infoText,
  checkReadMoreLinks,
  onTestProjectChecked,
  testProject
}) => {
  const { t } = useTranslation();
  const role = useTypedSelector((state) => state.auth.role);
  const currentOrganizationId = useTypedSelector((state) => state.auth.organizationId);
  const [organizationData, setOrganizationData] = useState<OrganizationData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (checkReadMoreLinks) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const data = await authOperations.getOrganizationData();

          setOrganizationData(data);
        } catch (e) {
          Toast.error(t('notifications.getBusinessUserData.error'));
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    } else {
      setLoading(false);
    }
  }, [currentOrganizationId]);

  return (
    <>
      {checkReadMoreLinks && !loading && (!organizationData || !organizationData.readMoreLink) && (
        <div style={{ width: '100%', margin: '1em 0' }}>
          <InfoBox type="warning">
            <OutlinedInfoBoxContent>
              <InfoIcon fontSize="large" />
              <div>
                {t('pages.dashboard.createWizard.publishDateSelector.readMoreLinksWarning1')}{' '}
                <Link to={`${linksConstants.MY_ORGANIZATION}#read-more-link`}>{t('navigation.myOrganization')}</Link>{' '}
                {t('pages.dashboard.createWizard.publishDateSelector.readMoreLinksWarning2')}
              </div>
            </OutlinedInfoBoxContent>
          </InfoBox>
        </div>
      )}
      <Container>
        {loading && <Loader />}

        {!loading && (
          <>
            <PublishDatePicker elevation={4}>
              <DatePicker
                disabledKeyboardNavigation={true}
                nextMonthButtonLabel="Next >"
                previousMonthButtonLabel="< Previous"
                selected={date}
                onChange={(date) => onSelect(date || new Date())}
                inline
              />
            </PublishDatePicker>

            <div>
              <InfoBox type="outlinedInfo">
                <OutlinedInfoBoxContent>
                  <InfoIcon fontSize="large" />
                  <div>
                    <Strong>{t(`${infoText}.bold`)}</Strong> <Light>{t(`${infoText}.normal`)}</Light>
                  </div>
                </OutlinedInfoBoxContent>
              </InfoBox>

              <ConfirmButton
                endIcon={<NavigateNextIcon />}
                color={'secondary'}
                variant="contained"
                size="large"
                onClick={onSubmit}
              >
                {t('common.confirm')}
              </ConfirmButton>

              {hasPermission(role, ['debugTestProjectsCreation']) && (
                <div>
                  <FormControlLabel
                    control={<Checkbox checked={testProject} onChange={onTestProjectChecked} />}
                    label={t('pages.dashboard.createWizard.publishDateSelector.testProject')}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ConfirmButton = styled(Button)`
  margin: 2rem 0;
`;

export const PublishDatePicker = withTheme(styled(Paper)`
  margin-right: 2rem;
  .react-datepicker {
    border: none;

    &__header {
      background: transparent;
      border: none;
    }

    &__navigation {
      display: block;
      font-size: 1.1rem;
      line-height: 1rem;
      color: ${({ theme }) => theme.palette.secondary.main};
      overflow: visible;

      padding: 0;
      width: 100px;
      text-indent: 0;
      border: none;
      top: 2.5rem;
      outline: none;

      &--next {
        text-align: right;
        right: 1rem;
      }

      &--previous {
        left: 1rem;
        text-align: left;
      }
    }

    &__current-month {
      font-size: 1.4rem;
      padding: 2rem 0;
    }

    &__day,
    &__day-name {
      font-size: 1rem;
      padding: 1rem;
      width: 4rem;
    }

    &__day-name {
      color: #999999;
      padding-bottom: 0;
    }
  }
`);

export default SelectPublishDate;
