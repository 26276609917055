import React from 'react';
import { FormCheckboxInputProps } from './types';
import { Field, FieldProps } from 'formik';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const FormCheckboxInput: React.FC<FormCheckboxInputProps> = (props: FormCheckboxInputProps) => {
  const { formikFieldProps, checkboxFieldProps } = props;
  return (
    <Field name={formikFieldProps.name}>
      {(fieldProps: FieldProps) => {
        const { field } = fieldProps;

        const { value, ...rest } = field;

        return (
          <FormControlLabel
            control={
              <Checkbox
                {...rest}
                checked={value}
                {...checkboxFieldProps}
                onChange={(e) => {
                  field.onChange(e);
                  checkboxFieldProps && checkboxFieldProps.onChange && checkboxFieldProps.onChange(e, !!e.target.value);
                }}
              />
            }
            label={checkboxFieldProps?.label}
          />
        );
      }}
    </Field>
  );
};

export default FormCheckboxInput;
