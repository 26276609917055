import React, { FunctionComponent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectModelV2 } from '../../../Editors/EmailDndEditor/types';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import styled from 'styled-components';

type AdminEditEmailTemplateModelWindowProps = {
  open: boolean;
  model: ProjectModelV2;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  onSubmit: (data: ProjectModelV2) => void;
};

const AdminEditEmailTemplateModelWindow: FunctionComponent<AdminEditEmailTemplateModelWindowProps> = ({
  onCloseClick,
  open,
  onSubmit,
  model
}) => {
  const { t } = useTranslation();
  const currentModel = useRef<ProjectModelV2>(model);

  const submit = async () => {
    if (currentModel.current) {
      onSubmit(currentModel.current);
      onCloseClick();
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      okButtonText={t('common.submit')}
      header={t('pages.adminEmailTemplates.windows.editModel')}
      onCloseClick={onCloseClick}
      onSubmit={submit}
    >
      <EditModelTextArea
        onBlur={(e) => {
          try {
            currentModel.current = (JSON.parse(e.target.value) as unknown) as ProjectModelV2;
          } catch (e) {
            alert('JSON Model seems to be invalid');
          }
        }}
      >
        {JSON.stringify(currentModel.current, null, 2)}
      </EditModelTextArea>
    </ModalConfirmationWindow>
  );
};

const EditModelTextArea = styled.textarea`
  width: 100%;
  min-height: 400px;
  border: 1px solid #ccc;
`;

export default AdminEditEmailTemplateModelWindow;
