import {
  AdminRole,
  AdminRolesActionsConstants,
  AdminRolesIndexActionBEGIN,
  AdminRolesIndexActionFAILED,
  AdminRolesIndexActionSUCCESS,
  AdminRolesIndexResponse,
  AdminRolesKeywordAddedAction,
  AdminRolesKeywordRemovedAction
} from './types';

export const rolesIndexBegin = (): AdminRolesIndexActionBEGIN => ({
  type: AdminRolesActionsConstants.INDEX_BEGIN
});

export const rolesIndexSuccess = (payload: AdminRolesIndexResponse): AdminRolesIndexActionSUCCESS => ({
  type: AdminRolesActionsConstants.INDEX_SUCCESS,
  payload
});

export const rolesIndexFailed = (error: any): AdminRolesIndexActionFAILED => ({
  type: AdminRolesActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const keywordAdded = (template: AdminRole): AdminRolesKeywordAddedAction => ({
  type: AdminRolesActionsConstants.KEYWORD_ADDED,
  payload: template
});

export const keywordRemoved = (template: AdminRole): AdminRolesKeywordRemovedAction => ({
  type: AdminRolesActionsConstants.KEYWORD_REMOVED,
  payload: template
});
