import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { InviteContributorValues, Story } from '../../../../store/Stories/types';
import { storiesOperations } from '../../../../store/Stories';
import Toast from '../../../Shared/Toast/Toast';
import { AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import ModalFormWindow from '../../../Shared/Window/ModalFormWindow';
import app from '../../../../config/app/app';
import { errorCodeToToast } from '../../../../utils';

type InviteStoryContributorWindowProps = {
  open: boolean;
  story: Story;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const InviteStoryContributorWindow: FunctionComponent<InviteStoryContributorWindowProps> = ({
  onCloseClick,
  open,
  story
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!story) return null;

  const onSubmit = async (values: InviteContributorValues) => {
    try {
      await dispatch(storiesOperations.inviteContentContributor(story.id, values));
      await dispatch(storiesOperations.indexStoryContributors(story.id));
      onCloseClick();
      Toast.success(t('notifications.story.contributorInvited'));
    } catch (e) {
      errorCodeToToast.handleContributorInvitationErrorCode(e, t);
    }
  };

  const formInputs = () => (
    <>
      <AdminTextInput t={t} name="email" section="story" />
      <AdminTextInput t={t} name="firstName" section="story" />
      <AdminTextInput t={t} name="lastName" section="story" />
      <AdminTextInput t={t} name="infobox" section="story" multiline />
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.invite')}
      header={t('pages.story.windows.inviteStoryContributor.header')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        infobox: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().max(app.maxInputLength).email().required(),
        firstName: Yup.string().nullable(),
        lastName: Yup.string().nullable(),
        infobox: Yup.string().nullable()
      })}
    />
  );
};

export default InviteStoryContributorWindow;
