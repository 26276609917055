import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { TextField } from '@material-ui/core';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import LoadingModal from '../../../Shared/Loading/LoadingModal';
import { EmailProject } from '../../../../store/EmailProjects/types';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';

type CloneEmailProjectWindowProps = {
  open: boolean;
  project: EmailProject;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const CloneEmailProjectWindow: FunctionComponent<CloneEmailProjectWindowProps> = ({ project, onCloseClick, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [projectName, setProjectName] = useState<string>(project.name);
  const [copyingProject, setCopyingProject] = useState<boolean>(false);

  const onSubmit = async () => {
    try {
      setCopyingProject(true);
      const newProject = await emailProjectsOperations.cloneProject(project.id, projectName);
      onCloseClick();
      Toast.success(t('notifications.sharingCenter.cloneProjectSuccess'));
      setCopyingProject(false);

      dispatch(push(linksConstants.PROJECT_DETAILS(newProject.id)));
    } catch (e) {
      Toast.error(t('notifications.sharingCenter.cloneProjectError'));
    }
  };

  useEffect(() => {
    if (open) {
      setProjectName(project.name);
    }
  }, [open]);

  return (
    <>
      {copyingProject && <LoadingModal message={t('pages.sharingCenter.copyingProject')} />}
      <ModalConfirmationWindow
        open={open}
        header={t('pages.dashboard.cloneEmailProject.title')}
        okButtonText={t('pages.sharingCenter.windows.clone')}
        onCloseClick={onCloseClick}
        onSubmit={onSubmit}
        disableSubmitButton={projectName === ''}
      >
        <Row>{t('pages.dashboard.cloneEmailProject.content')}</Row>
        <Row>
          <TextField
            id="project-name"
            value={projectName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProjectName(event.target.value);
            }}
            size="small"
            fullWidth
            label={t('pages.sharingCenter.windows.name')}
            variant="outlined"
          />
        </Row>
      </ModalConfirmationWindow>
    </>
  );
};

export default CloneEmailProjectWindow;
