import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import ChangePasswordIcon from '@material-ui/icons/VpnKey';
import CheckIcon from '@material-ui/icons/Check';
import NotVerifiedIcon from '@material-ui/icons/HighlightOff';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LoginAsIcon from '@material-ui/icons/ExitToApp';
import BlockUserIcon from '@material-ui/icons/Lock';
import UnblockUserIcon from '@material-ui/icons/LockOpen';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import { formatTableTimestamp, getRoleNameElement, useTypedSelector } from '../../../../utils';
import { usePaginationWatch } from '../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../Shared/DataTable/useTableSearchWatch';
import { AdminUser, UserType } from '../../../../store/AdminUsers/types';
import { adminUsersOperations } from '../../../../store/AdminUsers';
import { PaginationStoreModule } from '../../../../store/Pagination/types';
import DataTable from '../../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../../Shared/DataTable/TableActionButton/TableActionButton';
import { TableSearchStoreModule } from '../../../../store/TableSearch/types';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import AdminCreateUserWindow from './Windows/AdminCreateUserWindow';
import { withTheme } from '@material-ui/core';
import AdminUpdateUserWindow from './Windows/AdminEditUserWindow';
import AdminToggleBlockUserWindow from './Windows/AdminToggleBlockUserWindow';
import AdminDeleteUserWindow from './Windows/AdminDeleteUserWindow';
import AdminLoginAsUserWindow from './Windows/AdminLoginAsUserWindow';
import AdminVerifyUserWindow from './Windows/AdminVerifyUserWindow';
import styled from 'styled-components';
import AdminChangeUserPasswordWindow from './Windows/AdminChangeUserPasswordWindow';
import PageHeader from '../../../Shared/Layout/PageHeader';
import AdminKeywordsList from '../../../Shared/Tags/AdminKeywordsList';
import { AdminKeyword, AdminKeywordGroup } from '../../../../store/AdminKeywords/types';
import { adminKeywordsOperations } from '../../../../store/AdminKeywords';
import { useDispatch } from 'react-redux';
import { canEditUserType, hasPermission } from '../../../../utils/permissions';
import { administrativeRoleNames, DefaultRoleName } from '../../../../store/AdminRoles/types';
import UserOrganizationsWithKeywords from './Partials/UserOrganizationWithKeywords';

type AdminUsersPageProps = {};

const AdminUsersPage: FunctionComponent<AdminUsersPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authState = useTypedSelector((state) => state.auth);
  const role = useTypedSelector((state) => state.auth.role);
  const users = useTypedSelector((state) => state.adminUsers.users);
  const isFailed = useTypedSelector((state) => state.adminUsers.index.isFailed);
  const isLoading = useTypedSelector((state) => state.adminUsers.index.isLoading);
  const pagination = useTypedSelector((state) => state.adminUsers.pagination);
  const tableSearch = useTypedSelector((state) => state.adminUsers.tableSearch);
  const allKeywords = useTypedSelector((state) => state.adminKeywords.all);
  const [userTypes, setUserTypes] = useState<UserType[]>([]);

  useEffect(() => {
    const fetchKeywords = async () => {
      await dispatch(adminKeywordsOperations.getAllKeywords());
    };
    const fetchUserTypes = async () => {
      const types = await adminUsersOperations.getUserTypes();
      setUserTypes(types.data);
    };
    fetchUserTypes();
    fetchKeywords();
  }, []);

  usePaginationWatch(pagination, [adminUsersOperations.index]);
  useTableSearchWatch<AdminUser>(tableSearch, [adminUsersOperations.index]);

  const [activeUser, setActiveUser] = useState<AdminUser | null>(null);
  const [createUserWindowOpen, onCreateUserWindowOpen, onCreateUserWindowClose] = useOpenHandler();
  const [updateUserWindowOpen, onUpdateUserWindowOpen, onUpdateUserWindowClose] = useOpenHandler();
  const [toggleBlockUserWindowOpen, onToggleBlockUserWindowOpen, onToggleBlockUserWindowClose] = useOpenHandler();
  const [loginAsUserWindowOpen, onLoginAsUserWindowOpen, onLoginAsUserWindowClose] = useOpenHandler();
  const [deleteUserWindowOpen, onDeleteUserWindowOpen, onDeleteUserWindowClose] = useOpenHandler();
  const [verifyUserWindowOpen, onVerifyUserWindowOpen, onVerifyUserWindowClose] = useOpenHandler();
  const [
    changeUserPasswordWindowOpen,
    onChangeUserPasswordWindowOpen,
    onChangeUserPasswordWindowClose
  ] = useOpenHandler();

  const getActionButtons = () => {
    if (hasPermission(role, ['usersAdd'])) {
      return [
        {
          label: t('pages.adminUsers.createUser'),
          icon: <AddIcon />,
          onClick: onCreateUserWindowOpen
        }
      ];
    }

    return [];
  };

  const onAddKeywordToUser = useCallback((user: AdminUser, keyword: AdminKeyword) => {
    dispatch(adminUsersOperations.addKeywordToUser(user, keyword));
  }, []);

  const onAddKeywordGroupToUser = useCallback((user: AdminUser, group: AdminKeywordGroup) => {
    dispatch(adminUsersOperations.addKeywordGroupToUser(user, group));
  }, []);

  const onDeleteKeywordFromUser = useCallback((user: AdminUser, keyword: AdminKeyword) => {
    dispatch(adminUsersOperations.removeKeywordFromUser(user, keyword));
  }, []);

  const onDeleteKeywordGroupFromUser = useCallback((user: AdminUser, group: AdminKeywordGroup) => {
    dispatch(adminUsersOperations.removeKeywordGroupFromUser(user, group));
  }, []);

  const isAdministrativeUserType = (userType: DefaultRoleName) => {
    return administrativeRoleNames.includes(userType);
  };

  const blockedStyle = {
    opacity: 0.4
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.adminUsers.title')} rightActionButtons={getActionButtons()} />
      <PageContent>
        <DataTable<AdminUser, 'actions' | 'fullName'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'email',
              // isUrl: true,
              // url: (user) => `/users/${user.id}`,
              render: (user) => (
                <div title={user.email} style={user.blocked ? blockedStyle : {}}>
                  {user.email}
                </div>
              ),
              sortable: true
            },
            {
              name: 'actions',
              render: (user) => (
                <TableActionButtonsContainer>
                  <TableActionButton
                    icon={<EditIcon />}
                    tooltip={t('pages.adminUsers.table.buttons.edit')}
                    ButtonProps={{
                      disabled:
                        !hasPermission(role, ['usersAdd']) ||
                        !canEditUserType(authState, user.type.name as DefaultRoleName),
                      onClick: () => {
                        setActiveUser(user);
                        onUpdateUserWindowOpen();
                      },
                      style: user.blocked ? blockedStyle : {}
                    }}
                  />
                  {user.verified && !user.blocked && (
                    <TableActionButton
                      icon={<LoginAsIcon />}
                      tooltip={t('pages.adminUsers.table.buttons.loginAsThisUser')}
                      ButtonProps={{
                        disabled:
                          !hasPermission(role, ['usersLoginAs']) ||
                          !canEditUserType(authState, user.type.name as DefaultRoleName),
                        onClick: () => {
                          setActiveUser(user);
                          onLoginAsUserWindowOpen();
                        }
                      }}
                    />
                  )}
                  {!user.verified && (
                    <TableActionButton
                      icon={<VerifiedUserIcon />}
                      tooltip={t('pages.adminUsers.table.buttons.manuallyVerify')}
                      ButtonProps={{
                        disabled:
                          !hasPermission(role, ['usersChangePassword']) ||
                          !canEditUserType(authState, user.type.name as DefaultRoleName),
                        onClick: () => {
                          setActiveUser(user);
                          onVerifyUserWindowOpen();
                        },
                        style: user.blocked ? blockedStyle : {}
                      }}
                    />
                  )}
                  {user.verified && (
                    <TableActionButton
                      icon={<ChangePasswordIcon />}
                      tooltip={t('pages.adminUsers.table.buttons.changePassword')}
                      ButtonProps={{
                        disabled:
                          !hasPermission(role, ['usersChangePassword']) ||
                          !canEditUserType(authState, user.type.name as DefaultRoleName),
                        onClick: () => {
                          setActiveUser(user);
                          onChangeUserPasswordWindowOpen();
                        },
                        style: user.blocked ? blockedStyle : {}
                      }}
                    />
                  )}
                  <TableActionButton
                    icon={user.blocked ? <UnblockUserIcon /> : <BlockUserIcon />}
                    tooltip={
                      user.blocked
                        ? t('pages.adminUsers.table.buttons.unblock')
                        : t('pages.adminUsers.table.buttons.block')
                    }
                    ButtonProps={{
                      disabled:
                        !hasPermission(role, ['usersBlock']) ||
                        !canEditUserType(authState, user.type.name as DefaultRoleName),
                      onClick: () => {
                        setActiveUser(user);
                        onToggleBlockUserWindowOpen();
                      },
                      style: user.blocked ? blockedStyle : {}
                    }}
                  />
                  <TableActionButton
                    icon={<DeleteIcon />}
                    tooltip={t('pages.adminUsers.table.buttons.delete')}
                    ButtonProps={{
                      disabled:
                        !hasPermission(role, ['usersRemove']) ||
                        !canEditUserType(authState, user.type.name as DefaultRoleName),
                      onClick: () => {
                        setActiveUser(user);
                        onDeleteUserWindowOpen();
                      },
                      style: user.blocked ? blockedStyle : {}
                    }}
                  />
                </TableActionButtonsContainer>
              )
            },
            {
              name: 'keywords',
              style: { minWidth: '250px' },
              render: (user: AdminUser) => (
                <span style={user.blocked ? blockedStyle : {}}>
                  <AdminKeywordsList
                    readOnly={!hasPermission(role, ['keywordsAssociate'])}
                    allKeywords={allKeywords}
                    keywords={user.keywords}
                    groups={user.keywordGroups}
                    onDelete={
                      hasPermission(role, ['keywordsAssociate'])
                        ? (keyword) => onDeleteKeywordFromUser(user, keyword)
                        : null
                    }
                    onDeleteGroup={
                      hasPermission(role, ['keywordsAssociate'])
                        ? (group) => onDeleteKeywordGroupFromUser(user, group)
                        : null
                    }
                    onAdd={(keyword) => onAddKeywordToUser(user, keyword)}
                    onAddGroup={(group) => onAddKeywordGroupToUser(user, group)}
                  />
                </span>
              )
            },
            {
              name: 'fullName',
              render: (user) => (
                <span style={user.blocked ? blockedStyle : {}}>
                  {user.firstName} {user.lastName}
                </span>
              ),
              isEmpty: (user) => !user.firstName && !user.lastName,
              sortable: true
            },
            {
              name: 'organizations',
              style: { minWidth: '250px' },
              render: (user: AdminUser) => (
                <span style={user.blocked ? blockedStyle : {}}>
                  <UserOrganizationsWithKeywords
                    key={`${user.id}-organization-with-keyword`}
                    organizations={user.organizations}
                    userId={user.id}
                    showKeywords
                  />
                </span>
              )
            },
            {
              name: 'role',
              sortable: true,
              render: (user) => (
                <span style={user.blocked ? blockedStyle : {}}>
                  {isAdministrativeUserType(user.type.name as DefaultRoleName)
                    ? getRoleNameElement(t, user.role)
                    : 'N/A'}
                </span>
              )
            },
            {
              name: 'type',
              sortable: true,
              render: (user) => (
                <span style={user.blocked ? blockedStyle : {}}>{t(`common.userTypes.${user.type.name}`)}</span>
              )
            },
            {
              name: 'verified',
              sortable: true,
              render: (user) => (
                <span style={user.blocked ? blockedStyle : {}}>
                  {user.verified ? <CheckIconStyled /> : <NotVerifiedIconStyled />}
                </span>
              )
            },
            {
              name: 'blocked',
              sortable: true,
              render: (user) => (
                <span style={user.blocked ? blockedStyle : {}}>
                  {user.blocked ? <NotVerifiedIconStyled /> : <CheckIconStyled />}
                </span>
              )
            },
            {
              name: 'systemMaintenanceAllowed',
              sortable: true,
              render: (user) => (
                <span style={user.blocked ? blockedStyle : {}}>
                  {user.systemMaintenanceAllowed ? <CheckIconStyled /> : <NotVerifiedIconStyled />}
                </span>
              )
            },
            {
              name: 'brandfolderCollection',
              sortable: true,
              render: (user) => (
                <span style={user.blocked ? blockedStyle : {}}>
                  {user.brandfolderCollection ? (
                    user.brandfolderCollection
                  ) : (
                    <span style={{ opacity: '0.7' }}>{t('common.default')}</span>
                  )}
                </span>
              )
            },
            {
              name: 'createdAt',
              render: (user) => (
                <span style={user.blocked ? blockedStyle : {}}>{formatTableTimestamp(user.createdAt)}</span>
              ),
              sortable: true
            }
          ]}
          tPath={'pages.adminUsers.table'}
          data={users}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.ADMIN_USERS
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.ADMIN_USERS
            // onPageChange: () => {},
            // onPerChange: () => {}
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>

      <AdminCreateUserWindow
        open={createUserWindowOpen}
        onCloseClick={onCreateUserWindowClose}
        fullScreenOnMobile
        userTypes={userTypes}
      />
      <AdminUpdateUserWindow
        user={activeUser}
        open={updateUserWindowOpen}
        onCloseClick={onUpdateUserWindowClose}
        fullScreenOnMobile
        userTypes={userTypes}
      />
      <AdminToggleBlockUserWindow
        user={activeUser}
        open={toggleBlockUserWindowOpen}
        onCloseClick={onToggleBlockUserWindowClose}
        fullScreenOnMobile
      />
      <AdminDeleteUserWindow
        user={activeUser}
        open={deleteUserWindowOpen}
        onCloseClick={onDeleteUserWindowClose}
        fullScreenOnMobile
      />
      <AdminLoginAsUserWindow
        user={activeUser}
        open={loginAsUserWindowOpen}
        onCloseClick={onLoginAsUserWindowClose}
        fullScreenOnMobile
      />
      <AdminVerifyUserWindow
        user={activeUser}
        open={verifyUserWindowOpen}
        onCloseClick={onVerifyUserWindowClose}
        fullScreenOnMobile
      />
      <AdminChangeUserPasswordWindow
        user={activeUser}
        open={changeUserPasswordWindowOpen}
        onCloseClick={onChangeUserPasswordWindowClose}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

const CheckIconStyled = withTheme(styled(CheckIcon)`
  color: ${({ theme }) => theme.palette.success.main};
`);

const NotVerifiedIconStyled = withTheme(styled(NotVerifiedIcon)`
  color: ${({ theme }) => theme.palette.warning.main};
`);

export default AdminUsersPage;
