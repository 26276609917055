import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Box, Button, Typography } from '@material-ui/core';
import Toast from '../../../Shared/Toast/Toast';
import { PasswordPolicy, PasswordPolicySettings } from '../../../../store/SystemSettings/types';
import { systemSettingsOperations } from '../../../../store/SystemSettings';
import { AdminCheckboxInput, AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import Loader from '../../../Shared/Loading/Loader';
import { Row } from '../../../Shared/StyledComponents';
import { hasPermission, isOrganizationOwner } from '../../../../utils/permissions';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import { AuthState } from '../../../../store/Auth/types';

type PasswordPolicyEditFormProps = {
  authState: AuthState;
  isGlobal: boolean;
  readOnly?: boolean;
};

const PasswordPolicyEditForm: FunctionComponent<PasswordPolicyEditFormProps> = ({
  authState,
  isGlobal,
  readOnly = false
}) => {
  const { t } = useTranslation();
  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicy | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { role } = authState;

  const savePasswordPolicySettings = async (values: PasswordPolicySettings) => {
    try {
      if (isGlobal) {
        setPasswordPolicy(await systemSettingsOperations.setGlobalPasswordPolicy(values));
      } else {
        setPasswordPolicy(await myOrganizationOperations.setPasswordPolicy(values));
      }
      Toast.success(t('notifications.passwordPolicy.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.passwordPolicy.errorSave'));
    }
  };

  const removePasswordPolicy = async () => {
    try {
      if (!isGlobal) {
        const globalPolicy = await myOrganizationOperations.removePasswordPolicy();
        setPasswordPolicy(globalPolicy);
        Toast.success(t('notifications.passwordPolicy.successOperationRemove'));

        return globalPolicy;
      }
    } catch (e) {
      Toast.error(t('notifications.passwordPolicy.errorSave'));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (isGlobal) {
          setPasswordPolicy(await systemSettingsOperations.getGlobalPasswordPolicy());
        } else {
          if (authState.organizationId) {
            setPasswordPolicy(await myOrganizationOperations.getOrganizationPasswordPolicy(authState.organizationId));
          }
        }
      } catch (e) {
        Toast.error(t('notifications.passwordPolicy.errorFetch'));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [authState.organizationId]);

  const getTitle = () => {
    if (isGlobal) {
      return t('pages.systemSettings.passwordPolicyTitle');
    }

    return t('pages.myOrganization.passwordPolicyTitle');
  };

  const hasPermissionsToEdit = isGlobal
    ? hasPermission(role, ['systemSettingsManage'])
    : isOrganizationOwner(authState);

  const renderPasswordPolicyForm = (props: FormikProps<PasswordPolicySettings>) => (
    <Form>
      <Typography variant="h6" gutterBottom>
        {getTitle()}
      </Typography>
      {!isGlobal && passwordPolicy && passwordPolicy.inherited && (
        <Typography variant="subtitle2" gutterBottom style={{ fontStyle: 'italic' }}>
          {t('pages.myOrganization.passwordPolicyInherited')}
        </Typography>
      )}
      <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />

      <Row>
        <AdminTextInput
          t={t}
          name="passwordLength"
          section="passwordPolicy"
          type="number"
          disabled={!hasPermissionsToEdit || readOnly}
        />
      </Row>
      <Row>
        <AdminCheckboxInput t={t} name="upper" section="passwordPolicy" disabled={!hasPermissionsToEdit || readOnly} />
      </Row>
      <Row>
        <AdminCheckboxInput t={t} name="lower" section="passwordPolicy" disabled={!hasPermissionsToEdit || readOnly} />
      </Row>
      <Row>
        <AdminCheckboxInput
          t={t}
          name="numbers"
          section="passwordPolicy"
          disabled={!hasPermissionsToEdit || readOnly}
        />
      </Row>
      <Row>
        <AdminCheckboxInput
          t={t}
          name="specialCharacters"
          section="passwordPolicy"
          disabled={!hasPermissionsToEdit || readOnly}
        />
      </Row>
      <Row>
        <AdminCheckboxInput
          t={t}
          name="forceRotation"
          section="passwordPolicy"
          disabled={!hasPermissionsToEdit || readOnly}
        />
      </Row>
      <Row>
        <AdminTextInput
          t={t}
          name="rotationDays"
          section="passwordPolicy"
          type="number"
          disabled={!hasPermissionsToEdit || !props.values.forceRotation || readOnly}
        />
      </Row>
      <Row>
        <AdminCheckboxInput
          t={t}
          name="forceUnique"
          section="passwordPolicy"
          disabled={!hasPermissionsToEdit || readOnly}
        />
      </Row>
      <Row>
        <AdminTextInput
          t={t}
          name="historyLength"
          section="passwordPolicy"
          type="number"
          disabled={!hasPermissionsToEdit || !props.values.forceUnique || readOnly}
        />
      </Row>

      {!readOnly && (
        <Box display="flex" justifyContent="flex-end">
          {!isGlobal && passwordPolicy && !passwordPolicy.inherited && (
            <Button
              style={{ marginTop: '1rem' }}
              color="secondary"
              variant="outlined"
              disabled={!hasPermissionsToEdit}
              onClick={async () => {
                const newPolicy = await removePasswordPolicy();
                if (newPolicy) {
                  props.setFieldValue('passwordLength', newPolicy.passwordLength);
                  props.setFieldValue('upper', newPolicy.upper);
                  props.setFieldValue('lower', newPolicy.lower);
                  props.setFieldValue('numbers', newPolicy.numbers);
                  props.setFieldValue('specialCharacters', newPolicy.specialCharacters);
                  props.setFieldValue('forceRotation', newPolicy.forceRotation);
                  props.setFieldValue('rotationDays', newPolicy.rotationDays);
                  props.setFieldValue('forceUnique', newPolicy.forceUnique);
                  props.setFieldValue('historyLength', newPolicy.historyLength);
                }
              }}
              size="medium"
            >
              {t('pages.myOrganization.inheritGlobalPasswordPolicy')}
            </Button>
          )}
          <Button
            style={{ marginTop: '1rem', marginLeft: 10 }}
            color="secondary"
            variant="contained"
            onClick={props.submitForm}
            size="medium"
            type="submit"
            disabled={!hasPermissionsToEdit || props.isSubmitting || !props.isValid || !props.dirty}
          >
            {t('common.save')}
          </Button>
        </Box>
      )}
    </Form>
  );

  return (
    <>
      {loading && <Loader />}
      {!loading && passwordPolicy && (
        <Formik
          initialValues={{
            passwordLength: passwordPolicy.passwordLength,
            upper: passwordPolicy.upper,
            lower: passwordPolicy.lower,
            numbers: passwordPolicy.numbers,
            specialCharacters: passwordPolicy.specialCharacters,
            forceRotation: passwordPolicy.forceRotation,
            rotationDays: passwordPolicy.rotationDays || 60,
            forceUnique: passwordPolicy.forceUnique,
            historyLength: passwordPolicy.historyLength || 10
          }}
          validationSchema={Yup.object().shape({
            passwordLength: Yup.number().integer().min(6).max(50).required(),
            upper: Yup.boolean().required(),
            lower: Yup.boolean().required(),
            numbers: Yup.boolean().required(),
            specialCharacters: Yup.boolean().required(),
            forceRotation: Yup.boolean().required(),
            rotationDays: Yup.number().integer().min(10).max(365).required(),
            forceUnique: Yup.boolean().required(),
            historyLength: Yup.number().integer().min(1).max(20).required()
          })}
          onSubmit={savePasswordPolicySettings}
        >
          {renderPasswordPolicyForm}
        </Formik>
      )}
    </>
  );
};

export default PasswordPolicyEditForm;
