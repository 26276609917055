import {
  AdminMailingList,
  AdminMailingListsActionsConstants,
  AdminMailingListsIndexActionBEGIN,
  AdminMailingListsIndexActionFAILED,
  AdminMailingListsIndexActionSUCCESS,
  AdminMailingListsIndexResponse,
  AdminMailingListsUpdateAction
} from './types';

export const adminMailingListsIndexBegin = (): AdminMailingListsIndexActionBEGIN => ({
  type: AdminMailingListsActionsConstants.INDEX_BEGIN
});

export const adminMailingListsIndexSuccess = (
  payload: AdminMailingListsIndexResponse
): AdminMailingListsIndexActionSUCCESS => ({
  type: AdminMailingListsActionsConstants.INDEX_SUCCESS,
  payload
});

export const adminMailingListsIndexFailed = (error: any): AdminMailingListsIndexActionFAILED => ({
  type: AdminMailingListsActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const updateAdminMailingList = (adminMailingList: AdminMailingList): AdminMailingListsUpdateAction => ({
  type: AdminMailingListsActionsConstants.UPDATE_MAILING_LIST,
  payload: {
    adminMailingList
  }
});
