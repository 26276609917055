import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../../Shared/StyledComponents';
import { EmailProjectAttachment } from '../../../../../../store/EmailProjects/types';

type DeleteAttachmentWindowProps = {
  attachment: EmailProjectAttachment;
  open: boolean;
  onCloseClick: () => void;
  onFormSubmit: () => void;
  fullScreenOnMobile: boolean;
};

const DeleteAttachmentWindow: FunctionComponent<DeleteAttachmentWindowProps> = ({
  attachment,
  onCloseClick,
  onFormSubmit,
  open
}) => {
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.story.windows.removeAttachment.header')}
      okButtonText={t('common.remove')}
      onCloseClick={onCloseClick}
      onSubmit={onFormSubmit}
    >
      <Row>{t('pages.story.windows.removeAttachment.content')}</Row>
      <Row>
        <Strong>{attachment.filename}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteAttachmentWindow;
