import { Pagination } from '@material-ui/lab';
import React from 'react';

type WidgetPaginationProps = {
  pagination: {
    page: number;
    totalPages: number;
  };
  handlePaginationChange: (val: number) => void;
};

export const WidgetPagination = (props: WidgetPaginationProps) => {
  return (
    <Pagination
      count={props.pagination.totalPages}
      page={props.pagination.page}
      shape="rounded"
      onChange={(e, val) => props.handlePaginationChange(val)}
      size="small"
      style={{ marginTop: 'auto' }}
    />
  );
};
