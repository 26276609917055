import React, { useState } from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import { Chip, ClickAwayListener, Divider, Tooltip, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { StoryChannel, StoryChannelStatus } from '../../../../store/Stories/types';
import { getChannelFields } from '../Utils/storyUtils';
import { TFunction } from 'i18next';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import CachedIcon from '@material-ui/icons/Cached';

type CalendarEventChipProps = {
  channel: StoryChannel;
};

const CalendarEventChip: React.FC<CalendarEventChipProps> = ({ channel }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const fieldsOrder = getChannelFields(channel.type);

  const channelContent = channel.fields
    .sort((a, b) => fieldsOrder.indexOf(a.name) - fieldsOrder.indexOf(b.name))
    .map((field, i) => (
      <>
        <strong>{t(`pages.story.inputs.${field.name}`)}</strong>:{' '}
        <span dangerouslySetInnerHTML={{ __html: field.value.toString() }} />
        {i !== channel.fields.length - 1 && <Divider style={{ margin: '0.4rem 0' }} />}
      </>
    ));
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <HtmlTooltip
        PopperProps={{
          disablePortal: true
        }}
        onClose={handleTooltipClose}
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        interactive
        title={channelContent.length ? channelContent : t('pages.story.noContent').toString()}
      >
        <Chip
          variant="outlined"
          label={getCalendarEventChipContent(channel, t, theme)}
          style={{
            margin: '2px 0 0 0',
            height: 'auto',
            paddingTop: '3px',
            paddingBottom: '3px'
          }}
          onClick={handleTooltipOpen}
        />
      </HtmlTooltip>
    </ClickAwayListener>
  );
};

const getCalendarEventChipContent = (channel: StoryChannel, t: TFunction, theme: Theme) => {
  return (
    <Typography variant="caption" style={{ display: 'flex', alignItems: 'center', lineHeight: 'normal' }}>
      {t(`pages.story.channels.${channel.type}`)}&nbsp;{getCalendarEventStatus(channel.status, t, theme)}
    </Typography>
  );
};

const getCalendarEventStatus = (status: StoryChannelStatus, t: TFunction, theme: Theme) => {
  let icon: React.ReactNode;
  let color: string = theme.palette.secondary.main;
  const iconStyle: React.CSSProperties = {
    width: 12,
    height: 12
  };

  switch (status) {
    case StoryChannelStatus.IDLE:
      color = theme.palette.warning.main;
      icon = <CachedIcon style={iconStyle} />;
      break;
    case StoryChannelStatus.APPROVED:
      color = '#9c27b0';
      icon = <EmojiEmotionsOutlinedIcon style={iconStyle} />;
      break;
  }

  return (
    <Typography
      variant="caption"
      style={{ color: color, display: 'inline-flex', alignItems: 'center', lineHeight: 'normal' }}
    >
      {icon}&nbsp;
      {t(`pages.story.statuses.${status === StoryChannelStatus.APPROVED ? StoryChannelStatus.PUBLISHED : status}`)}
    </Typography>
  );
};

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    maxWidth: 380,
    fontSize: theme.typography.pxToRem(13),
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: '0.7rem',
    maxHeight: '50vh',
    overflow: 'auto'
  },
  arrow: {
    color: theme.palette.secondary.main
  }
}))(Tooltip);

export default CalendarEventChip;
