import {
  AdminUser,
  AdminUsersActionsConstants,
  AdminUsersIndexActionBEGIN,
  AdminUsersIndexActionFAILED,
  AdminUsersIndexActionSUCCESS,
  AdminUsersIndexResponse,
  AdminUsersKeywordAddedAction,
  AdminUsersKeywordGroupAddedAction,
  AdminUsersKeywordGroupRemovedAction,
  AdminUsersKeywordRemovedAction,
  AdminUsersShowActionBEGIN,
  AdminUsersShowActionFAILED,
  AdminUsersShowActionSUCCESS,
  AdminUsersShowResponse
} from './types';

export const usersIndexBegin = (): AdminUsersIndexActionBEGIN => ({
  type: AdminUsersActionsConstants.INDEX_BEGIN
});

export const usersIndexSuccess = (payload: AdminUsersIndexResponse): AdminUsersIndexActionSUCCESS => ({
  type: AdminUsersActionsConstants.INDEX_SUCCESS,
  payload
});

export const usersIndexFailed = (error: any): AdminUsersIndexActionFAILED => ({
  type: AdminUsersActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const usersShowBegin = (): AdminUsersShowActionBEGIN => ({
  type: AdminUsersActionsConstants.SHOW_BEGIN
});

export const usersShowSuccess = (payload: AdminUsersShowResponse): AdminUsersShowActionSUCCESS => ({
  type: AdminUsersActionsConstants.SHOW_SUCCESS,
  payload
});

export const usersShowFailed = (error: any): AdminUsersShowActionFAILED => ({
  type: AdminUsersActionsConstants.SHOW_FAILED,
  payload: {
    error
  }
});

export const keywordAdded = (user: AdminUser): AdminUsersKeywordAddedAction => ({
  type: AdminUsersActionsConstants.KEYWORD_ADDED,
  payload: user
});

export const keywordGroupAdded = (user: AdminUser): AdminUsersKeywordGroupAddedAction => ({
  type: AdminUsersActionsConstants.KEYWORD_GROUP_ADDED,
  payload: user
});

export const keywordRemoved = (user: AdminUser): AdminUsersKeywordRemovedAction => ({
  type: AdminUsersActionsConstants.KEYWORD_REMOVED,
  payload: user
});

export const keywordGroupRemoved = (user: AdminUser): AdminUsersKeywordGroupRemovedAction => ({
  type: AdminUsersActionsConstants.KEYWORD_GROUP_REMOVED,
  payload: user
});
