import React, { useState } from 'react';
import { Button, DialogProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { sleep } from '../../../utils';
import useViewportSize from '../../../hooks/useViewportSize';
import { useTranslation } from 'react-i18next';
import {
  CloseButton,
  DialogActionsStyled,
  DialogContentStyled,
  DialogHeaderStyled,
  DialogStyled
} from './WindowStyledComponents';

export type ModalConfirmationWindowProps = {
  open: boolean;
  withCloseButton?: boolean;
  onCloseClick: () => void;
  onCloseModalButtonClick?: () => void;
  onSubmit?: () => void;
  header: string | JSX.Element;
  okButtonText?: string | JSX.Element;
  cancelButtonText?: string | JSX.Element;
  extraButtons?: JSX.Element[];
  hideActionButtons?: boolean;
  disableSubmitButton?: boolean;
  disableBackdropClick?: boolean;
  disableCloseButton?: boolean;
  disableOnlyXCloseButton?: boolean;
  iframeContent?: boolean;
  showScrollBar?: boolean;
  fullWidth?: boolean;
  maxWidth?: DialogProps['maxWidth'];
};

const ModalConfirmationWindow: React.FC<ModalConfirmationWindowProps> = ({
  open,
  onCloseClick,
  onCloseModalButtonClick,
  onSubmit,
  header,
  okButtonText,
  cancelButtonText,
  children,
  hideActionButtons,
  disableSubmitButton,
  disableCloseButton,
  disableOnlyXCloseButton,
  disableBackdropClick,
  iframeContent,
  showScrollBar,
  fullWidth,
  maxWidth,
  extraButtons
}) => {
  const { t } = useTranslation();
  const { smartphone } = useViewportSize();
  const [loading, setLoading] = useState(false);

  const onClose = async (timeout = 200) => {
    onCloseClick();
    await sleep(timeout);
    setLoading(false);
  };

  const submit = async () => {
    if (onSubmit) {
      setLoading(true);
      await onSubmit();
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  return (
    <DialogStyled
      $iframeContent={iframeContent}
      $showScrollBar={showScrollBar}
      open={open}
      scroll="paper"
      onBackdropClick={async () => !loading && !disableBackdropClick && (await onClose())}
      disableEscapeKeyDown={loading}
      onEscapeKeyDown={async () => !loading && (await onClose())}
      fullScreen={smartphone}
      disableBackdropClick={disableBackdropClick}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogHeaderStyled>
        {header || <span>&nbsp;</span>}
        {!(disableCloseButton || disableOnlyXCloseButton) && (
          <CloseButton
            aria-label="close"
            onClick={async () =>
              !loading && (onCloseModalButtonClick ? await onCloseModalButtonClick() : await onClose())
            }
          >
            <CloseIcon />
          </CloseButton>
        )}
      </DialogHeaderStyled>
      <DialogContentStyled>{children}</DialogContentStyled>
      {!hideActionButtons && (
        <DialogActionsStyled>
          {!disableCloseButton && (
            <Button variant="outlined" onClick={async () => !loading && (await onClose())} size="medium">
              {cancelButtonText || t('common.cancel')}
            </Button>
          )}
          {extraButtons && extraButtons.length > 0 && extraButtons.map((btn) => btn)}
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            size="medium"
            disabled={loading || disableSubmitButton}
            onClick={async () => !loading && (await submit())}
          >
            {okButtonText || t('common.ok')}
          </Button>
        </DialogActionsStyled>
      )}
    </DialogStyled>
  );
};

export default ModalConfirmationWindow;
