import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { AdminUser } from '../AdminUsers/types';

export type MailingListsState = StateWithPagination &
  StateWithTableSearch<MailingList> & {
    data: NormalizedMailingLists;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type MailingList = {
  id: string;
  name: string;
  updatedAt: string;
  createdAt: string;
  externalId: string;
  websiteFormDefault: boolean;
  uniqMailingList: 'uniqMailingList';
  createdBy: AdminUser;
  activeMemberCount: number;
  bouncedMemberCount: number;
  removedMemberCount: number;
  unsubscribedMemberCount: number;
};

export type NormalizedMailingLists = {
  entities: Dictionary<MailingList>;
  result: string[];
};

export type MailingListsIndexResponse = {
  data: MailingList[];
  pagination: Pagination;
};

export type MailingListsIndexActionBEGIN = {
  type: MailingListsActionsConstants.INDEX_BEGIN;
};

export type MailingListsIndexActionSUCCESS = {
  type: MailingListsActionsConstants.INDEX_SUCCESS;
  payload: MailingListsIndexResponse;
};

export type MailingListsIndexActionFAILED = {
  type: MailingListsActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type MailingListsUpdateAction = {
  type: MailingListsActionsConstants.UPDATE_MAILING_LIST;
  payload: {
    mailingList: MailingList;
  };
};

export type IndexMailingListsRequestParams = {
  per: number;
  page: number;
  q: string | null;
  order?: string[];
};

export type CreateMailingListValues = Pick<MailingList, 'name'>;
export type UpdateMailingListValues = Pick<MailingList, 'name'>;

export type MailingListsResponse = {
  data: MailingList[];
};

export enum MailingListsActionsConstants {
  UPDATE_MAILING_LIST = 'MAILING_LISTS/UPDATE',
  INDEX_BEGIN = 'MAILING_LISTS/INDEX/BEGIN',
  INDEX_SUCCESS = 'MAILING_LISTS/INDEX/SUCCESS',
  INDEX_FAILED = 'MAILING_LISTS/INDEX/FAILED'
}

export enum MailingListType {
  TEST = 'TEST',
  MAIN = 'MAIN'
}

export type MailingListsActionType =
  | MailingListsUpdateAction
  | MailingListsIndexActionBEGIN
  | MailingListsIndexActionSUCCESS
  | MailingListsIndexActionFAILED;
