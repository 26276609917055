import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icon, Tooltip, useTheme, withTheme } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ViewCompactOutlinedIcon from '@material-ui/icons/ViewCompactOutlined';
import VerticalSplitOutlinedIcon from '@material-ui/icons/VerticalSplitOutlined';
import { PageContentTextEditorSidebar } from '../../../../PageStyledComponents';
import { AdPlacementStatus, NewsletterSidebarItemType, ProjectModelV2 } from '../../types';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import AttachmentIcon from '@material-ui/icons/Attachment';
import BrandfolderIcon from '../../../../../../assets/icons/wecreate.png';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import { ReactComponent as StoryListIcon } from '../../../../../../assets/icons/stories.svg';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import Brandfolder from './MainSections/Brandfolder';
import Images from './MainSections/Images';
import ColorSwatches from './MainSections/ColorSwatches';
import { EmailProject } from '../../../../../../store/EmailProjects/types';
import { MyOrganizationSwatchColor, MyOrganizationSwatchGroup } from '../../../../../../store/MyOrganization/types';
import { ContentComponents } from './MainSections/ContentComponents';
import { StructureElements } from './MainSections/StructureElements';
import { DesignerStory } from '../../../../../../store/PrintProjects/types';
import { AdminEmailTemplate } from '../../../../../../store/AdminEmailTemplates/types';
import { hasPermission } from '../../../../../../utils/permissions';
import { useTypedSelector } from '../../../../../../utils';
import { VariableElements } from './MainSections/VariableElements';
import CodeIcon from '@material-ui/icons/Code';
import { Story } from '../../../../../../store/Stories/types';
import StoryList from './MainSections/StoryList';
import { isTheme } from '../../../../../../utils/environment';
import { APP_THEME } from '../../../../../../typings/environment';
import EmailAttachments from './MainSections/EmailAttachments';
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import { Settings } from './MainSections/Settings';
import { DamSystemName } from '../../../../../../store/SystemSettings/types';

type TemplateEditorSidebarProps = {
  setActiveTab: (tab: NewsletterSidebarItemType) => void;
  toggleExpand: () => void;
  active: NewsletterSidebarItemType;
  expanded: boolean;
  project: EmailProject | AdminEmailTemplate;
  model: ProjectModelV2;
  setModel: (model: ProjectModelV2) => void;
  swatchesGroups?: MyOrganizationSwatchGroup[];
  colors?: MyOrganizationSwatchColor[];
  selectedColorSwatches: { [key: string]: MyOrganizationSwatchColor };
  applyColor?: (swatchGroup: MyOrganizationSwatchGroup, color: MyOrganizationSwatchColor | null) => void;
  designerStories?: DesignerStory[];
  storyList?: Story[];
  showBrandfolder: boolean;
  showImages: boolean;
  variables: boolean;
  showEmailAttachments: boolean;
  availableAdSpaces: AdPlacementStatus[];
  inProjectEditor: boolean;
  damStatus?: { damActive: boolean; system: DamSystemName | null };
  innerAdjustmentEnabled: boolean;
};

const EditorSidebar: FunctionComponent<TemplateEditorSidebarProps> = ({
  active,
  setActiveTab,
  toggleExpand,
  expanded,
  project,
  swatchesGroups,
  colors,
  applyColor,
  selectedColorSwatches,
  designerStories,
  storyList,
  variables,
  showImages,
  showEmailAttachments,
  showBrandfolder,
  model,
  setModel,
  availableAdSpaces,
  inProjectEditor,
  damStatus,
  innerAdjustmentEnabled
}) => {
  const { t } = useTranslation();
  const role = useTypedSelector((state) => state.auth.role);
  const sidebarWidth = 400;
  const theme = useTheme();

  return (
    <>
      <SidebarButtonsWrapper sidebarWidth={sidebarWidth} expanded={expanded} disabled={innerAdjustmentEnabled}>
        <SidebarTabs>
          <SidebarTab active={true} onClick={toggleExpand} style={{ marginBottom: '20px' }}>
            {expanded ? <NavigateNextIcon /> : <NavigateBeforeIcon />}
          </SidebarTab>
          {hasPermission(role, ['projectsEmailAdvancedEditor']) && (
            <Tooltip title={'General Settings'} placement="left">
              <SidebarTab active={active === 'settings'} onClick={() => setActiveTab('settings')}>
                <Icon>
                  <SettingsApplicationsOutlinedIcon color="secondary" />
                </Icon>
              </SidebarTab>
            </Tooltip>
          )}
          {hasPermission(role, ['projectsEmailAdvancedEditor']) && (
            <Tooltip title={'Structure'} placement="left">
              <SidebarTab active={active === 'structure'} onClick={() => setActiveTab('structure')}>
                <Icon>
                  <ViewCompactOutlinedIcon color="secondary" />
                </Icon>
              </SidebarTab>
            </Tooltip>
          )}
          {hasPermission(role, ['projectsEmailAdvancedEditor']) && (
            <Tooltip title={'Content'} placement="left">
              <SidebarTab active={active === 'content'} onClick={() => setActiveTab('content')}>
                <VerticalSplitOutlinedIcon color="secondary" />
              </SidebarTab>
            </Tooltip>
          )}
          {storyList && (
            <Tooltip title={`${t('common.sidebarTab.storyList')}`} placement="left">
              <SidebarTab active={active === 'storyList'} onClick={() => setActiveTab('storyList')}>
                <Icon>
                  <StoryListIcon fill={theme.palette.secondary.main} width={20} height={20} />
                </Icon>
              </SidebarTab>
            </Tooltip>
          )}
          {showImages && (
            <Tooltip title={`${t('common.sidebarTab.images')}`} placement="left">
              <SidebarTab active={active === 'images'} onClick={() => setActiveTab('images')}>
                <PhotoLibraryIcon color="secondary" />
              </SidebarTab>
            </Tooltip>
          )}
          {showEmailAttachments && (
            <Tooltip title={`${t('common.sidebarTab.emailAttachments')}`} placement="left">
              <SidebarTab active={active === 'emailAttachments'} onClick={() => setActiveTab('emailAttachments')}>
                <AttachmentIcon color="secondary" />
              </SidebarTab>
            </Tooltip>
          )}
          {showBrandfolder && (
            <Tooltip
              title={`${
                damStatus && damStatus.damActive
                  ? t('common.sidebarTab.brandfolder')
                  : t('common.sidebarTab.damNotActive')
              }`}
              placement="left"
            >
              <SidebarTab active={active === 'bandFolder'} onClick={() => setActiveTab('bandFolder')}>
                <Icon>
                  {isTheme(APP_THEME.LPI) ? (
                    <img src={BrandfolderIcon} alt="wecreate" style={{ width: '100%' }} />
                  ) : (
                    <BurstModeIcon color="secondary" />
                  )}
                </Icon>
              </SidebarTab>
            </Tooltip>
          )}
          {swatchesGroups && (
            <Tooltip title={`${t('common.sidebarTab.colorSwatches')}`} placement="left">
              <SidebarTab active={active === 'colorSwatches'} onClick={() => setActiveTab('colorSwatches')}>
                <Icon>
                  <ColorLensIcon color="secondary" />
                </Icon>
              </SidebarTab>
            </Tooltip>
          )}
          {variables && (
            <Tooltip title={`${t('common.sidebarTab.variables')}`} placement="left">
              <SidebarTab active={active === 'variables'} onClick={() => setActiveTab('variables')}>
                <Icon>
                  <CodeIcon color="secondary" />
                </Icon>
              </SidebarTab>
            </Tooltip>
          )}
        </SidebarTabs>
      </SidebarButtonsWrapper>
      <PageContentTextEditorSidebar
        style={{ width: expanded ? sidebarWidth : 0, paddingTop: 0, flex: `0 0 ${expanded ? sidebarWidth : 0}px` }}
        disabled={innerAdjustmentEnabled}
      >
        {active === 'settings' && (
          <Settings
            border={model.settings && model.settings.border}
            setGeneralSettings={(borderSettings, backgroundColor) => {
              setModel({
                ...model,
                settings: {
                  border: {
                    borderRadius: borderSettings.borderRadius === '' ? '0' : borderSettings.borderRadius,
                    borderStyle: borderSettings.borderStyle,
                    borderWidth: borderSettings.borderWidth === '' ? '0' : borderSettings.borderWidth,
                    borderColor: borderSettings.borderColor
                  },
                  backgroundColor: backgroundColor
                }
              });
            }}
            backgroundColor={model.settings && model.settings.backgroundColor}
          />
        )}
        {active === 'structure' && <StructureElements />}
        {active === 'content' && (
          <ContentComponents
            project={project}
            model={model}
            adSpaces={availableAdSpaces}
            inProjectEditor={inProjectEditor}
          />
        )}
        {storyList && active === 'storyList' && (
          <StoryList storyList={storyList} designerStories={designerStories} project={project as EmailProject} />
        )}
        {showBrandfolder && active === 'bandFolder' && <Brandfolder project={project as EmailProject} />}
        {showImages && active === 'images' && <Images project={project as EmailProject} />}
        {showEmailAttachments && active === 'emailAttachments' && (
          <EmailAttachments project={project as EmailProject} />
        )}
        {colors && applyColor && swatchesGroups && active === 'colorSwatches' && (
          <ColorSwatches
            swatches={swatchesGroups}
            applyColor={applyColor}
            currentColors={selectedColorSwatches}
            colors={colors}
          />
        )}
        {active === 'variables' && <VariableElements />}
      </PageContentTextEditorSidebar>
    </>
  );
};

const SidebarTabs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const SidebarTab = styled.div<{ active: boolean }>`
  width: 50px;
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  opacity: ${({ active }) => (active ? '1' : '.4')};
`;

const SidebarButtonsWrapper = withTheme(styled.div<{
  expanded: boolean;
  sidebarWidth: number;
  disabled: boolean;
}>`
  position: fixed;
  top: 125px;
  right: ${({ expanded, sidebarWidth }) => (expanded ? `${sidebarWidth}px` : '0px')};
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
`);

export default EditorSidebar;
