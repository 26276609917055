import React, { FunctionComponent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../PageStyledComponents';
import { createNetworkErrorObject, timeToTimestamp } from '../../../../utils';
import app from '../../../../config/app/app';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { useDispatch } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { adminOrganizationsOperations } from '../../../../store/AdminOrganizations';
import { AdminCreateOrganizationValues } from '../../../../store/AdminOrganizations/types';
import Loader from '../../../Shared/Loading/Loader';
import Toast from '../../../Shared/Toast/Toast';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import AdminOrganizationForm from './Partials/AdminOrganizationForm';
import { adminPasswordPoliciesOperations } from '../../../../store/AdminPasswordPolicies';
import { AdminPasswordPolicy } from '../../../../store/AdminPasswordPolicies/types';

const AdminOrganizationCreatePage: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [globalPasswordPolicyId, setGlobalPasswordPolicyId] = useState<string>('');
  const [passwordPolicies, setPasswordPolicies] = useState<AdminPasswordPolicy[]>([]);

  useEffect(() => {
    const fetchPasswordPolicies = async () => {
      setLoading(true);
      try {
        const policies = await adminPasswordPoliciesOperations.getAll();
        setPasswordPolicies(policies.data);

        const globalPasswordPolicies = policies.data.find((passwordPolicy) => passwordPolicy.isGlobal);
        if (globalPasswordPolicies) {
          setGlobalPasswordPolicyId(globalPasswordPolicies.id);
        }
      } catch (e) {
        Toast.error(t('notifications.common.errorOperation'));
      } finally {
        setLoading(false);
      }
    };

    fetchPasswordPolicies();
  }, []);

  const onSubmit = async (values: AdminCreateOrganizationValues) => {
    try {
      await dispatch(
        adminOrganizationsOperations.create({
          ...values,
          automatedEmailTime: timeToTimestamp(values.automatedEmailTime),
          senderEmail: values.senderEmail === '-1' ? null : values.senderEmail,
          dam: values.dam && values.dam.id && values.dam.id !== '-1' ? values.dam : null,
          printCenters: values.printCenters
        })
      );
      Toast.success(t('notifications.adminOrganizations.successOperation'));
      dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.INDEX));
    } catch (e) {
      const error = createNetworkErrorObject(e);

      switch (error.message) {
        case 'account_number_already_taken':
          return Toast.error(t('notifications.adminOrganizations.accountNumberTaken'));
        default:
          return Toast.error(t('notifications.adminOrganizations.errorOperation'));
      }
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <AdminOrganizationForm props={props} passwordPolicies={passwordPolicies} />
  );

  const backButton = {
    onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.INDEX)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.adminOrganizations.createOrganization')} leftActionButtons={[backButton]} />
      {loading && <Loader />}
      {!loading && (
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            name: '',
            accountNumber: '',
            readMoreLink: `${window.location.origin}/flipbook`,
            passwordPolicyId: globalPasswordPolicyId,
            stripProtocolFromPrintInjectedUrls: true,
            bulletin: true,
            email: true,
            flyer: true,
            newsletter: true,
            directory: true,
            colorSwatchesLimit: null,
            injectLogoToPrintProjects: true,
            emailWizardEnabled: false,
            automatedEmailEnabled: false,
            defaultAutomatedEmailTemplateId: null,
            automatedEmailDateSchedule: null,
            automatedEmailTime: null,
            automatedEmailSubject: null,
            senderEmail: '-1',
            socialPublishing: false,
            dam: null,
            enableCampaigns: true,
            printCenters: {},
            region: null,
            district: null
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(app.maxInputLength).required(),
            accountNumber: Yup.string().max(app.maxInputLength).required(),
            colorSwatchesLimit: Yup.number().min(0).max(200).nullable(),
            injectLogoToPrintProjects: Yup.boolean(),
            stripProtocolFromPrintInjectedUrls: Yup.boolean(),
            socialPublishing: Yup.boolean(),
            bulletin: Yup.boolean(),
            email: Yup.boolean(),
            flyer: Yup.boolean(),
            newsletter: Yup.boolean(),
            directory: Yup.boolean(),
            readMoreLink: Yup.string().nullable(),
            passwordPolicyId: Yup.string().required(),
            emailWizardEnabled: Yup.boolean(),
            automatedEmailEnabled: Yup.boolean(),
            defaultAutomatedEmailTemplateId: Yup.string().nullable(),
            automatedEmailTime: Yup.string().nullable(),
            automatedEmailDateSchedule: Yup.string().nullable(),
            automatedEmailSubject: Yup.string().nullable(),
            senderEmail: Yup.string().nullable(),
            printCenters: Yup.object(),
            enableCampaigns: Yup.boolean(),
            dam: Yup.object()
              .shape({
                id: Yup.string(),
                apiKey: Yup.string()
              })
              .nullable(),
            region: Yup.string().nullable(),
            district: Yup.string().nullable()
          })}
        >
          {formInputs}
        </Formik>
      )}
    </PageContainer>
  );
};

export default AdminOrganizationCreatePage;
