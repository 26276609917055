import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import linksConstants from '../../../config/app/linksConstants';
import { PageContentPaper } from '../PageStyledComponents';
import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import Toast from '../../Shared/Toast/Toast';
import { Strong } from '../../Shared/StyledComponents';
import useOpenHandler from '../../../hooks/useOpenHandler';
import ProjectAvatar from '../../Shared/Avatar/ProjectAvatar';
import moment from 'moment';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import TelegramIcon from '@material-ui/icons/Telegram';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { printProjectsActions, printProjectsOperations } from '../../../store/PrintProjects';
import { FlipBookState, PrintProject, PrintProjectStatus } from '../../../store/PrintProjects/types';
import { ButtonsContainer, TableCellStatus } from './ProjectDetailsPage';
import ProjectPublishPrintWindow from '../Dashboard/Windows/ProjectPublishPrintWindow';
import { User } from '../../../store/Auth/types';
import PublicationDateDetails from './Shared/PublicationDateDetails';
import { hasPermission } from '../../../utils/permissions';
import { formatPublishDate, useTypedSelector } from '../../../utils';
import ProjectNotes from '../Editors/Common/ProjectNotes';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ResetProjectCheckInStatusWindow from '../Dashboard/Windows/ResetProjectCheckInStatusWindow';
import { ProjectActionType } from '../Dashboard/WorkInProgressTable/useHandleProjectAction';
import { AnyProject } from '../../../store/Dashboard/types';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import styled from 'styled-components';
import InputChangeOnBlur from '../../Shared/Forms/InputChangeOnBlur';
import ProjectClonesAccordion from './Shared/ProjectClonesAccordion';
import { CampaignProjectType } from '../../../store/Campaigns/types';
import ProjectCampaignsAccordion from '../Editors/Common/ProjectCampaignsAccordion';
import { getStatusIcon } from '../../Shared/ProjectStatusIcon';

type PrintProjectDetailsPageProps = {
  project: PrintProject;
  user: User;
  forceCheckOut: (project: AnyProject) => void;
  reFetchData: () => void;
};

const PrintProjectDetailsPage: FunctionComponent<PrintProjectDetailsPageProps> = ({
  project,
  user,
  reFetchData,
  forceCheckOut
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);
  const organization = useTypedSelector((state) => state.auth.organization);
  const publishWindow = useTypedSelector((state) => state.printProjects.publishWindow);

  const [onlyPreview, setOnlyPreview] = useState<boolean>(false);
  const publishProjectWindowOpen = publishWindow.opened;
  const onPublishProjectWindowOpen = (prj: PrintProject) => dispatch(printProjectsActions.openPublishWindow(prj));

  const [resetCheckInWindowOpen, onResetCheckInWindowOpen, onResetCheckInWindowClose] = useOpenHandler();

  const hasProcessingErrors = Boolean(project.vertexErrors && project.vertexErrors.length);

  const saveProjectPublishDate = async (date: Date) => {
    try {
      await printProjectsOperations.saveProjectPublishDate(project, date);

      await reFetchData();
      Toast.success(t('notifications.projectPublishDateChange.success'));
    } catch (e) {
      Toast.error(t('notifications.projectPublishDateChange.error'));
    }
  };

  const saveProjectName = async (name: string) => {
    try {
      await printProjectsOperations.savePrintProjectName(project, name);
      Toast.success(t('notifications.projectNameChange.success'));
    } catch (e) {
      Toast.error(t('notifications.projectNameChange.error'));
    }
  };

  const handleProjectAction = async (project: PrintProject, type: ProjectActionType) => {
    switch (type) {
      case 'publishPrint':
        setOnlyPreview(false);
        onPublishProjectWindowOpen(project);
        break;

      case 'preview':
        setOnlyPreview(true);
        onPublishProjectWindowOpen(project);
        break;

      case 'generateFlipBook':
        window.open(linksConstants.FLIPBOOK_PUBLIC + `?pid=${project.id}`);
        break;

      case 'resetCheckIn':
        onResetCheckInWindowOpen();
        break;

      case 'edit':
        dispatch(push(linksConstants.PROJECTS.BULLETIN.EDIT(project.id)));
        break;
    }
  };

  const projectLocked = !!project.checkedInBy && user.id !== project.checkedInBy.id;

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <PageContentPaper>
          <Box display="flex" alignItems="center">
            <ProjectAvatar type={project.type} locked={projectLocked} shared={project.isShared} />
            <div>
              <InputChangeOnBlur initialValue={project.name} onSave={saveProjectName} disabled={projectLocked} />
              <Box fontSize={14} mt={1} ml={1}>
                {project.templateDescription}
              </Box>

              {hasPermission(role, ['debugExternalProjectIdDisplay']) && <ExternalId>{project.externalId}</ExternalId>}
            </div>
          </Box>

          <Typography component="div">
            <Box mt={2}>
              <TableCellStatus status={project.status}>
                {getStatusIcon(project.status, hasProcessingErrors, theme.palette.success.main)}{' '}
                {t(`common.projectStatus.${project.kind}.${project.status}`)}
              </TableCellStatus>
            </Box>
            <Box fontSize="fontSize" mt={1}>
              {t('pages.projectDetails.createdAt')}: {formatPublishDate(project.createdAt)}
            </Box>
            {!project.updatedBy && <>N/A</>}
            {project.updatedBy && (
              <Box fontSize={16} mb={5}>
                <Strong>{project.updatedBy.id === user.id ? t('common.you') : project.updatedBy.fullName}</Strong>{' '}
                {t('pages.dashboard.editedTheProject')} {moment(project.updatedByAt).fromNow()}
              </Box>
            )}
            {project.checkedInBy && (
              <Box fontSize={16} mb={5}>
                {t('common.projectCheckedOutBy')}{' '}
                <Strong>{project.checkedInBy.id === user.id ? t('common.you') : project.checkedInBy.fullName}</Strong>{' '}
                {project.checkedInBy.id === user.id && (
                  <Button onClick={() => forceCheckOut(project)} variant="outlined" color="secondary">
                    {t('pages.dashboard.imNotEditingAnymore')}
                  </Button>
                )}
              </Box>
            )}
          </Typography>
          {project.isShared && <ProjectClonesAccordion project={project} />}
          <Box display="flex" justifyContent="flex-end" mt={5}>
            <ButtonsContainer>
              {project.flipBookState === FlipBookState.DONE && (
                <Button
                  disabled={!hasPermission(role, ['projectsPrintAccess']) || projectLocked}
                  onClick={() => handleProjectAction(project, 'generateFlipBook')}
                  variant="outlined"
                  color="secondary"
                >
                  <MenuBookIcon /> {t('common.flipBook')}
                </Button>
              )}
              <Button
                disabled={!hasPermission(role, ['projectsPrintAccess']) || projectLocked}
                onClick={() => handleProjectAction(project, 'preview')}
                variant="outlined"
                color="secondary"
              >
                <VisibilityOutlinedIcon /> {t('common.preview')}
              </Button>
              <Button
                disabled={!hasPermission(role, ['projectsPrintEdition'])}
                onClick={() => dispatch(push(linksConstants.DASHBOARD.CLONE_PROJECT(project.id)))}
                variant="outlined"
                color="secondary"
              >
                <FileCopyIcon /> {t('common.copy')}
              </Button>
              {project.status === PrintProjectStatus.IN_PROGRESS && (
                <Button
                  disabled={!hasPermission(role, ['projectsPrintPublishing']) || projectLocked}
                  onClick={() => handleProjectAction(project, 'publishPrint')}
                  variant="outlined"
                  color="secondary"
                >
                  <TelegramIcon /> {t('common.scheduleForPublish')}
                </Button>
              )}
              {project.status === PrintProjectStatus.IN_PROGRESS && (
                <Button
                  disabled={!hasPermission(role, ['projectsPrintEdition']) || projectLocked}
                  onClick={() => handleProjectAction(project, 'edit')}
                  variant="contained"
                  color="secondary"
                >
                  <EditOutlinedIcon /> {t('common.edit')}
                </Button>
              )}
              {projectLocked && hasPermission(role, ['projectsPrintResetCheckIn']) && (
                <Button
                  onClick={() => handleProjectAction(project, 'resetCheckIn')}
                  variant="contained"
                  color="secondary"
                >
                  <LockOpenIcon /> {t('common.resetCheckOut')}
                </Button>
              )}
            </ButtonsContainer>
          </Box>
        </PageContentPaper>
        {organization && organization.enableCampaigns && (
          <ProjectCampaignsAccordion projectId={project.id} role={role} user={user} type={CampaignProjectType.PRINT} />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <PageContentPaper>
          <PublicationDateDetails
            disabled={!hasPermission(role, ['projectsPrintPublishing']) || projectLocked}
            project={project}
            onPublishDateUpdate={saveProjectPublishDate}
          />
        </PageContentPaper>
        <ProjectNotes projectId={project.id} type="print" role={role} user={user} isPopover={false} />
      </Grid>

      <ProjectPublishPrintWindow
        open={publishProjectWindowOpen}
        project={project}
        onCloseClick={() => {
          setOnlyPreview(false);
          // dispatching event moved to window component
        }}
        fullScreenOnMobile={true}
        onSuccessfulSubmit={reFetchData}
        onlyPreview={onlyPreview}
      />
      <ResetProjectCheckInStatusWindow
        open={resetCheckInWindowOpen}
        project={project}
        onCloseClick={onResetCheckInWindowClose}
        fullScreenOnMobile={true}
      />
    </>
  );
};

const ExternalId = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  border: 1px solid #9f9e9e;
  background-color: #cccccc;
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 2px 10px;
  opacity: 0.8;
`;

export default PrintProjectDetailsPage;
