import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';

type ProjectAlreadyCheckedInProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  errorMessage: string;
};

const ProjectAlreadyCheckedIn: FunctionComponent<ProjectAlreadyCheckedInProps> = ({
  onCloseClick,
  open,
  errorMessage
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    dispatch(push(linksConstants.DASHBOARD.INDEX));
  };

  return (
    <ModalConfirmationWindow
      open={open}
      disableBackdropClick={true}
      header={t('windows.projectAlreadyCheckedIn.header')}
      okButtonText={t('windows.projectAlreadyCheckedIn.goToDashboard')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
      disableCloseButton={true}
    >
      {errorMessage === 'project_opened_in_another_tab' && (
        <Row>{t('windows.projectAlreadyCheckedIn.openedInAnotherTab')}</Row>
      )}
      {errorMessage !== 'project_opened_in_another_tab' && <Row>{t('windows.projectAlreadyCheckedIn.info')}</Row>}
    </ModalConfirmationWindow>
  );
};

export default ProjectAlreadyCheckedIn;
