import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { PrintCenter } from '../../../../store/SystemSettings/types';
import { systemSettingsOperations } from '../../../../store/SystemSettings';

type DeletePrintCenterWindowProps = {
  open: boolean;
  printCenter: PrintCenter | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  onSuccessfulSubmit?: () => void;
};

const DeletePrintCenterWindow: FunctionComponent<DeletePrintCenterWindowProps> = ({
  onCloseClick,
  printCenter,
  open,
  onSuccessfulSubmit
}) => {
  const { t } = useTranslation();

  if (!printCenter) return null;

  const onSubmit = async () => {
    try {
      await systemSettingsOperations.deletePrintCenter(printCenter.id);

      if (onSuccessfulSubmit) {
        onSuccessfulSubmit();
      }
      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.common.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.systemSettings.printCenters.deletePrintCenterWindowTitle')}
      okButtonText={t('common.remove')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.systemSettings.printCenters.deletePrintCenterWindowContent')}</Row>
      <Row>
        <Strong>{printCenter.name}</Strong>
      </Row>
      <Row>
        <Strong>{printCenter.url}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeletePrintCenterWindow;
