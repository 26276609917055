import { Item } from './CheckboxList';
import { CheckboxState, ItemState } from './OrganizationTree';

export const updateItemStates = (oldState: ItemState[], items: Item[], clickedId: string) => {
  const newState = oldState.map((i) => ({ ...i }));
  // getters
  const getItemState = (id: string) => {
    const itemState = newState.find((i) => i.id === id);
    if (!itemState) return;
    return itemState.state;
  };
  // setters
  const updateParent = (id: string) => {
    const item = items.find((i) => i.id === id);
    if (!item) return;
    const parent = items.find((i) => i.id === item.parentId);
    if (!parent) return;
    const childIds = items.filter((i) => i.parentId === parent.id).map((i) => i.id);
    const childStates = childIds.map((childId) => getItemState(childId));
    if (childStates.length === childStates.filter((s) => s === CheckboxState.CHECKED).length) {
      const newStateToUpdate = newState.find((i) => i.id === parent.id);
      if (!newStateToUpdate) return;
      newStateToUpdate.state = CheckboxState.CHECKED;
    } else if (childStates.length === childStates.filter((s) => s === CheckboxState.UNCHECKED).length) {
      const newStateToUpdate = newState.find((i) => i.id === parent.id);
      if (!newStateToUpdate) return;
      newStateToUpdate.state = CheckboxState.UNCHECKED;
      // } else {
      //   const newStateToUpdate = newState.find((i) => i.id === parent.id);
      //   if (!newStateToUpdate) return;
      //   newStateToUpdate.state = CheckboxState.INDETERMINATE;
    }
    updateParent(parent.id);
  };
  const setUnchecked = (id: string) => {
    const newStateToUpdate = newState.find((i) => i.id === id);
    if (!newStateToUpdate) return;
    newStateToUpdate.state = CheckboxState.UNCHECKED;
    // items
    //   .filter((i) => i.parentId === id)
    //   .map((i) => i.id)
    //   .forEach((childId) => setUnchecked(childId));
    // updateParent(id);
  };
  const setChecked = (id: string) => {
    const newStateToUpdate = newState.find((i) => i.id === id);
    if (!newStateToUpdate) return;
    newStateToUpdate.state = CheckboxState.CHECKED;
    // items
    //   .filter((i) => i.parentId === id)
    //   .map((i) => i.id)
    //   .forEach((childId) => setChecked(childId));
    // updateParent(id);
  };
  // actual logic
  const itemState = getItemState(clickedId);
  if (itemState === CheckboxState.CHECKED) {
    setUnchecked(clickedId);
  } else {
    setChecked(clickedId);
  }
  return newState;
};
