import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { subscribersOperations } from '../../../../../store/Subscribers';
import { Subscriber, UpdateSubscriberValues } from '../../../../../store/Subscribers/types';
import Toast from '../../../../Shared/Toast/Toast';
import { AdminTextInput } from '../../../Admin/Shared/AdminFormInputs';
import ModalFormWindow from '../../../../Shared/Window/ModalFormWindow';
import { FormikProps } from 'formik';
import app from '../../../../../config/app/app';

type UpdateSubscriberWindowProps = {
  open: boolean;
  mailingListId: string;
  subscriber: Subscriber | null;
  onCloseClick: (isFormSubmitted: boolean) => void;
  fullScreenOnMobile: boolean;
};

const UpdateSubscriberWindow: FunctionComponent<UpdateSubscriberWindowProps> = ({
  mailingListId,
  subscriber,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!subscriber) return null;

  const onSubmit = async (values: UpdateSubscriberValues) => {
    try {
      await dispatch(
        subscribersOperations.update(mailingListId, subscriber.id, {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email
        })
      );
      Toast.success(t('notifications.subscribers.successOperation'));

      onCloseClick(true);
    } catch (e) {
      Toast.error(t('notifications.subscribers.errorOperation'));
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdminTextInput t={t} name="email" section="subscribers" disabled />
      <AdminTextInput t={t} name="firstName" section="subscribers" />
      <AdminTextInput t={t} name="lastName" section="subscribers" />
    </>
  );

  return (
    <ModalFormWindow
      open={open}
      header={t('pages.subscribers.windows.updateSubscriber')}
      okButtonText={t('common.update')}
      onCloseClick={() => onCloseClick(false)}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{ firstName: subscriber.firstName, lastName: subscriber.lastName, email: subscriber.email }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .max(app.maxInputLength)
          .test(
            'You cannot use characters <, >, ", \', %, &, +, ?',
            'You cannot use characters <, >, ", \', %, &, +, ?',
            (value: string) => !(value || '').match(/[<>"'%&+?]/)
          )
          .nullable(),
        lastName: Yup.string()
          .max(app.maxInputLength)
          .test(
            'You cannot use characters <, >, ", \', %, &, +, ?',
            'You cannot use characters <, >, ", \', %, &, +, ?',
            (value: string) => !(value || '').match(/[<>"'%&+?]/)
          )
          .nullable(),
        email: Yup.string().email().required()
      })}
    />
  );
};

export default UpdateSubscriberWindow;
