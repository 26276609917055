import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TableRow, TableCell, withTheme } from '@material-ui/core';
import { AdvertiserBannerStatisticsData } from '../../../../store/AdminAds/types';

type AdsStatisticsBannersSectionProps = {
  banner: AdvertiserBannerStatisticsData;
  isLast?: boolean;
};

const AdsStatisticsBannersSection: FunctionComponent<AdsStatisticsBannersSectionProps> = ({
  banner,
  isLast = false
}) => {
  const noBorderStyle = {
    border: 0
  };

  const ctr = Math.round(((parseInt(banner.clicks) / parseInt(banner.impressions)) * 100 + Number.EPSILON) * 100) / 100;

  return (
    <TableRow>
      <TableCell style={isLast ? {} : noBorderStyle} />
      <TableCell style={isLast ? {} : noBorderStyle}>
        <Title>{banner.bannername}</Title>
      </TableCell>
      <TableCell style={isLast ? {} : noBorderStyle}>{banner.impressions}</TableCell>
      <TableCell style={isLast ? {} : noBorderStyle}>{banner.clicks}</TableCell>
      <TableCell style={isLast ? {} : noBorderStyle}>{isNaN(ctr) ? 0 : ctr}%</TableCell>
    </TableRow>
  );
};

const TextHolder = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-width: 12rem;
`;
const Title = withTheme(styled(TextHolder)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
`);

export default AdsStatisticsBannersSection;
