import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { MyOrganizationUser } from '../../../../store/MyOrganization/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import { StoryContentContributor } from '../../../../store/Stories/types';

type DeleteMyOrganizationUserWindowProps = {
  type: 'user' | 'contributor';
  open: boolean;
  organizationId: string;
  organizationUser: MyOrganizationUser | StoryContentContributor | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  onSuccessfulSubmit?: () => void;
};

const DeleteMyOrganizationUserWindow: FunctionComponent<DeleteMyOrganizationUserWindowProps> = ({
  type,
  organizationUser,
  onCloseClick,
  organizationId,
  open,
  onSuccessfulSubmit
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!organizationUser) return null;

  const onSubmit = async () => {
    try {
      if (type === 'user') {
        await dispatch(myOrganizationOperations.deleteOrganizationUser(organizationId, organizationUser.id));
        await dispatch(myOrganizationOperations.index(organizationId));
        Toast.success(t('notifications.myOrganization.successOperation'));
      }
      if (type === 'contributor') {
        await dispatch(myOrganizationOperations.deleteOrganizationContributor(organizationId, organizationUser.id));
        Toast.success(t('notifications.myOrganization.successOperation'));
      }

      if (onSuccessfulSubmit) {
        onSuccessfulSubmit();
      }
      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.myOrganization.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t(
        `pages.myOrganization.windows.${type === 'user' ? 'deleteOrganizationUser' : 'deleteOrganizationContributor'}`
      )}
      okButtonText={t(`pages.myOrganization.windows.completelyDelete${type === 'user' ? 'User' : 'Contributor'}`)}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>
        {t(`pages.myOrganization.windows.deleteOrganization${type === 'user' ? 'User' : 'Contributor'}Content`)}
      </Row>
      <Row>
        <Strong>{organizationUser.email}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteMyOrganizationUserWindow;
