import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../../Shared/Loading/Loader';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import Toast from '../../../Shared/Toast/Toast';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import { authActions } from '../../../../store/Auth';
import { MyOrganizationSwatchColor, MyOrganizationSwatchColorResponse } from '../../../../store/MyOrganization/types';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import CreateOrUpdateSwatchColorWindow from '../Windows/CreateOrUpdateSwatchColorWindow';
import DeleteSwatchColorWindow from '../Windows/DeleteSwatchColorWindow';
import OrganizationLogoForm from './OrganizationLogoForm';
import { hasPermission } from '../../../../utils/permissions';
import { useTypedSelector } from '../../../../utils';
import { useDispatch } from 'react-redux';

type OrganizationSwatchesFormProps = {};

const OrganizationSwatchesForm: FunctionComponent<OrganizationSwatchesFormProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [colors, setColors] = useState<MyOrganizationSwatchColorResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(0);
  const [organizationSwatchColor, setOrganizationSwatchColor] = useState<MyOrganizationSwatchColor | null>(null);
  const role = useTypedSelector((state) => state.auth.role);
  const currentOrganizationId = useTypedSelector((state) => state.auth.organizationId);

  const theme = useTheme();
  const gridSpacing = useMediaQuery(theme.breakpoints.down('sm')) ? 0 : 3;

  useEffect(() => {
    fetchData();
  }, [currentOrganizationId]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const colors = await myOrganizationOperations.getOrganizationColors();
      setColors({ ...colors, data: colors.data });
      setLimit(colors.colorSwatchLimit);
      dispatch(
        authActions.updateColors(
          colors.data.map((color) => ({
            name: color.name,
            hex: color.hex
          }))
        )
      );
    } catch (e) {
      Toast.error(t('notifications.myOrganization.errorOperation'));
    } finally {
      setLoading(false);
    }
  };

  const [createSwatchColorWindowOpen, onCreateSwatchColorWindowOpen, onCreateSwatchColorWindowClose] = useOpenHandler();
  const [updateSwatchColorWindowOpen, onUpdateSwatchColorWindowOpen, onUpdateSwatchColorWindowClose] = useOpenHandler();
  const [deleteSwatchColorWindowOpen, onDeleteSwatchColorWindowOpen, onDeleteSwatchColorWindowClose] = useOpenHandler();

  const onAddColorClick = () => {
    onCreateSwatchColorWindowOpen();
  };

  const onUpdateColorClick = (swatchColor: MyOrganizationSwatchColor) => {
    setOrganizationSwatchColor(swatchColor);
    onUpdateSwatchColorWindowOpen();
  };

  const onDeleteColorClick = (swatchColor: MyOrganizationSwatchColor) => {
    setOrganizationSwatchColor(swatchColor);
    onDeleteSwatchColorWindowOpen();
  };

  const refreshSwatches = () => {
    fetchData();
  };

  const renderColor = (color: MyOrganizationSwatchColor) => {
    return (
      <Color color={color.hex} border="solid" key={color.id}>
        <ColorIcons>
          {hasPermission(role, ['swatchesEdit']) && (
            <Tooltip title={`${t('common.edit')}`} placement="top" arrow>
              <ColorIcon onClick={() => onUpdateColorClick(color)}>
                <EditIcon fontSize="small" />
              </ColorIcon>
            </Tooltip>
          )}
          {hasPermission(role, ['swatchesRemove']) && (
            <Tooltip title={`${t('common.remove')}`} placement="top" arrow>
              <ColorIcon onClick={() => onDeleteColorClick(color)}>
                <DeleteIcon fontSize="small" />
              </ColorIcon>
            </Tooltip>
          )}
        </ColorIcons>
      </Color>
    );
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && colors && (
        <>
          <Typography variant="h6" gutterBottom>
            {t('pages.myOrganization.swatchColor.organizationBranding')}
          </Typography>
          <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
          <Grid container spacing={gridSpacing}>
            {hasPermission(role, ['swatchesView']) && (
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="primary" gutterBottom>
                  {t('pages.myOrganization.swatchColor.colorPalette')} ({colors.data.length}/
                  {limit === 0 ? t('common.unlimited') : limit})
                </Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {colors.data.map((color) => {
                    return renderColor(color);
                  })}
                  {hasPermission(role, ['swatchesAdd']) && colors.data.length < limit && (
                    <Tooltip title={`${t('pages.myOrganization.swatchColor.addColor')}`} placement="top" arrow>
                      <Color color={`rgba(255, 255, 255, 1)`} border="dashed" onClick={() => onAddColorClick()}>
                        +
                      </Color>
                    </Tooltip>
                  )}
                </div>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <OrganizationLogoForm />
            </Grid>
          </Grid>
        </>
      )}
      <CreateOrUpdateSwatchColorWindow
        type="create"
        open={createSwatchColorWindowOpen}
        onCloseClick={onCreateSwatchColorWindowClose}
        onSubmit={refreshSwatches}
        fullScreenOnMobile
      />

      {organizationSwatchColor && (
        <CreateOrUpdateSwatchColorWindow
          type="update"
          open={updateSwatchColorWindowOpen}
          onCloseClick={() => {
            setOrganizationSwatchColor(null);
            onUpdateSwatchColorWindowClose();
          }}
          onSubmit={async () => {
            setOrganizationSwatchColor(null);
            await fetchData();
          }}
          fullScreenOnMobile
          swatchColor={organizationSwatchColor}
        />
      )}

      {organizationSwatchColor && (
        <DeleteSwatchColorWindow
          open={deleteSwatchColorWindowOpen}
          onCloseClick={onDeleteSwatchColorWindowClose}
          onSubmit={refreshSwatches}
          fullScreenOnMobile
          swatchColor={organizationSwatchColor}
        />
      )}
    </>
  );
};

const ColorIcons = styled.div`
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
`;

const ColorIcon = styled.div`
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  width: 24px;
  color: rgba(0, 0, 0, 0.8);
`;

const Color = styled.div<{ color: string; border: string }>`
  width: 80px;
  height: 80px;
  border-radius: 6px;
  border: 1px ${({ border }) => border} #ccc;
  margin-right: 1rem;
  margin-bottom: 1rem;
  background-color: ${({ color }) => color};
  text-align: center;
  line-height: 80px;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
  &:hover ${ColorIcons} {
    visibility: visible;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 26px;
    line-height: 24px;
  }
`;

export default OrganizationSwatchesForm;
