import React, { FunctionComponent } from 'react';
import { Placeholder } from '../../types';

type PlaceholderProps = {
  data: Placeholder;
};

const PlaceholderComponent: FunctionComponent<PlaceholderProps> = ({ data }) => {
  return <div style={data.style} />;
};

export default PlaceholderComponent;
