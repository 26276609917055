import React, { FunctionComponent } from 'react';
import { FormWysiwygEditorProps } from './types';
import { Field, FieldProps } from 'formik';
import getFormInputError from './getFormInputError';
import { useTranslation } from 'react-i18next';
import WysiwygEditor from '../../Pages/Editors/EmailDndEditor/Partials/Components/Utils/WysiwygEditor';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core';
import { AdminInputLabel } from '../../Pages/Admin/AdminStyledComponents';

const FormWysiwygEditor: FunctionComponent<FormWysiwygEditorProps> = (props) => {
  const { formikFieldProps, customErrorMessagePath, wysiwygProps } = props;
  const { t, i18n } = useTranslation();

  return (
    <Field name={formikFieldProps.name}>
      {(fieldProps: FieldProps) => {
        const { field } = fieldProps;
        const error = getFormInputError(fieldProps, t, customErrorMessagePath);
        const hasError = !!(error[0] || (wysiwygProps && wysiwygProps.required && !field.value));
        const errorMessage =
          error[1] ||
          (wysiwygProps && wysiwygProps.required && !field.value && t('formValidationErrors.genericRequired')) ||
          '';

        return (
          <>
            <div style={{ display: 'flex' }}>
              <AdminInputLabel>{wysiwygProps.label}</AdminInputLabel> <ErrorMessage>{errorMessage}</ErrorMessage>
            </div>
            <WysiwygEditor
              inline={false}
              toolbar={wysiwygProps.toolbar}
              disabled={wysiwygProps.disabled}
              height={wysiwygProps.height}
              content={field.value}
              onBlur={(content) => {
                fieldProps.form.setFieldValue(field.name, content);
              }}
            />
          </>
        );
      }}
    </Field>
  );
};

const ErrorMessage = withTheme(styled(AdminInputLabel)`
  color: ${({ theme }) => theme.palette.error.main};
  margin-left: 8px;
`);

export default FormWysiwygEditor;
