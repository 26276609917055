import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikProps, useFormikContext } from 'formik';
import {
  StoryBlogChannelValues,
  StoryEmailChannelValues,
  StoryPrintChannelValues,
  StorySimpleSocialChannelValues
} from '../../../../store/Stories/types';
import _ from 'lodash';

type ChannelFormButtonsProps = {
  props:
    | FormikProps<StoryPrintChannelValues>
    | FormikProps<StoryEmailChannelValues>
    | FormikProps<StoryBlogChannelValues>
    | FormikProps<StorySimpleSocialChannelValues>;
  // deleteChannel: () => void;
  saveChannel?: () => void;
  disabled: boolean;
};

const ChannelFormButtons: FunctionComponent<ChannelFormButtonsProps> = ({
  props,
  // deleteChannel,
  saveChannel,
  disabled
}) => {
  const { t } = useTranslation();

  // formik autosave functionality
  const debounceMs = 1000;
  const formik = useFormikContext();
  const debouncedSubmit = useCallback(_.debounce(formik.submitForm, debounceMs), [formik.submitForm, debounceMs]);

  useEffect(() => {
    formik.dirty && debouncedSubmit();
  }, [debouncedSubmit, formik.dirty, formik.values]);

  return (
    <Box display="flex" justifyContent="flex-end" mt={1}>
      {/* <Button color="primary" variant="contained" onClick={deleteChannel} size="medium" disabled={disabled}>
        {t('pages.story.deleteChannel')}
      </Button> */}
      <Button
        color="secondary"
        variant="contained"
        onClick={saveChannel}
        size="medium"
        disabled={props.isSubmitting || !props.isValid || disabled}
      >
        {props.isSubmitting ? t('common.saving') : t('common.save')}
      </Button>
    </Box>
  );
};

export default ChannelFormButtons;
