import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core';
import Toast from '../../../Shared/Toast/Toast';
import { DamSystem, DamSystemForm } from '../../../../store/SystemSettings/types';
import { systemSettingsOperations } from '../../../../store/SystemSettings';
import Loader from '../../../Shared/Loading/Loader';
import { hasPermission } from '../../../../utils/permissions';
import { AdminRole } from '../../../../store/AdminRoles/types';
import { Alert } from '@material-ui/lab';
import { handleDamSystemError } from '../../../../utils/errorCodeToToast';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import LanguageIcon from '@material-ui/icons/Language';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDamWindow from '../Windows/DeleteDamWindow';
import CreateOrUpdateDamSystem from '../Windows/CreateOrUpdateDamSystem';

type DamSystemsProps = {
  role: AdminRole;
};

const DamSystems: FunctionComponent<DamSystemsProps> = ({ role }) => {
  const { t } = useTranslation();
  const [dams, setDams] = useState<DamSystem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [activeDam, setActiveDam] = useState<DamSystem | null>(null);
  const [deleteDamWindowOpen, onDeleteDamWindowOpen, onDeleteDamWindowClose] = useOpenHandler();
  const [updateDamWindowOpen, onUpdateDamWindowOpen, onUpdateDamWindowClose] = useOpenHandler();
  const [createDamWindowOpen, onCreateDamWindowOpen, onCreateDamWindowClose] = useOpenHandler();

  const fetchDams = async () => {
    setLoading(true);
    try {
      const response = await systemSettingsOperations.getDamSystems();
      setDams(response.data);
    } catch (e) {
      Toast.error(t('notifications.dams.getDamsError'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDams();
  }, []);

  const hasPermissionsToEdit = hasPermission(role, ['systemSettingsManage']);

  return (
    <>
      {loading && <Loader />}

      {!loading && (
        <>
          <Box marginTop={5} marginBottom={1}>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              {t('pages.systemSettings.dam.title')}
              <Button
                color="secondary"
                disabled={!hasPermissionsToEdit}
                size="small"
                variant="contained"
                style={{
                  marginLeft: 12
                }}
                onClick={() => {
                  setActiveDam(null);
                  onCreateDamWindowOpen();
                }}
              >
                + {t('pages.systemSettings.dam.addNew')}
              </Button>
            </Typography>
          </Box>
          {dams.length === 0 && (
            <Alert variant="outlined" severity="info">
              {t('pages.systemSettings.dam.noDams')}
            </Alert>
          )}
          {dams.length > 0 && (
            <List dense>
              {dams.map((dam) => (
                <React.Fragment key={dam.id}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <LanguageIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t(`common.damSystem.${dam.name}`)}
                      secondary={
                        <Link href={dam.url} target="_blank">
                          {dam.url}
                        </Link>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="update"
                        disabled={!hasPermissionsToEdit}
                        onClick={() => {
                          setActiveDam(dam);
                          onUpdateDamWindowOpen();
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        disabled={!hasPermissionsToEdit}
                        aria-label="delete"
                        onClick={() => {
                          setActiveDam(dam);
                          onDeleteDamWindowOpen();
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )}
          {deleteDamWindowOpen && (
            <DeleteDamWindow
              open={deleteDamWindowOpen}
              dam={activeDam}
              onCloseClick={onDeleteDamWindowClose}
              onSuccessfulSubmit={fetchDams}
              fullScreenOnMobile
            />
          )}
          {createDamWindowOpen && (
            <CreateOrUpdateDamSystem
              type="create"
              open={createDamWindowOpen}
              onCloseClick={onCreateDamWindowClose}
              onSubmit={async (formData: DamSystemForm) => {
                try {
                  await systemSettingsOperations.createDamSystem(formData);
                  onCreateDamWindowClose();
                  Toast.success(t('notifications.dams.created'));
                  await fetchDams();
                } catch (e) {
                  handleDamSystemError(e, t);
                }
              }}
              fullScreenOnMobile
            />
          )}
          {updateDamWindowOpen && (
            <CreateOrUpdateDamSystem
              type="update"
              open={updateDamWindowOpen}
              onCloseClick={onUpdateDamWindowClose}
              onSubmit={async (formData: DamSystemForm) => {
                if (activeDam) {
                  try {
                    await systemSettingsOperations.updateDamSystem(activeDam.id, formData);
                    onUpdateDamWindowClose();
                    Toast.success(t('notifications.dams.updated'));
                    await fetchDams();
                  } catch (e) {
                    handleDamSystemError(e, t);
                  }
                }
              }}
              dam={activeDam}
              fullScreenOnMobile
            />
          )}
        </>
      )}
    </>
  );
};

export default DamSystems;
