import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { PrintProjectTemplateTypeName } from '../Templates/types';
import { AdminKeyword, AdminKeywordGroup } from '../AdminKeywords/types';

export type AdminPrintTemplatesState = StateWithPagination &
  StateWithTableSearch<AdminPrintTemplate> & {
    templates: NormalizedAdminPrintTemplates;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedAdminPrintTemplates = {
  entities: Dictionary<AdminPrintTemplate>;
  result: string[];
};

export type AdminPrintTemplatesIndexResponse = {
  data: AdminPrintTemplate[];
  pagination: Pagination;
};

export type AdminPrintTemplatesIndexActionBEGIN = {
  type: AdminPrintTemplatesActionsConstants.INDEX_BEGIN;
};

export type AdminPrintTemplatesIndexActionSUCCESS = {
  type: AdminPrintTemplatesActionsConstants.INDEX_SUCCESS;
  payload: AdminPrintTemplatesIndexResponse;
};

export type IndexAdminPrintTemplatesRequestParams = {
  per: number;
  page: number;
  order?: string[];
  q: string | null;
};
export type AdminCreatePrintTemplateValues = Pick<
  AdminPrintTemplate,
  'name' | 'description' | 'type' | 'designProject'
>;
export type AdminUpdatePrintTemplateValues = Pick<
  AdminPrintTemplate,
  'name' | 'description' | 'type' | 'designProject'
>;

export type AdminPrintTemplatesIndexActionFAILED = {
  type: AdminPrintTemplatesActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type AdminPrintTemplatesKeywordAddedAction = {
  type: AdminPrintTemplatesActionsConstants.KEYWORD_ADDED;
  payload: AdminPrintTemplate;
};

export type AdminPrintTemplatesKeywordGroupAddedAction = {
  type: AdminPrintTemplatesActionsConstants.KEYWORD_GROUP_ADDED;
  payload: AdminPrintTemplate;
};

export type AdminPrintTemplatesKeywordRemovedAction = {
  type: AdminPrintTemplatesActionsConstants.KEYWORD_REMOVED;
  payload: AdminPrintTemplate;
};

export type AdminPrintTemplatesKeywordGroupRemovedAction = {
  type: AdminPrintTemplatesActionsConstants.KEYWORD_GROUP_REMOVED;
  payload: AdminPrintTemplate;
};

export type AdminPrintTemplate = {
  id: string;
  uniqPrint: 'print';
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  uploadedAt: string;
  uploaded: boolean;
  keywords: AdminKeyword[];
  keywordGroups: AdminKeywordGroup[];
  type: PrintProjectTemplateTypeName;
  thumbnailUrl: string | null;
  uniqAdminPrintTemplate: 'uniqAdminPrintTemplate';
  designProject: boolean;
};

export type AdminPrintTemplatesResponse = {
  data: AdminPrintTemplate[];
};

export enum AdminPrintTemplatesActionsConstants {
  KEYWORD_ADDED = 'ADMIN_TEMPLATES/PRINT/KEYWORD_ADDED',
  KEYWORD_REMOVED = 'ADMIN_TEMPLATES/PRINT/KEYWORD_REMOVED',
  KEYWORD_GROUP_ADDED = 'ADMIN_TEMPLATES/PRINT/KEYWORD_GROUP_ADDED',
  KEYWORD_GROUP_REMOVED = 'ADMIN_TEMPLATES/PRINT/KEYWORD_GROUP_REMOVED',
  INDEX_BEGIN = 'ADMIN_TEMPLATES/PRINT/INDEX/BEGIN',
  INDEX_SUCCESS = 'ADMIN_TEMPLATES/PRINT/INDEX/SUCCESS',
  INDEX_FAILED = 'ADMIN_TEMPLATES/PRINT/INDEX/FAILED'
}

export type AdminPrintTemplatesActionType =
  | AdminPrintTemplatesKeywordAddedAction
  | AdminPrintTemplatesKeywordRemovedAction
  | AdminPrintTemplatesKeywordGroupAddedAction
  | AdminPrintTemplatesKeywordGroupRemovedAction
  | AdminPrintTemplatesIndexActionBEGIN
  | AdminPrintTemplatesIndexActionSUCCESS
  | AdminPrintTemplatesIndexActionFAILED;
