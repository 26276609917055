import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../components/Shared/DataTable/types';
import { EmailProjectTemplateTypeName } from '../Templates/types';
import { AdminKeyword, AdminKeywordGroup } from '../AdminKeywords/types';

export type AdminEmailTemplatesState = StateWithPagination &
  StateWithTableSearch<AdminEmailTemplate> & {
    templates: NormalizedAdminEmailTemplates;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedAdminEmailTemplates = {
  entities: Dictionary<AdminEmailTemplate>;
  result: string[];
};

export type AdminEmailTemplatesIndexResponse = {
  data: AdminEmailTemplate[];
  pagination: Pagination;
};

export type AdminEmailTemplatesIndexActionBEGIN = {
  type: AdminEmailTemplatesActionsConstants.INDEX_BEGIN;
};

export type AdminEmailTemplatesIndexActionSUCCESS = {
  type: AdminEmailTemplatesActionsConstants.INDEX_SUCCESS;
  payload: AdminEmailTemplatesIndexResponse;
};

export type IndexAdminEmailTemplatesRequestParams = {
  per: number;
  page: number;
  order?: string[];
  q: string | null;
};
export type AdminCreateEmailTemplateValues = Pick<
  AdminEmailTemplate,
  'name' | 'description' | 'type' | 'overrideAdZones'
>;
export type AdminUpdateEmailTemplateValues = Pick<
  AdminEmailTemplate,
  'name' | 'description' | 'type' | 'overrideAdZones'
>;

export type AdminEmailTemplatesIndexActionFAILED = {
  type: AdminEmailTemplatesActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type AdminEmailTemplatesKeywordAddedAction = {
  type: AdminEmailTemplatesActionsConstants.KEYWORD_ADDED;
  payload: AdminEmailTemplate;
};

export type AdminEmailTemplatesKeywordGroupAddedAction = {
  type: AdminEmailTemplatesActionsConstants.KEYWORD_GROUP_ADDED;
  payload: AdminEmailTemplate;
};

export type AdminEmailTemplatesKeywordRemovedAction = {
  type: AdminEmailTemplatesActionsConstants.KEYWORD_REMOVED;
  payload: AdminEmailTemplate;
};

export type AdminEmailTemplatesKeywordGroupRemovedAction = {
  type: AdminEmailTemplatesActionsConstants.KEYWORD_GROUP_REMOVED;
  payload: AdminEmailTemplate;
};

export type AdminEmailTemplate = {
  id: string;
  uniqEmail: 'email';
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  uploadedAt: string;
  uploaded: boolean;
  automatedDefault: boolean;
  overrideAdZones: string | null;
  keywords: AdminKeyword[];
  keywordGroups: AdminKeywordGroup[];
  type: EmailProjectTemplateTypeName;
  thumbnailUrl: string | null;
  uniqAdminEmailTemplate: 'uniqAdminEmailTemplate';
};

export type AdminEmailTemplatesResponse = {
  data: AdminEmailTemplate[];
};

export enum AdminEmailTemplatesActionsConstants {
  KEYWORD_ADDED = 'ADMIN_TEMPLATES/EMAIL/KEYWORD_ADDED',
  KEYWORD_REMOVED = 'ADMIN_TEMPLATES/EMAIL/KEYWORD_REMOVED',
  KEYWORD_GROUP_REMOVED = 'ADMIN_TEMPLATES/EMAIL/KEYWORD_GROUP_REMOVED',
  KEYWORD_GROUP_ADDED = 'ADMIN_TEMPLATES/EMAIL/KEYWORD_GROUP_ADDED',
  INDEX_BEGIN = 'ADMIN_TEMPLATES/EMAIL/INDEX/BEGIN',
  INDEX_SUCCESS = 'ADMIN_TEMPLATES/EMAIL/INDEX/SUCCESS',
  INDEX_FAILED = 'ADMIN_TEMPLATES/EMAIL/INDEX/FAILED'
}

export type AdminEmailTemplatesActionType =
  | AdminEmailTemplatesKeywordAddedAction
  | AdminEmailTemplatesKeywordRemovedAction
  | AdminEmailTemplatesKeywordGroupRemovedAction
  | AdminEmailTemplatesKeywordGroupAddedAction
  | AdminEmailTemplatesIndexActionBEGIN
  | AdminEmailTemplatesIndexActionSUCCESS
  | AdminEmailTemplatesIndexActionFAILED;
