import { Keyword } from '../Keywords/types';

/** Print Template Type Names */
export enum PrintProjectTemplateTypeName {
  BULLETIN = 'bulletin',
  FLYER = 'flyer',
  NEWSLETTER = 'newsletter',
  DIRECTORY = 'directory'
}
export const printProjectTemplateTypeName: PrintProjectTemplateTypeName[] = [
  PrintProjectTemplateTypeName.BULLETIN,
  PrintProjectTemplateTypeName.FLYER,
  PrintProjectTemplateTypeName.NEWSLETTER,
  PrintProjectTemplateTypeName.DIRECTORY
];

/** Email Template Type Names */
export enum EmailProjectTemplateTypeName {
  EMAIL = 'email'
}
export const emailProjectTemplateTypeName = [EmailProjectTemplateTypeName.EMAIL];

/** ALL Combined template type names */
export enum TemplateTypeName {
  BULLETIN = 'bulletin',
  FLYER = 'flyer',
  NEWSLETTER = 'newsletter',
  DIRECTORY = 'directory',
  EMAIL = 'email'
}
export const templateTypeName = [
  TemplateTypeName.BULLETIN,
  TemplateTypeName.EMAIL,
  TemplateTypeName.FLYER,
  TemplateTypeName.NEWSLETTER,
  TemplateTypeName.DIRECTORY
];

export type Template = {
  id: string;
  name: string;
  description: string;
  type: TemplateTypeName;
  keywords: Keyword[];
  thumbnailUrl: string | null;
};

export type TemplateType = {
  id: string;
  name: TemplateTypeName;
};

export type UserTemplatesResponse = {
  data: Template[];
};

export type TemplateTypesResponse = {
  data: TemplateType[];
};
