import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminUser, AdminVerifyUserPayload } from '../../../../../store/AdminUsers/types';
import { adminUsersOperations } from '../../../../../store/AdminUsers';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import SetPasswordForm from '../../../Auth/ValidateAndSetPassword/SetPasswordForm';
import { createNetworkErrorObject } from '../../../../../utils';
import Toast from '../../../../Shared/Toast/Toast';
import { PasswordPolicy } from '../../../../../store/SystemSettings/types';
import Loader from '../../../../Shared/Loading/Loader';

type AdminVerifyUserWindowProps = {
  open: boolean;
  user: AdminUser | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminVerifyUserWindow: FunctionComponent<AdminVerifyUserWindowProps> = ({ user, onCloseClick, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formSubmitError, setFormSubmitError] = useState<NetworkError | null>(null);
  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicy | null>(null);

  useEffect(() => {
    const fetchPasswordPolicy = async () => {
      if (user) {
        setPasswordPolicy(await adminUsersOperations.getUserPasswordPolicy(user.id));
      }
    };
    if (open) {
      setFormSubmitError(null);
      fetchPasswordPolicy();
    }
  }, [open]);

  if (!user) return null;

  const onSubmit = async (values: AdminVerifyUserPayload) => {
    setFormSubmitError(null);
    try {
      await dispatch(adminUsersOperations.verify(user.id, values));
      Toast.success(t('notifications.adminUsers.successOperation'));
      await dispatch(adminUsersOperations.index());

      onCloseClick();
    } catch (e) {
      const networkError = createNetworkErrorObject(e);
      setFormSubmitError(networkError);
      Toast.error(t('notifications.adminUsers.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminUsers.windows.manuallyVerify')}
      onCloseClick={onCloseClick}
      hideActionButtons
    >
      {!passwordPolicy && <Loader />}
      {passwordPolicy && (
        <SetPasswordForm handleSubmit={onSubmit} formSubmitError={formSubmitError} passwordPolicy={passwordPolicy} />
      )}
    </ModalConfirmationWindow>
  );
};

export default AdminVerifyUserWindow;
