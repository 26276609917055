import update from 'immutability-helper';
import {
  AdminOrganization,
  AdminOrganizationsActionsConstants,
  AdminOrganizationsActionType,
  AdminOrganizationsState
} from './types';
import { normalizeOrganizations } from '../helpers';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: AdminOrganizationsState = {
  organizations: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const adminOrganizationsPaginationReducer = createPaginationReducer<AdminOrganizationsState, AdminOrganization>(
  PaginationStoreModule.ADMIN_ORGANIZATIONS
);

const adminOrganizationsTableSearchReducer = createTableSearchReducer<AdminOrganization, AdminOrganizationsState>(
  TableSearchStoreModule.ADMIN_ORGANIZATIONS
);

export default function adminOrganizationsReducer(
  state = initialState,
  action: WithLogoutAction<AdminOrganizationsActionType>
) {
  switch (action.type) {
    case AdminOrganizationsActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case AdminOrganizationsActionsConstants.INDEX_SUCCESS: {
      const data = normalizeOrganizations(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        organizations: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case AdminOrganizationsActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        organizations: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AdminOrganizationsActionsConstants.KEYWORD_GROUP_REMOVED:
    case AdminOrganizationsActionsConstants.KEYWORD_GROUP_ADDED:
    case AdminOrganizationsActionsConstants.KEYWORD_REMOVED:
    case AdminOrganizationsActionsConstants.KEYWORD_ADDED: {
      return update(state, {
        organizations: {
          entities: {
            [action.payload.id]: {
              keywords: { $set: action.payload.keywords },
              keywordGroups: { $set: action.payload.keywordGroups }
            }
          }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return adminOrganizationsTableSearchReducer(
        adminOrganizationsPaginationReducer(state, action),
        action
      ) as AdminOrganizationsState;
    }
  }
}
