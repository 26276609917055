import React, { useEffect, useState } from 'react';
import { PageContentPaper } from '../../PageStyledComponents';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import { Box, Button, Grid } from '@material-ui/core';
import { storiesOperations } from '../../../../store/Stories';
import Toast from '../../../Shared/Toast/Toast';
import { Story } from '../../../../store/Stories/types';
import Loader from '../../../Shared/Loading/Loader';
import InviteStoryContributorWindow from '../Windows/InviteStoryContributorWindow';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import OrganizationContributorsList from '../../MyOrganization/Partials/OrganizationContributorsList';
import StoryContributorsTable from '../Contributors/StoryContributorsTable';

type ContentContributorTabProps = {
  storyId: string;
};

const ContentContributorTab: React.FC<ContentContributorTabProps> = ({ storyId }) => {
  const { t } = useTranslation();
  const [initialLoading, setInitialLoading] = useState(true);
  const [reFetchRequired, setReFetchRequired] = useState(false);
  const [story, setStory] = useState<Story | null>(null);
  const [inviteContributorWindowOpen, onInviteContributorWindowOpen, onInviteContributorWindowClose] = useOpenHandler();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const story = await storiesOperations.show(storyId);
        setStory(story);
      } catch (e) {
        Toast.error(t('notifications.story.cannotFetchStory'));
      } finally {
        setInitialLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <PageContentPaper style={{ margin: 0, padding: '1rem 2rem' }}>
      {initialLoading && <Loader />}

      {!initialLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {story && (
              <>
                <Box display="flex" justifyContent="flex-end" mb={2}>
                  <Button color="secondary" variant="contained" size="small" onClick={onInviteContributorWindowOpen}>
                    <AddIcon /> {t('pages.story.contentContributors.inviteButton')}
                  </Button>
                </Box>
                <OrganizationContributorsList
                  reFetchRequired={reFetchRequired}
                  onReFetchCompleted={() => setReFetchRequired(false)}
                  story={story}
                  display="grid"
                  title={t('pages.story.contentContributors.contributorsListHeader')}
                  subtitle={t('pages.story.contentContributors.contributorsListSubheader')}
                  clickable
                />
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            {story && (
              <StoryContributorsTable
                story={story}
                onContributorRemoved={() => setReFetchRequired(true)}
                disablePaper
              />
            )}
          </Grid>
        </Grid>
      )}
      {inviteContributorWindowOpen && story && (
        <InviteStoryContributorWindow
          open={inviteContributorWindowOpen}
          story={story}
          onCloseClick={onInviteContributorWindowClose}
          fullScreenOnMobile
        />
      )}
    </PageContentPaper>
  );
};

export default ContentContributorTab;
