import {
  AdminOrganization,
  AdminOrganizationsActionsConstants,
  AdminOrganizationsIndexActionBEGIN,
  AdminOrganizationsIndexActionFAILED,
  AdminOrganizationsIndexActionSUCCESS,
  AdminOrganizationsIndexResponse,
  AdminOrganizationsKeywordAddedAction,
  AdminOrganizationsKeywordGroupAddedAction,
  AdminOrganizationsKeywordGroupRemovedAction,
  AdminOrganizationsKeywordRemovedAction
} from './types';

export const organizationsIndexBegin = (): AdminOrganizationsIndexActionBEGIN => ({
  type: AdminOrganizationsActionsConstants.INDEX_BEGIN
});

export const organizationsIndexSuccess = (
  payload: AdminOrganizationsIndexResponse
): AdminOrganizationsIndexActionSUCCESS => ({
  type: AdminOrganizationsActionsConstants.INDEX_SUCCESS,
  payload
});

export const organizationsIndexFailed = (error: any): AdminOrganizationsIndexActionFAILED => ({
  type: AdminOrganizationsActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const keywordAdded = (organization: AdminOrganization): AdminOrganizationsKeywordAddedAction => ({
  type: AdminOrganizationsActionsConstants.KEYWORD_ADDED,
  payload: organization
});

export const keywordGroupAdded = (organization: AdminOrganization): AdminOrganizationsKeywordGroupAddedAction => ({
  type: AdminOrganizationsActionsConstants.KEYWORD_GROUP_ADDED,
  payload: organization
});

export const keywordRemoved = (organization: AdminOrganization): AdminOrganizationsKeywordRemovedAction => ({
  type: AdminOrganizationsActionsConstants.KEYWORD_REMOVED,
  payload: organization
});

export const keywordGroupRemoved = (organization: AdminOrganization): AdminOrganizationsKeywordGroupRemovedAction => ({
  type: AdminOrganizationsActionsConstants.KEYWORD_GROUP_REMOVED,
  payload: organization
});
