import React from 'react';
import { AdminRole, DefaultRoleName, defaultRoleNames } from '../store/AdminRoles/types';
import { TFunction } from 'i18next';
import { Strong } from '../components/Shared/StyledComponents';
import styled from 'styled-components';

export const getRoleNameElement = (t: TFunction, role: AdminRole, raw = false) => {
  if (defaultRoleNames.includes(role.name as DefaultRoleName)) {
    if (raw) {
      return `${t('common.default')} ${t(`common.userTypes.${role.name}`)}`;
    }

    return (
      <div>
        <p>{`${t('common.default')} ${t(`common.userTypes.${role.name}`)}`}</p>
        <RoleDescription>{role.description}</RoleDescription>
      </div>
    );
  }

  if (raw) {
    return role.name;
  }

  return (
    <div>
      <p>{role.name}</p>
      <RoleDescription>{role.description}</RoleDescription>
    </div>
  );
};

const RoleDescription = styled.p`
  font-size: 0.8rem;
  font-style: italic;
`;
