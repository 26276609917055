import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { Row } from '../../../../Shared/StyledComponents';
import { PageContentPaper, PageContentPaperHeading } from '../../../PageStyledComponents';
import SaveIcon from '@material-ui/icons/Save';
import {
  DailyOrganizationPrintActivityReportData,
  DailyOrganizationPrintActivityReportResponse
} from '../../../../../store/AdminReports/types';
import { adminReportsOperations } from '../../../../../store/AdminReports';
import { scaleBand, scaleLinear } from 'd3';
import './Chart/Chart.scss';
import { AxisBottom } from './Chart/AxisBottom';
import { AxisLeft } from './Chart/AxisLeft';
import { Legend } from './Chart/Legend';
import { LegendElement } from './Chart/types';
import { LineChart } from './Chart/LineChart';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import AdminReportsGenerateCsvWindow from '../Windows/AdminReportsGenerateCsvWindow';

type DailyOrganizationPrintActivityReportProps = {};

const DailyOrganizationPrintActivityReport: FunctionComponent<DailyOrganizationPrintActivityReportProps> = () => {
  const { t } = useTranslation();
  const [reportData, setReportData] = useState<DailyOrganizationPrintActivityReportResponse['data']>([]);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [csvDownloadWindowOpen, onCsvDownloadWindowOpen, onCsvDownloadWindowClose] = useOpenHandler();

  const width = 960;
  const height = 500;
  const margin = { top: 40, right: 30, bottom: 65, left: 50 };
  const xAxisLabelOffset = 50;

  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  const createdColor = '#e3ba22';
  const submittedColor = '#137b80';

  useEffect(() => {
    const fetchData = async () => {
      setError(false);
      try {
        const response = await adminReportsOperations.dailyOrganizationPrintActivity('json');

        if (response) {
          setReportData(response.data);
        }
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    };

    fetchData();
  }, []);

  if (loading && !reportData) {
    return <>Loading...</>;
  }

  const xValue = (d: DailyOrganizationPrintActivityReportData) => d.date;
  const xAxisLabel = 'Day';

  const yLineValue = (d: DailyOrganizationPrintActivityReportData) => d.created;
  const yLine2Value = (d: DailyOrganizationPrintActivityReportData) => d.submitted;

  let maxYValue = 0;
  for (const key in reportData) {
    maxYValue = Math.max(maxYValue, reportData[key].created, reportData[key].submitted);
  }

  const xScale = scaleBand().domain(reportData.map(xValue)).range([0, innerWidth]).padding(0.5);

  const yScale = scaleLinear().domain([0, maxYValue]).range([innerHeight, 0]).nice();

  const legendData: LegendElement[] = [
    { type: 'line', text: 'Created', color: createdColor },
    { type: 'line', text: 'Submitted', color: submittedColor }
  ];

  return (
    <PageContentPaper>
      <PageContentPaperHeading>
        <Typography variant="h6" gutterBottom>
          {t('pages.adminReports.dailyOrganizationPrintActivity.title')}
        </Typography>
        <Button color={'secondary'} variant={'contained'} size="medium" onClick={onCsvDownloadWindowOpen}>
          <SaveIcon /> {t('pages.adminReports.generateCsvReport')}
        </Button>
      </PageContentPaperHeading>
      <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
      {error && <div>Something went wrong...</div>}
      {!error && (
        <Row style={{ maxWidth: 800 }}>
          {!loading && (
            <svg viewBox={`0 0 ${width} ${height}`}>
              <g transform={`translate(${margin.left},${margin.top})`}>
                <Legend legendData={legendData} positionLeft={240} positionTop={-30} />
                <AxisBottom xScale={xScale} innerHeight={innerHeight} tickOffset={7} />
                <AxisLeft yScale={yScale} innerWidth={innerWidth} tickOffset={7} />
                <text className="axis-label" x={innerWidth / 2} y={innerHeight + xAxisLabelOffset} textAnchor="middle">
                  {xAxisLabel}
                </text>
                <g className="marks">
                  <LineChart
                    data={reportData}
                    xValue={xValue}
                    yLineValue={yLineValue}
                    xScale={xScale}
                    yScale={yScale}
                    circleRadius={3}
                    color={createdColor}
                  />
                  <LineChart
                    data={reportData}
                    xValue={xValue}
                    yLineValue={yLine2Value}
                    xScale={xScale}
                    yScale={yScale}
                    circleRadius={3}
                    color={submittedColor}
                  />
                </g>
              </g>
            </svg>
          )}
        </Row>
      )}
      <AdminReportsGenerateCsvWindow
        open={csvDownloadWindowOpen}
        onCloseClick={onCsvDownloadWindowClose}
        onSubmit={async (range) => await adminReportsOperations.dailyOrganizationPrintActivity('csv', range)}
      />
    </PageContentPaper>
  );
};

export default DailyOrganizationPrintActivityReport;
