import React from 'react';
import { Popover, Box, FormControl, Select, MenuItem } from '@material-ui/core';
import theme from '../../../../config/themes/lpi';
import {
  StoryChannel,
  StoryChannelStatus,
  StoryEmailChannelPriority,
  storyEmailChannelPriority
} from '../../../../store/Stories/types';
import ChannelStatusButton from './ChannelStatusButton';
import { useTranslation } from 'react-i18next';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';

type EmailApproveButtonProps = {
  approvalAllowed: boolean;
  channel: StoryChannel;
  setChannelStatus: (
    channel: StoryChannel,
    status: StoryChannelStatus,
    muteToast: boolean,
    prevStatus?: StoryChannelStatus,
    priority?: StoryEmailChannelPriority
  ) => void;
};

const EmailApproveButton: React.FC<EmailApproveButtonProps> = ({ approvalAllowed, channel, setChannelStatus }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleEmailApproveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const [priorityLevel, setPriorityLevel] = React.useState(StoryEmailChannelPriority.LOW);

  const handlePriorityLevelChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPriorityLevel(event.target.value as StoryEmailChannelPriority);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'priority-popover' : undefined;

  return (
    <div>
      <ChannelStatusButton
        disabled={!approvalAllowed}
        variant="outlined"
        color={theme.palette.success.main}
        startIcon={<ThumbUpAltOutlinedIcon />}
        label={t('pages.story.approve')}
        onClick={handleEmailApproveClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <Box display="flex" alignItems="center" style={{ padding: 15 }}>
          <div>{t('pages.story.choosePriorityLevel')}:</div>
          <FormControl variant="outlined" size="small" style={{ margin: '0 10px' }}>
            <Select
              id="priority-select"
              value={priorityLevel}
              onChange={handlePriorityLevelChange}
              style={{ height: '32px' }}
            >
              {storyEmailChannelPriority.map((priority) => (
                <MenuItem key={`priority-${priority}`} value={priority}>
                  {t(`pages.story.priorities.${priority}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ChannelStatusButton
            disabled={!approvalAllowed}
            variant="outlined"
            color={theme.palette.success.main}
            startIcon={<ThumbUpAltOutlinedIcon />}
            label={t('pages.story.approve')}
            onClick={() => setChannelStatus(channel, StoryChannelStatus.APPROVED, false, undefined, priorityLevel)}
          />
        </Box>
      </Popover>
    </div>
  );
};

export default EmailApproveButton;
