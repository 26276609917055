import {
  MyOrganizationActionsConstants,
  MyOrganizationUsersIndexActionBEGIN,
  MyOrganizationUsersIndexActionFAILED,
  MyOrganizationUsersIndexActionSUCCESS,
  MyOrganizationUsersIndexResponse
} from './types';

export const organizationUsersIndexBegin = (): MyOrganizationUsersIndexActionBEGIN => ({
  type: MyOrganizationActionsConstants.INDEX_BEGIN
});

export const organizationUsersIndexSuccess = (
  payload: MyOrganizationUsersIndexResponse
): MyOrganizationUsersIndexActionSUCCESS => ({
  type: MyOrganizationActionsConstants.INDEX_SUCCESS,
  payload
});

export const organizationUsersIndexFailed = (error: any): MyOrganizationUsersIndexActionFAILED => ({
  type: MyOrganizationActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});
