import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { printProjectsOperations } from '../../../../store/PrintProjects';
import ProjectPaper from '../../../Shared/Layout/ProjectPaper';
import { DesignerStory } from '../../../../store/PrintProjects/types';
import styled from 'styled-components';
import { Box, Button, Paper, Typography, withTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { GetUserPrintProjectsResponse } from '../../../../store/PrintProjects/types';
import { Pagination } from '@material-ui/lab';
import Loader from '../../../Shared/Loading/Loader';
import TemplateThumbnail from '../../../Shared/Thumbnail/TemplateThumbnail';
import { trimHtml } from '../../../../utils';

type PrintArticlesWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  onSuccessfulSubmit: (article: DesignerStory) => void;
  fullScreenOnMobile: boolean;
};

const PrintArticlesWindow: FunctionComponent<PrintArticlesWindowProps> = ({
  onCloseClick,
  open,
  onSuccessfulSubmit
}) => {
  const { t } = useTranslation();
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false);
  const [loadingArticles, setLoadingArticles] = useState<boolean>(false);
  const [projects, setProjects] = useState<GetUserPrintProjectsResponse | null>(null);
  const [articles, setArticles] = useState<DesignerStory[]>([]);
  const [activeView, setActiveView] = useState<'projects' | 'articles'>('projects');
  const [page, setPage] = useState(1);
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    fetchPrintProjects(value);
  };

  useEffect(() => {
    if (open) {
      setActiveView('projects');
      fetchPrintProjects(page);
    }
  }, [open]);

  const fetchPrintProjects = async (page: number) => {
    setLoadingProjects(true);
    try {
      const projectsResponse = await printProjectsOperations.getPrintProjects(page, 9, null, ['order=createdAt,desc']);
      setProjects(projectsResponse);
    } catch (error) {
      Toast.error(t('pages.story.windows.printArticles.errorFetchingProjects'));
    } finally {
      setLoadingProjects(false);
    }
  };

  const fetchArticles = async (projectId: string) => {
    setLoadingArticles(true);
    setActiveView('articles');
    try {
      const articlesResponse = await printProjectsOperations.getArticles(projectId);
      setArticles(articlesResponse.data);
    } catch (error) {
      Toast.error(t('pages.story.windows.printArticles.errorFetchingArticles'));
    } finally {
      setLoadingArticles(false);
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={
        activeView === 'projects' ? (
          t('pages.story.windows.printArticles.selectPrintProject')
        ) : (
          <>
            <Button
              color={'secondary'}
              variant={'outlined'}
              size="small"
              onClick={() => setActiveView('projects')}
              startIcon={<ChevronLeftIcon />}
              style={{ marginRight: 10 }}
            >
              {t('common.back')}
            </Button>
            {t('pages.story.windows.printArticles.selectArticle')}
          </>
        )
      }
      hideActionButtons
      onCloseClick={onCloseClick}
      maxWidth="md"
    >
      {loadingProjects && activeView === 'projects' && (
        <EmptyWrapper>
          <Loader />
        </EmptyWrapper>
      )}
      {!loadingProjects && projects && activeView === 'projects' && (
        <>
          {!projects.data.length && (
            <EmptyWrapper>{t('pages.story.windows.printArticles.noPrintProjects')}</EmptyWrapper>
          )}
          {projects.pagination.totalPages > 1 && (
            <PaginationWrapper>
              <Pagination count={projects.pagination.totalPages} page={page} onChange={handlePageChange} />
            </PaginationWrapper>
          )}
          {projects.data.length > 0 && (
            <ProjectsListContainer>
              {projects.data.map((project) => (
                <ProjectPaper key={project.id} project={project} onSelect={(project) => fetchArticles(project.id)} />
              ))}
            </ProjectsListContainer>
          )}
          {projects.pagination.totalPages > 1 && (
            <PaginationWrapper>
              <Pagination count={projects.pagination.totalPages} page={page} onChange={handlePageChange} />
            </PaginationWrapper>
          )}
        </>
      )}
      {loadingArticles && activeView === 'articles' && (
        <EmptyWrapper>
          <Loader />
        </EmptyWrapper>
      )}
      {!loadingArticles && activeView === 'articles' && (
        <Stories>
          {articles.length === 0 && <EmptyWrapper>{t('pages.story.windows.printArticles.noArticles')}</EmptyWrapper>}
          {articles.length > 0 && (
            <StoriesList>
              {articles.map((article, i) => (
                <LinkNoUnderline key={`${article.name}_${i}`}>
                  <Paper elevation={3} style={{ maxWidth: 400 }}>
                    <Box display="flex">
                      <TemplateThumbnail
                        thumbnailUrl={article.images.length ? article.images[0].url : null}
                        width="130px"
                        height="130px"
                      />
                      <Texts>
                        <Title>{trimHtml(article.title, { limit: 200, preserveTags: false }).html}</Title>
                        <Description>{trimHtml(article.body, { limit: 200, preserveTags: false }).html}</Description>
                      </Texts>
                    </Box>
                    <ActionButtons>
                      <Button
                        color={'secondary'}
                        variant="contained"
                        size="medium"
                        onClick={() => {
                          onSuccessfulSubmit(article);
                          onCloseClick();
                        }}
                      >
                        {t('common.select')}
                      </Button>
                    </ActionButtons>
                  </Paper>
                </LinkNoUnderline>
              ))}
            </StoriesList>
          )}
        </Stories>
      )}
    </ModalConfirmationWindow>
  );
};

export default PrintArticlesWindow;

const SectionLabel = styled(Typography)`
  padding: 0.5rem 0 0 1rem;
`;

const EmptyWrapper = styled.div`
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
`;

const Stories = styled.div`
  min-height: 300px;
`;

const StoriesList = styled.div`
  text-align: center;
`;

const ProjectsListContainer = styled.div`
  text-align: center;
`;

const PaginationWrapper = styled.div`
  margin: 20px auto;
  display: flex;
  justify-content: center;
`;

const LinkNoUnderline = styled.div`
  margin: 0 2rem 2rem 0;
  text-align: left;
  display: inline-block;
`;

const Texts = withTheme(styled.div`
  width: 270px;
  padding: 0.5rem 1rem 0 1rem;
`);

const ActionButtons = styled.div`
  padding: 1rem;
`;

const TextHolder = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-width: 240px;
  word-break: break-all;
  letter-spacing: normal;
  line-height: normal;
`;

const Title = withTheme(styled(TextHolder)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  height: 36px;
  -webkit-line-clamp: 2; /* number of lines to show */
`);

const Description = withTheme(styled(TextHolder)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 0.8rem;
  -webkit-line-clamp: 5; /* number of lines to show */
`);
