import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { campaignsOperations } from '../../../../store/Campaigns';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import { Campaign, CampaignProjectType } from '../../../../store/Campaigns/types';

type ProjectCampaignUnassignWindowProps = {
  open: boolean;
  campaign: Campaign;
  projectId: string;
  type: CampaignProjectType;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  onSuccessfulSubmit: () => void;
};

const ProjectCampaignUnassignWindow: FunctionComponent<ProjectCampaignUnassignWindowProps> = ({
  campaign,
  projectId,
  type,
  onCloseClick,
  open,
  onSuccessfulSubmit
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!campaign) return null;

  const onSubmit = async () => {
    try {
      await dispatch(campaignsOperations.unassign(campaign.id, projectId, type));
      onCloseClick();
      onSuccessfulSubmit();
      Toast.success(t('notifications.campaigns.projectUnassigned'));
    } catch (e) {
      Toast.error(t('notifications.campaigns.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.campaigns.windows.unassignProjectFromCampaign')}
      okButtonText={t('pages.campaigns.windows.unassign')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>{t('pages.campaigns.windows.unassignProjectFromCampaignContent')}</Row>
      <Row>
        <Strong>{campaign.name}</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default ProjectCampaignUnassignWindow;
