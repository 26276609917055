import {
  DoAppMerchantPromotionsActionsConstants,
  DoAppMerchantPromotionsIndexActionBEGIN,
  DoAppMerchantPromotionsIndexActionFAILED,
  DoAppMerchantPromotionsIndexActionSUCCESS,
  DoAppPromotionsMineIndexResponse
} from './types';

export const doAppPromotionsIndexBegin = (): DoAppMerchantPromotionsIndexActionBEGIN => ({
  type: DoAppMerchantPromotionsActionsConstants.INDEX_BEGIN
});

export const doAppPromotionsIndexSuccess = (
  payload: DoAppPromotionsMineIndexResponse
): DoAppMerchantPromotionsIndexActionSUCCESS => ({
  type: DoAppMerchantPromotionsActionsConstants.INDEX_SUCCESS,
  payload
});

export const doAppPromotionsIndexFailed = (error: any): DoAppMerchantPromotionsIndexActionFAILED => ({
  type: DoAppMerchantPromotionsActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});
