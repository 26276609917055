import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createNetworkErrorObject, useTypedSelector } from '../../../utils';
import { usePaginationWatch } from '../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../Shared/DataTable/useTableSearchWatch';
import { PageContainer, PageContent } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import DataTable from '../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import { TableSearchStoreModule } from '../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../store/Pagination/types';
import linksConstants from '../../../config/app/linksConstants';
import { hasPermission } from '../../../utils/permissions';
import { Campaign } from '../../../store/Campaigns/types';
import { campaignsOperations } from '../../../store/Campaigns';
import { TableLink, TableLinkDisabled } from '../Admin/Organizations/AdminOrganizationsPage';
import { Button, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RouteComponentProps } from 'react-router';
import Toast from '../../Shared/Toast/Toast';
import { emailProjectsOperations } from '../../../store/EmailProjects';
import { printProjectsOperations } from '../../../store/PrintProjects';
import { storiesOperations } from '../../../store/Stories';
import { StyledAlert } from './CampaignProjectsPage';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

type AssignCampaignPageProps = RouteComponentProps<{ projectId: string; projectType: string }>;

const AssignCampaignPage: FunctionComponent<AssignCampaignPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);
  const campaigns = useTypedSelector((state) => state.campaigns.data);
  const isFailed = useTypedSelector((state) => state.campaigns.index.isFailed);
  const isLoading = useTypedSelector((state) => state.campaigns.index.isLoading);
  const pagination = useTypedSelector((state) => state.campaigns.pagination);
  const tableSearch = useTypedSelector((state) => state.campaigns.tableSearch);
  const projectId = match.params.projectId;
  const projectType = match.params.projectType;

  const [loading, setLoading] = useState(true);
  const [campaignsData, setCampaignsData] = useState<Campaign[]>([]);

  useEffect(() => {
    if (projectId) {
      fetchCampaigns();
    } else {
      setLoading(false);
    }
  }, []);

  const fetchCampaigns = async () => {
    try {
      let campaigns: {
        data: Campaign[];
      } = { data: [] };
      if (projectType === 'email') {
        campaigns = await emailProjectsOperations.getEmailProjectCampaigns(projectId);
      } else if (projectType === 'print') {
        campaigns = await printProjectsOperations.getPrintProjectCampaigns(projectId);
      } else if (projectType === 'story') {
        campaigns = await storiesOperations.getStoryCampaigns(projectId);
      }
      setCampaignsData(campaigns.data);
    } catch (e) {
      const error = createNetworkErrorObject(e);

      switch (error.statusCode) {
        case 401:
          return Toast.info(t('notifications.common.loginFirst'));
        default:
          return Toast.error(t('notifications.campaigns.cannotFetchCampaign'));
      }
    } finally {
      setLoading(false);
    }
  };

  usePaginationWatch(pagination, [campaignsOperations.index]);
  useTableSearchWatch<Campaign>(tableSearch, [campaignsOperations.index]);

  const handleRedirection = () => {
    if (projectType === 'email') {
      dispatch(push(linksConstants.PROJECT_DETAILS(projectId)));
    } else if (projectType === 'print') {
      dispatch(push(linksConstants.PROJECT_DETAILS(projectId)));
    } else if (projectType === 'story') {
      dispatch(push(linksConstants.STORY.EDIT(projectId)));
    }
  };

  const assignToProject = async (campaignId: string) => {
    try {
      if (projectId) {
        await campaignsOperations.assign(campaignId, projectId, projectType);
        handleRedirection();
        Toast.success(t('notifications.campaigns.campaignAssigned'));
      }
    } catch (e) {
      Toast.error(t('notifications.campaigns.errorOperation'));
    }
  };

  const backButton = {
    onClick: handleRedirection,
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.campaigns.title')} leftActionButtons={[backButton]} />
      <PageContent>
        <DataTable<Campaign, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'name',
              sortable: true,
              render: (campaign) => (
                <>
                  {hasPermission(role, ['campaignsView']) ? (
                    <TableLink onClick={() => dispatch(push(linksConstants.CAMPAIGNS.EDIT(campaign.id)))}>
                      {campaign.name}
                    </TableLink>
                  ) : (
                    <TableLinkDisabled>{campaign.name}</TableLinkDisabled>
                  )}
                  <Typography variant="caption" display="block">
                    {campaign.description}
                  </Typography>
                </>
              )
            },
            {
              name: 'actions',
              render: (campaign) => (
                <TableActionButtonsContainer>
                  {!loading && (
                    <>
                      {campaignsData.find((c) => c.id === campaign.id) ? (
                        <StyledAlert severity="success" variant="outlined">
                          {t('pages.campaigns.projectList.alreadyAdded')}
                        </StyledAlert>
                      ) : (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => assignToProject(campaign.id)}
                          size="small"
                          disabled={!hasPermission(role, ['campaignsEdit'])}
                        >
                          {t('pages.campaigns.projectList.addToCampaign')}
                        </Button>
                      )}
                    </>
                  )}
                </TableActionButtonsContainer>
              )
            }
          ]}
          tPath={'pages.campaigns.table'}
          data={campaigns}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.CAMPAIGNS
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.CAMPAIGNS
          }}
          isFailed={isFailed}
          isLoading={loading && isLoading}
        />
      </PageContent>
    </PageContainer>
  );
};

export default AssignCampaignPage;
