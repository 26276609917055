import React from 'react';
import styled from 'styled-components';
import AdminKeywordChip from '../../../../Shared/Tags/AdminKeywordChip';
import { TableLinkExternal } from '../../../../Shared/DataTable/ColumnCell/UrlColumnCell';
import linksConstants from '../../../../../config/app/linksConstants';
import { useTranslation } from 'react-i18next';
import { AdminOrganization } from '../../../../../store/AdminOrganizations/types';

type UserOrganizationsWithKeywordsProps = {
  userId: string;
  organizations?: AdminOrganization[];
  showKeywords: boolean;
};

const UserOrganizationsWithKeywords: React.FC<UserOrganizationsWithKeywordsProps> = ({
  userId,
  organizations,
  showKeywords
}) => {
  const { t } = useTranslation();
  if (!organizations || !organizations.length) {
    return <NoOrganizations key={`${userId}-organization-with-keyword-wrapper`}>N/A</NoOrganizations>;
  }

  return (
    <Wrapper key={`${userId}-organization-with-keyword-wrapper`}>
      <OrganizationsList>
        {organizations.map((organization, i) => (
          <div key={`${organization.id}-${i}-keywords`}>
            <UserOrganizationItem>
              <TableLinkExternal
                to={`${linksConstants.ADMINISTRATION.ORGANIZATIONS.USERS(organization.id)}?page=1&per=10`}
              >
                {organization.name} <AccountNumber>{organization.accountNumber || 'N/A'}</AccountNumber>
              </TableLinkExternal>
              {showKeywords && (
                <KeywordsList>
                  {organization.keywords.length === 0 && <NoKeywords>{t('common.noKeywords')}</NoKeywords>}
                  {organization.keywords.map((keyword) => (
                    <AdminKeywordChip
                      key={`${organization.id}-${keyword.id}-keyword`}
                      keyword={keyword}
                      onDelete={null}
                    />
                  ))}
                </KeywordsList>
              )}
            </UserOrganizationItem>
            <Separator />
          </div>
        ))}
      </OrganizationsList>
    </Wrapper>
  );
};

const Separator = styled.hr`
  background: #e2e2e2;
  height: 1px;
  border: none;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const OrganizationsList = styled.div`
  hr:last-child {
    display: none;
  }
`;
const UserOrganizationItem = styled.div``;

const NoKeywords = styled.div`
  opacity: 0.8;
  font-size: 0.6rem;
`;

const KeywordsList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  opacity: 0.6;
`;

const NoOrganizations = styled.div`
  padding: 7px 20px;
  font-style: italic;
`;

const AccountNumber = styled.span`
  margin-left: 4px;
  font-style: italic;
  font-size: 0.7rem;
  color: #000;
  font-weight: 100;
`;

export default UserOrganizationsWithKeywords;
