import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { ItemTypes } from '../../../types';
import { useDrag } from 'react-dnd';
import { Story as StoryType, StoryChannel, StoryFieldName } from '../../../../../../../store/Stories/types';
import { stripHtml } from '../../../Utils';
import moment from 'moment';
import app from '../../../../../../../config/app/app';
import linksConstants from '../../../../../../../config/app/linksConstants';

type StoryProps = {
  story: StoryType;
  channel: StoryChannel;
};

const Story: FunctionComponent<StoryProps> = ({ channel, story }) => {
  const body = channel.fields.find((field) => field.name === StoryFieldName.BODY)?.value || '';
  const title = channel.fields.find((field) => field.name === StoryFieldName.SUBJECT)?.value || '';

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.STORY, story: channel },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0.4 : 1;

  return (
    <Wrapper ref={drag} style={{ opacity }}>
      <Actions>
        <IconButton style={{ cursor: 'move' }}>
          <OpenWithIcon />
        </IconButton>
      </Actions>
      <Content>
        {channel.attachments.length > 0 && (
          <StoryImageWrapper>
            <StoryImage src={channel.attachments[0].url} />
          </StoryImageWrapper>
        )}
        <StoryTextWrapper>
          {title && (
            <StoryHeadline title={stripHtml(title.toString())} dangerouslySetInnerHTML={{ __html: title.toString() }} />
          )}
          {body && (
            <StoryBody title={stripHtml(body.toString())} dangerouslySetInnerHTML={{ __html: body.toString() }} />
          )}

          <SourceStoryInfo>
            <span title={story.name}>
              <Link to={linksConstants.STORY.EDIT(story.id)} target="_blank">
                {story.name}
              </Link>
            </span>
            {story.createdBy && <span title={story.createdBy.email || ''}>- {story.createdBy.email}</span>}
            <span>- {moment(story.publicationDateTo).format(app.dateFormats.projectPublishDate)}</span>
          </SourceStoryInfo>
        </StoryTextWrapper>
      </Content>
    </Wrapper>
  );
};

const StoryImageWrapper = styled.div``;
const StoryTextWrapper = styled.div`
  padding: 0 10px;
`;

const StoryImage = styled.div<{ src: string }>`
  width: 50px;
  height: 50px;
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
`;
const StoryHeadline = styled.div`
  font-weight: bold;
  font-size: 0.9em;

  a {
    pointer-events: none;
  }
`;
const StoryBody = styled.div`
  padding-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 0.8em;

  a {
    pointer-events: none;
  }
`;

const Wrapper = styled.div`
  cursor: move;
  padding: 1em 0.5em 0 0.5em;
  display: flex;
  align-items: center;
  width: 100%;
  word-break: break-word;
`;

const Actions = styled.div``;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const SourceStoryInfo = styled.div`
  padding-top: 1rem;
  display: flex;
  font-size: 0.8em;
  font-style: italic;

  > span {
    white-space: nowrap;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 2px;
  }
`;

export default Story;
