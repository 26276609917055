import AdminMailingListsPage from './AdminMailingListsPage';
import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';
import MailingListEditPage from '../../MailingLists/MailingListEditPage';

const AdminMailingListsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMINISTRATION.MAILING_LISTS.INDEX,
    exact: true,
    name: 'adminMailingLists',
    public: false,
    show: true,
    component: AdminMailingListsPage,
    scope: []
  },
  {
    path: linksConstants.ADMINISTRATION.MAILING_LISTS.EDIT(':mailingListId', ':mailingListType'),
    exact: false,
    name: 'adminMailingListsEdit',
    public: false,
    show: true,
    component: MailingListEditPage,
    scope: []
  }
];

export default AdminMailingListsRoutes;
