import {
  AuthActionsConstants,
  GetOrganizationSocialAccountsDataAction,
  LoginAction,
  LoginRequestResponse,
  LogoutAction,
  SwitchOrganizationAction,
  UpdateAccountAction,
  UpdateColorsAction,
  User,
  ValidateTokenAction,
  ValidateTokenRequestResponse
} from './types';
import { AyrshareSocialAccount, MyOrganizationSwatchColorSmall } from '../MyOrganization/types';

export const logout = (): LogoutAction => ({
  type: AuthActionsConstants.LOGOUT
});

export const validateToken = (validateTokenRequestResponse: ValidateTokenRequestResponse): ValidateTokenAction => ({
  type: AuthActionsConstants.VALIDATE_TOKEN,
  payload: validateTokenRequestResponse
});

export const login = (loginRequestPayload: LoginRequestResponse): LoginAction => ({
  type: AuthActionsConstants.LOGIN,
  payload: loginRequestPayload
});

export const updateAccount = (user: User): UpdateAccountAction => ({
  type: AuthActionsConstants.UPDATE_ACCOUNT,
  payload: user
});

export const switchOrganization = (organizationId: string): SwitchOrganizationAction => ({
  type: AuthActionsConstants.SWITCH_ORGANIZATION,
  payload: {
    organizationId
  }
});

export const getOrganizationSocialAccountsData = (
  data: AyrshareSocialAccount[]
): GetOrganizationSocialAccountsDataAction => ({
  type: AuthActionsConstants.GET_ORGANIZATION_SOCIAL_ACCOUNT,
  payload: data
});

export const updateColors = (colors: MyOrganizationSwatchColorSmall[]): UpdateColorsAction => ({
  type: AuthActionsConstants.UPDATE_COLORS,
  payload: colors
});
