import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useTypedSelector } from '../../../../../../../utils';
import RegisterPlugins from './Plugins/RegisterPlugins';
import { useTranslation } from 'react-i18next';
import { appConfig } from '../../../../../../../config';
import { Color } from '../../../../../../../typings';

type WysiwygEditorProps = {
  content: string;
  onBlur?: (content: string) => void;
  onChange?: (content: string) => void;
  onFocus?: (contentColor: string) => void;
  toolbar?: boolean | string[];
  disabled?: boolean;
  height?: number;
  inline: boolean;
};

const validStyles = [
  'font-size',
  'font-style',
  'font-family',
  'color',
  'text-decoration',
  'text-align',
  'background-color',
  'line-height',
  'text-transform',
  'width',
  'min-width',
  'font-weight',
  'display',
  'vertical-align',
  'height',
  'white-space',
  'margin',
  'padding',
  'margin-top'
];

const WysiwygEditor: FunctionComponent<WysiwygEditorProps> = ({
  content,
  onBlur,
  disabled,
  onFocus,
  toolbar,
  onChange,
  height,
  inline
}) => {
  const { t } = useTranslation();
  const editorRef = useRef<any>(null);
  const [currentContent, setCurrentContent] = useState<string>(content || '');

  const organizationColors = useTypedSelector((state) => state.auth.colors);

  const colorMap: string[] = useMemo(() => {
    const colors: string[] = [];

    // Default colors
    for (const defaultColor of appConfig.colors.defaults) {
      colors.push(defaultColor.hex, defaultColor.name);
    }

    // Organization colors
    for (const organizationColor of organizationColors) {
      colors.push(organizationColor.hex, organizationColor.name);
    }

    // Fill empty spaces to make the color picker look nice
    const emptySpaces = 5 - (organizationColors.length % 5);
    if (emptySpaces !== 5) {
      for (let i = 0; i < emptySpaces; i++) {
        colors.push('', '');
      }
    }

    // Shares of grey
    for (const shadeOfGrey of appConfig.colors.shadesOfGrey) {
      colors.push(shadeOfGrey.hex, shadeOfGrey.name);
    }
    return colors;
  }, [organizationColors]);

  useEffect(() => {
    if (editorRef.current) {
      if (disabled) {
        editorRef.current.mode.set('readonly');
      } else {
        editorRef.current.mode.set('design');
      }
    }
  }, [disabled]);

  const tinyMceEditLink = (editor: any) => {
    editor.windowManager.oldOpen = editor.windowManager.open;
    editor.windowManager.open = function (t: any, r: any) {
      if (t.title === 'Insert/Edit Link') {
        t.title = 'Add Link to Text';
        try {
          t.body.items = t.body.items.reduce((all: any, formElement: any) => {
            if (formElement.name === 'url') {
              formElement.label = 'Text Link URL';
              all.push(formElement);
            }
            if (formElement.name === 'target') {
              all.push(formElement);
            }
            return all;
          }, []);
          t.buttons = t.buttons.map((button: any) => {
            if (button.name === 'save') {
              button.text = 'Submit';
            }
            return button;
          });
        } catch (e) {
          console.warn('Could not edit URL modal input.', e);
        }
      }

      return this.oldOpen.apply(this, [t, r]);
    };
  };

  return (
    <Editor
      onInit={(evt, editor) => (editorRef.current = editor)}
      onFocus={() => {
        if (!onFocus) return;

        try {
          const inlineEditorRegex = /<p\b[^>]*>(?:<em>)?(?:<strong>)?(?:<em>)?<span\b[^>]*>(.*?)<\/p>/g;
          if (inlineEditorRegex.test(content)) {
            const parser = new DOMParser();
            const document = parser.parseFromString(content, 'text/html');
            const p = document.querySelector('p');

            let colorToUse = '';

            if (p && p.style.color) {
              colorToUse = p.style.color;
            } else {
              document.querySelectorAll('span').forEach((node: HTMLElement) => {
                if (colorToUse) return;

                if (!colorToUse && node.style.color) {
                  colorToUse = node.style.color;
                }
              });
            }

            if (colorToUse) {
              onFocus(colorToUse);
            }
          }
        } catch (e) {
          console.warn('Could not read text box content color.', e);
        }
      }}
      onBlur={() => {
        if (onBlur) {
          onBlur(currentContent);
        }
      }}
      onEditorChange={(content) => {
        setCurrentContent(content);
        if (onChange) {
          onChange(content);
        }
      }}
      initialValue={content || ''}
      init={{
        setup: function (editor) {
          editor.on('init', (e) => {
            RegisterPlugins(editor, t);
            tinyMceEditLink(editor);

            if (disabled) {
              setTimeout(() => {
                editor.mode.set('readonly');
              }, 10);
            }
          });
        },
        paste_preprocess: function (pl: any, o: { content: string }) {
          o.content = o.content.replace(/\r?\n/g, '<br>');
        },
        inline_boundaries: false,
        font_size_formats:
          '6px 8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 36px 40px 44px 48px 52px 56px 60px 64px 68px 72px',
        valid_elements: 'p[style],strong,em,span[style],a[href|target|title|style],ul,ol,li,hr[style]',
        valid_styles: {
          '*': validStyles.join(',')
        },
        link_default_target: '_blank',
        link_assume_external_targets: true,
        elementpath: false,
        link_title: false,
        link_target_list: [{ title: 'Open in new tab', value: '_blank' }],
        powerpaste_word_import: 'clean',
        height: height || 500,
        menubar: false,
        inline,
        plugins: ['link', 'lists', 'autolink', 'paste', 'hrCustom', 'emoticons'],
        paste_as_text: true,
        toolbar: toolbar || [
          'undo redo | fontfamily fontsize',
          'forecolor backcolor | link | bold italic underline | alignleft aligncenter alignright alignjustify',
          'lineheight | numlist bullist | hrCustom | emoticons'
        ],
        content_style:
          '@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap"); ' +
          'body { font-family: Helvetica, Arial, sans-serif; font-size: 14px; } ' +
          '.mce-content-body a { text-decoration: underline; } ' +
          '.mce-content-body em { font-style: italic; } ' +
          '.mce-content-body p { word-break: break-word; } ' +
          '.mce-content-body ul { list-style: disc; } ' +
          '.mce-content-body ol { list-style: decimal; } ' +
          '.mce-content-body ul, .mce-content-body ol { padding-left: 40px; } ' +
          '.mce-content-body hr { border: 0; } ' +
          '.cta-button .mce-content-body a { text-decoration: initial; } ' +
          '.download-attachment .mce-content-body a { text-decoration: initial; } ',
        font_formats:
          'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; ' +
          'Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; ' +
          'Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; ' +
          'Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; ' +
          'Montserrat=montserrat,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif;' +
          'Terminal=terminal,monaco; Times New Roman=times new roman,times; ' +
          'Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; ' +
          'Wingdings=wingdings,zapf dingbats',
        color_map: colorMap.length > 0 ? colorMap : undefined
      }}
    />
  );
};

export default WysiwygEditor;
