import { useCallback, useState } from 'react';

const useOpenHandler = (initialValue = false) => {
  const [isOpen, setIsOpen] = useState(initialValue);
  const openFunction = useCallback(() => setIsOpen(true), []);
  const closeFunction = useCallback(() => setIsOpen(false), []);

  return [isOpen, openFunction, closeFunction] as const;
};

export default useOpenHandler;
