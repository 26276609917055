import React, { useEffect, useState } from 'react';
import { WordpressCategory, WordpressIntegration } from '../../../../../store/MyOrganization/types';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';
import WordpressCategoryTree from './WordpressCategoryTree';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CategoryIcon from '@material-ui/icons/Category';

type WordpressIntegrationsListProps = {
  wordpressIntegrations: WordpressIntegration[];
  checkboxes?: boolean;
  categories?: boolean;
  onUpdateIconClick?: (integration: WordpressIntegration) => void;
  onDeleteIconClick?: (integration: WordpressIntegration) => void;
  setSelectedWordpressIntegrations?: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCategories?: { [integrationId: string]: WordpressCategory[] };
  handleCategorySelect?: (integrationId: string, categories: WordpressCategory[]) => void;
};

const WordpressIntegrationsList: React.FC<WordpressIntegrationsListProps> = ({
  wordpressIntegrations,
  checkboxes = false,
  categories = false,
  onDeleteIconClick,
  onUpdateIconClick,
  setSelectedWordpressIntegrations,
  selectedCategories,
  handleCategorySelect
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<string[]>(
    wordpressIntegrations.length === 1 ? [wordpressIntegrations[0].id] : []
  );
  const [selectAll, setSelectAll] = useState(false);
  const [openCategories, setOpenCategories] = useState<string[]>([]);

  const handleCategoriesOpen = (integrationId: string) => {
    setOpenCategories((prevOpen) =>
      prevOpen.includes(integrationId)
        ? prevOpen.filter((item) => item !== integrationId)
        : [...prevOpen, integrationId]
    );
  };

  useEffect(() => {
    // Handle the special case when there's only one integration
    if (wordpressIntegrations.length === 1 && checkboxes) {
      const singleIntegrationId = wordpressIntegrations[0].id;
      setChecked([singleIntegrationId]);
      setSelectedWordpressIntegrations && setSelectedWordpressIntegrations([singleIntegrationId]);
    }
  }, [checkboxes, wordpressIntegrations, setSelectedWordpressIntegrations]);

  const handleToggle = (integration: string) => () => {
    if (wordpressIntegrations.length === 1) {
      // If there's only one integration, keep it checked
      return;
    }

    const currentIndex = checked.indexOf(integration);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(integration);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setSelectedWordpressIntegrations && setSelectedWordpressIntegrations(newChecked);

    if (newChecked.length === wordpressIntegrations.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      setChecked([]);
      setSelectedWordpressIntegrations && setSelectedWordpressIntegrations([]);
    } else {
      const allIntegrations = wordpressIntegrations.map((integration) => integration.id);
      setChecked(allIntegrations);
      setSelectedWordpressIntegrations && setSelectedWordpressIntegrations(allIntegrations);
    }
    setSelectAll(!selectAll);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-end">
      {checkboxes && wordpressIntegrations.length > 1 && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">{t('pages.myOrganization.integrations.wordpress.chooseSites')}:</Typography>
          <Button size="small" color="primary" variant="contained" onClick={toggleSelectAll}>
            {selectAll ? t('common.deselectAll') : t('common.selectAll')}
          </Button>
        </Box>
      )}
      <List dense>
        {wordpressIntegrations.map((integration) => (
          <React.Fragment key={integration.id}>
            <ListItem button onClick={handleToggle(integration.id)}>
              <ListItemAvatar>
                <Avatar>
                  <LanguageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={integration.name}
                secondary={
                  <>
                    <Link href={integration.url} target="_blank" style={{ display: 'block' }}>
                      {integration.url}
                    </Link>
                    {categories && (
                      <Box display="flex" alignItems="flex-start" maxWidth={400}>
                        <Box display="flex" alignItems="center">
                          <CategoryIcon style={{ fontSize: 12, marginRight: 6 }} />
                          <strong>{t('pages.myOrganization.integrations.wordpress.categories')}:&nbsp;</strong>
                        </Box>
                        <Box display="flex" alignItems="flex-start" flexDirection="column">
                          <div
                            style={{
                              marginBottom:
                                selectedCategories && (selectedCategories[integration.id] || []).length === 0 ? 0 : 7
                            }}
                          >
                            {selectedCategories &&
                              (selectedCategories[integration.id] || []).map((cat, index, array) => (
                                <React.Fragment key={cat.id}>
                                  <span>{cat.name}</span>
                                  {index !== array.length - 1 && ', '}
                                </React.Fragment>
                              ))}
                          </div>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCategoriesOpen(integration.id);
                            }}
                            endIcon={openCategories.includes(integration.id) ? <ExpandLess /> : <ExpandMore />}
                            style={{ marginTop: '-4px' }}
                          >
                            {t('common.select')}
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </>
                }
              />
              {onUpdateIconClick && onDeleteIconClick && (
                <ListItemSecondaryAction>
                  <IconButton aria-label="update" onClick={() => onUpdateIconClick(integration)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => onDeleteIconClick(integration)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
              {checkboxes && wordpressIntegrations.length > 1 && (
                <ListItemIcon>
                  <Checkbox edge="start" checked={checked.indexOf(integration.id) !== -1} tabIndex={-1} disableRipple />
                </ListItemIcon>
              )}
            </ListItem>
            {categories && (
              <Collapse in={openCategories.includes(integration.id)} timeout="auto" unmountOnExit>
                <WordpressCategoryTree
                  categories={integration.categories}
                  selectedCategories={selectedCategories ? selectedCategories[integration.id] || [] : []}
                  onCategorySelect={(categories) => {
                    if (handleCategorySelect) {
                      handleCategorySelect(integration.id, categories);
                    }
                  }}
                />
              </Collapse>
            )}
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default WordpressIntegrationsList;
