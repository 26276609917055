import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const PublicOpenAIPolicyPage: FunctionComponent = () => {
  return (
    <Wrapper className="main-article">
      <StyledH1>OpenAI Usage policies</StyledH1>
      <div>
        <p>
          We want everyone to use our tools safely and responsibly. That&apos;s why we&apos;ve created usage policies
          that apply to all users of OpenAI&apos;s models, tools, and services. By following them, you&apos;ll ensure
          that our technology is used for good.
        </p>
        <p>
          If we discover that your product or usage doesn&apos;t follow these policies, we may ask you to make necessary
          changes. Repeated or serious violations may result in further action, including suspending or terminating your
          account.
        </p>
        <p>Our policies may change as we learn more about use and abuse of our models.</p>
        <StyledH3>Disallowed usage of our models</StyledH3>
        <strong>We don&apos;t allow the use of our models for the following:</strong>
        <ul style={{ listStyleType: 'disc' }}>
          <li>
            Illegal activity
            <ul style={{ listStyleType: 'circle' }}>
              <li>OpenAI prohibits the use of our models, tools, and services for illegal activity.</li>
            </ul>
          </li>
          <li>
            Child Sexual Abuse Material or any content that exploits or harms children
            <ul style={{ listStyleType: 'circle' }}>
              <li>We report CSAM to the National Center for Missing and Exploited Children.</li>
            </ul>
          </li>
          <li>
            Generation of hateful, harassing, or violent content
            <ul style={{ listStyleType: 'circle' }}>
              <li>Content that expresses, incites, or promotes hate based on identity</li>
              <li>Content that intends to harass, threaten, or bully an individual</li>
              <li>Content that promotes or glorifies violence or celebrates the suffering or humiliation of others</li>
            </ul>
          </li>
          <li>
            Generation of malware
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                Content that attempts to generate code that is designed to disrupt, damage, or gain unauthorized access
                to a computer system.
              </li>
            </ul>
          </li>
          <li>
            Activity that has high risk of physical harm, including:
            <ul style={{ listStyleType: 'circle' }}>
              <li>Weapons development</li>
              <li>Military and warfare</li>
              <li>Management or operation of critical infrastructure in energy, transportation, and water</li>
              <li>
                Content that promotes, encourages, or depicts acts of self-harm, such as suicide, cutting, and eating
                disorders
              </li>
            </ul>
          </li>
          <li>
            Activity that has high risk of economic harm, including:
            <ul style={{ listStyleType: 'circle' }}>
              <li>Multi-level marketing</li>
              <li>Gambling</li>
              <li>Payday lending</li>
              <li>
                Automated determinations of eligibility for credit, employment, educational institutions, or public
                assistance services
              </li>
            </ul>
          </li>
          <li>
            Fraudulent or deceptive activity, including:
            <ul style={{ listStyleType: 'circle' }}>
              <li>Scams</li>
              <li>Coordinated inauthentic behavior</li>
              <li>Plagiarism</li>
              <li>Academic dishonesty</li>
              <li>Astroturfing, such as fake grassroots support or fake review generation</li>
              <li>Disinformation</li>
              <li>Spam</li>
              <li>Pseudo-pharmaceuticals</li>
            </ul>
          </li>
          <li>
            Adult content, adult industries, and dating apps, including:
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                Content meant to arouse sexual excitement, such as the description of sexual activity, or that promotes
                sexual services (excluding sex education and wellness)
              </li>
              <li>Erotic chat</li>
              <li>Pornography</li>
            </ul>
          </li>
          <li>
            Political campaigning or lobbying, by:
            <ul style={{ listStyleType: 'circle' }}>
              <li>Generating high volumes of campaign materials</li>
              <li>Generating campaign materials personalized to or targeted at specific demographics</li>
              <li>
                Building conversational or interactive systems such as chatbots that provide information about campaigns
                or engage in political advocacy or lobbying
              </li>
              <li>Building products for political campaigning or lobbying purposes</li>
            </ul>
          </li>
          <li>
            Activity that violates people&apos;s privacy, including:
            <ul style={{ listStyleType: 'circle' }}>
              <li>Tracking or monitoring an individual without their consent</li>
              <li>Facial recognition of private individuals</li>
              <li>Classifying individuals based on protected characteristics</li>
              <li>Using biometrics for identification or assessment</li>
              <li>
                Unlawful collection or disclosure of personal identifiable information or educational, financial, or
                other protected records
              </li>
            </ul>
          </li>
          <li>
            Engaging in the unauthorized practice of law, or offering tailored legal advice without a qualified person
            reviewing the information
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                OpenAI&apos;s models are not fine-tuned to provide legal advice. You should not rely on our models as a
                sole source of legal advice.
              </li>
            </ul>
          </li>
          <li>
            Offering tailored financial advice without a qualified person reviewing the information
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                OpenAI&apos;s models are not fine-tuned to provide financial advice. You should not rely on our models
                as a sole source of financial advice.
              </li>
            </ul>
          </li>
          <li>
            Telling someone that they have or do not have a certain health condition, or providing instructions on how
            to cure or treat a health condition
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                OpenAI&apos;s models are not fine-tuned to provide medical information. You should never use our models
                to provide diagnostic or treatment services for serious medical conditions.
              </li>
              <li>
                OpenAI&apos;s platforms should not be used to triage or manage life-threatening issues that need
                immediate attention.
              </li>
            </ul>
          </li>
          <li>
            High risk government decision-making, including:
            <ul style={{ listStyleType: 'circle' }}>
              <li>Law enforcement and criminal justice</li>
              <li>Migration and asylum</li>
            </ul>
          </li>
        </ul>
      </div>
      <p></p>
      <div>
        <strong>We have further requirements for certain uses of our models:</strong>
        <ol style={{ listStyleType: 'decimal' }}>
          <li>
            Consumer-facing uses of our models in medical, financial, and legal industries; in news generation or news
            summarization; and where else warranted, must provide a disclaimer to users informing them that AI is being
            used and of its potential limitations.
          </li>
          <li>
            Automated systems (including conversational AI and chatbots) must disclose to users that they are
            interacting with an AI system. With the exception of chatbots that depict historical public figures,
            products that simulate another person must either have that person&apos;s explicit consent or be clearly
            labeled as &quot;simulated&quot; or &quot;parody.&quot;
          </li>
          <li>
            Use of model outputs in livestreams, demonstrations, and research are subject to our&nbsp;
            <a href="https://openai.com/api/policies/sharing-publication/" rel="noopener noreferrer" target="_blank">
              Sharing &amp; Publication Policy
            </a>
            .
          </li>
        </ol>
        <p></p>
        <p>
          You can use our&nbsp;
          <a href="https://platform.openai.com/docs/guides/moderation" rel="noopener noreferrer" target="_blank">
            free moderation endpoint
          </a>
          &nbsp;and&nbsp;
          <a
            href="https://platform.openai.com/docs/guides/safety-best-practices"
            rel="noopener noreferrer"
            target="_blank"
          >
            safety best practices
          </a>
          &nbsp;to help you keep your app safe.
        </p>
      </div>
      <StyledH3>Platform policy</StyledH3>
      <p>
        Our API is being used to power businesses across many sectors and technology platforms. From iOS Apps to
        websites to Slack, the simplicity of our API makes it possible to integrate into a wide array of use cases.
        Subject to the use case restrictions mentioned above, we allow the integration of our API into products on all
        major technology platforms, app stores, and beyond.
      </p>
      <StyledH3>Plugin policies</StyledH3>
      <div>
        <p>
          In addition to the disallowed usages of our models detailed above, we have additional requirements for
          developers building&nbsp;
          <a href="https://platform.openai.com/docs/plugins/" rel="noopener noreferrer" target="_blank">
            plugins
          </a>
          :
        </p>
        <ul style={{ listStyleType: 'disc' }}>
          <li>
            The plugin manifest must have a clearly stated description that matches the functionality of the API exposed
            to the model.
          </li>
          <li>
            Don&apos;t include irrelevant, unnecessary, or deceptive terms or instructions in the plugin manifest,
            OpenAPI endpoint descriptions, or plugin response messages. This includes instructions to avoid using other
            plugins, or instructions that attempt to steer or set model behavior.
          </li>
          <li>Don&apos;t use plugins to circumvent or interfere with OpenAI&apos;s safety systems.</li>
          <li>
            Don&apos;t use plugins to automate conversations with real people, whether by simulating a human-like
            response or by replying with pre-programmed messages.
          </li>
          <li>
            Plugins that distribute personal communications or content generated by ChatGPT (such as emails, messages,
            or other content) must indicate that the content was AI-generated.
          </li>
        </ul>
        <p></p>
        <p>
          Like our other usage policies, we expect our plugin policies to change as we learn more about use and abuse of
          plugins.
        </p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1440px;
  padding: 50px;
  margin: 0 auto;

  ul,
  ol {
    margin-left: 20px;
  }

  p {
    margin-bottom: 20px;
  }
`;

const StyledH1 = styled.h1`
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 15px;
`;

const StyledH3 = styled.h3`
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 15px;
`;

export default PublicOpenAIPolicyPage;
