import React from 'react';
import { formatTableTimestampWithHours, useTypedSelector } from '../../../../../utils';
import DataTable from '../../../../Shared/DataTable/DataTable';
import { DoAppPromotion } from '../../../../../store/DoApp/Promotions/types';
import { TableSearchStoreModule } from '../../../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../../../store/Pagination/types';
import { usePaginationWatch } from '../../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../../Shared/DataTable/useTableSearchWatch';
import { DoAppSubscriber } from '../../../../../store/DoApp/Subscribers/types';
import { doAppSubscribersOperations } from '../../../../../store/DoApp/Subscribers';

type SubscribersTabProps = {};

const SubscribersTab: React.FC<SubscribersTabProps> = () => {
  const doAppSubscribers = useTypedSelector((state) => state.doAppMerchantSubscribers.data);
  const isFailed = useTypedSelector((state) => state.doAppMerchantSubscribers.index.isFailed);
  const isLoading = useTypedSelector((state) => state.doAppMerchantSubscribers.index.isLoading);
  const pagination = useTypedSelector((state) => state.doAppMerchantSubscribers.pagination);
  const tableSearch = useTypedSelector((state) => state.doAppMerchantSubscribers.tableSearch);

  usePaginationWatch(pagination, [doAppSubscribersOperations.indexMerchantSubscribers]);
  useTableSearchWatch<DoAppPromotion>(tableSearch, [doAppSubscribersOperations.indexMerchantSubscribers]);

  return (
    <DataTable<DoAppSubscriber>
      enableSearch={false}
      columnDefinitions={[
        {
          name: 'email',
          sortable: false,
          render: (row) => <>{row.email}</>
        },
        {
          name: 'firstName',
          sortable: false,
          render: (row) => <>{row.firstName}</>
        },
        {
          name: 'lastName',
          sortable: false,
          render: (row) => <>{row.lastName}</>
        },
        {
          name: 'createdAt',
          sortable: false,
          render: (row) => <>{formatTableTimestampWithHours(row.createdAt)}</>
        }
      ]}
      tPath={'pages.doApp.merchants.table'}
      data={doAppSubscribers}
      tableSearchProps={{
        tableSearch,
        module: TableSearchStoreModule.DO_APP_MERCHANT_SUBSCRIBERS
      }}
      paginationProps={{
        pagination,
        module: PaginationStoreModule.DO_APP_MERCHANT_SUBSCRIBERS,
        noUrlUpdate: true
      }}
      isFailed={isFailed}
      isLoading={isLoading}
      disablePaper
    />
  );
};

export default SubscribersTab;
