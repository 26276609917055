import { createOrderArray, httpRequest } from '../../utils';
import { CreateSubscriberValues, SubscribersIndexResponse, SubscriberStatus, UpdateSubscriberValues } from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { subscribersActions, subscribersRequests } from './index';

export const index = (mailingListId: string, status = SubscriberStatus.SUBSCRIBED) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  const { subscribers } = getState();

  try {
    dispatch(subscribersActions.subscribersIndexBegin());

    const response = await httpRequest.runRequest<SubscribersIndexResponse>(
      subscribersRequests.index({
        requestParams: {
          mailingListId,
          status,
          page: subscribers.pagination.page,
          per: subscribers.pagination.per,
          q: subscribers.pagination.q,
          order: createOrderArray(subscribers.tableSearch.order)
        }
      })
    );

    dispatch(subscribersActions.subscribersIndexSuccess(response));
  } catch (e) {
    dispatch(subscribersActions.subscribersIndexFailed(e));
  }
};

export const create = (mailingListId: string, values: CreateSubscriberValues) => async () => {
  await httpRequest.runRequest<void>(
    subscribersRequests.create({
      requestPayload: values,
      requestParams: { mailingListId }
    })
  );
};

export const update = (mailingListId: string, id: string, values: UpdateSubscriberValues) => async () => {
  await httpRequest.runRequest<void>(
    subscribersRequests.update({
      requestPayload: { ...values },
      requestParams: { id, mailingListId }
    })
  );
};

export const deleteSubscriber = (mailingListId: string, id: string) => async () => {
  await httpRequest.runRequest<void>(
    subscribersRequests.deleteSubscriber({
      requestPayload: { id },
      requestParams: { mailingListId }
    })
  );
};

export const importCsv = (mailingListId: string, file: File) => async () => {
  const formData = new FormData();

  formData.append('file', file);

  return await httpRequest.runRequest<void>(
    subscribersRequests.importCsv({
      requestPayload: formData,
      requestParams: { mailingListId }
    })
  );
};
