import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { AnyProject } from '../../../../store/Dashboard/types';
import ProjectClones from '../../Dashboard/WorkInProgressTable/ProjectClones';

type ProjectClonesAccordionProps = {
  project: AnyProject;
};

const ProjectClonesAccordion: FunctionComponent<ProjectClonesAccordionProps> = ({ project }) => {
  const { t } = useTranslation();

  return (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: 0 }}>
        <Typography>{t('pages.projectDetails.clones')}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0, display: 'block' }}>
        <ProjectClones project={project} open />
      </AccordionDetails>
    </Accordion>
  );
};

export default ProjectClonesAccordion;
