import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import {
  AdminCreatePrintTemplateValues,
  AdminUpdatePrintTemplateValues,
  IndexAdminPrintTemplatesRequestParams
} from './types';

export const index = (settings: RequestSettings<IndexAdminPrintTemplatesRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/templates/print?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{}, AdminCreatePrintTemplateValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/templates/print'
  },
  payload: settings.requestPayload
});

export const update = (settings: RequestSettings<{}, AdminUpdatePrintTemplateValues>): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/templates/print/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deletePrintTemplate = (settings: RequestSettings<{ id: string }>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/templates/print/:id'
  },
  params: settings.requestParams
});

export const downloadPrintTemplate = (settings: RequestSettings<{ id: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/templates/print/:id/download'
  },
  params: settings.requestParams
});

export const uploadPrintTemplateZip = (settings: {
  requestPayload: FormData;
  requestParams: { id: string };
}): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/templates/print/:id/upload'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const addKeywordToPrintTemplate = (
  settings: RequestSettings<{ templateId: string }, { keywordId: string }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/templates/print/:templateId/keywords'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const addKeywordGroupToPrintTemplate = (
  settings: RequestSettings<{ templateId: string }, { keywordGroupId: string }>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/templates/print/:templateId/keyword_groups'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeKeywordFromPrintTemplate = (
  settings: RequestSettings<{ templateId: string }, { keywordId: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/templates/print/:templateId/keywords'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeKeywordGroupFromPrintTemplate = (
  settings: RequestSettings<{ templateId: string }, { keywordGroupId: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/templates/print/:templateId/keyword_groups'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const getPrintTemplateThumbnail = (settings: RequestSettings<{ id: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/templates/print/:id/thumbnail'
  },
  params: settings.requestParams
});

export const uploadPrintTemplateThumbnail = (settings: {
  requestPayload: FormData;
  requestParams: { id: string };
}): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/templates/print/:id/upload_thumbnail'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});
