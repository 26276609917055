import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Strong } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { useDispatch } from 'react-redux';
import { AnyProject } from '../../../../store/Dashboard/types';
import { isEmailProject, isPrintProject } from '../../../../utils';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import { printProjectsOperations } from '../../../../store/PrintProjects';
import { dashboardOperations } from '../../../../store/Dashboard';
import { adminProjectsOperations } from '../../../../store/AdminProjects';
import { EmailProject } from '../../../../store/EmailProjects/types';
import { PrintProject } from '../../../../store/PrintProjects/types';

type ProjectRemoveWindowProps = {
  open: boolean;
  context: 'email' | 'print' | 'dashboard' | 'backoffice';
  project: AnyProject | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  onSuccessfulSubmit?: () => void;
};

const ProjectRemoveWindow: FunctionComponent<ProjectRemoveWindowProps> = ({
  project,
  context,
  onCloseClick,
  open,
  onSuccessfulSubmit
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!project) return null;

  const onSubmit = async () => {
    try {
      if (isEmailProject(project)) {
        await dispatch(emailProjectsOperations.removeProject(project.id));
      }
      if (isPrintProject(project)) {
        await dispatch(printProjectsOperations.removePrintProject(project.id));
      }
      if (project && !isEmailProject(project) && !isPrintProject(project)) {
        try {
          await dispatch(emailProjectsOperations.removeProject((project as EmailProject).id));
        } catch (e) {
          // removing unknown project fallback
        }
        try {
          await dispatch(printProjectsOperations.removePrintProject((project as PrintProject).id));
        } catch (e) {
          // removing unknown project fallback
        }
      }

      switch (context) {
        case 'dashboard':
          await dispatch(dashboardOperations.getUserProjectsInProgress());
          break;
        case 'email':
          await dispatch(emailProjectsOperations.getUserEmailProjects());
          break;
        case 'print':
          await dispatch(printProjectsOperations.getUserPrintProjects());
          break;
        case 'backoffice':
          await dispatch(adminProjectsOperations.index());
          break;
      }
      Toast.success(t('notifications.projectRemoved.success'));

      if (onSuccessfulSubmit) {
        onSuccessfulSubmit();
      }

      onCloseClick();
    } catch (e) {
      Toast.error(t('notifications.projectRemoved.error'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('windows.projectRemove.header')}
      okButtonText={t('common.remove')}
      onCloseClick={onCloseClick}
      onSubmit={onSubmit}
    >
      <Row>
        {t('windows.projectRemove.areYouSure')} <Strong>({project.name})</Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default ProjectRemoveWindow;
