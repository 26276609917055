import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { AdPlacementReportParameters, GetReportFormatParameter } from './types';

export const emailCampaignsReport = (settings: RequestSettings<GetReportFormatParameter, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/reports/email_campaigns_daily?format=:format&items=:items&range=:range&from=:from&to=:to`
  },
  params: settings.requestParams
});

export const organizationMailingListSize = (settings: RequestSettings<GetReportFormatParameter, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/reports/organization_mailing_list_size?format=:format&items=:items&range=:range&from=:from&to=:to`
  },
  params: settings.requestParams
});

export const dailyOrganizationEmailActivity = (
  settings: RequestSettings<GetReportFormatParameter, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/reports/daily_organizations_email_activity?format=:format&items=:items&range=:range&from=:from&to=:to`
  },
  params: settings.requestParams
});

export const dailyOrganizationPrintActivity = (
  settings: RequestSettings<GetReportFormatParameter, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/reports/daily_organizations_print_activity?format=:format&items=:items&range=:range&from=:from&to=:to`
  },
  params: settings.requestParams
});

export const dailyAds = (settings: RequestSettings<GetReportFormatParameter, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/reports/daily_ads_activity?format=:format&items=:items&range=:range&from=:from&to=:to`
  },
  params: settings.requestParams
});

export const detailedEmailCampaignsReport = (
  settings: RequestSettings<GetReportFormatParameter, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/reports/detailed_email_campaigns?format=:format&items=:items&range=:range&from=:from&to=:to`
  },
  params: settings.requestParams
});

export const submittedPrintProjectsReport = (
  settings: RequestSettings<GetReportFormatParameter, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/admin/reports/submitted_print_projects?format=:format&items=:items&range=:range&from=:from&to=:to`
  },
  params: settings.requestParams
});

export const adPlacementReport = (settings: RequestSettings<AdPlacementReportParameters, {}>): BaseSettings => ({
  query: {
    method: 'get',
    // eslint-disable-next-line max-len
    url: `/api/v1/admin/reports/revenue_placement_simpler?organizationNumber=:organizationNumber&printCenter=:printCenter&size=:size&statuses=:statuses&range=:range&from=:from&to=:to`
  },
  params: settings.requestParams
});
