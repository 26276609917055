import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Paper, TextField } from '@material-ui/core';
import ColorPicker from '../../../../../../Shared/ColorPicker/ColorPicker';
import { useTranslation } from 'react-i18next';
import { BorderSettings } from '../../../types';

type SettingsProps = {
  border?: BorderSettings;
  backgroundColor?: string;
  setGeneralSettings: (border: BorderSettings, backgroundColor: string) => void;
};

export const Settings: React.FC<SettingsProps> = ({ border, backgroundColor, setGeneralSettings }) => {
  const { t } = useTranslation();

  const [generalBackgroundColor, setGeneralBackgroundColor] = useState<string>(backgroundColor || 'transparent');
  const [buttonBorderColor, setButtonBorderColor] = useState<string>((border && border.borderColor) || '#ffffff');
  const [buttonBorderRadius, setButtonBorderRadius] = useState<string>((border && border.borderRadius) || '0');
  const [buttonBorderWidth, setButtonBorderWidth] = useState<string>((border && border.borderWidth) || '0');

  return (
    <StyledPaper>
      <Heading>{t('pages.emailEditor.settings.header')}</Heading>
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.settings.background')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ColorPicker
              color={generalBackgroundColor}
              onChange={(color) => {
                setGeneralBackgroundColor(color);
                setGeneralSettings(
                  {
                    borderRadius: buttonBorderRadius,
                    borderStyle: 'solid',
                    borderWidth: buttonBorderWidth,
                    borderColor: buttonBorderColor
                  },
                  color
                );
              }}
            />
          </Grid>
        </Grid>
      </fieldset>
      <fieldset
        style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0', borderRadius: '4px', width: '100%' }}
      >
        <legend style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '11px', padding: '0 4px' }}>
          {t('pages.emailEditor.settings.border')}
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div>{t('pages.emailEditor.settings.borderWidth')}:</div>
            <TextField
              id="button-border-width"
              type="number"
              value={buttonBorderWidth}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const width = (Number(value) < 0 ? Math.abs(Number(value)) : value).toString();
                setButtonBorderWidth(width);
                setGeneralSettings(
                  {
                    borderRadius: buttonBorderRadius,
                    borderStyle: 'solid',
                    borderWidth: width,
                    borderColor: buttonBorderColor
                  },
                  generalBackgroundColor
                );
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setButtonBorderWidth('0');
                  setGeneralSettings(
                    {
                      borderRadius: buttonBorderRadius,
                      borderStyle: 'solid',
                      borderWidth: '0',
                      borderColor: buttonBorderColor
                    },
                    generalBackgroundColor
                  );
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <div>{t('pages.emailEditor.settings.borderRadius')}:</div>
            <TextField
              id="button-border-radius"
              type="number"
              value={buttonBorderRadius}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const radius = (Number(value) < 0 ? Math.abs(Number(value)) : value).toString();
                setButtonBorderRadius(radius);
                setGeneralSettings(
                  {
                    borderRadius: radius,
                    borderStyle: 'solid',
                    borderWidth: buttonBorderWidth,
                    borderColor: buttonBorderColor
                  },
                  generalBackgroundColor
                );
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (event.target.value === '') {
                  setButtonBorderRadius('0');
                  setGeneralSettings(
                    {
                      borderRadius: '0',
                      borderStyle: 'solid',
                      borderWidth: buttonBorderWidth,
                      borderColor: buttonBorderColor
                    },
                    generalBackgroundColor
                  );
                }
              }}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <div>{t('pages.emailEditor.settings.borderColor')}:</div>
            <ColorPicker
              color={buttonBorderColor}
              onChange={(color) => {
                setButtonBorderColor(color);
                setGeneralSettings(
                  {
                    borderRadius: buttonBorderRadius,
                    borderStyle: 'solid',
                    borderWidth: buttonBorderWidth,
                    borderColor: color
                  },
                  generalBackgroundColor
                );
              }}
            />
          </Grid>
        </Grid>
      </fieldset>
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  padding: 30px;
  min-height: 580px;
`;

const Heading = styled.h2`
  font-weight: bold;
`;
