import React from 'react';
import { line } from 'd3';

export const LineChart = ({ data, xValue, yLineValue, xScale, yScale, circleRadius, color }) => {
  return (
    <>
      {yLineValue && xValue && (
        <path
          fill="none"
          stroke={color}
          d={line()
            .x((d) => xScale(xValue(d)))
            .y((d) => yScale(yLineValue(d)))(data)}
        />
      )}
      {yLineValue &&
        data.map((d, i) => (
          <circle cx={xScale(xValue(d))} cy={yScale(yLineValue(d))} r={circleRadius} key={i} fill={color}>
            <title>{yLineValue(d)}</title>
          </circle>
        ))}
    </>
  );
};
