import React from 'react';
import { useTranslation } from 'react-i18next';
import app from '../../../../config/app/app';
import { StyledTableCell } from '../../StyledComponents';

export type TranslateColumnCellProps<E = never> = {
  value: ValueOf<E>;
  tPath: string;
};

export function TranslateColumnCell<E = never>({ value, tPath }: TranslateColumnCellProps<E>) {
  const { t } = useTranslation();

  return <StyledTableCell style={{ maxWidth: app.maxTableColumnWidth }}>{t(`${tPath}.${value}`)}</StyledTableCell>;
}
