import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoResultsFound, PageContainer, PageContent } from '../../PageStyledComponents';
import PageHeader, { HeaderActionButton } from '../../../Shared/Layout/PageHeader';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { AdminOrganization } from '../../../../store/AdminOrganizations/types';
import { RouteComponentProps } from 'react-router';
import Loader from '../../../Shared/Loading/Loader';
import { AdBanner } from '../../../../store/AdminAds/types';
import { adminAdsOperations } from '../../../../store/AdminAds';
import AdminAdsTable from '../Ads/AdminAdsTable';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import AdminCreateAdBannerWindow from '../Ads/Windows/AdBanner/AdminCreateAdBannerWindow';
import AdminUpdateAdBannerWindow from '../Ads/Windows/AdBanner/AdminUpdateAdBannerWindow';
import AdminDeleteAdBannerWindow from '../Ads/Windows/AdBanner/AdminDeleteAdBannerWindow';
import { hasPermission } from '../../../../utils/permissions';
import { useTypedSelector } from '../../../../utils';

type AdminOrganizationsDigitalAdsPageProps = RouteComponentProps<{ organizationId: string }> & {};

const AdminOrganizationsDigitalAdsPage: FunctionComponent<AdminOrganizationsDigitalAdsPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);

  const [loading, setLoading] = useState<boolean>(true);
  const [organization, setOrganization] = useState<AdminOrganization | null>(null);
  const [banners, setBanners] = useState<AdBanner[]>([]);
  const [error, setError] = useState<boolean>(false);

  const [activeBanner, setActiveBanner] = useState<AdBanner | null>(null);

  const [updateAdBannerWindowOpen, onUpdateAdBannerWindowOpen, onUpdateAdBannerWindowClose] = useOpenHandler();
  const [deleteAdBannerWindowOpen, onDeleteAdBannerWindowOpen, onDeleteAdBannerWindowClose] = useOpenHandler();
  const [createAdBannerWindowOpen, onCreateAdBannerWindowOpen, onCreateAdBannerWindowClose] = useOpenHandler();

  const fetchData = async (initialLoad?: boolean) => {
    if (initialLoad) {
      setLoading(true);
    }
    try {
      setError(false);
      const bannersResponse = await adminAdsOperations.indexOrganizationBanners(match.params.organizationId);

      setOrganization(bannersResponse.organization);
      setBanners(bannersResponse.data);

      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(true);
  }, [match.params.organizationId]);

  const getActionButtons = () => {
    const buttons: HeaderActionButton[] = [];

    buttons.push({
      label: t('pages.adminAds.openAssignments'),
      variant: 'outlined' as const,
      icon: <VisibilityIcon />,
      onClick: () =>
        dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.ADS_ASSIGNMENT(match.params.organizationId)))
    });

    if (hasPermission(role, ['adsAdd'])) {
      buttons.push({
        label: t('pages.adminAds.addNewBanner'),
        icon: <AddIcon />,
        onClick: () => onCreateAdBannerWindowOpen()
      });
    }

    return buttons;
  };

  const backButton = {
    onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.INDEX)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  return (
    <PageContainer>
      {error && <NoResultsFound>{t('pages.adminOrganizations.errorOnAdsFetch')}</NoResultsFound>}
      {loading && <Loader />}
      {!loading && organization && (
        <>
          <PageHeader
            title={`${organization.name} - ${t('pages.adminOrganizations.titleAds')}`}
            leftActionButtons={[backButton]}
            rightActionButtons={getActionButtons()}
          />
          <PageContent>
            {organization.advertiserId && (
              <AdminAdsTable
                banners={banners}
                isLoading={loading}
                onUpdateAdBanner={(banner) => {
                  setActiveBanner(banner);
                  onUpdateAdBannerWindowOpen();
                }}
                onRemoveAdBanner={(banner) => {
                  setActiveBanner(banner);
                  onDeleteAdBannerWindowOpen();
                }}
              />
            )}
          </PageContent>

          {organization.advertiserId && (
            <AdminCreateAdBannerWindow
              advertiserId={organization.advertiserId}
              organization={organization}
              open={createAdBannerWindowOpen}
              categories={[]}
              onCloseClick={onCreateAdBannerWindowClose}
              fullScreenOnMobile={true}
              showCategories={false}
              onFormSuSubmit={fetchData}
            />
          )}
          {organization.advertiserId && (
            <AdminUpdateAdBannerWindow
              advertiserId={organization.advertiserId}
              organization={organization}
              banner={activeBanner}
              categories={[]}
              open={updateAdBannerWindowOpen}
              onCloseClick={onUpdateAdBannerWindowClose}
              fullScreenOnMobile={true}
              showCategories={false}
              onFormSuSubmit={fetchData}
            />
          )}
          <AdminDeleteAdBannerWindow
            banner={activeBanner}
            open={deleteAdBannerWindowOpen}
            onCloseClick={onDeleteAdBannerWindowClose}
            fullScreenOnMobile={true}
            onFormSuSubmit={fetchData}
          />
        </>
      )}
    </PageContainer>
  );
};

export default AdminOrganizationsDigitalAdsPage;
