import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  MonthCalendarEvent,
  MonthCalendarEventPointer,
  MonthCalendarStyle,
  MonthCalendarStyles,
  OrganizationMonthCalendarDay
} from '../../../../../../../store/MyOrganization/types';
import { withStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';
import CalendarEvent from './CalendarEvent';
import { useWindowSize } from '../../../../../../../hooks/useWindowSize';

type MonthCalendarElementProps = {
  calendarRows: OrganizationMonthCalendarDay[][];
  calendarEvents: { [key: string]: MonthCalendarEvent };
  disableHoverListener?: boolean;
  styles?: MonthCalendarStyle;
  staticEventHeight?: number;
};

const MonthCalendarElement: FunctionComponent<MonthCalendarElementProps> = ({
  calendarRows,
  calendarEvents,
  disableHoverListener = false,
  styles = useTheme().palette.custom.calendar[MonthCalendarStyles.MAIN],
  staticEventHeight
}) => {
  const [windowWidth, windowHeight] = useWindowSize();

  const isWeekView = calendarRows.length === 1;
  const eventStyles = () => {
    if (isWeekView && staticEventHeight) {
      return {
        height: staticEventHeight + 'px'
      };
    }
    return {
      height: isWeekView ? 'auto' : 22
    };
  };
  const calculateEventHeight = (rowNo: number) => {
    if (isWeekView && staticEventHeight) {
      return staticEventHeight + 'px';
    }
    const foundRowHeight = rowHeights.find((rh) => rh.row === rowNo);
    return isWeekView && foundRowHeight ? foundRowHeight.height : isWeekView ? 'auto' : 22;
  };
  const calculateMarginBottom = (rowNo: number) => {
    if (isWeekView && rowHeights.length > 0) {
      const highestEventInRow = Math.max(...rowHeights.filter((rh) => rh.row === rowNo).map((rh) => rh.height));
      const foundRowHeight = rowHeights.find((rh) => rh.row === rowNo);
      return highestEventInRow - (foundRowHeight ? foundRowHeight.height : 0) + 3;
    }
    return 3;
  };
  const [rowHeights, setRowHeights] = useState<{ eventId: string; row: number; height: number }[]>([]);

  useEffect(() => {
    setRowHeights([]);
  }, [calendarRows, windowHeight, windowWidth]);

  const getEvents = (day: OrganizationMonthCalendarDay) => {
    if (!day.events || !day.events.length) {
      return null;
    }

    const maxRowNo = Math.max(...day.events.map((e) => e.row));

    return [...Array(maxRowNo + 1).keys()].map((rowNo, i) => {
      const eventRef = day.events && day.events.find((e) => e.row === rowNo);

      if (!eventRef) {
        return (
          <div
            key={`event-${day.dayNumber}-${i}`}
            className="no-event"
            style={{
              ...eventStyles(),
              height: calculateEventHeight(rowNo),
              marginBottom: calculateMarginBottom(rowNo)
            }}
          />
        );
      }

      return getEvent(eventRef, day, i);
    });
  };

  const eventsWithNamesAlreadyListed: { [key: number]: boolean } = {};

  const getEventName = (eventRef: MonthCalendarEventPointer, event: MonthCalendarEvent) => {
    if (eventRef.s) {
      eventsWithNamesAlreadyListed[eventRef.id] = true;
      return event && event.name;
    }

    if (!eventRef.s && !eventRef.e && !eventsWithNamesAlreadyListed[eventRef.id]) {
      eventsWithNamesAlreadyListed[eventRef.id] = true;
      return event && event.name;
    }

    return null;
  };

  const getEvent = (eventRef: MonthCalendarEventPointer, day: OrganizationMonthCalendarDay, i: number) => {
    const event = calendarEvents[eventRef.id];

    if (!event) {
      return <div key={`${eventRef.id}-event-${day.dayNumber}-${i}`} className="no-event" style={eventStyles()} />;
    }

    const detailedView = (
      <React.Fragment>
        <Typography variant="caption" display="block" style={{ marginBottom: '8px' }}>
          {event.startMonth} {event.startDay}
          {(event.startMonth !== event.endMonth || event.startDay !== event.endDay) &&
            ` - ${event.endMonth} ${event.endDay}`}
        </Typography>
        <Typography variant="subtitle2" style={{ fontWeight: 'bold', lineHeight: '18px', marginBottom: '8px' }}>
          {event.name}
        </Typography>
        {event.thumbnailUrl && (
          <img
            src={event.thumbnailUrl}
            alt=""
            style={{
              display: 'block',
              width: staticEventHeight ? 70 : 100,
              height: staticEventHeight ? 70 : 100,
              objectFit: 'cover',
              marginBottom: staticEventHeight ? 4 : 10
            }}
          />
        )}
        {event.description}
      </React.Fragment>
    );

    const eventName = getEventName(eventRef, event);

    return (
      <HtmlTooltip
        key={`${eventRef.id}-event-${day.dayNumber}-${i}`}
        disableHoverListener={disableHoverListener || isWeekView}
        title={detailedView}
        placement="bottom-start"
      >
        <CalendarEvent
          eventRef={eventRef}
          event={event}
          eventName={eventName}
          isWeekView={isWeekView}
          detailedView={detailedView}
          setRowHeight={(row, height, eventId) => {
            setRowHeights((rh) => {
              if (rh.find((r) => r.eventId === eventId && r.row === row)) {
                return rh;
              }
              return [
                ...rh,
                {
                  eventId,
                  row,
                  height
                }
              ];
            });
          }}
          rowHeights={rowHeights}
          eventStyles={{
            ...eventStyles(),
            background: styles.eventBackgroundColor,
            borderColor: styles.eventBackgroundColor,
            color: styles.eventTextColor,
            ...(eventRef.s && {
              borderTopLeftRadius: `${styles.eventBorderRadius}px`,
              borderBottomLeftRadius: `${styles.eventBorderRadius}px`
            }),
            ...(eventRef.e && {
              borderTopRightRadius: `${styles.eventBorderRadius}px`,
              borderBottomRightRadius: `${styles.eventBorderRadius}px`
            })
          }}
          staticEventHeight={staticEventHeight}
        />
      </HtmlTooltip>
    );
  };

  return (
    <>
      <table className="sc-calendar">
        <tbody>
          <tr className="weekdays" style={{ background: styles.headerBackgroundColor }}>
            <th scope="col" style={{ color: styles.headerTextColor }}>
              Sunday
            </th>
            <th scope="col" style={{ color: styles.headerTextColor }}>
              Monday
            </th>
            <th scope="col" style={{ color: styles.headerTextColor }}>
              Tuesday
            </th>
            <th scope="col" style={{ color: styles.headerTextColor }}>
              Wednesday
            </th>
            <th scope="col" style={{ color: styles.headerTextColor }}>
              Thursday
            </th>
            <th scope="col" style={{ color: styles.headerTextColor }}>
              Friday
            </th>
            <th scope="col" style={{ color: styles.headerTextColor }}>
              Saturday
            </th>
          </tr>
        </tbody>
      </table>
      {calendarRows.map((days, i) => (
        <table className="sc-calendar" key={`calendar-row-${i}`} style={{ backgroundColor: styles.backgroundColor }}>
          <tbody>
            <tr className="days">
              {days.map((day, i) => (
                <td
                  key={`sc-calendar-day-${day.dayNumber}-${day.otherMonth ? 'other' : 'current'}`}
                  className={`day${day.otherMonth ? ' other-month' : ''}`}
                  style={{
                    borderColor: styles.borderColor,
                    ...(day.otherMonth && {
                      backgroundColor: styles.backgroundPrevNextColor
                    })
                  }}
                >
                  <div
                    className="date"
                    style={{
                      backgroundColor: styles.dayBackgroundColor,
                      color: styles.dayTextColor,
                      borderRadius: `${styles.dayBorderRadius}px`,
                      marginLeft: `${styles.dayPosition === 'r' ? 'auto' : '5px'}`
                    }}
                  >
                    {day.dayNumber}
                  </div>
                  {getEvents(day)}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      ))}
    </>
  );
};

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 340,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    marginTop: 2
  }
}))(Tooltip);

export const getCalendarStyle = (style: MonthCalendarStyles, theme: Theme) => {
  return {
    eventBackgroundColor: theme.palette.custom.calendar[style].eventBackgroundColor,
    eventTextColor: theme.palette.custom.calendar[style].eventTextColor,
    eventBorderRadius: theme.palette.custom.calendar[style].eventBorderRadius,
    headerBackgroundColor: theme.palette.custom.calendar[style].headerBackgroundColor,
    headerTextColor: theme.palette.custom.calendar[style].headerTextColor,
    borderColor: theme.palette.custom.calendar[style].borderColor,
    backgroundColor: theme.palette.custom.calendar[style].backgroundColor,
    backgroundPrevNextColor: theme.palette.custom.calendar[style].backgroundPrevNextColor,
    dayBackgroundColor: theme.palette.custom.calendar[style].dayBackgroundColor,
    dayTextColor: theme.palette.custom.calendar[style].dayTextColor,
    dayBorderRadius: theme.palette.custom.calendar[style].dayBorderRadius,
    dayPosition: theme.palette.custom.calendar[style].dayPosition
  };
};

export default MonthCalendarElement;
