import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../../components/Shared/DataTable/types';

export enum DoAppCreditTransactionStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  PENDING = 'pending'
}

export type DoAppCreditTransaction = {
  id: string;
  createdAt: Date;
  subscriber: {
    id: string;
    email: string;
  };
  merchant: {
    id: string;
    name: string;
  };
  amount: number;
  invoiceId: string | null;
  acceptedAt: Date | null;
  rejection: null | {
    createdAt: Date;
    comment: string;
    userId: string;
  };
};

export type DoAppCreditTransactionsIndexResponse = {
  data: DoAppCreditTransaction[];
  pagination: Pagination;
};

export type IndexDoAppCreditTransactionsRequestParams = {
  per: number;
  page: number;
  q: string | null;
  order?: string[];
};

export type CreateCreditTransactionRequestDto = {
  merchantAccountNumber: string;
  subscriberEmail: string;
  amount: number;
  deviceId: string;
};

export type DoAppCreditTransactionsMineIndexResponse = {
  data: DoAppCreditTransaction[];
  pagination: Pagination;
};

export type IndexDoAppCreditTransactionsMineRequestParams = {
  per: number;
  page: number;
  q: string | null;
  order?: string[];
  status?: DoAppCreditTransactionStatus;
};

export type DoAppMerchantCreditTransactionsState = StateWithPagination &
  StateWithTableSearch<DoAppCreditTransaction> & {
    data: NormalizedDoAppMerchantCreditTransactions;
    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedDoAppMerchantCreditTransactions = {
  entities: Dictionary<DoAppCreditTransaction>;
  result: string[];
};

export type DoAppMerchantCreditTransactionsIndexActionBEGIN = {
  type: DoAppMerchantCreditTransactionsActionsConstants.INDEX_BEGIN;
};

export type DoAppMerchantCreditTransactionsIndexActionSUCCESS = {
  type: DoAppMerchantCreditTransactionsActionsConstants.INDEX_SUCCESS;
  payload: DoAppCreditTransactionsMineIndexResponse;
};

export type DoAppMerchantCreditTransactionsIndexActionFAILED = {
  type: DoAppMerchantCreditTransactionsActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export enum DoAppMerchantCreditTransactionsActionsConstants {
  INDEX_BEGIN = 'DO_APP/MERCHANT_CREDIT_TRANSACTIONS/INDEX/BEGIN',
  INDEX_SUCCESS = 'DO_APP/MERCHANT_CREDIT_TRANSACTIONS/INDEX/SUCCESS',
  INDEX_FAILED = 'DO_APP/MERCHANT_CREDIT_TRANSACTIONS/INDEX/FAILED'
}

export type DoAppMerchantCreditTransactionsActionType =
  | DoAppMerchantCreditTransactionsIndexActionBEGIN
  | DoAppMerchantCreditTransactionsIndexActionSUCCESS
  | DoAppMerchantCreditTransactionsIndexActionFAILED;
