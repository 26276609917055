import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import { CreateSubscriberValues, IndexSubscribersRequestParams, UpdateSubscriberValues } from './types';

export const index = (settings: RequestSettings<IndexSubscribersRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/api/v1/mailing_list/:mailingListId/subscribers?per=:per&page=:page&status=:status`
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{ mailingListId: string }, CreateSubscriberValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/mailing_list/:mailingListId/subscribers'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const update = (
  settings: RequestSettings<{ mailingListId: string; id: string }, UpdateSubscriberValues>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/mailing_list/:mailingListId/subscribers/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteSubscriber = (
  settings: RequestSettings<{ mailingListId: string }, { id: string }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/mailing_list/:mailingListId/subscribers'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const importCsv = (settings: {
  requestPayload: FormData;
  requestParams: { mailingListId: string };
}): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/mailing_list/:mailingListId/subscribers/csv'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});
