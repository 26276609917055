import React from 'react';
import { ColumnName, DataTableTableSearchProps } from '../types';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

type DataTableColumnOrderButtonsProps<E = never, UCN extends string = never> = {
  columnName: ColumnName<E, UCN>;
} & DataTableTableSearchProps<E>;

export function DataTableColumnOrderButtons<E = never, UCN extends string = never>({
  columnName,
  tableSearch,
  module
}: DataTableColumnOrderButtonsProps<E, UCN>): JSX.Element {
  const currentOrder = tableSearch.order[columnName];

  return (
    <ButtonsContainer>
      {(!currentOrder || !currentOrder.order) && (
        <SortableIndicator>
          <UnfoldMoreIcon fontSize="small" />
        </SortableIndicator>
      )}
      {currentOrder && currentOrder.order === 'asc' && (
        <IconButton size="small">
          <KeyboardArrowUp fontSize="small" />
        </IconButton>
      )}
      {currentOrder && currentOrder.order === 'desc' && (
        <IconButton size="small">
          <KeyboardArrowDown fontSize="small" />
        </IconButton>
      )}
    </ButtonsContainer>
  );
}

const SortableIndicator = styled.div`
  opacity: 0.4;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default DataTableColumnOrderButtons;
