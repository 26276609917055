import React, { FunctionComponent } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { FilterHeader } from '../../AdminStyledComponents';
import { AdminProjectsFilters } from '../../../../../store/AdminProjects/types';
import { useTranslation } from 'react-i18next';
import { EmailProjectStatus } from '../../../../../store/EmailProjects/types';
import { AnyProjectStatus } from '../../../../../store/Dashboard/types';

type ProjectStatusFiltersProps = {
  filters: AdminProjectsFilters['status'];
  onChange: (status: AnyProjectStatus | 'locked') => void;
};

const ProjectStatusFilters: FunctionComponent<ProjectStatusFiltersProps> = ({ filters, onChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <FilterHeader>{t('pages.adminProjects.filters.status')}</FilterHeader>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="inprogress"
              checked={filters.includes(EmailProjectStatus.IN_PROGRESS)}
              onChange={() => onChange(EmailProjectStatus.IN_PROGRESS)}
            />
          }
          label={t('common.projectStatus.email.IN_PROGRESS')}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="scheduled"
              checked={filters.includes(EmailProjectStatus.SCHEDULED)}
              onChange={() => onChange(EmailProjectStatus.SCHEDULED)}
            />
          }
          label={`${t('common.projectStatus.email.SCHEDULED')}/${t('common.projectStatus.print.SCHEDULED')}`}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="published"
              checked={filters.includes(EmailProjectStatus.PUBLISHED)}
              onChange={() => onChange(EmailProjectStatus.PUBLISHED)}
            />
          }
          label={`${t('common.projectStatus.email.PUBLISHED')}/${t('common.projectStatus.print.DELIVERED')}`}
        />
        <FormControlLabel
          control={<Checkbox name="locked" checked={filters.includes('locked')} onChange={() => onChange('locked')} />}
          label={t('common.locked')}
        />
      </FormGroup>
    </>
  );
};

export default ProjectStatusFilters;
