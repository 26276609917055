import { EmailProjectRow, ProjectModalColumnSize, ProjectModelColumn, structureElements } from '../../../types';
import { TFunction } from 'i18next';
import { AdminSelectInput, AdminTextInput } from '../../../../../Admin/Shared/AdminFormInputs';
import * as Yup from 'yup';
import update from 'immutability-helper';
import React, { HTMLAttributes, useState } from 'react';
import { RowEditFormReturnType } from '../index';
import { Grid, MenuItem } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { FormikProps } from 'formik';
import { AdminInputLabel } from '../../../../../Admin/AdminStyledComponents';
import ColorPicker from '../../../../../../Shared/ColorPicker/ColorPicker';
import { Row, Strong } from '../../../../../../Shared/StyledComponents';
import Border from './border';
import styled from 'styled-components';
import { StructureIcon } from '../../Styling/StructureIcon';
import ModalConfirmationWindow from '../../../../../../Shared/Window/ModalConfirmationWindow';

const roles: EmailProjectRow['role'][] = ['not-set', 'header', 'footer'];

type Values = {
  cols: ProjectModelColumn[];
  style: {
    backgroundColor: string;
    marginTop: number;
    marginBottom: number;
    marginLeft: number;
    marginRight: number;
    paddingTop: number;
    paddingBottom: number;
    paddingLeft: number;
    paddingRight: number;
    borderTopLeftRadius: number;
    borderTopRightRadius: number;
    borderBottomLeftRadius: number;
    borderBottomRightRadius: number;
    borderWidth: number;
    borderStyle: string;
    borderColor: string;
  };
  border: {
    top: boolean;
    right: boolean;
    bottom: boolean;
    left: boolean;
  };
  role: EmailProjectRow['role'];
};

const updateColsBasedOnStructure = (structure: ProjectModalColumnSize[], currentCols: ProjectModelColumn[]) => {
  const updatedCols: ProjectModelColumn[] = [];
  let index = 0;

  for (const size of structure) {
    updatedCols.push({
      size,
      components: currentCols[index]?.components || [] // Retain existing components if available
    });
    index++;
  }

  return updatedCols;
};

export const row = (
  component: EmailProjectRow,
  t: TFunction,
  inProjectEditor: boolean
): RowEditFormReturnType<EmailProjectRow> => {
  const findMatchingStructureElement = (
    row: EmailProjectRow,
    structureElements: { name: string; sizes: ProjectModalColumnSize[] }[]
  ) => {
    const sizesMatch = (arr1: number[], arr2: number[]) => {
      if (arr1.length !== arr2.length) return false;
      return arr1.every((size, index) => size === arr2[index]);
    };

    for (const structureEl of structureElements) {
      const sizes = row.cols.map((col) => col.size);
      if (sizesMatch(structureEl.sizes, sizes)) {
        return structureEl;
      }
    }

    return null; // Return null if no matching structureElement is found
  };

  const [activeStructure, setActiveStructure] = useState<{
    name: string;
    sizes: ProjectModalColumnSize[];
  } | null>(null);
  const [selectedStructure, setSelectedStructure] = useState<{
    name: string;
    sizes: ProjectModalColumnSize[];
  } | null>(findMatchingStructureElement(component, structureElements));

  const isRowBackgroundColorDisabled = (props: FormikProps<any>) => {
    if (props.values.separator) {
      if (
        props.values.separator.style.typeStyle === 'solid' &&
        [
          props.values.style.paddingTop,
          props.values.style.paddingBottom,
          props.values.style.paddingLeft,
          props.values.style.paddingRight
        ].every((val) => parseInt(val) <= 0)
      ) {
        return true;
      }
    }

    return false;
  };

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const form = (props: FormikProps<Values>) => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AdminInputLabel>
          <Strong>{t('pages.emailEditor.row.structure')}</Strong>
        </AdminInputLabel>
        <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'center' }}>
          {structureElements.map((structureEl, i) => (
            <StructureElement
              key={`structure-element-${i}`}
              selected={structureEl === selectedStructure}
              onClick={() => {
                if (structureEl !== selectedStructure) {
                  const updatedCols = updateColsBasedOnStructure(structureEl.sizes, component.cols);

                  if (updatedCols.length < component.cols.length) {
                    setActiveStructure(structureEl);
                    setOpenConfirmationModal(true);
                    return;
                  }

                  props.setFieldValue('cols', updatedCols);
                  setSelectedStructure(structureEl);
                }
              }}
            >
              <StructureIcon sizes={structureEl.sizes} />
              <Name>{structureEl.name}</Name>
            </StructureElement>
          ))}
          {activeStructure && (
            <ModalConfirmationWindow
              open={openConfirmationModal}
              header={t('pages.emailEditor.row.confirmation')}
              okButtonText={t('common.confirm')}
              onCloseClick={() => setOpenConfirmationModal(false)}
              onSubmit={() => {
                const updatedCols = updateColsBasedOnStructure(activeStructure.sizes, component.cols);
                props.setFieldValue('cols', updatedCols);
                setSelectedStructure(activeStructure);
                setOpenConfirmationModal(false);
              }}
            >
              <Row>
                <Strong>{t('pages.emailEditor.row.reducingColumnsWarning')}</Strong>
              </Row>
            </ModalConfirmationWindow>
          )}
        </div>
      </Grid>
      {!inProjectEditor && (
        <Grid item xs={12}>
          <AdminSelectInput t={t} name="role" section="emailEditor.row">
            {roles.map((role) => (
              <MenuItem key={role} value={role}>
                {t(`pages.emailEditor.rowRole.${role}`)}
              </MenuItem>
            ))}
          </AdminSelectInput>
        </Grid>
      )}
      <Grid item xs={12}>
        <AdminInputLabel style={isRowBackgroundColorDisabled(props) ? { opacity: '0.2' } : {}}>
          <Strong>{t('pages.emailEditor.row.inputs.backgroundColor')}</Strong>
        </AdminInputLabel>
        <ColorPicker
          disabled={isRowBackgroundColorDisabled(props)}
          color={props.values.style.backgroundColor}
          onChange={(backgroundColor) => props.setFieldValue('style.backgroundColor', backgroundColor)}
        />
      </Grid>
      <Grid item xs={7}>
        <AdminInputLabel>
          <Strong>{t('pages.emailEditor.row.inputs.margin')}</Strong>
        </AdminInputLabel>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <AdminTextInput t={t} type="number" name="style.marginTop" section="emailEditor.row" />
          </Grid>
          <Grid item xs={3}>
            <AdminTextInput t={t} type="number" name="style.marginRight" section="emailEditor.row" />
          </Grid>
          <Grid item xs={3}>
            <AdminTextInput t={t} type="number" name="style.marginBottom" section="emailEditor.row" />
          </Grid>
          <Grid item xs={3}>
            <AdminTextInput t={t} type="number" name="style.marginLeft" section="emailEditor.row" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={7}>
        <AdminInputLabel>
          <Strong>{t('pages.emailEditor.row.inputs.padding')}</Strong>
        </AdminInputLabel>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <AdminTextInput t={t} type="number" name="style.paddingTop" section="emailEditor.row" />
          </Grid>
          <Grid item xs={3}>
            <AdminTextInput t={t} type="number" name="style.paddingRight" section="emailEditor.row" />
          </Grid>
          <Grid item xs={3}>
            <AdminTextInput t={t} type="number" name="style.paddingBottom" section="emailEditor.row" />
          </Grid>
          <Grid item xs={3}>
            <AdminTextInput t={t} type="number" name="style.paddingLeft" section="emailEditor.row" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <AdminInputLabel>
          <Strong>{t('pages.emailEditor.row.inputs.border')}</Strong>
        </AdminInputLabel>
        <Border
          selectedButtons={{
            left: props.values.border.left,
            top: props.values.border.top,
            right: props.values.border.right,
            bottom: props.values.border.bottom
          }}
          onClick={(border) =>
            props.setFieldValue(`border.${border}`, (props.values.border as any)[border] === true ? false : true)
          }
        />
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <AdminTextInput t={t} type="number" name="style.borderWidth" section="emailEditor.row" />
          </Grid>
          <Grid item xs={4}>
            <AdminSelectInput t={t} name="style.borderStyle" section="emailEditor.row">
              <MenuItem key="none" value="none">
                {t('pages.emailEditor.row.inputs.none')}
              </MenuItem>
              <MenuItem key="solid" value="solid">
                {t('pages.emailEditor.row.inputs.solid')}
              </MenuItem>
              <MenuItem key="dashed" value="dashed">
                {t('pages.emailEditor.row.inputs.dashed')}
              </MenuItem>
            </AdminSelectInput>
          </Grid>
          <Grid item xs={4}>
            <AdminInputLabel>{t('pages.emailEditor.row.inputs.style.borderColor')}</AdminInputLabel>
            <ColorPicker
              color={props.values.style.borderColor}
              onChange={(color) => props.setFieldValue('style.borderColor', color)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <AdminInputLabel>
          <Strong>{t('pages.emailEditor.row.inputs.borderRadius')}</Strong>
        </AdminInputLabel>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <AdminTextInput t={t} type="number" name="style.borderTopLeftRadius" section="emailEditor.row" />
          </Grid>
          <Grid item xs={3}>
            <AdminTextInput t={t} type="number" name="style.borderTopRightRadius" section="emailEditor.row" />
          </Grid>
          <Grid item xs={3}>
            <AdminTextInput t={t} type="number" name="style.borderBottomLeftRadius" section="emailEditor.row" />
          </Grid>
          <Grid item xs={3}>
            <AdminTextInput t={t} type="number" name="style.borderBottomRightRadius" section="emailEditor.row" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const initialFields: Values = extractInitialFieldValues(component);

  const validationSchema = {
    cols: Yup.array(),
    style: Yup.object().shape({
      backgroundColor: Yup.string().required(),
      marginTop: Yup.number().required(),
      marginBottom: Yup.number().required(),
      marginLeft: Yup.number().required(),
      marginRight: Yup.number().required(),
      paddingTop: Yup.number().required(),
      paddingBottom: Yup.number().required(),
      paddingLeft: Yup.number().required(),
      paddingRight: Yup.number().required(),
      borderTopLeftRadius: Yup.number().required(),
      borderTopRightRadius: Yup.number().required(),
      borderBottomLeftRadius: Yup.number().required(),
      borderBottomRightRadius: Yup.number().required(),
      borderWidth: Yup.number().required(),
      borderStyle: Yup.string().required(),
      borderColor: Yup.string().required()
    }),
    border: Yup.object().shape({
      top: Yup.boolean().required(),
      right: Yup.boolean().required(),
      bottom: Yup.boolean().required(),
      left: Yup.boolean().required()
    }),
    role: Yup.string()
  };

  return {
    form,
    initialFields,
    validationSchema,
    onSubmit: (params: Values) =>
      update(component, {
        cols: { $set: params.cols },
        style: { $set: extractStylesForRow(params) },
        border: { $set: params.border },
        role: { $set: params.role }
      })
  };
};

const extractStylesForRow = (params: Values): CSSProperties => {
  return {
    backgroundColor: params.style.backgroundColor,
    marginTop: `${params.style.marginTop}px`,
    marginBottom: `${params.style.marginBottom}px`,
    marginLeft: `${params.style.marginLeft}px`,
    marginRight: `${params.style.marginRight}px`,
    paddingTop: `${params.style.paddingTop}px`,
    paddingBottom: `${params.style.paddingBottom}px`,
    paddingLeft: `${params.style.paddingLeft}px`,
    paddingRight: `${params.style.paddingRight}px`,
    borderTopLeftRadius: `${params.style.borderTopLeftRadius}px`,
    borderTopRightRadius: `${params.style.borderTopRightRadius}px`,
    borderBottomLeftRadius: `${params.style.borderBottomLeftRadius}px`,
    borderBottomRightRadius: `${params.style.borderBottomRightRadius}px`,
    borderColor: params.style.borderColor,
    borderStyle: params.style.borderStyle,
    borderWidth: `${params.style.borderWidth}px`
  };
};

const extractInitialFieldValues = (component: EmailProjectRow) => {
  const styles = component.style || {};

  const extractedStyles = {
    cols: component.cols,
    style: {
      backgroundColor: '#FFFFFF',
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderWidth: 0,
      borderStyle: 'solid',
      borderColor: '#000000'
    },
    border: {
      top: component.border?.top ?? true,
      right: component.border?.right ?? true,
      bottom: component.border?.bottom ?? true,
      left: component.border?.left ?? true
    },
    role: component.role || 'not-set'
  };

  const div = document.createElement('div');
  Object.assign(div.style, styles);
  div.style.display = 'none';
  document.body.appendChild(div);

  const computed = window.getComputedStyle(div, null);

  // @ts-ignore
  if (computed['background-color']) {
    // @ts-ignore
    extractedStyles.style.backgroundColor = computed['background-color'] || '#FFFFFF';
  }

  // @ts-ignore
  if (computed['margin-top']) {
    // @ts-ignore
    extractedStyles.style.marginTop = parseInt(computed['margin-top']) || 0;
  }

  // @ts-ignore
  if (computed['margin-bottom']) {
    // @ts-ignore
    extractedStyles.style.marginBottom = parseInt(computed['margin-bottom']) || 0;
  }

  // @ts-ignore
  if (computed['margin-left']) {
    // @ts-ignore
    extractedStyles.style.marginLeft = parseInt(computed['margin-left']) || 0;
  }

  // @ts-ignore
  if (computed['margin-right']) {
    // @ts-ignore
    extractedStyles.style.marginRight = parseInt(computed['margin-right']) || 0;
  }

  // @ts-ignore
  if (computed['padding-top']) {
    // @ts-ignore
    extractedStyles.style.paddingTop = parseInt(computed['padding-top']) || 0;
  }

  // @ts-ignore
  if (computed['padding-bottom']) {
    // @ts-ignore
    extractedStyles.style.paddingBottom = parseInt(computed['padding-bottom']) || 0;
  }

  // @ts-ignore
  if (computed['padding-left']) {
    // @ts-ignore
    extractedStyles.style.paddingLeft = parseInt(computed['padding-left']) || 0;
  }

  // @ts-ignore
  if (computed['padding-right']) {
    // @ts-ignore
    extractedStyles.style.paddingRight = parseInt(computed['padding-right']) || 0;
  }

  // @ts-ignore
  if (computed['border-top-left-radius']) {
    // @ts-ignore
    extractedStyles.style.borderTopLeftRadius = parseInt(computed['border-top-left-radius']) || 0;
  }

  // @ts-ignore
  if (computed['border-top-right-radius']) {
    // @ts-ignore
    extractedStyles.style.borderTopRightRadius = parseInt(computed['border-top-right-radius']) || 0;
  }

  // @ts-ignore
  if (computed['border-bottom-left-radius']) {
    // @ts-ignore
    extractedStyles.style.borderBottomLeftRadius = parseInt(computed['border-bottom-left-radius']) || 0;
  }

  // @ts-ignore
  if (computed['border-bottom-right-radius']) {
    // @ts-ignore
    extractedStyles.style.borderBottomRightRadius = parseInt(computed['border-bottom-right-radius']) || 0;
  }

  // @ts-ignore
  if (computed['border-color']) {
    // @ts-ignore
    extractedStyles.style.borderColor = computed['border-color'] || '#000000';
  }

  // @ts-ignore
  if (computed['border-width']) {
    // @ts-ignore
    extractedStyles.style.borderWidth = parseInt(computed['border-width']) || 0;
  }

  // @ts-ignore
  if (computed['border-style']) {
    // @ts-ignore
    extractedStyles.style.borderStyle = computed['border-style'] || 'solid';
  }

  document.body.removeChild(div);

  return extractedStyles;
};

type StructureElementProps = HTMLAttributes<HTMLDivElement> & { selected: boolean };

const StructureElement = styled.div<StructureElementProps>`
  border: ${(props) => (props.selected ? '2px solid #364766' : '1px solid #d4d4d4')};
  background-color: white;
  padding: 0.7rem 1rem 0.4rem;
  border-radius: 10px;
  color: #717175;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  align-items: center;
  width: 140px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
`;

const Name = styled.div`
  margin-top: 5px;
`;
