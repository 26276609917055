import React from 'react';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { structureElements } from '../../../types';
import { StructureElement } from '../Helpers/StructureElement';
import { getEmptyColumnsArray } from '../../../Utils';

export const StructureElements: React.FC<{}> = () => {
  return (
    <StyledPaper>
      {structureElements.map((structureEl, i) => (
        <StructureElement
          key={`structure-element-${i}`}
          name={structureEl.name}
          cols={getEmptyColumnsArray(structureEl.sizes)}
          sizes={structureEl.sizes}
        />
      ))}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: 30px;
`;
