import React, { FunctionComponent } from 'react';
import { withTheme } from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import linksConstants from '../../../../config/app/linksConstants';
import { CampaignProject, CampaignProjectType } from '../../../../store/Campaigns/types';
import CampaignProjectAvatar from './CampaignProjectAvatar';

type CampaignProjectNameCellProps = {
  project: CampaignProject;
};

const CampaignProjectNameCell: FunctionComponent<CampaignProjectNameCellProps> = ({ project }) => {
  return (
    <ProjectArea>
      <CampaignProjectAvatar type={project.type} />
      <div>
        <ProjectTitle
          to={
            project.type === CampaignProjectType.STORY
              ? linksConstants.STORY.EDIT(project.id)
              : linksConstants.PROJECT_DETAILS(project.id)
          }
        >
          {project.name}
        </ProjectTitle>
        <SubTitle>{project.type}</SubTitle>
      </div>
    </ProjectArea>
  );
};

const ProjectArea = styled.div`
  display: flex;
`;

export const ProjectTitle = withTheme(styled(Link)<{ disabled?: boolean }>`
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  word-break: normal;
  ${({ disabled }) => (disabled ? `pointer-events: none;` : '')}
`);

const SubTitle = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 0.8rem;
  padding-right: 2rem;
  word-break: normal;
`);

export default CampaignProjectNameCell;
