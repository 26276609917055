import styled from 'styled-components';
import {
  Dialog as DialogMUI,
  DialogActions as DialogActionsMUI,
  DialogContent as DialogContentMUI,
  DialogTitle as DialogTitleMUI,
  IconButton,
  withTheme
} from '@material-ui/core';

export const DialogStyled = styled(DialogMUI)<{ $iframeContent?: boolean; $showScrollBar?: boolean }>`
  .MuiDialogContent-root {
    ${({ $iframeContent, $showScrollBar }) => {
      if ($iframeContent) {
        return `
          padding: 0;
          overflow: ${$showScrollBar ? 'auto' : 'hidden'};
        `;
      }
    }}
  }

  .MuiDialog-paper {
    padding: 0;
    min-width: 40vw;

    ${({ $iframeContent }) => {
      if ($iframeContent) {
        return `
          min-width: 90vw;
          min-height: 90vh;
          width: 90vw;
          height: 90vh;
          transition: all 0.5s ease-in-out;
        `;
      }
    }}
  }
`;

export const DialogHeaderStyled = withTheme(styled(DialogTitleMUI)`
  font-size: 1.2rem;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
`);

export const DialogContentStyled = styled(DialogContentMUI)``;

export const DialogActionsStyled = styled(DialogActionsMUI)`
  border-top: 1px solid #ccc;
  padding: 1.2rem;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;
