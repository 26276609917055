import React, { FunctionComponent } from 'react';
import { LegendElement } from './types';

type LegendProps = {
  legendData: LegendElement[];
  positionTop: number;
  positionLeft: number;
};

export const Legend: FunctionComponent<LegendProps> = ({ legendData, positionTop, positionLeft }) => {
  return (
    <g className="legend" transform={`translate(${positionLeft},${positionTop})`}>
      {legendData.map((el, i) => (
        <g transform={`translate(${el.marginLeft ? el.marginLeft : el.text.length * 22 * i},0)`} key={i}>
          {el.type === 'rect' && <rect width="16" height="16" fill={el.color} />}
          {el.type === 'line' && <line y1="8" x2="16" y2="8" stroke={el.color} strokeWidth={3} />}
          {el.type === 'circle' && <circle cx="8" cy="8" r="8" fill={el.color} />}
          <text transform={`translate(22,14)`}>{el.text}</text>
        </g>
      ))}
    </g>
  );
};
