import React, { FunctionComponent } from 'react';
import { Avatar, withTheme } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import { TemplateTypeName } from '../../../store/Templates/types';
import { ReactComponent as SharingCenterIcon } from '../../../assets/icons/navigation/sharing-center.svg';

type ProjectAvatarProps = {
  type: TemplateTypeName;
  locked?: boolean;
  shared?: boolean;
};

const ProjectAvatar: FunctionComponent<ProjectAvatarProps> = ({ type, locked, shared }) => {
  const prefix = locked && <LockIconStyled />;
  const postfix = shared && <SharedIconStyled />;

  switch (type) {
    case TemplateTypeName.BULLETIN:
      return (
        <BulletinAvatar>
          {prefix}B{postfix}
        </BulletinAvatar>
      );

    case TemplateTypeName.EMAIL:
      return (
        <EmailAvatar>
          {prefix}E{postfix}
        </EmailAvatar>
      );

    case TemplateTypeName.NEWSLETTER:
      return (
        <NewsletterAvatar>
          {prefix}N{postfix}
        </NewsletterAvatar>
      );

    case TemplateTypeName.FLYER:
      return (
        <FlyerAvatar>
          {prefix}F{postfix}
        </FlyerAvatar>
      );

    case TemplateTypeName.DIRECTORY:
      return (
        <DirectoryAvatar>
          {prefix}D{postfix}
        </DirectoryAvatar>
      );

    default:
      return (
        <MissingTemplate>
          <DeleteIcon />
        </MissingTemplate>
      );
  }
};

const ProjectAvatarStyled = withTheme(styled(Avatar)`
  margin-right: 1rem;
  position: relative;
  overflow: visible;
`);
const LockIconStyled = withTheme(styled(LockIcon)`
  position: absolute;
  top: -6px;
  right: -5px;
  color: ${({ theme }) => theme.palette.primary.main};
`);
const SharedIconStyled = withTheme(styled(SharingCenterIcon)`
  width: 20px;
  position: absolute;
  bottom: -6px;
  right: -5px;
  fill: #fff;
  height: 20px;
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;
  padding: 2px;
`);
const BulletinAvatar = withTheme(styled(ProjectAvatarStyled)`
  background: ${({ theme }) => theme.palette.custom.templateTypes.bulletin};
`);
const EmailAvatar = withTheme(styled(ProjectAvatarStyled)`
  background: ${({ theme }) => theme.palette.custom.templateTypes.email};
`);
const NewsletterAvatar = withTheme(styled(ProjectAvatarStyled)`
  background: ${({ theme }) => theme.palette.custom.templateTypes.newsletter};
`);
const FlyerAvatar = withTheme(styled(ProjectAvatarStyled)`
  background: ${({ theme }) => theme.palette.custom.templateTypes.flyer};
`);
const DirectoryAvatar = withTheme(styled(ProjectAvatarStyled)`
  background: ${({ theme }) => theme.palette.custom.templateTypes.directory};
`);
const MissingTemplate = withTheme(styled(ProjectAvatarStyled)`
  background: #939393;
`);

export default ProjectAvatar;
