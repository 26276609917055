import AdminOnlineUsersPage from './AdminOnlineUsersPage';
import { ApplicationRoute } from '../../../../typings';
import linksConstants from '../../../../config/app/linksConstants';

const AdminOnlineUsersRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.ADMINISTRATION.ONLINE_USERS.INDEX,
    exact: true,
    name: 'onlineUsers',
    public: false,
    show: true,
    component: AdminOnlineUsersPage,
    scope: []
  }
];

export default AdminOnlineUsersRoutes;
