import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import SupportPage from './SupportPage';

const SupportRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.SUPPORT,
    exact: true,
    name: 'systemSettings',
    public: false,
    show: true,
    component: SupportPage,
    scope: []
  }
];

export default SupportRoutes;
