import React from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { AdPlacementStatus, ItemTypes, ProjectModelComponent } from '../../../types';
import { ContentIcon } from '../../ContentIcon';
import styled from 'styled-components';

const style: React.CSSProperties = {
  border: '1px solid #D4D4D4',
  backgroundColor: 'white',
  padding: '0.7rem 1rem 0.4rem',
  cursor: 'move',
  borderRadius: '10px',
  color: '#717175',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '12px',
  alignItems: 'center',
  width: '100%'
};

interface ContentElementProps {
  componentType: ProjectModelComponent['type'];
  extraLabel?: string;
  data?: AdPlacementStatus;
}

export const ContentElement: React.FC<ContentElementProps> = ({ componentType, extraLabel, data }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { componentType, type: ItemTypes.CONTENT, data },
    end: (
      item: { componentType: ProjectModelComponent['type']; type: string } | undefined,
      monitor: DragSourceMonitor
    ) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        // alert(`You dropped ${item.name} into ${dropResult.name}!`);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0.4 : 1;

  return (
    <div ref={drag} style={{ ...style, opacity }}>
      <ContentIcon type={componentType} />
      {componentType}
      <ExtraLabel>{extraLabel}</ExtraLabel>
    </div>
  );
};

const ExtraLabel = styled.div`
  font-style: italic;
  font-size: 0.8rem;
  font-weight: bold;
`;
