import { createOrderArray, httpRequest } from '../../../utils';
import { CreateSponsorRequestBodyDto, DoAppSponsorsIndexResponse } from './types';
import { doAppSponsorsRequests } from './index';

export const indexRaw = (page: number, per: number, q: string | null, order: any) => {
  return httpRequest.runRequest<DoAppSponsorsIndexResponse>(
    doAppSponsorsRequests.index({
      requestParams: {
        page: page,
        per: per,
        q: q,
        order: createOrderArray(order)
      }
    })
  );
};

export const createSponsor = async (data: CreateSponsorRequestBodyDto) => {
  return httpRequest.runRequest(
    doAppSponsorsRequests.create({
      requestPayload: data
    })
  );
};

export const getRegionApps = async () => {
  return httpRequest.runRequest<{ data: { app: string; name: string }[] }>(doAppSponsorsRequests.getRegionApps());
};
