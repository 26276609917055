import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../../../Shared/StyledComponents';

type DeleteRowWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  onFormSubmit: () => void;
  fullScreenOnMobile: boolean;
  rowHasContent: boolean;
};

const DeleteRowWindow: FunctionComponent<DeleteRowWindowProps> = ({
  onCloseClick,
  onFormSubmit,
  open,
  rowHasContent
}) => {
  const { t } = useTranslation();

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.emailEditor.windows.deleteRow.header')}
      okButtonText={t('pages.emailEditor.windows.deleteRow.confirm')}
      onCloseClick={onCloseClick}
      onSubmit={onFormSubmit}
    >
      <Row>
        {rowHasContent
          ? t('pages.emailEditor.windows.deleteRow.areYouSureToDeleteRowWithContent')
          : t('pages.emailEditor.windows.deleteRow.areYouSureToDeleteRow')}
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteRowWindow;
