import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row } from '../../../Shared/StyledComponents';
import { Button, Divider, Grid } from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';

type PreviouslyEnteredPromptsWindowProps = {
  prompts: string[];
  onPromptLoad: (prompt: string) => void;
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const PreviouslyEnteredPromptsWindow: FunctionComponent<PreviouslyEnteredPromptsWindowProps> = ({
  prompts,
  onPromptLoad,
  onCloseClick,
  open
}) => {
  const PROMPT_CHARACTERS_LIMIT = 160;
  const { t } = useTranslation();
  const [expandedPromptIndex, setExpandedPromptIndex] = useState<number | null>(null);

  const toggleExpand = (index: number) => {
    setExpandedPromptIndex(index === expandedPromptIndex ? null : index);
  };

  const renderPromptRow = (prompt: string, index: number) => {
    const isExpanded = index === expandedPromptIndex;
    const shouldShowReadMoreButton = prompt.length > PROMPT_CHARACTERS_LIMIT;

    const displayedPrompt = isExpanded
      ? prompt
      : prompt.slice(0, PROMPT_CHARACTERS_LIMIT) + (shouldShowReadMoreButton ? '...' : '');

    return (
      <Row key={`prompt-row-${index}`}>
        <Grid container alignItems="center" style={{ marginBottom: 10 }}>
          <Grid item xs={10}>
            <div>{displayedPrompt}</div>
            {shouldShowReadMoreButton && (
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => toggleExpand(index)}
                style={{ marginTop: 5 }}
              >
                {isExpanded
                  ? t('pages.story.windows.createWithAi.readLess')
                  : t('pages.story.windows.createWithAi.readMore')}
              </Button>
            )}
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Button
              color={'secondary'}
              variant={'contained'}
              size="small"
              onClick={() => {
                onPromptLoad(prompt);
              }}
            >
              <UpdateIcon style={{ width: '16px', height: '16px', marginRight: 5 }} />
              {t('pages.story.windows.createWithAi.load')}
            </Button>
          </Grid>
        </Grid>
        {index < prompts.length - 1 && <Divider />}
      </Row>
    );
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.story.windows.createWithAi.previouslyEnteredPrompts')}
      onCloseClick={onCloseClick}
      hideActionButtons
      maxWidth="sm"
    >
      {prompts.map(renderPromptRow)}
      <Divider />
      <Row style={{ textAlign: 'right' }}>
        <Button color={'secondary'} variant={'outlined'} size="medium" onClick={onCloseClick}>
          {t('common.close')}
        </Button>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default PreviouslyEnteredPromptsWindow;
