import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Paper, withTheme } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { AdBanner, AdCategory } from '../../../../../../store/AdminAds/types';
import AdminAdsTable from '../../AdminAdsTable';
import TableActionButton from '../../../../../Shared/DataTable/TableActionButton/TableActionButton';
import EditIcon from '@material-ui/icons/Edit';
import { hasPermission } from '../../../../../../utils/permissions';
import DeleteIcon from '@material-ui/icons/Delete';
import TableActionButtonsContainer from '../../../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import { useTypedSelector } from '../../../../../../utils';
import { useTranslation } from 'react-i18next';

type HouseAdCategorySectionProps = {
  banners: AdBanner[];
  category: AdCategory;
  onRemoveAdBanner: (banner: AdBanner) => void;
  onUpdateAdBanner: (banner: AdBanner) => void;
  onRemoveAdCategory: (category: AdCategory) => void;
  onUpdateAdCategory: (category: AdCategory) => void;
};

const HouseAdCategorySection: FunctionComponent<HouseAdCategorySectionProps> = ({
  banners,
  category,
  onRemoveAdBanner,
  onUpdateAdBanner,
  onRemoveAdCategory,
  onUpdateAdCategory
}) => {
  const { t } = useTranslation();
  const role = useTypedSelector((state) => state.auth.role);
  const [expanded, setExpanded] = useState<boolean>(false);

  if (category.id === 'uncategorized' && !banners.length) {
    return null;
  }

  return (
    <div>
      <Wrapper>
        <NameSection onClick={() => setExpanded(!expanded)}>
          <div>{expanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}</div>
          <div>
            <Title>
              {category.name} ({banners.length})
            </Title>
            {category.description && <Description>{category.description}</Description>}
          </div>
        </NameSection>
        <ActionsSection>
          {category.id !== 'uncategorized' && (
            <TableActionButtonsContainer>
              <TableActionButton
                icon={<EditIcon />}
                tooltip={t('pages.adminAds.table.buttons.editCategory')}
                ButtonProps={{
                  disabled: !hasPermission(role, ['adsEdit']),
                  onClick: () => onUpdateAdCategory(category)
                }}
              />
              <TableActionButton
                icon={<DeleteIcon />}
                tooltip={t('pages.adminAds.table.buttons.removeCategory')}
                ButtonProps={{
                  disabled: !hasPermission(role, ['adsRemove']),
                  onClick: () => onRemoveAdCategory(category)
                }}
              />
            </TableActionButtonsContainer>
          )}
        </ActionsSection>
      </Wrapper>

      <div>
        {expanded && (
          <AdminAdsTable
            banners={banners}
            isLoading={false}
            onUpdateAdBanner={onUpdateAdBanner}
            onRemoveAdBanner={onRemoveAdBanner}
            showStartDate={false}
            showEndDate={false}
          />
        )}
      </div>
    </div>
  );
};

const Wrapper = withTheme(styled(Paper)`
  display: flex;
  cursor: pointer;
  margin: 1rem 0;
  padding: 1rem;
  align-items: center;
`);

const NameSection = withTheme(styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-grow: 1;
`);
const ActionsSection = withTheme(styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`);
const Title = withTheme(styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-left: 0.5rem;
`);
const Description = withTheme(styled.div`
  font-size: 0.8rem;
  font-style: italic;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-left: 0.5rem;
`);

export default HouseAdCategorySection;
