import React, { FunctionComponent } from 'react';
import { EmailSocialLinks } from '../../types';
import { useTypedSelector } from '../../../../../../utils';
import { getDefaultImage } from './Factories/initialComponentFactory';

type SocialLinksProps = {
  data: EmailSocialLinks;
};

const SocialLinks: FunctionComponent<SocialLinksProps> = ({ data }) => {
  const organization = useTypedSelector((state) => state.auth.organization);
  const facebookLink = organization?.facebookLink || '';
  const instagramLink = organization?.instagramLink || '';
  const linkedinLink = organization?.linkedinLink || '';
  const twitterLink = organization?.twitterLink || '';
  const youtubeLink = organization?.youtubeLink || '';

  // prevent undefined error if the template has no youtube social icon specified
  if (youtubeLink && !data.params.images.youtube) {
    data.params.images.youtube = getDefaultImage(30, 30);
  }

  return (
    <div style={data.wrapperStyle}>
      {facebookLink && (
        <div style={{ display: 'inline-block' }}>
          <a target="_blank" rel="noopener noreferrer" href={facebookLink}>
            <svg
              fill={data.params.color}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={data.params.images.facebook.width}
              height={data.params.images.facebook.height}
            >
              {/* eslint-disable-next-line max-len */}
              <path d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h7.621v-6.961h-2.343v-2.725h2.343V9.309 c0-2.324,1.421-3.591,3.495-3.591c0.699-0.002,1.397,0.034,2.092,0.105v2.43h-1.428c-1.13,0-1.35,0.534-1.35,1.322v1.735h2.7 l-0.351,2.725h-2.365V21H19c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z" />
            </svg>
          </a>
        </div>
      )}
      {instagramLink && (
        <div style={{ display: 'inline-block' }}>
          <a target="_blank" rel="noopener noreferrer" href={instagramLink}>
            <svg
              fill={data.params.color}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={data.params.images.instagram.width}
              height={data.params.images.instagram.height}
            >
              {/* eslint-disable-next-line max-len */}
              <path d="M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z" />
            </svg>
          </a>
        </div>
      )}
      {linkedinLink && (
        <div style={{ display: 'inline-block' }}>
          <a target="_blank" rel="noopener noreferrer" href={linkedinLink}>
            <svg
              fill={data.params.color}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={data.params.images.linkedIn.width}
              height={data.params.images.linkedIn.height}
            >
              {/* eslint-disable-next-line max-len */}
              <path d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M9,17H6.477v-7H9 V17z M7.694,8.717c-0.771,0-1.286-0.514-1.286-1.2s0.514-1.2,1.371-1.2c0.771,0,1.286,0.514,1.286,1.2S8.551,8.717,7.694,8.717z M18,17h-2.442v-3.826c0-1.058-0.651-1.302-0.895-1.302s-1.058,0.163-1.058,1.302c0,0.163,0,3.826,0,3.826h-2.523v-7h2.523v0.977 C13.93,10.407,14.581,10,15.802,10C17.023,10,18,10.977,18,13.174V17z" />
            </svg>
          </a>
        </div>
      )}
      {twitterLink && (
        <div style={{ display: 'inline-block' }}>
          <a target="_blank" rel="noopener noreferrer" href={twitterLink}>
            <svg
              fill={data.params.color}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={data.params.images.twitter.width}
              height={data.params.images.twitter.height}
            >
              {/* eslint-disable-next-line max-len */}
              <path d="M10.053,7.988l5.631,8.024h-1.497L8.566,7.988H10.053z M21,6v12	c0,1.657-1.343,3-3,3H6c-1.657,0-3-1.343-3-3V6c0-1.657,1.343-3,3-3h12C19.657,3,21,4.343,21,6z M17.538,17l-4.186-5.99L16.774,7	h-1.311l-2.704,3.16L10.552,7H6.702l3.941,5.633L6.906,17h1.333l3.001-3.516L13.698,17H17.538z"></path>
            </svg>
          </a>
        </div>
      )}
      {youtubeLink && (
        <div style={{ display: 'inline-block' }}>
          <a target="_blank" rel="noopener noreferrer" href={youtubeLink}>
            <svg
              fill={data.params.color}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={data.params.images.youtube.width}
              height={data.params.images.youtube.height}
            >
              {/* eslint-disable-next-line max-len */}
              <path d="M10.51,9.86l3.82,2.17l-3.82,2.17V9.86z M21.01,4.95v14.14c0,1.06-0.86,1.93-1.93,1.93H4.94 c-1.06,0-1.93-0.86-1.93-1.93V4.95c0-1.06,0.86-1.93,1.93-1.93h14.14C20.14,3.02,21.01,3.89,21.01,4.95z M19.32,12.03 c0,0,0-2.4-0.3-3.54c-0.17-0.64-0.66-1.13-1.29-1.3c-1.14-0.31-5.71-0.31-5.71-0.31s-4.58,0-5.71,0.31 c-0.63,0.17-1.12,0.67-1.29,1.3c-0.3,1.14-0.3,3.54-0.3,3.54s0,2.4,0.3,3.54c0.17,0.64,0.66,1.11,1.29,1.28 c1.14,0.3,5.71,0.3,5.71,0.3s4.58,0,5.71-0.31c0.63-0.17,1.12-0.65,1.29-1.28C19.32,14.43,19.32,12.03,19.32,12.03L19.32,12.03z" />
            </svg>
          </a>
        </div>
      )}
    </div>
  );
};

export default SocialLinks;
