import update from 'immutability-helper';
import { AdminRole, AdminRolesActionsConstants, AdminRolesActionType, AdminRolesState } from './types';
import { normalizeRoles } from '../helpers';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: AdminRolesState = {
  roles: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const adminRolesPaginationReducer = createPaginationReducer<AdminRolesState, AdminRole>(
  PaginationStoreModule.ADMIN_ROLES
);

const adminRolesTableSearchReducer = createTableSearchReducer<AdminRole, AdminRolesState>(
  TableSearchStoreModule.ADMIN_ROLES
);

export default function adminRolesReducer(state = initialState, action: WithLogoutAction<AdminRolesActionType>) {
  switch (action.type) {
    case AdminRolesActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case AdminRolesActionsConstants.INDEX_SUCCESS: {
      const data = normalizeRoles(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        roles: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case AdminRolesActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        roles: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AdminRolesActionsConstants.KEYWORD_REMOVED:
    case AdminRolesActionsConstants.KEYWORD_ADDED: {
      return update(state, {
        roles: {
          entities: {
            [action.payload.id]: {
              keywords: { $set: action.payload.keywords }
            }
          }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return adminRolesTableSearchReducer(adminRolesPaginationReducer(state, action), action) as AdminRolesState;
    }
  }
}
