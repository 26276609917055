import moment from 'moment';
import { PasswordPolicy } from '../store/SystemSettings/types';
import { User } from '../store/Auth/types';

export function getPasswordExpirationData(passwordPolicy: PasswordPolicy, user: User) {
  const rotationEnabled = passwordPolicy.forceRotation;
  const expirationDate = moment(user.passwordUpdatedAt).add(passwordPolicy.rotationDays, 'days');
  const daysToExpire = expirationDate.diff(moment(), 'days');
  const percentOfTimeLeft = Math.floor((daysToExpire / passwordPolicy.rotationDays) * 100);
  const humanReadableTimeToExpire = moment().to(expirationDate);

  return {
    rotationEnabled,
    daysToExpire,
    percentOfTimeLeft,
    humanReadableTimeToExpire
  };
}
