import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Template, TemplateTypeName } from '../../../../../store/Templates/types';
import { templatesOperations } from '../../../../../store/Templates';
import DoneIcon from '@material-ui/icons/Done';
import { createNetworkErrorObject, formatPublishDate } from '../../../../../utils';
import Loader from '../../../../Shared/Loading/Loader';
import { NoResultsFound } from '../../../PageStyledComponents';
import TemplatePaper from '../../../../Shared/Layout/TemplatePaper';
import styled from 'styled-components';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';
import { Light, OutlinedInfoBoxContent, Strong } from '../../../../Shared/StyledComponents';
import InfoIcon from '@material-ui/icons/Info';
import { Button, Chip, useTheme, withTheme } from '@material-ui/core';
import { EmailProjectCreatorState } from '../../types';
import { Keyword } from '../../../../../store/Keywords/types';
import { AnyProject } from '../../../../../store/Dashboard/types';
import { keywordsOperations } from '../../../../../store/Keywords';

type SelectTemplateProps = {
  onSubmit: (template: Template) => void;
  templateTypeName: TemplateTypeName;
  sourceProject: AnyProject | null;
  setCurrentState: (state: EmailProjectCreatorState) => void;
  publishDate: Date;
  infoText: string;
  showTopBar: boolean;
  showSourceProject: boolean;
  showOnlyDesigns?: boolean;
  onBackClick?: () => void;
  showSimplifiedTopBar?: boolean;
};

const SelectTemplate: FunctionComponent<SelectTemplateProps> = ({
  onSubmit,
  templateTypeName,
  publishDate,
  setCurrentState,
  showTopBar,
  sourceProject,
  infoText,
  showSourceProject,
  showSimplifiedTopBar,
  onBackClick,
  showOnlyDesigns
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([]);
  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const templates = await templatesOperations.getTemplates(templateTypeName, {
          showOnlyDesigns: !!showOnlyDesigns
        });

        const templatesKeywordsIds: Set<string> = templates.data.reduce((keywordIds, template) => {
          template.keywords.forEach((keyword) => keywordIds.add(keyword.id));
          return keywordIds;
        }, new Set<string>());

        setTemplates(templates.data);
        setFilteredTemplates(templates.data);

        if (showTopBar) {
          const keywords = await keywordsOperations.getUserKeywords();
          setKeywords(keywords.data.filter((keyword) => templatesKeywordsIds.has(keyword.id)));
        }
      } catch (e) {
        const networkError = createNetworkErrorObject(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedKeywords.length) {
      setFilteredTemplates(
        // templates.filter((template) => template.keywords.find((keyword) => selectedKeywords.includes(keyword.id)))
        templates.filter((template) =>
          selectedKeywords.every((selectedKeyword) =>
            template.keywords.find((keyword) => keyword.id === selectedKeyword)
          )
        )
      );
    } else {
      setFilteredTemplates(templates);
    }
  }, [selectedKeywords]);

  const onKeywordClick = (keyword: Keyword) => {
    if (isKeywordSelected(keyword)) {
      setSelectedKeywords(selectedKeywords.filter((id) => id !== keyword.id));
    } else {
      setSelectedKeywords([...selectedKeywords, keyword.id]);
    }
  };

  const isKeywordSelected = (keyword: Keyword) => {
    return selectedKeywords.includes(keyword.id);
  };

  return (
    <div>
      {showSimplifiedTopBar && (
        <SimplifiedTopBar>
          <div style={{ margin: '1rem 3rem' }}>
            <Button
              onClick={() => {
                if (onBackClick) {
                  onBackClick();
                }
              }}
              color="secondary"
              variant="contained"
              size="medium"
            >
              {t('common.back')}
            </Button>
          </div>
          <InfoBoxWrapper>
            <InfoBox type="outlinedInfo">
              <OutlinedInfoBoxContent>
                <InfoIcon fontSize="large" />
                <div>
                  <Strong>{infoText}</Strong>
                </div>
              </OutlinedInfoBoxContent>
            </InfoBox>
          </InfoBoxWrapper>
        </SimplifiedTopBar>
      )}
      {showTopBar && (
        <TopBarContainer>
          <InfoBoxWrapper>
            <InfoBox type="outlinedInfo">
              <OutlinedInfoBoxContent>
                <InfoIcon fontSize="large" />
                <div>
                  <Strong>{infoText}</Strong>
                </div>
              </OutlinedInfoBoxContent>
            </InfoBox>
          </InfoBoxWrapper>
          <PublishDateWrapper>
            <div>
              <Light>{t('common.publishDate')}</Light>: {formatPublishDate(publishDate)}
            </div>
            {showSourceProject && (
              <div>
                <Light>{t('common.printProduct')}</Light>: {sourceProject ? sourceProject.name : '---'}{' '}
                <Button
                  color={'secondary'}
                  variant="outlined"
                  size="large"
                  onClick={() => setCurrentState('printProduct')}
                >
                  {t('common.change')}
                </Button>
              </div>
            )}
          </PublishDateWrapper>
        </TopBarContainer>
      )}

      {loading && <Loader />}
      {!loading && (
        <>
          {keywords.length > 0 && (
            <FilterByKeywordsWrapper>
              <div>{t('pages.dashboard.filterByKeyword')}</div>
              <div>
                {keywords.map((keyword) => (
                  <KeywordItem
                    key={keyword.id}
                    avatar={isKeywordSelected(keyword) ? <DoneIcon /> : null}
                    label={keyword.name}
                    onClick={() => onKeywordClick(keyword)}
                    clickable
                    color={isKeywordSelected(keyword) ? 'secondary' : 'primary'}
                    variant="outlined"
                  />
                ))}
              </div>
            </FilterByKeywordsWrapper>
          )}

          <TemplatesList>
            {filteredTemplates.map((template) => (
              <TemplatePaper
                onClick={() => onSubmit(template)}
                key={template.id}
                id={template.id}
                title={template.name}
                description={template.description}
                color={theme.palette.custom.templateTypes[template.type]}
                thumbnailUrl={template.thumbnailUrl}
              />
            ))}
          </TemplatesList>
        </>
      )}
      {!loading && templates.length === 0 && <NoResultsFound>{t('pages.dashboard.noTemplates')}</NoResultsFound>}
    </div>
  );
};
const TopBarContainer = styled.div`
  display: flex;
  align-items: center;

  .MuiButtonBase-root {
    margin-left: 1rem;
  }
`;
const InfoBoxWrapper = styled.div``;
const PublishDateWrapper = styled.div`
  font-size: 1.1rem;
  flex-grow: 1;
  text-align: right;

  > div {
    margin-bottom: 1rem;
  }

  .MuiButtonBase-root {
  }
`;

const TemplatesList = styled.div`
  margin-top: 2rem;
  text-align: center;
`;

const FilterByKeywordsWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
`;

const KeywordItem = withTheme(styled(Chip)`
  margin: 0 0.5rem;

  .MuiChip-avatarColorSecondary {
    color: ${({ theme }) => theme.palette.secondary.main};
    background: transparent;
  }
`);

const SimplifiedTopBar = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export default SelectTemplate;
