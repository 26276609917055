import React, { FunctionComponent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../PageStyledComponents';
import { timeToTimestamp, timestampToTime } from '../../../../utils';
import app from '../../../../config/app/app';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { adminMailingListsOperations } from '../../../../store/AdminMailingLists';
import { adminOrganizationsOperations } from '../../../../store/AdminOrganizations';
import { AdminOrganization, AdminUpdateOrganizationValues } from '../../../../store/AdminOrganizations/types';
import { MailingList } from '../../../../store/MailingLists/types';
import Loader from '../../../Shared/Loading/Loader';
import Toast from '../../../Shared/Toast/Toast';
import { AdminPasswordPolicy } from '../../../../store/AdminPasswordPolicies/types';
import { adminPasswordPoliciesOperations } from '../../../../store/AdminPasswordPolicies';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import AdminOrganizationForm from './Partials/AdminOrganizationForm';

type AdminOrganizationPageProps = RouteComponentProps<{ organizationId: string }> & {};

const AdminOrganizationEditPage: FunctionComponent<AdminOrganizationPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mailingLists, setMailingLists] = useState<MailingList[]>([]);
  const [organization, setOrganization] = useState<AdminOrganization | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [passwordPolicies, setPasswordPolicies] = useState<AdminPasswordPolicy[]>([]);

  const organizationId = match.params.organizationId;

  useEffect(() => {
    const getMailingLists = async () => {
      if (organizationId) {
        try {
          const mailingLists = await adminMailingListsOperations.getOrganizationMailingLists(organizationId);
          setMailingLists(mailingLists.data);
        } catch (e) {
          Toast.error(t('notifications.mailingLists.fetchingListsError'));
        }
      }
    };
    const fetchPasswordPolicies = async () => {
      const policies = await adminPasswordPoliciesOperations.getAll();
      setPasswordPolicies(policies.data);
    };
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await adminOrganizationsOperations.show(organizationId);

        setOrganization(data);

        await fetchPasswordPolicies();
        await getMailingLists();
      } catch (e) {
        Toast.error(t('notifications.getBusinessUserData.error'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [organizationId]);

  if (!organizationId) return null;

  const onSubmit = async (values: AdminUpdateOrganizationValues) => {
    try {
      await dispatch(
        adminOrganizationsOperations.update(organizationId, {
          name: values.name,
          accountNumber: values.accountNumber,
          injectLogoToPrintProjects: values.injectLogoToPrintProjects,
          stripProtocolFromPrintInjectedUrls: values.stripProtocolFromPrintInjectedUrls,
          socialPublishing: values.socialPublishing,
          bulletin: values.bulletin,
          enableCampaigns: values.enableCampaigns,
          email: values.email,
          flyer: values.flyer,
          newsletter: values.newsletter,
          directory: values.directory,
          colorSwatchesLimit: values.colorSwatchesLimit || null,
          readMoreLink: values.readMoreLink || null,
          passwordPolicyId: values.passwordPolicyId,
          emailWizardEnabled: values.emailWizardEnabled,
          automatedEmailEnabled: values.automatedEmailEnabled,
          defaultAutomatedEmailTemplateId: values.defaultAutomatedEmailTemplateId,
          automatedEmailDateSchedule: values.automatedEmailDateSchedule,
          automatedEmailTime: timeToTimestamp(values.automatedEmailTime),
          automatedEmailSubject: values.automatedEmailSubject || null,
          defaultAutomatedEmailMailingListId: values.defaultAutomatedEmailMailingListId,
          senderEmail: values.senderEmail === '-1' ? null : values.senderEmail,
          parentId: values.parentId === '-1' ? null : values.parentId,
          dam: values.dam && values.dam.id && values.dam.id !== '-1' ? values.dam : null,
          printCenters: values.printCenters,
          region: values.region,
          district: values.district
        })
      );
      Toast.success(t('notifications.adminOrganizations.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.adminOrganizations.errorOperation'));
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <AdminOrganizationForm props={props} passwordPolicies={passwordPolicies} mailingLists={mailingLists} />
  );

  const backButton = {
    onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.INDEX)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.adminOrganizations.updateOrganization')} leftActionButtons={[backButton]} />
      {loading && <Loader />}
      {!loading && organization && (
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            name: organization.name,
            accountNumber: organization.accountNumber,
            passwordPolicyId: organization.passwordPolicyId,
            injectLogoToPrintProjects: organization.injectLogoToPrintProjects,
            colorSwatchesLimit: organization.colorSwatchesLimit,
            readMoreLink: organization.readMoreLink,
            stripProtocolFromPrintInjectedUrls: organization.stripProtocolFromPrintInjectedUrls,
            enableCampaigns: organization.enableCampaigns,
            socialPublishing: organization.socialPublishing,
            bulletin: organization.bulletin,
            email: organization.email,
            flyer: organization.flyer,
            newsletter: organization.newsletter,
            directory: organization.directory,
            defaultAutomatedEmailTemplateId: organization.defaultAutomatedEmailTemplateId,
            emailWizardEnabled: organization.emailWizardEnabled,
            automatedEmailEnabled: organization.automatedEmailEnabled,
            automatedEmailDateSchedule: organization.automatedEmailDateSchedule,
            automatedEmailTime: timestampToTime(organization.automatedEmailTime),
            automatedEmailSubject: organization.automatedEmailSubject,
            defaultAutomatedEmailMailingListId: organization.defaultAutomatedEmailMailingListId,
            senderEmail: organization.senderEmail === null ? '-1' : organization.senderEmail,
            parentId: organization.parentId === null ? '-1' : organization.parentId,
            printCenters: (organization.printCenters || []).reduce((obj, curr) => {
              return {
                ...obj,
                [curr.id]: true
              };
            }, {}),
            dam:
              organization.damIntegrations && organization.damIntegrations[0]
                ? {
                    id: organization.damIntegrations[0].systemId,
                    apiKey: organization.damIntegrations[0].apiKey
                  }
                : null,
            region: organization.region,
            district: organization.district
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(app.maxInputLength).required(),
            accountNumber: Yup.string().max(app.maxInputLength).required(),
            injectLogoToPrintProjects: Yup.boolean(),
            stripProtocolFromPrintInjectedUrls: Yup.boolean(),
            socialPublishing: Yup.boolean(),
            bulletin: Yup.boolean(),
            email: Yup.boolean(),
            flyer: Yup.boolean(),
            newsletter: Yup.boolean(),
            directory: Yup.boolean(),
            emailWizardEnabled: Yup.boolean(),
            automatedEmailEnabled: Yup.boolean(),
            defaultAutomatedEmailTemplateId: Yup.string().nullable(),
            colorSwatchesLimit: Yup.number().min(0).max(200).nullable(),
            readMoreLink: Yup.string().nullable(),
            passwordPolicyId: Yup.string().required(),
            automatedEmailTime: Yup.string().nullable(),
            automatedEmailDateSchedule: Yup.string().nullable(),
            automatedEmailSubject: Yup.string().nullable(),
            defaultAutomatedEmailMailingListId: Yup.string().nullable(),
            senderEmail: Yup.string().nullable(),
            parentId: Yup.string().nullable(),
            printCenters: Yup.object(),
            enableCampaigns: Yup.boolean(),
            dam: Yup.object()
              .shape({
                id: Yup.string(),
                apiKey: Yup.string()
              })
              .nullable()
          })}
        >
          {formInputs}
        </Formik>
      )}
    </PageContainer>
  );
};

export default AdminOrganizationEditPage;
