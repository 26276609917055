export type AdminAdsState = {
  index: {
    isLoading: boolean;
    isFailed: boolean;
    error: NetworkError | null;
  };
};

export type Advertiser = {
  advertiserId: string;
  accountId: string;
  agencyId: string;
  advertiserName: string;
  contactName: string;
  emailAddress: string;
  comments: string;
  updated: string;
  banners: AdBanner[];
};

export type AdZone = {
  zoneId: string;
  publisherId: string;
  zoneName: string;
  type: string;
  width: string;
  height: string;
  capping: string;
  sessionCapping: string;
  block: string;
  comments: string;
  append: string;
  prepend: string;
  chainedZoneId: string | null;
};

export type AdCategory = {
  id: string;
  name: string;
  description: string;
  createdAt?: Date;
  updateAt?: Date;
};

export type HouseAdsBannersCategory = {
  category: AdCategory;
  banners: AdBanner[];
};

export type AdBanner = {
  bannerId: string;
  campaignId: string;
  bannerName: string;
  storageType: string;
  imageURL: string;
  categoryId: string;
  width: string;
  height: string;
  weight: string;
  target: string;
  url: string;
  bannerText: string;
  status: string;
  adserver: string;
  transparent: string;
  capping: string;
  sessionCapping: string;
  block: string;
  aImage: null;
  aBackupImage: null;
  comments: string;
  alt: string;
  filename: string;
  updated: string;
  startDate: string;
  endDate: string;
};

export type ChartData = {
  impressions: string;
  clicks: string;
  requests: string;
  revenue: string;
  day: string;
};

export type ChartDataByDays = {
  id: number;
  start_date: string;
  end_date: string;
  days: {
    data: ChartData[];
  };
};

export type AdvertiserStatisticsData = {
  impressions: string;
  clicks: string;
  requests: string;
  revenue: string;
  advertiserid: string;
  advertisername: string;
};

export type AdvertiserStatistics = {
  id: number;
  start_date: string;
  end_date: string;
  advertisers: {
    data: AdvertiserStatisticsData[];
  };
};

export type AdvertiserBannerStatisticsData = {
  impressions: string;
  clicks: string;
  requests: string;
  revenue: string;
  campaignid: string;
  campaignname: string;
  bannerid: string;
  bannername: string;
};

export type AdvertiserBannerStatistics = {
  id: number;
  start_date: string;
  end_date: string;
  banners: {
    data: AdvertiserBannerStatisticsData[];
  };
};

export type Filter = {
  value: number;
  name: string;
};

export type AdminCreateAdCategoryValues = Pick<AdCategory, 'name' | 'description'>;
export type AdminUpdateAdCategoryValues = Pick<AdCategory, 'name' | 'description'>;

export type AdminCreateAdBannerValues = Pick<
  AdBanner,
  | 'bannerName'
  | 'height'
  | 'width'
  | 'comments'
  | 'alt'
  | 'filename'
  | 'storageType'
  | 'url'
  | 'weight'
  | 'target'
  | 'startDate'
  | 'endDate'
> & { bannerFile: File; advertiserId: string } & {
  organization: {
    id: string;
    label: string;
  };
  category?: {
    id: string;
    label: string;
  };
};

export type AdminUpdateAdBannerValues = Pick<
  AdBanner,
  | 'bannerName'
  | 'height'
  | 'width'
  | 'comments'
  | 'alt'
  | 'filename'
  | 'storageType'
  | 'url'
  | 'weight'
  | 'target'
  | 'startDate'
  | 'endDate'
> & { bannerFile: File | null; advertiserId: string } & {
  organization: {
    id: string;
    label: string;
  };
  category?: {
    id: string;
    label: string;
  };
};

export type AdminAdBannersIndexResponse = {
  data: AdBanner[];
};

export type AdminAdBannersIndexActionSUCCESS = {
  type: AdminAdsActionsConstants.INDEX_ADBANNERS_SUCCESS;
  payload: {
    response: AdminAdBannersIndexResponse;
    advertiserId: string;
  };
};

export enum AdminAdsActionsConstants {
  INDEX_ADBANNERS_SUCCESS = 'ADMIN_ADS/INDEX_ADBANNERS/SUCCESS'
}

export type AdminAdsActionType = AdminAdBannersIndexActionSUCCESS;
