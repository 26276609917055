import {
  ChangePageAction,
  ChangePageActionPayload,
  ChangePaginationAction,
  ChangePaginationActionPayload,
  ChangePerAction,
  ChangePerActionPayload,
  ChangeSearchQueryAction,
  ChangeSearchQueryActionPayload,
  ClearPaginationAction,
  PaginationActionsConstants,
  PaginationStoreModule
} from './types';

export const changePage = (module: PaginationStoreModule, payload: ChangePageActionPayload): ChangePageAction => ({
  type: PaginationActionsConstants.CHANGE_PAGE,
  payload,
  module
});

export const changePer = (module: PaginationStoreModule, payload: ChangePerActionPayload): ChangePerAction => ({
  type: PaginationActionsConstants.CHANGE_PER,
  payload,
  module
});

export const changeSearchQuery = (
  module: PaginationStoreModule,
  payload: ChangeSearchQueryActionPayload
): ChangeSearchQueryAction => ({
  type: PaginationActionsConstants.CHANGE_SEARCH_QUERY,
  payload,
  module
});

export const changePagination = (
  module: PaginationStoreModule,
  payload: ChangePaginationActionPayload
): ChangePaginationAction => ({
  type: PaginationActionsConstants.CHANGE_PAGINATION,
  payload,
  module
});

export const clearPagination = (module: PaginationStoreModule): ClearPaginationAction => ({
  type: PaginationActionsConstants.CLEAR_ALL,
  module
});
