import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import { AdsStatisticsTitle } from '../AdsStatisticsPage';
import { Paper } from '@material-ui/core';
import { adminAdsOperations } from '../../../../store/AdminAds';
import { ChartData } from '../../../../store/AdminAds/types';
import { ChartData as ChartDataFromChartJs } from 'chart.js';
import Loader from '../../../Shared/Loading/Loader';

type AdsStatisticsChartProps = {};

const AdsStatisticsChart: FunctionComponent<AdsStatisticsChartProps> = () => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<ChartDataFromChartJs | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const days: ChartData[] = [];
  const labelsData: string[] = [];
  const clicksData: number[] = [];
  const impressionsData: number[] = [];

  const getDateMinusXDays = (x: number, leadingZero: boolean): string => {
    const date = new Date();
    date.setDate(date.getDate() - x);

    const dayOfTheMonth = date.getDate();
    const month = date.getMonth() + 1;
    const dayOfTheMonthWithLeadingZero = dayOfTheMonth < 10 ? `0${dayOfTheMonth}` : dayOfTheMonth;
    return `${date.getFullYear()}-${month < 10 ? `0${month}` : month}-${
      leadingZero ? dayOfTheMonthWithLeadingZero : dayOfTheMonth
    }`;
  };

  const fetchChartData = async () => {
    const response = await adminAdsOperations.getChartData(getDateMinusXDays(6, false), getDateMinusXDays(0, false));

    for (let index = 6; index >= 0; index--) {
      const day = getDateMinusXDays(index, true);

      response.data.days.data.forEach((dayEl) => {
        if (dayEl.day === day) {
          days.push(dayEl);
        } else {
          days.push({
            impressions: '0',
            clicks: '0',
            requests: '0',
            revenue: '0',
            day
          });
        }
      });
    }

    days.forEach((day) => {
      labelsData.push(day.day);
      clicksData.push(parseInt(day.clicks));
      impressionsData.push(parseInt(day.impressions));
    });

    setChartData({
      labels: labelsData,
      datasets: [
        {
          label: `# ${t('pages.adsStatistics.clicks')}`,
          data: clicksData,
          fill: false,
          backgroundColor: 'rgb(31, 119, 180)',
          borderColor: 'rgb(31, 119, 180)'
        },
        {
          label: `# ${t('pages.adsStatistics.impressions')}`,
          data: impressionsData,
          fill: false,
          backgroundColor: 'rgb(255, 127, 14)',
          borderColor: 'rgb(255, 127, 14)'
        }
      ]
    });

    setLoading(false);
  };

  useEffect(() => {
    fetchChartData();
  }, []);

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1
        },
        type: 'linear',
        display: true,
        gridLines: {
          drawOnArea: false
        }
      }
    },
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          padding: 20
        }
      }
    }
  };

  return (
    <Paper style={{ padding: '20px', height: '302px' }}>
      {loading && <Loader />}
      {!loading && (
        <>
          <AdsStatisticsTitle>{t('pages.adsStatistics.clicksVsImpressions')}</AdsStatisticsTitle>
          <Line type="line" data={chartData} options={options} style={{ margin: '20px 30px 30px' }} />
        </>
      )}
    </Paper>
  );
};

export default AdsStatisticsChart;
