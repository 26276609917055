import React, { FunctionComponent, useEffect, useState } from 'react';
import { PageContainer, PageContent } from '../PageStyledComponents';
import PageHeader from '../../Shared/Layout/PageHeader';
import { useTypedSelector } from '../../../utils';
import { useTranslation } from 'react-i18next';
import Loader from '../../Shared/Loading/Loader';
import { RouteComponentProps } from 'react-router';
import { usePaginationWatch } from '../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../Shared/DataTable/useTableSearchWatch';
import DataTable from '../../Shared/DataTable/DataTable';
import { TableSearchStoreModule } from '../../../store/TableSearch/types';
import { PaginationStoreModule } from '../../../store/Pagination/types';
import { sharingCenterActions, sharingCenterOperations } from '../../../store/SharingCenter';
import { SharedProject, SharedProjectsListingType } from '../../../store/SharingCenter/types';
import ProjectNameCell from '../Dashboard/WorkInProgressTable/ProjectNameCell';
import { Tab, Tabs } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import TableActionButtonsContainer from '../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../Shared/DataTable/TableActionButton/TableActionButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import ShareIcon from '@material-ui/icons/Share';
import { ReactComponent as CloneProjectIcon } from '../../../assets/icons/clone.svg';
import linksConstants from '../../../config/app/linksConstants';
import { push } from 'connected-react-router';
import useOpenHandler from '../../../hooks/useOpenHandler';
import CloneProjectWindow from './Windows/CloneProjectWindow';
import ProjectShareWindow from '../Dashboard/Windows/ProjectShareWindow';
import ProjectDetailsWindow from './Windows/ProjectDetailsWindow';
import SharedStoryNameCell from '../Dashboard/WorkInProgressTable/SharedStoryNameCell';

type SharingCenterPageProps = RouteComponentProps<{}> & {};

const SharingCenterPage: FunctionComponent<SharingCenterPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const user = useTypedSelector((state) => state.auth.user);
  const role = useTypedSelector((state) => state.auth.role);
  const projects = useTypedSelector((state) => state.sharingCenter.projects);

  const activeTab = useTypedSelector((state) => state.sharingCenter.type);
  const isFailed = useTypedSelector((state) => state.sharingCenter.index.isFailed);
  const loading = useTypedSelector((state) => state.sharingCenter.index.isLoading);
  const pagination = useTypedSelector((state) => state.sharingCenter.pagination);
  const tableSearch = useTypedSelector((state) => state.sharingCenter.tableSearch);

  useEffect(() => {
    if (!loading) {
      setInitialLoading(false);
    }
  }, [loading]);

  usePaginationWatch(pagination, [sharingCenterOperations.getProjectsSharedWithMe]);
  useTableSearchWatch<SharedProject>(tableSearch, [sharingCenterOperations.getProjectsSharedWithMe]);

  const [cloneProjectWindowOpen, onCloneProjectWindowOpen, onCloneProjectWindowClose] = useOpenHandler();
  const [shareProjectWindowOpen, onShareProjectWindowOpen, onShareProjectWindowClose] = useOpenHandler();
  const [detailsProjectWindowOpen, onDetailsProjectWindowOpen, onDetailsProjectWindowClose] = useOpenHandler();
  const [activeProject, setActiveProject] = useState<SharedProject | null>(null);

  const tabs = (
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(e, tab) => {
        dispatch(sharingCenterActions.setListingType(tab));
        dispatch(sharingCenterOperations.getProjectsSharedWithMe());
      }}
    >
      <Tab label={t('pages.sharingCenter.tabs.sharedWithMe')} value={SharedProjectsListingType.SHARED_WITH_ME} />
      <Tab label={t('pages.sharingCenter.tabs.iSharedWith')} value={SharedProjectsListingType.I_SHARED_WITH} />
    </Tabs>
  );

  return (
    <PageContainer>
      <PageHeader title={t('navigation.sharingCenter')} />
      <PageContent>
        {initialLoading && <Loader />}
        {(!initialLoading || pagination.q) && (
          <DataTable<SharedProject, 'actions'>
            enableSearch={true}
            wrapperStyle={{
              padding: '0.5rem 2rem 0'
            }}
            tabs={tabs}
            headingStyle={{
              fontSize: '1.4rem',
              color: '#014b72',
              textAlign: 'left',
              padding: '0.5rem',
              fontWeight: 'normal'
            }}
            columnDefinitions={[
              {
                name: 'name',
                sortable: true,
                render: (project) =>
                  ['email', 'print'].includes(project.kind) ? (
                    <ProjectNameCell customId={project.projectId} project={project} user={user} role={role} />
                  ) : (
                    <SharedStoryNameCell customId={project.projectId} project={project} />
                  )
              },
              {
                name: 'actions',
                sortable: false,
                render: (project) => (
                  <div>
                    {activeTab === SharedProjectsListingType.SHARED_WITH_ME && (
                      <TableActionButtonsContainer>
                        <TableActionButton
                          icon={<VisibilityIcon />}
                          tooltip={t('pages.sharingCenter.table.buttons.open')}
                          ButtonProps={{
                            onClick: () =>
                              ['email', 'print'].includes(project.kind)
                                ? dispatch(push(linksConstants.PROJECT_DETAILS(project.projectId)))
                                : dispatch(
                                    push({
                                      pathname: linksConstants.STORY.EDIT(project.projectId),
                                      state: { from: linksConstants.DASHBOARD.SHARING_CENTER }
                                    })
                                  )
                          }}
                        />
                        <TableActionButton
                          icon={<CloneProjectIcon fill="white" width={18} />}
                          tooltip={t('pages.sharingCenter.table.buttons.clone')}
                          ButtonProps={{
                            onClick: () => {
                              setActiveProject(project);
                              onCloneProjectWindowOpen();
                            }
                          }}
                        />
                      </TableActionButtonsContainer>
                    )}
                    {activeTab === SharedProjectsListingType.I_SHARED_WITH && (
                      <TableActionButtonsContainer>
                        <TableActionButton
                          icon={<DescriptionIcon />}
                          tooltip={t('pages.sharingCenter.table.buttons.details')}
                          ButtonProps={{
                            onClick: () => {
                              setActiveProject(project);
                              onDetailsProjectWindowOpen();
                            }
                          }}
                        />
                        <TableActionButton
                          icon={<ShareIcon />}
                          tooltip={t('pages.sharingCenter.table.buttons.share')}
                          ButtonProps={{
                            onClick: () => {
                              setActiveProject(project);
                              onShareProjectWindowOpen();
                            }
                          }}
                        />
                      </TableActionButtonsContainer>
                    )}
                  </div>
                )
              },
              {
                name: 'sourceOrganizationName',
                label: t(
                  `pages.sharingCenter.table.${
                    activeTab === SharedProjectsListingType.SHARED_WITH_ME ? 'sharedBy' : 'sharedWith'
                  }`
                ),
                sortable: false,
                render: (project) => (
                  <div>
                    {activeTab === SharedProjectsListingType.SHARED_WITH_ME && project.sourceOrganizationName}
                    {activeTab === SharedProjectsListingType.I_SHARED_WITH && (
                      <>
                        {project.shares} {t('navigation.organizations')}
                      </>
                    )}
                  </div>
                )
              }
            ]}
            tPath={'pages.sharingCenter.table'}
            data={projects}
            tableSearchProps={{
              tableSearch,
              module: TableSearchStoreModule.SHARING_CENTER
            }}
            // @ts-ignore
            paginationProps={{
              pagination,
              module: PaginationStoreModule.SHARING_CENTER
              // onPageChange: () => {},
              // onPerChange: () => {}
            }}
            isFailed={isFailed}
            isLoading={loading}
          />
        )}
      </PageContent>
      {activeProject && (
        <CloneProjectWindow
          project={activeProject}
          open={cloneProjectWindowOpen}
          onCloseClick={onCloneProjectWindowClose}
          fullScreenOnMobile
        />
      )}
      {activeProject && (
        <ProjectDetailsWindow
          open={detailsProjectWindowOpen}
          project={activeProject}
          onCloseClick={onDetailsProjectWindowClose}
          fullScreenOnMobile={true}
        />
      )}
      <ProjectShareWindow
        context="sharingCenter"
        open={shareProjectWindowOpen}
        project={activeProject}
        onCloseClick={onShareProjectWindowClose}
        fullScreenOnMobile={true}
      />
    </PageContainer>
  );
};

export default SharingCenterPage;
