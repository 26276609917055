import React, { ChangeEvent, FormEvent, FunctionComponent, useState } from 'react';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { useTranslation } from 'react-i18next';
import { PrintCenter, PrintCenterForm } from '../../../../store/SystemSettings/types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { TFunction } from 'i18next';

type CreateOrUpdatePrintCenterProps = {
  type: 'create' | 'update';
  open: boolean;
  onCloseClick: () => void;
  onSubmit: (form: PrintCenterForm) => Promise<void>;
  fullScreenOnMobile: boolean;
  printCenter?: PrintCenter | null;
};

type FormField = keyof PrintCenterForm;

const CreateOrUpdatePrintCenter: FunctionComponent<CreateOrUpdatePrintCenterProps> = ({
  onCloseClick,
  onSubmit,
  type,
  open,
  printCenter
}) => {
  const { t } = useTranslation();

  const getInitialFormData = (): PrintCenterForm => {
    if (printCenter) {
      return {
        name: printCenter.name,
        url: printCenter.url
      };
    }

    return {
      name: '',
      url: ''
    };
  };

  const [formData, setFormData] = useState<PrintCenterForm>(getInitialFormData());
  const [formErrors, setFormErrors] = useState<Record<FormField, boolean>>({
    name: false,
    url: false,
    apiKey: false,
    secret: false
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    const errors: Record<FormField, boolean> = {
      name: false,
      url: false,
      apiKey: false,
      secret: false
    };

    let isValid = true;

    for (const key in formData) {
      if (formData[key as FormField] === '') {
        if (key !== 'apiKey' && key !== 'secret') {
          errors[key as FormField] = true;
          isValid = false;
        }
      } else {
        errors[key as FormField] = false;
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const submit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateForm()) {
      await onSubmit(formData);
    }
  };

  return (
    <ModalConfirmationWindow
      onCloseClick={onCloseClick}
      open={open}
      header={
        type === 'create'
          ? t('pages.systemSettings.printCenters.createPrintCenterWindowTitle')
          : t('pages.systemSettings.printCenters.updatePrintCenterWindowTitle')
      }
      okButtonText={type === 'create' ? t('common.create') : t('common.update')}
      hideActionButtons
    >
      <div>
        <form onSubmit={submit}>
          <FormFieldInput
            t={t}
            name="name"
            label={t('pages.systemSettings.printCenters.form.name')}
            error={formErrors.name}
            onChange={handleInputChange}
            value={formData.name}
          />
          <FormFieldInput
            t={t}
            name="url"
            label={t('pages.systemSettings.printCenters.form.url')}
            error={formErrors.url}
            onChange={handleInputChange}
            value={formData.url}
          />
          <FormFieldInput
            t={t}
            name="apiKey"
            label={t('pages.systemSettings.printCenters.form.apiKey')}
            error={formErrors.apiKey}
            onChange={handleInputChange}
            value={formData.apiKey}
          />
          <FormFieldInput
            t={t}
            name="secret"
            label={t('pages.systemSettings.printCenters.form.secret')}
            error={formErrors.secret}
            onChange={handleInputChange}
            value={formData.secret}
          />

          <div style={{ textAlign: 'right', marginBottom: 16 }}>
            <Button type="submit" variant="contained" color="primary" style={{ marginTop: 10 }}>
              {t('common.submit')}
            </Button>
          </div>
        </form>
      </div>
    </ModalConfirmationWindow>
  );
};

type FormFieldProps = {
  t: TFunction;
  error: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string | undefined;
  helperText?: any;
  name: string;
  label: string;
};

const FormFieldInput: FunctionComponent<FormFieldProps> = (props) => (
  <TextField
    name={props.name}
    label={props.label}
    variant="outlined"
    size="small"
    fullWidth
    margin="normal"
    error={props.error}
    helperText={props.helperText}
    onChange={props.onChange}
    value={props.value}
  />
);

export default CreateOrUpdatePrintCenter;
