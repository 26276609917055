import React, { FunctionComponent, useEffect, useState } from 'react';
import PageHeader from '../../Shared/Layout/PageHeader';
import { PageContainer, PageContentPaper } from '../PageStyledComponents';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Toast from '../../Shared/Toast/Toast';
import { push } from 'connected-react-router';
import linksConstants from '../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Form, Formik, FormikProps } from 'formik';
import { AdminTextInput } from '../Admin/Shared/AdminFormInputs';
import * as Yup from 'yup';
import { RouteComponentProps } from 'react-router';
import Loader from '../../Shared/Loading/Loader';
import { createNetworkErrorObject, formatPublishDate, getCampaignDateRange, useTypedSelector } from '../../../utils';
import { hasPermission, hasPermissionToAnyChannel } from '../../../utils/permissions';
import { campaignsOperations } from '../../../store/Campaigns';
import { Campaign, CampaignProject, CreateCampaignValues } from '../../../store/Campaigns/types';
import app from '../../../config/app/app';
import TableActionButton from '../../Shared/DataTable/TableActionButton/TableActionButton';
import DeleteIcon from '@material-ui/icons/Delete';
import useOpenHandler from '../../../hooks/useOpenHandler';
import UnassignProjectWindow from './Windows/UnassignProjectWindow';
import CampaignProjectNameCell from './Partials/CampaignProjectNameCell';
import { withTheme } from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import { AdminInputLabel } from '../Admin/AdminStyledComponents';

type ManageCampaignPageProps = RouteComponentProps<{ id: string }> & {
  location: { state: { from: string; projectId: string } };
};

const ManageCampaignPage: FunctionComponent<ManageCampaignPageProps> = ({ match, location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const campaignId = match.params.id;
  const fromPath = location.state && location.state.from;
  const fromProjectId = location.state && location.state.projectId;
  const role = useTypedSelector((state) => state.auth.role);
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState<Campaign | null>(null);

  const [activeProject, setActiveProject] = useState<CampaignProject | null>(null);
  const [unassignProjectWindowOpen, onUnassignProjectWindowOpen, onUnassignProjectWindowClose] = useOpenHandler();

  useEffect(() => {
    if (match.params.id) {
      fetchCampaignData();
    } else {
      setLoading(false);
    }
  }, []);

  const fetchCampaignData = async () => {
    try {
      const campaign = await campaignsOperations.show(match.params.id);
      setCampaign(campaign);
    } catch (e) {
      const error = createNetworkErrorObject(e);

      switch (error.statusCode) {
        case 401:
          return Toast.info(t('notifications.common.loginFirst'));
        default:
          return Toast.error(t('notifications.campaigns.cannotFetchCampaign'));
      }
    } finally {
      setLoading(false);
    }
  };

  if (!campaign) {
    return null;
  }

  const updateCampaign = async (values: CreateCampaignValues) => {
    try {
      if (campaignId) {
        await campaignsOperations.update(campaignId, {
          name: values.name,
          description: values.description
        });
        Toast.success(t('notifications.campaigns.campaignSaved'));
      }
    } catch (e) {
      Toast.error(t('notifications.campaigns.errorSave'));
    }
  };

  const backButton = {
    onClick: () => {
      if (fromPath === 'email' && fromProjectId) {
        dispatch(push(linksConstants.PROJECT_DETAILS(fromProjectId)));
      } else if (fromPath === 'print' && fromProjectId) {
        dispatch(push(linksConstants.PROJECT_DETAILS(fromProjectId)));
      } else if (fromPath === 'story' && fromProjectId) {
        dispatch(push(linksConstants.STORY.EDIT(fromProjectId)));
      } else {
        dispatch(push(linksConstants.CAMPAIGNS.INDEX));
      }
    },
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  const renderPublicationDate = (dateFrom: string, dateTo: string) => {
    if (dateFrom === dateTo) {
      return formatPublishDate(dateFrom);
    }
    return `${formatPublishDate(dateFrom)} - ${formatPublishDate(dateTo)}`;
  };

  const renderBasicDataForm = (props: FormikProps<CreateCampaignValues>) => (
    <Form>
      <PageContentPaper>
        <Typography variant="h6" gutterBottom>
          {t('pages.campaigns.campaignInfo')}
        </Typography>
        <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <AdminTextInput
              t={t}
              name="name"
              section="campaigns"
              autoFocus
              disabled={!hasPermission(role, ['campaignsEdit'])}
            />
          </Grid>
          <Grid item xs={6}>
            <AdminTextInput
              t={t}
              name="description"
              section="campaigns"
              disabled={!hasPermission(role, ['campaignsEdit'])}
            />
          </Grid>
          <Grid item xs={12}>
            {campaign.projects.length > 0 && (
              <>
                <AdminInputLabel>{t('pages.campaigns.dateRange')}:</AdminInputLabel>
                <Typography variant="body2" display="block">
                  <CalendarIcon style={{ fontSize: '12px', marginRight: 5 }} />
                  {getCampaignDateRange(campaign)}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="space-between">
          <Button
            style={{ marginTop: '1rem', marginLeft: 'auto' }}
            color="secondary"
            variant="contained"
            onClick={props.submitForm}
            size="medium"
            disabled={props.isSubmitting || !props.isValid || !hasPermission(role, ['campaignsEdit'])}
          >
            {props.isSubmitting ? t('common.saving') : t('common.save')}
          </Button>
        </Box>
      </PageContentPaper>
    </Form>
  );

  return (
    <PageContainer>
      <PageHeader title={t('pages.campaigns.manageCampaignTitle')} leftActionButtons={[backButton]} />
      <Box marginTop={2}>
        {loading && <Loader />}
        {!loading && (
          <>
            <Formik
              initialValues={{
                name: campaign?.name || '',
                description: campaign?.description || ''
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().max(app.maxInputLength).required(),
                description: Yup.string().max(app.maxInputLength)
              })}
              onSubmit={updateCampaign}
            >
              {renderBasicDataForm}
            </Formik>
            <PageContentPaper>
              <Typography variant="h6" gutterBottom>
                {t('pages.campaigns.projects')}
              </Typography>
              <hr style={{ marginBottom: '1.5rem', opacity: 0.1 }} />
              {campaign.projects.length === 0 && t('pages.campaigns.noProjectsAssigned')}
              {campaign.projects.length > 0 && (
                <>
                  {campaign.projects.map((project) => (
                    <React.Fragment key={project.id}>
                      <Box display="flex" alignItems="center">
                        <CampaignProjectNameCell project={project} />
                        <div style={{ textAlign: 'right', marginLeft: 'auto' }}>
                          {renderPublicationDate(project.publicationDateFrom, project.publicationDateTo)}
                          <CalendarIcon style={{ fontSize: '12px', marginLeft: 5 }} />
                        </div>
                        <div style={{ marginLeft: '30px' }}>
                          <TableActionButton
                            icon={<DeleteIcon />}
                            tooltip={t('pages.campaigns.table.buttons.unassign')}
                            ButtonProps={{
                              disabled: !hasPermission(role, ['campaignsEdit']),
                              onClick: () => {
                                setActiveProject(project);
                                onUnassignProjectWindowOpen();
                              }
                            }}
                          />
                        </div>
                      </Box>
                      <Divider style={{ margin: '10px 0' }} />
                    </React.Fragment>
                  ))}
                </>
              )}
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => dispatch(push(linksConstants.CAMPAIGNS.ADD_PROJECTS(campaign.id)))}
                  size="small"
                  disabled={!hasPermission(role, ['campaignsEdit'])}
                >
                  {t('pages.campaigns.addExistingProject')}
                </Button>
                <CreateNewProjectLink
                  to={{
                    pathname: linksConstants.DASHBOARD.NEW_PROJECT,
                    state: { campaignId }
                  }}
                  disabled={!hasPermission(role, ['campaignsEdit']) || !hasPermissionToAnyChannel(role)}
                >
                  <Button
                    style={{ marginLeft: 10 }}
                    color="secondary"
                    variant="contained"
                    size="small"
                    disabled={!hasPermission(role, ['campaignsEdit']) || !hasPermissionToAnyChannel(role)}
                  >
                    {t('pages.campaigns.createNewProject')}
                  </Button>
                </CreateNewProjectLink>
              </Box>
            </PageContentPaper>
          </>
        )}
      </Box>

      <UnassignProjectWindow
        project={activeProject}
        open={unassignProjectWindowOpen}
        onCloseClick={onUnassignProjectWindowClose}
        fullScreenOnMobile
        campaignId={campaignId}
        onSuccessfulSubmit={fetchCampaignData}
      />
    </PageContainer>
  );
};

const CreateNewProjectLink = withTheme(styled(Link)<{ disabled?: boolean }>`
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  word-break: normal;
  ${({ disabled }) => (disabled ? `pointer-events: none;` : '')}
`);

export default ManageCampaignPage;
