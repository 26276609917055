import {
  SubscribersActionsConstants,
  SubscribersIndexActionBEGIN,
  SubscribersIndexActionFAILED,
  SubscribersIndexActionSUCCESS,
  SubscribersIndexResponse
} from './types';

export const subscribersIndexBegin = (): SubscribersIndexActionBEGIN => ({
  type: SubscribersActionsConstants.INDEX_BEGIN
});

export const subscribersIndexSuccess = (payload: SubscribersIndexResponse): SubscribersIndexActionSUCCESS => ({
  type: SubscribersActionsConstants.INDEX_SUCCESS,
  payload
});

export const subscribersIndexFailed = (error: any): SubscribersIndexActionFAILED => ({
  type: SubscribersActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});
