import {
  AnyProject,
  DashboardActionsConstants,
  DashboardProjectsIndexResponse,
  GetProjectByIdActionBEGIN,
  GetProjectByIdActionFAILED,
  GetProjectByIdActionSUCCESS,
  KickedOutFromProjectAction,
  NotifyToCheckOutAction,
  NotifyToCheckOutCloseAction,
  ProjectCheckedOutAction,
  ProjectsIndexActionBEGIN,
  ProjectsIndexActionFAILED,
  ProjectsIndexActionSUCCESS,
  TestFlagToggledAction
} from './types';
import { ShortUser } from '../Auth/types';

export const projectsIndexBegin = (): ProjectsIndexActionBEGIN => ({
  type: DashboardActionsConstants.INDEX_BEGIN
});

export const projectsIndexSuccess = (payload: DashboardProjectsIndexResponse): ProjectsIndexActionSUCCESS => ({
  type: DashboardActionsConstants.INDEX_SUCCESS,
  payload
});

export const projectsIndexFailed = (error: any): ProjectsIndexActionFAILED => ({
  type: DashboardActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const getProjectByIdBegin = (): GetProjectByIdActionBEGIN => ({
  type: DashboardActionsConstants.GET_PROJECT_BY_ID_BEGIN
});

export const getProjectByIdSuccess = (payload: AnyProject): GetProjectByIdActionSUCCESS => ({
  type: DashboardActionsConstants.GET_PROJECT_BY_ID_SUCCESS,
  payload
});

export const getProjectByIdFailed = (error: any): GetProjectByIdActionFAILED => ({
  type: DashboardActionsConstants.GET_PROJECT_BY_ID_FAILED,
  payload: {
    error
  }
});

export const projectCheckedOut = (projectId: string, user: ShortUser): ProjectCheckedOutAction => ({
  type: DashboardActionsConstants.PROJECT_CHECKED_OUT,
  payload: {
    projectId,
    user
  }
});

export const notifyToCheckOut = (project: AnyProject, notifier: ShortUser): NotifyToCheckOutAction => ({
  type: DashboardActionsConstants.NOTIFY_TO_CHECK_OUT,
  payload: {
    project,
    notifier
  }
});

export const notifyToCheckOutClose = (project: AnyProject): NotifyToCheckOutCloseAction => ({
  type: DashboardActionsConstants.NOTIFY_TO_CHECK_OUT_CLOSE,
  payload: {
    project
  }
});

export const kickOutFromProject = (project: AnyProject): KickedOutFromProjectAction => ({
  type: DashboardActionsConstants.KICKED_OUT_FROM_PROJECT,
  payload: {
    project
  }
});

export const testFlagToggled = (project: AnyProject): TestFlagToggledAction => ({
  type: DashboardActionsConstants.TEST_FLAG_TOGGLED,
  payload: project
});
