import update from 'immutability-helper';
import { createPaginationReducer } from '../../Pagination/reducers';
import { PaginationStoreModule } from '../../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../../utils';
import { AuthActionsConstants } from '../../Auth/types';
import { createTableSearchReducer } from '../../TableSearch/reducers';
import { TableSearchStoreModule } from '../../TableSearch/types';
import {
  DoAppCreditTransaction,
  DoAppMerchantCreditTransactionsActionsConstants,
  DoAppMerchantCreditTransactionsActionType,
  DoAppMerchantCreditTransactionsState
} from './types';
import { normalizeDoAppMerchantCreditTransactions } from '../../helpers';

const initialState: DoAppMerchantCreditTransactionsState = {
  data: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: {
    page: 1,
    per: 10,
    q: null,
    total: 0,
    totalPages: 0
  },
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  }
};

const doAppMerchantCreditTransactionsPaginationReducer = createPaginationReducer<
  DoAppMerchantCreditTransactionsState,
  DoAppCreditTransaction
>(PaginationStoreModule.DO_APP_MERCHANT_CREDIT_TRANSACTIONS);

const doAppMerchantCreditTransactionsTableSearchReducer = createTableSearchReducer<
  DoAppCreditTransaction,
  DoAppMerchantCreditTransactionsState
>(TableSearchStoreModule.DO_APP_MERCHANT_CREDIT_TRANSACTIONS);

export default function doAppMerchantCreditTransactionsReducer(
  state = initialState,
  action: WithLogoutAction<DoAppMerchantCreditTransactionsActionType>
) {
  switch (action.type) {
    case DoAppMerchantCreditTransactionsActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case DoAppMerchantCreditTransactionsActionsConstants.INDEX_SUCCESS: {
      const data = normalizeDoAppMerchantCreditTransactions(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        data: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case DoAppMerchantCreditTransactionsActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        data: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return doAppMerchantCreditTransactionsTableSearchReducer(
        doAppMerchantCreditTransactionsPaginationReducer(state, action),
        action
      ) as DoAppMerchantCreditTransactionsState;
    }
  }
}
