import { DesignerStory } from '../../../../../../store/PrintProjects/types';
import { imagesOperations } from '../../../../../../store/Images';
import { ImageData } from '../../types';
import { ImageProcessingResponse } from '../../../../../../store/Images/types';

export const designerArticle = async (
  projectId: string,
  imageData: ImageData,
  story: DesignerStory
): Promise<ImageProcessingResponse | null> => {
  if (story.images.length) {
    // upload image using designer URL
    return await imagesOperations.uploadStoryImage(
      projectId,
      story.images[0].url,
      imageData.containerWidth,
      imageData.containerHeight
    );
  }

  return null;
};
