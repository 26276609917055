import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Strong } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { useDispatch } from 'react-redux';
import { AnyProject } from '../../../../store/Dashboard/types';
import { isEmailProject, isPrintProject, useTypedSelector } from '../../../../utils';
import { emailProjectsOperations } from '../../../../store/EmailProjects';
import { printProjectsOperations } from '../../../../store/PrintProjects';
import { dashboardOperations } from '../../../../store/Dashboard';
import { adminProjectsOperations } from '../../../../store/AdminProjects';
import { EmailProject } from '../../../../store/EmailProjects/types';
import { PrintProject } from '../../../../store/PrintProjects/types';
import styled from 'styled-components';

type EmbedCalendarWindowProps = {
  open: boolean;
  onCloseClick: () => void;
};

const EmbedCalendarWindow: FunctionComponent<EmbedCalendarWindowProps> = ({ onCloseClick, open }) => {
  const { t } = useTranslation();
  const webAppUrl = useTypedSelector((state) => state.auth.webAppUrl);
  const organizationId = useTypedSelector((state) => state.auth.organizationId);
  const viewType = localStorage.getItem('sc-calendar-view-type') === 'week' ? 'week' : 'month';

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.calendar.embedWindow.header')}
      okButtonText={t('common.close')}
      disableCloseButton
      onCloseClick={onCloseClick}
      onSubmit={onCloseClick}
    >
      <Row>
        <div>{t('pages.calendar.embedWindow.content')}</div>
        <div>
          <IframeContentInput
            value={`<iframe width="100%" height="700px" src="${webAppUrl}/org_calendar?oid=${organizationId}&type=${viewType}"></iframe>`}
            onFocus={(e) => e.target.select()}
          />
        </div>
      </Row>
    </ModalConfirmationWindow>
  );
};

const IframeContentInput = styled.input`
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border-color: #f5f5f5;
  border-width: 1px;
  color: #535353;
  margin-top: 10px;
`;

export default EmbedCalendarWindow;
