import { FormControl, InputLabel, Select, MenuItem, Box, Tooltip, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';

type EmailPreviewPageProps = RouteComponentProps<{ previewUrl: string; contentWidth: string }> & {};

type DeviceSettings = {
  [key: string]: {
    width: number;
    height: number;
    userAgent: string;
  };
};

type DeviceKey = keyof DeviceSettings;

const DEVICE_LABELS: Record<DeviceKey, string> = {
  Desktop: 'pages.emailEditor.preview.desktop',
  iPhone15: 'pages.emailEditor.preview.iPhone15',
  iPadPro12: 'pages.emailEditor.preview.iPadPro12',
  androidPhone: 'pages.emailEditor.preview.androidPhone',
  androidTablet: 'pages.emailEditor.preview.androidTablet'
};

const EmailPreviewPage: React.FC<EmailPreviewPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const previewUrl = decodeURIComponent(match.params.previewUrl);
  const contentWidth = parseInt(match.params.contentWidth) + 30; // add 30px for margins

  // Define the phone device emulation settings
  const deviceSettings: DeviceSettings = {
    Desktop: {
      width: 1024,
      height: 768,
      userAgent:
        'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.81 Safari/537.36'
    },
    iPhone15: {
      width: 1179,
      height: 2556,
      userAgent:
        'Mozilla/5.0 (iPhone; CPU iPhone OS 16_0 like Mac OS X) AppleWebKit/606.1.15 (KHTML, like Gecko) Version/16.0 Mobile/16E148 Safari/605.1'
    },
    iPadPro12: {
      width: 1366,
      height: 1024,
      userAgent:
        'Mozilla/5.0 (iPad; CPU OS 16_0 like Mac OS X) AppleWebKit/606.1.15 (KHTML, like Gecko) Version/16.0 Mobile/16E148 Safari/605.1'
    },
    androidPhone: {
      width: 1080,
      height: 2400,
      userAgent:
        'Mozilla/5.0 (Linux; Android 12; Pixel 6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.0.0 Mobile Safari/537.36'
    },
    androidTablet: {
      width: 2560,
      height: 1600,
      userAgent:
        'Mozilla/5.0 (Linux; Android 12; Samsung Galaxy Tab S7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.0.0 Safari/537.36'
    }
  };

  // State to track the selected device
  const [selectedDevice, setSelectedDevice] = useState<string>(t('pages.emailEditor.preview.desktop'));

  // Function to set the device emulation settings
  const setDeviceMode = (device: string) => {
    const iframe = document.getElementById('previewIframe') as HTMLIFrameElement;
    if (iframe && deviceSettings[device]) {
      if (['iPhone15', 'androidPhone', 'androidTablet'].includes(device)) {
        iframe.style.width = `${contentWidth}px`;
        iframe.style.height = `${
          (deviceSettings[device].height / 3 / (deviceSettings[device].width / 3)) * contentWidth
        }px`;
        iframe.style.transform = `scale(${deviceSettings[device].width / 3 / contentWidth})`;
        iframe.style.transformOrigin = 'top';
      } else if (device === 'iPadPro12') {
        iframe.style.width = `${contentWidth}px`;
        iframe.style.height = `${
          (deviceSettings[device].height / 2 / (deviceSettings[device].width / 2)) * contentWidth
        }px`;
        iframe.style.transform = `scale(${deviceSettings[device].width / 2 / contentWidth})`;
        iframe.style.transformOrigin = 'top';
      } else {
        iframe.style.width = `${deviceSettings[device].width}px`;
        iframe.style.height = `${deviceSettings[device].height}px`;
        iframe.style.transform = `scale(1)`;
      }

      iframe.contentWindow?.postMessage(
        {
          type: 'setUserAgent',
          userAgent: deviceSettings[device].userAgent
        },
        '*'
      );
    }
    setSelectedDevice(device);
  };

  useEffect(() => {
    setDeviceMode(selectedDevice);
  }, []);

  return (
    <Wrapper
      style={{
        height: selectedDevice !== 'Desktop' ? '180vh' : '100%'
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <InputLabel id="device-select-label" style={{ marginRight: '10px' }}>
          {t('pages.emailEditor.preview.device')}:
        </InputLabel>
        <FormControl variant="outlined" size="small" style={{ background: 'white' }}>
          <Select
            labelId="device-select-label"
            id="device-select"
            onChange={(e) => setDeviceMode(e.target.value as string)}
            value={selectedDevice}
          >
            {Object.keys(deviceSettings).map((device) => (
              <MenuItem key={device} value={device}>
                {t(DEVICE_LABELS[device])}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Tooltip title={t('pages.emailEditor.preview.info').toString()}>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <StyledIframe id="previewIframe" src={previewUrl} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1440px;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ul,
  ol {
    margin-left: 20px;
  }

  p {
    margin-bottom: 20px;
  }
`;

const StyledIframe = styled.iframe`
  margin: 10px auto 0 auto;
`;

export default EmailPreviewPage;
