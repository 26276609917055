import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from '@material-ui/core';
import styled from 'styled-components';
import { Strong, TestProjectIndicator } from '../../../Shared/StyledComponents';
import { Link } from 'react-router-dom';
import { User } from '../../../../store/Auth/types';
import ProjectAvatar from '../../../Shared/Avatar/ProjectAvatar';
import linksConstants from '../../../../config/app/linksConstants';
import { AnyProject } from '../../../../store/Dashboard/types';
import { AdminRole } from '../../../../store/AdminRoles/types';
import { hasAccessToAction } from './hasAccessToAction';

type ProjectNameCellProps = {
  project: AnyProject;
  user: User;
  role: AdminRole;
  customId?: string;
};

const ProjectNameCell: FunctionComponent<ProjectNameCellProps> = ({ project, user, role, customId }) => {
  const { t } = useTranslation();

  const projectLocked = !!project.checkedInBy && user.id !== project.checkedInBy.id;

  const hasAction = hasAccessToAction(project, role);

  return (
    <ProjectArea>
      <ProjectAvatar type={project.type} locked={projectLocked} shared={project.isShared} />
      <div>
        <ProjectTitle disabled={!hasAction('access')} to={linksConstants.PROJECT_DETAILS(customId || project.id)}>
          {project.name}
        </ProjectTitle>
        {project.checkedInBy && (
          <SubTitle>
            {t('common.projectCheckedOutBy')}: <Strong>{project.checkedInBy.fullName}</Strong> (
            {project.checkedInBy.email})
          </SubTitle>
        )}
        {project.test && <TestProjectIndicator>{t('common.testProject')}</TestProjectIndicator>}
        {!projectLocked && <SubTitle>{project.templateDescription}</SubTitle>}
      </div>
    </ProjectArea>
  );
};

const ProjectArea = styled.div`
  display: flex;
`;

export const ProjectTitle = withTheme(styled(Link)<{ disabled?: boolean }>`
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  word-break: normal;
  ${({ disabled }) => (disabled ? `pointer-events: none;` : '')}
`);

const SubTitle = withTheme(styled.div`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 0.8rem;
  padding-right: 2rem;
  word-break: normal;
`);

export default ProjectNameCell;
