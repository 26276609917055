import { imagesOperations } from '../../../../../../store/Images';
import { ImageData } from '../../types';

export const userImage = async (projectId: string, imageData: ImageData, fileId: string) => {
  // upload image using user images palette
  return await imagesOperations.uploadUserImage(
    projectId,
    fileId,
    imageData.containerWidth || 80,
    imageData.containerHeight || 80
  );
};
