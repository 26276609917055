import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { AdminRole, DefaultRoleName } from '../../../../store/AdminRoles/types';
import { hasPermission } from '../../../../utils/permissions';
import { User } from '../../../../store/Auth/types';
import { Campaign, CampaignProjectType } from '../../../../store/Campaigns/types';
import linksConstants from '../../../../config/app/linksConstants';
import { withTheme } from '@material-ui/core';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import ProjectCampaignUnassignWindow from './ProjectCampaignUnassignWindow';
import TableActionButton from '../../../Shared/DataTable/TableActionButton/TableActionButton';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';

type ProjectCampaignProps = {
  campaign: Campaign;
  projectId: string;
  type: CampaignProjectType;
  user: User;
  role: AdminRole;
  onUnassignCampaign: (campaign: Campaign) => void;
};

const ProjectCampaignItem: FunctionComponent<ProjectCampaignProps> = ({
  campaign,
  projectId,
  type,
  onUnassignCampaign,
  role,
  user
}) => {
  const { t } = useTranslation();
  const [unassignProjectWindowOpen, onUnassignProjectWindowOpen, onUnassignProjectWindowClose] = useOpenHandler();

  const hasPermissionsToEdit =
    user.type.name === DefaultRoleName.SYSTEM_OWNER ||
    (campaign.createdBy.id === user.id && hasPermission(role, ['campaignsEdit']));

  return (
    <ProjectCampaignWrapper>
      <CampaignLink
        to={{
          pathname: linksConstants.CAMPAIGNS.EDIT(campaign.id),
          state: {
            from: type,
            projectId
          }
        }}
      >
        {campaign.name}
      </CampaignLink>
      <ActionsWrapper>
        <TableActionButton
          icon={<DeleteIcon />}
          tooltip={t('pages.campaigns.table.buttons.unassign')}
          ButtonProps={{
            disabled: !hasPermissionsToEdit,
            onClick: onUnassignProjectWindowOpen
          }}
        />
      </ActionsWrapper>
      <ProjectCampaignUnassignWindow
        campaign={campaign}
        open={unassignProjectWindowOpen}
        onCloseClick={onUnassignProjectWindowClose}
        fullScreenOnMobile
        projectId={projectId}
        type={type}
        onSuccessfulSubmit={() => onUnassignCampaign(campaign)}
      />
    </ProjectCampaignWrapper>
  );
};

const ProjectCampaignWrapper = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid #e3e2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ActionsWrapper = styled.div`
  text-align: right;
`;
const CampaignLink = withTheme(styled(Link)`
  text-decoration: none !important;
  color: ${({ theme }) => theme.palette.secondary.main};
  cursor: pointer;
  font-weight: bold;
`);

export default ProjectCampaignItem;
