import React, { FunctionComponent } from 'react';
import { Form, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import linksConstants from '../../../../config/app/linksConstants';
import { Link } from 'react-router-dom';
import { Collapse, IconButton, InputAdornment } from '@material-ui/core';
import { LoginFormValues } from '../../../../store/Auth/types';
import InfoBox from '../../../Shared/InfoBox/InfoBox';
import translateNetworkError from '../../../../utils/translateNetworkError';
import {
  AdditionalLinkContainer,
  AuthActionButtons,
  AuthInput,
  AuthMainButton,
  InputRow
} from '../AuthStyledComponents';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

type LoginFormProps = {
  formikProps: FormikProps<LoginFormValues>;
  error: NetworkError | null;
};

const LoginForm: FunctionComponent<LoginFormProps> = ({ formikProps, error }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Form>
      <InputRow>
        <AuthInput
          formikFieldProps={{ name: 'email' }}
          textFieldProps={{
            label: t('pages.auth.login.form.email'),
            placeholder: 'your@email.com',
            color: 'secondary',
            type: 'email',
            variant: 'outlined',
            fullWidth: true
          }}
        />
      </InputRow>
      <InputRow>
        <AuthInput
          formikFieldProps={{
            name: 'password'
          }}
          textFieldProps={{
            label: t('pages.auth.login.form.password'),
            placeholder: '********',
            variant: 'outlined',
            color: 'secondary',
            type: showPassword ? 'text' : 'password',
            fullWidth: true,
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }
          }}
        />
      </InputRow>

      <Collapse
        in={!!error && !formikProps.isSubmitting}
        style={{ maxWidth: '30rem', marginTop: 12, marginBottom: 12 }}
      >
        <InfoBox type={'error'}>{translateNetworkError(error, t)}</InfoBox>
      </Collapse>

      <AuthActionButtons>
        <AuthMainButton
          color={'secondary'}
          variant={'contained'}
          style={{
            width: 150
          }}
          type={'submit'}
          disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
        >
          {t('pages.auth.login.login')}
        </AuthMainButton>
      </AuthActionButtons>

      <AdditionalLinkContainer>
        <Link to={linksConstants.AUTH.FORGOT_PASSWORD}>{t('pages.auth.login.form.forgotPassword')}</Link>
      </AdditionalLinkContainer>
      <AdditionalLinkContainer>
        <Link to={linksConstants.AUTH.RESEND_VERIFICATION}>{t('pages.auth.doYouNeedVerificationEmailAgain')}</Link>
      </AdditionalLinkContainer>
    </Form>
  );
};

export default LoginForm;
