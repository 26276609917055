import React, { FunctionComponent } from 'react';
import { FormTextInputProps } from './types';
import { Field, FieldProps } from 'formik';
import getFormInputError from './getFormInputError';
import { useTranslation } from 'react-i18next';
import { InputAdornment, InputLabel, TextField } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

const FormTextInput: FunctionComponent<FormTextInputProps> = (props) => {
  const { formikFieldProps, textFieldProps, customErrorMessagePath, label, className } = props;
  const { t, i18n } = useTranslation();

  const getPlaceholder = () => {
    if (!textFieldProps) return;

    return textFieldProps.required && textFieldProps.value
      ? `${textFieldProps.placeholder} *`
      : textFieldProps.placeholder;
  };

  return (
    <Field name={formikFieldProps.name}>
      {(fieldProps: FieldProps) => {
        const { field } = fieldProps;
        const errorMessage = getFormInputError(fieldProps, t, customErrorMessagePath);

        return (
          <>
            {label}
            <TextField
              {...field}
              classes={{
                root: className
              }}
              InputProps={{
                endAdornment: errorMessage[0] ? (
                  <InputAdornment position={'end'}>
                    <ErrorOutline style={{ color: '#FF3300' }} />
                  </InputAdornment>
                ) : null
              }}
              {...textFieldProps}
              placeholder={getPlaceholder()}
              label={textFieldProps?.label}
              error={errorMessage[0] || (textFieldProps && textFieldProps.required && !field.value)}
              helperText={
                errorMessage[1] ||
                (textFieldProps &&
                  textFieldProps.required &&
                  !field.value &&
                  t('formValidationErrors.genericRequired')) ||
                ''
              }
            />
          </>
        );
      }}
    </Field>
  );
};

export default FormTextInput;
