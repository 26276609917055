import React, { FunctionComponent } from 'react';
import { ColorSwatchType, MyOrganizationSwatchColor } from '../../../../../../store/MyOrganization/types';
import { DownloadAttachment, DownloadAttachmentType } from '../../types';
import {
  extractColorsFromSwatches,
  getDefaultButtonBackgroundColor,
  getDefaultButtonBorderColor,
  getDefaultButtonBorderRadius,
  getDefaultButtonBorderWidth
} from '../../Utils';
import WysiwygEditor from './Utils/WysiwygEditor';
import { useTranslation } from 'react-i18next';
import DownloadAttachmentWindow from '../Windows/DownloadAttachmentWindow';
import useOpenHandler from '../../../../../../hooks/useOpenHandler';
import { ComponentActionIcons, StyledEditIcon } from '../../../../../Shared/StyledComponents';
import { ReactComponent as SettingsIcon } from '../../../../../../assets/icons/settings.svg';

type DownloadAttachmentProps = {
  projectId: string;
  data: DownloadAttachment;
  onChange: (data: DownloadAttachment) => void;
  selectedColorSwatches: { [key: string]: MyOrganizationSwatchColor };
  inProjectEditor: boolean;
};

const DownloadAttachmentComponent: FunctionComponent<DownloadAttachmentProps> = ({
  projectId,
  data,
  onChange,
  selectedColorSwatches,
  inProjectEditor
}) => {
  const { t } = useTranslation();
  const [
    downloadAttachmentWindowOpen,
    onDownloadAttachmentWindowOpen,
    onDownloadAttachmentWindowClose
  ] = useOpenHandler();

  return (
    <div>
      <p
        style={{
          ...data.wrapperStyle,
          ...data.style,
          ...extractColorsFromSwatches(
            selectedColorSwatches,
            [ColorSwatchType.button, ColorSwatchType.link],
            data.colorSwatchIgnore,
            '',
            data.params.style
          )
        }}
        className="download-attachment"
      >
        <WysiwygEditor
          inline
          content={data.params.text}
          onBlur={(newContent) =>
            onChange({
              ...data,
              params: {
                ...data.params,
                text: newContent
              }
            })
          }
        />
      </p>
      <ComponentActionIcons style={{ left: 'calc(100% - 45px)' }}>
        <StyledEditIcon onClick={() => onDownloadAttachmentWindowOpen()}>
          <SettingsIcon style={{ width: 22, fill: '#fff' }} />
        </StyledEditIcon>
      </ComponentActionIcons>

      {downloadAttachmentWindowOpen && (
        <DownloadAttachmentWindow
          open={downloadAttachmentWindowOpen}
          onCloseClick={onDownloadAttachmentWindowClose}
          onFormSubmit={(values) => {
            onChange({
              ...data,
              params: {
                ...data.params,
                type: values.downloadAll ? DownloadAttachmentType.ALL : DownloadAttachmentType.SINGLE,
                attachmentId: values.downloadAll ? null : values.attachmentId,
                style: {
                  backgroundColor: values.backgroundColor,
                  borderRadius: values.borderRadius,
                  borderStyle: values.borderStyle,
                  borderWidth: values.borderWidth,
                  borderColor: values.borderColor
                }
              }
            });
            onDownloadAttachmentWindowClose();
          }}
          projectId={projectId}
          downloadAllSwitch={data.params.type === DownloadAttachmentType.ALL}
          selectedAttachmentId={data.params.attachmentId}
          backgroundColor={getDefaultButtonBackgroundColor(data)}
          borderRadius={getDefaultButtonBorderRadius(data)}
          borderStyle={(data.params.style && data.params.style.borderStyle) || 'inherit'}
          borderWidth={getDefaultButtonBorderWidth(data)}
          borderColor={getDefaultButtonBorderColor(data)}
        />
      )}
    </div>
  );
};

export default DownloadAttachmentComponent;
