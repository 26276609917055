import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { PageContainer, PageContent } from '../../PageStyledComponents';
import { formatTableTimestamp, useTypedSelector } from '../../../../utils';
import { usePaginationWatch } from '../../../Shared/DataTable/Pagination/usePaginationWatch';
import { useTableSearchWatch } from '../../../Shared/DataTable/useTableSearchWatch';
import { PaginationStoreModule } from '../../../../store/Pagination/types';
import DataTable from '../../../Shared/DataTable/DataTable';
import TableActionButtonsContainer from '../../../Shared/DataTable/TableActionButton/TableActionButtonsContainer';
import TableActionButton from '../../../Shared/DataTable/TableActionButton/TableActionButton';
import { TableSearchStoreModule } from '../../../../store/TableSearch/types';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import AdminCreateKeywordWindow from './Windows/AdminCreateKeywordWindow';
import AdminUpdateKeywordWindow from './Windows/AdminUpdateKeywordWindow';
import AdminDeleteKeywordWindow from './Windows/AdminDeleteKeywordWindow';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { adminKeywordsOperations } from '../../../../store/AdminKeywords';
import { AdminKeyword } from '../../../../store/AdminKeywords/types';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import app from '../../../../config/app/app';
import { hasPermission } from '../../../../utils/permissions';
import { push } from 'connected-react-router';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

type AdminKeywordsPageProps = {};

const AdminKeywordsPage: FunctionComponent<AdminKeywordsPageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);
  const keywords = useTypedSelector((state) => state.adminKeywords.keywords);
  const isFailed = useTypedSelector((state) => state.adminKeywords.index.isFailed);
  const isLoading = useTypedSelector((state) => state.adminKeywords.index.isLoading);
  const pagination = useTypedSelector((state) => state.adminKeywords.pagination);
  const tableSearch = useTypedSelector((state) => state.adminKeywords.tableSearch);

  usePaginationWatch(pagination, [adminKeywordsOperations.index]);
  useTableSearchWatch<AdminKeyword>(tableSearch, [adminKeywordsOperations.index]);

  const [activeKeyword, setActiveKeyword] = useState<AdminKeyword | null>(null);
  const [createKeywordWindowOpen, onCreateKeywordWindowOpen, onCreateKeywordWindowClose] = useOpenHandler();
  const [updateKeywordWindowOpen, onUpdateKeywordWindowOpen, onUpdateKeywordWindowClose] = useOpenHandler();
  const [deleteKeywordWindowOpen, onDeleteKeywordWindowOpen, onDeleteKeywordWindowClose] = useOpenHandler();

  const getActionButtons = () => {
    if (hasPermission(role, ['keywordsAdd'])) {
      return [
        {
          label: t('pages.adminKeywords.keywordGroups'),
          onClick: () => dispatch(push(linksConstants.ADMINISTRATION.KEYWORD_GROUPS.INDEX))
        },
        {
          label: t('pages.adminKeywords.createKeyword'),
          icon: <AddIcon />,
          onClick: onCreateKeywordWindowOpen
        }
      ];
    }

    return [];
  };

  return (
    <PageContainer>
      <PageHeader title={t('pages.adminKeywords.title')} rightActionButtons={getActionButtons()} />
      <PageContent>
        <DataTable<AdminKeyword, 'actions'>
          enableSearch={true}
          columnDefinitions={[
            {
              name: 'name',
              sortable: true,
              render: (keyword) => <div>{keyword.name}</div>
            },
            {
              name: 'actions',
              render: (keyword) => (
                <TableActionButtonsContainer>
                  <TableActionButton
                    icon={<EditIcon />}
                    tooltip={t('pages.adminKeywords.table.buttons.edit')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['keywordsAdd']),
                      onClick: () => {
                        setActiveKeyword(keyword);
                        onUpdateKeywordWindowOpen();
                      }
                    }}
                  />
                  <TableActionButton
                    icon={<DeleteIcon />}
                    tooltip={t('pages.adminKeywords.table.buttons.delete')}
                    ButtonProps={{
                      disabled: !hasPermission(role, ['keywordsRemove']),
                      onClick: () => {
                        setActiveKeyword(keyword);
                        onDeleteKeywordWindowOpen();
                      }
                    }}
                  />
                </TableActionButtonsContainer>
              )
            },
            {
              name: 'createdAt',
              render: (keyword) => <>{formatTableTimestamp(keyword.createdAt)}</>,
              sortable: true
            }
          ]}
          tPath={'pages.adminKeywords.table'}
          data={keywords}
          tableSearchProps={{
            tableSearch,
            module: TableSearchStoreModule.ADMIN_KEYWORDS
          }}
          paginationProps={{
            pagination,
            module: PaginationStoreModule.ADMIN_KEYWORDS
            // onPageChange: () => {},
            // onPerChange: () => {}
          }}
          isFailed={isFailed}
          isLoading={isLoading}
        />
      </PageContent>

      <AdminCreateKeywordWindow
        open={createKeywordWindowOpen}
        onCloseClick={onCreateKeywordWindowClose}
        fullScreenOnMobile
      />
      <AdminUpdateKeywordWindow
        keyword={activeKeyword}
        open={updateKeywordWindowOpen}
        onCloseClick={onUpdateKeywordWindowClose}
        fullScreenOnMobile
      />
      <AdminDeleteKeywordWindow
        keyword={activeKeyword}
        open={deleteKeywordWindowOpen}
        onCloseClick={onDeleteKeywordWindowClose}
        fullScreenOnMobile
      />
    </PageContainer>
  );
};

export default AdminKeywordsPage;
