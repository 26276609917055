import React, { ChangeEvent } from 'react';
import { AdminAutocomplete, AdminCheckbox, AdminInput, AdminInputLabel, AdminSelect } from '../AdminStyledComponents';
import { TFunction } from 'i18next';
import FormWysiwygEditor from '../../../Shared/Forms/FormWysiwygEditor';

type InputProps = {
  t: TFunction;
  section: string;
  name: string;
  multiline?: boolean;
  disabled?: boolean;
  required?: boolean;
  type?: 'text' | 'number' | 'date' | 'time';
  identifier?: string;
  children?: JSX.Element | JSX.Element[];
  onChange?: (value: any) => void;
  inputProps?: any;
  autoFocus?: boolean;
  label?: string;
};

type AutocompleteInputProps = {
  t: TFunction;
  options: { label: string; id: string }[];
  section: string;
  name: string;
  disabled?: boolean;
  identifier?: string;
  onChange?: (value: any) => void;
  onSearchTextChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onOpen?: () => void;
  onClose?: () => void;
  loading?: boolean;
};

type WysiwygInputProps = {
  t: TFunction;
  section: string;
  toolbar: string[];
  name: string;
  disabled?: boolean;
  required?: boolean;
  height?: number;
};

export const AdminTextInput = ({
  multiline,
  t,
  name,
  disabled,
  section,
  type,
  identifier,
  required,
  inputProps,
  autoFocus
}: InputProps) => {
  if (!type) {
    type = 'text';
  }

  return (
    <AdminInput
      label={<AdminInputLabel>{t(`pages.${section}.inputs.${name}`)}</AdminInputLabel>}
      formikFieldProps={{ name }}
      textFieldProps={{
        autoFocus,
        multiline,
        rows: multiline ? 5 : 1,
        disabled,
        type,
        required,
        fullWidth: true,
        placeholder: t(`pages.${section}.inputs.${name}Placeholder`),
        id: identifier,
        inputProps
      }}
    />
  );
};

export const AdminSelectInput = ({ t, name, disabled, section, children, onChange }: InputProps) => (
  <AdminSelect
    label={<AdminInputLabel>{t(`pages.${section}.inputs.${name}`)}</AdminInputLabel>}
    formikFieldProps={{ name }}
    textFieldProps={{
      onChange,
      disabled,
      fullWidth: true,
      placeholder: t(`pages.${section}.inputs.${name}Placeholder`)
    }}
  >
    {children}
  </AdminSelect>
);

export const AdminAutocompleteInput = ({
  t,
  name,
  disabled,
  section,
  onChange,
  onSearchTextChange,
  options,
  onOpen,
  onClose,
  loading
}: AutocompleteInputProps) => (
  <AdminAutocomplete
    label={<AdminInputLabel>{t(`pages.${section}.inputs.${name}`)}</AdminInputLabel>}
    formikFieldProps={{ name }}
    onSearchTextChange={onSearchTextChange}
    autocompleteProps={{
      onChange,
      disabled,
      onOpen,
      onClose,
      options,
      loading,
      getOptionLabel: (option) => option.label,
      fullWidth: true,
      placeholder: t(`pages.${section}.inputs.${name}Placeholder`)
    }}
  />
);

export const AdminCheckboxInput = ({ t, name, disabled, section, children, onChange, label }: InputProps) => (
  <AdminCheckbox
    formikFieldProps={{ name }}
    checkboxFieldProps={{
      label: label || t(`pages.${section}.inputs.${name}`),
      onChange,
      disabled,
      placeholder: t(`pages.${section}.inputs.${name}Placeholder`)
    }}
  >
    {children}
  </AdminCheckbox>
);

export const WysiwygInput = ({ t, name, required, toolbar, disabled, section, height }: WysiwygInputProps) => (
  <FormWysiwygEditor
    formikFieldProps={{ name }}
    wysiwygProps={{
      disabled,
      label: t(`pages.${section}.inputs.${name}`),
      required,
      toolbar,
      height
    }}
  />
);
