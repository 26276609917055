import React from 'react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from '../../../types';
import { Attachment } from '../../../../../../../store/EmailProjects/types';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import app from '../../../../../../../config/app/app';

interface BrandfolderAssetProps {
  attachment: Attachment;
  handleAttachmentClick: (attachment: Attachment) => void;
}

export const BrandfolderAsset: React.FC<BrandfolderAssetProps> = ({ attachment, handleAttachmentClick }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.BRANDFOLDER, id: attachment.id || '' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + app.filesizeUnits[i];
  };

  const opacity = isDragging ? 0.4 : 1;
  const isDraggable = attachment.attributes.extension !== 'doc' && attachment.attributes.extension !== 'bftemplate';

  return (
    <ListItem
      alignItems="flex-start"
      ref={isDraggable ? drag : null}
      style={{
        opacity,
        cursor: isDraggable ? 'move' : 'pointer'
      }}
      onClick={() => handleAttachmentClick(attachment)}
    >
      <ListItemAvatar>
        <Avatar alt={attachment.attributes.filename} src={attachment.attributes.thumbnailUrl} variant="rounded" />
      </ListItemAvatar>
      <ListItemText
        primary={attachment.attributes.filename}
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }}
        secondary={
          <React.Fragment>
            <Typography component="span" variant="body2" color="textPrimary">
              {attachment.attributes.extension.toUpperCase()}
            </Typography>
            {' — '}
            {formatBytes(attachment.attributes.size)} {attachment.attributes.width}x{attachment.attributes.height}
          </React.Fragment>
        }
      />
    </ListItem>
  );
};
