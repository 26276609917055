import React, { FunctionComponent, useEffect, useState } from 'react';
import { UpcomingEvents } from '../../types';
import { useTranslation } from 'react-i18next';
import useOpenHandler from '../../../../../../hooks/useOpenHandler';
import { ComponentActionIcons, StyledEditIcon } from '../../../../../Shared/StyledComponents';
import { ReactComponent as DateRangeIcon } from '../../../../../../assets/icons/date_range.svg';
import { StoryChannel } from '../../../../../../store/Stories/types';
import { myOrganizationOperations } from '../../../../../../store/MyOrganization';
import { Range } from 'react-date-range';
import { format, parseISO } from 'date-fns';
import DateRangePickerWindow from '../../../../Stories/Windows/DateRangePickerWindow';
import Loader from '../../../../../Shared/Loading/Loader';
import UpcomingEventElement from './Utils/UpcomingEventElement';
import Toast from '../../../../../Shared/Toast/Toast';
import WysiwygEditor from './Utils/WysiwygEditor';
import { addNegativeTimezoneOffset } from '../../../../../../utils';

type UpcomingEventsProps = {
  data: UpcomingEvents;
  onChange: (data: UpcomingEvents) => void;
  inProjectEditor: boolean;
};

const UpcomingEventsComponent: FunctionComponent<UpcomingEventsProps> = ({ data, onChange, inProjectEditor }) => {
  const { t } = useTranslation();
  const [dateRangePickerWindowOpen, onDateRangePickerWindowOpen, onDateRangePickerWindowClose] = useOpenHandler();

  const [loading, setLoading] = useState<boolean>(true);
  const [calendarEvents, setCalendarEvents] = useState<StoryChannel[]>([]);
  const [calendarEventPeriod, setCalendarEventPeriod] = useState<Range[]>([
    {
      startDate: addNegativeTimezoneOffset(data.params.startDate),
      endDate: addNegativeTimezoneOffset(data.params.endDate),
      key: 'selection'
    }
  ]);

  const fetchCalendarEvents = async (startDate: string, endDate: string) => {
    setLoading(true);
    try {
      const events = await myOrganizationOperations.getCalendarEvents(startDate, endDate);
      setCalendarEvents(events.data);
    } catch (error) {
      Toast.error(t('notifications.story.errorFetchingCalendarEvents'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCalendarEvents(
      format(parseISO(data.params.startDate), 'yyyy-MM-dd'),
      format(parseISO(data.params.endDate), 'yyyy-MM-dd')
    );
  }, []);

  return (
    <div style={data.wrapperStyle}>
      <div style={{ fontSize: '24px', padding: '15px' }}>
        <WysiwygEditor
          inline
          content={data.params.header}
          onBlur={(newContent) =>
            onChange({
              ...data,
              params: {
                ...data.params,
                header: newContent
              }
            })
          }
        />
      </div>
      {loading && <Loader />}
      {!loading && calendarEvents.length === 0 && (
        <div style={{ padding: '15px' }}>{t('pages.emailEditor.calendarEvents.noUpcomingEvents')}</div>
      )}
      {!loading && calendarEvents.map((event) => <UpcomingEventElement key={event.id} event={event} />)}
      {inProjectEditor && (
        <ComponentActionIcons style={{ left: 'calc(100% - 45px)' }}>
          <StyledEditIcon onClick={() => onDateRangePickerWindowOpen()}>
            <DateRangeIcon style={{ width: 22, fill: '#fff' }} />
          </StyledEditIcon>
        </ComponentActionIcons>
      )}

      {dateRangePickerWindowOpen && (
        <DateRangePickerWindow
          range={calendarEventPeriod}
          open={dateRangePickerWindowOpen}
          fullScreenOnMobile={true}
          onCloseClick={onDateRangePickerWindowClose}
          onSaveClick={(period: Range[]) => {
            setCalendarEventPeriod(period);
            if (period[0].startDate && period[0].endDate) {
              const startDateString = format(period[0].startDate, 'yyyy-MM-dd');
              const endDateString = format(period[0].endDate, 'yyyy-MM-dd');

              onChange({
                ...data,
                params: {
                  ...data.params,
                  startDate: startDateString,
                  endDate: endDateString
                }
              });
              fetchCalendarEvents(startDateString, endDateString);
            }
          }}
          label={t('pages.emailEditor.calendarEvents.upcomingEventsDateRange')}
        />
      )}
    </div>
  );
};

export default UpcomingEventsComponent;
