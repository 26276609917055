import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import DoAppMerchantDashboardPublicPage from './DoAppMerchantDashboardPublicPage';

const DoAppMerchantDashboardPublicRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.DOAPP_MERCHANT_DASHBOARD,
    exact: true,
    name: 'doAppMerchantDashboardPublic',
    public: true,
    show: true,
    component: DoAppMerchantDashboardPublicPage,
    scope: []
  }
];

export default DoAppMerchantDashboardPublicRoutes;
