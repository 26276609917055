import React, { FunctionComponent } from 'react';
import { ScaleBand } from 'd3';
import { Text } from '@visx/text';

type AxisBottomProps = {
  xScale: ScaleBand<string>;
  innerHeight: number;
  tickOffset?: number;
  textWrap?: boolean;
};

export const AxisBottom: FunctionComponent<AxisBottomProps> = ({
  xScale,
  innerHeight,
  tickOffset = 3,
  textWrap = false
}) => {
  return (
    <>
      {xScale.domain().map((tickValue) => (
        <g className="tick" key={tickValue} transform={`translate(${xScale(tickValue)},0)`}>
          <line y2={innerHeight} />
          {textWrap && (
            <Text
              textAnchor="end"
              verticalAnchor="start"
              dy=".71em"
              y={innerHeight + tickOffset}
              width={100}
              angle={-45}
            >
              {tickValue}
            </Text>
          )}
          {!textWrap && (
            <text style={{ textAnchor: 'middle' }} dy=".71em" y={innerHeight + tickOffset}>
              {tickValue}
            </text>
          )}
        </g>
      ))}
    </>
  );
};
