import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import posed, { PoseGroup } from 'react-pose';
import Loader from '../../../Shared/Loading/Loader';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { SetPasswordFormValues } from '../../../../store/Auth/types';
import { authOperations } from '../../../../store/Auth';
import linksConstants from '../../../../config/app/linksConstants';
import { createNetworkErrorObject } from '../../../../utils';
import AuthTicketValidationError from './AuthTicketValidationError';
import getAuthAuthTicketValidationData from '../Shared/getAuthTicketValidationData';
import { Link } from 'react-router-dom';
import { AdditionalLinkContainer, AuthPageForm, AuthPageTitle } from '../AuthStyledComponents';
import { PasswordPolicy } from '../../../../store/SystemSettings/types';
import { myOrganizationOperations } from '../../../../store/MyOrganization';
import SetPasswordForm from './SetPasswordForm';

type ValidateAndSetPasswordPageProps = {};

const AnimatedDiv = posed.div({
  flip: {
    transition: 'none'
  },
  enter: {
    opacity: 1,
    flip: false
  },
  exit: {
    opacity: 0,
    flip: false
  }
});

const ValidateAndSetPasswordPage: FunctionComponent<ValidateAndSetPasswordPageProps> = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const params = useParams<{ ticketId?: string }>();
  const authTicketValidationData = getAuthAuthTicketValidationData(location, params);

  const dispatch = useDispatch();
  const [ticketValidationStatus, setTicketValidationStatus] = useState<
    'VALIDATING' | 'VALIDATION_SUCCESS' | 'VALIDATION_FAILED'
  >('VALIDATING');
  const [ticketValidationError, setTicketValidationError] = useState<NetworkError | null>(null);
  const [formSubmitError, setFormSubmitError] = useState<NetworkError | null>(null);
  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicy | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPasswordPolicy(await authOperations.validateAuthTicket(authTicketValidationData));

        setTicketValidationStatus('VALIDATION_SUCCESS');
      } catch (e) {
        const networkError = createNetworkErrorObject(e);
        setTicketValidationError(networkError);
        setTicketValidationStatus('VALIDATION_FAILED');
      }
    };
    fetchData();
  }, []);

  const handleFormSubmit = async (values: SetPasswordFormValues) => {
    setFormSubmitError(null);
    try {
      if (authTicketValidationData.ticketType === 'AV') {
        await dispatch(authOperations.verifyUser(authTicketValidationData, values));
        await dispatch(authOperations.validateToken({ cookie: true }));
        dispatch(push(linksConstants.AUTH.LOGIN));
      } else {
        await dispatch(authOperations.setPassword(authTicketValidationData, values));
        dispatch(push(linksConstants.AUTH.LOGIN));
      }
    } catch (e) {
      const networkError = createNetworkErrorObject(e);
      setFormSubmitError(networkError);
    }
  };

  const renderBasedOnValidationStatus = () => {
    switch (ticketValidationStatus) {
      case 'VALIDATING': {
        return (
          <AnimatedDiv key={'loader'}>
            <Loader />
          </AnimatedDiv>
        );
      }
      case 'VALIDATION_SUCCESS': {
        return (
          <AnimatedDiv key={'setPasswordForm'}>
            {passwordPolicy && (
              <SetPasswordForm
                handleSubmit={handleFormSubmit}
                authTicketValidationData={authTicketValidationData}
                formSubmitError={formSubmitError}
                consentRequired={false}
                passwordPolicy={passwordPolicy}
              />
            )}
          </AnimatedDiv>
        );
      }
      case 'VALIDATION_FAILED': {
        return (
          <AnimatedDiv key={'ticketValidationError'}>
            <AuthTicketValidationError
              authTicketValidationData={authTicketValidationData}
              error={ticketValidationError}
            />
          </AnimatedDiv>
        );
      }
    }
  };

  return (
    <>
      <AuthPageTitle>{t('pages.auth.validateAndSetPassword.title')}</AuthPageTitle>
      <AuthPageForm>
        <PoseGroup>{renderBasedOnValidationStatus()}</PoseGroup>
        <AdditionalLinkContainer>
          <Link to={linksConstants.AUTH.LOGIN}>{t('pages.auth.backToLogin')}</Link>
        </AdditionalLinkContainer>
      </AuthPageForm>
    </>
  );
};

export default ValidateAndSetPasswordPage;
