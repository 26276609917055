import React from 'react';
import { WordpressCategory } from '../../../../../store/MyOrganization/types';
import { ListItem, Checkbox, ListItemText, List } from '@material-ui/core';

interface WordpressCategoryTreeProps {
  categories: WordpressCategory[];
  selectedCategories: WordpressCategory[];
  onCategorySelect: (selectedCategory: WordpressCategory[]) => void;
}

const WordpressCategoryTree: React.FC<WordpressCategoryTreeProps> = ({
  categories,
  selectedCategories,
  onCategorySelect
}) => {
  const handleCheckboxToggle = (category: WordpressCategory) => {
    const selectedIndex = selectedCategories.indexOf(category);
    let newSelected: WordpressCategory[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCategories, category);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCategories.slice(1));
    } else if (selectedIndex === selectedCategories.length - 1) {
      newSelected = newSelected.concat(selectedCategories.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCategories.slice(0, selectedIndex),
        selectedCategories.slice(selectedIndex + 1)
      );
    }

    onCategorySelect(newSelected);
  };

  const renderCategory = (category: WordpressCategory) => {
    const hasChildren = categories.some((cat) => cat.parent === category.id);
    return (
      <React.Fragment key={category.id}>
        <ListItem dense button onClick={() => handleCheckboxToggle(category)}>
          <Checkbox checked={selectedCategories.includes(category)} style={{ padding: '0 10px 0 0' }} />
          <ListItemText primary={category.name} />
        </ListItem>
        {hasChildren && (
          <List disablePadding style={{ paddingLeft: '15px' }}>
            {categories.filter((cat) => cat.parent === category.id).map((child) => renderCategory(child))}
          </List>
        )}
      </React.Fragment>
    );
  };

  return (
    <List>{categories.filter((category) => category.parent === 0).map((category) => renderCategory(category))}</List>
  );
};

export default WordpressCategoryTree;
