import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PrintProject } from '../../../../store/PrintProjects/types';
import Property from '../../../Shared/InfoBox/Property';
import { useTranslation } from 'react-i18next';
import { formatPublishDate } from '../../../../utils';

type SourceProjectDetailsProps = {
  sourceProject: PrintProject;
};

const SourceProjectDetails: FunctionComponent<SourceProjectDetailsProps> = ({ sourceProject }) => {
  const { t } = useTranslation();

  return (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: 0 }}>
        <Typography>Source: {sourceProject.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Property title={t('common.publishDate')} value={formatPublishDate(sourceProject.publishDate)} />
        <Property
          title={t('common.status')}
          value={t(`common.projectStatus.${sourceProject.kind}.${sourceProject.status}`)}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default SourceProjectDetails;
