import update from 'immutability-helper';
import { DesignProject, DesignProjectsActionsConstants, DesignProjectsActionType, DesignProjectsState } from './types';
import { normalizeDesignProjects } from '../helpers';
import { createPaginationReducer } from '../Pagination/reducers';
import { PaginationStoreModule } from '../Pagination/types';
import { createNetworkErrorObject, getLocationPaginationState } from '../../utils';
import { AuthActionsConstants } from '../Auth/types';
import { createTableSearchReducer } from '../TableSearch/reducers';
import { TableSearchStoreModule } from '../TableSearch/types';

const initialState: DesignProjectsState = {
  list: {
    entities: {},
    result: []
  },
  tableSearch: {
    order: {}
  },
  pagination: getLocationPaginationState(),
  index: {
    error: null,
    isFailed: false,
    isLoading: false
  },
  printDesignThumbnailGenerated: null
};

const designProjectsPaginationReducer = createPaginationReducer<DesignProjectsState, DesignProject>(
  PaginationStoreModule.DESIGN_PROJECTS
);

const designProjectsTableSearchReducer = createTableSearchReducer<DesignProject, DesignProjectsState>(
  TableSearchStoreModule.DESIGN_PROJECTS
);

export default function designProjectsReducer(
  state = initialState,
  action: WithLogoutAction<DesignProjectsActionType>
) {
  switch (action.type) {
    case DesignProjectsActionsConstants.INDEX_BEGIN: {
      return update(state, {
        index: {
          isFailed: { $set: false },
          isLoading: { $set: true },
          error: { $set: null }
        }
      });
    }

    case DesignProjectsActionsConstants.INDEX_SUCCESS: {
      const data = normalizeDesignProjects(action.payload.data);

      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          error: { $set: null }
        },
        list: {
          entities: { $set: data.entities },
          result: { $set: data.result }
        },
        pagination: {
          $set: action.payload.pagination
        }
      });
    }

    case DesignProjectsActionsConstants.INDEX_FAILED: {
      const networkError = createNetworkErrorObject(action.payload.error);
      return update(state, {
        index: {
          isLoading: { $set: false },
          isFailed: { $set: true },
          error: { $set: networkError }
        },
        list: {
          entities: { $set: {} },
          result: { $set: [] }
        }
      });
    }

    case DesignProjectsActionsConstants.PRINT_DESIGN_THUMBNAIL_GENERATED:
      return update(state, {
        printDesignThumbnailGenerated: {
          $set: action.payload
        }
      });

    case AuthActionsConstants.LOGOUT:
      return update(state, {
        $set: initialState
      });

    default: {
      return designProjectsTableSearchReducer(
        designProjectsPaginationReducer(state, action),
        action
      ) as DesignProjectsState;
    }
  }
}
