import React, { FunctionComponent, useEffect, useState } from 'react';
import { AdBanner, Advertiser, AdZone } from '../../../../../store/AdminAds/types';
import { useTranslation } from 'react-i18next';
import { adminEmailTemplatesOperations } from '../../../../../store/AdminEmailTemplates';
import Loader from '../../../../Shared/Loading/Loader';
import styled from 'styled-components';
import { NoResultsFound } from '../../../PageStyledComponents';
import { Paper, TableContainer, Typography } from '@material-ui/core';
import { adminAdsOperations } from '../../../../../store/AdminAds';
import { AdminEmailTemplate } from '../../../../../store/AdminEmailTemplates/types';
import EmailTemplateAdsAssignmentTables from './EmailTemplateAdsAssignmentTables';
import AdsPreviewInTemplate from './AdsPreviewInTemplate';
import { ProjectModelV2 } from '../../../Editors/EmailDndEditor/types';

type EmailTemplateAdsAssignmentPreviewProps = {
  banners: AdBanner[];
  houseAdsAdvertiser: Advertiser | null;
  emailTemplate: AdminEmailTemplate;
};

const EmailTemplateAdsAssignmentPreview: FunctionComponent<EmailTemplateAdsAssignmentPreviewProps> = ({
  houseAdsAdvertiser,
  banners,
  emailTemplate
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [model, setProjectModel] = useState<ProjectModelV2 | null>(null);
  const [adSpaces, setAdSpaces] = useState<{ [key: string]: number }>({});
  const [bannerZones, setBannerZones] = useState<{ zone: AdZone; banner: AdBanner }[]>([]);

  useEffect(() => {
    const getModel = async () => {
      setLoading(true);
      try {
        const response = await adminEmailTemplatesOperations.getTemplateModel(emailTemplate.id);
        setProjectModel(response.model);

        const allAdSpaces = response.model.sizes.large.reduce((allAdSpaces, row) => {
          row.cols.forEach((column) => {
            column.components.forEach((comp) => {
              if (comp.type === 'ad-space') {
                const adSize = `${comp.params.width}x${comp.params.height}`;

                if (!allAdSpaces[adSize]) {
                  allAdSpaces[adSize] = 1;
                } else {
                  allAdSpaces[adSize] = allAdSpaces[adSize] + 1;
                }
              }
            });
          });
          return allAdSpaces;
        }, {} as { [key: string]: number });

        setAdSpaces(allAdSpaces);

        if (houseAdsAdvertiser) {
          const zones = await adminAdsOperations.getZones(houseAdsAdvertiser.advertiserId, Object.keys(allAdSpaces));
          setBannerZones(zones.data);
        }
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoading(false);
      }
    };
    getModel();
  }, [emailTemplate]);

  return (
    <Wrapper>
      {loading && <Loader />}
      {!loading && !model && <NoResultsFound>Template cannot be processed...</NoResultsFound>}
      {!loading && !!model && (
        <>
          <AdsPreviewInTemplate model={model} />
          <BannerContentWrapper>
            <Typography variant="h5" color="primary" gutterBottom>
              {emailTemplate.name}
            </Typography>
            <Typography variant="caption" color="primary" gutterBottom>
              {emailTemplate.description}
            </Typography>
            {Object.keys(adSpaces).map((size) => (
              <TableContainer
                key={`${emailTemplate.id}-${size}`}
                component={Paper}
                style={{ margin: '1rem 0', padding: '1rem 2rem' }}
              >
                <Typography variant="h5" color="secondary" gutterBottom>
                  {size} <em style={{ fontSize: '1rem' }}>(x{adSpaces[size]})</em>
                </Typography>
                <EmailTemplateAdsAssignmentTables
                  size={size}
                  bannerZones={bannerZones}
                  banners={banners}
                  emailTemplate={emailTemplate}
                  adSpaces={adSpaces}
                />
              </TableContainer>
            ))}
          </BannerContentWrapper>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 2rem;
  border-bottom: 1px solid #0000001f;
  display: flex;
  width: 100%;

  &:not(:first-child) {
    padding-top: 2rem;
  }
`;
const BannerContentWrapper = styled.div`
  width: calc(100% - 300px);
  margin-left: 1rem;
`;

export default EmailTemplateAdsAssignmentPreview;
