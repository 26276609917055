import {
  CheckInPrintProjectAction,
  FlipBookState,
  GetUserPrintProjectsResponse,
  PrintProject,
  PrintProjectsActionsConstants,
  PrintProjectsIndexActionBEGIN,
  PrintProjectsIndexActionFAILED,
  PrintProjectsIndexActionSUCCESS,
  PublishPrintProjectAction,
  PublishPrintProjectWindowCloseAction,
  PublishPrintProjectWindowOpenAction,
  PublishPrintProjectWindowRenderingDoneAction,
  PublishPrintProjectWindowRenderingErrorAction,
  RemovePrintProjectAction,
  SetFlipBookProjectAction,
  SetFlipBookStateAction,
  SharePrintProjectAction
} from './types';
import { ShortUser } from '../Auth/types';

export const projectsIndexBegin = (): PrintProjectsIndexActionBEGIN => ({
  type: PrintProjectsActionsConstants.INDEX_BEGIN
});

export const projectsIndexSuccess = (payload: GetUserPrintProjectsResponse): PrintProjectsIndexActionSUCCESS => ({
  type: PrintProjectsActionsConstants.INDEX_SUCCESS,
  payload
});

export const projectsIndexFailed = (error: any): PrintProjectsIndexActionFAILED => ({
  type: PrintProjectsActionsConstants.INDEX_FAILED,
  payload: {
    error
  }
});

export const removeProject = (projectId: string): RemovePrintProjectAction => ({
  type: PrintProjectsActionsConstants.REMOVE_PROJECT,
  payload: projectId
});

export const shareProject = (projectId: string): SharePrintProjectAction => ({
  type: PrintProjectsActionsConstants.SHARE_PROJECT,
  payload: projectId
});

export const publishProject = (project: PrintProject): PublishPrintProjectAction => ({
  type: PrintProjectsActionsConstants.PUBLISH_PROJECT,
  payload: project
});

export const openPublishWindow = (
  project: PrintProject,
  reScheduling?: boolean
): PublishPrintProjectWindowOpenAction => ({
  type: PrintProjectsActionsConstants.PUBLISH_PROJECT_WINDOW_OPEN,
  payload: { project, reScheduling }
});

export const closePublishWindow = (): PublishPrintProjectWindowCloseAction => ({
  type: PrintProjectsActionsConstants.PUBLISH_PROJECT_WINDOW_CLOSE
});

export const pdfPublishRenderingJobDone = (projectId: string): PublishPrintProjectWindowRenderingDoneAction => ({
  type: PrintProjectsActionsConstants.PUBLISH_PROJECT_WINDOW_JOB_RENDERING_DONE,
  payload: { projectId }
});

export const pdfPublishRenderingJobError = (projectId: string): PublishPrintProjectWindowRenderingErrorAction => ({
  type: PrintProjectsActionsConstants.PUBLISH_PROJECT_WINDOW_JOB_RENDERING_ERROR,
  payload: { projectId }
});

export const checkIn = (projectId: string, user: ShortUser): CheckInPrintProjectAction => ({
  type: PrintProjectsActionsConstants.CHECK_IN_PROJECT,
  payload: {
    projectId,
    user
  }
});

export const setFlipBookProject = (project: PrintProject | null): SetFlipBookProjectAction => ({
  type: PrintProjectsActionsConstants.SET_FLIP_BOOK_PROJECT,
  payload: {
    project
  }
});

export const setFlipBookState = (projectId: string, state: FlipBookState | null): SetFlipBookStateAction => ({
  type: PrintProjectsActionsConstants.SET_FLIP_BOOK_STATE,
  payload: {
    projectId,
    state
  }
});
