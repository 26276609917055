import React, { FunctionComponent, useState } from 'react';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type InputChangeOnBlurProps = {
  disabled?: boolean;
  initialValue: string;
  onSave: (name: string) => void;
  label?: string;
  fullWidth?: boolean;
};

const InputChangeOnBlur: FunctionComponent<InputChangeOnBlurProps> = ({
  initialValue,
  onSave,
  disabled,
  label,
  fullWidth
}) => {
  const { t } = useTranslation();
  const [initialInputValue, setInitialInputValue] = useState<string>(initialValue);
  const [outputInputValue, setOutputInputValue] = useState<string>(initialValue);

  const changeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    setOutputInputValue(e.target.value);
  };

  const saveInputValue = async () => {
    if (disabled) return;

    if (outputInputValue && initialInputValue !== outputInputValue) {
      setInitialInputValue(outputInputValue);
      onSave(outputInputValue);
    }
  };

  return (
    <TextField
      data-item-id="project-name-input"
      variant="outlined"
      size="small"
      label={label}
      fullWidth={fullWidth}
      placeholder={`${t('common.enterValue')}...`}
      value={outputInputValue}
      onBlur={saveInputValue}
      onChange={changeInputValue}
      disabled={disabled}
      required
      error={!outputInputValue}
    />
  );
};

export default InputChangeOnBlur;
