import { BaseSettings, RequestSettings } from '../../../utils/httpRequest';
import { IndexDoAppRunningPromotionReportsRequestParams, IndexDoAppRunningPromotionsRequestParams } from './types';

export const indexRunningPromotions = (
  settings: RequestSettings<IndexDoAppRunningPromotionsRequestParams, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/reports/running-promotions/entries?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const indexRunningPromotionReports = (
  settings: RequestSettings<IndexDoAppRunningPromotionReportsRequestParams, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/reports/running-promotions/reports?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const removeReportWithEntries = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: `/do-app/v1/reports/running-promotions/reports/:id`
  },
  params: settings.requestParams
});
