import React, { useState } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import {
  ConnectDropTarget,
  ConnectDragSource,
  DropTargetMonitor,
  DragSourceMonitor,
  ConnectDragPreview
} from 'react-dnd';
import { DragSource, DropTarget, DropTargetConnector, DragSourceConnector } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import styled from 'styled-components';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import PaletteOutlinedIcon from '@material-ui/icons/PaletteOutlined';
import { EmailProjectRow, ItemTypes, ProjectModelColumn } from '../../types';
import { hasPermission } from '../../../../../../utils/permissions';
import { useTypedSelector } from '../../../../../../utils';
import EditRowWindow from '../Windows/EditRowWindow';
import useOpenHandler from '../../../../../../hooks/useOpenHandler';
import { ComponentToUpdate, RowToUpdateAlongWithComponents } from '../../Utils/modelTransformator';
import { ReactComponent as DuplicateRowIcon } from '../../../../../../assets/icons/duplicate_row.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';

export interface RowProps {
  id: any;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  duplicateRow: (row: EmailProjectRow) => void;
  deleteRow: (rowIndex: number) => void;
  row: EmailProjectRow;
  updateComponentsAndRow: (
    components: ComponentToUpdate[],
    rowData?: RowToUpdateAlongWithComponents,
    cols?: ProjectModelColumn[]
  ) => void;

  isDragging: boolean;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  connectDragPreview: ConnectDragPreview;

  children?: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
  inProjectEditor: boolean;
}

interface RowInstance {
  getNode(): HTMLDivElement | null;
}

const RowWithColumns = forwardRef<HTMLDivElement, RowProps>(function Row(
  {
    id,
    row,
    index,
    updateComponentsAndRow,
    isDragging,
    connectDragSource,
    connectDropTarget,
    connectDragPreview,
    children,
    style,
    inProjectEditor,
    duplicateRow,
    deleteRow
  },
  ref
) {
  const { t } = useTranslation();
  const role = useTypedSelector((state) => state.auth.role);
  const [isShown, setIsShown] = useState(false);
  const [editRowWindowOpen, onEditRowWindowOpen, onEditRowWindowClose] = useOpenHandler();
  const elementRef = useRef(null);
  connectDropTarget(elementRef);

  const opacity = isDragging ? 0.2 : 1;
  useImperativeHandle<any, RowInstance>(ref, () => ({
    getNode: () => elementRef.current
  }));

  const dragSourceStyle: React.CSSProperties = {
    position: 'absolute',
    top: 'calc(50% - 45px)',
    cursor: 'move',
    width: '30px',
    height: '30px',
    backgroundColor: '#00a5aa',
    color: '#fff',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9,
    visibility: isShown ? 'visible' : 'hidden'
  };

  const deleteRowStyle: React.CSSProperties = {
    position: 'absolute',
    top: 'calc(50% - 15px)',
    left: '-30px',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    backgroundColor: '#ef5350',
    color: '#fff',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9,
    visibility: isShown ? 'visible' : 'hidden'
  };

  const editRowStyle: React.CSSProperties = {
    position: 'absolute',
    top: 'calc(50% - 15px)',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    backgroundColor: '#00a5aa',
    color: '#fff',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9,
    visibility: isShown ? 'visible' : 'hidden'
  };

  const duplicateRowStyle: React.CSSProperties = {
    position: 'absolute',
    top: 'calc(50% + 15px)',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    backgroundColor: '#00a5aa',
    color: '#fff',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9,
    visibility: isShown ? 'visible' : 'hidden'
  };

  const iconsHolder: React.CSSProperties = {
    position: 'absolute',
    left: `calc(-15px)`,
    width: `calc(15px + ${row.style?.marginLeft || '0px'})`,
    height: '100%'
  };

  const getBorderStyle = (row: EmailProjectRow): React.CSSProperties => {
    const borderStyle: React.CSSProperties = {
      borderTop: `${row.style?.borderWidth} ${row.style?.borderStyle} ${row.style?.borderColor}`,
      borderRight: `${row.style?.borderWidth} ${row.style?.borderStyle} ${row.style?.borderColor}`,
      borderBottom: `${row.style?.borderWidth} ${row.style?.borderStyle} ${row.style?.borderColor}`,
      borderLeft: `${row.style?.borderWidth} ${row.style?.borderStyle} ${row.style?.borderColor}`
    };

    if (row.border) {
      if (row.border.top === false) borderStyle.borderTop = '0';
      if (row.border.right === false) borderStyle.borderRight = '0';
      if (row.border.bottom === false) borderStyle.borderBottom = '0';
      if (row.border.left === false) borderStyle.borderLeft = '0';
    }

    return borderStyle;
  };

  const divStyle: React.CSSProperties = {
    ...row.style,
    ...style,
    opacity,
    width: `calc(700px - ${row.style?.marginLeft || '0px'} - ${row.style?.marginRight || '0px'})`,
    ...getBorderStyle(row)
  };

  return connectDragPreview(
    <div ref={elementRef} style={divStyle} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
      <StyledRow>
        <div style={iconsHolder}>
          {hasPermission(role, ['projectsEmailAdvancedEditor']) && (
            <div style={deleteRowStyle} onClick={() => deleteRow(index)}>
              <Tooltip title={`${t('pages.emailEditor.deleteRow')}`}>
                <CloseIcon />
              </Tooltip>
            </div>
          )}
          {hasPermission(role, ['projectsEmailAdvancedEditor']) &&
            connectDragSource(
              <div style={dragSourceStyle}>
                <OpenWithIcon />
              </div>
            )}
          <div style={editRowStyle} onClick={onEditRowWindowOpen}>
            <Tooltip title={`${t('pages.emailEditor.rowSettings')}`}>
              <PaletteOutlinedIcon />
            </Tooltip>
          </div>
          <div style={duplicateRowStyle} onClick={() => duplicateRow(row)}>
            <Tooltip title={`${t('pages.emailEditor.duplicateRow')}`}>
              <DuplicateRowIcon fill={'#ffffff'} width={18} />
            </Tooltip>
          </div>
        </div>
        {children}
      </StyledRow>

      {editRowWindowOpen && (
        <EditRowWindow
          row={row}
          open={editRowWindowOpen}
          rowIndex={index}
          onCloseClick={onEditRowWindowClose}
          fullScreenOnMobile={true}
          updateComponentsAndRow={updateComponentsAndRow}
          onFormSubmit={() => onEditRowWindowClose()}
          inProjectEditor={inProjectEditor}
        />
      )}
    </div>
  );
});

export default DropTarget(
  ItemTypes.ROW,
  {
    hover(props: RowProps, monitor: DropTargetMonitor, component: RowInstance) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }

      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      props.moveRow(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    }
  },
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget()
  })
)(
  DragSource(
    ItemTypes.ROW,
    {
      beginDrag: (props: RowProps) => ({
        id: props.id,
        index: props.index
      })
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
    })
  )(RowWithColumns)
);

const StyledRow = styled.div`
  position: relative;
  // width: 700px;
  // background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: default;
`;
