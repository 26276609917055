import { createOrderArray, httpRequest } from '../../utils';
import {
  AdminCreateOrganizationValues,
  AdminOrganization,
  AdminOrganizationsIndexResponse,
  AdminUpdateOrganizationValues
} from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../rootReducer';
import { adminOrganizationsActions, adminOrganizationsRequests } from './index';
import { AdminKeyword, AdminKeywordGroup } from '../AdminKeywords/types';

export const index = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { adminOrganizations } = getState();

  try {
    dispatch(adminOrganizationsActions.organizationsIndexBegin());

    const response = await httpRequest.runRequest<AdminOrganizationsIndexResponse>(
      adminOrganizationsRequests.index({
        requestParams: {
          page: adminOrganizations.pagination.page,
          per: adminOrganizations.pagination.per,
          q: adminOrganizations.pagination.q,
          order: createOrderArray(adminOrganizations.tableSearch.order)
        }
      })
    );

    dispatch(adminOrganizationsActions.organizationsIndexSuccess(response));
  } catch (e) {
    dispatch(adminOrganizationsActions.organizationsIndexFailed(e));
  }
};

export const getAll = async () => {
  return await httpRequest.runRequest<{ data: AdminOrganization[] }>(adminOrganizationsRequests.getAll());
};

export const show = async (id: string) => {
  return await httpRequest.runRequest<AdminOrganization>(
    adminOrganizationsRequests.show({
      requestParams: {
        id
      }
    })
  );
};

export const create = (values: AdminCreateOrganizationValues) => async () => {
  return await httpRequest.runRequest<void>(
    adminOrganizationsRequests.create({
      requestPayload: values
    })
  );
};

export const update = (id: string, values: AdminUpdateOrganizationValues) => async () => {
  return await httpRequest.runRequest<void>(
    adminOrganizationsRequests.update({
      requestPayload: values,
      requestParams: { id }
    })
  );
};

export const deleteOrganization = (organizationId: string) => async () => {
  return await httpRequest.runRequest<void>(
    adminOrganizationsRequests.deleteOrganization({
      requestParams: { id: organizationId }
    })
  );
};

export const addKeywordToOrganization = (organization: AdminOrganization, keyword: AdminKeyword) => async (
  dispatch: Dispatch
) => {
  const updatedOrganization = await httpRequest.runRequest<AdminOrganization>(
    adminOrganizationsRequests.addKeywordToOrganization({
      requestParams: { organizationId: organization.id },
      requestPayload: { keywordId: keyword.id }
    })
  );
  dispatch(adminOrganizationsActions.keywordAdded(updatedOrganization));
};

export const addKeywordGroupToOrganization = (organization: AdminOrganization, group: AdminKeywordGroup) => async (
  dispatch: Dispatch
) => {
  const updatedOrganization = await httpRequest.runRequest<AdminOrganization>(
    adminOrganizationsRequests.addKeywordGroupToOrganization({
      requestParams: { organizationId: organization.id },
      requestPayload: { keywordGroupId: group.id }
    })
  );
  dispatch(adminOrganizationsActions.keywordGroupAdded(updatedOrganization));
};

export const removeKeywordFromOrganization = (organization: AdminOrganization, keyword: AdminKeyword) => async (
  dispatch: Dispatch
) => {
  const updatedOrganization = await httpRequest.runRequest<AdminOrganization>(
    adminOrganizationsRequests.removeKeywordFromOrganization({
      requestParams: { organizationId: organization.id },
      requestPayload: { keywordId: keyword.id }
    })
  );
  dispatch(adminOrganizationsActions.keywordRemoved(updatedOrganization));
};

export const removeKeywordGroupFromOrganization = (organization: AdminOrganization, group: AdminKeywordGroup) => async (
  dispatch: Dispatch
) => {
  const updatedOrganization = await httpRequest.runRequest<AdminOrganization>(
    adminOrganizationsRequests.removeKeywordGroupFromOrganization({
      requestParams: { organizationId: organization.id },
      requestPayload: { keywordGroupId: group.id }
    })
  );
  dispatch(adminOrganizationsActions.keywordGroupRemoved(updatedOrganization));
};
