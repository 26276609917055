import React, { FunctionComponent } from 'react';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { WordpressPost } from '../../../../store/MyOrganization/types';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Link
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';

type WordpressPostsWindowProps = {
  open: boolean;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  wordpressPosts: WordpressPost[];
};

const WordpressPostsWindow: FunctionComponent<WordpressPostsWindowProps> = ({ onCloseClick, open, wordpressPosts }) => {
  const { t } = useTranslation();

  const handleInnerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Prevent the click event from propagating up the component hierarchy
    e.stopPropagation();
  };

  return (
    <div onClick={handleInnerClick}>
      <ModalConfirmationWindow
        open={open}
        header={t('pages.story.wordpressPosts')}
        hideActionButtons
        onCloseClick={onCloseClick}
        maxWidth="md"
      >
        <List dense>
          {wordpressPosts.map((post) => (
            <React.Fragment key={post.id}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <LanguageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={post.integration.name}
                  secondary={
                    <Link href={post.integration.url} target="_blank">
                      {post.integration.url}
                    </Link>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="open"
                    onClick={() => {
                      window.open(
                        `${post.integration.url}/wp-admin/post.php?post=${post.postId}&action=edit`,
                        '_blank'
                      );
                    }}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </ModalConfirmationWindow>
    </div>
  );
};

export default WordpressPostsWindow;
